import React from 'react';
import moment from 'moment';
import { Row, Tabs, Col, Table, Checkbox } from 'antd';
import AppContext from '../../../AppContext';
import closeIcon from '../../../../src/assets/closeXsmall.svg';
import { connect } from 'react-redux';
import * as statisticsService from '../../../../src/services/statisticsService';
import { data } from './domeAdmin/domeAdmin';
// import {getStatisticsForvoucher} from '../../../../src/services/domeService'

const { TabPane } = Tabs;
function callback(key) {
}



class Voucher extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      apiData: null,
      filteredData:null
    };
  }
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  showVoucher = async () => {
    let context = await this.getContext()
    context.voucher = false;
    await this.updateContext(context);
  }

  componentDidMount = async () => {
    const response = await statisticsService.getStatisticsForvoucherget(0, this.props.userId)
     const filteredData =response.List.filter(item =>item.used=='0')
            this.setState({
      apiData: response.List,
      filteredData:filteredData
    });

  };

  

  render() {
    const { translation, coupons, vouchers } = this.props
    const { apiData,filteredData } = this.state;

    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog z-index-13">
          <div className="third-card relative">
            <div className="noTitleBox">
              <i className="iconx cursor">
                <img onClick={this.showVoucher} src={closeIcon} alt="Info" />
              </i>
            </div>

            <div className="domeAddContainer voucherCard">
              <div className='flatTabs'>
                <Tabs defaultActiveKey="1" onChange={callback}>
                  <TabPane tab={translation("Valid C&V")} key="1">
                    <div className='validPane scrollSection'>
                      <Row>
                        <Col>
                          <div className='couponTag mb-15'>{translation('COUPON')} <i>{coupons}</i></div>
                          <h4 className='text-center text-muted'><i>{translation('No valid Coupon')}</i></h4>
                          <div>
                              {/* <div className='vcloop'>
                                  <Row type="flex" justify="space-between">
                                    <Col span={24}>
                                      <div className='vcloop__first'>1. Dome Address 1-Year registration coupon</div>
                                      <div className='vcloop__second fx-jcfb'>
                                        <span>ID v1233211233</span>
                                        <span>Expiration date: Jul 23, 2024</span>
                                      </div>
                                    </Col>
                                  </Row>                           
                              </div> */}
                          </div>
                        </Col>
                      </Row>
                      
                      <Row className='mb-20'>
                        <Col>
                          <div className='couponTag'>{translation('VOUCHER')} <i>{vouchers}</i></div>
                        </Col>
                      </Row>
                      {(vouchers=='0'?<h4 className='text-center text-muted'><i> {translation('No valid Voucher')}</i></h4> :"")}
                    
                      <div>
                        <div className='vcloop'>
                          {
                            filteredData?.map((item: any, index: any) => {
                              return (
                                <Row type="flex" justify="space-between">
                                  <Col span={24}>
                                    <div className='vcloop__first'>{index + 1}. {translation('Dome Address 5-Year')}</div>
                                    <div className='vcloop__second fx-jcfb'>
                                      <span>{translation('ID')} {item.voucherID}</span>
                                      <span>{translation('Expiration date')}:{moment(parseInt(item.expiration, 10)).format('DD/MM/YY')}</span>
                                    </div>
                                    <br />
                                  </Col>
                                </Row>
                              )                             
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  <TabPane tab={translation('History')} key="2">
                    <div className='tblMain scrollSection'>
                      <table className="table theadFixTable">
                        <thead>
                          <tr>
                            <th>{translation('Date')}</th>
                            <th>{translation('Item')}</th>
                            <th>{translation('ID')}</th>
                            <th>{translation('Status')}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {apiData==0? <tr><td className='text-center' colSpan={4} style={{paddingTop:'100px'}}><i className='text-muted'>{translation('No History')}</i></td></tr>:''}
                          {
                            apiData?.map((item: any, index: any) => {
                              return (
                                <tr>
                                  <td>{moment(parseInt(item.issue_date, 10)).format('DD/MM/YY')}</td>
                                  <td>{translation('Voucher')}</td>
                                  <td>{item.voucherID}</td>
                                  <td>
                                    {item.issue_date === item.expiration
                                      ? translation('Expired')
                                      : item.used === '0'
                                        ? translation('Unused')
                                        : translation('Used')} </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  translation: state.userData.translation,
  coupons: state.userData.coupons,
  vouchers: state.userData.vouchers,
  userId: state.userData.mysqlID,
});
export default connect(mapStateToProps, null)(Voucher);
