import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import CandyEvent from './CandyEvent';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import PropTypes from 'prop-types';

function CandyEventList(props) {
  const { candyEventList, language ,challenge_type,month} = props;
  // const [challenge_type,setchallenge_type]=useState('')
  return (
    <div>
      {candyEventList.map((candyEvent) => {
        return (
          <CandyEvent
            key={candyEvent.ID}
            ID={candyEvent.ID}
            startDate={candyEvent.startDate}
             endDate={candyEvent.endDate}
            title={candyEvent.title}
            country={candyEvent.country}
            challenge_type={candyEvent.challenge_type}
          />
        );
      })}
      {candyEventList.length === 0 && (
        <Row className="no-prizes">
          <Col>
            <Typography.Text className="roboto-regular center large-text">
              {candyEvent.noCandyEvent[language]}
            </Typography.Text>
          </Col>
        </Row>
      )}
    </div>
  );
}

CandyEventList.propTypes = {
  candyEventList: PropTypes.array,
  challenge_type:PropTypes.string,
  language: PropTypes.string,
};

CandyEventList.defaultProps = {
  candyEventList: [],
  language: 'EN',
};

export default CandyEventList;