import React from 'react';
import AppContext from '../../../AppContext';
import { connect } from 'react-redux';
import { Row, Spin, Col } from 'antd';
import {
  listMyDomes,
  getMessagesList,
  getDomeTypes,
} from '../../../services/domeService';
import { notification } from 'antd';

class DomButton extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      current_id: 0,
      dome_setting_active: '',
      dome_editting_active: '',
      setting: false,
      editing: false,
      admin: false,
    };
    this.domeSettingHandler = this.domeSettingHandler.bind(this);
    this.domeEditingHandler = this.domeEditingHandler.bind(this);
    this.domeAdminHandler = this.domeAdminHandler.bind(this);
  }

  componentDidMount = async () => {
    let contextdata =   await this.getContext();
    if (contextdata.go_to_setting) {
        await this.domeSettingHandler();
        await this.domeDetails(
        contextdata.current_dome.ID,
        contextdata.current_dome.current_index
      );
      contextdata.go_to_setting = 0;
        await this.updateContext(contextdata);
    }
  };

  showErrorMessage = (title) => {
    notification.error({
      message: `${title}`,
      duration: 10,
    });
  };

  componentDidUpdate = async () => {
    let contextdata =   await this.getContext();
    if (contextdata.isDomeEditingActive === 'active') {
      //   this.domeEditingHandler();
      this.setState({
        dome_setting_active: '',
        dome_editting_active: 'active',
        dome_admin_active: '',
        setting: false,
        editing: true,
        admin: false,
      });
      contextdata.isDomeEditingActive = '';
        await this.updateContext(contextdata);
    } else if (contextdata.isDomeAdminNavigation === 'active') {
      //   this.domeEditingHandler();
      this.setState({
        dome_setting_active: '',
        dome_editting_active: '',
        dome_admin_active: 'active',
        setting: false,
        editing: false,
        admin: true,
      });
      contextdata.isDomeAdminNavigation = '';
        await this.updateContext(contextdata);
    }
  };
  
  domeSettingHandler = async () => {
    let profile =   await this.getContext();
    profile.loader = true;
      await this.updateContext(profile);
    this.setState({
      dome_setting_active: 'active',
      dome_editting_active: '',
      dome_admin_active: '',
      setting: true,
      editing: false,
      admin: false,
    });

    let msgResp =   await getMessagesList(this.state.current_id);
    let domeList =   await listMyDomes();
    if(domeList.length==0){
     this. showErrorMessage("No Dome Address to be set");
    }
    profile =   await this.getContext();
    profile.loader = false;
    profile.domeList = [];
    profile.domeSettingList = [];
    profile.domeSettingList = domeList;
    if (this.state.current_id) {
      const current_dome = domeList.find(
        (item) => item.ID === this.state.current_id
      );
      const current_index = domeList.findIndex(
        (item) => item.ID === this.state.current_id
      );

      profile.edit_current_dome = null;
      profile.current_dome = null;
      profile.admin_dome = null;
      profile.msgList = msgResp.data;
      profile.current_dome = current_dome;
      profile.current_dome.current_index = current_index;
    }

      await this.updateContext(profile);
  };
  domeEditingHandler = async () => {
    let profile =   await this.getContext();
    profile.loader = true;
    // profile.domeEditingList = [];
      await this.updateContext(profile);
    this.setState({
      dome_setting_active: '',
      dome_editting_active: 'active',
      dome_admin_active: '',
      setting: false,
      editing: true,
      admin: false,
    });
    let domeList =   await listMyDomes();
    if(domeList.length==0){
     this. showErrorMessage("No Dome Address to be edited");
    }
    profile =   await this.getContext();

    profile.loader = false;
    profile.domeList = [];
    profile.domeSettingList = [];
    profile.domeSettingList = domeList;
    if (this.state.current_id) {
      const current_dome = domeList.find(
        (item) => item.ID === this.state.current_id
      );
      const current_index = domeList.findIndex(
        (item) => item.ID === this.state.current_id
      );

      profile.edit_current_dome = null;
      profile.current_dome = null;
      profile.admin_dome = null;
      profile.edit_current_dome = current_dome;
      profile.edit_current_dome.current_index = current_index;
    }

      await this.updateContext(profile);
  };

  domeAdminHandler = async (e: any) => {
    let profile =   await this.getContext();
    profile.loader = true;
      await this.updateContext(profile);
    this.setState({
      dome_setting_active: '',
      dome_editting_active: '',
      dome_admin_active: 'active',
      setting: false,
      editing: false,
      admin: true,
    });
    let domeList =   await listMyDomes();
    if(domeList.length==0){
     this. showErrorMessage("  No Dome Address to be admined");
    }
    profile =   await this.getContext();
    profile.loader = false;
    profile.domeList = [];
    profile.domeSettingList = [];
    profile.domeSettingList = domeList;
    if (this.state.current_id) {
      const current_dome = domeList.find(
        (item) => item.ID === this.state.current_id
      );
      const current_index = domeList.findIndex(
        (item) => item.ID === this.state.current_id
      );

      profile.edit_current_dome = null;
      profile.current_dome = null;
      profile.admin_dome = null;
      profile.admin_dome = current_dome;
      profile.admin_dome.current_index = current_index;
    }

      await this.updateContext(profile);
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  domeDetails = async (id: number, index: number) => {
    this.setState({
      current_id: id,
    });
    const domeList =   await listMyDomes();
    let profile =   await this.getContext();
    let msgResp =   await getMessagesList(this.state.current_id);
    const domeSetting =   await getDomeTypes(this.state.current_id);
    profile.domeSettingList = domeList;
    // let domeSettingList = profile.domeSettingList;
    let domeSettingList = domeList;
    profile.edit_current_dome = null;
    profile.current_dome = null;
    profile.admin_dome = null;
    profile.domeTypeSetting = domeSetting.dome_type_setting;

      await this.updateContext(profile);
    let current_dome = domeSettingList[index];
    if (profile.isDomeEditingActive === 'active') {
      profile.current_dome = null;
      profile.edit_current_dome = current_dome;
      profile.edit_current_dome.current_index = index;
    } else if (this.state.setting) {
      profile.msgList = msgResp.data;
      profile.current_dome = current_dome;
      profile.current_dome.current_index = index;
      profile.edit_current_dome = null;
    } else if (this.state.editing) {
      profile.current_dome = null;
      profile.edit_current_dome = current_dome;
      profile.edit_current_dome.current_index = index;
    } else if (this.state.admin) {
      profile.current_dome = null;
      profile.edit_current_dome = null;
      profile.admin_dome = current_dome;
      profile.admin_dome.current_index = index;
    }
    profile.isDomeEditingActive = '';
      await this.updateContext(profile);
  };

  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };

  render() {
    const context = this.context;
    const { translation } = this.props
    let toalDomeSettingList = 0;
    const domeSettingList = context.profile.domeSettingList || [];
    toalDomeSettingList = domeSettingList.length;
    let loader = context.profile.loader || false;
    let isDomeEditingActive = context.profile.isDomeEditingActive || '';
    return (
      <>
        <Row className="fx-jcc sticky-buttons">
          <button
            onClick={this.domeSettingHandler}
            className={
              'ant-btn primary-ghost-btn xs ' + this.state.dome_setting_active
            }
          >
           {translation("Dome Settings")}
            {/* Dome Settings */}
          </button>
          <button
            onClick={this.domeEditingHandler}
            className={`ant-btn primary-ghost-btn xs ml-5 mr-5 ${
              isDomeEditingActive
              ? isDomeEditingActive
              : this.state.dome_editting_active
              }`}
          >
            {translation("Dome Editing")}
            {/* Dome Editing */}
          </button>
          <button
            onClick={this.domeAdminHandler}
            className={
              'ant-btn primary-ghost-btn xs ' + this.state.dome_admin_active
            }
          >
            {translation("Dome Admin")}
            {/* Dome Admin */}
          </button>
        </Row>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog domeSettingInside">
          {loader && (
            <Spin
              size="large"
              style={{ marginTop: '2em', marginBottom: '2em' }}
            />
          )}
          {domeSettingList.map((dome, index) => (
            <div key={index}>
              <div
                onClick={() => this.domeDetails(dome.ID, index)}
                className={
                  'let-long-loop domeAddLoop ' +
                  (this.state.current_id === dome.ID ? 'active' : '')
                }
              >
                <div className="let-long-loop__left">
                  <div className="let-long-loop__left__row mb-10">
                    {dome.profile !== null ? (
                      <div className="picCircle">
                        <i className="let-long-loop__left__row__icon">
                          <img
                            src={
                              ' https://app.loamoa-dev-merge.cladev.com/uploads/' +
                              dome.profile.id
                            }
                            alt="User"
                          />
                        </i>
                      </div>
                    ) : (
                      <div className="picCircle"></div>
                    )}
                    <div className="let-long-loop__left__row__info">
                      <h3 className="font-bold size14 mb-0">
                        {dome.addr_name}{' '}
                        <span className="text-muted" translate='no' >
                          {/* @{dome.dome_status == 1 ?  dome.dome_name : ''} */}
                          @
                          {this.renderDomeName(
                            dome.dome_status,
                            dome.dome_name,
                            dome.dome_name_suffix,
                            dome.file
                          )}
                        </span>
                      </h3>
                      <div className="let-long-loop__left__row__info__row">
                        <div className="let-long-loop__left__row__info__row__info size10">
                          <div className="mr-10">
                            <p className="mb-0">{ translation("Latitude")}</p>
                            <p className="mb-0">
                              {dome.location.y.toFixed(11)}
                            </p>
                          </div>
                          <div className="mb-0">
                            <p className="mb-0">{translation("Longitude")}</p>
                            <p className="mb-0">
                              {dome.location.x.toFixed(11)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="let-long-loop__right size11 text-primary">
                  <p className="mb-3 text-primary">
                    {translation('Dome Address')}:{' '}
                    <em>
                      {dome.addr_status ? (
                        <span className="published">{translation('Published')}</span>
                      ) : (
                        <span className="unpublished">{translation('Unpublished')}</span>
                      )}
                    </em>
                  </p>
                  <p className="mb-0 text-primary">
                  {translation('Dome Settings')}:{' '}
                    <em>
                      {dome.setting_status ? (
                        <span className="approved"> {translation('Uploaded')}</span>
                      ) : (
                        <span className="notSubmitted"> {translation('Not Uploaded')}</span>
                      )}
                    </em>
                  </p>
                  {/* <p className="mb-0">Go to &#62; <a href="" className="text-default text-underline">Dome settings</a></p> */}
                </div>
              </div>
            </div>
          ))}
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomButton);
