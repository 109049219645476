import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import locationIcon from '../../../../src/assets/location-on-map.svg';
// import infoIcon from '../../../../src/assets/icon_info.svg';
// import editIcon from '../../../../src/assets/icon_edit.svg';
// import deleteIcon from '../../../../src/assets/icon_delete_o.svg';
// import { isExistsDomeName, UpdateDome,UpdateDomeType, SearchDome } from '../../../services/domeService';

class DomeNameSetting extends React.Component<any, any> {
    static contextType = AppContext;
    constructor(props: any) {
        super(props);
        this.state = {
            dome_name: false,
        }
    }

    close = async(e:any) =>{
        this.props.vrificationstatus({
            after_verification: false
        });
    }

    render(){
        const context = this.context;
        const profile = context.profile;

        return(
            <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
                <div className="third-card">
                    <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold"> &#62; Dome Name settings</h4>
                        <i onClick={this.close} className="iconx cursor">
                            <img src={closeIcon} alt="Info" />
                        </i>
                    </div>

                    <div className="size12">
                        <p>{profile.current_dome.addr_name} Dome address has a Dome Name as @{profile.current_dome.dome_name}</p>
                        <p>Thanks</p>
                    </div>
                </div>
            </Row>
        )
    }
}

export default DomeNameSetting;