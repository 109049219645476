import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, Typography } from 'antd';
import KitPriceCountry from './KitPriceCountry';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { languages } from '../../../../lib/interfaces/language';
import { kitPricesPerCountry } from '../../../../lib/strings/kitCreation';

interface Props {
  handleClose?: () => void;
  pricesPerCountry: Array<any>;
  language: languages;
}

const mapState = (state: {
  kitDetails: { pricesPerCountry: any };
  authentication: { language: any };
}) => {
  return {
    pricesPerCountry: state.kitDetails.pricesPerCountry,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
  };
};

const KitPricesByCountry = (props: Props) => {
  return (
    <Row className="kit-details-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="start" align="middle">
          <Button onClick={props.handleClose} className="details-arrow">
            <img src={arrow} alt="close" />
          </Button>

          <Typography.Text className="bold">{kitPricesPerCountry[props.language]}</Typography.Text>
        </Row>
        <Row className="kit-details">
          {props.pricesPerCountry.map((country, index) => {
            if (country.country !== 'default') {
              return (
                <KitPriceCountry
                  key={index}
                  countryName={country.country}
                  points={country.points}
                  price={country.price}
                />
              );
            }
          })}
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(KitPricesByCountry);
