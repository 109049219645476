import { rechargeConstants } from '../constants/rechargeConstants';
import { rechargeConstantss } from '../constants/rechargeConstants';

export const recharge = (state = {}, action: any) => {
  switch (action.type) {
    case rechargeConstants.STORE_AMOUNT:
      return {
        ...state,
        amount: action.amount,
        usd: action.amount,
      };
      
    default:
      return state;
  }
};

export const plumsCoins = (state = {}, action: any) => {
  switch (action.type) {
    case rechargeConstantss.STORE_AMOUNTS:
      return {
        ...state,
        amounts: action.amounts,
        us: action.amounts,
      };
    default:
      return state;
  }
};