import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import PropTypes from 'prop-types';

function TreasureListLocationSelector(props) {
  const { visible, list, onChange, defaultValue } = props;

  if (!visible) {
    return null;
  }

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const onSelectHandler = (value) => setCurrentValue(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  return (
    // only on dev server
    <Row className="treasure-list-location">
      <Col>
        <Select
          className="treasure-list-location-selector treasure-list-status-selector-picker"
          defaultValue={list[0]}
          onChange={onSelectHandler}
        >
          {list.map((name) => {
            return (
              <Select.Option className="text-centered" key={name} value={name}>
                {name}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
}

TreasureListLocationSelector.propTypes = {
  visible: PropTypes.bool,
  list: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

TreasureListLocationSelector.defaultProps = {
  visible: true,
  defaultValue: 'Test1',
  list: ['Test1', 'Test2', 'Test3'],
  onChange: () => {},
};

export default TreasureListLocationSelector;
