import React, { useState } from 'react';

import { notification, Row, Spin } from 'antd';
import AppContext from '../../../AppContext';
import { store } from '../../../helpers/store';
import { throttle } from 'throttle-debounce';

import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import { languages } from '../../../lib/interfaces/language';
import {
  listMyDomes,
  publishUnpublishDome,
} from '../../../services/domeService';

import { getMarkerLocation } from '../../../helpers/GetLocation';
import { connect } from 'react-redux';
import { baseURL, domepayment } from '../../../lib/config';
import Profile from './Profile';
import {b} from '../../dashboard/menu/i18next'


interface Props {
  language: languages;
  
}

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 15,
  });
};



class Mydome extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    
    super(props);
    this.state = {
      isPublished: false,
      isUnpublished: false,
      dome_name: '',
      updateStatus: false,
      dome_id: 0,
      dome_status: 1,
      messsage: '',
    
     
    };

  }
 
 

  publishModalToggle = () => {
    
    this.setState({
      isPublished: false,
    });
  };

  unPublishModalToggle = () => {
    this.setState({
      isUnpublished: false,
    });
  };

  unPublish = async (status) => {
    if (status === 'yes') {
       await this.updateDomeStatus(this.state.dome_id);
      this.setState({
        isUnpublished: false,
        isPublished: true,
        messsage: b?.t('Dome Add has been unpublished.'),
      });
    } else {
      this.setState({
        isUnpublished: false,
      });
    }
  };
 

  dateFormate = (newDate) => {
    var dateVal = '/Date(' + newDate + ')/';
    var date = new Date(newDate);

    return (
      date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
    );
    // console.log(
    // date.getFullYear() + " " +
    // (date.getMonth() + 1) + "/" +
    // date.getDate());
  };

  changeDomeStatus = async (id: number, status: number, dome_name: string) => {
    if (status === 1) {
      this.setState({
        isUnpublished: true,
        dome_name: dome_name,
        dome_id: id,
        dome_status: status,
      });
    } else {
       await this.updateDomeStatus(id);
      this.setState({
        isPublished: true,
        dome_name: dome_name,
        dome_id: id,
        dome_status: status,
        messsage:b?.t('Successfully published'),
      });
    }
  };

  updateDomeStatus = async (id: number) => {
    let data =  await this.getContext();
    let domes = data.domeList || [];

    const updatedDomes =  await Promise.all(
      domes.map(async (dome: any) => {
        if (dome.ID === id) {
          let status = dome.addr_status == 1 ? 0 : 1;
          return {
            ...dome,
            addr_status: status,
          };
        }
        return dome;
      })
    );
    data.domeList = updatedDomes;
     await this.updateContext(data);
  
    // Call API to publish unpublish
     await publishUnpublishDome(id);
  };

  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
    const mapInstance = store.getState().address.mapInstance;
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }

    let res = getMarkerLocation(lat, lng, dome_addr);
    mapInstance.setCenter(res.center);
    this.setState({
      marker: res.marker,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  closeMyDomePopup = async () => {
    let data =  await this.getContext();
    data.domeList = null;
     await this.updateContext(data);
  };

  goToSetting = async (e, index) => {
    let contextData =  await this.getContext();
    contextData.current_dome = contextData.domeList[index];
    contextData.current_dome.current_index = index;
    contextData.domeSettingList = contextData.domeList;
    contextData.domeList = [];
    contextData['current_menu_name'] = 'Dome';
    contextData['go_to_setting'] = 1;
     await this.updateContext(contextData);
  };

  //   _initProfile(status, name = '') {
  //     const context = this.context;
  //     let profileData = context.profile;
  //     profileData.register = status;
  //     profileData.name = name;
  //     // profileData.dom_map = true;
  //     // profileData.dome_lat = this.state.dome_lat;
  //     // profileData.dome_lng = this.state.dome_lng;
  //     // profileData.dome_name = this.state.dom_name ? this.state.dom_name : '';
  //     // profileData.zoom = 20;

  //     context.setProfile(profileData);
  //   }

  renewDomHandler = throttle(
    3000,
    async (dome) => {
      const context = this.context;
      let profile = context.profile;
      profile.show=true
      
      // const PageName = e.target.getAttribute('data-name');
      if (this.props.role === 1100) {
        const requestOption = {
          method: 'POST',
          headers: {
            'access-token': store.getState().authentication.user.newToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            dome_id: dome.ID,
            year: 1,
          }),
        };
        const res =  await fetch(baseURL + domepayment, requestOption).then(
          (r) => r.json()
        );
        const domeList =  await listMyDomes();
        profile['domeList'] = domeList;
        context.setProfile(profile);
        // console.log(dome);
        showSuccessMessage(`${dome.addr_name} Renewed Successfully`);
      } else {
        const PageName = '';

        let profile1 = { ...profile };

        // this._initProfile(true, PageName);
        profile.dome_error = false;
        profile.register = true;
        profile.showItems = true;
        profile.iscart = false;
        profile.name = 'Renew';
        // profile.enter_for_location = false;
        context.setProfile(profile);
        // this.setState({
        //   dome_error: false,
        // });

        // const inserted_dome_id = res.data.inserted_dome_id;
        // profile.inserted_dome_id = inserted_dome_id;
        profile.inserted_dome_id = dome.ID;
        // profile.dome_name = this.state.dom_name;
        profile.dome_name = dome.addr_name;
        profile.dome_lat = dome.location.x;
        profile.dome_lng = dome.location.y;
        profile.domeList = null;
        context.setProfile(profile);
        
      }
    },
    {
      noTrailing: true,
    }
  );

  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };


  render() {
    const context = this.context;
    const { translation } = this.props
    let toalDomeList = 0;
    const domeList = context.profile.domeList || [];
    let loader = context.profile.loader || false;
    toalDomeList = domeList.length;
    let isPublished = this.state.isPublished || false;
    let isUnpublished = this.state.isUnpublished || false;
    const ds = new Date();
    return (
      <>
        {/* My Dome Add. card */}

        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          {loader && (
            <Spin
              size="large"
              style={{ marginTop: '2em', marginBottom: '2em' }}
            />
          )}
          {toalDomeList ? (
            <div className="second-card">
              <div className="fx-jcsb-aic mb-20">
                <h4 className="text-primary mb-0 font-bold">
                  {' '}               
                  &#62;{ translation("My Dome Address")} 
                </h4>
                <i onClick={this.closeMyDomePopup} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>

              <div className="domeAddContainer scrollSection">
                {domeList.map((dome, index) => (
                  <div
                    key={dome.ID}
                    className={
                      'let-long-loop domeAddLoop ' +
                      (this.state.dome_id === dome.ID ? 'active' : '')
                    }
                  >
                    <div className="let-long-loop__left">
                      <div className="let-long-loop__left__row mb-10">
                        <div className="let-long-loop__left__row__info">
                          <h3 className="font-bold size14 mb-0">
                            {/* <input type="checkbox" /> */}
                            {dome.addr_name}{' '}
                            <span className="text-muted">
                              @
                              {/* {dome.dome_status === 1 ? dome.dome_name : ''} */}
                              {this.renderDomeName(
                                dome.dome_status,
                                dome.dome_name,
                                dome.dome_name_suffix,
                                dome.file
                              )}

                            </span>
                          </h3>
                          <div className="let-long-loop__left__row__info__row">
                          
                            <i
                             style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                this.getLocation(
                                  dome.location.y,
                                  dome.location.x,
                                  dome.addr_name,
                                  e
                                )
                              }
                              className="let-long-loop__left__row__icon"
                            >
                              <img src={locationIcon} alt="Location" />
                            </i>
                            <div className="let-long-loop__left__row__info__row__info size10">
                              <div className="mr-10">
                                <p className="mb-0">{ translation("Latitude")} </p>
                                <p className="mb-0">
                                  {dome.location.y.toFixed(11)}
                                </p>
                              </div>
                              <div className="mb-0">
                                <p className="mb-0">{translation("Longitude")}</p>
                                <p className="mb-0">
                                  {dome.location.x.toFixed(11)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="size12">
                        <p className="mb-3">
                        { translation("Dome Address")}:{' '}
                          <em className="text-primary">
                            {dome.addr_status ? (
                              <span className="published">{translation('Published')}</span>
                            ) : (
                              <span className="unpublished">
                                {translation('Unpublished')}
                              </span>
                            )}
                          </em>
                        </p>
                        <p className="mb-3">
                        { translation("Dome Settings")}:{' '}
                          <em className="text-success">
                            {dome.setting_status ? (
                              <span className="approved"> { translation('Uploaded')}</span>
                            ) : (
                              <span className="notSubmitted">
                               { translation('Not Uploaded')} 
                              </span>
                            )}
                          </em>
                        </p>
                        <p  className="mb-0">
                          
                        { translation("Go to")} &#62;{' '}
                          < h6  
                          style={{
                            display:"inline",
                            fontSize:"initial",
                            cursor:"pointer",
                          
                          }}
                            onClick={(e) => this.goToSetting(e, index)}
                            className="text-default text-underline size12 font-bold">
                              { translation("Dome Settings")}
                            </h6> 
                        </p>
                      </div>
                    </div>

                    <div className="let-long-loop__right">
                      <div className="mb-10">
                        <p className="mb-3 size12">
                        { translation("Auto-renews on")}{this.dateFormate(dome.valid_till)}{''}
                          
                        </p>
                        <div className="fx-jcfe">
                          <button
                            // disabled={!dome.addr_status}
                            onClick={() => this.renewDomHandler(dome)}
                            // onClick={
                            //   dome.addr_status
                            //     ? () => this.renewDomHandler(dome)
                            //     : () => {}
                            // }
                            className="ant-btn primary-ghost-btn xs rd-5"
                          >
                           { translation("Renew now")} 
                          </button>
                        </div>
                      </div>

                      <div className="totalAmt">
                        <button
                          disabled={!dome.setting_status}
                          onClick={() =>
                            this.changeDomeStatus(
                              dome.ID,
                              dome.addr_status,
                              dome.addr_name
                            )
                          }
                          className={
                            !dome.addr_status
                              ? 'ant-btn yellow-gradient xs rd-5 publishBtn'
                              : 'ant-btn yellow-gradient xs rd-5 unPublishBtn'
                          }
                        >
                          {!dome.addr_status ?translation('Publish') : translation('Unpublish')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </Row>

        {/* Publish Modal UI */}
        <div
          className={!isPublished ? 'overlay' : 'overlay opened'}
          onClick={this.publishModalToggle}
        ></div>
        <div
          className={
            !isPublished ? 'custom-modal xs' : 'custom-modal xs opened'
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.publishModalToggle}
          ></div>
          <div className="custom-modal__body">
            <h4>{this.state.dome_name}</h4>
            <p>{this.state.messsage}</p>
            <p className="mb-0">
            {translation('Thank you!')} <br />
            {translation('LoaMoa Team')} 
            </p>
          </div>
        </div>

        {/* Unpublish Modal UI */}
        <div
          className={!isUnpublished ? 'overlay' : 'overlay opened'}
          onClick={this.unPublishModalToggle}
        ></div>
        <div
          className={
            !isUnpublished ? 'custom-modal xs' : 'custom-modal xs opened'
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.unPublishModalToggle}
          ></div>
          <div className="custom-modal__body">
            <p>
            {translation('Are you sure if')}  {this.state.dome_name}{translation('Dome Add is unpublished?')} 
            </p>
            <div className="fx-jcsb">
              <button
                onClick={() => this.unPublish('cancle')}
                className="ant-btn primary-ghost-btn xs rd-5"
              >
               {translation(' Cancel')} 
              </button>
              <button
                onClick={() => this.unPublish('yes')}
                className="ant-btn yellow-gradient xs rd-5"
              >
               {translation('Yes')}  
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.userData.role,
  translation: state.userData.translation,
});

export default connect(mapStateToProps, null)(Mydome);


// import React, { useState } from 'react';

// import { Row, Spin } from 'antd';
// import AppContext from '../../../AppContext';
// import { store } from '../../../helpers/store';

// import closeIcon from '../../../../src/assets/closeXsmall.svg';
// import locationIcon from '../../../../src/assets/location-on-map.svg';
// import { publishUnpublishDome } from '../../../services/domeService';

// import { getMarkerLocation } from '../../../helpers/GetLocation';

// class Mydome extends React.Component<any, any> {
//   static contextType = AppContext;
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       isPublished: false,
//       isUnpublished: false,
//       dome_name: "",
//       updateStatus: false,
//       dome_id: 0,
//       dome_status: 1,
//       messsage: "",
//     };
//   }

//   publishModalToggle = () => {
//     this.setState({
//       isPublished: false,
//     });
//   };

//   unPublishModalToggle = () => {
//     this.setState({
//       isUnpublished: false,
//     });
//   };

//   unPublish = async (status) => {
//     if (status === "yes") {
//        await this.updateDomeStatus(this.state.dome_id);
//       this.setState({
//         isUnpublished: false,
//         isPublished: true,
//         messsage: "Dome Add has been unpublished.",
//       });
//     } else {
//       this.setState({
//         isUnpublished: false,
//       });
//     }
//   };

//   dateFormate = (newDate) => {
//     var dateVal = "/Date(" + newDate + ")/";
//     var date = new Date(newDate);

//     return (
//       date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
//     );
//     // console.log(
//     // date.getFullYear() + " " +
//     // (date.getMonth() + 1) + "/" +
//     // date.getDate());
//   };

//   changeDomeStatus = async (id: number, status: number, dome_name: string) => {
//     if (status === 1) {
//       this.setState({
//         isUnpublished: true,
//         dome_name: dome_name,
//         dome_id: id,
//         dome_status: status,
//       });
//     } else {
//        await this.updateDomeStatus(id);
//       this.setState({
//         isPublished: true,
//         dome_name: dome_name,
//         dome_id: id,
//         dome_status: status,
//         messsage: "Successfully published.",
//       });
//     }
//   };

//   updateDomeStatus = async (id: number) => {
//     let data =  await this.getContext();
//     let domes = data.domeList || [];

//     const updatedDomes =  await Promise.all(
//       domes.map(async (dome: any) => {
//         if (dome.ID === id) {
//           let status = dome.addr_status == 1 ? 0 : 1;
//           return {
//             ...dome,
//             addr_status: status,
//           };
//         }
//         return dome;
//       })
//     );
//     data.domeList = updatedDomes;
//      await this.updateContext(data);

//     // Call API to publish unpublish
//      await publishUnpublishDome(id);
//   };

//   getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
//     const contextData = this.context;
//     let profile = contextData.profile;
//     const mapInstance = store.getState().address.mapInstance;
//     if (this.state.marker) {
//       this.state.marker.setMap(null);
//     }

//     let res = getMarkerLocation(lat, lng, dome_addr);
//     mapInstance.setCenter(res.center);
//     this.setState({
//       marker: res.marker,
//     });
//   };

//   getContext = async () => {
//     const context = this.context;
//     let profile = context.profile || {};
//     return profile;
//   };

//   updateContext = async (data: any) => {
//     const context = this.context;
//     context.setProfile(data);
//   };

//   closeMyDomePopup = async () => {
//     let data =  await this.getContext();
//     data.domeList = null;
//      await this.updateContext(data);
//   };

//   goToSetting = async (e, index) => {
//     let contextData =  await this.getContext();
//     contextData.current_dome = contextData.domeList[index];
//     contextData.current_dome.current_index = index;
//     contextData.domeSettingList = contextData.domeList;
//     contextData.domeList = [];
//     contextData["current_menu_name"] = "Dome";
//     contextData["go_to_setting" ||" 돔 설정"] = 1;
//      await this.updateContext(contextData);
//   };

//   domeSettingHandler = async () => {
//     let profile =  await this.getContext();
//     profile.loader = true;
//      await this.updateContext(profile);
//     this.setState({
//       dome_setting_active: "active",
//       dome_editting_active: "",
//       dome_admin_active: "",
//       setting: true,
//       editing: false,
//       admin: false,
//       data: true,
//       noData: false,
//     });

//     let msgResp =  await getMessagesList(this.state.current_id);
//     const domeList =  await listMyDomes();
//     profile =  await this.getContext();
//     profile.loader = false;
//     profile.domeList = [];
//     profile.domeSettingList = [];
//     profile.domeSettingList = domeList;
//     if (this.state.current_id) {
//       const current_dome = domeList.find(
//         (item) => item.ID === this.state.current_id
//       );
//       const current_index = domeList.findIndex(
//         (item) => item.ID === this.state.current_id
//       );

//       profile.edit_current_dome = null;
//       profile.current_dome = null;
//       profile.admin_dome = null;
//       profile.msgList = msgResp.data;
//       profile.current_dome = current_dome;
//       profile.current_dome.current_index = current_index;
//     }

//      await this.updateContext(profile);
//   };

//   //   _initProfile(status, name = '') {
//   //     const context = this.context;
//   //     let profileData = context.profile;
//   //     profileData.register = status;
//   //     profileData.name = name;
//   //     // profileData.dom_map = true;
//   //     // profileData.dome_lat = this.state.dome_lat;
//   //     // profileData.dome_lng = this.state.dome_lng;
//   //     // profileData.dome_name = this.state.dom_name ? this.state.dom_name : '';
//   //     // profileData.zoom = 20;

//   //     context.setProfile(profileData);
//   //   }

//   renewDomHandler = (dome) => {
//     // const PageName = e.target.getAttribute('data-name');
//     const PageName = "";
//     const context = this.context;
//     let profile = context.profile;
//     let profile1 = { ...profile };

//     // this._initProfile(true, PageName);
//     profile.dome_error = false;
//     profile.register = true;
//     profile.showItems = true;
//     profile.iscart = false;
//     profile.name = "Renew";
//     // profile.enter_for_location = false;
//     context.setProfile(profile);
//     // this.setState({
//     //   dome_error: false,
//     // });

//     // const inserted_dome_id = res.data.inserted_dome_id;
//     // profile.inserted_dome_id = inserted_dome_id;
//     profile.inserted_dome_id = dome.ID;
//     // profile.dome_name = this.state.dom_name;
//     profile.dome_name = dome.addr_name;
//     profile.dome_lat = dome.location.x;
//     profile.dome_lng = dome.location.y;
//     profile.domeList = null;
//     context.setProfile(profile);
//     console.log("qqqq",this.props.total)

//     this.setState({
//       total: 18.0,
//     })

//   };

//   renderDomeName = (status, name, suffix, file) => {
//     if (status === 1) {
//       if (file) {
//         return name;
//       } else {
//         if (name && suffix) {
//           return `${name}${suffix}`;
//         }
//       }
//     }
//     return "";
//   };

//   render() {
//     const context = this.context;
//     let toalDomeList = 0;
//     const domeList = context.profile.domeList || [];
//     let loader = context.profile.loader || false;
//     toalDomeList = domeList.length;
//     let isPublished = this.state.isPublished || false;
//     let isUnpublished = this.state.isUnpublished || false;

//     return (
//       <>
//         {/* My Dome Add. card */}

//         <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
//           {loader && (
//             <Spin
//               size="large"
//               style={{ marginTop: "2em", marginBottom: "2em" }}
//             />
//           )}
//           {toalDomeList ? (
//             <div className="second-card">
//               <div className="fx-jcsb-aic mb-20">
//                 <h4 className="text-primary mb-0 font-bold">
//                   {" "}
//                   &#62; My Dome Address
//                 </h4>
//                 <i onClick={this.closeMyDomePopup} className="iconx cursor">
//                   <img src={closeIcon} alt="Info" />
//                 </i>
//               </div>

//               <div className="domeAddContainer scrollSection">
//                 {domeList.map((dome, index) => (
//                   <div
//                     key={dome.ID}
//                     className={
//                       "let-long-loop domeAddLoop " +
//                       (this.state.dome_id === dome.ID ? "active" : "")
//                     }
//                   >
//                     <div className="let-long-loop__left">
//                       <div className="let-long-loop__left__row mb-10">
//                         <div className="let-long-loop__left__row__info">
//                           <h3 className="font-bold size14 mb-0">
//                             {/* <input type="checkbox" /> */}
//                             {dome.addr_name}{" "}
//                             <span className="text-muted">
//                               @
//                               {/* {dome.dome_status === 1 ? dome.dome_name : ''} */}
//                               {this.renderDomeName(
//                                 dome.dome_status,
//                                 dome.dome_name,
//                                 dome.dome_name_suffix,
//                                 dome.file
//                               )}
//                             </span>
//                           </h3>
//                           <div className="let-long-loop__left__row__info__row">
//                             <i
//                               onClick={(e) =>
//                                 this.getLocation(
//                                   dome.location.y,
//                                   dome.location.x,
//                                   dome.addr_name,
//                                   e
//                                 )
//                               }
//                               className="let-long-loop__left__row__icon"
//                             >
//                               <img src={locationIcon} alt="Location" />
//                             </i>
//                             <div className="let-long-loop__left__row__info__row__info size10">
//                               <div className="mr-10">
//                                 <p className="mb-0">Latitude</p>
//                                 <p className="mb-0">
//                                   {dome.location.x.toFixed(11)}
//                                 </p>
//                               </div>
//                               <div className="mb-0">
//                                 <p className="mb-0">Longitude</p>
//                                 <p className="mb-0">
//                                   {dome.location.y.toFixed(11)}
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="size12">
//                         <p className="mb-3">
//                           Dome Address:{" "}
//                           <em className="text-primary">
//                             {dome.addr_status ? (
//                               <span
//                                 translate="yes"
//                                 title="게시"
//                                 className="published"
//                               >
//                                 {"Published"}
//                               </span>
//                             ) : (
//                               <span
//                                 translate="yes"
//                                 title="게시되지 않음"
//                                 className="unpublished"
//                               >
//                                 {"Unpublished"}
//                               </span>
//                             )}
//                           </em>
//                         </p>
//                         <p className="mb-3">
//                           Dome settings:{" "}
//                           <em className="text-success">
//                             {dome.setting_status ? (
//                               <span
//                                 translate="yes"
//                                 title="업로드"
//                                 className="approved"
//                               >
//                                 {"Uploaded"}
//                               </span>
//                             ) : (
//                               <span
//                                 translate="yes"
//                                 title="업로드되지 않음"
//                                 className="notSubmitted"
//                               >
//                                 {"Not Uploaded"}
//                               </span>
//                             )}
//                           </em>
//                         </p>
//                         <p className="mb-0">
//                           Go to &#62;{" "}
//                           <h6
//                             style={{
//                               display: "inline",
//                               fontSize: "10px",
//                               cursor: "pointer",

//                             }}
//                             onClick={(e) => this.goToSetting(e, index)}
//                             // href="#"
//                             className="text-default text-underline"
//                           >
//                             Dome settings
//                           </h6>
//                         </p>
//                       </div>
//                     </div>

//                     <div className="let-long-loop__right">
//                       <div className="mb-10">
//                         <p className="mb-3 size12">
//                           Auto-renews on {this.dateFormate(dome.valid_till)}{" "}
//                         </p>
//                         <div className="fx-jcfe">
//                           <button
//                              disabled={false}
//                             onClick={
//                                  () => this.renewDomHandler(dome)
//                             }
//                             className="ant-btn primary-ghost-btn xs rd-5"
//                           >
//                             Renew now
//                           </button>
//                         </div>
//                       </div>

//                       <div className="totalAmt">
//                         <button
//                           disabled={!dome.setting_status}
//                           onClick={() =>
//                             this.changeDomeStatus(
//                               dome.ID,
//                               dome.addr_status,
//                               dome.addr_name
//                             )
//                           }
//                           className={
//                             !dome.addr_status
//                               ? "ant-btn yellow-gradient xs rd-5 publishBtn"
//                               : "ant-btn yellow-gradient xs rd-5 unPublishBtn"
//                           }
//                         >
//                           {!dome.addr_status ? "Publish" : "Unpublish"}
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             ""
//           )}
//         </Row>

//         {/* Publish Modal UI */}
//         <div
//           className={!isPublished ? "overlay" : "overlay opened"}
//           onClick={this.publishModalToggle}
//         ></div>
//         <div
//           className={
//             !isPublished ? "custom-modal xs" : "custom-modal xs opened"
//           }
//         >
//           <div
//             className="custom-modal__close"
//             onClick={this.publishModalToggle}
//           ></div>
//           <div className="custom-modal__body">
//             <h4>{this.state.dome_name}</h4>
//             <p>{this.state.messsage}</p>
//             <p className="mb-0">
//               Thank you! <br />
//               LoaMoa Team
//             </p>
//           </div>
//         </div>

//         {/* Unpublish Modal UI */}
//         <div
//           className={!isUnpublished ? "overlay" : "overlay opened"}
//           onClick={this.unPublishModalToggle}
//         ></div>
//         <div
//           className={
//             !isUnpublished ? "custom-modal xs" : "custom-modal xs opened"
//           }
//         >
//           <div
//             className="custom-modal__close"
//             onClick={this.unPublishModalToggle}
//           ></div>
//           <div className="custom-modal__body">
//             <p>
//               Are you sure if {this.state.dome_name} Dome Add is unpublished?
//             </p>
//             <div className="fx-jcsb">
//               <button
//                 onClick={() => this.unPublish("cancle")}
//                 className="ant-btn primary-ghost-btn xs rd-5"
//               >
//                 cancel
//               </button>
//               <button
//                 onClick={() => this.unPublish("yes")}
//                 className="ant-btn yellow-gradient xs rd-5"
//               >
//                 Yes
//               </button>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default Mydome;

