import React, { useState, memo, useMemo, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { candySmall, icon_male, icon_female, icon_other } from '../../assets';
import exclamationMark from '../../assets/exclamationMark.svg';
import { baseURL } from '../../lib/config';
import PropTypes from 'prop-types';

function RankItem(props) {
  const {
    rank,
    profilePicUrl,
    name,
    candyCount,
    onClick,
    profileComplete,
    gender,
    index,
    selected,
  } = props;

  const [profilePicture, setProfilePicture] = useState(
    profilePicUrl ? baseURL + '/' + profilePicUrl : ''
  );

  useEffect(() => {
    if (profilePicUrl) {
      setProfilePicture(baseURL + '/' + profilePicUrl);
    }
  }, [profilePicUrl]);

  let altProfilePicture = icon_other;
  if (gender === 'male') {
    altProfilePicture = icon_male;
  } else if (gender === 'female') {
    altProfilePicture = icon_female;
  }

  const onProfilePictureError = () => {
    setProfilePicture(altProfilePicture);
  };

  const onClickHandler = () => {
    onClick(index);
  };

  return (
    <div onClick={onClickHandler} style={{ cursor: 'pointer' }}>
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        className={selected ? 'candyEvent-rank candyEvent-rank-selected' : 'candyEvent-rank'}
      >
        <Col span={19}>
          <Row type="flex" align="middle">
            <Col span={2} className="rank-container">
              <Typography.Text className="rank-number">{rank}.</Typography.Text>
            </Col>
            <Col span={22}>
              <Row type="flex" align="middle">
                <img className="profile-img" src={profilePicture} onError={onProfilePictureError} />
                <Typography.Text className="rank-name">{name}</Typography.Text>
                {!profileComplete && <img className="candy-img" src={exclamationMark} />}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row type="flex" align="middle">
            <Typography.Text className="rank-number">{candyCount}</Typography.Text>
            <img className="candy-img" src={candySmall} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

RankItem.propTypes = {
  rank: PropTypes.number,
  index: PropTypes.number,
  profilePicUrl: PropTypes.string,
  name: PropTypes.string,
  candyCount: PropTypes.number,
  onClick: PropTypes.func,
  profileComplete: PropTypes.bool,
  gender: PropTypes.oneOf(['male', 'female', 'other']),
  selected: PropTypes.bool,
};

RankItem.defaultProps = {
  onClick: () => {
    console.log('missing logic');
  },
  rank: 1,
  profilePicUrl: '',
  candyCount: 0,
  profileComplete: true,
};

export default memo(RankItem);
