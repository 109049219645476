import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Typography, Switch, Icon, Divider, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { menuConstants } from '../../../constants/menuConstants';
import {
  Canvas,
  BackgroundColors,
  BackgroundImages,
  TextInput,
  UploadFile,
  PortraitOrLandscape,
  PreviewModal,
  BackButtonLeftText,
} from '../../../components';
import * as treasureActions from '../../../actions/treasureActions';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { editingTitle } from '../../../lib/strings/treasureCreation';
import * as adMakerServices from '../../../services/adMakerServices';
import { baseURL } from '../../../lib/config';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

function AdMakerNew(props) {
  const { language, handleClose, uploadFile, handleInput, treasure, onAdvertisementChange } = props;

  const [title, setTitle] = useState<any>();
  const [description, setDescription] = useState();
  const [contactDetails, setContactDetails] = useState();
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState();
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState('');
  const [selectedBackgroundFile, setSelectedBackgroundFile] = useState();
  const [startExporting, setStartExporting] = useState(false);
  const [landscapeOrPortrait, setLandscapeOrPortrait] = useState<'portrait' | 'landscape'>(
    'landscape'
  );
  const [showLogo, setShowLogo] = useState(false);
  const [logoImageState, setLogoImageState] = useState();
  const [showModal, setShowModal] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [dimensions, setDimensions] = useState({
    title: {
      x: 10,
      y: 10,
      rotation: 0,
    },
    logo: {
      width: 100,
      height: 100,
      x: 10,
      y: 55,
      rotation: 0,
    },
    description: {
      x: 10,
      y: 160,
      rotation: 0,
    },
    contactDetails: {
      x: 10,
      y: 220,
      rotation: 0,
    },
  });

  const dimensionsHandler = (key, value) => {
    setDimensions((currentState) => {
      return { ...currentState, [key]: value };
    });
  };

  const backgroundColorHandler = (color) => {
    setSelectedBackgroundColor(color);
    setSelectedBackgroundImage(undefined);
    setSelectedBackgroundFile(undefined);
  };

  const backgroundImageHandler = (image, file) => {
    setSelectedBackgroundColor(undefined);
    setSelectedBackgroundImage(image);
    setSelectedBackgroundFile(file);
  };

  const showModalHandler = () => {
    setShowModal(true);
    exportImage();
  };

  const exportImage = () => {
    setStartExporting(true);
  };

  const getImage = async ({ file, fileUrl }) => {
    setStartExporting(false);

    // API call from PointCreation
    if (!showModal) {
      saveDetails();
      treasure.onAdvertisementChange({ file, fileUrl });
      closeMakeNew();
    } else {
      setPreviewImageUrl(fileUrl);
    }
  };

  const onLogoChange = async ({ file, fileUrl }) => {
    setLogoImageState(fileUrl);

    // API call from PointCreation
    treasure.onLogoChange({ file, fileUrl });
  };

  useEffect(() => {
    if (treasure.logoUrl !== logoImageState) {
      setLogoImageState(treasure.logoUrl);
    }
  }, [treasure.logoUrl]);

  const scaleFactor = 4;
  const landscape = { width: 1920, height: 1080 };
  const portrait = { width: 1080, height: 1920 };
  const pixelRatio = 4; // set to 2 if image size is to large

  const canvasDimensions = landscapeOrPortrait === 'landscape' ? landscape : portrait;

  const closeMakeNew = () => {
    handleInput('uploadReuseMakeNew', 'upload');
    handleClose();
  };

  const saveDetails = async () => {
    const formData = new FormData();
    formData.append('showLogo', showLogo ? '1' : '0');
    formData.append('landscapeOrPortrait', landscapeOrPortrait);
    formData.append('dimensions', JSON.stringify(dimensions));
    title && formData.append('title', JSON.stringify(title));
    description && formData.append('description', JSON.stringify(description));
    contactDetails && formData.append('details', JSON.stringify(contactDetails));
    selectedBackgroundColor && formData.append('backgroundColor', selectedBackgroundColor);
    if (selectedBackgroundImage) {
      if (selectedBackgroundFile) {
        formData.append('file', selectedBackgroundFile);
      } else {
        formData.append('backgroundImageLocal', selectedBackgroundImage);
      }
    }

    await adMakerServices.postAdMakerDetails(treasure.ID);
    const data = await adMakerServices.putAdMakerDetails(treasure.ID, formData);
    handleInput('useAdKitMakeNew', true);
    handleInput('adKitDataDetails', data);
  };

  const fetchDataOnEdit = async () => {
    if (treasure.isEditing) {
      const adKitData = await adMakerServices.getAdMakerDetails(treasure.ID);
      if (adKitData !== 4000000) {
        saveApiToState(adKitData);
        return adKitData;
      } else {
        return null;
      }
    } else if (treasure.useAdKitMakeNew) {
      saveApiToState(treasure.adKitDataDetails);
    }
  };

  const saveApiToState = (adKitData) => {
    if (adKitData) {
      setShowLogo(adKitData.showLogo === 1 ? true : false);
      setLandscapeOrPortrait(adKitData.landscapeOrPortrait);
      setTitle(adKitData.title);
      setDescription(adKitData.description);
      setContactDetails(adKitData.details);
      setDimensions(adKitData.dimensions);
      adKitData.backgroundImageUrl &&
        setSelectedBackgroundImage(`${baseURL}${adKitData.backgroundImageUrl}`);
      adKitData.backgroundImageLocal && setSelectedBackgroundImage(adKitData.backgroundImageLocal);
      adKitData.backgroundColor && setSelectedBackgroundColor(adKitData.backgroundColor);
    }
  };

  const makeNewQuery = useQuery(['adKitMakeNew', treasure.ID], fetchDataOnEdit, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  const onPreviewCancel = () => {
    setShowModal(false);
    setPreviewImageUrl('');
  };

  return (
    <Row className="make-new-wrapper" style={{ paddingBottom: '45px' }}>
      <div className="scrollable">
        <BackButtonLeftText label={editingTitle[language]} onClick={closeMakeNew} />

        <BackgroundColors language={language} onChange={backgroundColorHandler} />

        {!makeNewQuery.isLoading && (
          <BackgroundImages
            language={language}
            onChange={backgroundImageHandler}
            orientation={landscapeOrPortrait}
            initialImage={selectedBackgroundImage}
            colorIsSelected={selectedBackgroundColor}
          />
        )}

        <Row style={{ marginLeft: '1em', marginRight: '1em' }}>
          <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
        </Row>

        {/* <PortraitOrLandscape
          onChange={(value) => {
            setLandscapeOrPortrait(value);
          }}
          value={landscapeOrPortrait}
        />

        <Row type="flex" justify="center" align="middle">
          <Canvas
            backgroundColor={selectedBackgroundColor}
            backgroundImage={selectedBackgroundImage}
            title={title}
            description={description}
            contactDetails={contactDetails}
            startExporting={startExporting}
            onExport={getImage}
            canvasSize={canvasDimensions}
            scaleFactor={scaleFactor}
            pixelRatio={pixelRatio}
            logoImageUrl={logoImageState}
            showLogo={showLogo}
            dimensions={dimensions}
            dimensionsHandler={dimensionsHandler}
          />
        </Row> */}

        <Row style={{ marginLeft: '1em', marginTop: '2em', marginRight: '1em' }}>
          <Col>
            <Typography.Text className="make-new-section-text">
              {treasureCreation.adKitLogo[language]}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="start"
          align="middle"
          style={{
            marginLeft: '1em',
            marginTop: '1em',
            marginRight: '1em',
            position: 'relative',
          }}
        >
          <Col>
            <Switch
              style={{
                // position: 'absolute',
                top: '0.5em',
                left: '3em',
                marginRight: '5em',
              }}
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={showLogo}
              onChange={setShowLogo}
            />
          </Col>

          <Col>
            <UploadFile rowJustify="start" valueUrl={treasure.logoUrl} onChange={onLogoChange} />
          </Col>
        </Row>

        {!makeNewQuery.isLoading && (
          <>
            <TextInput
              label={treasureCreation.title[language]}
              placeholder={treasureCreation.addTitle[language]}
              type="input"
              onChange={setTitle}
              initialTextSettings={title}
            />
            <TextInput
              label={treasureCreation.description[language]}
              placeholder={treasureCreation.addMessage[language]}
              type="textArea"
              onChange={setDescription}
              initialTextSettings={description}
            />
            <TextInput
              label={treasureCreation.contactDetails[language]}
              placeholder={treasureCreation.addContactDetails[language]}
              type="textArea"
              onChange={setContactDetails}
              initialTextSettings={contactDetails}
              popoverOffset={[-80, -360]}
            />
          </>
        )}
        <Row type="flex" justify="center" style={{ marginTop: '44px' }}>
          <Button
            onClick={showModalHandler}
            className="buttons treasure-button-width transparent-btn-black"
          >
            <span style={{ textDecoration: 'underline' }}>
              {treasureCreation.preview[language]}
            </span>
          </Button>
          <div style={{ width: '32px' }}></div>
          <Button onClick={exportImage} className="buttons yellow-gradient treasure-button-width">
            {treasureCreation.save[language]}
          </Button>
        </Row>
        <PreviewModal
          imageUrl={previewImageUrl}
          visible={showModal}
          onCancel={onPreviewCancel}
          landscapeOrPortrait={landscapeOrPortrait}
        />
        {makeNewQuery.isLoading && (
          <Row type="flex" justify="center" style={{ padding: '2em' }}>
            <Spin size="large" />
          </Row>
        )}
      </div>
      <div
        className="canvas-fake-submenu"
        style={{
          width: canvasDimensions.width / scaleFactor + 32,
          right: -canvasDimensions.width / scaleFactor - 32 - 16,
        }}
      >
        <PortraitOrLandscape
          onChange={(value) => {
            setLandscapeOrPortrait(value);
          }}
          value={landscapeOrPortrait}
        />

        <Row type="flex" justify="center" align="middle">
          <Canvas
            backgroundColor={selectedBackgroundColor}
            backgroundImage={selectedBackgroundImage}
            title={title}
            description={description}
            contactDetails={contactDetails}
            startExporting={startExporting}
            onExport={getImage}
            canvasSize={canvasDimensions}
            scaleFactor={scaleFactor}
            pixelRatio={pixelRatio}
            logoImageUrl={logoImageState}
            showLogo={showLogo}
            dimensions={dimensions}
            dimensionsHandler={dimensionsHandler}
          />
        </Row>
      </div>
    </Row>
  );
}

AdMakerNew.propTypes = {
  logoImageUrl: PropTypes.string,
  ID: PropTypes.number,
  uploadFile: PropTypes.func,
  handleInput: PropTypes.func,
  treasure: PropTypes.object,
  onAdvertisementChange: PropTypes.func,
};

AdMakerNew.defaultProps = {
  uploadFile: () => {},
  onAdvertisementChange: () => {},
};

const mapStateToProps = (state) => ({
  ID: state.treasure.ID,
  language: state.authentication.language,
  uploadFile: state.treasure.uploadFile, //should be send by normal props not redux
  treasure: state.treasure,
  onAdvertisementChange: state.treasure.onAdvertisementChange, //should be send by normal props not redux
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
      handleInput: treasureActions.handleInput,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdMakerNew);
