import React, { useState } from 'react';
import { Row } from 'antd';
import Contest from './Contest';

interface Props {
  contestList: any;
}

const ContestList = (props: Props) => {
  const [contestID, setContestID] = useState(null);

  const handleSelectedContest = (id: number) => {
    setContestID(id);
  };

  return (
    <Row type="flex" justify="center" style={{ width: '100%' }}>
      {props.contestList
        .sort((a: any, b: any) => (a.ID < b.ID ? 1 : -1))
        .map((contest: any) => {
          return (
            <Contest
              all={contest}
              characterName={contest.characterName}
              selected={contest.ID === contestID}
              key={contest.ID}
              ID={contest.ID}
              startDate={contest.startDate}
              endDate={contest.endDate}
              name={contest.name}
              handleSelectedContest={handleSelectedContest}
              image={contest.picturePath}
              characterDetails={contest.characterDetails}
              title={contest.title}
            />
          );
        })}
    </Row>
  );
};

export default ContestList;
