import { Row } from 'antd';
import React, { useEffect } from 'react';
import closeIcon from '../../../../../../src/assets/closeXsmall.svg';

type ImagePreviewModalProps = {
  closeModal: () => void;
  imagePreviewSource: string;
  hideMarker: boolean;
};

const ImagePreviewModal = (props: ImagePreviewModalProps) => {
  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"></h4>
            <i className="iconx cursor">
              <img
                onClick={(e) => props.closeModal()}
                src={closeIcon}
                alt="Info"
              />
            </i>
          </div>
          <div
            style={{
              border: '2px solid grey',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            {props.hideMarker ? null : (
              <div
                style={{
                  width: '0px',
                  position: 'absolute',
                  height: '0px',
                  top: '-20px',
                  borderLeft: ' 11px solid transparent',
                  borderRight: '11px solid transparent',
                  borderTop: '23px solid rgb(236, 9, 9)',
                  left: '48%',
                }}
              ></div>
            )}
            <img
              src={props.imagePreviewSource}
              style={{ width: '80%', height: '80%', margin: 'auto' }}
            />
          </div>
        </div>
      </Row>
    </>
  );
};

export default ImagePreviewModal;
