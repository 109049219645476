import React from 'react';

import { Row, Spin, notification } from 'antd';
import { throttle } from 'throttle-debounce';
import AppContext from '../../../../AppContext';

import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { connect } from 'react-redux';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import {b} from '../../../dashboard/menu/i18next'

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import {
  getNotification,
  crateNotification,
} from '../../../../services/domeService';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class DomeNotification extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      notifications: [],
      title: '',
      message: '',
      category: 1,
      general: 'English',
    };
  }

  componentDidMount = async () => {
    let profile =  await this.getContext();
    let id = profile.admin_dome.ID;
    const notifications =  await getNotification(id);
    this.setState({
      notifications: notifications,
    });
  };

  saveNotification = throttle(
    10000,
    async () => 
    {
            const { title, message, category, general } = this.state;
      if (title === '') {
        showErrorMessage(b?.t('Error'), b?.t('Title should not be an empty'));
      }
      else if (message === '') {
        showErrorMessage(b?.t('Error'), b?.t('Message should not be an empty'));
      }
      else {
        let profile =  await this.getContext();
        let id = profile.admin_dome.ID;
        let data = {
          dome_id: id,
          category: category,
          general: general,
          title: title,
          message: message,
        };
        const res =  await crateNotification(data);    
      }
      this.props.closeCreateNotification()
    },
    {
      noTrailing: true, 
    }
  );

  formHandler = (e: any) => {
    if (e.target.value.length <= 70) {
      console.log("ffff",[e.target.name])
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  render() {
    const context = this.context;
    const {translation}=this.props
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
          <div className="third-card">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                {' '}
                &#62;{translation('Create new notifications')} 
              </h4>
              <i
                onClick={this.props.closeCreateNotification}
                className="iconx cursor"
              >
                <img src={closeIcon} alt="Info" />
              </i>
            </div>

            <div className="">
              <div className="form-group">
                <label className="font-bold">{translation('Category:')} </label>
                <select
                  defaultValue={this.state.category}
                  name="category"
                  onChange={this.formHandler}
                  className="form-control"
                >
                  <option value="1">{translation('All (Picks + Joined)')}</option>
                  <option value="2">{translation('Picks')} </option>
                  <option value="3">{translation('Joined')}</option>
                </select>
              </div>
              <div className="form-group mb-20">
                <label className="font-bold">{translation('General:')}</label>
                <select
                  defaultValue={this.state.general}
                  name="general"
                  onChange={this.formHandler}
                  className="form-control"
                >
                  <option value={'Korean'}>한국어</option>
                  <option value={'English'}>English</option>
                </select>
              </div>

              <div className="form-group mb-20">
                <label className="font-bold">{translation('Title')}:</label>
                <input
                  onChange={this.formHandler}
                  name="title"
                  value={this.state.title}
                  type="text"
                  placeholder={translation("Add title:")}
                  className="inp default"
                />
              </div>

              <div className="form-group mb-20">
                <label className="font-bold">{translation('Message')}:</label>
                <textarea
                  onChange={this.formHandler}
                  name="message"
                  value={this.state.message}
                  rows={10}
                  className="inp default"
                  placeholder={translation("Add message:")}
                ></textarea>
                <div className="fx-jcfe">
                  <small
                    style={{
                      color: `${this.state.message.length < 65 ? 'lightgrey' : 'red'
                        }`,
                    }}
                  >
                    ({70 - this.state.message.length}/70)
                  </small>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button
                onClick={this.props.closeCreateNotification}
                className="ant-btn primary-ghost-btn xs mr-5"
              >
                {translation("Cancel")}
              </button>
              <button
                onClick={this.saveNotification }
                className="ant-btn purple-gradient xs ml-5"
              >
                {translation("Save")}
              </button>
            </div>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomeNotification);
