import { LanguageMap } from '../interfaces/language';
//contestants.tsx

export const contestants: LanguageMap<string> = {
  EN: 'contestants',
  KO: '참가자',
};

export const creatingIdol: LanguageMap<string> = {
  EN: 'Creating new idol',
  KO: '새로운 아이돌을 생성중입니다',
};

export const editingIdol: LanguageMap<string> = {
  EN: 'Editing idol',
  KO: '아이돌을 수정중입니다',
};

export const idolNameLabel: LanguageMap<string> = {
  EN: 'Idol Name:',
  KO: 'Idol Name:',
};

export const idolNameValidation: LanguageMap<string> = {
  EN: 'Enter name!',
  KO: 'Enter name!',
};

export const startDateLabel: LanguageMap<string> = {
  EN: 'Start Date:',
  KO: '시작 날짜',
};

export const startDateValidation: LanguageMap<string> = {
  EN: 'Select start date!',
  KO: '시작 날짜를 선택해줏요',
};

export const endDateLabel: LanguageMap<string> = {
  EN: 'End Date:',
  KO: '종료 날짜',
};

export const endDateValidation: LanguageMap<string> = {
  EN: 'Select end date!',
  KO: '종료 날짜를 선택해주세요',
};

export const characterLabel: LanguageMap<string> = {
  EN: 'Character',
  KO: '캐릭터',
};

export const characterValidation: LanguageMap<string> = {
  EN: 'Select character!',
  KO: '캐릭터를 선택해주세요',
};

export const imageLabel: LanguageMap<string> = {
  EN: 'Image',
  KO: '이미지',
};

export const imageValidation: LanguageMap<string> = {
  EN: 'Select image!',
  KO: '이미지를 선택하세요',
};

export const descriptionLabel: LanguageMap<string> = {
  EN: 'Description',
  KO: 'Description',
};

export const descriptionValidation: LanguageMap<string> = {
  EN: 'Enter description!',
  KO: 'Enter description!',
};

export const descriptionPlaceholder: LanguageMap<string> = {
  EN: 'Add text',
  KO: 'Add text',
};

export const schedule: LanguageMap<string> = {
  EN: 'Schedule',
  KO: '스케줄',
};

export const guidePostingLabel: LanguageMap<string> = {
  EN: 'Guide song posting:',
  KO: '가이드송 게시',
};

export const dateValidation: LanguageMap<string> = {
  EN: 'Enter Date!',
  KO: '날짜를 입력해주세요',
};

export const to: LanguageMap<string> = {
  EN: 'to',
  KO: 'to',
};

export const updateFailed: LanguageMap<string> = {
  EN: 'failed to update!',
  KO: '업데이트에 실패하였습니다',
};

export const creationFailed: LanguageMap<string> = {
  EN: 'failed to create!',
  KO: '생성에 실패하였습니다',
};

export const noImgMessage: LanguageMap<string> = {
  EN: 'Idol image missing!',
  KO: '아이돌 이미지가 존재하지 않습니다',
};

export const noImgDetails: LanguageMap<string> = {
  EN: 'Please upload image in .jpeg, .png, .gif or .webp format!',
  KO: '.jpeg, .png. gif, .webp 파일형식으로 이미지를 업로드해주세요',
};

export const noGuideSongMessage: LanguageMap<string> = {
  EN: 'Guide song missing!',
  KO: '가이드송이 존재하지 않습니다',
};

export const noGuideSongDetails: LanguageMap<string> = {
  EN: 'Please upload video in .mp4 format!',
  KO: '.mp4 파일형식으로 동영상을 업로드해주세요',
};

export const noLyricsMessage: LanguageMap<string> = {
  EN: 'Lyrics file missing!',
  KO: '가사 파일이 존재하지 않습니다',
};

export const noLyricsDescription: LanguageMap<string> = {
  EN: 'Please upload song lyrics file in .pdf format!',
  KO: '.pdf 파일형식으로 가사를 업로드 해주세요',
};

export const noInstrumentalAndVocalsMessage: LanguageMap<string> = {
  EN: 'Instrumental + vocals file missing!',
  KO: 'Instrumental + 보컬 파일이 존재하지 않습니다',
};

export const noInstrumentalAndVocalsDescription: LanguageMap<string> = {
  EN: 'Please upload instrumental with vocals file in .mp3 format',
  KO: '.mp4 파일형식으로 instrumental + 보컬 파일을 업로드 해주세요',
};

export const noInstrumentalMessage: LanguageMap<string> = {
  EN: 'Instrumental file missing!',
  KO: 'Instrumental 파일이 존재하지 않습니다',
};

export const noInstrumentalDescription: LanguageMap<string> = {
  EN: 'Please upload song instrumental file in .mp3 format!',
  KO: '.mp4 파일형식으로 instrumental 파일을 업로드 해주세요',
};

export const incorrectFormatMessage: LanguageMap<string> = {
  EN: 'Incorrect format',
  KO: '형식이 올바르지 않습니다',
};

export const incorrectFormatDescription: LanguageMap<string> = {
  EN:
    'For guide song, upload .mp4 file, for instrumental songs, upload .mp3 file and for lyrics, upload .pdf file.',
  KO: '가이드송과 instrumental 곡 업로드는 .mp4, 가사는 .pdf 파일형식으로 업로드 해주세요',
};

export const ApplyLabel: LanguageMap<string> = {
  EN: 'Applying:',
  KO: '지원중',
};

export const contestantVideoUploadLabel: LanguageMap<string> = {
  EN: `Contestant's Song Video Upload:`,
  KO: '참가자 노래동영상 업로드:',
};

export const qualifyingLabel: LanguageMap<string> = {
  EN: `Qualifying Round`,
  KO: '예선전:',
};

export const top10Label: LanguageMap<string> = {
  EN: `Top 10 announcement:`,
  KO: 'Top 10 발표:',
};

export const finalLabel: LanguageMap<string> = {
  EN: 'Final Round',
  KO: '결승전',
};

export const attachmentsLabel: LanguageMap<string> = {
  EN: 'Attachments',
  KO: '첨부파일',
};

export const guideSongVideosLabel: LanguageMap<string> = {
  EN: 'Guide Song Videos',
  KO: '가이드송 동영상',
};

export const noFileSelected: LanguageMap<string> = {
  EN: 'No file selected',
  KO: '선택된 파일이 없습니다',
};

export const guideSong1Label: LanguageMap<string> = {
  EN: 'Guide Song 1: (MP4)',
  KO: '가이드송 1:',
};

export const guideSong2Label: LanguageMap<string> = {
  EN: 'Guide Song 2 (MP4):',
  KO: '가이드송 2 (선택사항):',
};

export const otherFilesLabel: LanguageMap<string> = {
  EN: 'Other files',
  KO: '다른 파일',
};

export const music1Label: LanguageMap<string> = {
  EN: 'Music - instrumentals + vocals (MP3):',
  KO: '음악 - instrumentals + 보컬',
};

export const music2Label: LanguageMap<string> = {
  EN: 'Music - instrumentals only (MP3):',
  KO: '음악 – instrumentals',
};

export const lyricsLabel: LanguageMap<string> = {
  EN: 'Lyrics (PDF):',
  KO: '가사(PDF):',
};

export const browseButton: LanguageMap<string> = {
  EN: 'Browse',
  KO: '검색',
};

export const prizesLabel: LanguageMap<string> = {
  EN: 'Prizes',
  KO: '상금',
};

export const firstPlacePrize: LanguageMap<string> = {
  EN: '1st place:',
  KO: '1등:',
};

export const prizeValidation: LanguageMap<string> = {
  EN: 'Enter prize!',
  KO: '상금을 입력해주세요',
};

export const secondPlacePrize: LanguageMap<string> = {
  EN: '2nd place:',
  KO: '2등:',
};

export const thirdPlacePrize: LanguageMap<string> = {
  EN: '3rd place:',
  KO: '3등',
};

export const saveButton: LanguageMap<string> = {
  EN: 'Save',
  KO: '저장',
};

export const cancelButton: LanguageMap<string> = {
  EN: 'Cancel',
  KO: '취소',
};

export const createIdolButton: LanguageMap<string> = {
  EN: 'Create a new idol',
  KO: 'Create a new idol',
};

export const createCharacterStoryButton: LanguageMap<string> = {
  EN: 'Create new character story',
  KO: 'Create new character story',
};

export const mottosLabel: LanguageMap<string> = {
  EN: 'Mottos: ',
  KO: 'Mottos: ',
};

export const jobLabel: LanguageMap<string> = {
  EN: 'Job: ',
  KO: 'Job: ',
};

export const specialitiesLabel: LanguageMap<string> = {
  EN: 'Specialities: ',
  KO: 'Specialities: ',
};

export const scheduleLabel: LanguageMap<string> = {
  EN: 'Schedule',
  KO: 'Schedule',
};

export const guideSongLabel: LanguageMap<string> = {
  EN: 'Guide song',
  KO: 'Guide song',
};

export const applyingLabel: LanguageMap<string> = {
  EN: 'Applying',
  KO: 'Applying',
};

export const uploadingLabel: LanguageMap<string> = {
  EN: `Uploading Contestant's song video`,
  KO: `Uploading Contestant's song video`,
};

export const qualifying2Label: LanguageMap<string> = {
  EN: `Qualifying Round`,
  KO: `Qualifying Round`,
};

export const top5announcemetLabel: LanguageMap<string> = {
  EN: `Top 5 Announcement`,
  KO: `Top 5 Announcement`,
};

export const finalRound: LanguageMap<string> = {
  EN: 'Final Round',
  KO: 'Final Round',
};

export const prizesText: LanguageMap<string> = {
  EN: 'Prizes',
  KO: 'Prizes',
};

export const firstPlaceText: LanguageMap<string> = {
  EN: '1st Place',
  KO: '1st Place',
};

export const secondPlaceText: LanguageMap<string> = {
  EN: '2nd Place',
  KO: '2nd Place',
};

export const thirdPlaceText: LanguageMap<string> = {
  EN: '3rd Place',
  KO: '3rd Place',
};

export const reviewButton: LanguageMap<string> = {
  EN: 'Reviews',
  KO: 'Reviews',
};

export const contestantsButton: LanguageMap<string> = {
  EN: 'Contestants',
  KO: 'Contestants',
};

export const pendingReviewsText: LanguageMap<string> = {
  EN: 'pending reviews',
  KO: 'pending reviews',
};

export const rejectButton: LanguageMap<string> = {
  EN: 'Reject',
  KO: 'Reject',
};

export const approveButton: LanguageMap<string> = {
  EN: 'Approve',
  KO: 'Approve',
};

export const approvedButton: LanguageMap<string> = {
  EN: 'Approved',
  KO: 'Approved',
};

export const uploadMP4: LanguageMap<String> = {
  EN: 'Please upload .mp4 file.',
  KO: 'Please upload .mp4 file.',
};

export const uploadMP3: LanguageMap<String> = {
  EN: 'Please upload .mp3 file.',
  KO: 'Please upload .mp3 file.',
};

export const uploadPDF: LanguageMap<String> = {
  EN: 'Please upload .pdf file.',
  KO: 'Please upload .pdf file.',
};

export const guideSongText: LanguageMap<String> = {
  EN: 'Guide song',
  KO: 'Guide song',
};

export const freeChoiceVideoText: LanguageMap<String> = {
  EN: 'Free choice video',
  KO: 'Free choice video',
};

export const file: LanguageMap<String> = {
  EN: 'File:',
  KO: 'File:',
};

export const guideSongVideo: LanguageMap<String> = {
  EN: 'Guide song video',
  KO: 'Guide song video',
};

export const music: LanguageMap<String> = {
  EN: 'Music',
  KO: 'Music',
};

export const instrumental: LanguageMap<String> = {
  EN: 'Instrumental',
  KO: 'Instrumental',
};

export const lyrics: LanguageMap<String> = {
  EN: 'Lyrics',
  KO: 'Lyrics',
};

export const finished: LanguageMap<String> = {
  EN: 'Finished',
  KO: 'Finished',
};

export const contestantNumber: LanguageMap<String> = {
  EN: 'CONTESTANT',
  KO: 'CONTESTANT',
};

export const contestantsNumber: LanguageMap<String> = {
  EN: 'CONTESTANTS',
  KO: 'CONTESTANTS',
};

export const pendingReview: LanguageMap<String> = {
  EN: 'PENDING REVIEW',
  KO: 'PENDING REVIEW',
};

export const pendingReviews: LanguageMap<String> = {
  EN: 'PENDING REVIEWS',
  KO: 'PENDING REVIEWS',
};

export const review: LanguageMap<String> = {
  EN: 'REVIEW',
  KO: 'REVIEW',
};

export const username: LanguageMap<String> = {
  EN: 'Username:',
  KO: 'Username:',
};

export const firstName: LanguageMap<String> = {
  EN: 'First name:',
  KO: 'First name:',
};

export const lastName: LanguageMap<String> = {
  EN: 'Last name:',
  KO: 'Last name:',
};

export const applicationDate: LanguageMap<String> = {
  EN: 'Date of application:',
  KO: 'Date of application:',
};

export const freeSongDate: LanguageMap<String> = {
  EN: 'Date of Free song upload:',
  KO: 'Date of Free song upload:',
};

export const guideSongDate: LanguageMap<String> = {
  EN: 'Date of Guide song upload:',
  KO: 'Date of Guide song upload:',
};

export const email: LanguageMap<String> = {
  EN: 'Email:',
  KO: 'Email:',
};

export const titleLabel: LanguageMap<string> = {
  EN: 'Title:',
  KO: 'Title:',
};

export const titleValidation: LanguageMap<string> = {
  EN: 'Enter Title!',
  KO: 'Enter Title!',
};

export const titlePlaceholder: LanguageMap<string> = {
  EN: 'Add title',
  KO: 'Add title',
};

export const uploadImage: LanguageMap<string> = {
  EN: 'Upload image!',
  KO: 'Upload image!',
};

export const months = {
  EN: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  KO: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const final: LanguageMap<string> = {
  EN: 'Final',
  KO: 'Final',
};

export const qualifying: LanguageMap<string> = {
  EN: 'Qualifying',
  KO: 'Qualifying',
};

export const winners: LanguageMap<string> = {
  EN: 'Winners',
  KO: 'Winners',
};

export const editIdolBanner: LanguageMap<string> = {
  EN: 'Edit idol banner',
  KO: 'Edit idol banner',
};

export const banner: LanguageMap<string> = {
  EN: 'BANNER',
  KO: 'BANNER',
};

export const title: LanguageMap<string> = {
  EN: 'Title:',
  KO: 'Title:',
};

export const subtitle: LanguageMap<string> = {
  EN: 'Subtitle:',
  KO: 'Subtitle:',
};

export const image: LanguageMap<string> = {
  EN: 'Image:',
  KO: 'Image:',
};

export const moreInfo: LanguageMap<string> = {
  EN: 'MORE INFO',
  KO: 'MORE INFO',
};

export const title1: LanguageMap<string> = {
  EN: 'Title 1',
  KO: 'Title 1',
};

export const title2: LanguageMap<string> = {
  EN: 'Title 2',
  KO: 'Title 2',
};

export const description: LanguageMap<string> = {
  EN: 'Description',
  KO: 'Description',
};

export const title3: LanguageMap<string> = {
  EN: 'Title 3',
  KO: 'Title 3',
};

export const title4: LanguageMap<string> = {
  EN: 'Title 4',
  KO: 'Title 4',
};

export const placeholderTitle: LanguageMap<string> = {
  EN: 'Add title',
  KO: 'Add title',
};

export const placeholderSubtitle: LanguageMap<string> = {
  EN: 'Add subtitle',
  KO: 'Add subtitle',
};

export const placeholderDescription: LanguageMap<string> = {
  EN: 'Add description',
  KO: 'Add description',
};

export const save: LanguageMap<string> = {
  EN: 'Save',
  KO: 'Save',
};

export const cancel: LanguageMap<string> = {
  EN: 'Cancel',
  KO: 'Cancel',
};

export const uploadingFilesPleaseWait: LanguageMap<string> = {
  EN: 'Uploading files, please wait... ',
  KO: 'Uploading files, please wait... ',
};

export const bannerPopup: LanguageMap<string> = {
  EN:
    'Before you select the other language, save changes for this one. Otherwise all unsaved changes will be lost. ',
  KO:
    'Before you select the other language, save changes for this one. Otherwise all unsaved changes will be lost. ',
};

export const confirm: LanguageMap<string> = {
  EN: 'Confirm',
  KO: 'Confirm',
};
