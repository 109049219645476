import clinic1 from './clinic1.JPG';
import clinic2 from './clinic2.JPG';
import clinic3 from './clinic3.JPG';
import clinic4 from './clinic4.JPG';
import clinic5 from './clinic5.JPG';
import clinic6 from './clinic6.JPG';
import clinic7 from './clinic7.JPG';
import clinic8 from './clinic8.JPG';
import clinic9 from './clinic9.JPG';
import clinic10 from './clinic10.JPG';

import clinic1_1 from './clinic1.1.JPG';
import clinic2_1 from './clinic2.1.JPG';
import clinic3_1 from './clinic3.1.JPG';
import clinic4_1 from './clinic4.1.JPG';
import clinic5_1 from './clinic5.1.JPG';
import clinic6_1 from './clinic6.1.JPG';
import clinic7_1 from './clinic7.1.JPG';
import clinic8_1 from './clinic8.1.JPG';
import clinic9_1 from './clinic9.1.JPG';
import clinic10_1 from './clinic10.1.JPG';

export const clinicportrait = [
  { id: 1, src: clinic1 },
  { id: 2, src: clinic2 },
  { id: 3, src: clinic3 },
  { id: 4, src: clinic4 },
  { id: 5, src: clinic5 },
  { id: 6, src: clinic6 },
  { id: 7, src: clinic7 },
  { id: 8, src: clinic8 },
  { id: 9, src: clinic9 },
  { id: 10, src: clinic10 },
];

export const cliniclandscape = [
  { id: 1, src: clinic1_1 },
  { id: 2, src: clinic2_1 },
  { id: 3, src: clinic3_1 },
  { id: 4, src: clinic4_1 },
  { id: 5, src: clinic5_1 },
  { id: 6, src: clinic6_1 },
  { id: 7, src: clinic7_1 },
  { id: 8, src: clinic8_1 },
  { id: 9, src: clinic9_1 },
  { id: 10, src: clinic10_1 },
];
