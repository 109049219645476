import { contestConstants } from '../constants/contestConstants';

export const initialStateContest = {
  isEditing: false,
  isCreating: false,
  guideSongs: new Array(),
  guidelineIDs: new Array(),
  needsFetch: false,
};

export const contest = (state = initialStateContest, action: any) => {
  switch (action.type) {
    case contestConstants.CONTEST_ID:
      return {
        ...state,
        ID: action.ID,
        isCreating: true,
      };
    case contestConstants.UPDATE_INPUT:
      return {
        ...state,
        [action.param]: action.value,
      };
    case contestConstants.EDIT_CONTEST:
      return {
        isEditing: true,
        ...action.contest,
      };
    case contestConstants.CONTEST_IMAGE:
      return {
        ...state,
        imgUrl: action.imgUrl,
        imgFile: action.imgFile,
      };
    case contestConstants.FETCH_CONTESTS:
      return {
        ...state,
        needsFetch: action.needsFetch,
      };
    case contestConstants.STORE_FILES:
      return {
        ...state,
        [action.typeOfFile]: action.file,
      };
    case contestConstants.PURGE_CONTEST_DATA:
      return initialStateContest;
    case contestConstants.STORE_GUIDELINE_SONG_ID:
      return {
        ...state,
        guidelineIDs: action.guidelineIDs,
      };
    case contestConstants.STORE_FILE_PATH:
      return {
        ...state,
        [action.typeOfFile]: action.path,
      };

    default:
      return state;
  }
};

export const contestList = (state: Array<any> = [], action: any) => {
  switch (action.type) {
    case contestConstants.STORE_CONTESTS:
      return action.response;
    case contestConstants.DELETE_CONTEST:
      let cloneState = [...state];
      const contestToRemove = cloneState.findIndex((contest) => contest.ID === action.contest.ID);
      cloneState.splice(contestToRemove, 1);
      return cloneState;
    default:
      return state;
  }
};

export const contestDetails = (state = {}, action: any) => {
  switch (action.type) {
    case contestConstants.GET_CONTEST_BY_ID:
      return action.contest;
    case contestConstants.CONTESTANT_TYPE:
      return {
        ...state,
        contestantType: action.contestantType,
      };
    case contestConstants.DELETE_CONTEST:
      return {};
    default:
      return state;
  }
};

export const contestantsList = (state: Array<any> = [], action: any) => {
  switch (action.type) {
    case contestConstants.SHOW_CONTESTANTS_LIST:
      return action.list;
    case contestConstants.REMOVE_PENDING_CONTESTANT:
      return action.list;
    default:
      return state;
  }
};

export const contestant = (state = {}, action: any) => {
  switch (action.type) {
    case contestConstants.SHOW_CONTESTANT:
      return action.contestant;
    default:
      return state;
  }
};

export const initialContestants = {
  round: 'qualifying',
  selectedContestantIndex: null,
};

export const contestants = (state = initialContestants, action: any) => {
  switch (action.type) {
    case contestConstants.CONTESTANTS_RESET_STATE:
      return initialContestants;

    case contestConstants.CONTESTANTS_UPDATE_STATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
