import { TotalLcash,MorePoints,Pointsflash,SubTotal,
  MorePoints_total,Pointsflash2,Pointsflash3,Pointsflash4,Pointsflash5,
  Physical1,Physical2,Physical3,Physical4,Physical5,additional_prize2,
} from '../../src/lib/const';

export const totalAdditionalprize = {
    TotalLcashdata,
    MorePointsdata,
    MorePointsflash,
    SubTotalflash,
    TotalLcashflash,
    FlashUnitsflash,
    MorePointsflash2,
    MorePointsflash3,
    MorePointsflash4,
    MorePointsflash5,
    PhysicalGoods1,
    PhysicalGoods2,
    PhysicalGoods3,
    PhysicalGoods4,
    PhysicalGoods5,
    PhysicalGoodsare
//   clear,
};

function TotalLcashdata(message1: string) {
  return { type: TotalLcash, message1 };
}

function MorePointsdata(message: string) {
  return { type: MorePoints, message };
}

function MorePointsflash(place_1st: string) {
  return { type:Pointsflash, place_1st};
}

function MorePointsflash2(place_2nd: string) {
  return { type:Pointsflash2, place_2nd};
}
function MorePointsflash3(place_3rd: string) {
  return { type:Pointsflash3, place_3rd};
}
function MorePointsflash4(place_4th: string) {
  return { type:Pointsflash4, place_4th};
}
function MorePointsflash5(place_5th: string) {
  return { type:Pointsflash5, place_5th};
}

function SubTotalflash(message: string) {
  return { type:MorePoints_total, message };
}

function FlashUnitsflash(message: string) {
  return { type:SubTotal, message };
}

function TotalLcashflash(message: string) {
  return { type:TotalLcash, message };
}

function PhysicalGoods1(prize_1st: string) {
  return { type:Physical1,prize_1st};

}function PhysicalGoods2(prize_2nd: string) {
  return { type:Physical2, prize_2nd};
}
function PhysicalGoods3(prize_3rd: string) {
  return { type:Physical3, prize_3rd};

}
function PhysicalGoods4(prize_4th: string) {
  return { type:Physical4, prize_4th};
}
function PhysicalGoods5(prize_5th: string) {
  return { type:Physical5, prize_5th};
}

function PhysicalGoodsare(additional_prize: string) {
  return { type:additional_prize2, additional_prize};
}
