import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';

function DetailsGrid(props) {
  const { title, value, titleSpan, isEmail } = props;
  return (
    <Row type="flex" justify="space-between" gutter={[0, 10]}>   
      <Col span={12}>
        <Typography.Text className="weight-700">{title}</Typography.Text>
      </Col>
      <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!isEmail && <Typography.Text style={{ textAlign: 'end' }}>{value}</Typography.Text>}

        {isEmail && (
          <a href={`mailto: ${value}`}>
            <u>{value}</u>
          </a>
        )}
      </Col>
    </Row>
  );
}

DetailsGrid.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleSpan: PropTypes.number,
  isEmail: PropTypes.bool,
};

DetailsGrid.defaultProps = {
  titleSpan: 12,
  isEmail: false,
};

export default DetailsGrid;
