import React, { useState, useEffect, useMemo } from 'react';
import { Row, Form, Col, Typography, Input, Button } from 'antd';
import { Autocomplete } from '@react-google-maps/api';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import Geocode from 'react-geocode';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function CouponLocation(props) {
  const { language, onChange, form, initialAddress, initialLat, initialLng, showLatLng } = props;
  const {t}=useTranslation()

  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [autocomplete, setAutocomplete] = useState<any>();

  useEffect(() => {
    setLatitude(initialLat);
    setLongitude(initialLng);
  }, []);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = async () => {
    if (autocomplete !== null) {
      const res =   await autocomplete.getPlace();
      if (res.formatted_address) {
        const resLatLng = res.geometry.location;
        const lat =   await resLatLng.lat();
        const lng =   await resLatLng.lng();

        setAddress(res.formatted_address);
        setLatitude(parseFloat(lat));
        setLongitude(parseFloat(lng));

        props.form.setFieldsValue({ couponAddress: res.formatted_address });
        props.form.setFieldsValue({ couponLat: lat });
        props.form.setFieldsValue({ couponLng: lng });
        onChange(res.formatted_address, lat, lng);
      }
    } else {
      throw 'Autocomplete is not loaded yet!';
    }
  };

  const addressFromLatLng = (lat, lng) => {
    if (lat && lng) {
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddress(address);

          props.form.setFieldsValue({ couponAddress: address });
          props.form.setFieldsValue({ couponLat: lat });
          props.form.setFieldsValue({ couponLng: lng });
          onChange(address, lat, lng);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const onAddressChangeHandler = (event) => {
    setAddress(event.target.value);
  };

  const inputLatitudeHandler = (event) => {
    const lat = parseFloat(event.target.value);
    setLatitude(lat);

    addressFromLatLng(lat, longitude);
  };

  const inputLongitudeHandler = (event) => {
    const lng = parseFloat(event.target.value);
    setLongitude(lng);

    addressFromLatLng(latitude, lng);
  };

  return (
    <div>
      {/* <div className="location-wrapper"> */}
      {/* <Typography.Paragraph>{treasureCreation.LocationFormName[language]}</Typography.Paragraph> */}

      <Form.Item
        className="two-rows-label roboto-regular image-upload-req"
        label={t('Would you like to send a map with an address? (Optional):')+ ':'}
      >
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          {form.getFieldDecorator('couponAddress', {
            rules: [{ required: false }],
            initialValue: initialAddress,
          })(
            <Input
              className="input-treasure"
              placeholder={t('Address..')}
              onBlur={onAddressChangeHandler}
              // value={address}
            />
          )}
        </Autocomplete>
      </Form.Item>

      {showLatLng && (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              className="roboto-regular"
              label={t('Latitude')}
            >
              {form.getFieldDecorator('couponLat', {
                rules: [{ required: false }],
                initialValue: initialLat,
              })(
                <Input
                  className="input-treasure"
                  id={'couponLat'}
                  onBlur={inputLatitudeHandler}
                  // value={latitude}
                  placeholder={'0.000000'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="roboto-regular"
              label={t("Longitude")}
            >
              {form.getFieldDecorator('couponLng', {
                rules: [{ required: false }],
                initialValue: initialLng,
              })(
                <Input
                  className="input-treasure"
                  id={'couponLng'}
                  onBlur={inputLongitudeHandler}
                  // value={longitude}
                  placeholder={'0.000000'}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
}

CouponLocation.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  initialAddress: PropTypes.string,
  initialLat: PropTypes.number,
  initialLng: PropTypes.number,
  showLatLng: PropTypes.bool,
};

CouponLocation.defaultProps = {
  language: 'EN',
  onChange: () => {},
  showLatLng: true,
};

export default CouponLocation;
