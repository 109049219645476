import React,{Component} from "react";
import { Row } from "antd";

import AppContext from "../../../../AppContext";
// import closeIcon from '../../../../src/assets/closeXsmall.svg';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import iconSend from '../../../../src/assets/iconSend.svg';
// import iconSend from '../../../../../src/assets/iconSend.svg';
import iconSend from '../../../../../src/assets/iconSend.svg';


class SettingReviewDetail extends Component<any, any> {
    static contextType = AppContext;
    constructor(props){
        super(props);
    }

    render(){
        return(
            <>
            <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
                <div className="third-card relative">
                    <div className="fx-jcsb-aic mb-20">
                        <h4 className="text-primary mb-0 font-bold">&#62; NYC5.9</h4>
                        <div className="fx-aic">
                            <i onClick={this.props.close} className="iconx cursor">
                                <img src={closeIcon} alt="Info" />
                            </i>
                        </div>
                    </div>

                    <div className="fx-aic mb-10">
                        <div className="col col-6">
                            Dome Name to be approved
                        </div>
                        <div className="col col-6">
                            <input type="text" value="@BurgerKing" className="form-control botLine bdr-primary" />
                        </div>
                    </div>

                    <div>
                        <table className="table primary size11 mb-5">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>File review</th>
                                    <th>Name of team</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tr>
                                <td style={{verticalAlign:'middle'}}>Jun01, 2021</td>
                                <td>
                                    <p className="fileText">File:xxxxxxxxx</p>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline mr-5">
                                            <input type="checkbox"/> Done
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Need more
                                        </span>
                                    </p>
                                </td>
                                <td style={{verticalAlign:'middle'}}>Maria</td>
                                <td style={{verticalAlign:'middle'}}>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Pending
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Approved
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <table className="table primary size11 mb-5">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>File review</th>
                                    <th>Name of team</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tr>
                                <td style={{verticalAlign:'middle'}}>Jun01, 2021</td>
                                <td>
                                    <p className="fileText">File:xxxxxxxxx</p>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline mr-5">
                                            <input type="checkbox"/> Done
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Need more
                                        </span>
                                    </p>
                                </td>
                                <td style={{verticalAlign:'middle'}}>Maria</td>
                                <td style={{verticalAlign:'middle'}}>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline mr-5">
                                            <input type="checkbox"/> Pending
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Approved
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                        <table className="table primary size11">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>File review</th>
                                    <th>Name of team</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tr>
                                <td style={{verticalAlign:'middle'}}>Jun01, 2021</td>
                                <td>
                                    <p className="fileText">File:xxxxxxxxx</p>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline mr-5">
                                            <input type="checkbox"/> Done
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Need more
                                        </span>
                                    </p>
                                </td>
                                <td style={{verticalAlign:'middle'}}>Maria</td>
                                <td style={{verticalAlign:'middle'}}>
                                    <p className="mb-0">
                                        <span className="fx-aic-inline mr-5">
                                            <input type="checkbox"/> Pending
                                        </span>
                                        <span className="fx-aic-inline">
                                            <input type="checkbox"/> Approved
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>

                        <div className="mb-10 text-center">
                            <button className="ant-btn primary-ghost-btn xs">Hall</button>
                            <button className="ant-btn primary-ghost-btn xs ml-5">Performance Schedule</button>
                            <button className="ant-btn primary-ghost-btn xs ml-5">Arena</button>
                            <button className="ant-btn primary-ghost-btn xs ml-5">Notice</button>
                        </div>

                        <div className="mb-10 text-center">
                            <button className="ant-btn primary-ghost-btn xs">Box Office</button>
                            <button className="ant-btn primary-ghost-btn xs ml-5">Application</button>
                            <button className="ant-btn primary-ghost-btn xs ml-5">DP stand</button>
                        </div>

                        <div className="to-email-section">
                            <label>To email</label>
                            <div>
                                <div className="fx">
                                    <div className="col col-2 text-right">
                                        <button className="emlAddBtn">+</button>
                                    </div>
                                    <div className="col col-8">
                                        <input type="text" value="" className="form-control" />
                                    </div>
                                    <div className="col col-2">
                                        <button className="emailSendBtn"><img src={iconSend} alt="send" /></button>
                                    </div>
                                </div>
                                <div className="fx">
                                    <div className="col col-4 fx-aic-inline">
                                        <span>Name of staff</span>
                                    </div>
                                    <div className="col col-6">
                                        <input type="text" value="Maria" className="form-control h-30" />
                                    </div>
                                    <div className="col col-2">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Row>

            </>
        );
    }
}

export default SettingReviewDetail;