import React, { PureComponent } from 'react';
import {
  GoogleMap,
  LoadScript,
  Circle,
  Marker,
  OverlayView,
  Autocomplete,
} from '@react-google-maps/api';
import RadiusComponent from '../../components/RadiusComponent';
import { connect } from 'react-redux';
import * as treasureActions from '../../actions/treasureActions';
import { mapActions } from '../../actions/mapActions';
import LocationsMapControlList from './LocationsList';
import * as constant from '../../lib/const';
import StickerIcon from '../../assets/sticker_item_on_map.svg';
import ZoomControls from './ZoomControls';
import { domeList, gm } from '../../lib/config';
import PointIcon from '../../assets/ar_star_large.svg';
import CandyIcon from '../../assets/icon_candy.svg';
import { store } from '../../helpers/store';
import IconLDiscountSmall from '../../assets/iconLDiscountSmall.svg';
import { GetAllDomeByBounds, getMyDome } from '../../services/domeService';
import { parse, stringify, toJSON, fromJSON } from 'flatted';
import { Spin, Row } from 'antd';

import AppContext from '../../AppContext';

interface MapState {
  zoom: number;
  showInfoBox: boolean;
  domeList: any;
}

interface MapProps {
  lat: number;
  lng: number;
  mapInstance: any;
  zoom: number;
  locations: any;
  openZoomControls: boolean;
  selectedLocation: number;
  messageLocations: any;
  treasureUnitsResponse: any;
  open: number;
  treasureType: number;
  updateZoom: (zoom: number, radius: number) => void;
  enterRadius?: (radius: number) => void;
  storeInstance: (instance: any) => void;
  updateMap: () => void;
  isValidToken?: boolean;
  updateAddressZoom?: any;
  treasureDetails?: any;
  needReset: boolean;
  radius: number;
}

const libraries = ['places', 'geometry'];

const locationRadiusOptions = {
  strokeColor: '#70329e',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#70329e',
  fillOpacity: 0.35,
  zIndex: 10,
};

const messagingRadiusOptions = {
  strokeColor: '#70329e',
  strokeOpacity: 0,
  strokeWeight: 0,
  fillColor: '#70329e',
  fillOpacity: 0.5,
  zIndex: 9,
};

const mapStateToProps = (state: {
  treasure: any;
  menuUIChange: any;
  address: {
    lat: number;
    lng: number;
    mapInstance: any;
    zoom: number;
    needReset: boolean;
    radius: number;
  };
  locations: any;
  openZoomControls: boolean;
  selectedLocation: any;
  messageLocations: any;
  treasureUnitsResponse: any;
  treasureDetails: any;
}) => {
  const {
    menuUIChange,
    address,
    locations,
    selectedLocation,
    messageLocations,
    treasureUnitsResponse,
    treasure,
    treasureDetails,
  } = state;
  const { lat, lng, mapInstance, zoom, needReset, radius } = address;
  const { open, openZoomControls } = menuUIChange;
  const { treasureType } = treasure;
  return {
    open,
    lat,
    lng,
    mapInstance,
    zoom,
    locations,
    openZoomControls,
    selectedLocation,
    messageLocations,
    treasureUnitsResponse,
    treasureType,
    treasureDetails,
    needReset,
    radius,
  };
};

const actionCreators = {
  updateMap: treasureActions.updateMapAddressOnIdle,
  storeInstance: mapActions.storeInstance,
  updateZoom: treasureActions.updateZoom,
  // updateAddressZoom: treasureActions.updateAddressZoom,
};

class Map extends PureComponent<MapProps, MapState> {
  static contextType = AppContext;
  
  constructor(props: any) {
    super(props);

    this.state = {
      zoom: constant.DefaultZoom,
      showInfoBox: false,
      domeList: [],
    };
  }
  
  componentDidUpdate = async () => {
    let profile =   await this.getContext();

    if (profile.search_lat && profile.search_lng) {
      if (profile.isDraggingMap) {
        if (
          this.props.lat !== profile.search_lat &&
          this.props.lng !== profile.search_lng
        ) {
          profile.search_lat = this.props.lat;
          profile.search_lng = this.props.lng;

            await this.updateContext(profile);
        }
      }
    }
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  getMapCenter = async () => {
    console.log('draging');
    if (!this.props.mapInstance) return;
    let profile =   await this.getContext();
    profile.isDraggingMap = true;
      await this.props.updateMap();
      await this.updateContext(profile);
  };

  updateZoom = () => {
    if (this.props.mapInstance) {
      const zoom = this.props.mapInstance.getZoom();
       this.props.updateZoom(zoom, this.calculateRadius(zoom));
      this.props.mapInstance.panTo({ lat: this.props.lat, lng: this.props.lng });
    }
  };

  calculateRadius = (zoom: number) => {
    const meters_per_pixel =
      (156543.03392 * Math.cos((this.props.lat * Math.PI) / 180)) /
      Math.pow(2, zoom);
    let radius = 200 * meters_per_pixel;
    let toKm = radius / 1000;

    return toKm;
  };

  pickIcon = () => {
    switch (this.props.treasureType) {
      case constant.sticker:
        return StickerIcon;
      case constant.point:
        return PointIcon;
      case constant.candy:
        return CandyIcon;
      case constant.LDiscount:
        return IconLDiscountSmall;
      default:
        return null;
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.mapInstance) {
      // if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      //   this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() + 1);
      //   this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() - 1);
      // }
      //   else if (
      //     this.props.needReset &&
      //     !(this.props.locations.length > 0 && this.props.openZoomControls)
      //   ) {
      //     this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() - 1);
    }
   }

  render() {
    const mapOptions = {
      disableDefaultUI: true,
      gestureHandling:
        store.getState().menuUIChange.open === 2 ? 'none' : 'auto',
      // zoomControl: false,
    };

    const treasureSet: JSX.Element[] = [];

    for (let key in this.props.treasureUnitsResponse) {
      let icon = this.pickIcon();
      this.props.treasureUnitsResponse[key]?.map(
        (treasureUnit: any, index: number) => {
          treasureSet.push(
            <div className="treasure-set" key={Number(`${index}.${key}`)}>
              <Marker
                position={{
                  lat: Number(treasureUnit?.latitude),
                  lng: Number(treasureUnit?.longitude),
                }}
                icon={icon}
              />
            </div>
          );
        }
      );
    }

    var dom_map = false;
    var dome_lat = this.props.lat;
    var dome_lng = this.props.lng;
    let dome_loader = false;
    let points_loader = true;

    const profile = this.context.profile;
    if (profile) {
      dom_map = profile.dom_map;
      dome_loader = profile.dome_loader || false;
      points_loader = profile.points_loader || false;
      // dome_lat = parseFloat(this.props.lat);
      // dome_lng = parseFloat(this.props.lng);
      dome_lat = parseFloat(
        profile.search_lat ? profile.search_lat : this.props.lat
      );
      dome_lng = parseFloat(
        profile.search_lng ? profile.search_lng : this.props.lng
      );
    }

    let markerTest = null;
    // for testing created locations
    // if (
    //   this.props.treasureDetails.treasureUnits?.length > 0 &&
    //   this.props.treasureDetails.locations.length > 0
    // ) {
    //   markerTest = this.props.treasureDetails.treasureUnits.map((treasureUnit) => {
    //     return (
    //       <Marker
    //         key={treasureUnit.ID}
    //         position={{ lat: Number(treasureUnit.latitude), lng: Number(treasureUnit.longitude) }}
    //       />
    //     );
    //   });
    //   this.props.treasureDetails.locations.map((location) => {
    //     markerTest.push(
    //       <Circle
    //         options={messagingRadiusOptions}
    //         key={location.ID}
    //         center={{
    //           lat: Number(location.latitude),
    //           lng: Number(location.longitude),
    //         }}
    //         radius={location.radius * 1000}
    //       />
    //     );
    //   });
    // }

    return (
      <>
        <LoadScript
          id="script-loader"
          googleMapsApiKey={gm}
          libraries={libraries}
        >
          <LocationsMapControlList />         
          {this.props.locations.length > 0 && this.props.openZoomControls ? (
            <ZoomControls />
          ) : null}

          <GoogleMap
            id="example-map"
            center={{ lat: dome_lat, lng: dome_lng }}
            zoom={this.state.zoom}           
            options={mapOptions}
            onLoad={(map) => {
              this.props.storeInstance(map);
            }}
            onDragEnd={this.getMapCenter}
            onZoomChanged={this.updateZoom}
            // onIdle={this.getBounds}
          >
            {!dom_map ? (
              <div className="map-overlay">
                {this.props.open === 1 ? <RadiusComponent /> : null}

                {this.props.locations.map((location: any) => {
                  return (
                    <Circle
                      options={locationRadiusOptions}
                      key={location.ID}
                      center={{
                        lat: location.latitude,
                        lng: location.longitude,
                      }}
                      radius={location.radius * 1000}
                    />
                  );
                })}

                {this.props.messageLocations.map((messageLocation: any) => {
                  return (
                    <Circle
                      options={messagingRadiusOptions}
                      key={messageLocation.ID}
                      center={{
                        lat: messageLocation.latitude,
                        lng: messageLocation.longitude,
                      }}
                      radius={messageLocation.radius * 1000}
                    />
                  );
                })}

                {treasureSet}
              </div>
            ) : (
              ''
            )}
            {/* {dome_loader ?
            <div className="loader-container">
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            : ''
          } */}
          </GoogleMap>
        </LoadScript>
        {points_loader && (
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <Spin size="large" spinning={true} />
          </div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, actionCreators)(Map);
