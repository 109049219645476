import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import { Table, Input, Popconfirm, Form } from 'antd';

import editIcon from '../../../../../src/assets/icon_edit.svg';


import { useQuery } from 'react-query';
import DomeStatistics from '../statistics/DomeStatistics';
import moment from 'moment';

import { getCashing, UpdateCashingData, sendmail, updatecashingdata  } from '../../../../services/domeService';
import { Row, Button, Input, notification, Checkbox , Modal} from 'antd';
import DomePlums from '../statistics/DomePlums';
import { selected } from '../../../../lib/strings/adminNotifications';


function Plums(props: any) {
  const { language } = props;
  const [isActive, setActive] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [num, setNum] = useState([]);
  const [email, setEmail] = useState('');
  const [dataSource, setDataSource] = useState([])
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [getinput, setGetinput] = useState("")
  const [getcheck, setGetcheck] = useState(false)
  const [id, setId] = useState([])
  const [changevalue, setChangevalue] = useState([])
  // const [selectedDomeAddressIdForEdit, setSelectedDomeAddressIdForEdit] = useState(null);
  // const [selectedValueForEdit, setSelectedValueForEdit] = useState('');
  const [selected, setselected] = useState(null)
  const [search,setSearch]=useState("")

  const toggleClass = (email) => {
    setActive(!isActive);
    setEmail(email)
  };

  const showErrorMessage = (title) => {
    notification.error({
      message: `${title}`,
      duration: 10,
    });
  };


  const { confirm } = Modal

 
  const Class = () => {
    setActive(!isActive);

  };

  const Send = async () => {
    let getmail =  await sendmail(getinput, email)
    setActive(!isActive)
  }


  const staff = async (indx: any, item: any) => {
    const staff_name = document.getElementById(indx).value ? document.getElementById(indx).value : ""
    let ch = 0
    if (getcheck == true) { ch = 1 }
    if (staff_name != "") {
      setValue(indx)
      showConfirm(staff_name,item)
    }
    else {
      showErrorMessage('Please fill staff name')
    }
  }

  const  showConfirm=async(name,item)=> {
    confirm({
      title: 'Are you sure you want to cash this request?',
      async onOk() {
         await updatecashingdata(item, name,)
        setGetcheck(true)
      },
      onCancel() {
        setGetcheck(false) 
      },
    });
  }



  useEffect(() => {
    (async (filters,startDate,endDate) => {
      setLoading(true);
      let dataSource =  await getCashing(1,filters,startDate,endDate)
      setDataSource(dataSource.data);
      setTotalTreasures(dataSource.data.length)
      setId(dataSource.total_number)
      setNum(dataSource.total_cashing_request)
      setChangevalue(dataSource.data)
      setLoading(false);
    })();
  }, []);


  const onFiltersChangeHandler = async (filters,startDate,endDate) => {
    setLoading(true);
     let dataSource =  await getCashing (1,filters,startDate,endDate)
     setDataSource(dataSource.data)
     setLoading(false);
  };

 

  const handleChange = (event) => {
    setValue(event.target.value)
  }



  const getvalue = (event) => {
    setGetinput(event.target.value)

  }

  let columns = [

    {
      title: 'No.',
      key: 'Number',
      render: (text, indx, record) => <span>{indx + 1}</span>,
    },
    {
      title: 'Users',
      key: 'users',
      render: (text, indx, item) => <span>

        {[item.nickname]}
      </span>,
    },


    {
      title: 'Requested plum for cashing',
      align: "center",
      key: 'Requested plum for cashing',
      render: (text, indx, item) => <span className='fx-jcc'>
        <span>
          {[item.cashed_plum]}
          {'   '}
          <span className='ml-5 mr-5' style={{ cursor: "pointer" }} onClick={(e) => toggleClass(item.email)}>
            <img src={editIcon} alt="Edit" height="14" />
          </span>{' '}
        </span>
        <span>$</span>{[item.usd]} </span>
    },

    {
      title: 'Date',
      key: 'Date',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, index, item) => {
        return <span>

          {moment(item.date).format('MM/DD/YY')}
        </span>;
      },
    },

    {
      title: 'Country',
      key: 'Country',
      render: (text, indx, item) => <span>
        {[item.country]}
      </span>,
    },


    {
      title: 'Beneficiary name ',
      key: 'Beneficiary name ',
      render: (text, indx, item) => <span className='text-center'>
        {[item.beneficiary_name]}
      </span>,
    },

    {
      title: ' Bank name',
      key: ' Bank name',
      render: (text, indx, item) => <span className='text-center'>
        {[item.bank_name]}
      </span>,
    },

    {
      title: 'Account number',
      key: ' Account number',
      render: (text, indx, item) => <span>
        {[item.account_number]}
      </span>,
    },

    {
      title: 'ABA no',
      key: ' ABA no',
      render: (text, indx, item) => <span>
        {[item.swift_code]}
      </span>,
    },

    {
      title: 'US1099-nec',
      key: ' US1099-nec',
      render: (text, indx, item) => <div className='fx-jcc main'>
        <label className="custom-checkbox">
          <input type="checkbox" name="vehicle1" checked={item.is_us == 1 ? true : false}></input>
          <span className="checkmark"></span>
        </label>
      </div>
      ,
    },

    {
      title: 'Name of staff',
      key: ' Name of staff',
      render: (text, indx, item) => <span>
        <input
          type="text"
          id={indx}
          className='inp regular white' style={{ width: '150px' }}
          onChange={(e) => handleChange(e)}
          defaultValue={item.staff_name}
        />

      </span>,
    },



    {
      title: 'Status',
      key: ' Status',
      render: (text, indx, item) => <span className='fx-jcc '>
        <input
          type="checkbox"
          onChange={() => staff(indx, item.ID)}
          checked={value == indx ? getcheck : false  || item.status==1?true:false}
          style={item.status==1?{pointerEvents: "none"}:{}} 
        
        />
      </span>,
    },



  ];

  return (
    <>
      <DomePlums
        label="Dome Statistics"
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        statisticsType={'statisticsForPlums'}
          onFiltersChange={onFiltersChangeHandler}
        totalItems={totalTreasures}
        num={num}
        id={id}
        pageSize={500}
        currentPage={currentPage}
      />
      <div className={!isActive ? "overlay" : "overlay opened"}></div>
      <div
        className={!isActive ? "custom-modal xs" : "custom-modal xs opened"}>
        <div className="custom-modal__close" onClick={Class} ></div>
        <div className="custom-modal__body">
          <div>
            <p className="mb-0" ><label>from: </label><a href="mailto:info@loamoa.com"> info@loamoa.com </a></p>
            <p><label>To: </label>{email}</p>
          </div>
          <div className='form-group'>
            <textarea rows={6} onChange={getvalue} className='form-control' ></textarea>
          </div>
          <div className="fx-jcc">
            <button onClick={Send} className="ant-btn yellow-gradient xs rd-5">Send</button>
          </div>
        </div>
      </div>
      
    </>
  );
}





Plums.propTypes = {};

Plums.defaultProps = {};



const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(Plums);



