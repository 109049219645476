import moment from 'moment';
import { enterLatLng } from '../actions/treasureActions';
import { store } from '../helpers/store';
import { latLng } from './pointFill';

export const fillAdDiscount = async (
  setFieldsValue,
  onInputChange,
  onHashtagChange,
  onEndDateChange,
  onDescriptionChange,
  onLDiscountChange,
  ldiscountExpirationDateHandler
) => {
  store.dispatch(enterLatLng(latLng.coordinates, 0));

  setFieldsValue({
    lat0: latLng.coordinates.lat,
    lng0: latLng.coordinates.lng,
    address0: latLng.address,
  });

  onInputChange({ target: { value: 'quickFill AD Discount' } });

  onDescriptionChange({ target: { value: 'quickFill description' } });

  onLDiscountChange({ target: { value: '100' } });

  onHashtagChange(['quickFill']);

  ldiscountExpirationDateHandler(parseInt(moment().add(30, 'd').format('x')));

  onEndDateChange(parseInt(moment().add(7, 'd').format('x')));
};
