import React from 'react';
import { Row, Modal, Col, Typography, Spin } from 'antd';
import PropTypes from 'prop-types';

function ModalLoadingProgressText(props) {
  const { loading, done, percentage, text } = props;

  return (
    <Row className="loading-modal-container">
      <Modal
        bodyStyle={{ padding: '0', paddingTop: '1em', borderRadius: '10px' }}
        visible={loading && !done}
        footer={null}
        closable={false}
        centered={true}
      >
        <Row type="flex" justify="center" className="loading-modal">
          <Col>
            <Row type="flex" justify="center" style={{ padding: '2em' }}>
              <Typography.Text className="fredoka">
                {text} {percentage}%
              </Typography.Text>
            </Row>
            <Row type="flex" justify="center" style={{ padding: '2em' }}>
              <Spin size="large" spinning={loading} />
            </Row>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}

ModalLoadingProgressText.propTypes = {
  loading: PropTypes.bool,
  done: PropTypes.bool,
  percentage: PropTypes.number,
  text: PropTypes.string,
};

ModalLoadingProgressText.defaultProps = {
  loading: true,
  done: false,
  percentage: 5,
};

export default ModalLoadingProgressText;
