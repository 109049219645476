import { store } from '../helpers/store';
import { notification } from 'antd';
import { fileUploadErr } from '../lib/strings/error';
import { responseCodes } from '../lib/const';
import * as userActions from '../actions/signinActions';

export const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 5,
  });
};

export const uploadFiles = async (
  url: string,
  formData: FormData,
  listener: any,
  method = 'POST'
) => {
  try {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open(method, url, true);
      xhr.setRequestHeader('access-token', store.getState().authentication.user.newToken);
      xhr.responseType = 'json';

      xhr.upload.onprogress = (e: ProgressEvent) => {
        const perc = Math.floor((e.loaded / e.total) * 1000) / 10;
        if (listener && listener.onProgress) listener.onProgress(perc);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.response.code === responseCodes.successful) {
            resolve(xhr.response);
          } else {
            notification.error({
              message: fileUploadErr[store.getState().authentication.language],
              description: `${xhr.response.code}`,
            });
            resolve(xhr.response);
          }
        }
      };
      xhr.send(formData);
    });
  } catch (error) {
    notification.error({
      message: fileUploadErr[store.getState().authentication.language],
    });
    return error;
  }
};

export const executeSignOut = () => {
  store.dispatch(userActions.signout());
};

export const toFormData = (value) => {
  const type = typeof value;

  switch (type) {
    case 'string':
      return value;
    case 'number':
      return `${value}`;
    case 'boolean':
      return `${value}`;
    case 'object':
      if (value) {
        return value;
      }
      return '';
    case undefined:
      return '';
    default:
      return '';
  }
};
