import { LanguageMap } from '../interfaces/language';

export const PrivacyPolicyTitle: LanguageMap<string> = {
  EN: 'LOAMOA_Privacy Policy',
};

export const p1: LanguageMap<string> = {
  EN:
    'This Privacy Policy (the "Policy") explains the way of treatment of the information which is provided or collected in the LoaMoa (the "LOAMOA") service (the "Service") on which this Policy is posted. ',
};

export const p2: LanguageMap<string> = {
  EN:
    'The Company is the controller of the information provided or collected in the app and websites on which this Policy is posted and in the course of using the service of the Company which exist in the app and websites or platforms of other company.',
};

export const p3: LanguageMap<string> = {
  EN: `Through this Policy, the Company regards personal information of the users as important and inform them of the purpose and method of Company's using the personal information provided by the users and the measures taken by the Company for protection of those personal information.`,
};

export const p4: LanguageMap<string> = {
  EN: '1. Information to be collected and method of collection',
};

export const p5: LanguageMap<string> = {
  EN: '(1) Personal information items to be collected',
};

export const p6: LanguageMap<string> = {
  EN: 'Personal information items to be collected by the Company are as follows:',
};

export const p7: LanguageMap<string> = {
  EN: 'Information provided by the users',
};

export const p8: LanguageMap<string> = {
  EN: 'The Company may collect the information directly provided by the users as below:',
};

export const l1: LanguageMap<string> = {
  EN: 'email address or phone number, password',
};

export const l2: LanguageMap<string> = {
  EN:
    'in case of the access by Facebook, Google, Instagram, Line or KakaoTalk account: user ID and a friends list of the linked account',
};

export const l3: LanguageMap<string> = {
  EN:
    'cameras and photos: images and other information stored in the camera and photo album of the device being used',
};

export const l4: LanguageMap<string> = {
  EN:
    'Face data: the Company employs TrueDepth API to collect face blendshape information of the TrueDepth camera and synchronizes the collected information with a character: provided, however, that the face blendshape information collected by the Company is solely used for the performance of the ‘mirror’ function not for storage in server nor for sharing with a 3rd party',
};

export const l5: LanguageMap<string> = {
  EN:
    ' Hardware model name, Equipment identifier, operation system, hardware version, type and set-up of browser, language of browser, information of the device being used such as mobile network information, etc.',
};

export const l6: LanguageMap<string> = {
  EN: 'Information on users’ ADID(Advertising ID)/IDFA(Identifier for Advertisers)',
};

export const l7: LanguageMap<string> = {
  EN:
    'If conflicts or other errors occur while users are using the Service, the Company may exceptionally collect the information on certain devices necessary to identify the cause of such problems.',
};

export const m2: LanguageMap<string> = {
  EN: '(2) Method of collection',
};

export const p9: LanguageMap<string> = {
  EN: 'The Company collects the information of users in a way of the followings:',
};

export const list2_1: LanguageMap<string> = {
  EN: 'app, webpage, written form, e-mailing, tools for collection of created information',
};

export const list2_2: LanguageMap<string> = {
  EN: 'provided by partner companies',
};

export const p10: LanguageMap<string> = {
  EN: 'The Company collects the information of users in a way of the followings:',
};

export const point2: LanguageMap<string> = {
  EN: '2. Use of collected information',
};

export const p11: LanguageMap<string> = {
  EN: 'The Company uses the collected information of users for the following purposes:',
};

export const list3_1: LanguageMap<string> = {
  EN: 'Member management and identification',
};

export const list3_2: LanguageMap<string> = {
  EN: 'To detect and deter unauthorized or fraudulent use of or abuse of the Service',
};

export const list3_3: LanguageMap<string> = {
  EN: 'Improvement of existing services and development of new services',
};

export const list3_4: LanguageMap<string> = {
  EN: 'Making notice of function of company sites or applications or matters on policy change',
};

export const list3_5: LanguageMap<string> = {
  EN: 'To provide information on promotional events as well as opportunity to participate',
};

export const list3_6: LanguageMap<string> = {
  EN: 'To create users’ characters as an avatar',
};

export const list3_7: LanguageMap<string> = {
  EN: 'To comply with applicable laws or legal obligation',
};

export const list3_8: LanguageMap<string> = {
  EN:
    'Use of information with prior consent of the users (for example, utilization of marketing advertisement)',
};

export const point3: LanguageMap<string> = {
  EN: '3. Disclosure of collected information',
};

export const p12: LanguageMap<string> = {
  EN:
    'Except for the following cases, the Company will not disclose personal information with a 3rd party:',
};

export const list4_1: LanguageMap<string> = {
  EN: 'when the Company disclosing the information with online tailored advertisement providers',
};

export const list4_2: LanguageMap<string> = {
  EN:
    'when the Company disclosing the information with its affiliates, partners and service providers',
};

export const list4_3: LanguageMap<string> = {
  EN: 'when the users consent to disclose in advance',
};

export const list4_4: LanguageMap<string> = {
  EN: 'when disclosure is required by the laws',
};

export const point4: LanguageMap<string> = {
  EN: '4. Cookies, Beacons and Similar Technologies',
};

export const p13: LanguageMap<string> = {
  EN: 'We do not use these technologies to collect the information of users.',
};

export const point5: LanguageMap<string> = {
  EN: '5. User’s right',
};

export const p14: LanguageMap<string> = {
  EN:
    'The users or their legal representatives, as main agents of the information, may exercise the following rights regarding the collection, use and sharing of personal information by the Company:',
};

export const list5_1: LanguageMap<string> = {
  EN: 'The right to access to personal information;',
};

export const p15: LanguageMap<string> = {
  EN:
    'The users or their legal representatives, as main agents of the information, may exercise the following rights regarding the collection, use and sharing of personal information by the Company:',
};

export const list5_2: LanguageMap<string> = {
  EN: 'The right to rectification;',
};

export const list5_2_1: LanguageMap<string> = {
  EN:
    '- The users or their legal representatives may request to correct inaccurate or incomplete information.',
};

export const list5_3: LanguageMap<string> = {
  EN: 'The right to erasure;',
};

export const list5_3_1: LanguageMap<string> = {
  EN:
    '- The users or their legal representatives may request to correct inaccurate or incomplete information.',
};

export const list5_3_2: LanguageMap<string> = {
  EN:
    '- However, Company will not be able to erase some personal data due to following reasons in accordance with the article 17 of the EU GDPR(General Data Protection Regulation).',
};

export const list5_3_3: LanguageMap<string> = {
  EN: '· The processing of your personal data is necessary for compliance with a legal obligation.',
};

export const list5_3_4: LanguageMap<string> = {
  EN:
    '· The processing of your personal data is necessary for the establishment, exercise or defense of legal claims.',
};

export const list5_4: LanguageMap<string> = {
  EN: 'The right to restriction of processing;',
};

export const list5_4_1: LanguageMap<string> = {
  EN:
    '- The users or their legal representatives may make temporary suspension of treatment of personal information in case of the disputes over the accuracy of information and the legality of information treatment, or if necessary to retain the information.',
};

export const list5_5: LanguageMap<string> = {
  EN: 'The right to data portability',
};

export const list5_5_1: LanguageMap<string> = {
  EN:
    '- The users or their legal representatives may request to provide or transfer the information.',
};

export const list5_6: LanguageMap<string> = {
  EN: ' The right to object',
};

export const list5_6_1: LanguageMap<string> = {
  EN:
    '- The users or their legal representatives may suspend the treatment of personal information if the information is used for the purpose of direct marketing, reasonable interests, the exercise of official duties and authority, and research and statistics.',
};

export const list5_7: LanguageMap<string> = {
  EN: 'The right to automated individual decision-making, including profiling',
};

export const list5_7_1: LanguageMap<string> = {
  EN:
    'The users or their legal representatives may request to cease the automated treatment of personal information, including profiling, which has critical impact or cause legal effect on them.',
};

export const p16: LanguageMap<string> = {
  EN: `If, in order to exercise the above rights, you, as an user, use the menu of 'amendment of member information of webpage or contact the Company by sending a document or e-mails, or using telephone to the Company ( person in charge of management of personal information or a deputy), the Company will take measures without delay: Provided that the Company may reject the request of you only to the extent that there exists either proper cause as prescribed in the laws or equivalent cause.`,
};

export const point6: LanguageMap<string> = {
  EN: '6. User’s Obligations',
};

export const p17: LanguageMap<string> = {
  EN:
    'The users shall keep their personal information up to date and be liable for the problems arising out of or in connection with the information incorrectly input by themselves.',
};

export const p18: LanguageMap<string> = {
  EN:
    'The users have responsibility to ensure and maintain the security of their information such as email address, Facebook ID, Google ID, etc. and shall neither assign nor lend such information to a 3rd party. The Company shall not be held responsible for any problems related to personal information that may arise out of or in relation to the mistakes made by individual members or the cause not attributable to the Company.',
};

export const p19: LanguageMap<string> = {
  EN:
    'The users shall appropriately manage their personal information and take full responsibility for such management, and be liable for the cooperation with the request of the Company to change the password on a regular basis under this Policy.',
};

export const p20: LanguageMap<string> = {
  EN:
    'The users shall comply with the applicable laws and statues of personal information protection in each country.',
};

export const point7: LanguageMap<string> = {
  EN: '7. Security',
};

export const p21: LanguageMap<string> = {
  EN: `The Company regard the security of personal information of uses as very important. The company constructs the following security measures to protect the users' personal information from any unauthorized access, release, use or modification`,
};

export const list7_1: LanguageMap<string> = {
  EN: ' Encryption of personal information',
};

export const list7_1_1: LanguageMap<string> = {
  EN: `- Transmit users' personal information by using encrypted communication zone`,
};

export const list7_1_2: LanguageMap<string> = {
  EN: `- Store important information such as passwords after encrypting it`,
};

export const list7_2: LanguageMap<string> = {
  EN: 'Countermeasures against hacking',
};

export const list7_2_1: LanguageMap<string> = {
  EN: `- Install a system in the zone the external access to which is controlled so as to prevent leakage or damage of users' personal information by hacking or computer virus`,
};

export const list7_3: LanguageMap<string> = {
  EN: ' Establish and execute internal management plan',
};

export const list7_4: LanguageMap<string> = {
  EN: 'Install and operate access control system',
};

export const list7_5: LanguageMap<string> = {
  EN: 'Take measures to prevent forging or alteration of access record',
};

export const point8: LanguageMap<string> = {
  EN: '8. Protection of personal information of children',
};

export const p8_1: LanguageMap<string> = {
  EN:
    'In principle, the Company does not collect any information from the children under 13 or equivalent minimum age as prescribed in the laws in relevant jurisdiction. The Service of the Company are the ones to be provided to ordinary people, in principle. The service has function to do age limit so that children cannot use it and the Company does not intentionally collect any personal information from children through that function.',
};

export const p8_2: LanguageMap<string> = {
  EN:
    '(Additional procedure for collecting personal information from children) However, if the Company collects any personal information from children under 13 or equivalent minimum age as prescribed in the laws in relevant jurisdiction for the services for unavoidable reason, the Company will go through the additional procedure of the followings for protecting that personal information of children:• verify, to the extent that efforts are reasonably made, whether they are children of the age at which consent from their guardian is required and the consenting person is an authorized one.',
};

export const list8_1: LanguageMap<string> = {
  EN:
    'obtain consent from the parents or guardian of children so as to collect personal information of children or directly send the information of products and services of the Company',
};

export const list8_2: LanguageMap<string> = {
  EN: `give the parents or guardian of children a notice of Company's policy of privacy protection for children including the items, purpose and sharing of personal information collected`,
};

export const list8_3: LanguageMap<string> = {
  EN: `grant to legal representatives of children a right to access to personal information of that children/correction or deletion of personal information/temporary suspension of treatment of personal information/ and request for withdrawal of their consent provided before`,
};

export const list8_4: LanguageMap<string> = {
  EN: ` limit the amount of personal information exceeding those necessary for participation in online activities`,
};

export const point9: LanguageMap<string> = {
  EN: '9. Modification of Privacy Protection Policy',
};

export const p9_1: LanguageMap<string> = {
  EN: `The Company has the right to amend or modify this Policy from time to time and, in such case, the Company will make a public notice of it through pop-up of its service (or through individual notice such as e-mail) and obtain consent from the users if required by relevant laws.`,
};

export const p9_2: LanguageMap<string> = {
  EN: `If this Policy is amended, the Company will announce such amendment with the reason and effective date seven (7) days before the effective date.`,
};

export const p9_3: LanguageMap<string> = {
  EN:
    'In the event of a change to this Privacy Policy, the Company will announce the amendment reason and effective date seven days prior to the effective date of the change.',
};

export const point10: LanguageMap<string> = {
  EN: '10. Others',
};

export const point10_a: LanguageMap<string> = {
  EN: `A. A Special Note about Children in the United States of America or in Europe
    The Service is intended for a general audience and is not directed at or intended to be used by minors under the age of 13 (under the age of 16 in Europe) (“Children”).`,
};

export const p10_1: LanguageMap<string> = {
  EN: `We understand the special necessity to protect Children's online privacy, and we do not knowingly collect any personal information from Children.`,
};

export const p10_2: LanguageMap<string> = {
  EN: `If, however, you believe we have collected any personal information from Children, then please let us know via the E-mail.`,
};

export const p10_3: LanguageMap<string> = {
  EN: `If we learn that we have inadvertently collected personal information from Children, we will deactivate the relevant Account(s) and will take reasonable measures to promptly delete such personal information from our records.`,
};

export const point10_b: LanguageMap<string> = {
  EN: `B. Data transfer to other countries`,
};

export const p10_4: LanguageMap<string> = {
  EN: `Considering it engages in global businesses, the Company may provide the users' personal information to the companies located in other countries for the purpose as expressly stated in this Policy. For the places where the personal information is transmitted, retained or processed, the Company takes reasonable measures for protecting those personal information.`,
};

export const p10_5: LanguageMap<string> = {
  EN: `To make our Privacy Policy easier to understand for non-native English users, we may provide translations in your native language as a reference. However, in the event that any provision in the translations contradicts the English version of this Privacy Policy, unless otherwise specified, the English Version takes precedence to the extent permissible under applicable law.
    If you are using Services in these countries or regions, please refer to our Privacy Policy below applied in each country. `,
};

export const list10_1: LanguageMap<string> = {
  EN: `Korea `,
};

export const point10_c: LanguageMap<string> = {
  EN: `C. Those who are in the European Economic Area may contact the Data Protection Officer below or you may lodge a complaint with a supervisory authority in the European Economic Area.`,
};

export const p10_address1: LanguageMap<string> = {
  EN: `<Data Protection Officer>`,
};

export const p10_address2: LanguageMap<string> = {
  EN: `ARMAX INC`,
};

export const p10_address3: LanguageMap<string> = {
  EN: `Attn: Data Protection Officer`,
};

export const p10_address4: LanguageMap<string> = {
  EN: `4300 Stevens Creek Blvd Ste 250`,
};

export const p10_address5: LanguageMap<string> = {
  EN: `San Jose, CA 95129 USA`,
};

export const point11: LanguageMap<string> = {
  EN: '11. Amendment to the Privacy Policy',
};

export const p11_1: LanguageMap<string> = {
  EN:
    'The Company of the LOAMOA may modify our Privacy Policy from time to time for various reasons including to improve our privacy practices, to reflect changes to our Service, and to comply with relevant laws.  This Privacy Policy was last updated on Mar 23, 2020 (V1.0.0), and it was initially released on Mar 20, 2020 (V0.9.0).',
};

export const p11_2: LanguageMap<string> = {
  EN:
    'When we make any material changes to our Privacy Policy, we will notify you on our Services, or by other reasonable means.  We encourage you to review changes carefully.  If you agree to the changes, then please continue to use our Service.  If, however, you do not agree to any of the changes and you no longer wish to use our Services, you may choose to close your Account.  Continuing to use our Services after a notice of changes has been communicated to you or published on our Services constitutes your acceptance of the changes and consent to the modified Privacy Policy. We seek your consent when applicable law requires.',
};

export const point12: LanguageMap<string> = {
  EN: '12. Contact information of Company',
};

export const p12_1: LanguageMap<string> = {
  EN:
    'If you have any questions, comments, or concerns about this Privacy Policy or our other privacy policies or practices, please contact us as below ',
};

export const p12_keyword: LanguageMap<string> = {
  EN: 'E-mail:',
};

export const p12_address1: LanguageMap<string> = {
  EN: `Attn: ARMAX INC/ Privacy Policy`,
};

export const p12_address2: LanguageMap<string> = {
  EN: `4300 Stevens Creek Blvd Ste 250`,
};

export const p12_address3: LanguageMap<string> = {
  EN: `San Jose, CA 95129 USA`,
};

export const email: LanguageMap<string> = {
  EN: 'Email: support@loamoa.com',
};

export const version: LanguageMap<string> = {
  EN: '(Privacy Policy Version 1.0.0)',
};
