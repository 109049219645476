import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import {
  statisticsForPointsText,
  statisticsForPointsStrings,
} from '../../../../lib/strings/statistics';
import { statisticsForPoints } from './apiToTabel';
import * as statisticsService from '../../../../services/statisticsService';
import { useQuery } from 'react-query';

function StatisticsForPoints(props) {
  const { language } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForPoints = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForPoints(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['StatisticsForPoints', { ...currentFilters, page: currentPage }],
    fetchStatisticsForPoints,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  useEffect(() => {
    if (statisticsQuery.data) {
      const parsedStatistics = statisticsForPoints(statisticsQuery.data.transactions);
      setTotal({
        totalBalance: statisticsQuery.data.totalPoints,
        totalCollected: statisticsQuery.data.pointsCollected,
        totalConverted: statisticsQuery.data.pointsConverted,
      });
      setStatistics(parsedStatistics);
      setTotalTreasures(statisticsQuery.data.totalTransactions);
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
    setClickedUsername('');
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  let columns = [
    {
      titleStyle: {},
      dataIndex: 'date',
      dataStyle: {},
    },
    {
      dataIndex: 'username',
      onClick: clickedUsernameHandler,
      dataStyle: { textDecoration: 'underline', cursor: 'pointer' },
    },
    {
      dataIndex: 'country',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'collectedPoints',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'pointsConvertedAsCoins',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'usedPoints',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'userPointBalance',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
  ];

  columns.map((column, index) => {
    columns[index]['title'] = statisticsForPointsStrings[language][column.dataIndex];
  });

  return (
    <Statistics
      label={statisticsForPointsText[language]}
      columns={columns}
      dataSource={statistics}
      loading={statisticsQuery.isLoading}
      statisticsType={'StatisticsForPoints'}
      clickedUsername={clickedUsername}
      onFiltersChange={onFiltersChangeHandler}
      onPageChange={onPageChangeHandler}
      totalItems={totalTreasures}
      pageSize={10}
      currentPage={currentPage}
      total={total}
    />
  );
}

StatisticsForPoints.propTypes = {};

StatisticsForPoints.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(StatisticsForPoints);

// let dataForStatistics = [
//     {
//       date: 1607423692437,
//       username: 'user name bl1',
//       country: 'hr',
//       collectedPoints: 300,
//       pointsConvertedAsCoins: 280,
//       usedPoints: 3000,
//       userPointBalance: 200,
//       id: 55,
//     },
// ]
