import { baseURL, treasureAPI } from '../lib/config';
import { store } from '../helpers/store';
import { responseCodes } from '../lib/const';
import { notification } from 'antd';
import { fileUploadErr, serverError } from '../lib/strings/error';
import { executeSignOut, uploadFiles } from './sharedServices';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

export const postAdMakerDetails = async (id: number) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}/${id}/details`, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.details;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      showErrorMessage(serverError[store.getState().authentication.language], response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
    return error;
  }
};

export const putAdMakerDetails = async (id: number, formData: FormData) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}/${id}/details`, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
      body: formData,
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.adkitDetails;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      showErrorMessage(serverError[store.getState().authentication.language], response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
    return error;
  }
};

export const getAdMakerDetails = async (id: number) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}/${id}/details`, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.details;
    } else if (response.code === responseCodes.unknownError) {
      // after merge needs refactor
      // if treasure is missing details
      return 4000000;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      showErrorMessage(serverError[store.getState().authentication.language], response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
    return error;
  }
};
