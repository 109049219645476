import React from 'react';
import { Row, Typography } from 'antd';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import { getKits } from '../../../../services/kitService';
import * as kitActions from '../../../../actions/kitActions';
import {
  sticker,
  point,
  candy,
  LDiscount,
  draft,
  inReview,
  approved,
  declined,
  pending,
  finished,
  flash
} from '../../../../lib/const';

const findPricePerItem = (kitList = [], kitTypeID, country) => {
  const kitData = kitList.find((kit) => kit.ID === kitTypeID);
  if (kitData) {
    const myCountryPrices = kitData.pricesPerCountry.find(
      (priceObject) => priceObject.country === country
    );
    if (myCountryPrices) {
      return myCountryPrices.price;
    }
    const defaultPrices = kitData.pricesPerCountry.find(
      (priceObject) => priceObject.country === 'default'
    );
    if (defaultPrices) {
      return defaultPrices.price;
    }
  }
  return undefined;
};

const calculateCollectedUnits = (treasureUnits = []) => {
  let counter = 0;
  treasureUnits.forEach((treasureUnit) => {
    treasureUnit.isCollected && counter++;
  });

  return counter;
};

function TreasureInfoNumberOfUnits({
  language,
  storeKitList,
  numberOfUnits,
  kitTypeID,
  country,
  treasureUnits,
  treasureType,
  status,
}) {
  const kitQuery = useQuery('kitList', getKits, {
    staleTime: 1800000,
    cacheTime: 3600000,
    onSuccess: (response) => {
      storeKitList(response);
    },
  });

  const pricePerItem = findPricePerItem(kitQuery.data, kitTypeID, country);
  const totalPrice = pricePerItem ? pricePerItem * numberOfUnits : null;

  const collectedUnits = calculateCollectedUnits(treasureUnits);
  const uncollectedUnits = numberOfUnits - collectedUnits;
  const uncollectedBalance = pricePerItem ? pricePerItem * uncollectedUnits : null;

  const isPoint = treasureType === point;
  const isFlash = treasureType === flash;
  const notDraft = status !== draft;

  return (
    <>
      <Row type="flex" justify="space-between">
        <Typography.Text className="bold">{treasureInfo.noOfUnits[language]}</Typography.Text>
        <Typography.Text>{numberOfUnits}</Typography.Text>
      </Row>

      {isPoint && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">{treasureInfo.totalLcash[language]}</Typography.Text>
          <Typography.Text>{totalPrice}</Typography.Text>
        </Row>
      )}

      {isPoint && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.CollectedItems[language]}
          </Typography.Text>
          <Typography.Text>{collectedUnits}</Typography.Text>
        </Row>
      )}

      {isPoint && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.uncollectedUnits[language]}
          </Typography.Text>
          <Typography.Text>{uncollectedUnits}</Typography.Text>
        </Row>
      )}

      {isFlash && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.CollectedItems[language]}
          </Typography.Text>
          <Typography.Text>{collectedUnits}</Typography.Text>
        </Row>
      )}

       {isFlash && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.uncollectedUnits[language]}
          </Typography.Text>
          <Typography.Text>{uncollectedUnits}</Typography.Text>
        </Row>
      )}

      {isPoint && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.uncollectedBalance[language]}
          </Typography.Text>
          <Typography.Text>{uncollectedBalance}</Typography.Text>
        </Row>
      )}

      {isFlash && notDraft && (
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {treasureInfo.uncollectedBalance[language]}
          </Typography.Text>
          <Typography.Text>{uncollectedBalance}</Typography.Text>
        </Row>
      )}
    </>
  );
}

TreasureInfoNumberOfUnits.propTypes = {
  language: PropTypes.string,
  numberOfUnits: PropTypes.number,
  kitTypeID: PropTypes.number,
  country: PropTypes.string,
  treasureUnits: PropTypes.array,
  treasureType: PropTypes.oneOf([sticker, point, candy, LDiscount,flash]),
  status: PropTypes.oneOf([draft, inReview, approved, declined, pending, finished]),
};

TreasureInfoNumberOfUnits.defaultProps = {
  storeKitList: () => {},
  language: 'EN',
  numberOfUnits: 0,
  treasureUnits: [],
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  country: state.userData.country,
});

const mapDispatchToProps = (dispatch) => ({
  storeKitList: (kitList) => dispatch(kitActions.storeKitList(kitList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreasureInfoNumberOfUnits);
