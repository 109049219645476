import React from 'react';
import { Col, Row, Typography } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import moment from 'moment';
import { connect } from 'react-redux';
import countryData from '../../../../helpers/country.json';
import * as menuActions from '../../../../actions/menuActions';
import * as candyEventActions from '../../../../actions/candyEventActions';
import { candyEventDetails } from '../../../../lib/SubMenuConstants';
import { roleSuperadmin } from '../../../../lib/const';
import PropTypes from 'prop-types';

function CandyEvent(props) {
  const {
    startDate,
    endDate,
    title,
    role,
    ID,
    country,
    openCandyEventDetails,
    candyEventEditParameter,
    closeSubmenu2,
    selectedID,
    challenge_type
    
  } = props;

  const countryName = countryData.find((findCountry) => country === findCountry.alpha2Code);

  const openDetailsHandler = () => {
    closeSubmenu2();
    candyEventEditParameter(candyEventActions.editableParameters.ID, ID);
    candyEventEditParameter(candyEventActions.editableParameters.contestantIndex, null);
    openCandyEventDetails();
  };

  return (
    <Row
      style={{ width: '100%', padding: '0 1em' }}
      onClick={openDetailsHandler}
      type="flex"
      justify="center"
      className={ID === selectedID ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      {challenge_type== "1" &&
       <Row type="flex" justify="start" align="middle" style={{ width: '97%' }}>
        <Col className="treasure-img"></Col>
        <Col style={{ width: '100%' }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Row>
                <Typography.Text className="candyEvent-title">{title}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="candyEvent-text">
                  {moment(startDate, 'x').format('DD/MM/YY')}
                </Typography.Text>
                <Typography.Text className="candyEvent-text"> - </Typography.Text>
                <Typography.Text className="candyEvent-text">
                  {moment(endDate, 'x').format('DD/MM/YY')}
                </Typography.Text>
              </Row>
              {role === roleSuperadmin && (
                <Row className="candyEvent-country-text">{countryName.name}</Row>               
              )}
            </Col>
            <Col>
              <img src={arrow} className="treasure-arrow" />
            </Col>
          </Row>
        </Col>
      </Row>
      }

      
    </Row>
  );
}

CandyEvent.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  country: PropTypes.string,
  ID: PropTypes.number,

  language: PropTypes.string,
  role: PropTypes.number,
  resetCandyEvent: PropTypes.func,
  openCandyEventDetails: PropTypes.func,
  candyEventEditParameter: PropTypes.func,
  closeSubmenu2: PropTypes.func,
  challenge_type:PropTypes.string
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  selectedID: state.candyEvent.ID,
});

const mapDispatchToProps = (dispatch) => ({
  resetCandyEvent: () => dispatch(candyEventActions.resetCandyEvent()),
  openCandyEventDetails: () => dispatch(menuActions.openSubmenu1(candyEventDetails)),
  candyEventEditParameter: (parameterKey, parameterValue) =>
    dispatch(candyEventActions.editParameter(parameterKey, parameterValue)),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(CandyEvent);
