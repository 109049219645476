import React from 'react';
import { Row, Col, Typography } from 'antd';
import Winner from './Winner';
import PropTypes from 'prop-types';
import { baseURL } from '../../../lib/config';
import * as contestStrings from '../../../lib/strings/contest';

function Winners(props) {
  const { topTen, language, prizes } = props;

  const createWinnerProps = (index: number) => {
    if (topTen[index]) {
      return {
        visible: true,
        username: topTen[index].username,
        prize: prizes[index],
        qualifyingVotes: topTen[index].qualifyingRoundVotes,
        finalVotes: topTen[index].finalRoundVotes,
        userAvatar: `${baseURL}/${topTen[index].avatar?.thumbnail.nameOnServer}`,
        qualifyingVideo: `${baseURL}/${topTen[index].freeChoiceVideo}`,
        finalVideo: `${baseURL}/${topTen[index].songPath}`,
      };
    }
    return { visible: false };
  };

  console.log(prizes);

  return (
    <Row>
      <Col>
        <Row type="flex" justify="center">
          <Col>
            <Typography className="contest-winner-title">
              {contestStrings.winners[language]}
            </Typography>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" style={{ marginTop: 45 }}>
          <Col span={8}>
            <Winner place={2} language={language} {...createWinnerProps(1)} />
          </Col>
          <Col span={8}>
            <Winner place={1} language={language} {...createWinnerProps(0)} />
          </Col>
          <Col span={8}>
            <Winner place={3} language={language} {...createWinnerProps(2)} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

Winners.propTypes = {
  language: PropTypes.string,
  topTen: PropTypes.array,
  prizes: PropTypes.array,
};

Winners.defaultProps = {
  topTen: [
    {
      mysqlID: 91,
      _id: '5f1ae52bc6e23b0d7d46a81b',
      avatar: {
        picture: {
          originalName: 'image_1610700124.png',
          size: 42297,
          mimeType: 'image/png',
          nameOnServer: 'MzxLhRLLqXJ7UoDAW2ge2QVgXzSxdQdo',
        },
        thumbnail: {
          originalName: 'image_1610700124.png',
          size: 86834,
          mimeType: 'image/png',
          nameOnServer: 'lJmSJ26npcWWcFvzo5eK4HBgZ5Ew9Dkx',
        },
      },
      phoneNumber: '+385919292933',
      gender: 'male',
      username: 'zeljkohalleeeee',
      email: 'zeljko@clover.studio',
      firstName: 'Zeljko limaaaaaa',
      lastName: 'Halle',
      country: 'HR',
      songPath: '/uploads/yqphosUWY1Xb6qu39jjoEtwXXB0C579E.m3u8',
      songCaption: 'Hxjxxy',
      freeChoiceVideo: '/uploads/X4CdWPJ8nBooZOjtsaPgZLP5GQCpee1y.m3u8',
      description: null,
      qualifyingRoundVotes: 4,
      finalRoundVotes: 1,
    },
    {
      mysqlID: 224,
      _id: '5fc7604ef3735dfc800f53e9',
      avatar: {
        picture: {
          originalName: 'image_1606901890.png',
          size: 19952,
          mimeType: 'image/jpeg',
          nameOnServer: 'pMKKUEgEj1tXuGgp9ABkC5uCNAb5E1H2',
        },
        thumbnail: {
          originalName: 'image_1606901890.png',
          size: 16466,
          mimeType: 'image/jpeg',
          nameOnServer: 'h5BkgLSZNeU7vJwKIdlfOUWCZyR3JO4b',
        },
      },
      phoneNumber: '+385996349051',
      gender: 'male',
      username: 'Doms',
      email: 'doms@gmail.com',
      firstName: 'dominik',
      lastName: 'faks',
      country: 'HR',
      songPath: '/uploads/ThiOxKRd9YW2dudGwjXNntkbn202N722.m3u8',
      songCaption: null,
      freeChoiceVideo: '/uploads/tlWThPezEOPErbalBK9gK5Uod8yE0l3S.m3u8',
      description: null,
      qualifyingRoundVotes: 4,
      finalRoundVotes: 1,
    },
  ],
};

export default Winners;
