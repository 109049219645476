import { roleSuperadmin, roleAdmin, rolePartner, roleMaster } from '../../../../lib/const';
import { superAdmin, admin, partner, master } from '../../../../lib/strings/kitCreation';

const roles = {
  [roleSuperadmin]: superAdmin,
  [roleAdmin]: admin,
  [rolePartner]: partner,
  [roleMaster]: master,
};

export function getRoleString(role: number, language: string) {
  return role && roles[role][language];
}
