import { LanguageMap } from '../interfaces/language';

export const all: LanguageMap<string> = {
  EN: 'All',
  KO: '전부',
};

export const android: LanguageMap<string> = {
  EN: 'Android',
  KO: 'Android',
};

export const ios: LanguageMap<string> = {
  EN: 'iOS',
  KO: 'iOS',
};


export const now: LanguageMap<string> = {
  EN: 'now',
  KO: '지금',
};

export const url: LanguageMap<string> = {
  EN: 'Deeplink/URL',
  KO: 'Deeplink/URL',
};

export const audience: LanguageMap<string> = {
  EN: 'Audience',
  KO: '대상자',
};

export const browse: LanguageMap<string> = {
  EN: 'Browse',
  KO: '검색',
};

export const schedule: LanguageMap<string> = {
  EN: 'Schedule',
  KO: '스케줄',
};

export const selected: LanguageMap<string> = {
  EN: 'selected',
  KO: '선택됨',
};

export const newNotificationButton: LanguageMap<string> = {
  EN: 'Create new notification',
  KO: '새로운 공지 생성하기',
};

export const templateButton: LanguageMap<string> = {
  EN: 'Use a template',
  KO: '템플릿 사용하기',
};

export const scheduledButton: LanguageMap<string> = {
  EN: 'Scheduled',
  KO: '등록 완료',
};

export const sentButton: LanguageMap<string> = {
  EN: 'Sent',
  KO: '보내기 완료',
};

export const contestantsTitle: LanguageMap<string> = {
  EN: 'selected',
  KO: '선택됨',
};

export const selectAll: LanguageMap<string> = {
  EN: 'Select all',
  KO: '모두 선택',
};

export const loaMoaIdol: LanguageMap<string> = {
  EN: 'LoaMoa Idol',
  KO: 'LoaMoa 아이돌',
};

export const typeVoters: LanguageMap<string> = {
  EN: 'Voters',
  KO: '유권자',
};

export const typeContestants: LanguageMap<string> = {
  EN: 'Contestants',
  KO: '참가자',
};

export const browseButton: LanguageMap<string> = {
  EN: 'Browse',
  KO: '검색',
};

export const generalLabel: LanguageMap<string> = {
  EN: 'General',
  KO: '전체',
};

export const optionImmediately: LanguageMap<string> = {
  EN: 'Send Immediately',
  KO: '지금 보내기',
};

export const optionSchedule: LanguageMap<string> = {
  EN: 'Schedule sending at...',
  KO: '…에게 스케줄을 보내고 있습니다',
};

export const cancelButton: LanguageMap<string> = {
  EN: 'Cancel',
  KO: '취소',
};

export const saveButton: LanguageMap<string> = {
  EN: 'Save',
  KO: '저장',
};

export const previewLabel: LanguageMap<string> = {
  EN: 'Preview',
  KO: '미리보기',
};

export const titleEdit: LanguageMap<string> = {
  EN: 'Edit Notification',
  KO: '공지 수정',
};

export const createNotif: LanguageMap<string> = {
  EN: 'New notification',
  KO: '새로운 공지',
};

export const typeValidation: LanguageMap<string> = {
  EN: 'Pick a notification type!',
  KO: '공지 유형을 골라주세요',
};

export const idolValidation: LanguageMap<string> = {
  EN: 'Select idol for which you wish to notify the users!',
  KO: '당신의 아이돌을 선택해주세요',
};

export const groupValidation: LanguageMap<string> = {
  EN: 'Select user group which you wish to notify!',
  KO: 'Select user group which you wish to notify!',
};

export const nameValidation: LanguageMap<string> = {
  EN: 'Notification name is required!',
  KO: '공지 제목을 입력해주세요',
};

export const msgValidation: LanguageMap<string> = {
  EN: 'Notification message is required!',
  KO: '공지 내용을 입력해주세요',
};

export const urlValidation: LanguageMap<string> = {
  EN: 'Notification URL is required!',
  KO: '공지 URL을 설정해주세요',
};

export const noContestants: LanguageMap<string> = {
  EN: 'No contestants selected',
  KO: '참가자를 선택해주세요',
};

export const votedForLabel: LanguageMap<string> = {
  EN: 'Voted for:',
  KO: '투표하기',
};

export const idol: LanguageMap<string> = {
  EN: 'Idol',
  KO: 'Idol',
};

export const titleText: LanguageMap<string> = {
  EN: 'Title',
  KO: 'Title',
};

export const category: LanguageMap<string> = {
  EN: 'Category:',
  KO: 'Category:',
};

export const select: LanguageMap<string> = {
  EN: 'Select',
  KO: 'Select',
};

export const item: LanguageMap<string> = {
  EN: 'Item:',
  KO: 'Item:',
};

export const selectIdol: LanguageMap<string> = {
  EN: 'Select idol',
  KO: 'Select idol',
};

export const voters: LanguageMap<string> = {
  EN: 'Voters',
  KO: 'Voters',
};

export const contestants: LanguageMap<string> = {
  EN: 'Contestants',
  KO: 'Contestants',
};

export const contestantsNotCompleted: LanguageMap<string> = {
  EN: 'Contestants not completed',
  KO: 'Contestants not completed',
};

export const applicants: LanguageMap<string> = {
  EN: 'Applicants',
  KO: 'Applicants',
};

export const title: LanguageMap<string> = {
  EN: 'Title:',
  KO: 'Title:',
};

export const placeholderTitle: LanguageMap<string> = {
  EN: 'Add title',
  KO: 'Add title',
};

export const message: LanguageMap<string> = {
  EN: 'Message:',
  KO: 'Message:',
};

export const placeholderMessage: LanguageMap<string> = {
  EN: 'Add message',
  KO: 'Add message',
};
