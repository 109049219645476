import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { store } from '../../../helpers/store';

import { academyportrait, academylandscape } from '../../../assets/templates/academy';
import { agencyportrait, agencylandscape } from '../../../assets/templates/agency';
import { barportrait, barlandscape } from '../../../assets/templates/bar';
import { basicportrait, basiclandscape } from '../../../assets/templates/basic';
import { beautyportrait, beautylandscape } from '../../../assets/templates/beauty';
import { cafeportrait, cafelandscape } from '../../../assets/templates/cafe';
import { campaignportrait, campaignlandscape } from '../../../assets/templates/campaign';
import { clinicportrait, cliniclandscape } from '../../../assets/templates/clinic';
import { fashionportrait, fashionlandscape } from '../../../assets/templates/fashion';
import { foodportrait, foodlandscape } from '../../../assets/templates/food';
import { jobportrait, joblandscape } from '../../../assets/templates/job';
import { productportrait, productlandscape } from '../../../assets/templates/product';
import { promotionportrait, promotionlandscape } from '../../../assets/templates/promotion';
import { saleportrait, salelandscape } from '../../../assets/templates/sale';
import { storeportrait, storelandscape } from '../../../assets/templates/store';

const language = store.getState().authentication.language;

const selectorCategories = [
  { name: treasureCreation.academy[language], value: 'academy' },
  { name: treasureCreation.agency[language], value: 'agency' },
  { name: treasureCreation.bar[language], value: 'bar' },
  { name: treasureCreation.basic[language], value: 'basic' },
  { name: treasureCreation.beauty[language], value: 'beauty' },
  { name: treasureCreation.cafe[language], value: 'cafe' },
  { name: treasureCreation.campaign[language], value: 'campaign' },
  { name: treasureCreation.clinic[language], value: 'clinic' },
  { name: treasureCreation.fashion[language], value: 'fashion' },
  { name: treasureCreation.food[language], value: 'food' },
  { name: treasureCreation.job[language], value: 'job' },
  { name: treasureCreation.product[language], value: 'product' },
  { name: treasureCreation.promotion[language], value: 'promotion' },
  { name: treasureCreation.sale[language], value: 'sale' },
  { name: treasureCreation.store[language], value: 'store' },
];

export {
  selectorCategories,
  academyportrait,
  academylandscape,
  agencyportrait,
  agencylandscape,
  barportrait,
  barlandscape,
  basicportrait,
  basiclandscape,
  beautyportrait,
  beautylandscape,
  cafeportrait,
  cafelandscape,
  campaignportrait,
  campaignlandscape,
  clinicportrait,
  cliniclandscape,
  fashionportrait,
  fashionlandscape,
  foodportrait,
  foodlandscape,
  jobportrait,
  joblandscape,
  productportrait,
  productlandscape,
  promotionportrait,
  promotionlandscape,
  saleportrait,
  salelandscape,
  storeportrait,
  storelandscape,
};
