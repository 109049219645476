import React from 'react';
import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { baseURL } from '../../../../lib/config';
import burgerKing from '../../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import logoPic from '../../../../../src/assets/logoPic.png';
import { FileUpload, UpdateSetting } from '../../../../services/domeService';
import { connect } from 'react-redux';


class DomeTicket extends React.Component<any, any> {
    static contextType = AppContext;
    constructor(props) {
        super(props);
    }

    render() {
        const {translation}=this.props
        return (
            <div id="tab-1" className="tab-pane">
                <div className="mb-10">
                    <i className="blueBox mr-5"></i>
                    <span className="text-primary mr-5">{translation('Ticket Box')}</span>
                </div>
                <div className='text-center'>
                    <figure className='mb-3'>
                        <img src={logoPic} alt="logo" />
                    </figure>
                    <h2 className='text-muted mb-0'>{translation('Coming soon')}</h2>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state:any) => ({
    translation: state.userData.translation,
  });
  export default connect(mapStateToProps, null) (DomeTicket);