import { locationConstants } from '../constants/locationConstants';

export const mapActions = {
  storeInstance,
};

function storeInstance(mapInstance: any) {
  return (dispatch: (arg0: { type: string; mapInstance: any }) => void) => {
    dispatch(storeInstance(mapInstance));
  };
  function storeInstance(mapInstance: any) {
    return { type: locationConstants.MAP_INSTANCE, mapInstance };
  }
}
