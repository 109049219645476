import agency1 from './agency1.JPG';
import agency2 from './agency2.JPG';
import agency3 from './agency3.JPG';
import agency4 from './agency4.JPG';
import agency5 from './agency5.JPG';
import agency6 from './agency6.JPG';
import agency7 from './agency7.JPG';
import agency8 from './agency8.JPG';
import agency9 from './agency9.JPG';
import agency10 from './agency10.JPG';

import agency1_1 from './agency1.1.JPG';
import agency2_1 from './agency2.1.JPG';
import agency3_1 from './agency3.1.JPG';
import agency4_1 from './agency4.1.JPG';
import agency5_1 from './agency5.1.JPG';
import agency6_1 from './agency6.1.JPG';
import agency7_1 from './agency7.1.JPG';
import agency8_1 from './agency8.1.JPG';
import agency9_1 from './agency9.1.JPG';
import agency10_1 from './agency10.1.JPG';

export const agencyportrait = [
  { id: 1, src: agency1 },
  { id: 2, src: agency2 },
  { id: 3, src: agency3 },
  { id: 4, src: agency4 },
  { id: 5, src: agency5 },
  { id: 6, src: agency6 },
  { id: 7, src: agency7 },
  { id: 8, src: agency8 },
  { id: 9, src: agency9 },
  { id: 10, src: agency10 },
];

export const agencylandscape = [
  { id: 1, src: agency1_1 },
  { id: 2, src: agency2_1 },
  { id: 3, src: agency3_1 },
  { id: 4, src: agency4_1 },
  { id: 5, src: agency5_1 },
  { id: 6, src: agency6_1 },
  { id: 7, src: agency7_1 },
  { id: 8, src: agency8_1 },
  { id: 9, src: agency9_1 },
  { id: 10, src: agency10_1 },
];
