import React from 'react';
import { Form, Slider, Row } from 'antd';
import * as treasureActions from '../../../actions/treasureActions';
import { connect } from 'react-redux';
import locationDelete from '../../../assets/location_delete_icon.svg';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { languages } from '../../../lib/interfaces/language';

interface MessagingRadiusFormProps {
  enterRadius?: (radius: number, messageLocationID_UI: number) => void;
  messageLocationID_UI: number;
  locationID_UI: number;
  locations: any;
  messageLocations: any;
  deleteMessagingRadius: (messageLocationID_UI: number) => void;
  language?: languages;
}

const mapStateToProps = (state: {
  locations: any;
  messageLocations: any;
  authentication: { language: languages };
}) => {
  const { locations, messageLocations, authentication } = state;
  const { language } = authentication;
  return { locations, messageLocations, language };
};

const actionCreators = {
  enterRadius: treasureActions.enterMessagingRadius,
  deleteMessagingRadius: treasureActions.deleteMessagingRadius,
};

const MessagingRadiusForm = (props: MessagingRadiusFormProps) => {
  const handleChange = (event: any) => {
    props.enterRadius(event, props.messageLocationID_UI);
  };

  const { language } = props;
  return (
    <>
      <Form.Item
        className="roboto-regular"
        label={
          <Row type="flex" justify="space-between">
            <span>
              {`${treasureCreation.MessagingRadiusFormLabel[language]} #${
                props.messageLocationID_UI + 1
              }:`}
            </span>
            {props.messageLocations.length > 1 ? (
              <Row type="flex" justify="space-between" align="top">
                <img
                  className="icon-filter"
                  src={locationDelete}
                  onClick={() => props.deleteMessagingRadius(props.messageLocationID_UI)}
                />
              </Row>
            ) : null}
          </Row>
        }
      >
        <Row className="slider">
          <Slider
            onAfterChange={handleChange}
            min={0.001}
            step={0.001}
            defaultValue={props.messageLocations[props.messageLocationID_UI].radius}
            max={props.locations[props.locationID_UI].radius}
          />
        </Row>
      </Form.Item>
    </>
  );
};

export default connect(mapStateToProps, actionCreators)(MessagingRadiusForm);
