import React, { useState, useContext } from 'react';
import { Row, Tabs, Col, Table, Carousel, Checkbox, Select, Typography, Input } from 'antd';
import closeIcon from '../../../../assets/closeXsmall.svg';
import rightcheckicon_green from '../../../../assets/rightcheckicon_green.svg';
import AppContext from '../../../../AppContext';
import { VoucherApi, usernameVoucher } from '../../../../services/domeService';


const AdminVoucher = () => {
  const [inputData, setInputData] = useState('');
  const [value, setvalue] = useState('')
  const [count, setCount] = useState([{
    name: "",
    isChecked: ""
  }]);
  const profile = useContext(AppContext)

  const closeadminVoucher = () => {
    profile.profile['adminVoucher'] = false;
    profile.setContextData(profile.profile);
  };

  const handlerData = (e: any, id: any) => {
    const updatedCount = count.map((item, index) => {    
        if (index === id) {      
           return { ...item, name:e.target.value };     }   
          return item; 
       });   
        setCount(updatedCount);
    }

const handleSubmit = async (item,ind) => {
      let data = await usernameVoucher(item.name);
      setvalue(data.error)
      const updatedCount = data.error && count.map((item, index) => {    
        if (index === ind) {      
           return { ...item, isChecked:data.error};}   
          return item; 
       });   
       data.error && setCount(updatedCount);
    }

    const handleClickData = async () => {
      const arraydata=[]
      count.map(item=>arraydata.push(item.name.trim()))
      console.log(arraydata,"aarsry")
      if (arraydata[0].length>0) {
        let data = await VoucherApi(arraydata.toString());
      }
    }

    const handlePlusClick = () => {
      const teamps = [...count]
      teamps.push({ name: "", isChecked: "" })
      setCount(teamps);
    };
    return (
      <div>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="third-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62; Voucher
              </h4>
              <div className="fx-aic">
                <i onClick={closeadminVoucher} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer scrollSection" style={{ height: '350px' }}>
              <div className='voucher-inner-dv mt-50'>   
                {count.map((item, id) => (
                  <Row className='mb-10 mb-20'>
                    <Col span={20} offset={2}>
                      <Row gutter={8} type='flex' align='middle'>
                        <Col className="gutter-row" span={6}>Username</Col>
                        <Col className="gutter-row" span={14}>
                          <Input value={item.name} placeholder='username' onChange={(e) => handlerData(e, id)} />
                        </Col>
                        {item.isChecked == "Username is taken." ? <Col className="gutter-row" span={4}>
                          <img src={rightcheckicon_green} alt="checked" />
                        </Col> :
                          <Col className="gutter-row" span={4}>
                            <button value={item.isChecked} className='ant-btn xs min-auto' onClick={(e)=>handleSubmit(item,id)} >check</button>
                          </Col>}
                      </Row>
                    </Col>
                  </Row>
                ))}
                <Row className='mt-30'>
                  <Col span={20} offset={2} className='text-right'>
                    <button onClick={handlePlusClick} className='ant-btn'>+</button>
                  </Col>
                </Row>
              </div>



              <Row type='flex' justify='center'>
                <button onClick={closeadminVoucher} className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
                <button onClick={handleClickData} className='ant-btn yellow-gradient xs ml-5'>Enter</button>
              </Row>
            </div>
          </div>
        </Row>
      </div>
    );
  }; export default AdminVoucher