export const contestConstants = {
  SHOW_CONTEST_FORM: 'SHOW_CONTEST_FORM',
  UPDATE_INPUT: 'UPDATE_INPUT',
  STORE_CONTESTS: 'STORE_CONTESTS',
  SHOW_CONTEST_DETAILS: 'SHOW_CONTEST_DETAILS',
  GET_CONTEST_BY_ID: 'GET_CONTEST_BY_ID',
  EDIT_CONTEST: 'EDIT_CONTEST',
  DELETE_CONTEST: 'DELETE_CONTEST',
  CONTEST_IMAGE: 'CONTEST_IMAGE',
  SHOW_CONTESTANTS_LIST: 'SHOW_CONTESTANTS_LIST',
  SHOW_CONTESTANT: 'SHOW_CONTESTANT',
  FETCH_CONTESTS: 'FETCH_CONTESTS',
  STORE_FILES: 'STORE_FILES',
  PURGE_CONTEST_DATA: 'PURGE_CONTEST_DATA',
  CONTEST_ID: 'CONTEST_ID',
  STORE_GUIDELINE_SONG_ID: 'STORE_GUIDELINE_SONG_ID',
  CONTESTANT_TYPE: 'CONTESTANT_TYPE',
  STORE_FILE_PATH: 'STORE_FILE_PATH',
  REMOVE_PENDING_CONTESTANT: 'REMOVE_PENDING_CONTESTANT',
  CONTESTANTS_RESET_STATE: 'CONTESTANTS_RESET_STATE',
  CONTESTANTS_UPDATE_STATE: 'CONTESTANTS_UPDATE_STATE',
};
