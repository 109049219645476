import React, { useMemo } from 'react';
import { Row, Col, Spin, Typography } from 'antd';
import moment from 'moment';
import ContestantsReviewListItem from './ContestantsReviewListItem';
import * as contestStrings from '../../../lib/strings/contest';
import PropTypes from 'prop-types';

function ContestantsReviewList(props) {
  const { loading, data, selectedContestantIndex, onClick, language } = props;

  if (loading) {
    return (
      <Row type="flex" justify="center" className="contestants-user-list">
        <Spin size="large" />
      </Row>
    );
  }

  const dataToShow = useMemo(() => {
    let length = 0;
    const contestants = data.map((contestant) => {
      if (contestant.freeChoiceVideo && contestant.freeChoiceVideoStatus === 'pending') {
        length += 1;
        return contestant;
      } else if (
        contestant.freeChoiceVideoStatus === 'approved' &&
        contestant.songPath &&
        contestant.status === 'pending'
      ) {
        length += 1;
        return contestant;
      }
      return null;
    });
    return { contestants, length };
  }, [data]);

  const reviewOrReviews =
    dataToShow.length === 0
      ? contestStrings.pendingReview[language]
      : contestStrings.pendingReviews[language];

  return (
    <Row type="flex" justify="center" className="contestants-user-list">
      <Col span={24}>
        <Typography.Text className="contestant-count">
          {dataToShow.length} {reviewOrReviews}
        </Typography.Text>
        {dataToShow.contestants?.map((contestant, index) => {
          if (!contestant) {
            return null;
          }
          return (
            <ContestantsReviewListItem
              onClick={onClick}
              key={contestant.contestantID}
              index={index}
              username={contestant.username}
              selected={selectedContestantIndex === index}
              text={moment(contestant.freeSongUploadTimestamp).format('DD/MM/YYYY')}
              profilePicUrl={contestant.avatar?.thumbnail?.nameOnServer}
            />
          );
        })}
      </Col>
    </Row>
  );
}

ContestantsReviewList.propTypes = {
  language: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  selectedContestantIndex: PropTypes.number,
};

ContestantsReviewList.defaultProps = {
  loading: true,
  data: [],
};

export default ContestantsReviewList;
