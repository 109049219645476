import { menuConstants } from '../constants/menuConstants';
import * as treasureService from '../services/treasureService';
import { treasureConstants } from '../constants/treasureConstants';
import { alertActions } from './alertActions';
import { locationConstants } from '../constants/locationConstants';
import { store, persistor } from '../helpers/store';
import * as constant from '../lib/const';
import { detailsConstants } from '../constants/treasureDetailsConstants';
import { baseURL } from '../lib/config';
import { kitConstants } from '../constants/kitConstants';
import { getKits } from '../services/kitService';
import { notification } from 'antd';
import {
  treasureErrMsg,
  treasureErrDesc,
  kitErrMsg,
  charErrMsg,
  locErrMsg,
} from '../lib/strings/error';
import { languages } from '../lib/interfaces/language';
import { getUserData } from '../services/signinService';
import { userConstants } from '../constants/signinConstants';
import { LOCAL_STORAGE_USERDATA } from '../lib/config';

const language: languages = store.getState().authentication.language;

export function openTreasureCreation() {
  return (dispatch: (arg0: { type: string }) => void) => {
    dispatch(treasureLoading(true));
    dispatch(openTreasureCreationUI());

    (async () => {
      try {
        const userData = store.getState().userData;
        const treasureType =
          userData.role >= constant.roleAdmin ? constant.sticker : constant.point;
        const response = await treasureService.createTreasure(treasureType);

        if (!response || !response.data || !response.data.treasure) {
          notification.error({
            message: treasureErrMsg[language],
            description: treasureErrDesc[language],
          });
          throw 'Failed to create treasure';
        }

        dispatch(success(response.data.treasure));
        // dispatch(updateTreasureType(treasureType));
        dispatch(updateTreasureType(5));

        const kitTypes = await getKits();
        if (kitTypes) {
          dispatch(storeKitTypes(kitTypes));
        } else {
          notification.error({
            message: kitErrMsg[language],
          });
          throw 'failed to fecth kits';
        }

        const getCharacters = await treasureService.getStickerCharacters();
        if (getCharacters) {
          dispatch(storeCharacters(getCharacters));
        } else {
          notification.error({
            message: charErrMsg[language],
          });
        }

        const treasure = response.data.treasure;

        const address = store.getState().address;

        const responseCreateLocation = await treasureService.createLocation(
          treasure.ID,
          address.lat,
          address.lng,
          address.radius
        );

        if (
          !responseCreateLocation ||
          !responseCreateLocation.data ||
          !responseCreateLocation.data.location
        ) {
          notification.error({
            message: locErrMsg[language],
          });
          throw 'Failed to create location';
        }

        const location = responseCreateLocation.data.location;
        dispatch(locationSuccess(location));

        if (treasureType === constant.point) {
          const responseCreateMessageLocation = await treasureService.messageLocation(
            location,
            location.radius
          );

          if (!responseCreateMessageLocation) {
            notification.error({
              message: 'Error while creating messaging radius',
            });
            throw 'Failed to create message location';
          }

          const messageLocation = responseCreateMessageLocation;

          dispatch(messageLocationSuccess(messageLocation));
        }

        const selectedLocation = store.getState().selectedLocation;
        dispatch(treasureUnitsNotSent(selectedLocation)); //todo ( falseを入れてるの怪しい )
      } catch (e) {
        dispatch(alertActions.error(e));
      }
      dispatch(treasureLoading(false));
    })();
  };

  function success(response: any) {
    return { type: treasureConstants.INITIAL, response };
  }
  function updateTreasureType(treasureType: number) {
    return { type: treasureConstants.TREASURE_TYPE, treasureType };
  }
  function storeCharacters(characters: any) {
    return { type: treasureConstants.SAVE_CHARACTERS, characters };
  }
  function openTreasureCreationUI() {
    return { type: menuConstants.OPEN_TREASURE_CREATION };
  }
  function locationSuccess(response: any) {
    return { type: locationConstants.CREATE_LOCATION, response };
  }
  function messageLocationSuccess(response: any) {
    return { type: locationConstants.CREATE_MSG_LOCATION, response };
  }
  function treasureUnitsNotSent(locationID_UI: number) {
    return { type: locationConstants.NOT_SENT, locationID_UI };
  }
  function storeKitTypes(kitList: any) {
    return { type: kitConstants.STORE_KITS, kitList };
  }
  function treasureLoading(loadingState: boolean) {
    return { type: treasureConstants.AD_MAKER_INPUT, name: 'loading', value: loadingState };
  }
}

export function cancelTreasureCreation(treasureID: number) {
  return (dispatch: (arg0: { type: string }) => void) => {
    (async () => {
      dispatch(cancel());
      treasureService.deleteTreasure(treasureID);
      dispatch(resetReducers());
    })();
  };
  function cancel() {
    return { type: menuConstants.CANCEL_TREASURE_CREATION };
  }
  function resetReducers() {
    return { type: locationConstants.RESET_REDUCERS };
  }
}

export function reviewCreatedTreasure() {
  return (dispatch: (arg0: { type: string }) => void) => {
    dispatch(review());
  };
  function review() {
    return { type: menuConstants.OPEN_REVIEW_SCREEN };
  }
}

export function goBackToTreasureCreation() {
  return (dispatch: (arg0: { type: string }) => void) => {
    dispatch(goBack());
    persistor.pause();
  };
  function goBack() {
    return { type: menuConstants.BACK_TO_TREASURE_CREATION };
  }
}
export function finishReview(role: number,
  additional_prize:number,
  MorePoints:number,
  MorePoints_total:number,
  TotalLcash:number,
  place_1st:any,
    place_2nd:any,
    place_3rd:any,
    place_4th:any,
    place_5th:any,
    prize_1st:any,
    prize_2nd:any,
    prize_3rd:any,  
    prize_4th:any,
    prize_5th:any,
   LDiscountId?: number | null)
   
   {
  return (dispatch: (arg0: { type: any }) => void) => {
    (async () => {
      const currentTreasure = store.getState().treasure;

      if (
        currentTreasure.treasureType === constant.sticker &&
        currentTreasure.stickerType === 'new'
      ) {
        const updateStickerRes = await treasureService.updateStickerProps(
          currentTreasure.ID,
          currentTreasure.characterID,
          currentTreasure.sectionID,
          currentTreasure.sectionPosition
        );
        if (updateStickerRes.error) {
          return;
        }
        const updateImageRes = await treasureService.treasureUpdateImage(
          currentTreasure.ID,
          currentTreasure.image
        );
        if (updateImageRes.error) {
          return;
        }
       }

       if (role < constant.roleAdmin) {
        
        // constant.approved should be constant.pending once review will be implemented
        const res = await treasureService.treasureSendToReview(
          currentTreasure.ID,
          constant.approved,
          currentTreasure.totalPrice,
          true,
          LDiscountId,
          additional_prize,
          MorePoints,
          MorePoints_total,
         TotalLcash,
         place_1st,
        place_2nd,
        place_3rd,
        place_4th,
        place_5th,
        prize_1st,
        prize_2nd,
        prize_3rd,
        prize_4th,
        prize_5th,
        );
        if (res.error) {
          return;
        }
      } else {
        const res = await treasureService.treasureUpdate(
          currentTreasure.ID,
          'status',
          constant.approved
        );
        if (res.error) {
          return;
        }
      }
      // to get new balance and showPartnerInput
      if (role < constant.roleAdmin) {
        const newUserData = await getUserData(store.getState().userData._id);
        dispatch(updateUserData(newUserData));
        localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify(newUserData));
      }
      dispatch(openMain());
      setTimeout(() => {
        dispatch(resetTreasureReducers());
      }, 500);
    })();
  };
  function openMain() {
    return { type: menuConstants.OPEN_MAIN };
  }
  function resetTreasureReducers() {
    return { type: locationConstants.RESET_REDUCERS };
  }
  function updateUserData(newUserData) {
    return { type: userConstants.STORE_USER_DATA, user: newUserData };
  }
}

export function closeDetailsUI() {
  return (dispatch: (arg0: { type: string }) => void) => {
    dispatch(close());
  };
  function close() {
    return { type: detailsConstants.CLOSE };
  }
}

export function showLocation(locationID_UI: number) {
  return (dispatch: (arg0: { type: string; locationID_UI: number }) => void) => {
    dispatch(show());
  };
  function show() {
    return { type: menuConstants.OPEN_LOCATION_UI, locationID_UI };
  }
}

export function hideLocation(locationID_UI: number) {
  return (dispatch: (arg0: { type: string; locationID_UI: number }) => void) => {
    dispatch(hide());
  };
  function hide() {
    return { type: menuConstants.CLOSE_LOCATION_UI, locationID_UI };
  }
}

export function editTreasure() {
  return (dispatch: any) => {
    (async () => {
      //do everything as when creating treasure + adjust locations/msgLocations/units
      //const {omittedPropertyA, omittedPropertyB, ...remainingObject} = originalObject;
      const role = store.getState().userData.role;
      const details = store.getState().treasureDetails;
      const {
        clients,
        created,
        lastStatusUpdate,
        ownerInfo,
        messageLocations,
        locations,
        treasureUnits,
        messagingOptions,
        mediaPath,
        unitImage,
        exposureDuration,
        hashtags,
        treasureType,
        ...treasure
      } = details;

      treasure.treasureType = role >= constant.roleAdmin ? treasureType : constant.point;

      let imgUrl: string;
      switch (treasure.treasureType) {
        case constant.sticker:
          imgUrl = unitImage !== null ? `${baseURL}${unitImage.pic.path}` : null;
          break;
        case constant.point:
          imgUrl = mediaPath !== null ? `${baseURL}${mediaPath.pic.path}` : null;
          break;
        default:
          break;
      }
      treasure.imgUrl = imgUrl;

      let hashtagsStrings: Array<string> = hashtags.map((hashtag: { name: any }) => {
        return hashtag.name;
      });

      treasure.hashtags = hashtagsStrings;

      let duration;
      let durationUnit;
      if (exposureDuration <= 24) {
        duration = exposureDuration.toFixed();
        durationUnit = 'h';
      } else if (exposureDuration > 24 && exposureDuration <= 24 * 7) {
        const hoursToDays = exposureDuration / 24;
        duration = hoursToDays.toFixed();
        durationUnit = 'day';
      } else if (exposureDuration > 24 * 7) {
        const hoursToWeeks = exposureDuration / (24 * 7);
        duration = hoursToWeeks.toFixed();
        durationUnit = 'week';
      }
      treasure.duration = duration;
      treasure.durationUnit = durationUnit;

      if (treasureType === constant.point) {
        let messagingOptionsArray: Array<any> = [];
        if (messagingOptions.length > 0) {
          messagingOptions.forEach((msgOption: { optionID: any }) => {
            messagingOptionsArray.push(msgOption.optionID.toString());
          });
          treasure.messagingOptions = messagingOptionsArray;
        }
      }

      const kitTypes = await getKits();
      dispatch(storeKitTypes(kitTypes));

      //in case treasure will be switched to sticker
      const getCharacters = await treasureService.getStickerCharacters();
      dispatch(storeCharacters(getCharacters));

      const address = store.getState().address;

      //if there's no location
      if (locations.length === 0) {
        const responseCreateLocation = await treasureService.createLocation(
          treasure.ID,
          address.lat,
          address.lng,
          address.radius
        );

        if (
          !responseCreateLocation ||
          !responseCreateLocation.data ||
          !responseCreateLocation.data.location
        )
          throw 'Failed to create location';

        const location = responseCreateLocation.data.location;
        dispatch(locationSuccess(location));

        if (treasureType === constant.point) {
          const responseCreateMessageLocation = await treasureService.messageLocation(
            location,
            location.radius
          );

          if (
            !responseCreateMessageLocation ||
            !responseCreateMessageLocation.data ||
            !responseCreateMessageLocation.data.location
          )
            throw 'Failed to create message location';

          const messageLocation = responseCreateMessageLocation.data.location;

          dispatch(messageLocationSuccess(messageLocation));
        }

        const selectedLocation = store.getState().selectedLocation;
        dispatch(treasureUnitsNotSent(selectedLocation));
      } else {
        let count = 0;
        if (role < constant.roleAdmin) {
          locations.forEach((location: { locationPrice: number; unitCopies: any }) => {
            location.locationPrice =
              Number(treasure.pointsPerUnit) * Number(location.unitCopies) * 0.2;
            count = count + Number(location.unitCopies);
          });
        }

        treasure.totalPrice = count * Number(treasure.pointsPerUnit) * 0.2;

        dispatch(addLocations(locations));

        if (treasureType === constant.point && messageLocations)
          dispatch(addMsgLocations(messageLocations));
      }

      //get id of every location
      const locationIDS = locations.map((location: { ID: any }) => location.ID);
      //to display correctly and later edit, filter units by locationID
      //fills both treasureUnitLocation and treasureUnitResponse reducers
      if (treasureUnits) {
        locationIDS.forEach((ID: number, index: number) => {
          const filtered = treasureUnits.filter((obj: any) => {
            if (obj.locationID === ID) {
              return obj;
            }
          });
          dispatch(storeUnitsByLocations(filtered, index));
        });
      }

      dispatch(edit(treasure));
      const mapInstance = store.getState().address.mapInstance;
      mapInstance.panTo({ lat: locations[0].latitude, lng: locations[0].longitude });
    })();
  };
  function edit(treasure: any) {
    return { type: menuConstants.EDIT_DRAFT, treasure };
  }
  function addLocations(locations: any) {
    return { type: locationConstants.LOCATION_FROM_DRAFT, locations };
  }
  function addMsgLocations(messageLocations: any) {
    return { type: locationConstants.MSG_LOCATION_FROM_DRAFT, messageLocations };
  }
  function storeUnitsByLocations(treasureUnits: any, locationID_UI: number) {
    return { type: locationConstants.UNITS_FROM_DRAFTS, treasureUnits, locationID_UI };
  }
  function storeCharacters(characters: any) {
    return { type: treasureConstants.SAVE_CHARACTERS, characters };
  }
  function locationSuccess(response: any) {
    return { type: locationConstants.CREATE_LOCATION, response };
  }
  function messageLocationSuccess(response: any) {
    return { type: locationConstants.CREATE_MSG_LOCATION, response };
  }
  function treasureUnitsNotSent(locationID_UI: number) {
    return { type: locationConstants.NOT_SENT, locationID_UI };
  }
  function storeKitTypes(kitList: any) {
    return { type: kitConstants.STORE_KITS, kitList };
  }
}

export function openSubmenuFullScreen(menu: string) {
  return { type: menuConstants.OPEN_SUBMENU_FULL_SCREEN, menu: menu };
}

export function closeSubmenuFullScreen() {
  return { type: menuConstants.CLOSE_SUBMENU_FULL_SCREEN };
}

export function changeMenu(menu: string) {
  return { type: menuConstants.CHANGE_MENU, menu: menu };
}

export function openCreateCandyEvent() {
  return { type: menuConstants.OPEN_CREATE_CANDY_EVENT };
}

export function openCreateCandyChallenge() {
  return { type: menuConstants.OPEN_CREATE_CANDY_CHALLENGE };
}

export function openSubmenu1(menu: string) {
  return { type: menuConstants.OPEN_SUBMENU_1, menu };
}

export function openSubmenu2(menu: string) {
  return { type: menuConstants.OPEN_SUBMENU_2, menu };
}

export function closeSubmenu1() {
  return { type: menuConstants.CLOSE_SUBMENU_1 };
}

export function closeSubmenu2() {
  return { type: menuConstants.CLOSE_SUBMENU_2 };
}

export function closeMenu() {
  // window.location.reload()
  return { type: menuConstants.CLOSE_MENU };

}
