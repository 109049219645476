import campaign1 from './campaign1.JPG';
import campaign2 from './campaign2.JPG';
import campaign3 from './campaign3.JPG';
import campaign4 from './campaign4.JPG';
import campaign5 from './campaign5.JPG';
import campaign6 from './campaign6.JPG';
import campaign7 from './campaign7.JPG';
import campaign8 from './campaign8.JPG';
import campaign9 from './campaign9.JPG';
import campaign10 from './campaign10.JPG';

import campaign1_1 from './campaign1.1.JPG';
import campaign2_1 from './campaign2.1.JPG';
import campaign3_1 from './campaign3.1.JPG';
import campaign4_1 from './campaign4.1.JPG';
import campaign5_1 from './campaign5.1.JPG';
import campaign6_1 from './campaign6.1.JPG';
import campaign7_1 from './campaign7.1.JPG';
import campaign8_1 from './campaign8.1.JPG';
import campaign9_1 from './campaign9.1.JPG';
import campaign10_1 from './campaign10.1.JPG';

export const campaignportrait = [
  { id: 1, src: campaign1 },
  { id: 2, src: campaign2 },
  { id: 3, src: campaign3 },
  { id: 4, src: campaign4 },
  { id: 5, src: campaign5 },
  { id: 6, src: campaign6 },
  { id: 7, src: campaign7 },
  { id: 8, src: campaign8 },
  { id: 9, src: campaign9 },
  { id: 10, src: campaign10 },
];

export const campaignlandscape = [
  { id: 1, src: campaign1_1 },
  { id: 2, src: campaign2_1 },
  { id: 3, src: campaign3_1 },
  { id: 4, src: campaign4_1 },
  { id: 5, src: campaign5_1 },
  { id: 6, src: campaign6_1 },
  { id: 7, src: campaign7_1 },
  { id: 8, src: campaign8_1 },
  { id: 9, src: campaign9_1 },
  { id: 10, src: campaign10_1 },
];
