import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Spin, Col } from 'antd';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import CandyEventList from './CandyEventList';
import SelectorGray from '../../../../components/SelectorGray';
import { roleAdmin, roleSuperadmin, ultrasuperadmin } from '../../../../lib/const';
import countryData from '../../../../helpers/country.json';
import * as menuActions from '../../../../actions/menuActions';
import * as candyEventActions from '../../../../actions/candyEventActions';
import { useQuery } from 'react-query';
import * as candyEventService from '../../../../services/candyEventService';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';
import County_Cup from './County_Cup ';
import World_Cup from "./World_Cup";
// import CandyNewChallenge from './World_Cup';

function CandyEvents(props:any) {
  const {
    role,
    closeSubmenu1,
  } = props;
  
  const [cup, setCup] = useState(false)
  const [myplumcashing, setMyplumcashing] = useState("")
  const [myWorldCup, setmyWorldCup] = useState('')
  const [world, setworld] = useState(false)

  const [Callenge, setCallenge] = useState(false)
  const CountyCup = () => {
    setCup(true)
    setMyplumcashing("active")
    setmyWorldCup("")
    setworld(false)
    setCallenge(false)
    closeSubmenu1();
  }

  const WorldCup = () => {
    setCup(false)
    setMyplumcashing("")
    setmyWorldCup("active")
    setworld(true)
    setCallenge(false)
    closeSubmenu1();
  }

  // const CandyCallenge=()=>{
  //   setCup(false)
  //   setMyplumcashing("")
  //   setmyWorldCup("")
  //   setworld(false)
  //   setCallenge(true)

  // }


  return (
    <Row className="candyEvents-list pt-15">
      <>
        {/* <Row className="text-center mb-10 px-15">
          <Button className='buttons yellow-gradient' style={{ width: '100%' }}
          onClick={CandyCallenge}
          >
            Create New Candy Challenge
          </Button>
        </Row> */}
        <Row className="text-center">
          {(role === 1100 || role === 1200) && (
            <button
              onClick={WorldCup}
              className={'ant-btn primary-ghost-btn xs ml-5 mr-5 ' + myWorldCup}
            >
              World
            </button>
          )}
          {role !== 'roleAdmin' && (
            <button
              onClick={CountyCup}
              className={'ant-btn primary-ghost-btn xs ' + myplumcashing}
              style={{ marginLeft: "15px" }}
            >
              Country
            </button>
          )}
        </Row>
      </>
  
      {cup && <County_Cup />}
      <Row></Row>
  
      <Row>
        <div>{world && <World_Cup />}</div>
      </Row>
    </Row>
  ); 
  
}
const mapsStateToProps = (state) => ({
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  resetCandyEvent: () => dispatch(candyEventActions.resetCandyEvent()),
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1())
});

 export default connect(mapsStateToProps, mapDispatchToProps)(CandyEvents);
 
