import React from 'react';
import { Row, Form, Input, Radio } from 'antd';
import * as candyEvent from '../../lib/strings/candyEvent';
import PropTypes from 'prop-types';
import UploadFile from '../UploadFile';

function CreatePrizeForm(props) {
  const { language, decorator, label, placeholder, onChange, goods, name, pictureUrl } = props;
  const { getFieldDecorator } = props.form;

  const onNameChange = (event) => {
    onChange('name', event.target.value);
  };

  const onGoodsChange = (event) => {
    onChange('goods', event.target.value);  
  };
 
  const onFileChange = ({ file, fileUrl }) => {
    if (fileUrl !== pictureUrl) {
      onChange('pictureUrl', fileUrl);
      onChange('pictureFile', file);
    }
  };

  return (
    <Row className="candyEvent-prize-form">
      <Form.Item label={label} className="candyEvent-text weight-700">
        <Form.Item className="weight-400">
          {getFieldDecorator(`${decorator}-goods`, {
            rules: [{ required: true, message: candyEvent.errorGoods[language] }],
          })(
            <Radio.Group className="radio-group" onChange={onGoodsChange}>
              <Radio value={'physical'}>{candyEvent.physicalGoods[language]}</Radio>
              <Radio value={'digital'}>{candyEvent.digitalGoods[language]}</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item className="weight-400" style={{ marginTop: 0, marginLeft: ' 0.9em' }}>
          {getFieldDecorator(`${decorator}-name`, {
            rules: [{ required: true, message: candyEvent.errorTitle[language] }],
          })(
            <Input
              className="input-treasure full-width"
              onBlur={onNameChange}
              placeholder={placeholder}
            />
          )}
        </Form.Item>
        <Form.Item
          className="candyEvent-text weight-500"
          style={{ marginTop: -20 }}
          label={candyEvent.uploadPicture[language]}
        >
          {getFieldDecorator(`${decorator}-picture`, {
            rules: [{ required: true, message: candyEvent.errorFile[language] }],
              })
              (
            <div>
              < UploadFile onChange={onFileChange} valueUrl={pictureUrl} />
            </div>
          )}
        </Form.Item>
      </Form.Item>
    </Row>
  );
}

CreatePrizeForm.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
  decorator: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  goods: PropTypes.string,
  name: PropTypes.string,
  pictureUrl: PropTypes.string,
  onChange: PropTypes.func,
};

CreatePrizeForm.defaultProps = {
  language: 'EN',
  onChange: () => {},
};

export default CreatePrizeForm;
