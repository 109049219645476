import React from 'react';
import { Row, Col } from 'antd';
import OneLineInput from '../../OneLineInput';
import MultiLineInput from '../../MultiLineInput';
import PropTypes from 'prop-types';

function TitleAndDescription(props) {
  const {
    getFieldDecorator,
    id,
    labelTitle,
    labelDescription,
    titlePlaceholder,
    descriptionPlaceholder,
    onChangeTitle,
    onChangeDescription,
  } = props;

  return (
    <>
      <OneLineInput
        getFieldDecorator={getFieldDecorator}
        id={`title${id}`}
        label={labelTitle}
        placeholder={titlePlaceholder}
        className="title"
        onChange={onChangeTitle}
      />
      <Row className="banner-description">
        <Col>
          <MultiLineInput
            getFieldDecorator={getFieldDecorator}
            id={`description${id}`}
            label={labelDescription}
            placeholder={descriptionPlaceholder}
            minRows={3}
            className="description"
            onChange={onChangeDescription}
          />
        </Col>
      </Row>
    </>
  );
}

TitleAndDescription.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  labelTitle: PropTypes.string,
  labelDescription: PropTypes.string,
  titlePlaceholder: PropTypes.string,
  descriptionPlaceholder: PropTypes.string,
  onChangeTitle: PropTypes.func,
  onChangeDescription: PropTypes.func,
};

TitleAndDescription.defaultProps = {
  onChangeTitle: () => {},
  onChangeDescription: () => {},
};

export default TitleAndDescription;
