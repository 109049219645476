import { LanguageMap } from '../interfaces/language';

export const partnerRequests: LanguageMap<string> = {
  EN: 'Partner requests',
  KO: 'Partner requests',
};

export const partnerRequestsTitle: LanguageMap<string> = {
  EN: 'PARTNER REQUESTS',
  KO: 'PARTNER REQUESTS',
};

export const details: LanguageMap<string> = {
  EN: 'DETAILS',
  KO: 'DETAILS',
};

export const username: LanguageMap<string> = {
  EN: 'Username:',
  KO: 'Username:',
};

export const beneficiaryName: LanguageMap<string> = {
  EN: 'Beneficiary name:',
  KO: 'Beneficiary name:',
};

export const bankName: LanguageMap<string> = {
  EN: 'Bank name:',
  KO: 'Bank name:',
};

export const accountNumber: LanguageMap<string> = {
  EN: 'Account number:',
  KO: 'Account number:',
};

export const abaNumber: LanguageMap<string> = {
  EN: 'ABA number:',
  KO: 'ABA number:',
};

export const firstRegistrationDate: LanguageMap<string> = {
  EN: 'First registration date:',
  KO: 'First registration date:',
};

export const companyName: LanguageMap<string> = {
  EN: 'Company name:',
  KO: 'Company name:',
};

export const treasuresCreated: LanguageMap<string> = {
  EN: 'Treasures created:',
  KO: 'Treasures created:',
};

export const requestDate: LanguageMap<string> = {
  EN: 'Request date:',
  KO: 'Request date:',
};

export const approve: LanguageMap<string> = {
  EN: 'Approve',
  KO: 'Approve',
};

export const reject: LanguageMap<string> = {
  EN: 'Reject',
  KO: 'Reject',
};
