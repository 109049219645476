import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import {
  statisticsForPointsText,
  statisticsForPointsStrings,
} from '../../../../lib/strings/statistics';
import { statisticsForPoints } from './apiToTabel';
import * as statisticsService from '../../../../services/statisticsService';
import editIcon from '../../../../../src/assets/icon_edit.svg';

import changeIcon1 from '../../../../../src/assets/changeIcon1.png';
import CloseX from '../../../../../src/assets/closeX.svg';

import { useQuery } from 'react-query';
import DomeStatistics from './DomeStatistics';
import moment from 'moment';
import { UpdateDomeAddressBySuperAdmin } from '../../../../services/domeService';
import Column from 'antd/lib/table/Column';

function StatisticsForDomeStats(props) {
  const { language } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});
  const [usersData, setUsersData] = useState({});

  const [selectedDomeAddressIdForEdit, setSelectedDomeAddressIdForEdit] =
    useState(null);

  const [selectedValueForEdit, setSelectedValueForEdit] = useState('');

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForPoints = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForDomeStats(
      queryKey[1]
    );
    return response;
  };

  const statisticsQuery = useQuery(
    ['StatisticsForDomeStats', { ...currentFilters, page: currentPage + 1 }],
    fetchStatisticsForPoints,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  useEffect(() => {
    if (statisticsQuery.data) {
      //   const parsedStatistics = statisticsForPoints(
      //     statisticsQuery.data.transactions
      //   );
      console.log("oooo", statisticsQuery.data);

      //   setTotal({
      //     totalBalance: statisticsQuery.data.totalPoints,
      //     totalCollected: statisticsQuery.data.pointsCollected,
      //     totalConverted: statisticsQuery.data.pointsConverted,
      //   });
      console.log("ant", statisticsQuery)
      setStatistics(statisticsQuery.data.data);
      setUsersData(statisticsQuery.data.users);
      setTotalTreasures(statisticsQuery.data.total);
      console.log("zzz", usersData)
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(-1);
    setCurrentFilters(filters);
    // setClickedUsername('');
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
    // console.log("",usersData)
  };

  const onEditDomeAddClick = (id, val) => {
    setSelectedDomeAddressIdForEdit(id);
    setSelectedValueForEdit(val);
  };

  const onCancelEdit = () => {
    setSelectedDomeAddressIdForEdit(null);
    setSelectedValueForEdit('');
  };

  const onEditDomeAddChange = ({ target: { value } }) => {
    setSelectedValueForEdit(value);
  };

  const onSaveDomeAddClick = async () => {
    let tempData = [...statistics];
    let as =  await UpdateDomeAddressBySuperAdmin(
      selectedDomeAddressIdForEdit,
      selectedValueForEdit
    );


    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i].ID === selectedDomeAddressIdForEdit) {
        tempData[i].addr_name = selectedValueForEdit;
      }
    }
    setStatistics(tempData);
    setSelectedDomeAddressIdForEdit(null);
    setSelectedValueForEdit('');
  };

  let columns = [

    {
      title: 'Number',
      key: 'number',
      render: (text, indx, record) => <span> {500 * (currentPage) + indx + 1}</span>,
    },

    {
      title: 'Users',
      key: 'users',

      render: (text, indx, item) => <span>{usersData[item.spikaID]}</span>,
    },

    {
      title: 'Dome Address',
      dataIndex: 'addr_name',
      render: (_text, _indx, item) => {
        const { addr_name } = item;
        if (selectedDomeAddressIdForEdit === item.ID) {
          return (
            <span>
              <input
                onChange={onEditDomeAddChange}
                value={selectedValueForEdit}
              />
              <img
                style={{ width: '25px', height: '25px' }}
                src={changeIcon1}
                alt="check"
                onClick={onSaveDomeAddClick}
              />
              <img
                onClick={onCancelEdit}
                style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                src={CloseX}
              />
            </span>
          );
        } else {
          return (
            <span>
              <span>{addr_name}</span>
              {'   '}
              <span style={{ cursor: "pointer" }} onClick={() => onEditDomeAddClick(item.ID, addr_name)}>
                <img src={editIcon} alt="Edit" height="14" />
              </span>{' '}
            </span>
          );
        }
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, index, item) => {
        console.log('item...........', text, index, item);
        return <span>{moment(text).format('MM/DD/YY')}</span>;
      },
    },
    {
      title: 'Date Closed',
      dataIndex: 'valid_till',
      key: 'valid_till',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text) => {
        return <span>{moment(text).format('MM/DD/YY')}</span>;
      },
    },
    {
      title: 'Dome Name',
      dataIndex: 'dome_name',
      key: 'dome_name',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (_text, _index, item) => {
        const { dome_name, dome_name_suffix } = item;
        if (dome_name && !dome_name_suffix) {
          return (
            <span>
              <span>{dome_name}</span>
            </span>
          );
        }
        if (dome_name && dome_name_suffix) {
          return (
            <span>
              <span>{`${dome_name}${dome_name_suffix ? dome_name_suffix : ''
                }`}</span>
            </span>
          );
        }

        return <span></span>;
      },
    },
    {
      title: 'Dome Setting Status',
      dataIndex: 'setting_status',
      key: 'setting_status',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, _index) => {
        return (
          <span className={text === 1 ? 'approved' : 'notSubmitted'}>
            <em>{text === 1 ? 'Uploaded' : 'Not uploaded'}</em>
          </span>
        );
      },
    },
    {
      title: 'Dome Address Status',
      dataIndex: 'addr_status',
      key: 'addr_status',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, _index) => {
        return (
          <span className={text === 1 ? 'published' : 'unpublished'}>
            <em>{text === 1 ? 'Published' : 'Unpublished'}</em>
          </span>
        );
      },
    },
  ];

  //   columns.map((column, index) => {
  //     columns[index]['title'] =
  //       statisticsForPointsStrings[language][column.dataIndex];
  //   });

  return (
    <DomeStatistics
      label="Dome Statistics"
      columns={columns}
      dataSource={statistics}
      loading={statisticsQuery.isLoading}
      statisticsType={'StatisticsForDomeStats'}
      clickedUsername={clickedUsername}
      onFiltersChange={onFiltersChangeHandler}
      onPageChange={onPageChangeHandler}
      totalItems={totalTreasures}
      pageSize={500}
      currentPage={currentPage}
      currentFilters={currentFilters}
    //   total={total}
    />
  );
}

StatisticsForDomeStats.propTypes = {};

StatisticsForDomeStats.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(StatisticsForDomeStats);
