import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const LocationMapControl = (props) => {
  const { index } = props;
  return (
    <div className="location-controls">
      <Radio.Button className="location-button fredoka" value={index}>
        #{index + 1}
      </Radio.Button>
    </div>
  );
};

LocationMapControl.propTypes = {
  index: PropTypes.number,
};

export default LocationMapControl;
