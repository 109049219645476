import React from 'react';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';

function ButtonWithSpinner(props) {
  const {
    onClick,
    label,
    isLoading,
    className,
    type,
    width,
    height,
    underline,
    additionalClasses,
    style,
    ...otherProps
  } = props;

  const classSelector = () => {
    if (!className) {
      switch (type) {
        case 'yellowGradient':
          return 'buttons yellow-gradient';
        case 'transparentRed':
          return 'buttons transparent-btn-red weight-700';
        case 'purpleGradient':
          return 'buttons purple-gradient-right';
        case 'purpleBorder':
          return 'buttons white-bg-btn-selected';
        case 'transparentBlack':
          return 'buttons transparent-btn-black weight-700';
        default:
          return 'buttons yellow-gradient';
      }
    } else {
      return className;
    }
  };

  return (
    <Button
      className={classSelector() + ' ' + additionalClasses}
      onClick={onClick}
      style={{ ...style, width: width, height: height }}
      {...otherProps}
    >
      {isLoading ? (
        <Spin
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <span style={{ textDecoration: underline ? 'underline' : '' }}>{label}</span>
      )}
    </Button>
  );
}

ButtonWithSpinner.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([
    'yellowGradient',
    'transparentRed',
    'purpleGradient',
    'purpleBorder',
    'transparentBlack',
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  underline: PropTypes.bool,
  additionalClasses: PropTypes.string,
};

ButtonWithSpinner.defaultProps = {
  onClick: () => {
    console.log('missing logic');
  },
  isLoading: false,
  type: 'yellowGradient',
  underline: false,
};

export default ButtonWithSpinner;
