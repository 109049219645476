import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import BackButtonLeftText from '../../../../components/BackButtonLeftText';
import MenuToggleSwitch from '../../../../components/MenuToggleSwitch';
import ContestantsList from '../../../../components/contest/contestants/ContestantsList';
import * as contestStrings from '../../../../lib/strings/contest';
import * as contestActions from '../../../../actions/contestActions';
import * as contestService from '../../../../services/contestService';
import * as menuActions from '../../../../actions/menuActions';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { menuConstants } from '../../../../constants/menuConstants';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';

function Contestants(props) {
  const {
    language,
    round,
    contestId,
    contestName,
    selectedContestantIndex,
    updateContestants,
    resetContestants,
    openContestantDetails,
    openContestDetails,
    closeSubmenu2,
  } = props;

  useEffect(() => {
    return () => {
      closeSubmenu2();
      resetContestants();
    };
  }, []);

  const roundHandler = (value) => {
    closeSubmenu2();
    updateContestants({ round: value });
  };

  const onCloseHandler = () => {
    openContestDetails();
  };

  const contestantsList: any = useQuery(
    ['ContestantsList', { id: contestId, pageNumber: 1, round: round }],
    contestService.getContestantsQuery,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onClickHandler = (index) => {
    updateContestants({ selectedContestantIndex: index });
    openContestantDetails();
  };

  return (
    <Row className="partnerRequests-wrapper partnerRequests-text">
      <BackButtonLeftText label={contestName} onClick={onCloseHandler} />
      <MenuToggleSwitch
        onChange={roundHandler}
        value={round}
        menuItems={[
          { label: contestStrings.qualifyingLabel[language], value: 'qualifying' },
          { label: contestStrings.finalLabel[language], value: 'final' },
        ]}
      />

      <Col className="scrollable">
        <ContestantsList
          language={language}
          loading={contestantsList.isLoading}
          data={contestantsList.data?.contestants}
          round={round}
          onClick={onClickHandler}
          selectedContestantIndex={selectedContestantIndex}
        />
      </Col>
    </Row>
  );
}

Contestants.propTypes = {
  language: PropTypes.string.isRequired,
  round: PropTypes.oneOf(['qualifying', 'final']),
  contestId: PropTypes.number,
  contestName: PropTypes.string,
  selectedContestantIndex: PropTypes.number,
};

Contestants.defaultProps = {};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  round: state.contestants.round,
  contestId: state.contestDetails.ID,
  contestName: state.contestDetails.name,
  selectedContestantIndex: state.contestants.selectedContestantIndex,
});

const mapDispatchToProps = (dispatch) => ({
  updateContestants: (payload) => dispatch(contestActions.updateContestants(payload)),
  resetContestants: () => dispatch(contestActions.resetContestants()),
  openContestantDetails: () =>
    dispatch(menuActions.openSubmenu2(subMenuConstants.contestantDetails)),
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openContestDetails: () =>
    dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu: subMenuConstants.contestDetails }),
});

export default connect(mapsStateToProps, mapDispatchToProps)(Contestants);
