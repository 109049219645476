import React from 'react';
import { connect } from 'react-redux';

import { Row } from 'antd';
import { OpenStatsButton } from '../../../../components';

import * as cashExchangeStrings from '../../../../lib/strings/cashExchangeStrings';
import * as partnerRequestsStrings from '../../../../lib/strings/partnerRequestsStrings';
import * as menuActions from '../../../../actions/menuActions';
import * as SubMenuConstants from '../../../../lib/SubMenuConstants';

const Requests = (props) => {
  const { language, openSubmenu1, closeSubmenu2 } = props;

  const openCashExchange = () => {
    closeSubmenu2();
    openSubmenu1(SubMenuConstants.cashExchangeRequest);
  };

  const openPartnerRequests = () => {
    closeSubmenu2();
    openSubmenu1(SubMenuConstants.partnerRequests);
  };

  return (
    <Row className="stats-container">
      <OpenStatsButton
        label={cashExchangeStrings.cashExchangeRequestSmall[language]}
        onClick={openCashExchange}
      />
      <OpenStatsButton
        label={partnerRequestsStrings.partnerRequests[language]}
        onClick={openPartnerRequests}
      />
    </Row>
  );
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

const mapDispatchToProps = (dispatch) => ({
  openSubmenu1: (submenu1) => dispatch(menuActions.openSubmenu1(submenu1)),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
