import React, { useState, useContext } from 'react';
import Profile from './Profile';
import { Row, Tabs, Col, Table, Carousel, Checkbox, Select, Typography, Input, Radio } from 'antd';
import MenuButtons from './MenuButtons';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import TreasureCreation from '../treasureCreation/TreasureCreation';
import TreasureReview from '../treasureCreation/TreasureReview';
import CreateCandyEvent from './candyEvent/CreateCandyEvent';
import CreateCandyChallenge from './candyEvent/CreateCandyChallenge';

import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import iconDelete from '../../../../src/assets/icon_delete_o.svg';
import infoIconPrimary from '../../../../src/assets/icon_info_blue.svg';
import bannerPic from '../../../../src/assets/223237.jpg';
import rightcheckicon_green from '../../../../src/assets/rightcheckicon_green.svg';

import masterCard from '../../../../src/assets/masterCard.png';
import visaCard from '../../../../src/assets/visaCard.png';
import clockIcon from '../../../../src/assets/clockIcon.png';
import downloadIcon from '../../../../src/assets/downloadIcon.png';
import starIcon from '../../../../src/assets/star.png';
import iconSend from '../../../../src/assets/iconSend.svg';
import logoPic1 from '../../../../src/assets/logoPic1.png';

import infoIcon from '../../../../src/assets/icon_info.svg';
import editIcon from '../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../src/assets/icon_delete_o.svg';
import burgerKing from '../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../src/assets/burgerKingTrans.png';
import voucherPic from '../../../../src/assets/voucherPic.png';
import AppContext from '../../../AppContext';

import Mydome from './myDome';
import RegisterDom from './RegisterDom';
import DomeSetting from './domeSetting';
import DomeEditing from './domeEditing';
import DomeAdmin from './domeAdmin/domeAdmin';
import AccountSetting from './domeAccount/settings';
import PaymentMethods from './domeAccount/paymentMethods';
import SuperAdminDomeList from './superAdmin/domeList';
import SupportDetails from './superAdmin/supportDetails';
import HelpSupport from './superAdmin/supprot';
import CreateMasterSupport from './support/CreateMasterSupport';
import OpenMasterSupport from './support/openMasterSupport';
import NameReview from './superAdmin/nameReview';
import SettingReview from './superAdmin/settingReview';
import Recharge from './balance/Recharge';
import LcashHistory from './balance/LcashHistory';
import Exchange from './superAdmin/Exchange';
import Plums from './superAdmin/Plums';
import Received from './balance/Received';
import History from './balance/History';
import Cashing from './balance/Cashing';
import AdditionalPrizes from '../treasureCreation/AdditionalPrizes';
import Voucher from './Voucher';
import Lbox from './users/Lbox';
import LboxAdmin from './Lbox/LboxAdmin';
import AdminVoucher from './superAdmin/AdminVoucher';

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const mapState = (state: { menuUIChange: { open: number } }) => {
  return {
    open: state.menuUIChange.open,
  };
};



const pages = [
  (style: any) => (
    <animated.div
      style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}
    >
      <MenuButtons />
    </animated.div>
  ),
  (style: any) => (
    <animated.div
      style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}
    >
      <TreasureCreation />
    </animated.div>
  ),
  (style: any) => (
    <animated.div
      style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}
    >
      <TreasureReview />
    </animated.div>
  ),
  (style) => (
    <animated.div
      style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}
    >
      <CreateCandyEvent />
    </animated.div>
  ),
  (style) => (
    <animated.div
      style={{ ...style, overflowY: 'auto', willChange: 'height, opacity' }}
    >
      < CreateCandyChallenge />
    </animated.div>
  ),
];

interface UserMenuProps {
  open?: number;
}

const UserMenu = (props: UserMenuProps) => {
  const { profile } = useContext(AppContext);
  let domeList = profile['domeList'] || [];
  let countdomeList = domeList.length;
  let isShowItems = profile['showItems'] || false;
  let current_dome = profile['current_dome'] || false;
  let edit_current_dome = profile['edit_current_dome'] || false;
  let admin_dome = profile['admin_dome'] || false;
  let accountSetting = profile['accountSetting'] || false;
  let recharge = profile['recharge'] || false;
  let lcashHistory = profile['lcashHistory'] || false;

  let paymentMethos = profile['paymentMethos'] || false;
  let domeAdminList = profile['admin_domes'] || false;
  let support = profile['support'] || false;
  let editSupport = profile['editSupport'] || false;
  let createMasterSupport = profile['createMasterSupport'] || false;
  let openMasterSupport = profile['openMasterSupport'] || false;
  let nameReview = profile['name_review'] || false;
  let settingReview = profile['setting_review'] || false;
  let exchange = profile['exchange'] || false;
  let plums = profile['plums'] || false;
  let received = profile['received'] || false
  let history = profile['history'] || false
  let cashing = profile['cashing'] || false
  let additionalprizes = profile['additionalprizes'] || false
  let voucher = profile['voucher'] || false
  let lbox = profile['lbox'] || false
  let lboxAdmin = profile['lboxAdmin'] || false
  let adminVoucher = profile['adminVoucher'] || false


  // const { TabPane } = Tabs;
  function callback(key) {
    console.log(key);
  }

  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper z-index-14">
        <Profile />
        <Transition
          reset
          unique
          items={props.open}
          from={{ height: 0, opacity: 0 }}
          enter={{ height: 'auto', opacity: 1 }}
          leave={{ height: 0, opacity: 0 }}
        >
          {(open: any) => pages[open]}
        </Transition>
      </Row>

      {/* My Dome Add. card */}
      {countdomeList > 0 && !current_dome ? <Mydome /> : ''}
      {/* register-dialog */}
      {isShowItems && <RegisterDom />}
      {/* Dome Setting Card */}
      {current_dome && <DomeSetting />}

      {edit_current_dome && <DomeEditing />}

      {/* Dome Admin */}
      {admin_dome && <DomeAdmin />}

      {/* Account setting*/}
      {accountSetting && <AccountSetting />}

      {/* domeAdminList */}
      {/* {domeAdminList &&
				<SuperAdminDomeList />
			} */}
      {/* Support */}
      {support && <HelpSupport />}

      {editSupport && <SupportDetails />}

      {createMasterSupport && <CreateMasterSupport />}
      {openMasterSupport && <OpenMasterSupport />}

      {nameReview && <NameReview />}
      {settingReview && <SettingReview />}

      {exchange && <Exchange />}

      {plums && <Plums />}
      {received && <Received />}
      {history && <History />}
      {cashing && <Cashing />}

      {lcashHistory && <LcashHistory />}
      {additionalprizes && <AdditionalPrizes />}

      {voucher && <Voucher />}
      {lbox && <Lbox />}
      {lboxAdmin && <LboxAdmin />}
      {adminVoucher && <AdminVoucher />}
      {/* Profile */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Profile</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4>Profile image file upload</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <img src={burgerKing} alt="" />
                </figure>
                <h5>Drag and fit</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary">Preview</h3>
            </div>

            <ul className="uploadedItemList">
              <li>
                <figure>
                  <img src={burgerKing} alt="" />
                </figure>
                <figcaption>
                  <h3>burgerking</h3>
                </figcaption>
              </li>
              <li>
                <figure>
                  <img src={burgerKing} alt="" />
                </figure>
                <figcaption>
                  <h3>burgerking</h3>
                </figcaption>
              </li>
            </ul>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Outer Logo */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Outer Logo</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">Logo file upload</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <img src={burgerKing} alt="" />
                </figure>
                <h5>Drag and fit</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">Preview</h3>
            </div>

            <div className="dome-preview">
              <img src={burgerKingTrans} alt="Logo" height="40" />
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Lobby Logo */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Lobby Logo</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">Logo file upload</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <img src={burgerKing} alt="" />
                </figure>
                <h5>Drag and fit</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">Preview</h3>
            </div>

            <div className="dome-full-preview">
              <img src={burgerKingTrans} alt="Logo" height="40" />
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* 3D Icon */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; 3D Icon</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">File upload</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <img src={burgerKing} alt="" />
                </figure>
                <h5>Drag and fit</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">Preview</h3>
            </div>

            <div className="dome-preview">
              <img src={burgerKingTrans} alt="Logo" height="40" />
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* 2D Icon */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; 2D Icon</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">File upload</h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>

                <h4 className="profileImgUpload__label">Dome Name</h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value="@BurgerKing"
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                </div>

                <h4 className="profileImgUpload__label">Name of City</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value="LA"
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <figure className="photoPrev">
                  <img src={burgerKing} alt="" />
                </figure>
                <h5>Drag and fit</h5>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">Preview</h3>
            </div>

            <div className="twoDPlaceholder">
              <figure className="twoDPlaceholder__fig">
                <img src={burgerKingTrans} alt="Logo" height="40" />
              </figure>
              <figcaption className="twoDPlaceholder__info">
                <h3 className="weight-700">BurgerKing</h3>
                <h4 className="weight-700 text-muted">LA</h4>
              </figcaption>
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Outer wall */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Outer wall</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">File upload</h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                {/* <figure className="photoPrev"></figure> */}
              </div>
            </div>

            {/* <div className="text-center mb-20">
							<h3 className="text-primary weight-700">Preview</h3>
						</div> */}

            {/* <div className="grid-row fx-jcc">
							<div className="col col-6 fx-jcsb">
								<button className="ant-btn primary-ghost-btn xs">Cancel</button>
								<button className="ant-btn yellow-gradient xs">Publish</button>
							</div>
						</div> */}
          </div>
        </div>
      </Row>

      {/* Inner wall */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Inner wall</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">File upload</h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                {/* <figure className="photoPrev"></figure> */}
              </div>
            </div>

            {/* <div className="text-center mb-20">
							<h3 className="text-primary weight-700">Preview</h3>
						</div> */}

            {/* <div className="grid-row fx-jcc">
							<div className="col col-6 fx-jcsb">
								<button className="ant-btn primary-ghost-btn xs">Cancel</button>
								<button className="ant-btn yellow-gradient xs">Publish</button>
							</div>
						</div> */}
          </div>
        </div>
      </Row>

      {/*  Arena Icon */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Arena Icon</h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row profileImgUpload">
              <div className="col col-8">
                <h4 className="profileImgUpload__label">
                  Background Image file upload
                </h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value=""
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>

                <h4 className="profileImgUpload__label">Arena Name</h4>
                <div className="grid-row sm mb-10">
                  <div className="col col-8">
                    <input
                      type="text"
                      value="BurgerKing"
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                  <div className="col col-4">
                    <label className="ant-btn primary-ghost-btn xs btn-block">
                      <input type="file" className="d-none" />
                      Upload
                    </label>
                  </div>
                </div>

                <h4 className="profileImgUpload__label">Name of City</h4>
                <div className="grid-row sm">
                  <div className="col col-8">
                    <input
                      type="text"
                      value="LA"
                      className="form-control xs"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="col col-4 text-center">
                <div className="twoDPlaceholder fullBlock">
                  <figure className="twoDPlaceholder__fig">
                    <img src={burgerKingTrans} alt="Logo" height="40" />
                  </figure>
                  <figcaption className="twoDPlaceholder__info">
                    <h3 className="weight-700">&nbsp;</h3>
                    <h4 className="weight-700 text-muted">&nbsp;</h4>
                  </figcaption>
                </div>
                <p>Drag and fit</p>
              </div>
            </div>

            <div className="text-center mb-20">
              <h3 className="text-primary weight-700">Preview</h3>
            </div>

            <div className="twoDPlaceholder">
              <figure className="twoDPlaceholder__fig">
                <img src={burgerKingTrans} alt="Logo" height="40" />
              </figure>
              <figcaption className="twoDPlaceholder__info">
                <h3 className="weight-700">BurgerKing</h3>
                <h4 className="weight-700 text-muted">LA</h4>
              </figcaption>
            </div>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Dome Admin */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Dome Admin</h4>
            <div className="fx-aic">
              <div className="size12 text-primary">
                Dome Add status: <span className="published">Published</span>
              </div>
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="let-long-loop domeAddLoop">
              <div className="let-long-loop__left">
                <div className="let-long-loop__left__row mb-10">
                  <i className="let-long-loop__left__row__icon">
                    <img src={locationIcon} alt="Location" />
                  </i>
                  <div className="let-long-loop__left__row__info">
                    <h3 className="font-bold size14 mb-0">
                      000aa008 <span className="text-muted">@</span>
                    </h3>
                    <div className="let-long-loop__left__row__info__row">
                      <div className="let-long-loop__left__row__info__row__info size10">
                        <div className="mr-10">
                          <p className="mb-0">Latitude</p>
                          <p className="mb-0">440.7644690999999</p>
                        </div>
                        <div className="mb-0">
                          <p className="mb-0">Longitude</p>
                          <p className="mb-0">-73.9744877</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="domeAdminScroll">
              <div className="fx-jcc mt-15">
                <button className="ant-btn primary-ghost-btn xs">
                  {' '}
                  Notifications
                </button>
                <button className="ant-btn primary-ghost-btn xs ml-15 mr-15">
                  {' '}
                  ARshow
                </button>
                <button className="ant-btn primary-ghost-btn xs"> Chat</button>
              </div>

              <div className="fx-jcfe mt-15">
                <button className="ant-btn primary-ghost-btn xs">
                  {' '}
                  Noticeboard
                </button>
              </div>

              <div className="tab-content-box">
                {/* notifications-tab */}
                <div id="notifications-tab" className="tab-pane d-none">
                  <div className="mb-10">
                    <i className="blueBox mr-5"></i>
                    <span className="text-primary mr-5">Notifications</span>
                    <i>
                      <img src={infoIcon} alt="Info" height="14" />
                    </i>
                  </div>
                  <div className="fx-jcc">
                    <button className="ant-btn purple-gradient xs mr-10">
                      Create new notifications
                    </button>
                    <button className="ant-btn primary-ghost-btn xs  ml-10">
                      Use a template
                    </button>
                  </div>
                </div>

                {/* arshow-tab */}
                <div id="arshow-tab" className="tab-pane">
                  <div className="fx-jcsb-aic mb-10">
                    <div>
                      <i className="blueBox mr-5"></i>
                      <span className="text-primary mr-5">ARshow editing</span>
                      <i>
                        <img src={infoIcon} alt="Info" height="14" />
                      </i>
                    </div>
                    <div>
                      ARshow status:{' '}
                      <span className="text-primary">activated</span>
                    </div>
                  </div>

                  <ol className="editingList">
                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left w-auto">
                          <div className="grid-row xs">
                            <div className="col col-6">
                              <div className="dateSection">
                                <div className="fx-aic">
                                  <span className="dateSection__label">
                                    Start:
                                  </span>
                                  <input
                                    type="text"
                                    value="20/05/21 03:46 PM"
                                    className="form-control xss rd-0 size11"
                                  />
                                </div>
                                <div className="fx-aic">
                                  <span className="dateSection__label">
                                    End:
                                  </span>
                                  <input
                                    type="text"
                                    value=""
                                    placeholder="Select Date"
                                    className="form-control xss rd-0 size11"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col col-6">
                              <input
                                type="text"
                                value="abc.jpg"
                                className="form-control xs rd-0"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
                            <input type="file" />
                            Upload
                          </label>
                          <button className="iconx xs">
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button className="iconx xs">
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left w-auto">
                          <div className="grid-row xs">
                            <div className="col col-6">
                              <div className="dateSection">
                                <div className="fx-aic">
                                  <span className="dateSection__label">
                                    Start:
                                  </span>
                                  <input
                                    type="text"
                                    value="20/05/21 03:46 PM"
                                    className="form-control xss rd-0 size11"
                                  />
                                </div>
                                <div className="fx-aic">
                                  <span className="dateSection__label">
                                    End:
                                  </span>
                                  <input
                                    type="text"
                                    value=""
                                    placeholder="Select Date"
                                    className="form-control xss rd-0 size11"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col col-6">
                              <input
                                type="text"
                                value="abc.jpg"
                                className="form-control xs rd-0"
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
                            <input type="file" />
                            Upload
                          </label>
                          <button className="iconx xs">
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button className="iconx xs">
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </ol>
                  <div className="fx-jcfe mt-10 mb-10">
                    <button className="ant-btn addMore">+</button>
                  </div>

                  <div className="grid-row fx-jcc">
                    <div className="col col-6 fx-jcsb">
                      <button className="ant-btn primary-ghost-btn xs">
                        Cancel
                      </button>
                      <button className="ant-btn yellow-gradient xs">
                        Publish
                      </button>
                    </div>
                  </div>
                </div>

                {/* chat-tab */}
                <div id="chat-tab" className="tab-pane d-none">
                  <div className="fx-jcsb-aic mb-10">
                    <h4 className="text-primary mb-0 font-bold">&#62; Chat</h4>
                    <button className="iconx">
                      <img src={editIcon} alt="icon" height="14" />
                    </button>
                  </div>
                  <div className="fx-jcc">
                    <button className="ant-btn purple-gradient xs mr-10">
                      My Picks
                    </button>
                    <button className="ant-btn primary-ghost-btn xs  ml-10">
                      Now
                    </button>
                  </div>
                </div>

                {/* notice-tab */}
                <div id="notice-tab" className="tab-pane"></div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Create new notifications */}
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              {' '}
              &#62; Create new notifications
            </h4>
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="">
            <div className="form-group">
              <label className="font-bold">Category:</label>
              <select className="form-control">
                <option>All (My Pick + Subscriptions)</option>
                <option>My Pick</option>
                <option>Subscriptions</option>
              </select>
            </div>
            <div className="form-group mb-20">
              <label className="font-bold">General:</label>
              <select className="form-control">
                <option>Korean</option>
                <option>Korean</option>
                <option>English</option>
              </select>
            </div>

            <div className="form-group mb-20">
              <label className="font-bold">Title:</label>
              <input
                type="text"
                placeholder="Add title:"
                className="inp default"
              />
            </div>

            <div className="form-group mb-20">
              <label className="font-bold">Message:</label>
              <textarea
                rows={10}
                className="inp default"
                placeholder="Add message:"
              ></textarea>
              <div className="fx-jcfe">
                <small>(70/70)</small>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button className="ant-btn primary-ghost-btn xs mr-5">
              Cancel
            </button>
            <button className="ant-btn purple-gradient xs ml-5">Save</button>
          </div>
        </div>
      </Row>

      {/* My Picks */}
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"> &#62; My Picks</h4>
            <div className="fx-aic">
              <div className="size12 text-primary">
                Status: <span>No reply from users via chat</span>
              </div>
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="chat-loop-container">
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
            </div>

            <div className="reply-section">
              <label className="reply-section__upload">
                <input type="file" />+
              </label>
              <div className="reply-section__type">
                <input type="text" name="" id="" className="form-control" />
              </div>
              <button className="reply-section__submit">&nbsp;</button>
            </div>
          </div>
        </div>
      </Row>

      {/* Now */}
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"> &#62; Now</h4>
            <div className="fx-aic">
              <div className="size12 text-primary">
                Status: <span>No reply from users via chat</span>
              </div>
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="chat-loop-container">
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
            </div>

            <div className="reply-section">
              <label className="reply-section__upload">
                <input type="file" />+
              </label>
              <div className="reply-section__type">
                <input type="text" name="" id="" className="form-control" />
              </div>
              <button className="reply-section__submit">&nbsp;</button>
            </div>
          </div>
        </div>
      </Row>

      {/* Chat Edit */}
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"> &#62; Chat</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="group-chat-section">
              <h4>My Picks and joined users</h4>
              <div className="group-chat-setting">
                <h5 className="group-chat-setting__title">
                  <span className="mr-3">
                    Group chat with ‘My Picks’ and joined users
                  </span>
                  <i>
                    <img src={infoIcon} alt="icon" height="14" />
                  </i>
                </h5>
                <div className="fx-jcsb-aic">
                  <ul className="group-chat-setting__list">
                    <li>
                      <label>
                        <input type="checkbox" />
                        No reply from users via chat
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        Reply from users via chat
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="group-chat-section">
              <h4>Now</h4>
              <div className="group-chat-setting">
                <h5 className="group-chat-setting__title">
                  <span className="mr-3">
                    Group chat with engaged now users
                  </span>
                  <i>
                    <img src={infoIcon} alt="icon" height="14" />
                  </i>
                </h5>
                <div className="fx-jcsb-aic">
                  <ul className="group-chat-setting__list">
                    <li>
                      <label>
                        <input type="checkbox" />
                        No reply from users via chat
                      </label>
                      <button className="ant-btn yellow-gradient xs">
                        Enter
                      </button>
                    </li>
                    <li>
                      <label>
                        <input type="checkbox" />
                        Reply from users via chat
                      </label>
                      <button className="ant-btn yellow-gradient xs">
                        Enter
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Notice */}
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"> &#62; Notice</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <ol className="editingList">
              <li className="editingList__item">
                <div className="editingList__item__row">
                  <div className="editingList__item__row__left fullSide">
                    <div className="grid-row xs mb-5">
                      <div className="col col-3 fx-aic">
                        <label className="fileLabel">Title</label>
                      </div>
                      <div className="col col-9">
                        <input
                          type="text"
                          value=""
                          className="form-control xss rd-0"
                        />
                      </div>
                    </div>

                    <div className="grid-row xs">
                      <div className="col col-3 fx-aic">
                        <label className="fileLabel">Sub-title</label>
                      </div>
                      <div className="col col-9">
                        <input
                          type="text"
                          value=""
                          className="form-control xss rd-0"
                        />
                      </div>
                    </div>
                    <div className="grid-row xs mb-5">
                      <div className="col col-3 fx-aic">
                        <label className="fileLabel">Description1</label>
                      </div>
                      <div className="col col-9">
                        <textarea
                          rows={2}
                          className="form-control xss rd-0"
                        ></textarea>
                      </div>
                    </div>

                    <div className="grid-row xs">
                      <div className="col col-3 fx-aic">
                        <label className="fileLabel">Sub-title</label>
                      </div>
                      <div className="col col-9">
                        <input
                          type="text"
                          value=""
                          className="form-control xss rd-0"
                        />
                      </div>
                    </div>
                    <div className="grid-row xs">
                      <div className="col col-3 fx-aic">
                        <label className="fileLabel">Description2</label>
                      </div>
                      <div className="col col-9">
                        <textarea
                          rows={2}
                          className="form-control xss rd-0"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="editingList__item__row__action">
                    <button className="iconx xs">
                      <img src={editIcon} alt="Edit" height="14" />
                    </button>
                    <button className="iconx xs">
                      <img src={deleteIcon} alt="Delete" height="14" />
                    </button>
                  </div>
                </div>
              </li>

              <div className="fx-jcfe mt-10 mb-10">
                <button className="ant-btn addMore">+</button>
              </div>
            </ol>

            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button className="ant-btn primary-ghost-btn xs">Cancel</button>
                <button className="ant-btn yellow-gradient xs">Publish</button>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* My Settings */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card gaps relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; My Settings</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="fx-jcsb mt-15">
              <button className="ant-btn primary-ghost-btn xs">
                My Profile
              </button>
              <button className="ant-btn primary-ghost-btn xs ml-15 mr-15">
                Assign the admin
              </button>
              <button className="ant-btn primary-ghost-btn xs">
                Invite My Dome developer
              </button>
            </div>

            <div className="tab-content-box">
              {/* my-profile-tab */}
              <div id="my-profile-tab" className="tab-pane d-none">
                <div className="my-setting-form">
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">Username</label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">
                      Mobile Phone <sup>*</sup>
                    </label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">
                      First Name <sup>*</sup>
                    </label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">
                      Last Name <sup>*</sup>{' '}
                    </label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">
                      Year of Birth <sup>*</sup>
                    </label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">Company Name</label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">
                      Email <sup>*</sup>
                    </label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group">
                    <label className="col col-4 inp-label">Address</label>
                    <div className="col col-8">
                      <input type="text" value="" className="inp default" />
                    </div>
                  </div>
                  <div className="grid-row sm form-group mt-10">
                    <label className="col col-4 inp-label">&nbsp;</label>
                    <div className="col col-8">
                      <input
                        type="submit"
                        value="Submit"
                        className="ant-btn yellow-gradient xs"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div id="assign-tab" className="tab-pane">
                <div className="let-long-loop domeAddLoop active">
                  <div className="let-long-loop__left">
                    <div className="let-long-loop__left__row mb-10">
                      <div className="mr-5">
                        <img src={burgerKing} alt="burger King" height="40" />
                      </div>
                      <div className="let-long-loop__left__row__info">
                        <h3 className="font-bold size14 mb-0">
                          000aa008 <span className="text-muted">@</span>
                          burgerking
                        </h3>
                        <div className="let-long-loop__left__row__info__row">
                          <i className="let-long-loop__left__row__icon">
                            <img src={locationIcon} alt="Location" />
                          </i>
                          <div className="let-long-loop__left__row__info__row__info size10">
                            <div className="mr-10">
                              <p className="mb-0">Latitude</p>
                              <p className="mb-0">440.7644690999999</p>
                            </div>
                            <div className="mb-0">
                              <p className="mb-0">Longitude</p>
                              <p className="mb-0">-73.9744877</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="let-long-loop__right">
                    <div className="fx-jcsb-aic">
                      <div className="size12">
                        <p className="mb-3 text-primary">
                          Dome Add status: <br />{' '}
                          <span className="published">Published</span>
                        </p>
                        <p className="text-primary">
                          Dome Settings status: <br />{' '}
                          <span className="approved">Approved</span>
                        </p>
                      </div>
                      <div className="iconx">
                        <img src={editIcon} alt="icon" height="14" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <div className="grid-row sm form-group">
                    <label className="col col-3 inp-label">
                      Email <sup>*</sup>
                    </label>
                    <div className="col col-5">
                      <input
                        type="text"
                        value="gdfws@gmail.com"
                        className="inp default h-30"
                      />
                    </div>
                    <div className="col col-4">
                      <input
                        type="button"
                        value="Verified"
                        className="ant-btn purple-gradient xss"
                      />
                      <span className="size12 ml-5">Change</span>
                    </div>
                  </div>

                  <div className="grid-row sm form-group">
                    <label className="col col-3 inp-label">
                      Mobile Phone <sup>*</sup>
                    </label>
                    <div className="col col-5">
                      <input
                        type="text"
                        value="12124242322"
                        className="inp default h-30"
                      />
                    </div>
                    <div className="col col-4">
                      <input
                        type="button"
                        value="Verified"
                        className="ant-btn purple-gradient xss"
                      />
                      <span className="size12 ml-5">Change</span>
                    </div>
                  </div>

                  <div className="grid-row sm form-group mt-10">
                    <label className="col col-4 inp-label">&nbsp;</label>
                    <div className="col col-8">
                      <input
                        type="submit"
                        value="Submit"
                        className="ant-btn yellow-gradient xs"
                      />
                    </div>
                  </div>
                </div>

                <div className="let-long-loop domeAddLoop">
                  <div className="let-long-loop__left">
                    <div className="let-long-loop__left__row mb-10">
                      <div className="blueCircle mr-5">&nbsp;</div>
                      <div className="let-long-loop__left__row__info">
                        <h3 className="font-bold size14 mb-0">
                          000aa128<span className="text-muted">@</span>samsung
                        </h3>
                        <div className="let-long-loop__left__row__info__row">
                          <i className="let-long-loop__left__row__icon">
                            <img src={locationIcon} alt="Location" />
                          </i>
                          <div className="let-long-loop__left__row__info__row__info size10">
                            <div className="mr-10">
                              <p className="mb-0">Latitude</p>
                              <p className="mb-0">440.7644690999999</p>
                            </div>
                            <div className="mb-0">
                              <p className="mb-0">Longitude</p>
                              <p className="mb-0">-73.9744877</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="let-long-loop__right">
                    <div className="fx-jcsb-aic">
                      <div className="size12">
                        <p className="mb-3 text-primary">
                          Dome Add status: <br />{' '}
                          <span className="published">Published</span>
                        </p>
                        <p className="text-primary">
                          Dome settings status: <br />{' '}
                          <span className="approved">Approved</span>
                        </p>
                      </div>
                      <div className="iconx">
                        <img src={editIcon} alt="icon" height="14" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="let-long-loop domeAddLoop">
                  <div className="let-long-loop__left">
                    <div className="let-long-loop__left__row mb-10">
                      <div className="blueCircle mr-5">&nbsp;</div>
                      <div className="let-long-loop__left__row__info">
                        <h3 className="font-bold size14 mb-0">
                          000aa128<span className="text-muted">@</span>samsung
                        </h3>
                        <div className="let-long-loop__left__row__info__row">
                          <i className="let-long-loop__left__row__icon">
                            <img src={locationIcon} alt="Location" />
                          </i>
                          <div className="let-long-loop__left__row__info__row__info size10">
                            <div className="mr-10">
                              <p className="mb-0">Latitude</p>
                              <p className="mb-0">440.7644690999999</p>
                            </div>
                            <div className="mb-0">
                              <p className="mb-0">Longitude</p>
                              <p className="mb-0">-73.9744877</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="let-long-loop__right">
                    <div className="fx-jcsb-aic">
                      <div className="size12">
                        <p className="mb-3 text-primary">
                          Dome Add status: <br />{' '}
                          <span className="published">Published</span>
                        </p>
                        <p className="text-primary">
                          Dome settings status: <br />{' '}
                          <span className="approved">Approved</span>
                        </p>
                      </div>
                      <div className="iconx">
                        <img src={editIcon} alt="icon" height="14" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="invite-tab" className="tab-pane d-none"></div>
            </div>
          </div>
        </div>
      </Row>

      {/* Payment Methods */}
      {paymentMethos && <PaymentMethods />}
      {recharge && <Recharge />}
      {/* Recharge */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Recharge</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="rechargeCard">
            <div className="fx-jcsb-aic">
              <span className="uppercase size12">Current Balance</span>
              <span className="size12">
                <img src={clockIcon} alt="icon" /> 500
              </span>
            </div>
            <div className="rechargeCard__inside">
              <div className="rechargeCard__inside__one fx-aic">
                <div className="rechargeCard__inside__one__left">
                  <label>Lcash</label>
                  <input type="text" className="inp default h-30" />
                </div>
                <div className="rechargeCard__inside__one__center">=</div>

                <div className="rechargeCard__inside__one__right">
                  <label>Lcash</label>
                  <p>$</p>
                </div>
              </div>

              <div className="text-center">
                <button className="ant-btn yellow-gradient xs">Buy</button>
              </div>
            </div>
          </div>

          <div className="center mt-10">
            <span className="size12">1.00 Lcash = 1.00 US$</span>
          </div>
        </div>
      </Row>

      {/* Transactions */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Transactions</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="table-container">
            <p>5 results</p>
            <div className="mb-5">
              <button className="size12">
                <img src={downloadIcon} alt="icon" height="16" /> Export to CSV
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Spent</th>
                  <th>Bought</th>
                  <th>Returned</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>2/24/2021</td>
                  <td>Adskit O tea</td>
                  <td>200.00</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>500.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>2/14/2021</td>
                  <td>Recharge</td>
                  <td>&nbsp;</td>
                  <td>490.00</td>
                  <td>&nbsp;</td>
                  <td>700.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>2/04/2021</td>
                  <td>Adskit are</td>
                  <td>50.00</td>
                  <td>&nbsp;</td>
                  <td>10.00</td>
                  <td>210.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>1/24/2021</td>
                  <td>Adskit bur</td>
                  <td>100.00</td>
                  <td>&nbsp;</td>
                  <td>50.00</td>
                  <td>250.00</td>
                </tr>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>1/10/2021</td>
                  <td>Recharge</td>
                  <td>&nbsp;</td>
                  <td>300.00</td>
                  <td>&nbsp;</td>
                  <td>300.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Row>

      {/* Open support case */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Open support case
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="">
            <p>5 results</p>
            <div className="mb-5">
              <button className="size12">
                <img src={downloadIcon} alt="icon" height="16" /> Export to CSV
              </button>
            </div>
            <div className="my-setting-form">
              <div className="grid-row sm form-group">
                <label className="col col-4 inp-label regular">Date</label>
                <div className="col col-8">
                  <p className="mt-10 mb-0">Apr 26 2021</p>
                </div>
              </div>
              <div className="grid-row sm form-group">
                <label className="col col-4 inp-label regular">Subject</label>
                <div className="col col-8">
                  <input type="text" value="" className="inp regular" />
                </div>
              </div>
              <div className="grid-row sm form-group">
                <label className="col col-4 inp-label regular">
                  Description
                </label>
                <div className="col col-8">
                  <textarea
                    rows={10}
                    className="inp regular"
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
              <div className="grid-row sm form-group">
                <label className="col col-4 inp-label regular">&nbsp;</label>
                <div className="col col-8">
                  <input
                    type="submit"
                    value="Submit"
                    className="ant-btn primary-ghost-btn xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Support cases */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Support cases</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr className="text-primary">
                  <th>Created</th>
                  <th>Subject</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Apr 26 2021</td>
                  <td>Ssdfdljsssxxxxxxxxxxxxxxxx…</td>
                  <td>Resolved</td>
                </tr>
                <tr>
                  <td>Apr 26 2021</td>
                  <td>Ssdfdljsssxxxxxxxxxxxxxxxx…</td>
                  <td>Resolved</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Row>

      {/* Subject */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Subject</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="my-setting-form">
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label regular text-right">
                Subject
              </label>
              <div className="col col-9">
                <input type="text" value="" className="inp regular" />
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label regular text-right">
                Date
              </label>
              <div className="col col-9">
                <p className="mt-10 mb-0">Apr 26 2021</p>
              </div>
            </div>
            <div className="mb-5">
              <span className="text-primary">Correspondence</span>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel">
                LoaMoa Team <br />
                May 01 2021
                <br />
                08:28: 38 GMT
                <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea rows={3} className="inp regular">
                  Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx..
                </textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel">
                JohnB
                <br />
                Apr 30 2021
                <br />
                08:28: 38 GMT
                <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel">
                LoaMoa Team
                <br />
                Apr 27 2021
                <br />
                08:28: 38 GMT
                <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel">
                JohnB
                <br />
                Apr 26 2021
                <br />
                04:49:28 GMT
                <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="text-center mb-5">
              <button className="ant-btn purple-gradient xs">Load More</button>
            </div>
            <div className="rating-box">
              <p className="size12 mb-0">
                Was this response helpful? Click here to rate:
              </p>
              <span>
                <img src={starIcon} alt="icon" />
                <img src={starIcon} alt="icon" />
                <img src={starIcon} alt="icon" />
                <img src={starIcon} alt="icon" />
                <img src={starIcon} alt="icon" />
              </span>
            </div>
          </div>
        </div>
      </Row>

      {/* Name review */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Name review</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div>
            <div className="let-long-loop domeAddLoop">
              <div className="let-long-loop__left">
                <div className="let-long-loop__left__row mb-10">
                  <div className="let-long-loop__left__row__info">
                    <h3 className="font-bold size14 mb-0">
                      000aa008 <span className="text-muted">@</span>burgerking
                    </h3>
                    <div className="let-long-loop__left__row__info__row">
                      <i className="let-long-loop__left__row__icon">
                        <img src={locationIcon} alt="Location" />
                      </i>
                      <div className="let-long-loop__left__row__info__row__info size10">
                        <div className="mr-10">
                          <p className="mb-0">Latitude</p>
                          <p className="mb-0">440.7644690999999</p>
                        </div>
                        <div className="mb-0">
                          <p className="mb-0">Longitude</p>
                          <p className="mb-0">-73.9744877</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="let-long-loop__right">
                <div className="fx-jcsb-aic">
                  <div className="size12">
                    <p className="mb-3 text-error">
                      Submitted on 6/01/2021{' '}
                      <img src={editIcon} alt="icon" height="14" />
                    </p>
                    <p>
                      Dome Name status:{' '}
                      <span className="text-success">Approved</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="let-long-loop domeAddLoop">
              <div className="let-long-loop__left">
                <div className="let-long-loop__left__row mb-10">
                  <div className="let-long-loop__left__row__info">
                    <h3 className="font-bold size14 mb-0">
                      000aa008 <span className="text-muted">@</span>burgerking
                    </h3>
                    <div className="let-long-loop__left__row__info__row">
                      <i className="let-long-loop__left__row__icon">
                        <img src={locationIcon} alt="Location" />
                      </i>
                      <div className="let-long-loop__left__row__info__row__info size10">
                        <div className="mr-10">
                          <p className="mb-0">Latitude</p>
                          <p className="mb-0">440.7644690999999</p>
                        </div>
                        <div className="mb-0">
                          <p className="mb-0">Longitude</p>
                          <p className="mb-0">-73.9744877</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="let-long-loop__right">
                <div className="fx-jcsb-aic">
                  <div className="size12">
                    <p className="mb-3 text-error">
                      Submitted on 6/01/2021{' '}
                      <img src={editIcon} alt="icon" height="14" />
                    </p>
                    <p>
                      Dome Name status:{' '}
                      <span className="text-success">Approved</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* NYC5.9 */}
      <Row className="menu-wrapper treasure-menu-wrapper cc d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; NYC5.9</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="fx-aic mb-10">
            <div className="col col-6">Dome Name to be approved</div>
            <div className="col col-6">
              <input
                type="text"
                value="@BurgerKing"
                className="form-control botLine bdr-primary"
              />
            </div>
          </div>

          <div>
            <table className="table primary size11 mb-5">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>File review</th>
                  <th>Name of team</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tr>
                <td style={{ verticalAlign: 'middle' }}>Jun01, 2021</td>
                <td>
                  <p className="fileText">File:xxxxxxxxx</p>
                  <p className="mb-0">
                    <span className="fx-aic-inline mr-5">
                      <input type="checkbox" /> Done
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Need more
                    </span>
                  </p>
                </td>
                <td style={{ verticalAlign: 'middle' }}>Maria</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <p className="mb-0">
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Pending
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Approved
                    </span>
                  </p>
                </td>
              </tr>
            </table>
            <table className="table primary size11 mb-5">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>File review</th>
                  <th>Name of team</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tr>
                <td style={{ verticalAlign: 'middle' }}>Jun01, 2021</td>
                <td>
                  <p className="fileText">File:xxxxxxxxx</p>
                  <p className="mb-0">
                    <span className="fx-aic-inline mr-5">
                      <input type="checkbox" /> Done
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Need more
                    </span>
                  </p>
                </td>
                <td style={{ verticalAlign: 'middle' }}>Maria</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <p className="mb-0">
                    <span className="fx-aic-inline mr-5">
                      <input type="checkbox" /> Pending
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Approved
                    </span>
                  </p>
                </td>
              </tr>
            </table>
            <table className="table primary size11">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>File review</th>
                  <th>Name of team</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tr>
                <td style={{ verticalAlign: 'middle' }}>Jun01, 2021</td>
                <td>
                  <p className="fileText">File:xxxxxxxxx</p>
                  <p className="mb-0">
                    <span className="fx-aic-inline mr-5">
                      <input type="checkbox" /> Done
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Need more
                    </span>
                  </p>
                </td>
                <td style={{ verticalAlign: 'middle' }}>Maria</td>
                <td style={{ verticalAlign: 'middle' }}>
                  <p className="mb-0">
                    <span className="fx-aic-inline mr-5">
                      <input type="checkbox" /> Pending
                    </span>
                    <span className="fx-aic-inline">
                      <input type="checkbox" /> Approved
                    </span>
                  </p>
                </td>
              </tr>
            </table>

            <div className="mb-10 text-center">
              <button className="ant-btn primary-ghost-btn xs">Hall</button>
              <button className="ant-btn primary-ghost-btn xs ml-5">
                Performance Schedule
              </button>
              <button className="ant-btn primary-ghost-btn xs ml-5">
                Arena
              </button>
              <button className="ant-btn primary-ghost-btn xs ml-5">
                Noticeboard
              </button>
            </div>

            <div className="mb-10 text-center">
              <button className="ant-btn primary-ghost-btn xs">
                Box Office
              </button>
              <button className="ant-btn primary-ghost-btn xs ml-5">
                Application
              </button>
              <button className="ant-btn primary-ghost-btn xs ml-5">
                DP stand
              </button>
            </div>

            <div className="to-email-section">
              <label>To email</label>
              <div>
                <div className="fx">
                  <div className="col col-2 text-right">
                    <button className="emlAddBtn">+</button>
                  </div>
                  <div className="col col-8">
                    <input type="text" value="" className="form-control" />
                  </div>
                  <div className="col col-2">
                    <button className="emailSendBtn">
                      <img src={iconSend} alt="send" />
                    </button>
                  </div>
                </div>
                <div className="fx">
                  <div className="col col-4 fx-aic-inline">
                    <span>Name of staff</span>
                  </div>
                  <div className="col col-6">
                    <input
                      type="text"
                      value="Maria"
                      className="form-control h-30"
                    />
                  </div>
                  <div className="col col-2">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      {/* Support */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Support</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div
            className="table-container scrollSection"
            style={{ maxHeight: '550px' }}
          >
            <table className="table v-middle">
              <thead>
                <tr className="text-primary">
                  <th>Date</th>
                  <th>Username</th>
                  <th>Status</th>
                  <th>Name of staff</th>
                  <th>Rated</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="mb-3">May 10 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>
                    pending <span className="status pending"></span>
                  </td>
                  <td>Maria</td>
                  <td>&nbsp;</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">May 3 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>
                    pending <span className="status pending"></span>
                  </td>
                  <td>Mimi</td>
                  <td>&nbsp;</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">Apr 18 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 7 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>
                    <span className="resolved">resolved</span>
                  </td>
                  <td>David</td>
                  <td>5</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">May 3 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>not resolved</td>
                  <td>Maria</td>
                  <td>4</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">May 3 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>
                    pending <span className="status pending"></span>
                  </td>
                  <td>Mimi</td>
                  <td>&nbsp;</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">May 3 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>pending</td>
                  <td>David</td>
                  <td>&nbsp;</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-3">May 3 2021</p>
                    <p className="mb-0">
                      Latest corres <br />
                      Jun 07 2021
                    </p>
                  </td>
                  <td>xxxxxx</td>
                  <td>pending</td>
                  <td>Maria</td>
                  <td>&nbsp;</td>
                  <td>
                    <img src={editIcon} alt="Edit" height="14" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Row>

      {/* Subject */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62; Subject</h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="my-setting-form">
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label regular text-right">
                Subject
              </label>
              <div className="col col-9">
                <input
                  type="text"
                  value="Ssdfdljsssxxxxxxxxxxxxxxxx….."
                  className="inp regular"
                />
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label regular text-right">
                Date
              </label>
              <div className="col col-9">
                <p className="mt-10 mb-0">May 10 2021 04:49:28 GMT+0900</p>
              </div>
            </div>
            <div className="mb-5">
              <span className="text-primary">Correspondence</span>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel size11">
                Maria <br />
                LoaMoa Team Jun 07 2021 08:28: 38 GMT +0900.
              </label>
              <div className="col col-9">
                <textarea rows={3} className="inp regular">
                  Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..
                </textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel size11">
                JohnB <br />
                Jun05 2021
                <br />
                08:28: 38 GMT
                <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                >
                  Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..
                </textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel size11">
                Maria <br />
                LoaMoa Team <br />
                May 11 2021 <br />
                08:28: 38 GMT <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                >
                  Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..
                </textarea>
              </div>
            </div>
            <div className="grid-row sm form-group">
              <label className="col col-3 inp-label smallLabel size11">
                JohnB <br />
                May 10 2021 <br />
                04:49:28 GMT <br />
                +0900.
              </label>
              <div className="col col-9">
                <textarea
                  rows={3}
                  className="inp regular"
                  placeholder="Message"
                >
                  Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx…..
                </textarea>
              </div>
            </div>

            <div className="to-email-section">
              <label>To email</label>
              <div>
                <div className="fx">
                  <div className="col col-2 text-right">
                    <button className="emlAddBtn">+</button>
                  </div>
                  <div className="col col-8">
                    <input type="text" value="" className="form-control" />
                  </div>
                  <div className="col col-2">
                    <button className="emailSendBtn">
                      <img src={iconSend} alt="send" />
                    </button>
                  </div>
                </div>
                <div className="fx">
                  <div className="col col-4 fx-aic-inline">
                    <span>Name of staff</span>
                  </div>
                  <div className="col col-6">
                    <input
                      type="text"
                      value="Maria"
                      className="form-control h-30"
                    />
                  </div>
                  <div className="col col-2">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Payment Methods
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="payment-card">
            <div className="payment-card__left">
              <figure className="mb-3">
                <img src={visaCard} alt="visa" />
              </figure>
              <h4 className="font-bold">Visa * * * * 7765</h4>
              <h5 className="size12">Expires 09/2024</h5>
              <p className="size12">
                3000 San Fernando, San Francisco,
                <br /> California
              </p>
            </div>
            <div className="payment-card__left">
              <div className="fx-jcfe mb-5">
                <button className="iconx">
                  <img src={editIcon} alt="edit" height="14" />
                </button>
                <button className="iconx">
                  <img src={iconDelete} alt="delete" height="14" />
                </button>
              </div>
              <p className="size12">
                Default payment method <input type="checkbox" />{' '}
              </p>
              <a href="#" className="size12 text-underline">
                View transaction history
              </a>
            </div>
          </div>

          <div className="payment-card">
            <div className="payment-card__left">
              <figure className="mb-3">
                <img src={masterCard} alt="visa" />
              </figure>
              <h4 className="font-bold">Visa * * * * 7765</h4>
              <h5 className="size12">Expires 09/2024</h5>
              <p className="size12">
                3000 San Fernando, San Francisco,
                <br /> California
              </p>
            </div>
            <div className="payment-card__left">
              <div className="fx-jcfe mb-5">
                <button className="iconx">
                  <img src={editIcon} alt="edit" height="14" />
                </button>
                <button className="iconx">
                  <img src={iconDelete} alt="delete" height="14" />
                </button>
              </div>
              <p className="size12">
                Default payment method <input type="checkbox" />{' '}
              </p>
              <a href="#" className="size12 text-underline">
                View transaction history
              </a>
            </div>
          </div>

          <div className="center">
            <a href="#" className="text-underline">
              Add Payment Method
            </a>
          </div>
        </div>
      </Row>

      <Row className="menu-wrapper treasure-menu-wrapper third-dialog d-none">
        <div className="second-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Import Dome to duplicate verification
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="px-30">
            <div className="grid-row aic mb-10">
              <label className="col col-5 size12 text-right">
                Dome Name if any
              </label>
              <div className="col col-7">
                <input type="text" value="@nome" className="form-control xs" />
              </div>
            </div>
            <div className="grid-row aic mb-10">
              <label className="col col-5 size12 text-right">
                Dome Address
              </label>
              <div className="col col-7">
                <input
                  type="text"
                  value="000aaaa191"
                  className="form-control xs"
                />
              </div>
            </div>
            <div>
              <p className="text-primary">
                The verification code for 000aaaa191 has been sent to 000aaaa191
                assigned email xxxana@xxxxx.xxx.
              </p>
            </div>

            <div className="text-center">
              <button className="ant-btn primary-ghost-btn xs mr-5">
                Cancel
              </button>
              <button
                className="ant-btn yellow-gradient xs ml-5"
                onClick={toggleClass}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Row>


      {/* Lbox Card */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog cardBiger d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Lbox
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer scrollSection">
            <Row type='flex'>
              <Col span={12} className='galleryCard'>
                <div className='galleryCard__slider'>
                  <Carousel afterChange={onChange}>
                    <div>
                      <h3>Image 1</h3>
                    </div>
                    <div>
                      <h3>Image 2</h3>
                    </div>
                    <div>
                      <h3>Image 3</h3>
                    </div>
                    <div>
                      <h3>Image 4</h3>
                    </div>
                  </Carousel>
                </div>
                <p>Dome Address Voucher Bundle is the most economical and best way to register a Dome Address and build a Dome. </p>
                <p>Don't miss out on this special discount on Dome Address registration fees.</p>
              </Col>
              <Col span={12} className='voucherBundleCard'>
                <h3 className='font-bold text-center'>Dome Address Voucher Bundle</h3>
                <h4 className='font-bold text-center borderHeading'>Three 5-year Dome Addresses Vouchers</h4>
                <Row type='flex' justify="space-between">
                  <Col span={10}>
                    <img src={voucherPic} alt="voucher" />
                  </Col>
                  <Col span={14}>
                    <h4 className='font-bold'>Price:</h4>
                    <h3 className='font-bold'>USD <del>229.50</del></h3>
                    <h3 className='font-bold border-bottom'>USD 115.00 <span className='text-red font-regular'>(50% ↓)</span></h3>
                    <button className='ant-btn xs yellow-gradient'>Buy</button>
                  </Col>
                </Row>
                <div>
                  <h4 className='font-bold'>Benefits:</h4>
                  <p>You can secure the best location for Dome Address registration first, before anyone else.</p>
                  <ul>
                    <li className='mb-10'>a. With a drastically discounted price, you don’t have to worry about renewing your Dome Address for 5 years.</li>
                    <li>b. If another user wants to buy a Dome Address that you are not using during your possession, it is also possible to transfer it at a good price.</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>

      {/* Coupon and Voucher Card */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="noTitleBox">
            <i className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection" style={{ paddingBottom: '130px' }}>
            <Row>
              <Col>
                <div className='couponTag mb-10'>COUPON 0</div>
                {/* <h3 className='text-center text-primary'>There is no available coupon.</h3> */}
                <div>
                  <div className='vcloop'>
                    <Row type="flex" justify="space-between">
                      <Col span={20}>
                        <div className='vcloop__first'>1. Dome Address 1-Year registration coupon</div>
                        <div className='vcloop__second fx-jcfb'>
                          <span>ID v1233211233</span>
                          <span>Expiration date: Jul 23, 2024</span>
                        </div>
                      </Col>
                      <Col span={4} className='fx-jcc-aic'>
                        <Checkbox onChange={onChange}></Checkbox>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mb-20'>
              <Col>
                <div className='couponTag'>VOUCHER 0</div>
              </Col>
            </Row>

            <div>
              <div className='vcloop'>
                <Row type="flex" justify="space-between">
                  <Col span={20}>
                    <div className='vcloop__first'>1. Dome Address 5-Year registration voucher</div>
                    <div className='vcloop__second fx-jcfe'>
                      <span>Expiration date: Jul 23, 2024</span>
                    </div>
                  </Col>
                  <Col span={4} className='fx-jcc-aic'>
                    <Checkbox onChange={onChange}></Checkbox>
                  </Col>
                </Row>
              </div>
              <div className='vcloop'>
                <Row type="flex" justify="space-between">
                  <Col span={20}>
                    <div className='vcloop__first'>2. Dome Address 5-Year registration voucher</div>
                    <div className='vcloop__second fx-jcfe'>
                      <span>Expiration date: Jul 23, 2024</span>
                    </div>
                  </Col>
                  <Col span={4} className='fx-jcc-aic'>
                    <Checkbox onChange={onChange}></Checkbox>
                  </Col>
                </Row>
              </div>
              <div className='vcloop'>
                <Row type="flex" justify="space-between">
                  <Col span={20}>
                    <div className='vcloop__first'>3. Dome Address 5-Year registration voucher</div>
                    <div className='vcloop__second fx-jcfe'>
                      <span>Expiration date: Jul 23, 2024</span>
                    </div>
                  </Col>
                  <Col span={4} className='fx-jcc-aic'>
                    <Checkbox onChange={onChange}></Checkbox>
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div className='bottom-modal'>
            <p>Would you like to use a coupon or voucher to make your payment?</p>
            <div className='text-center'>
              <button className='ant-btn white-btn xs mr-15'>No thanks</button>
              <button className='ant-btn yellow-gradient xs  ml-15'>Yes</button>
            </div>
          </div>

          <div className='bottom-modal d-none'>
            <button className='closeIcn'></button>
            <p>Please, select the coupon or voucher you would like to use first.</p>
          </div>

        </div>
      </Row>


      {/* Lbox Admin */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Lbox Admin
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer scrollSection" style={{ paddingBottom: '130px' }}>
            <Row className='mb-10'>
              <Col span={18} offset={2}>
                <Select defaultValue="english" style={{ width: "100%" }} onChange={handleChange}>
                  <Option value="english">English</Option>
                  <Option value="korean">Korean</Option>
                </Select>
              </Col>
            </Row>

            <Row className='mb-10'>
              <Typography.Text className='font-bold'>Image:</Typography.Text>
              <div>
                <img src={bannerPic} alt="fig" style={{ maxWidth: '100%' }} />
              </div>
            </Row>

            <Row className='mb-10'>
              <Input placeholder="Image file(jpg, png)" />
            </Row>
            <Row className='mb-10'>
              <Input placeholder="Image file(jpg, png)" />
            </Row>
            <Row className='mb-10'>
              <Input placeholder="Image file(jpg, png)" />
            </Row>

            <Row type='flex' justify='center'>
              <button className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
              <button className='ant-btn yellow-gradient xs ml-5'>Save</button>
            </Row>
          </div>
        </div>
      </Row>

      {/* Voucher */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Voucher
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer scrollSection" style={{ paddingBottom: '130px' }}>

            <Row>
              <Col span={20} offset={2}>
                <Row gutter={8} type='flex' align='middle'>
                  <Col className="gutter-row" span={6}>Username</Col>
                  <Col className="gutter-row" span={14}>
                    <Input placeholder='username' />
                  </Col>
                  <Col className="gutter-row" span={4}>
                    {/* <button className='ant-btn xs min-auto'>check</button> */}
                    <img src={rightcheckicon_green} alt="checked" />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='mt-10'>
              <Col span={20} offset={2} className='text-right'>
                <button className='ant-btn'>+</button>
              </Col>
            </Row>

            <Row type='flex' justify='center'>
              <button className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
              <button className='ant-btn yellow-gradient xs ml-5'>Save</button>
            </Row>
          </div>
        </div>
      </Row>


      {/* Free Month */}
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog d-none">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Free Month
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer scrollSection" style={{maxHeight:'232px'}}>
            <Row>
              <Col span={24} className='months-group'>
                <Radio.Group defaultValue="0" buttonStyle="solid">
                  <Radio.Button value="0">0</Radio.Button>
                  <Radio.Button value="1">1</Radio.Button>
                  <Radio.Button value="2">2</Radio.Button>
                  <Radio.Button value="3">3</Radio.Button>
                  <Radio.Button value="4">4</Radio.Button>
                  <Radio.Button value="5">5</Radio.Button>
                  <Radio.Button value="6">6</Radio.Button>
                  <Radio.Button value="7">7</Radio.Button>
                  <Radio.Button value="8">8</Radio.Button>
                  <Radio.Button value="9">9</Radio.Button>
                  <Radio.Button value="10">10</Radio.Button>
                  <Radio.Button value="11">11</Radio.Button>
                  <Radio.Button value="12">12</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
            <Row type='flex' justify='center'>
              <button className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
              <button className='ant-btn yellow-gradient xs ml-5'>Update</button>
            </Row>
          </div>
        </div>
      </Row>









      <div className={isActive ? 'overlay' : 'overlay opened'}></div>
      <div className={isActive ? 'custom-modal' : 'custom-modal opened'}>
        <div className="custom-modal__close" onClick={toggleClass}></div>
        <div className="custom-modal__body pb-15">
          <div className="email-verify-box">
            <div className="email-verify-box__top">
              <figure>
                <img src={logoPic1} alt="logo" />
              </figure>
              <h3>Verify your email address</h3>
              <p>
                To complete the import process with LoaMoa, you'll need to
                verify your email address Just press the button below.
              </p>
            </div>
            <div className="email-verify-box__mid">
              <button className="ant-btn purple-gradient xs">Verify</button>
            </div>
            <div className="email-verify-box__footer">
              <div>
                <p className="mb-0 size12">
                  4300 Stevens Creek <br />
                  Ste 250, San Jose, CA 95129 <br />
                  2021 Armax Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapState)(UserMenu);
