import React from 'react';
import { Row, Col, Typography } from 'antd';
import { baseURL } from '../../../../lib/config';
import placeholder from '../../../../assets/profile_picture.svg';

interface Props {
  name: string;
  phoneNumber: string;
  img: any;
}

const Client = (props: Props) => {
  return (
    <Row type="flex" justify="start" className="client-data" align="middle">
      <Col className="client-img">
        <img
          src={props.img ? `${baseURL}/uploads/${props.img.picture.nameOnServer}` : placeholder}
        />
      </Col>
      <Col>
        <Row>
          <Typography.Text className="fredoka">{props.name}</Typography.Text>
        </Row>
      </Col>
    </Row>
  );
};

export default Client;
