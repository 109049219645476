import React from 'react';
import Dropzone from 'react-dropzone';
import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import domeNameCityEg from '../../../../../src/assets/domeNameCityEg.png';
import { connect } from 'react-redux';
import {b} from '../../../dashboard/menu/i18next'
import {
  FileUpload,
  getDomeTypes,
  UpdateSetting,
} from '../../../../services/domeService';

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

class DomeTwodIcon extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      city: '',
      city_name: '',
      dome_name_2d: '',
    };
  }

  componentDidMount = async () => {
    const profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    current_edit_dome.profile;
    this.setState({
      image: current_edit_dome.profile,
      // fileName: current_edit_dome.profile.name,
      // preview: baseURL + '/uploads/' + current_edit_dome.profile.id,
      city_name: current_edit_dome.city_name_2d
        ? current_edit_dome.city_name_2d
        : '',
      dome_name_2d: current_edit_dome.dome_name_2d
        ? current_edit_dome.dome_name_2d
        : '',
      file: current_edit_dome?.background_image_2d,
      fileName: current_edit_dome?.background_image_2d?.name,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  oNDropFileHandler = async (files) => {
    let file = files[0];
    let image = file;
    let fileName = file.name;
    let preview = URL.createObjectURL(file);
    const result =  await FileUpload(file);
    let resultfile = result.file;
    this.setState({
      image: image,
      fileName: fileName,
      preview: preview,
      file: resultfile,
    });
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result =  await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: '',
      },
    });
  };

  publish = async () => {
    if (!this.state.file) {
      showErrorMessage(b?.t('File not uploaded.'));
      return false;
    }

    if (!this.state.city_name) {
      showErrorMessage(b?.t('Please enter City Name.'));
      return false;
    }
    // if (!this.state.dome_name_2d) {
    // 	showErrorMessage('Please enter 2D Dome Name');
    // 	return false;
    // }
    let profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
      background_image_2d: this.state.file
        ? this.state.file
        : current_edit_dome.background_image_2d,
      city_name:
        this.state.city_name.charAt(0).toUpperCase() +
        this.state.city_name.slice(1),
      dome_name_2d: this.state.dome_name_2d,
    };
    this.setState({
      loader: true,
    });
    const res =  await UpdateSetting(id, obj);
    const domeSetting =  await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;
    current_edit_dome.background_image_2d = this.state.file;
    current_edit_dome.city_name_2d =
      this.state.city_name.charAt(0).toUpperCase() +
      this.state.city_name.slice(1);
    profile.current_edit_dome = current_edit_dome;
     await this.update(profile);
    this.props.closeTwodIcon();
     showSuccessMessage(b?.t("Dome Setting Updated"));
    this.setState({
      loader: false,
    });
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
     await this.updateContext(profile);
  };

  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    const profile = context.profile;

    const edit_current_dome = profile['edit_current_dome'];
    let dome_name = edit_current_dome?.dome_name;
    let dome_name_suffix = edit_current_dome?.dome_name_suffix;
    let background_image_2d =
      edit_current_dome.background_image_2d !== null
        ? edit_current_dome.background_image_2d.id
        : '';
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{ translation('2D Icon') } </h4>
            <i onClick={this.props.closeTwodIcon} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row sm">
              <div className="col col-4">
                <img
                  src={domeNameCityEg}
                  alt="fig"
                  className="domeNameCityEg"
                />
              </div>

              <div className="col col-8">
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                  { translation('Image file upload') } <em className="required">{translation("Required")}</em>
                  </h4>
                  <div className="grid-row sm">
                    <div className="col col-8">
                      <input
                        type="text"
                        value={
                          this.state.fileName
                            ? this.state.fileName
                            : edit_current_dome.background_image_2d !== null
                            ? edit_current_dome.background_image_2d.name
                            : ''
                        }
                        className="form-control xs"
                        readOnly
                      />
                    </div>
                    <div className="col col-4">
                      <label className="ant-btn primary-ghost-btn xs btn-block">
                        <input
                          onChange={this.fileHandler}
                          type="file"
                          className="d-none"
                        />
                        { translation('Upload') }
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className='mb-10'>
									<h4 className="profileImgUpload__label">Dome Name<em className='required'>(Required)</em></h4>
									<input type="text" name="dome_name_2d" onChange={this.handleChange} value={this.state.dome_name_2d} className="form-control xs ttc" />
								</div> */}
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                  { translation('Name of City, district, location') }{' '}
                    <em className="required">{translation("Required")}</em>
                  </h4>
                  <input
                    type="text"
                    name="city_name"
                    onChange={this.handleChange}
                    value={
                      this.state.city_name &&
                      this.state.city_name.charAt(0).toUpperCase() +
                        this.state.city_name.slice(1)
                    }
                    className="form-control xs ttc"
                  />
                </div>
                <div className="text-center profileImgUpload twoDIconPrev">
                  <figure className="photoPrev">
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        this.oNDropFileHandler(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img
                              height="120"
                              width="120"
                              src={
                                this.state.preview
                                  ? this.state.preview
                                  : ` https://app.loamoa-dev-merge.cladev.com/uploads/` +
                                    background_image_2d
                              }
                            />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </figure>
                  <h5>{translation("Drag and fit")}</h5>
                </div>
              </div>
            </div>
            <div className="grid-row"></div>
            <div className="text-center mb-20">
              <h3 className="text-primary weight-600">{translation("Preview")}</h3>
            </div>
            <div className="twoDPlaceholder">
              <figure className="twoDPlaceholder__fig">
                <img
                  src={
                    this.state.preview
                      ? this.state.preview
                      : ` https://app.loamoa-dev-merge.cladev.com/uploads/` +
                        background_image_2d
                  }
                  alt="Logo"
                />
              </figure>
              <figcaption className="twoDPlaceholder__info">
                <h3 className="weight-700">
                  {this.renderDomeName(
                    edit_current_dome.dome_status,
                    edit_current_dome.dome_name,
                    edit_current_dome.dome_name_suffix,
                    edit_current_dome.file
                  )}
                  {/* {`${dome_name}${
                  dome_name_suffix ? dome_name_suffix : ''
                }`} */}
                </h3>
                <h3 className="weight-700">{this.state.dome_name_2d}</h3>
                <h4 className="weight-700 text-muted">
                  {this.state.city_name}
                </h4>
              </figcaption>
            </div>
            <div className="grid-row fx-jcc">
              <div className="col col-6 fx-jcsb">
                <button
                  onClick={this.props.closeTwodIcon}
                  className="ant-btn primary-ghost-btn xs"
                >
                 {translation("Cancel")} 
                </button>
                <button
                  onClick={this.publish}
                  disabled={this.state.loader ? true : false}
                  className="ant-btn yellow-gradient xs"
                >
                  {this.state.loader ? translation('Loading...') : translation('Edit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(DomeTwodIcon);
