import { baseURL, addtocart, fetchCart, deleteFromCart } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';
import {b} from '../containers/dashboard/menu/i18next';


const showErrorMessage = (title, description?) => {
    notification.error({
        message: `${title}`,
        description: `${description || ''}`,
        duration: 10,
    });
};

const showSuccessMessage = (title) => {
    notification.success({
      message: `${title}`,
      duration: 10,
    });
  };

export const addToCart = async (data: any) => {
    try {
        const fetchResponse =   await fetch(baseURL + addtocart, {
            method: 'POST',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ name: data.dome_name, latitude: data.dome_lat, longitude: data.dome_lng })
        });
        const response =   await fetchResponse.json();
        if (response.code === 1) {
            showSuccessMessage(b?.t('Added to cart'))
            return response;
            // return response.data;

        } else {
             showErrorMessage(b?.t('This location already in your cart'),b?.t('Its already registered'));
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
};


export const getCart = async () => {
    try {
        const fetchResponse =   await fetch(baseURL + fetchCart, {
            method: 'GET',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });
        const response =   await fetchResponse.json();
        if (response.code === 1) {
            return response.data;

        } else {
            showErrorMessage('Dome not added in cart', response.code);
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
};

export const deleteItem = async (id:number) => {
    try {
        const fetchResponse =   await fetch(baseURL + deleteFromCart +'/'+id, {
            method: 'DELETE',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });
        const response =   await fetchResponse.json();
        if (response.code === 1) {
            return response.data;

        } else {
            showErrorMessage('Dome not added in cart', response.code);
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
};

