import React, { useState } from 'react';
import { Row, Col, Typography, Input, DatePicker, Button } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import calendar from '../../assets/icon_date.svg';
import SelectorGray from '../SelectorGray';
import CloseX from '../../assets/closeX.svg';
import * as statisticsString from '../../lib/strings/statistics';
import countryData from '../../helpers/country.json';
import { roleAdmin } from '../../lib/const';
import { statisticsForMasters } from '../../lib/SubMenuConstants';

function FilterForm(props) {
  const {
    closeForm,
    language,
    valueObject,
    onChange,
    onClear,
    onApply,
    statisticsType,
    kitList,
    role,
  } = props;

  const [countryNames] = useState(countryData.map((country) => country.name));

  const handleDates = (event: any, id: 'dateFrom' | 'dateTo') => {
    let dateMoment = moment(event);
    if ('dateFrom' === id) {
      dateMoment = moment(event)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
    } else {
      dateMoment = moment(event)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 999);
    }
    onChange(id, dateMoment);
  };

  const onApplyHandler = () => {
    onApply();
    closeForm();
  };

  const onEnterHandler = (event) => {
    const enterKey = 13;
    if (event.keyCode === enterKey) {
      onApplyHandler();
    }
  };

  let configuration = {
    username: true,
    treasureName: true,
    fromDate: true,
    toDate: true,
    country: true,
    adKitType: true,
    status: true,
    partnerUsername: false,
    paymentStatus: false,
    treasureStatus: false,
    className: 'statistics-filter-container-treasure',
    span: 8,
    User_type: false,
    user_filter: false,
    Bundle: false,
    expiration:false

  };

  if (statisticsType === 'StatisticsForPoints' || statisticsType === 'StatisticsForCoins') {
    configuration = {
      username: true,
      treasureName: false,
      fromDate: true,
      toDate: true,
      country: true,
      adKitType: false,
      status: false,
      user_filter: false,
      partnerUsername: false,
      paymentStatus: false,
      treasureStatus: false,
      className: 'statistics-filter-container-points-coins',
      span: 12,
      User_type: false,
      Bundle: false,
      expiration:false
    };
  } else if (statisticsType === 'StatisticsForPartners') {
    if (role >= roleAdmin) {
      configuration = {
        username: false,
        treasureName: false,
        fromDate: true,
        toDate: true,
        country: false,
        adKitType: false,
        status: false,
        user_filter: false,
        partnerUsername: true,
        paymentStatus: true,
        treasureStatus: false,
        className: 'statistics-filter-container-partners-admin',
        span: 12,
        User_type: false,
        Bundle: false,
        expiration:false
      };
    } else {
      configuration = {
        username: false,
        treasureName: false,
        fromDate: true,
        toDate: true,
        country: false,
        adKitType: false,
        status: false,
        user_filter: false,
        partnerUsername: false,
        paymentStatus: true,
        treasureStatus: true,
        className: 'statistics-filter-container-partners-partner',
        span: 12,
        User_type: false,
        Bundle: false,
        expiration:false
      };
    }
  } else if (statisticsType === statisticsForMasters) {
    configuration = {
      username: false,
      treasureName: true,
      fromDate: true,
      toDate: true,
      country: false,
      adKitType: true,
      status: true,
      user_filter: false,
      partnerUsername: false,
      paymentStatus: true,
      treasureStatus: true,
      className: 'statistics-filter-container-partners-partner',
      span: 12,
      User_type: false,
      Bundle: false,
      expiration:false
    };
  }

  else if (statisticsType === 'StatisticsForUsers') {
    configuration = {
      username: true,
      treasureName: false,
      fromDate: true,
      toDate: true,
      country: true,
      User_type: true,
      adKitType: false,
      status: false,
      user_filter: true,
      partnerUsername: false,
      paymentStatus: false,
      treasureStatus: false,
      className: 'statistics-filter-container-points-users',
      span: 12,
      Bundle: false,
      expiration:false
    };
  }

  else if (statisticsType === 'StatisticsForCoupon') {
    configuration = {
      username: true,
      treasureName: false,
      fromDate: true,
      toDate: true,
      country: true,
      User_type: false,
      adKitType: false,
      status: false,
      user_filter: false,
      partnerUsername: false,
      paymentStatus: false,
      treasureStatus: false,
      className: 'statistics-filter-container-points-coupons',
      span: 12,
      Bundle: false,
      expiration:false
    };
  }
  else if (statisticsType === 'StatisticsForVoucher') {
    configuration = {
      username: true,
      treasureName: false,
      fromDate: true,
      toDate: true,
      country: true,
      User_type: false,
      adKitType: false,
      status: false,
      user_filter: false,
      partnerUsername: false,
      paymentStatus: false,
      treasureStatus: false,
      className: 'statistics-filter-container-points-voucher',
      span: 8,
      Bundle: true,
      expiration:true
    };
  }

  return (
    <div className={configuration.className}>
      <img src={CloseX} onClick={closeForm} className="statistics-form-close-img" />
      {/* Username and treasure name */}
      <Row
        gutter={[16, 15]}
        style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }}
      >
        {configuration.username && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-text-input-label">
              <Typography.Text className="statistics-text">
                {statisticsString.username[language]}
              </Typography.Text>
            </Row>
            <Row>
              <Input
                className="input-treasure full-width"
                placeholder={statisticsString.usernamePlaceholder[language]}
                value={valueObject.username}
                onChange={(event) => onChange('username', event.target.value)}
                onKeyDown={onEnterHandler}
              />
            </Row>
          </Col>
        )}

        {configuration.treasureName && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-text-input-label">
              <Typography.Text className="statistics-text">
                {statisticsString.treasureName[language]}
              </Typography.Text>
            </Row>
            <Row>
              <Input
                className="input-treasure full-width"
                placeholder={statisticsString.treasureNamePlaceholder[language]}
                value={valueObject.treasureName}
                onChange={(event) => onChange('treasureName', event.target.value)}
                onKeyDown={onEnterHandler}
              />
            </Row>
          </Col>
        )}

        {configuration.partnerUsername && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-text-input-label">
              <Typography.Text className="statistics-text">
                {statisticsString.partnerUsername[language]}
              </Typography.Text>
            </Row>
            <Row>
              <Input
                className="input-treasure full-width"
                placeholder={statisticsString.usernamePlaceholder[language]}
                value={valueObject.partnerUsername}
                onChange={(event) => onChange('partnerUsername', event.target.value)}
                onKeyDown={onEnterHandler}
              />
            </Row>
          </Col>
        )}
      </Row>

      {/* Date selectors */}
      <Row
        gutter={[16, 15]}
        style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }}
      >
        {configuration.fromDate && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.from[language]}
              </Typography.Text>
            </Row>
            <Row>
              <DatePicker
                className="statistics-date-picker"
                format={'DD/MM/YY'}
                showTime={false}
                suffixIcon={<img src={calendar} />}
                onChange={(event) => {
                  handleDates(event, 'dateFrom');
                }}
                disabledDate={(current) => {
                  return valueObject.dateTo ? valueObject.dateTo.isBefore(current) : false;
                }}
                value={valueObject.dateFrom ? valueObject.dateFrom : null}
              />
            </Row>
          </Col>
        )}

        {configuration.toDate && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.to[language]}
              </Typography.Text>
            </Row>
            <Row>
              <DatePicker
                className="statistics-date-picker"
                format={'DD/MM/YY'}
                showTime={false}
                suffixIcon={<img src={calendar} />}
                onChange={(event) => {
                  handleDates(event, 'dateTo');
                }}
                disabledDate={(current) => {
                  return valueObject.dateFrom ? valueObject.dateFrom.isAfter(current) : false;
                }}
                value={valueObject.dateTo ? valueObject.dateTo : null}
              />
            </Row>
          </Col>
        )}
      </Row>

      {/* Drop down selectors */}
      <Row
        gutter={[16, 15]}
        style={{ marginTop: '0px', marginBottom: '0px', paddingTop: '0px', paddingBottom: '0px' }}
      >
        {configuration.country && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.country[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('country', event)}
                value={valueObject.country ? valueObject.country : undefined}
                // list={['Croatia','United States of America', ]}
                list={countryNames}
                firstCellEmpty={true}
                placeholder={statisticsString.select[language]}
                showSearch={true}
              />
            </Row>
          </Col>
        )}

        {configuration.adKitType && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.adKitType[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('kitType', event)}
                value={valueObject.kitType ? valueObject.kitType : undefined}
                firstCellEmpty={true}
                list={kitList?.map((kit) => kit.name)}
                placeholder={statisticsString.select[language]}
                showSearch={true}
              />
            </Row>
          </Col>
        )}


        {configuration.User_type && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.Usertype[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('User_type', event)}
                value={valueObject.User_type ? valueObject.User_type : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusListuser[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

        {configuration.Bundle && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.Bundle[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('Bundle', event)}
                value={valueObject.Bundle ? valueObject.Bundle : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusBundle[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

        {configuration.status && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.status[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('status', event)}
                value={valueObject.status ? valueObject.status : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusList[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

        {configuration.user_filter && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.status[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('user_filter', event)}
                value={valueObject.user_filter ? valueObject.user_filter : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusListDome[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

{configuration.expiration && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.status[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange(' expiration', event)}
                value={valueObject.expiration ? valueObject. expiration : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusBundle_expiration[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

        {configuration.paymentStatus && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.paymentStatus[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('paymentStatus', event)}
                value={valueObject.paymentStatus ? valueObject.paymentStatus : undefined}
                firstCellEmpty={true}
                list={statisticsString.paymentStatusList[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}

        {configuration.treasureStatus && (
          <Col span={configuration.span}>
            <Row className="statistics-spacing-bellow-label">
              <Typography.Text className="statistics-text">
                {statisticsString.treasureStatus[language]}
              </Typography.Text>
            </Row>
            <Row>
              <SelectorGray
                containerStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                dropDownTextPosition="start"
                selectorTextPosition="start"
                onChange={(event) => onChange('status', event)}
                value={valueObject.status ? valueObject.status : undefined}
                firstCellEmpty={true}
                list={statisticsString.filterStatusList[language]}
                placeholder={statisticsString.select[language]}
              />
            </Row>
          </Col>
        )}
      </Row>

      {/* Apply and clear buttons */}
      <Row gutter={[16, 15]}>
        <Col style={{ marginTop: '15px' }}>
          <Button
            onClick={onApplyHandler}
            className="buttons yellow-gradient statistics-button-width"
          >
            {statisticsString.apply[language]}
          </Button>
          <Button
            onClick={onClear}
            className="buttons statistics-button-width transparent-btn-black"
          >
            <span style={{ textDecoration: 'underline' }}>
              {statisticsString.clearAll[language]}
            </span>
          </Button>
        </Col>
      </Row>
    </div>
  );
}

FilterForm.propTypes = {
  closeForm: PropTypes.func,
  language: PropTypes.string,
  onChange: PropTypes.func,
  valueObject: PropTypes.object,
  onClear: PropTypes.func,
  onApply: PropTypes.func,
  statisticsType: PropTypes.oneOf([
    'StatisticsForTreasure',
    'StatisticsForPoints',
    'StatisticsForCoins',
    'StatisticsForPartners',
    'StatisticsForAssignment',
    'StatisticsForUsers',
    'StatisticsForVoucher'
  ]),
  kitList: PropTypes.array,
  role: PropTypes.number,
};

FilterForm.defaultProps = {
  closeForm: () => {
    console.log('missing closing function');
  },
  language: 'EN',
  onChange: (key, value) => {
    console.log(`missign logic key: ${key}, value: ${value}`);
  },
  valueObject: {
    treasureName: '',
    dateFrom: '',
    dateTo: '',
    country: '',
    kitType: '',
    status: '',
    partnerUsername: '',
    paymentStatus: '',
    treasureStatus: '',
    User_type: '',
    status1: '',

  },
  onClear: () => {
    console.log('missing logic');
  },
  onApply: () => {
    console.log('missing logic');
  },
};

export default FilterForm;
