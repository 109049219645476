import React, { Component, RefObject } from 'react';
import {
  Row,
  Typography,
  Col,
  Form,
  Input,
  Select,
  Button,
  notification,
  Checkbox,
  Spin,
} from 'antd';
import { characterConstants } from '../../../../constants/characterConstants';
import { connect } from 'react-redux';
import {
  deleteCharacterStory,
  updateCharacterStoryWithPercantage,
  postStoryLocalization,
  updateCharacterStoryFilesWithPercantage,
} from '../../../../services/characterService';
import { languages } from '../../../../lib/interfaces/language';
import { FormComponentProps } from 'antd/lib/form';
import {
  maximumAllowedMediaSize,
  typeJPEG,
  typePNG,
  typeGIF,
  typeWEBP,
  baseURL,
  typeMP4,
  characterStoryAPI,
} from '../../../../lib/config';
import UploadImageIcon from '../../../../assets/treasure_upload_image_icon.svg';
import UploadVideoIcon from '../../../../assets/uploadVideo.svg';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { treasureConstants } from '../../../../constants/treasureConstants';
import * as characterString from '../../../../lib/strings/character';
import { Action } from 'redux';
import ReactPlayer from 'react-player';
import { ModalLoadingProgressText } from '../../../../components';
import { constructStoryLocalizationBody, isEmpty } from './characterHelperFunctions';
import { batchEdit } from '../../../../actions/characterActions';

interface Props extends FormComponentProps {
  character: any;
  language: languages;
  title: string;
  subtitle: string; //brisati
  sub_title: string;
  mainDescription: string; //brisati
  description: string;
  auxillaryDescription: string; //brisati
  auxilliary_description: string;
  characterID: number;
  isEditing: boolean;
  isCreating: boolean;
  picturePath: string;
  videoPath: string;
  video_thumbnail: any;
  preview_image?: any;
  preview_video?: any;
  image: string;
  video: string;
  imgUrl: string;
  characters?: Array<any>;
  storeCharacters: (characters: any) => Action;
  handleInput: (param: string, value: string | number) => Action;
  handleClose: () => Action;
  storeCharacterStoryImage: (imgFile: File, imgUrl: any) => Action;
  fetchAgain: (needsFetch: boolean) => Action;
  purgeCharacterStoryData: () => Action;
  ID: number;
  loading: boolean;
  newToken: string;
  filePath: (fileName: string, path: string) => Action;
  errors: any;
  batchUpdateCharacter: any;
  characterStoryLocalization: any;
  characterDetails: any;
}

const mapState = (state: {
  character: any;
  authentication: { language: string; user: any };
  characters: Array<any>;
  characterStoryLocalization: any;
  characterDetails: any;
}) => {
  const { character, authentication, characterStoryLocalization, characterDetails } = state;
  const { user } = authentication;
  const { newToken } = user;
  const {
    title,
    sub_title,
    description,
    auxilliary_description,
    isEditing,
    isCreating,
    imgUrl,
    characterID,
  } = character;
  return {
    character: character,
    language: state.authentication.language,
    title,
    sub_title,
    description,
    auxilliary_description,
    isEditing,
    isCreating,
    imgUrl,
    characterID,
    newToken,
    characters: state.characters,
    characterStoryLocalization,
    characterDetails,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleInput: (param: string, value: string) =>
      dispatch({ type: characterConstants.UPDATE_INPUT, param, value }),
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    storeCharacterStoryImage: (imgFile: File, imgUrl: any) =>
      dispatch({ type: characterConstants.CHARACTER_IMAGE, imgFile, imgUrl }),
    storeCharacters: (characters: any) =>
      dispatch({ type: treasureConstants.SAVE_CHARACTERS, characters }),
    fetchAgain: (needsFetch: boolean) =>
      dispatch({ type: characterConstants.FETCH_CHARACTERS, needsFetch }),
    purgeCharacterStoryData: () => dispatch({ type: characterConstants.PURGE_CHARACTER_DATA }),
    filePath: (typeOfFile: string, path: string) =>
      dispatch({ type: characterConstants.STORE_FILE_PATH, typeOfFile, path }),
    batchUpdateCharacter: (payload) => dispatch(batchEdit(payload)),
  };
};

class CharacterForm extends Component<Props, any> {
  uploadImageRef: RefObject<any>;
  uploadVideoRef: RefObject<any>;
  uploadThumbRef: RefObject<any>;
  uploadBottomImageRef: RefObject<any>;

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      loading: false,
      percentage: 0,
      done: false,
      thumb: '',
      thumbURL: '',
      thumbHasChanged: false,
      video: '',
      videoURL: '',
      videoHasChanged: false,
      image: '',
      imageURL: '',
      imageHasChanged: false,
      bottomImage: '',
      bottomImageURL: '',
      bottomImageHasChanged: false,
    };

    this.uploadImageRef = React.createRef();
    this.uploadVideoRef = React.createRef();
    this.uploadThumbRef = React.createRef();
    this.uploadBottomImageRef = React.createRef();
  }

  componentDidMount = async () => {
    const { isEditing, character, form } = this.props;
    if (isEditing) {
      form.setFieldsValue({
        title: character.title,
        sub_title: character.sub_title,
        description: character.description,
        auxilliary_description:
          character.auxilliary_description === ' ' ? '' : character.auxilliary_description,
        character: character.characterID,
      });
      this.setState({
        thumb: character.video_thumbnail.image,
        video: character.preview_video?.video,
        image: character.preview_image?.image,
        bottomImage: character.optional_image?.image,
      });
    }
  };

  handleClose = async () => {
    if (!this.props.isEditing) {
       await deleteCharacterStory(this.props.character.id);
    }
    this.props.handleClose();
    this.props.purgeCharacterStoryData();
  };

  handleChange = (e: { target: { name: string; value: string } }) => {
    this.props.handleInput(e.target.name, e.target.value);
  };

  handleSelect = (e: any) => {
    this.props.handleInput('characterID', e);
  };

  handleFile = (event: any) => {
    event.persist();
    event.preventDefault();
    const supportedFileTypes = [typeJPEG, typePNG, typeGIF, typeWEBP, typeMP4];
    const image = event.target.files[0];
    const type = image.type;
    if (supportedFileTypes.indexOf(type) > -1 && image.size < maximumAllowedMediaSize) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        switch (event.target.id) {
          case 'video':
            this.setState({
              [event.target.id]: image,
              videoURL: reader.result,
              videoHasChanged: true,
            });
            return;
          case 'image':
            this.setState({
              [event.target.id]: image,
              imageURL: reader.result,
              imageHasChanged: true,
            });
            return;
          case 'thumb':
            this.setState({
              [event.target.id]: image,
              thumbURL: reader.result,
              thumbHasChanged: true,
            });
            return;
          case 'bottomImage':
            this.setState({
              [event.target.id]: image,
              bottomImageURL: reader.result,
              bottomImageHasChanged: true,
            });
            return;
          default:
            return;
        }
      };
    } else {
      console.log('Error with upload, type or size invalid');
    }
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields();
    const { isEditing, characterStoryLocalization, character } = this.props;
    const { imageHasChanged, thumbHasChanged, videoHasChanged, bottomImageHasChanged } = this.state;

    const validationErrors = isEditing
      ? Object.values(
          this.props.form.getFieldsValue(['character', 'title', 'sub_title', 'description'])
        )
      : Object.values(
          this.props.form.getFieldsValue([
            'character',
            'title',
            'sub_title',
            'description',
            'image',
            'thumbnail',
            'video',
          ])
        );

    if (!validationErrors.some((e) => e === undefined || e === '')) {
      let data = {
        ...this.props.character,
        thumb: this.state.thumb,
        video: this.state.video,
        image: this.state.image,
        bottomImage: this.state.bottomImage,
      };

      if (
        (this.props.isEditing || this.props.isCreating) &&
        data.thumb &&
        data.video &&
        data.image
      ) {
        // POST story localization data
        const {
          id,
          title,
          sub_title,
          description,
          auxilliary_description,
          titleShowsInList,
          titleShowsInDetails,
          subtitleShowsInList,
          subtitleShowsInDetails,
        } = this.props.character;

        // uploading
        let filesData: any;
        let res: any;
        this.setState({ loading: true });
        if (character.selectedLanguage === 'EN' || !isEditing) {
          console.log('english upload');
          res =  await updateCharacterStoryWithPercantage(data, {
            onProgress: (perc: number) => {
              if (perc >= 100) {
                // this.setState({ done: true })
              }
              this.setState({ percentage: perc });
            },
          });
          filesData = res.data?.story;
        } else {
          console.log('other language upload');
          res =  await updateCharacterStoryFilesWithPercantage(data, {
            onProgress: (perc: number) => {
              if (perc >= 100) {
                // this.setState({ done: true })
              }
              this.setState({ percentage: perc });
            },
          });
          filesData = res.data;
        }

        const characterStoryLocalizationCurrentLanguage =
          characterStoryLocalization[character.selectedLanguage.toLowerCase()];

        let optional_image = filesData.optional_image?.image
          ? filesData.optional_image?.image
          : this.props.character.optional_image?.image;
        if (!data.bottomImage) {
          optional_image = '';
        }

        let preperingLocalizationData = {
          title,
          sub_title,
          description,
          auxilliary_description,
          titleShowsInList,
          titleShowsInDetails,
          subtitleShowsInList,
          subtitleShowsInDetails,
          optional_image: optional_image,
          preview_image: filesData.preview_image?.image
            ? filesData.preview_image?.image
            : this.props.character.preview_image?.image,
          video_thumbnail: filesData.video_thumbnail?.image
            ? filesData.video_thumbnail?.image
            : this.props.character.video_thumbnail?.image,
          preview_video: filesData.preview_video?.video
            ? filesData.preview_video?.video
            : this.props.character.preview_video?.video,
        };

        if (characterStoryLocalizationCurrentLanguage) {
          let optionalImage = '';
          optionalImage =
            characterStoryLocalizationCurrentLanguage &&
            characterStoryLocalizationCurrentLanguage.optional_image;

          preperingLocalizationData = {
            ...preperingLocalizationData,
            optional_image: bottomImageHasChanged ? filesData.optional_image?.image : optionalImage,
            preview_image: imageHasChanged
              ? filesData.preview_image?.image
              : characterStoryLocalizationCurrentLanguage.preview_image,
            video_thumbnail: thumbHasChanged
              ? filesData.video_thumbnail?.image
              : characterStoryLocalizationCurrentLanguage.video_thumbnail,
            preview_video: videoHasChanged
              ? filesData.preview_video?.video
              : characterStoryLocalizationCurrentLanguage.preview_video,
          };
        }

        const selectedLanguage = this.props.character?.selectedLanguage.toLowerCase();
        const storyLocalization = constructStoryLocalizationBody(
          id,
          selectedLanguage,
          preperingLocalizationData
        );
         await postStoryLocalization(storyLocalization);
        this.setState({ done: true });

        this.props.handleClose();
        if (!res || !res.data) {
          notification.error({
            message: characterString.updateFailed[this.props.language],
          });
          throw 'update failed';
        } else {
          this.props.fetchAgain(true);
          //this.props.purgeCharacterStoryData()
        }
      } else {
        throw 'upload files';
      }
    } else {
      throw 'error with validation';
    }
  };

  handleRefClick = (e: any) => {
    switch (e.target.id) {
      case 'thumb':
        this.uploadThumbRef.current.click();
        return;
      case 'video':
        this.uploadVideoRef.current.click();
        return;
      case 'image':
        this.uploadImageRef.current.click();
        return;
      case 'bottomImage':
        this.uploadBottomImageRef.current.click();
        return;
      default:
        console.warn('handleRefClick: unknown case');
    }
  };

  hadnleVideoEdit = (event) => {
    event.stopPropagation();
    this.handleRefClick({ target: { id: 'video' } });
  };

  selectFile = (type: string) => {
    if (this.props.isEditing) {
      switch (type) {
        case 'thumb':
          if (this.state.thumbURL) {
            return (
              <img
                id="thumb"
                onClick={this.handleRefClick}
                src={this.state.thumbURL}
                className="img-upload adjust"
              />
            );
          }
          return (
            <img
              id="thumb"
              onClick={this.handleRefClick}
              src={`${baseURL}${this.props.character.video_thumbnail?.image}`}
              className="img-upload adjust"
            />
          );
        case 'video':
          // if this.state.video is URL
          if (typeof this.state.video === 'string') {
            return (
              <ReactPlayer
                url={`${baseURL}${this.props.character.preview_video?.video}`}
                controls
                height="auto"
              />
            );
          }
          return <ReactPlayer url={`${this.state.videoURL}`} controls height="auto" />;
        case 'image':
          if (this.state.imageURL) {
            return (
              <img
                id="image"
                onClick={this.handleRefClick}
                src={this.state.imageURL}
                className="img-upload adjust"
              />
            );
          }
          return (
            <img
              id="image"
              onClick={this.handleRefClick}
              src={`${baseURL}${this.props.character.preview_image?.image}`}
              className="img-upload adjust"
            />
          );
        case 'bottomImage':
          if (this.state.bottomImageURL) {
            return (
              <img
                id="bottomImage"
                onClick={this.handleRefClick}
                src={this.state.bottomImageURL}
                className="img-upload adjust"
              />
            );
          }
          return (
            <img
              id="bottomImage"
              onClick={this.handleRefClick}
              src={`${baseURL}${this.props.character.optional_image?.image}`}
              className="img-upload adjust"
            />
          );
      }
    } else {
      switch (type) {
        case 'thumb':
          return (
            <img
              id="thumb"
              onClick={this.handleRefClick}
              src={`${this.state.thumbURL}`}
              className="img-upload adjust"
            />
          );
        case 'video':
          return <ReactPlayer url={`${this.state.videoURL}`} controls height="auto" />;
        case 'image':
          return (
            <img
              id="image"
              onClick={this.handleRefClick}
              src={`${this.state.imageURL}`}
              className="img-upload adjust"
            />
          );
        case 'bottomImage':
          return (
            <img
              id="bottomImage"
              onClick={this.handleRefClick}
              src={`${this.state.bottomImageURL}`}
              className="img-upload adjust"
            />
          );
      }
    }
  };

  handleLanguage = async (value) => {
    const { batchUpdateCharacter, characterStoryLocalization, isEditing, character } = this.props;

    // in edit set fields if selected language already exists
    if (characterStoryLocalization[value.toLowerCase()] && isEditing) {
      const newLanguageData = characterStoryLocalization[value.toLowerCase()];
      this.setState({ bottomImage: '' });
      batchUpdateCharacter(newLanguageData);

      this.props.form.setFieldsValue({
        title: newLanguageData.title,
        sub_title: newLanguageData.sub_title,
        description: newLanguageData.description,
        auxilliary_description:
          newLanguageData.auxilliary_description === ' '
            ? ''
            : newLanguageData.auxilliary_description,
      });
    }
    // in edit empty fileds if there is no selected language
    else if (!characterStoryLocalization[value.toLowerCase()] && isEditing) {
      batchUpdateCharacter({
        titile: '',
        sub_title: '',
        description: '',
        auxilliary_description: '',
        titleShowsInList: character.titleShowsInList,
        titleShowsInDetails: character.titleShowsInDetails,
        subtitleShowsInList: character.subtitleShowsInList,
        subtitleShowsInDetails: character.subtitleShowsInDetails,
        optional_image: character.optional_image.image,
        preview_image: character.preview_image.image,
        preview_video: character.preview_video.video,
        video_thumbnail: character.video_thumbnail.image,
      });

      this.props.form.setFieldsValue({
        title: '',
        sub_title: '',
        description: '',
        auxilliary_description: '',
      });
    }
    this.props.handleInput('selectedLanguage', value);
  };

  handleCheckboxs = (event) => {
    this.props.handleInput(event.target.id, event.target.checked);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { language, character, isEditing } = this.props;
    const { isInitialized } = this.state;

    const languageList = characterString.languageList[language];
    const selectedLanguage = languageList.find(
      (element) => element.value === this.props.character?.selectedLanguage
    );

    return (
      <Row className="characterStory-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={this.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            {!isEditing ? (
              <Typography.Text className="contest-title bold">
                {characterString.creatingCharacterStory[language]}
              </Typography.Text>
            ) : (
              <Typography.Text className="contest-title bold">
                {characterString.editingCharacterStory[language]}
              </Typography.Text>
            )}
          </Row>

          <Form
            className="form-contest"
            onSubmit={(e) => e.preventDefault()}
            onReset={(e) => e.preventDefault()}
          >
            {/* Character selector */}
            <Row>
              <Form.Item
                label={characterString.characterLabel[language]}
                className="contest-title full-width"
              >
                {getFieldDecorator('character', {
                  rules: [
                    { required: true, message: characterString.characterValidation[language] },
                  ],
                })(
                  <Select
                    className="select-treasure-type treasure-type-picker full-width"
                    onChange={this.handleSelect}
                  >
                    {this.props.characters.map(
                      (character: { ID: React.ReactText; image: any; name: React.ReactNode }) => {
                        return (
                          <Select.Option
                            key={character.ID}
                            className="sticker-character"
                            value={character.ID}
                          >
                            <img src={`${baseURL}/${character.image}`} />
                            {character.name}
                          </Select.Option>
                        );
                      }
                    )}
                  </Select>
                )}
              </Form.Item>
            </Row>

            {/* Language selector */}
            <Row>
              <Form.Item className="contest-title full-width">
                <Select
                  className="select-treasure-type treasure-type-picker full-width"
                  onChange={this.handleLanguage}
                  defaultValue={character.selectedLanguage || 'EN'}
                >
                  {languageList.map((language) => (
                    <Select.Option value={language.value} key={language.value}>
                      {language.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                className="roboto-regular not-required-attachment"
                style={{ width: '100%' }}
                label={characterString.thumbnailImageLabel[language]}
              >
                {getFieldDecorator('thumbnail', {
                  rules: [
                    {
                      required: isEditing ? false : true,
                      message: characterString.characterStoryThumbnailValidation[language],
                    },
                  ],
                })(
                  <Row type="flex" justify="center" style={{ width: '100%' }}>
                    <input
                      id="thumb"
                      type="file"
                      accept={`${typePNG}, ${typeWEBP}, ${typeJPEG}, ${typeGIF}`}
                      onChange={this.handleFile}
                      ref={this.uploadThumbRef}
                      style={{ visibility: 'hidden' }}
                    />
                    <div className="upload" style={{ width: '100%' }}>
                      {this.state.thumb ? (
                        this.selectFile('thumb')
                      ) : (
                        <img
                          id="thumb"
                          onClick={this.handleRefClick}
                          src={UploadImageIcon}
                          className="upload-sticker-image"
                        />
                      )}
                    </div>
                  </Row>
                )}
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                className="roboto-regular not-required-attachment"
                style={{ width: '100%' }}
                label={characterString.videoLabel[language]}
              >
                {getFieldDecorator('video', {
                  rules: [
                    {
                      required: isEditing ? false : true,
                      message: characterString.characterStoryVideoValidation[language],
                    },
                  ],
                })(
                  <Row type="flex" justify="center" style={{ width: '100%' }}>
                    <Col style={{ width: '100%' }}>
                      <input
                        id="video"
                        type="file"
                        accept={`${typeMP4}`}
                        onChange={this.handleFile}
                        ref={this.uploadVideoRef}
                        style={{ visibility: 'hidden' }}
                      />
                      <div className="upload" onClick={this.hadnleVideoEdit}>
                        {this.state.video ? (
                          this.selectFile('video')
                        ) : (
                          <img
                            id="video"
                            onClick={() => {}}
                            src={UploadVideoIcon}
                            className="upload-sticker-image"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                className="roboto-regular not-required-attachment"
                style={{ width: '100%' }}
                label={characterString.imageLabel[language]}
              >
                {getFieldDecorator('image', {
                  rules: [
                    {
                      required: isEditing ? false : true,
                      message: characterString.characterStoryImageValidation[language],
                    },
                  ],
                })(
                  <Row type="flex" justify="center" style={{ width: '100%' }}>
                    <input
                      id="image"
                      type="file"
                      accept={`${typePNG}, ${typeWEBP}, ${typeJPEG}, ${typeGIF}`}
                      onChange={this.handleFile}
                      ref={this.uploadImageRef}
                      style={{ visibility: 'hidden' }}
                    />
                    <div className="upload" style={{ width: '100%' }}>
                      {this.state.image ? (
                        this.selectFile('image')
                      ) : (
                        <img
                          id="image"
                          onClick={this.handleRefClick}
                          src={UploadImageIcon}
                          className="upload-sticker-image"
                        />
                      )}
                    </div>
                  </Row>
                )}
              </Form.Item>

              {/* Bottom Image (Optional) */}
              <Form.Item
                className="roboto-regular not-required-attachment"
                style={{ width: '100%' }}
                label={characterString.bottomImageLabel[language]}
              >
                {getFieldDecorator('bottomImage', {
                  rules: [{ required: false }],
                })(
                  <Row type="flex" justify="center" style={{ width: '100%' }}>
                    <input
                      id="bottomImage"
                      type="file"
                      accept={`${typePNG}, ${typeWEBP}, ${typeJPEG}, ${typeGIF}`}
                      onChange={this.handleFile}
                      ref={this.uploadBottomImageRef}
                      style={{ visibility: 'hidden' }}
                    />
                    <div className="upload" style={{ width: '100%' }}>
                      {this.state.bottomImage || character.optional_image?.image ? (
                        this.selectFile('bottomImage')
                      ) : (
                        <img
                          id="bottomImage"
                          onClick={this.handleRefClick}
                          src={UploadImageIcon}
                          className="upload-sticker-image"
                        />
                      )}
                    </div>
                  </Row>
                )}
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                label={characterString.characterStoryTitle[language]}
                style={{ marginBottom: '0px' }}
              >
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: characterString.characterStoryTitleValidation[language],
                    },
                  ],
                })(<Input name="title" onBlur={this.handleChange} className="input-treasure" />)}
              </Form.Item>

              <Form.Item>
                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  className="full-width testCharacterStory"
                >
                  <Col>
                    <Typography.Text>{characterString.showIn[language]}</Typography.Text>
                  </Col>
                  <Col>
                    <Checkbox
                      id="titleShowsInList"
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                      onChange={this.handleCheckboxs}
                      checked={this.props.character.titleShowsInList}
                    />
                    <Typography.Text>{characterString.list[language]}</Typography.Text>
                  </Col>
                  <Col>
                    <Checkbox
                      id="titleShowsInDetails"
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                      onChange={this.handleCheckboxs}
                      checked={this.props.character.titleShowsInDetails}
                    />
                    <Typography.Text>{characterString.details[language]}</Typography.Text>
                  </Col>
                </Row>
              </Form.Item>
            </Row>

            <Row>
              <Form.Item
                label={characterString.characterStorySubtitle[language]}
                style={{ marginBottom: '0px' }}
              >
                {getFieldDecorator('sub_title', {
                  rules: [
                    {
                      required: true,
                      message: characterString.characterStorySubtitleValidation[language],
                    },
                  ],
                })(
                  <Input name="sub_title" onBlur={this.handleChange} className="input-treasure" />
                )}
              </Form.Item>

              <Form.Item>
                <Row
                  type="flex"
                  justify="start"
                  align="middle"
                  className="full-width testCharacterStory"
                >
                  <Col>
                    <Typography.Text>Show in:</Typography.Text>
                  </Col>
                  <Col>
                    <Checkbox
                      id="subtitleShowsInList"
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                      onChange={this.handleCheckboxs}
                      checked={this.props.character.subtitleShowsInList}
                    />
                    <Typography.Text>List</Typography.Text>
                  </Col>
                  <Col>
                    <Checkbox
                      id="subtitleShowsInDetails"
                      style={{ marginLeft: '10px', marginRight: '5px' }}
                      onChange={this.handleCheckboxs}
                      checked={this.props.character.subtitleShowsInDetails}
                    />
                    <Typography.Text>Details</Typography.Text>
                  </Col>
                </Row>
              </Form.Item>
            </Row>

            <Row>
              <Form.Item label={characterString.characterStoryMainDescription[language]}>
                {getFieldDecorator('description', {
                  rules: [
                    {
                      required: true,
                      message: characterString.characterStoryMainDescriptionValidation[language],
                    },
                  ],
                })(
                  <Input.TextArea
                    name="description"
                    onBlur={this.handleChange}
                    style={{ height: '280px' }}
                  />
                )}
              </Form.Item>
            </Row>

            <Row>
              <Form.Item label={characterString.characterStoryAuxillaryDescription[language]}>
                {getFieldDecorator('auxilliary_description', {
                  rules: [{ required: false }],
                })(
                  <Input.TextArea
                    name="auxilliary_description"
                    onBlur={this.handleChange}
                    style={{ height: '280px' }}
                  />
                )}
              </Form.Item>
            </Row>
          </Form>

          <Row type="flex" justify="center" style={{ paddingBottom: '25px' }}>
            <Button className="transparent-btn-black" onClick={this.handleClose}>
              {characterString.cancelButton[language]}
            </Button>
            {this.props.isEditing ? (
              <Button className="buttons purple-gradient-right" onClick={this.handleSubmit}>
                {characterString.saveButton[language]}
              </Button>
            ) : (
              <Button className="buttons purple-gradient-right" onClick={this.handleSubmit}>
                {characterString.createButton[language]}
              </Button>
            )}
          </Row>

          <ModalLoadingProgressText
            loading={this.state.loading}
            done={this.state.done}
            percentage={this.state.percentage}
            text={characterString.uploadingFilesPleaseWait[language]}
          />
        </div>
      </Row>
    );
  }
}

const wrappedCharacterCreationForm = Form.create({ name: 'character' })(CharacterForm);
export default connect(mapState, mapDispatch)(wrappedCharacterCreationForm);
