import React, { useState } from 'react';
import { Row, Col, Typography, Button, Form, Modal, Divider } from 'antd';
import { connect } from 'react-redux';
import * as menuActions from '../../../actions/menuActions';
import LocationPricingDataList from './LocationPricingDataList';
import * as treasureReview from '../../../lib/strings/treasureReview';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { languages } from '../../../lib/interfaces/language';
import lcash from '../../../assets/lcash.svg';
import { sticker, roleAdmin, candy, point, LDiscount, rolePartner } from '../../../lib/const';
import * as treasureActions from '../../../actions/treasureActions';
import { SelectorLDiscount } from '../../../components';
import * as appConstants from '../../../lib/strings/appConstants';
import { recharge } from '../../../lib/SubMenuConstants';

const { Text } = Typography;

const mapStateToProps = (state: {
  menuUIChange: any;
  treasure: any;
  locations: any;
  authentication: any;
  userData?: any;
  isPaymentInitiated: boolean;
  address: any;
  Additionalprize:any;
  }) => {
  const { menuUIChange, treasure, locations, authentication, userData, address,Additionalprize, } = state;
  const { mapInstance } = address;
    const { language } = authentication;
  const {
    treasureName,
    totalNumberOfUnits,
    treasureType,
    duration,
    durationUnit,
    totalPrice,
    pointsPerUnit,   
    ID,
  } = treasure;
  const { role } = userData;
  const {MorePoints,MorePoints_total,TotalLcash,additional_prize,
  place_1st,
  place_2nd,
  place_3rd,
  place_4th,
  place_5th,
  prize_1st,
  prize_2nd,
  prize_3rd,
  prize_4th,
  prize_5th
  } = Additionalprize;
  const { goBackToTreasureCreation } = menuUIChange;
  
  return {
    goBackToTreasureCreation,
    treasureName,
    totalNumberOfUnits,
    locations,
    treasureType,
    duration,
    language,
    durationUnit,
    totalPrice,
    role,
    pointsPerUnit,
    ID,
    state,
    mapInstance,
    treasure,
    MorePoints,
    MorePoints_total,
    TotalLcash,
    additional_prize,
    place_1st,
    place_2nd,
    place_3rd,
    place_4th,
    place_5th,
    prize_1st,
    prize_2nd,
    prize_3rd,
    prize_4th,
    prize_5th
  };
};

const actionCreators = {
  backToTreasureCreation: menuActions.goBackToTreasureCreation,
  finishTreasureCreation: menuActions.finishReview,
  handleInput: treasureActions.handleInput,
  saveAsDraft: menuActions.saveAsDraft,
  openRecharge: menuActions.openSubmenu1,
};

interface TreasureReviewProps {
  treasureName?: string;
  totalNumberOfUnits?: number;
  locations?: any;
  treasureType?: number;
  duration?: number;
  language: languages;
  durationUnit: string;
  totalPrice: number;
  role?: number;
  pointsPerUnit?: number;
  ID: number;
  state: any;
  mapInstance: any;
    MorePoints:any,
    MorePoints_total:any,
    TotalLcash:any,
    additional_prize:any;
    place_1st:any,
    place_2nd:any,
    place_3rd:any,
    place_4th:any,
    place_5th:any,
    prize_1st:any,
    prize_2nd:any,
    prize_3rd:any,  
    prize_4th:any,
    prize_5th:any
  backToTreasureCreation?: () => void;
  finishTreasureCreation?: (role: number,
    MorePoints:any,
    MorePoints_total:any,
    TotalLcash:any,
    additional_prize:any,
    place_1st:any,
    place_2nd:any,
    place_3rd:any,
    place_4th:any,
    place_5th:any,
    prize_1st:any,
    prize_2nd:any,
    prize_3rd:any,  
    prize_4th:any,
    prize_5th:any,
    LDiscountId?: number | null, callback?: any) => void;
  handleInput: any;
  treasure: any;
  saveAsDraft: any;
  openRecharge: any;
}

const TreasureReview = (props: TreasureReviewProps) => {
  const {
    language,
    treasure,
    role,
    MorePoints,
    MorePoints_total,
        TotalLcash,
        additional_prize,
        place_1st,
        place_2nd,
        place_3rd,
        place_4th,
        place_5th,
        prize_1st,
        prize_2nd,
        prize_3rd,
        prize_4th,
        prize_5th,
      locations,
      handleInput,
    backToTreasureCreation,
    finishTreasureCreation,
    saveAsDraft,
    openRecharge,
  } = props;

  const [selectedLDiscount, setSelectedLDiscount] = useState({
    treasureName: '',
    ID: 0,
    discountValue: 0,
  });
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(treasure.totalPrice);
  const [insufficientFundsModal, setInsufficientFundsModal] = useState(false);

  const calculateDurationInHours = (startTime, endTime) => {
    return (endTime - startTime) / 1000 / 3600;
  };

  const calculateDurationUnit = (exposureDuration) => {
    let duration;
    let durationUnit;
    if (exposureDuration <= 24) {
      duration = exposureDuration.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption3[language]
          : appConstants.TreasureReviewDurationHours[language];
    } else if (exposureDuration > 24 && exposureDuration <= 24 * 7) {
      const hoursToDays = exposureDuration / 24;
      duration = hoursToDays.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption2[language]
          : appConstants.TreasureReviewDurationDays[language];
    } else if (exposureDuration > 24 * 7) {
      const hoursToWeeks = exposureDuration / (24 * 7);
      duration = hoursToWeeks.toFixed();
      durationUnit =
        duration === '1'
          ? appConstants.TreasureCreationDurationOption1[language]
          : appConstants.TreasureReviewDurationWeeks[language];
    }
    return { duration, durationUnit };
  };

  let treasureTypeText;
  switch (treasure.treasureType) {
    case sticker:
      treasureTypeText = treasureReview.treasureTypeSticker[language];
      break;
    case point:
      treasureTypeText = treasureReview.treasureTypePoint[language];
      break;
    case candy:
      treasureTypeText = treasureReview.treasureTypeCandy[language];
      break;
    case LDiscount:
      treasureTypeText = treasureReview.treasureTypeLDiscount[language];
      break;
    default:
      break;
  }

  const onBackHandler = () => {
    handleInput('onBack', true);
    backToTreasureCreation();
  };

  const onLDiscountSelectHandler = (idValue) => {
    const selectedValue = treasure.availableDiscounts.find((object) => object.ID === idValue);
    let price = treasure.totalPrice;
    if (selectedValue) {
      price = treasure.totalPrice - selectedValue.discountValue;
      if (price < 0) {
        price = 0;
        setShowWarningModal(true);
      }
    }
    setTotalPrice(price);
    setSelectedLDiscount(selectedValue);
  };

  const onFinishHandler = async () => {

    if (role < roleAdmin) {
     
      finishTreasureCreation(role,
        MorePoints,
        MorePoints_total,
        TotalLcash,
        additional_prize,
        place_1st,
        place_2nd,
        place_3rd,
        place_4th,
        place_5th,
        prize_1st,
        prize_2nd,
        prize_3rd,
        prize_4th,
        prize_5th
        );
      } 
      else {
      finishTreasureCreation(role,
        MorePoints,
        MorePoints_total,
        TotalLcash,
        additional_prize, undefined,
        place_1st,
        place_2nd,
        place_3rd,
        place_4th,
        place_5th,
        prize_1st,
        prize_2nd,
        prize_3rd,
        prize_4th,
        prize_5th,
      () => {
        setInsufficientFundsModal(true);
      });
    }
    const timer = setTimeout(() => {
      window.location.reload();
    }, 4000);
       
  };


  const duration = calculateDurationUnit(
    calculateDurationInHours(treasure.startDate, treasure.endDate)
  );

  const saveAsDraftHandler = () => {
    setInsufficientFundsModal(false);
    saveAsDraft();
  };

  const rechargeHandler = () => {
    setInsufficientFundsModal(false);
    openRecharge(recharge);
  };

  return (
    // Treasure informations: tresureType, treasureName, treasureDuration
    <Row className="review-treasure">
      <Col className="review-treasure-child">
        <Row type="flex" align="top" justify="center" className="pricing-container">
          <Col>
            <Row>
              <Col className="center">
                <Text className="treasure-review-treasure-type-text">{treasureTypeText}</Text>
              </Col>
            </Row>
            <Row>
              <Col className="center ">
                <Text className="treasure-review-treasure-name-text">{treasure.treasureName}</Text>
              </Col>
            </Row>
            {Number(treasure.treasureType) !== candy && (
              <Row>
                <Col className="center">
                  <Text className="treasure-review-treasure-duration">
                    {duration.duration} {duration.durationUnit}
                  </Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {/* List of locations */}
        <LocationPricingDataList />

        {/* Use your LDiscount for master ONLY! */}
        {/* {treasure.treasureType > sticker && role < roleAdmin && (
          <Row>
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="treasure-review-row-container"
            >
              <Col>
                <Text className="treasure-review-section-text">
                  {treasureReview.useYourLDiscount[language]}{' '}
                  <Text type="secondary" style={{ fontStyle: 'italic' }}>
                    {treasureReview.useYourLDiscountOptional[language]}
                  </Text>
                </Text>
              </Col>
            </Row>

            <Row>
              <Col>
                <SelectorLDiscount
                  language={language}
                  size="large"
                  placeholder={treasureReview.selectPlaceholder[language]}
                  list={treasure.availableDiscounts}
                  onChange={onLDiscountSelectHandler}
                />
              </Col>
            </Row>
          </Row>
        )} */}

        {/* Price, LDiscount, Total */}
        {Number(treasure.treasureType) > sticker && role < roleAdmin ? (
          <Row>
            <Col>
              {/* Price */}
              {/* <Row type="flex" justify="space-between" className="treasure-review-row-container">
                <Text className="treasure-review-section-text">
                  {treasureReview.TreasureReviewPrice[language]}
                </Text>
                <Text className="treasure-review-section-text">
                  {treasure.totalPrice.toFixed(2)} {treasureReview.lCash[language]}
                </Text>
              </Row> */}
              {selectedLDiscount && (
                <>
                  {/* LDiscount */}
                  {/* <Row
                    type="flex"
                    justify="space-between"
                    className="treasure-review-row-container"
                     >
                    <Text className="treasure-review-section-text">
                      {treasureReview.LDiscountValue[language]}
                    </Text>
                    <Text className="treasure-review-section-text">
                      {selectedLDiscount.discountValue.toFixed(2)} {treasureReview.lCash[language]}
                    </Text>
                  </Row> */}

                  <div style={{ marginLeft: '1em', marginRight: '1em' }}>
                    <Divider style={{ marginBottom: '0px', marginTop: '0px' }} />
                  </div>

                  {/* Total */}
                  {/* <Row
                    type="flex"
                    justify="space-between"
                    className="treasure-review-row-container"
                     >
                    <Text className="treasure-review-section-text">
                      {treasureReview.totalPrice[language]}
                    </Text>
                    <Text className="treasure-review-section-text">
                      {totalPrice.toFixed(2)} {treasureReview.lCash[language]}
                    </Text>
                  </Row> */}
                </>
              )}
            </Col>
          </Row>
        ) : null}
        <Row className="review-buttons">
          <Col>
            <Row type="flex" justify="space-between">
              <Col>
                <Button className="purple-gradient-right buttons white-txt" onClick={onBackHandler}>
                  {treasureReview.TreasureReviewButton1[language]}
                </Button>
              </Col>
              <Col>
                <Button className="yellow-gradient white-txt buttons" onClick={onFinishHandler}>
                   {role<roleAdmin &&treasure.isEditing==true?treasureReview.TreasureReviewButton3[language]:
                  role < roleAdmin
                    ? treasureReview.TreasureReviewButton2[language]
                    : treasureReview.TreasureReviewPublish[language]}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Modal
        visible={showWarningModal}
        footer={null}
        centered
        onCancel={() => setShowWarningModal(false)}
      >
        <Typography.Text>
          {treasureReview.LDiscountErrorMessage[language]}{' '}
          {(selectedLDiscount?.discountValue - treasure.totalPrice).toFixed(2)}{' '}
          {treasureReview.lCash[language]}.
        </Typography.Text>
      </Modal>

      <Modal
        visible={insufficientFundsModal}
        footer={null}
        centered
        onCancel={() => setInsufficientFundsModal(false)}
      >
        <Row type="flex" justify="center">
          <Typography.Text>{treasureReview.insufficientLcash[language]}</Typography.Text>
        </Row>

        <Row type="flex" justify="space-around" style={{ paddingTop: '16px' }}>
          <Col>
            <Button className="buttons" onClick={saveAsDraftHandler}>
              {treasureCreation.TreasureCreationSaveAsDraftButton[language]}
            </Button>
          </Col>
          <Col>
            <Button className="yellow-gradient buttons" onClick={rechargeHandler}>
              {treasureReview.rechargeLcash[language]}
            </Button>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

export default connect(mapStateToProps, actionCreators)(TreasureReview);
