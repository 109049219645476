import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Input, Row, Col, Select, Button, notification } from 'antd';
import moment from 'moment';
import * as treasureService from '../../../services/treasureService';
import * as treasureActions from '../../../actions/treasureActions';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import StartEndDatePicker from '../../../components/treasure/StartEndDatePicker';
import LocationForm from './LocationForm';
import location_icon from '../../../assets/location_add_new_location_icon.svg';
import ReviewButtons from '../../../components/treasure/ReviewButtons';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { isDev } from '../../../lib/config';

import { fillCandy } from '../../../qucikFill/candyFill';
import { useTranslation } from 'react-i18next';

function CandyCreation(props) {
  const {
    language,
    treasure,
    handleInput,
    onHashtagChange,
    form,
    onStartDateChange,
    onEndDateChange,
    locations,
    onAnotherLocationClick,
    role,
    onCancel,
    onReview,
    treasureErrors,
    resetTreasureErrors,
  } = props;
  const { getFieldDecorator, setFieldsValue, validateFields } = form;
  const { inputParameters } = treasureActions;
  const {t}=useTranslation()

  useEffect(() => {
    console.log('pp',props)
    const checkIfError = () => {
      if (!isEmpty(treasureErrors)) {
        const errorKeys = Object.keys(treasureErrors);
        let setValues = {};
        errorKeys.forEach((errorKey) => {
          setValues[errorKey] = null;
        });
        // tested only on treasureUnit, and treasureUpdate
        setFieldsValue(setValues);

        resetTreasureErrors();
      }
    };

    checkIfError();
  }, [treasureErrors]);

  const handleCandyName = async (event) => {
    handleInput(inputParameters.treasureName, event.target.value);
    treasureService.treasureUpdate(treasure.ID, inputParameters.treasureName, event.target.value);
  };

  const onReviewHandler = () => {
    validateFields((errors, values) => {
      if (!errors) {
        onReview();
      } else {
        notification.error({
          message:t("Please enter all required fields"),
        });
      }
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="create-treasure-padding">
        <Form>
          {/* Candy treasure name */}
          <Form.Item
            className="roboto-regular"
            label={treasureCreation.TreasureCreationNameLabel[language]}
          >
            {getFieldDecorator(inputParameters.treasureName, {
              rules: [
                {
                  required: true,
                  message: treasureCreation.TreasureCreationNameError[language],
                },
              ],
              initialValue: treasure.treasureName || '',
            })(
              <Input
                className="input-treasure full-width"
                onBlur={handleCandyName}
                placeholder={treasureCreation.TreasureCreationNamePlaceholder[language]}
                name="candyName"
              />
            )}
          </Form.Item>

          {/* Candy hashtag */}
          <Form.Item
            className="roboto-regular"
            label={treasureCreation.TreasureCreationHashtagLabel[language]}
          >
            <Row>
              <Col>
                {getFieldDecorator(inputParameters.hashtag, {
                  rules: [
                    {
                      required: true,
                      message: treasureCreation.TreasureCreationHashtagError[language],
                    },
                  ],
                  initialValue: treasure.hashtags.map((hashtagObject) => hashtagObject.name) || [],
                })(
                  <Select
                    placeholder="#"
                    className="hashtag"
                    mode="tags"
                    tokenSeparators={[',']}
                    onBlur={onHashtagChange}
                    maxTagCount={10}
                  ></Select>
                )}
              </Col>
            </Row>
          </Form.Item>

          <StartEndDatePicker
            language={language}
            initialStartDateValue={
              treasure.isEditing || treasure.onBack
                ? treasure.startDate
                : parseInt(moment().format('x'))
            }
            initialEndDateValue={treasure.endDate}
            onChangeStartDate={onStartDateChange}
            onChangeEndDate={onEndDateChange}
            form={form}
            formStartDateId={inputParameters.startDate}
            formEndDateId={inputParameters.endDate}
            
          />

          {locations.map((location: any, index: number) => {
            return (
              <LocationForm
                key={index}
                locationID_UI={index}
                form={form}
                location={(treasure.isEditing || treasure.onBack) && location}
              />
            );
          })}
          
          <Row type="flex" justify="center" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
            <Button
              style={{ width: '100%' }}
              className="buttons purple-gradient-right menu-button-width"
              onClick={onAnotherLocationClick}
            >
              <img src={location_icon} alt="location" />
              {treasureCreation.TreasureCreationAddLocation[language]}
            </Button>
          </Row>
        </Form>
      </div>
      <ReviewButtons
        treasure={treasure}
        role={role}
        onCancel={onCancel}
        onReview={onReviewHandler}
      />

      {/* quickFill for testing */}
      {isDev && !treasure.isEditing && (
        <div className="quick-fill-container">
          <Button
            onClick={() => {
              fillCandy(handleCandyName, onHashtagChange, form.setFieldsValue, onEndDateChange);
            }}
          >
            AUTOFILL Candy
          </Button>
        </div>
      )}
    </div>
  );
}

CandyCreation.propTypes = {
  language: PropTypes.string,
  treasure: PropTypes.object,
  onHashtagChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  onAnotherLocationClick: PropTypes.func,
  onCancel: PropTypes.func,
  onReview: PropTypes.func,
};

CandyCreation.defaultProps = {
  language: 'EN',
  onHashtagChange: () => {},
  onStartDateChange: () => {},
  onEndDateChange: () => {},
  onAnotherLocationClick: () => {},
  onCancel: () => {},
  onReview: () => {},
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  treasure: state.treasure,
  locations: state.locations,
  treasureErrors: state.treasureErrors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleInput: treasureActions.handleInput,
      resetTreasureErrors: () => dispatch(treasureActions.resetTreasureErrors()),
    },
    dispatch
  );

const wrappedTreasureCreationForm = Form.create({ name: 'candyTreasure' })(CandyCreation);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedTreasureCreationForm);
