import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';

import { Row, Col } from 'antd';
import { BackButtonLeftText, PendingDoneSelector, UserList } from '../../../../../components';

import * as menuActions from '../../../../../actions/menuActions';
import * as subMenuConstants from '../../../../../lib/SubMenuConstants';
import * as cashExchangeActions from '../../../../../actions/cashExchangeActions';
import * as cashExchangeStrings from '../../../../../lib/strings/cashExchangeStrings';
import * as cashExchangeService from '../../../../../services/cashExchangeService';
import { showErrorMessage } from '../../../../../services/sharedServices';
import { serverError } from '../../../../../lib/strings/error';
import PropTypes from 'prop-types';

function CashExchangeRequests(props) {
  const {
    language,
    closeSubmenu1,
    closeSubmenu2,
    openCashExchangeDetails,
    cashExchangeEditParameters,
    cashExchangeReset,
    cashExchange,
  } = props;

  useEffect(() => {
    return () => {
      cashExchangeReset();
    };
  }, []);

  const cashExchangeList: any = useQuery(
    ['CashExchangeList', { paymentStatus: cashExchange.pendingOrFinished }],
    cashExchangeService.getCashExchangeList,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onCloseHandler = () => {
    closeSubmenu2();
    closeSubmenu1();
  };

  const pendingOrDoneHandler = (value) => {
    cashExchangeEditParameters({ selectedUserIndex: null });
    closeSubmenu2();

    cashExchangeEditParameters({ pendingOrFinished: value });
  };

  const onClickHandler = (index) => {
    cashExchangeEditParameters({ selectedUserIndex: index });
    openCashExchangeDetails();
  };

  return (
    <Row className="cashEvent-request-wrapper cashEvent-text">
      <BackButtonLeftText
        label={cashExchangeStrings.cashExchangeRequest[language]}
        onClick={onCloseHandler}
      />
      <PendingDoneSelector
        language={language}
        value={cashExchange.pendingOrFinished}
        onChange={pendingOrDoneHandler}
      />
      <Col className="scrollable">
        <UserList
          type={cashExchange.pendingOrFinished}
          loading={cashExchangeList.isLoading}
          data={cashExchangeList.data}
          onClick={onClickHandler}
          selectedUserIndex={cashExchange.selectedUserIndex}
        />
      </Col>
    </Row>
  );
}

CashExchangeRequests.propTypes = {
  language: PropTypes.string,
};

CashExchangeRequests.defaultProps = {
  language: 'EN',
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  cashExchange: state.cashExchange,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openCashExchangeDetails: () =>
    dispatch(menuActions.openSubmenu2(subMenuConstants.cashExchangeDetails)),

  cashExchangeEditParameters: (payload) => dispatch(cashExchangeActions.editParameters(payload)),
  cashExchangeReset: () => dispatch(cashExchangeActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashExchangeRequests);
