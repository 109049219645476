import { LanguageMap } from '../interfaces/language';

export const cashExchangeRequest: LanguageMap<string> = {
  EN: 'CASH EXCHANGE REQUESTS',
  KO: 'CASH EXCHANGE REQUESTS',
};

export const cashExchangeRequestSmall: LanguageMap<string> = {
  EN: 'Cash Exchange Requests',
  KO: 'Cash Exchange Requests',
};

export const pending: LanguageMap<string> = {
  EN: 'Pending',
  KO: 'Pending',
};

export const done: LanguageMap<string> = {
  EN: 'Done',
  KO: 'Done',
};

export const details: LanguageMap<string> = {
  EN: 'DETAILS',
  KO: 'DETAILS',
};

export const username: LanguageMap<string> = {
  EN: 'Username:',
  KO: 'Username:',
};

export const beneficiaryName: LanguageMap<string> = {
  EN: 'Beneficiary name:',
  KO: 'Beneficiary name:',
};

export const bankName: LanguageMap<string> = {
  EN: 'Bank name:',
  KO: 'Bank name:',
};

export const accountNumber: LanguageMap<string> = {
  EN: 'Account number:',
  KO: 'Account number:',
};

export const abaNumber: LanguageMap<string> = {
  EN: 'ABA number:',
  KO: 'ABA number:',
};

export const amountOfPoints: LanguageMap<string> = {
  EN: 'Amount of points:',
  KO: 'Amount of points:',
};

export const amountOfUsd: LanguageMap<string> = {
  EN: 'Amount of USD:',
  KO: 'Amount of USD:',
};

export const dateOfExchange: LanguageMap<string> = {
  EN: 'Date of exchange:',
  KO: 'Date of exchange:',
};

export const dateOfPayment: LanguageMap<string> = {
  EN: 'Date of payment:',
  KO: 'Date of payment:',
};

export const email: LanguageMap<string> = {
  EN: 'Email:',
  KO: 'Email:',
};

export const markAsPaid: LanguageMap<string> = {
  EN: 'Mark as paid',
  KO: 'Mark as paid',
};

export const sendPointsBackToUser: LanguageMap<string> = {
  EN: 'Send points back to user',
  KO: 'Send points back to user',
};
