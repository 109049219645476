import { Row } from 'antd';
import React from 'react';
import closeIcon from '../../../../../../src/assets/closeXsmall.svg';

type ImagePreviewCustomProps = {
  closeModal: () => void;
  imagePreviewSource: string;
};

const ImagePreviewCustom = (props: ImagePreviewCustomProps) => {
  return (
    <>
      <Row>
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            {/* <h4 className="text-primary mb-0 font-bold"></h4>
            <i className="iconx cursor">
              <img
                onClick={(e) => props.closeModal()}
                src={closeIcon}
                alt="Info"
              />
            </i> */}
          </div>
          <div
            style={{
              // border: '2px solid grey',
              width: '100%',
              height: '100%',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <img
              src={props.imagePreviewSource}
              style={{ width: '80%', height: '80%', margin: 'auto' }}
            />
          </div>
        </div>
      </Row>
    </>
  );
};

export default ImagePreviewCustom;
