import { baseURL, createSupportTicket, getSupportTicket } from '../../../../lib/config';
import { store } from '../../../../helpers/store';
import { responseCodes } from '../../../../lib/const';
import { notification } from 'antd';
import {b} from '../../../dashboard/menu/i18next'
  
  
  const showErrorMessage = (title, description?) => {
    notification.error({
      message: `${title}`,
      description: `${description || ''}`,
      duration: 10,
    });
  };

  const showSuccessMessage = (title, description?) => {
    notification.success({
      message: `${title}`,
      description: `${description || ''}`,
      duration: 10,
    });
  };
  
  
  
  export const getSupportCases = async () => {
    try {
      const fetchResponse =  await fetch(baseURL+ getSupportTicket, {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
        },
      });
      const response =  await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"));
      return error;
    }
  };

  export const getSupportMessage = async (support_id: bigint) => {
    try {
      const fetchResponse =  await fetch(baseURL+ getSupportTicket + '/'+ support_id, {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
        },
      });
      const response =  await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"))
      return error;
    }
  };
  

  export const sendNewMessage = async (support_id: bigint, data: any) => {
    try {
      const fetchResponse =  await fetch(baseURL+ getSupportTicket + '/'+ support_id, {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data),
      });
      const response =  await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t('success'),b?.t('Message send successfully.'));
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to send message');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"));
      return error;
    }
  };

  export const updateRating = async (support_id: bigint, data: any) => {
    try {
      const fetchResponse =  await fetch(baseURL+ getSupportTicket + '/'+ support_id, {
        method: 'PUT',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type' : 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response =  await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t('success'),b?.t('Rating updated successfully.'));
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to send message');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"))
      return error;
    }
  };


  export const resolveIssue = async (support_id: bigint) => {
    try {
      const fetchResponse =  await fetch(baseURL+ getSupportTicket + '/'+ support_id, {
        method: 'PUT',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type' : 'application/json'
        }
      });
      const response =  await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t('success'),b?.t('Issue status updated successfully.'));
        return response.data;
      } else {
        showErrorMessage(response.error, response.code);
        return Promise.reject('failed to send message');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"));
      return error;
    }
  };
  
  export const CreateSupport = async (data) => {
    try {
      const fetchResponse =  await fetch(baseURL + createSupportTicket, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(data),
      });
      const response =  await fetchResponse.json();
      
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t('Success'),b.t("Image uploaded successfully."));
        return response.data;
      } else {
         showErrorMessage(b?.t("Required Field Missing"), response.code);
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage(b?.t("Try after sometimes"));
      return error;
    }
  };
  
  
  