import { LanguageMap } from '../interfaces/language';

export const km: LanguageMap<string> = {
  EN: 'km',
  KO: 'km',
};

export const m: LanguageMap<string> = {
  EN: 'm',
  KO: 'm(미터)',
};

export const ok: LanguageMap<string> = {
  EN: 'OK',
  KO: 'OK',
};

export const ModalLocationRadius: LanguageMap<string> = {
  EN: 'Enter location radius:',
  KO: '지역 범위를 입력해주세요',
};
