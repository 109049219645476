import cafe1 from './cafe1.JPG';
import cafe2 from './cafe2.JPG';
import cafe3 from './cafe3.JPG';
import cafe4 from './cafe4.JPG';
import cafe5 from './cafe5.JPG';
import cafe6 from './cafe6.JPG';
import cafe7 from './cafe7.JPG';
import cafe8 from './cafe8.JPG';
import cafe9 from './cafe9.JPG';
import cafe10 from './cafe10.JPG';

import cafe1_1 from './cafe1.1.JPG';
import cafe2_1 from './cafe2.1.JPG';
import cafe3_1 from './cafe3.1.JPG';
import cafe4_1 from './cafe4.1.JPG';
import cafe5_1 from './cafe5.1.JPG';
import cafe6_1 from './cafe6.1.JPG';
import cafe7_1 from './cafe7.1.JPG';
import cafe8_1 from './cafe8.1.JPG';
import cafe9_1 from './cafe9.1.JPG';
import cafe10_1 from './cafe10.1.JPG';

export const cafeportrait = [
  { id: 1, src: cafe1 },
  { id: 2, src: cafe2 },
  { id: 3, src: cafe3 },
  { id: 4, src: cafe4 },
  { id: 5, src: cafe5 },
  { id: 6, src: cafe6 },
  { id: 7, src: cafe7 },
  { id: 8, src: cafe8 },
  { id: 9, src: cafe9 },
  { id: 10, src: cafe10 },
];

export const cafelandscape = [
  { id: 1, src: cafe1_1 },
  { id: 2, src: cafe2_1 },
  { id: 3, src: cafe3_1 },
  { id: 4, src: cafe4_1 },
  { id: 5, src: cafe5_1 },
  { id: 6, src: cafe6_1 },
  { id: 7, src: cafe7_1 },
  { id: 8, src: cafe8_1 },
  { id: 9, src: cafe9_1 },
  { id: 10, src: cafe10_1 },
];
