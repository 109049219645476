import React, { Component, useContext } from 'react';
import { Row, Col, Button, Typography, Select } from 'antd';
import imgPlaceholder from '../../../assets/profile_picture.svg';
import Logout from '../../../assets/home_logout_icon.svg';
import Dashboard from '../../../assets/icon_dashboard.svg';
import * as userActions from '../../../actions/signinActions';
import { connect } from 'react-redux';
import * as userService from '../../../services/signinService';
import { baseURL } from '../../../lib/config';
import AppContext from '../../../AppContext';
import I18next from './i18next';


import {
  roleMaster,
  rolePartner,
  roleAdmin,
  roleSuperadmin,
} from '../../../lib/const';
import lcashIcon from '../../../assets/lcash.svg';
import {
  admin,
  superadmin,
  ultrasuperadmin,
  master,
  masterPartner,
} from '../../../lib/strings/appConstants';
import { languages } from '../../../lib/interfaces/language';
import emojiFlags from 'emoji-flags';
import Flag, { Props } from 'react-flagkit';
import { menuConstants } from '../../../constants/menuConstants';
import { store } from '../../../helpers/store';
import { userData } from '../../../reducers/userReducer';
import { b } from './i18next'


const { Text } = Typography;

const actionCreators = (dispatch: any) => {
  return {
    onClickLogout: () => dispatch(userActions.signout(),
    )
  };
};
const mapStateToProps = (state: {
  authentication: { user: any; language: string, };
  userData: any;
  menuUIChange: any;
}) => {
  const { authentication, userData, menuUIChange } = state;
  const { user, language, } = authentication;
  const { role, lcash, username, books, coupons, phoneNumber, userPermissions,vouchers} = userData;
  const permissions = userPermissions ? userPermissions.split(",") : [];

  const { country } = userData;
  return {
    user,
    userData,
    menuUIChange,
    role,
    lcash,
    language,
    country,
    username,
    books,
    coupons,
    phoneNumber,
    vouchers,
    permissions: permissions,
   
  };
};

interface ProfileInfoProps {
  onClickLogout: () => void;
  user?: any;
  userData: any;
  menuUIChange: any;
  role: number;
  lcash?: number;
  language: languages;
  country: any;
  username: any;
  books: any;
  coupons: any;
  permissions: any;
  phoneNumber: string;
  vouchers:any
}


interface ProfileInfoState {
  img: any;
  username: string;
  phoneNumber: string;
  lcash: number;
  lcash1: number;
  books: any,
  coupons: any,
  permissions: any,
  vouchers:any

}

export class ProfileInfo extends Component<ProfileInfoProps, ProfileInfoState> {
  static contextType = AppContext;
  constructor(props: Readonly<ProfileInfoProps>) {
    super(props);
    this.state = {
      img: imgPlaceholder,
      username: '',
      phoneNumber: '',
      lcash: 0,
      books: 0,
      coupons: 0,
      lcash1: 0,
      permissions: '',
      vouchers:0

    };


  }

  fetchUserData = async () => {
    const res = await userService.getUserData(this.props.user.user._id);
    store.dispatch({ type: 'USER_BOOKS', books: res.plum_rec });
    store.dispatch({ type: "USER_ROLE", Role: res.role })
    store.dispatch({ type: "COUPONS", coupons: res.coupons })
    store.dispatch({ type: "PERMISSIONS", permissions: res.userPermissions })
    store.dispatch({ type: "VOUCHERS",vouchers :res.vouchers,})
    this.setState({books: res.plum_rec,});
    this.setState({ coupons: res.coupons });
   this.setState({ vouchers:res.vouchers})
    this.setState({ lcash1: res.lcash });
    this.setState({ permissions: res.userPermissions })

    return res;
  };

  componentDidMount = async () => {
    if (this.props.userData.avatar && this.props.userData.avatar.picture) {
      this.setState({
        img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
      });
    }

    if (this.props.userData.lcash) {
      this.setState({
        lcash: this.props.userData.lcash,
      });
    }
    this.fetchUserData()


  };


  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  showVoucher = async () => {
    let context = await this.getContext()
    context.voucher = true;
    context['current_menu_name']=false
    context.lbox = false;
    
    await this.updateContext(context);
  }

  thousandSeparator = (x: Props) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }

  thousandSeparators = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }
  componentDidUpdate = (prevProps: any) => {
    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData.avatar && this.props.userData.avatar.picture) {
        this.setState({
          img: `${baseURL}/uploads/${this.props.userData.avatar.picture.nameOnServer}`,
        });
      }
    }
    if (prevProps.lcash !== this.props.lcash) {
      if (this.props.lcash) {
        this.setState({
          lcash: this.props.lcash,
        });
      }
    }

  };


  switchRole = () => {
    const { country, role, language, phoneNumber } = this.props;
    const countryFlag = this.props.country ? (
      <Flag
        country={this.props.country.toUpperCase()}
        style={{ marginBottom: 2 }}
      />
    ) : null;
    switch (this.props.role) {
      case roleMaster:
        return (
          <>

            <div className='couponBlock'>
              <div className='fx-aic'>
                <div>
                  <button className="couponBadge cursor" onClick={this.showVoucher}>
                    <div className='couponBadge__loop couponItem'>
                      <span>{b?.t('COUPON')}</span>
                      <span className="couponBadge__counter"> {this.state.coupons}</span>
                    </div>
                    <div className='couponBadge__loop voucherItem'>
                      <span>{b?.t('VOUCHER')}</span>
                      <span className="couponBadge__counter"> {this.state.vouchers}</span>
                    </div>
                  </button>
                  <p className='mb-0 domeAdrs'>{b?.t('Dome Address')}</p>
                </div>

                <div className="infoTip" style={{marginTop:'-10px'}}>
                  <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                  <div className="infoTip__tooltip md-size" style={{ top: "28px" }}>
                    <p className="size12 mb-0 font-regular">{b?.t('Go to the')}</p>
                  </div>
                </div>
              </div>


              
              <span>{this.state.coupons > 0 && (
                <span>Expired: {this.getLastDayOfMonth()}</span>
              )}
              </span>
            </div>
            <Row className="center large-text-no-padding ">
              <Text className="roboto-regular">
                {b?.t('master')}
                {/* {master[this.props.language]}{' '} */}
              </Text>
              {countryFlag}
            </Row>

            <Row className="col-24 fx-jcsb large-text-no-padding ">
              <Text className="roboto-regular center" style={{ width: '25%', fontSize: '14px' }}>
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.thousandSeparators(this.state.lcash1)}
                {/* {this.state.lcash} */}
              </Text>
              <div className="roboto-regular fx" style={{ width: '75%', fontSize: '14px' }}>
                <div className='text-center'>{b?.t('Received1')}</div>
                <div className="infoTip">
                  <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                  <div className="infoTip__tooltip sm-size">
                    <p className="size12 mb-0 font-regular">
                      {b?.t('The received plums')}

                    </p>
                  </div>
                </div>
                <div className='fx-aic ml-5'>
                  <span className='blue-dot mr-5'></span>

                  {this.state.books ? this.thousandSeparator(this.state.books ? this.state.books : '') : 0}

                </div>
              </div>


            </Row>
          </>
        );

      case rolePartner:
        return (
          <>

            <Row className="center large-text-no-padding">

              <Text className="roboto-regular">
                {masterPartner[this.props.language]}{' '}
              </Text>
              {countryFlag}
            </Row>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                <img style={{ marginRight: '.2em' }} src={lcashIcon} />
                {this.state.lcash}
              </Text>
            </Row>
          </>
        );

      case roleAdmin:
        return (
          <Row className="center large-text-no-padding">
            <Text className="roboto-regular">
              {admin[this.props.language]}{' '}
            </Text>
            {countryFlag}
          </Row>
        );

      case roleSuperadmin:
        return (
          <>
            <Row className="center large-text-no-padding">
              <Text className="roboto-regular">
                {phoneNumber === '+821077032867'
                  ? ultrasuperadmin[this.props.language]
                  : phoneNumber === '+12139355403'
                    ? ultrasuperadmin[this.props.language]
                    : superadmin[this.props.language]}
                {/* {phoneNumber === '+821077032867' ? ultrasuperadmin[this.props.language]: superadmin[this.props.language]}{' '} */}
                {/* {phoneNumber === '+12139355403' ? ultrasuperadmin[this.props.language] : superadmin[this.props.language]}{' '} */}
              </Text>
              {countryFlag}
            </Row>

            <div className="roboto-regular fx-jcc" style={{ width: '100%', fontSize: '14px' }}>
              <div className='text-center'>Received Plum Balance</div>
              <div className="infoTip">
                <span className="infoTip__icon" style={{ height: '22px' }}>&nbsp;</span>
                <div className="infoTip__tooltip sm-size">
                  <p className="size12 mb-0 font-regular">
                          The received plums from the users’donation can be cashed on the Account> Plums> Cashing
                  </p>
                </div>
              </div>
              <div className='fx-aic ml-5'>
                <span className='blue-dot mr-5'></span>

                {/* {this.state.books ? this.thousandSeparator(this.state.books ? this.state.books : '') : 0} */}
              </div>
            </div>

          </>
        );
      default:
        break;
    }
  };
  getLastDayOfMonth() {
    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const currentMonth = currentDate.toLocaleString('default', { month: 'short' }).toUpperCase(); // Use 'short' to get abbreviated month name and then convert to uppercase
    return `${lastDayOfMonth}.${currentMonth},${currentDate.getFullYear()}`;
  }

  render() {
    return (
      <>
        <div className="profile-info-safari-fix">
          <Row type="flex" justify="center" className="profile-info-wrapper">
            <div className='langSelect'>
              <I18next />
            </div>
            <Col className='ant-col-24'>
              <Row type="flex" justify="center" align="middle">
                <img
                  alt="profile photo"
                  src={this.state.img}
                  className="profile-photo"
                />
              </Row>
              <Row>
                <Col className="center large-text-no-padding">
                  <Text className="fredoka">{this.props?.username}</Text>
                </Col>
              </Row>
              {this.switchRole()}{' '}
            </Col>
            <Button className="button-logout" onClick={this.props.onClickLogout}>
              {b?.t('Logout')}
              <img src={Logout} className="logout-icon" />
            </Button>
            <Button
              onClick={() => window.location.reload()}
              className="button-dashboard"
            >
              {b?.t('Dashboard')}

              <img src={Dashboard} className="dashboard-icon" />
            </Button>


          </Row>
        </div>
      </>
    );
  }
}
export default connect(mapStateToProps, actionCreators)(ProfileInfo);
