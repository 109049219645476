import React from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
// import iconInfo from '../../../../src/assets/icon_info_blue.svg';
import iconInfo from '../../../../../src/assets/icon_info_blue.svg';
// import cardCVV from '../../../../src/assets/card-cvv.svg';
import cardCVV from '../../../../../src/assets/card-cvv.svg';
// import allCards from '../../../../src/assets/all-cards.png';
import allCards from '../../../../../src/assets/all-cards.png';
import { store } from '../../../../helpers/store';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import {b} from '../i18next'

import { loadStripe } from '@stripe/stripe-js';
import * as Config from '../../../../lib/config';
import AppContext from '../../../../AppContext';
// import { store } from '../../../helpers/store';
// import { baseURL, domepayment, cartCheckout } from '../../../lib/config'
// import { cartPayment } from '../../../services/domePaymentService';
import { CreateCard, getCardList } from './account';
import { Row, Col, notification } from 'antd';
import { OmitProps } from 'antd/lib/transfer/renderListBody';
import { pay } from '../../../../lib/strings/statistics';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

const successMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

// import AppContext from '../../../AppContext';

export const logEvent = (name) => (event) => {
  console.log(`[${name}]`, event);
};

export const Result = ({ children }) => (
  <div className="result">{children}</div>
);

export const ErrorResult = ({ children }) => (
  <div className="error">{children}</div>
);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#252525',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#999',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const stripePromise = loadStripe(Config.stripe.apikey);
class AddPaymentMethods extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      card_holder_name: '',
    };
  }

  // handleInputEmail = (e: any) => {
  //     this.setState({
  //         email: e.target.value
  //     })
  // }

  inputHandler = (e: any) => {
    this.setState({
      card_holder_name: e.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let profile = await this.getContext();
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      showErrorMessage(b?.t('Error'), b?.t('Stripe element not loaded.'));
      return false;
    }
    const userData = store.getState().userData;
    if (!this.state.email) {
      this.setState({
        email: userData.email,
      });
    }

    if (!stripe || !elements) {
      showErrorMessage(b?.t('Error'), b?.t('Stripe element not loaded.'));
      return false;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const payload = await stripe.createToken(cardElement);
    if (payload.error) {
        showErrorMessage(b?.t("Your card number is incomplete"));
       return false;
    }
    // console.log('[Token]', payload.token.card);
    // Call API
    let data = {
      card_token: payload.token.id,
      card: payload.token.card.last4,
      card_type: payload.token.card.brand,
      expires: payload.token.card.exp_month + '/' + payload.token.card.exp_year,
      address: payload.token.card.address_line1,
      city: payload.token.card.address_city,
      is_default: profile?.cardList?.length ? 0 : 1,
    };
    // save card
    await CreateCard(data);
    // Get saved card and update context
    const cardList = await getCardList();

    profile.cardList = cardList;
    await this.updateContext(profile);

    console.log(payload.token.id);
    console.log('[Token]', payload.token.card);
    this.setState({
      token: payload.token.id,
      errorMessage: null,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  render() {
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog ">
        <div className="third-card pay-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              {' '}
              &#62; {b?.t('Add Payment Method')}
            </h4>
            <i onClick={this.props.close} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>
          <div>
            <h3 className="font-bold">{b?.t('Card Detail')}</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>{b?.t('Card information')}</label>
                <div className="card-information">
                  <div className="card-information__top">
                    <CardNumberElement
                      id="cardNumber"
                      onChange={logEvent('change')}
                      onReady={logEvent('number')}
                      options={ELEMENT_OPTIONS}
                    />
                    <div className="card-information__top__icons">
                      <img src={allCards} alt="Info" />
                    </div>
                  </div>
                  <div className="card-information__bot">
                    <div className="card-information__bot__left">
                      <CardExpiryElement
                        id="expiry"
                        onChange={logEvent('change')}
                        onReady={logEvent('ready')}
                        options={ELEMENT_OPTIONS}
                      />
                    </div>
                    <div className="card-information__bot__right">
                      <CardCvcElement
                        id="cvc"
                        onChange={logEvent('change')}
                        onReady={logEvent('ready')}
                        options={ELEMENT_OPTIONS}
                      />
                      <img src={cardCVV} alt="Info" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{b?.t('Name on card')}</label>
                <input
                  onChange={this.inputHandler}
                  type="text"
                  value={this.state.card_holder_name}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>{b?.t('Country or region')}</label>
                <select className="form-control">
                  <option value="Republic of Korea"> {b?.t('Republic of Korea')}</option>
                </select>
              </div>

              <button
                type="submit"
                className="ant-btn buttons btn-block purple-gradient rd-5"
              >
                {b?.t('Save card')}{' '}
              </button>
            </form>
          </div>
        </div>
      </Row>
    );
  }
}


export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <AddPaymentMethods
          stripe={stripe}
          elements={elements}
          close={props.close}
        />
      )}
    </ElementsConsumer>
  );
}
