import React from 'react';
import { Row, Col, Spin } from 'antd';
import UserListItemPartnerRequests from './UserListItemPartnerRequests';
import moment from 'moment';
import PropTypes from 'prop-types';

function UserListPartnerRequests(props) {
  const { type, loading, data, selectedUserIndex, onClick } = props;

  if (loading) {
    return (
      <Row type="flex" justify="center" className="partnerRequests-user-list">
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <Row type="flex" justify="center" className="partnerRequests-user-list">
      <Col span={24}>
        {data.map((user, index) => {
          return (
            <UserListItemPartnerRequests
              onClick={onClick}
              key={user.ID}
              index={index}
              username={user.username}
              date={moment(user.requestDate).format('DD/MM/YYYY')}
              selected={selectedUserIndex === index}
              profilePicUrl={user.avatar?.thumbnail?.nameOnServer}
            />
          );
        })}
      </Col>
    </Row>
  );
}

UserListPartnerRequests.propTypes = {
  type: PropTypes.oneOf(['pending', 'done']),
  loading: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  selectedUserIndex: PropTypes.number,
};

UserListPartnerRequests.defaultProps = {
  loading: true,
  data: [],
};

export default UserListPartnerRequests;
