import React from 'react';
import { Modal, Button, Row, Col, Typography } from 'antd';
import * as contestStrings from '../../../lib/strings/contest';
import PropTypes from 'prop-types';

const { Text } = Typography;

function LanguageChangeModal(props) {
  const { visible, onCancel, onConfirm, language } = props;

  const onConfirmHandler = () => {
    onCancel();
    onConfirm();
  };

  return (
    <Modal visible={visible} footer={null} closable={false} centered width={270}>
      <Row type="flex" justify="center" className="full-width">
        <Row type="flex" justify="center" style={{ paddingBottom: '22px' }} className="full-width">
          <Text>{contestStrings.bannerPopup[language]}</Text>
        </Row>
        <Row type="flex" justify="space-between" className="full-width">
          <Col style={{ marginRight: '4px' }}>
            <Button
              className="buttons transparent-btn-black"
              style={{ width: '105px', height: '24px' }}
              onClick={onConfirmHandler}
            >
              {contestStrings.confirm[language]}
            </Button>
          </Col>
          <Col style={{ marginLeft: '4px' }}>
            <Button
              className="buttons yellow-gradient"
              style={{ width: '105px', height: '24px' }}
              onClick={onCancel}
            >
              {contestStrings.cancel[language]}
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
}

LanguageChangeModal.propTypes = {
  visible: PropTypes.bool,
  language: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

LanguageChangeModal.defaultProps = {
  visible: true,
  language: 'EN',
  onCancel: () => {},
  onConfirm: () => {},
};

export default LanguageChangeModal;
