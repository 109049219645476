import React, { useState,useEffect} from 'react';
import { Col, Row, Radio } from 'antd';
import * as candyEvent from '../../lib/strings/candyEvent';
import PropTypes from 'prop-types';

export default function OptionalFreeCoupon(props) {
  const { EnterCoupon ,couponNumber} = props;
  const [selectedCoupon, setSelectedCoupon] = useState(0);
  // console.log(couponNumber,"Ahsan")

  useEffect(()=>{
    //  console.log("Ahsan NN",data?.sponsorBanner)
    setSelectedCoupon(parseInt(couponNumber))
    

    // setBanner(coupon?.sponsorUrl)
   },[couponNumber])
 // setBanner(coupon?.sponsorUrl)
  const onCouponClick = (value) => {
    if (selectedCoupon === value) {
      setSelectedCoupon(null); // Deselect the coupon on double-click
      EnterCoupon(0); // Send null value to indicate deselection
    } else {
      setSelectedCoupon(value); // Select the clicked coupon
      EnterCoupon(value); // Send the selected coupon value to the function
    }
  };

  return (
    <Row className='border-top pt-10 mb-40'>
      <p>Optional free coupon for the Dome Address</p>
      <Row gutter={16}>
        <Radio.Group value={selectedCoupon} buttonStyle="solid">
          <Col className="gutter-row" span={5}>
            <Radio.Button value={1}  onClick={() => onCouponClick(1)}>1</Radio.Button>
          </Col>
          <Col className="gutter-row" span={5}>
            <Radio.Button value={2}  onClick={() => onCouponClick(2)}>2</Radio.Button>
          </Col>
          <Col className="gutter-row" span={5}>
            <Radio.Button value={3} onClick={() => onCouponClick(3)}>3</Radio.Button>
          </Col>
        </Radio.Group>
      </Row>
    </Row>
  );
}

OptionalFreeCoupon.propTypes = {
  EnterCoupon: PropTypes.func,
  coupon:PropTypes.object,
  couponNumber:PropTypes.string

};
