import { store } from '../helpers/store';
import { baseURL, treasureAPI, kitAPI, del, put, exchangeRateAPI } from '../lib/config';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const createKit = async (kitData: any, countryCode: string,t_type:any) => {

  const statusName = (t_type) => {
    switch (t_type) {
      case 'adskit':
       return '0';
     case 'flash':
      return '1';
      case 'green':
      return '2';
    default:
       return '1';  
    }
  };

  try {
    const fetchResponse =   await fetch(baseURL + treasureAPI + kitAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: kitData.name,
        mediaType: kitData.mediaType,
        adDuration: kitData.adDuration,
        role: kitData.role,
        t_type:statusName(t_type),
        pricePerUnit: JSON.stringify([kitData[countryCode], kitData.default]),
      }),
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to create kit');
    }
  } catch (error) {
    return error;
  }
};

export const getKits = async () => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}${kitAPI}/all`, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.kitTypes;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject();
    }
  } catch (error) {
    return error;
  }
};

export const deleteKit = async (kitID: number) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}${kitAPI}/${kitID}`, {
      method: del,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to delete kit');
    }
  } catch (error) {
    return error;
  }
};

export const editKit = async (kitData: any, countryCode: string,t_type:any) => {
  console.log(t_type,"t_type")
  const statusName = (t_type) => {
    switch (t_type) {
      case 'adskit':
       return '0';
     case 'flash':
      return '1';
      case 'green':
      return '2';
    default:
       return '1';  
    }
  };
  try {
    const fetchResponse =   await fetch(`${baseURL}${treasureAPI}${kitAPI}/${kitData.ID}`, {
      method: put,
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: kitData.name,
        mediaType: kitData.mediaType,
        adDuration: kitData.adDuration,
        role: kitData.role,
        t_type:statusName(t_type),
        pricePerUnit: JSON.stringify([kitData[countryCode], kitData.default]),
      }),
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to update kit');
    }
  } catch (error) {
    return error;
  }
};

export const exchange = async (country: string, coinValueInPoints: number) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${exchangeRateAPI}`, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coinValueInPoints: coinValueInPoints,
        country: country,
      }),
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('Failed to update exchange rate');
    }
  } catch (error) {
    return error;
  }
};

export const exchangeRate = async () => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${exchangeRateAPI}?isWeb=true`, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject();
    }
  } catch (error) {
    return error;
  }
};
