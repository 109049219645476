import academy1 from './academy1.JPG';
import academy2 from './academy2.JPG';
import academy3 from './academy3.JPG';
import academy4 from './academy4.JPG';
import academy5 from './academy5.JPG';
import academy6 from './academy6.JPG';
import academy7 from './academy7.JPG';
import academy8 from './academy8.JPG';
import academy9 from './academy9.JPG';
import academy10 from './academy10.JPG';

import academy1_1 from './academy1.1.JPG';
import academy2_1 from './academy2.1.JPG';
import academy3_1 from './academy3.1.JPG';
import academy4_1 from './academy4.1.JPG';
import academy5_1 from './academy5.1.JPG';
import academy6_1 from './academy6.1.JPG';
import academy7_1 from './academy7.1.JPG';
import academy8_1 from './academy8.1.JPG';
import academy9_1 from './academy9.1.JPG';
import academy10_1 from './academy10.1.JPG';

export const academyportrait = [
  { id: 1, src: academy1 },
  { id: 2, src: academy2 },
  { id: 3, src: academy3 },
  { id: 4, src: academy4 },
  { id: 5, src: academy5 },
  { id: 6, src: academy6 },
  { id: 7, src: academy7 },
  { id: 8, src: academy8 },
  { id: 9, src: academy9 },
  { id: 10, src: academy10 },
];

export const academylandscape = [
  { id: 1, src: academy1_1 },
  { id: 2, src: academy2_1 },
  { id: 3, src: academy3_1 },
  { id: 4, src: academy4_1 },
  { id: 5, src: academy5_1 },
  { id: 6, src: academy6_1 },
  { id: 7, src: academy7_1 },
  { id: 8, src: academy8_1 },
  { id: 9, src: academy9_1 },
  { id: 10, src: academy10_1 },
];
