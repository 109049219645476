import React, { memo, useState } from 'react';
import { Row, Typography, Col, Popover } from 'antd';
import Carousel from './Carousel';
import EllipseIcon from '../../../assets/ellipse12.svg';
import { SketchPicker } from 'react-color';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import PropTypes from 'prop-types';

const backgroudColors = [
  'yellow',
  'orangeRed',
  'blue',
  'red',
  'steelBlue',
  'brown',
  'deepPink',
  'darkSeaGreen',
  'darkMagenta',
  'chartreuse',
  'blueViolet',
  'aqua',
  'gray',
];

function BackgroundColors(props) {
  const { onChange, language } = props;
  const [pickerColor, setPickerColor] = useState();
  const [backgroudColorsItems, setBackgroudColorsItems] = useState(
    backgroudColors.map((color, index) => (
      <div onClick={() => carouselHandler(index)} key={index} className="admaker-template">
        <div
          style={{
            backgroundColor: color,
            border: '1px solid #979797',
            borderRadius: '8px',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    ))
  );

  const carouselHandler = (item) => {
    onChange(backgroudColors[item]);
  };

  const pickerHandler = (colors) => {
    const rgba = colors.rgb;
    setPickerColor(rgba);
    onChange(`rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`);
  };

  return (
    <>
      <Row>
        <Typography.Text
          style={{ marginLeft: '1em', marginBottom: '1em', color: 'rgba(0, 0, 0, 0.65)' }}
        >
          {treasureCreation.backgroundColors[language]}
        </Typography.Text>
      </Row>
      <Carousel items={backgroudColorsItems} onChange={carouselHandler} />
      <Row
        type="flex"
        justify="end"
        align="middle"
        style={{ marginLeft: '1em', marginRight: '14%' }}
      >
        <Col>
          <Popover
            placement="bottom"
            trigger="click"
            overlayStyle={{ width: 0, height: 0 }}
            align={{ offset: [-80, -10] }}
            content={<SketchPicker onChange={pickerHandler} color={pickerColor} />}
          >
            <img style={{ cursor: 'pointer' }} src={EllipseIcon} />
          </Popover>
          <Typography.Text style={{ marginLeft: '10px' }}>
            {treasureCreation.pickYourColor[language]}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
}

BackgroundColors.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
};

BackgroundColors.defaultProps = {
  language: 'EN',
  onChange: () => {},
};

export default memo(BackgroundColors);
