import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import trash from '../../../assets/location_delete_icon.svg';
import { connect } from 'react-redux';
import * as treasureService from '../../../services/treasureService';
import * as treasureActions from '../../../actions/treasureActions';
import * as treasureReview from '../../../lib/strings/treasureReview';
import { languages } from '../../../lib/interfaces/language';
import * as appConstants from '../../../lib/strings/appConstants';
import { roleAdmin, sticker, point, candy, LDiscount, flash } from '../../../lib/const';
import lcash from '../../../assets/lcash.svg';
import { locationConstants } from '../../../constants/locationConstants';
import { createLocationV2 } from '../../../services/treasureService';

const { Text } = Typography;

const mapState = (state: { locations: any; authentication: any; treasure: any; userData: any }) => {
  const { locations, authentication, treasure, userData } = state;
  const { role } = userData;
  const { treasureType, ID } = treasure;
  const { language } = authentication;
  return { locations, language, treasureType, role, ID };
};

// const actionCreators = {
//   deleteLocation: treasureActions.deleteLocation,
// };

const mapDispatchToProps = (dispatch) => ({
  deleteLocation: (index) => dispatch({ type: locationConstants.DELETE_LOCATION2, index }),
});

interface Props {
  lat?: number;
  lng?: number;
  numberOfUnits?: string;
  messagingLocations?: number;
  locations: any;
  deleteLocation?: (index: number) => void;
  numberOfLocation: number;
  language: languages;
  treasureType: number;
  locationPrice: number;
  role?: number;
  ID: number;
}

const LocationPricingData = (props: Props) => {
  const [location, setlocation] = useState('');

  const reverseGeocoding = async () => {
    const reverse = await treasureService.reverseGeocodeAdressComponents(props.lat, props.lng);
    if (typeof reverse === 'object') setlocation(reverse[2].long_name);
  };

  useEffect(() => {
    reverseGeocoding();
  }, []);

  const { language } = props;

  const onDeleteLocationHandler = () => {
    props.deleteLocation(props.numberOfLocation);
    const updateApiData = [
      ...props.locations.slice(0, props.numberOfLocation),
      ...props.locations.slice(props.numberOfLocation + 1),
    ];
    createLocationV2(updateApiData, props.ID);
  };

  let unitType = props.treasureType;
  let unitTypeWord;
  switch (unitType) {
    case sticker:
      if (Number(props.numberOfUnits) > 1) {
        unitTypeWord = appConstants.TreasureCreationTypeValueMul[language];
      } else {
        unitTypeWord = appConstants.TreasureCreationTypeValue[language];
      }
      break;
    case point:
      if (Number(props.numberOfUnits) > 1) {
        unitTypeWord = appConstants.TreasureCreationUnits[language];
      } else {
        unitTypeWord = appConstants.TreasureCreationUnits[language];
      }
      break;
      case flash:
        if (Number(props.numberOfUnits) > 1) {
          unitTypeWord = appConstants.TreasureCreationUnits[language];
        } else {
          unitTypeWord = appConstants.TreasureCreationUnits[language];
        }
        break;
    case candy:
      if (Number(props.numberOfUnits) > 1) {
        unitTypeWord = appConstants.TreasureCreationUnits[language];
      } else {
        unitTypeWord = appConstants.TreasureCreationUnits[language];
      }
      break;
    case LDiscount:
      if (Number(props.numberOfUnits) > 1) {
        unitTypeWord = appConstants.TreasureCreationLDiscounts[language];
      } else {
        unitTypeWord = appConstants.TreasureCreationLDiscount[language];
      }
      break;
    default:
      break;
  }

  return (
    <Row className="pricing-container-location">
      <Col>
        {/* Location No and City name */}
        <Row type="flex" justify="space-between" align="middle">
          <Col span={2}>
            <Text className="treasure-review-treasure-type-text treasure-review-opacity">
              #{props.numberOfLocation + 1}
            </Text>
          </Col>
          <Col span={20}>
            <Text className="treasure-review-section-text treasure-review-bold">
              {' ' + location}
            </Text>
          </Col>
          {props.locations.length > 1 ? (
            <Col span={2}>
              <img
                onClick={onDeleteLocationHandler}
                className="pricing-title"
                src={trash}
                alt="delete"
              />
            </Col>
          ) : (
            <Col span={2} />
          )}
        </Row>
        {/* Number of units and price */}
        <Row type="flex" justify="space-between" align="middle" style={{ marginTop: '-6px' }}>
          <Col offset={2}>
            {/* Messaging location */}
            {/* {props.treasureType === point ?
                            <Text>{props.messagingLocations} {props.messagingLocations > 1 ? treasureReview.LocationPricingDataMessagingPoints[language] : treasureReview.LocationPricingDataMessagingPoint[language]} </Text>
                            : null} */}
            <Text className="treasure-review-treasure-duration-text treasure-review-bold">
              {props.numberOfUnits !== undefined ? Number(props.numberOfUnits) : 0}{' '}
              {' ' + unitTypeWord}
            </Text>
          </Col>
          {props.treasureType > sticker && props.role < roleAdmin ? (
            <Col className="pricing-text">
              <Text className="treasure-review-treasure-duration-text treasure-review-bold">
                {props.locationPrice.toFixed(2)} {treasureReview.lCash[language]}{' '}
              </Text>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default connect(mapState, mapDispatchToProps)(LocationPricingData);
