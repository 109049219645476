import React from 'react';
import { Row, Spin, Col, Checkbox, notification,Radio} from 'antd';
import moment from 'moment';
import AppContext from '../../../AppContext';
import closeIcon from '../../../assets/closeXsmall.svg';
import logoPic from '../../../assets/logoPic.png';
import { connect } from 'react-redux';
import * as statisticsService from '../../../../src/services/statisticsService';
import { store } from '../../../helpers/store';

import downAroBlink from '../../../assets/downAroBlink.svg';

import {
  baseURL,
  domepayment,
  cartCheckout,
  createDome,
  stripe,
} from '../../../lib/config';

interface DomRegisterProps {
  paymentHandler?: () => void;
  translation: any;
  coupons: any;
  vouchers: any;
}

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
}
class CouponandVoucher extends React.Component<any, any, DomRegisterProps> {

  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      coupons: 0,
      vouchers: 0,
      apiData: null,
      filteredData:null,
      isChecked: false,
      value:0

    };
  }
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  componentDidMount = async () => {
    const response = await statisticsService.getStatisticsForvoucherget(0, this.props.userId)
    const filteredData =response.List.filter(item =>item.used=='0')
    this.setState({
      apiData: response.List,
      filteredData:filteredData
    });
  };

  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };


  handleCheckboxChange = () => {
    if (this.props.coupons == '0') {
      const newCheckedState = this.state.isChecked;
    }
    else {
      const newCheckedState = !this.state.isChecked;
      this.setState({ isChecked: newCheckedState });
    }
  };


  paymentHandlerCoupon = async (price: any) => {
    if (this.state.isChecked == true) {
      let resisterRes = {};
      let profile = this.context.profile;
      const context = this.context;
      const requestOptionForRegister = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
          iscoupon: '1',
          voucher_id: this.state.value
        }),
      }
      resisterRes = await fetch(baseURL + createDome, requestOptionForRegister).then((r) => r.json());

      if (resisterRes?.data?.paid) {
        profile.iscart = null;
        profile.items = null;
        profile.is_payment = false;
        profile.year = null;
        profile.register = false;
        profile['fetch_in_boundry'] = true;
        // remove all current dome
        let markers = profile['markers'] || [];
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        profile['markers'] = [];
        profile['is_dome_registered_successfully'] = true;
        context.setProfile(profile);
        showSuccessMessage('Dome created successfully');
        const timer = setTimeout(() => {
          window.location.reload();
        }, 3000);

      }
    }
    else if (this.state.value > 0) {
      let resisterRes = {};
      let profile = this.context.profile;
      const context = this.context;
      const requestOptionForRegister = {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addr_name: profile.dome_name,
          longitude: profile.dome_lat,
          latitude: profile.dome_lng,
          isvoucher: 1,
          voucher_id: this.state.value
        }),
      }
      resisterRes = await fetch(baseURL + createDome, requestOptionForRegister).then((r) => r.json());

      if (resisterRes?.data?.paid) {
        profile.iscart = null;
        profile.items = null;
        profile.is_payment = false;
        profile.year = null;
        profile.register = false;
        profile['fetch_in_boundry'] = true;
        // remove all current dome
        let markers = profile['markers'] || [];
        for (let i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
        profile['markers'] = [];
        profile['is_dome_registered_successfully'] = true;
        context.setProfile(profile);
        showSuccessMessage('Dome Address created successfully');

        const timer = setTimeout(() => {
          window.location.reload();
        }, 3000);

      }
    }

  }

  render() {
    const { translation, coupons, vouchers } = this.props
    const { apiData,filteredData } = this.state;
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog borderStyle">
          <div className="third-card relative">
            <div className="noTitleBox">
              <i onClick={this.props.closeCouponVoucher} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>

            <div className="domeAddContainer scrollSection" style={{ marginBottom: '100px', maxHeight: 'calc(100vh - 330px)' }}>
              <Row>
                <Col>
                  <div className='fx-jcsb-aic'>
                    <div className='couponTag mb-10'>{translation('COUPON')} {coupons}</div>
                    <div className='downArBlink'>
                      <img src={downAroBlink} alt="aro" />
                      <span>Choose one</span>
                    </div>
                  </div>
                  <h4 className='text-center text-muted'><i>{translation('No valid Coupon')}</i></h4>
                  {/* <h3 className='text-center text-primary'>There is no available coupon.</h3> */}
                  <div>
                    {/* <div className='vcloop'>
                      <Row type="flex" justify="space-between">
                        <Col span={20}>
                          <div className='vcloop__first'>1. Dome Address 1-Year registration coupon</div>
                          <div className='vcloop__second fx-jcfb'>
                            <span>ID v1233211233</span>
                            <span>Expiration date: Jul 23, 2024</span>
                          </div>
                        </Col>
                        <Col span={4} className='fx-jcc-aic'>
                          <input
                            type="checkbox"
                            checked={this.state.isChecked}
                            onChange={this.handleCheckboxChange}
                          />
                        </Col>
                      </Row>
                    </div> */}
                  </div>
                </Col>
              </Row>

              <Row className='mb-20'>
                <Col>
                  <div className='couponTag'>{translation('VOUCHER')} {vouchers}</div>
                </Col>
              </Row>
              <div>
                <div className='vcloop'>
                  {
                    filteredData?.map((item: any, index: any) => { 
                        return (
                          <Row type="flex" justify="space-between">
                            <Col span={20}>
                              <div className='vcloop__first'>{index + 1}. {translation('Dome Address 5-Year')}</div>
                              <div className='vcloop__second fx-jcfb'>
                                <span>{translation('ID')} {item.voucherID}</span>
                                <span>{translation('Expiration date')}:{moment(parseInt(item.expiration, 10)).format('DD/MM/YY')}</span>
                              </div>
                              <br />
                            </Col>
                            <Col span={4} className='fx-jcc-aic square-radio'>
                              <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={item.ID}></Radio>
                              </Radio.Group>
                            </Col>
                          </Row>

                        ) 
                    })
                  }
                </div>
              </div>
            </div>

            <div className='bottom-modal'>
              <p>{translation('Would you like to use')}?</p>
              <div className='text-center'>
                <button onClick={this.props.paymentHandler_no} className='ant-btn white-btn xs mr-15' >{translation('No thanks')}</button>
                <button onClick={this.paymentHandlerCoupon} className='ant-btn yellow-gradient xs  ml-15'>{translation('Yes')}</button>
              </div>
            </div>

            <div className='bottom-modal d-none'>
              <button className='closeIcn'></button>
              <p>Please, select the coupon or voucher you would like to use first.</p>
            </div>

          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state: any) => (
  {
    translation: state.userData.translation,
    coupons: state.userData.coupons,
    vouchers: state.userData.vouchers,
    userId: state.userData.mysqlID
  }
);

export default connect(mapStateToProps, null)(CouponandVoucher);