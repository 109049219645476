import React, {useState, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history, persistor } from './helpers/store';
import SignIn from './containers/signin/SignIn';
import Dashboard from './containers/dashboard/Dashboard';
import PrivacyPolicy from './containers/signin/legal/PrivacyPolicy';
import TermsOfUse from './containers/signin/legal/TermsOfUse';
import { PersistGate } from 'redux-persist/integration/react';

import AppContext from './AppContext';
import {defaultVal} from './AppContext';


class Routes extends React.Component{

   setProfile = (profile) => {
    this.setState({ profile });
  };
  
  state = {
    profile: defaultVal.profile,
    setContextData: this.setProfile,
    setProfile: this.setProfile,
  }
 

  render(){
  return (
    <Provider store={store}>
      <AppContext.Provider value = {this.state}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
          </Switch>
        </ConnectedRouter>
      </PersistGate>
      </AppContext.Provider>
    </Provider>
  );
};
}
export default Routes;
