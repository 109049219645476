import React, { useState, useEffect } from 'react';
import CharacterForm from './CharacterForm';
import { Form, Spin, Row, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import * as characterString from '../../../../lib/strings/character';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { characterConstants } from '../../../../constants/characterConstants';
import { getStoryLocalization } from '../../../../services/characterService';
import { parseStoryLocalizationAll, isEmpty } from './characterHelperFunctions';
import {
  updateLocalizationData,
  batchEdit,
  resetStoryLocalization,
} from '../../../../actions/characterActions';

function CharacterFormLoading(props) {
  const {
    language,
    isEditing,
    handleCloseSubmenu,
    purgeCharacterStoryData,
    id,
    updateLocalizationData,
    batchUpdateCharacter,
  } = props;

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    resetStoryLocalization();
    const fetchData = async () => {
      const storyLocalization =  await getStoryLocalization(id);

      if (!isEmpty(storyLocalization)) {
        const parsedLocalizationData: any = parseStoryLocalizationAll(storyLocalization);
        updateLocalizationData(parsedLocalizationData);

        if (parsedLocalizationData.en) {
          batchUpdateCharacter({ ...parsedLocalizationData.en, selectedLanguage: 'EN' });
        } else {
          const languageList = Object.keys(parsedLocalizationData);
          batchUpdateCharacter({
            ...parsedLocalizationData[languageList[0]],
            selectedLanguage: languageList[0].toUpperCase(),
          });
        }
      }
      setIsInitialized(true);
    };
    fetchData();
    return () => resetStoryLocalization();
  }, []);

  const handleClose = async () => {
    handleCloseSubmenu();
    purgeCharacterStoryData();
  };

  if (isEditing && !isInitialized) {
    return (
      <Row className="characterStory-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {characterString.editingCharacterStory[language]}
            </Typography.Text>
          </Row>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        </div>
      </Row>
    );
  }

  return <CharacterForm />;
}

CharacterFormLoading.propTypes = {};

CharacterFormLoading.defaultProps = {};

const mapState = (state: {
  character: any;
  authentication: { language: string; user: any };
  characters: Array<any>;
}) => {
  const { character, authentication } = state;
  const { user } = authentication;
  const { newToken } = user;
  const {
    title,
    sub_title,
    description,
    auxilliary_description,
    isEditing,
    isCreating,
    imgUrl,
    characterID,
  } = character;
  return {
    id: character.id,
    language: state.authentication.language,
    isEditing,
    isCreating,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleCloseSubmenu: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    purgeCharacterStoryData: () => dispatch({ type: characterConstants.PURGE_CHARACTER_DATA }),
    updateLocalizationData: (payload) => dispatch(updateLocalizationData(payload)),
    batchUpdateCharacter: (payload) => dispatch(batchEdit(payload)),
  };
};

const wrappedCharacterCreationForm = Form.create({ name: 'character' })(CharacterFormLoading);
export default connect(mapState, mapDispatch)(wrappedCharacterCreationForm);
