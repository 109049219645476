export const kitConstants = {
  UPDATE_KIT: 'UPDATE_KIT',
  UPDATE_PRICE: 'UPDATE_PRICE',
  UPDATE_POINTS: 'UPDATE_POINTS',
  UPDATE_LCASH: 'UPDATE_LCASH',
  MEDIA_TYPE: 'MEDIA_TYPE',
  POINTS_BY_COUNTRY: 'POINTS_BY_COUNTRY',
  STORE_KITS: 'STORE_KITS',
  KIT_DETAILS: 'KIT_DETAILS',
  EDIT_KIT: 'EDIT_KIT',
  DELETE_KIT: 'DELETE_KIT',
  EXCHANGE_RATE: 'EXCHANGE_RATE',
  UPDATE_POINTS_DEFAULT: 'UPDATE_POINTS_DEFAULT',
  UPDATE_POINTS_COUNTRY: 'UPDATE_POINTS_COUNTRY',
  UPDATE_EDITED: 'UPDATE_EDITED',
  FETCH_KITS: 'FETCH_KITS',
  STORE_RATES: 'STORE_RATES',
  CLEAR_KIT_DATA: 'CLEAR_KIT_DATA',
};
