import React from 'react';
import AppContext from '../../../../AppContext';
import { notification, Row, Spin } from 'antd';
import masterCard from '../../../../../src/assets/masterCard.png';
import visaCard from '../../../../../src/assets/visaCard.png';
import iconDelete from '../../../../../src/assets/icon_delete_o.svg';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import InjectedCheckoutForm from './addPaymentMethods';
import { connect } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as Config from '../../../../lib/config';
import { getCardList } from './account';
import {b} from '../../../dashboard/menu/i18next';
import {
  deletePaymontMethod,
  setPaymentMethodAsDefault,
} from '../../../../services/domeService';

const stripePromise = loadStripe(Config.stripe.apikey);

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class PaymentMethods extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      add: false,
    };
  }

  componentDidMount = async () => {
    const cardList =  await getCardList();
    let profile =  await this.getContext();
    profile['cardList'] = cardList;
     await this.updateContext(profile);
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  closePaymentMethodListing = async () => {
    let profile =  await this.getContext();
    let cardList = profile['cardList'];
    let isAnyDefault = false;
    for (let i = 0; i < cardList.length; i++) {
      if (cardList[i].is_default) {
        isAnyDefault = true;
      }
    }
    if (!isAnyDefault) {
      showErrorMessage('Please Check one as Default');
      return;
    }

    profile['paymentMethos'] = false;
     await this.updateContext(profile);
  };

  addPaymentMethod = async () => {
    this.setState({
      add: true,
    });
  };

  closePaymentMethods = async () => {
    this.setState({
      add: false,
    });
  };

  onDeleteClick = async (id) => {
    try {
       await deletePaymontMethod(id);
      const cardList =  await getCardList();
      let profile =  await this.getContext();
      profile['cardList'] = cardList;
       await this.updateContext(profile);
      showSuccessMessage(b?.t('Deleted Successfully'));
    } catch (error) {}
  };

  onEditClick = async (data) => {
    let profile =  await this.getContext();
    profile['cardList-edit-data'] = data;
     await this.updateContext(profile);
    this.setState({
      add: true,
    });
  };

  onChangeDefault = async (id) => {
     await setPaymentMethodAsDefault(id);
    let profile =  await this.getContext();
    let cardList = profile['cardList'];
    for (let i = 0; i < cardList.length; i++) {
      if (cardList[i].id === id) {
        cardList[i].is_default = 1;
      } else {
        cardList[i].is_default = 0;
      }
    }
    profile['cardList'] = cardList;
     await this.updateContext(profile);
  };

  //deletePaymontMethod
  //setPaymentMethodAsDefault

  render() {
    const { translation } = this.props
    const context = this.context;
    let profile = context.profile || {};
    let cardList = profile.cardList || [];
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62;{translation("Payment Methods")} 
              </h4>
              <div className="fx-aic">
                <i
                  onClick={this.closePaymentMethodListing}
                  className="iconx cursor"
                >
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer scrollSection">
              {cardList.map((card, index) => (
                <div className="payment-card">
                  <div className="payment-card__left">
                    <figure className="mb-3">
                      <img src={visaCard} alt="visa" />
                    </figure>
                    <h4 className="font-bold">
                      {card.card_type} * * * * {card.card}
                    </h4>
                    <h5 className="size12">{translation("Expires")} {card.expires}</h5>
                    <p className="size12">
                      {card.address},<br /> {card.city}
                    </p>
                  </div>
                  <div className="payment-card__left">
                    <div className="fx-jcfe mb-5">
                      {/* <button
                        // onClick={() => this.onEditClick(card)}
                        className="iconx"
                      >
                        <img src={editIcon} alt="edit" height="14" />
                      </button> */}
                      <button
                        onClick={() => this.onDeleteClick(card.id)}
                        className="iconx"
                      >
                        <img src={iconDelete} alt="delete" height="14" />
                      </button>
                    </div>
                    <p className="size12">
                    {translation("Default payment method")}
                      <input
                        style={{ marginLeft: '8px' }}
                        type="checkbox"
                        onChange={() => this.onChangeDefault(card.id)}
                        // onChange={
                        //   card.is_default
                        //     ? () => {}
                        //     : () => this.onChangeDefault(card.id)
                        // }
                        // checked={cardList.length === 1 ? true : false}
                        checked={card.is_default}
                      />
                    </p>
                    {/* <p className="size12">View transaction history</p> */}
                  </div>
                </div>
              ))}

              <div className="center">
                <a
                  onClick={this.addPaymentMethod}
                  href="#"
                  className="text-underline"
                >
                  {translation("Add Payment Method")}
                </a>
              </div>
            </div>
          </div>
        </Row>
        {this.state.add && (
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm close={this.closePaymentMethods} />
          </Elements>
        )}
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(PaymentMethods);
