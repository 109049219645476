import React from 'react';
import { Row, Col, Typography } from 'antd';
import * as privacyPolicy from '../../../lib/strings/privacyPolicy';
import { languages } from '../../../lib/interfaces/language';
import { connect } from 'react-redux';

interface Props {
  language: languages;
}

const mapState = (state: { authentication: any }) => {
  const { authentication } = state;
  const { language } = authentication;
  return { language };
};

const PrivacyPolicy = (props: Props) => {
  const { language } = props;
  return (
    <Row type="flex" justify="center" className="privacy-policy-wrapper">
      <Col className="privacy-policy">
        <Row>
          <Typography.Title className="roboto-regular bold">
            {privacyPolicy.PrivacyPolicyTitle[language]}
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p4[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p5[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p6[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.p7[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p8[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.l1[language]}</li>
            <li>{privacyPolicy.l2[language]}</li>
            <li>{privacyPolicy.l3[language]}</li>
            <li>{privacyPolicy.l4[language]}</li>
            <li>{privacyPolicy.l5[language]}</li>
            <li>{privacyPolicy.l6[language]}</li>
            <li>{privacyPolicy.l7[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.m2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p9[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list2_1[language]}</li>
            <li>{privacyPolicy.list2_2[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p11[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list3_1[language]}</li>
            <li>{privacyPolicy.list3_2[language]}</li>
            <li>{privacyPolicy.list3_3[language]}</li>
            <li>{privacyPolicy.list3_4[language]}</li>
            <li>{privacyPolicy.list3_5[language]}</li>
            <li>{privacyPolicy.list3_6[language]}</li>
            <li>{privacyPolicy.list3_7[language]}</li>
            <li>{privacyPolicy.list3_8[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p12[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list4_1[language]}</li>
            <li>{privacyPolicy.list4_2[language]}</li>
            <li>{privacyPolicy.list4_3[language]}</li>
            <li>{privacyPolicy.list4_4[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point4[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p13[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point5[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p14[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list5_1[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p15[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list5_2[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list5_2_1[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list5_3[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list5_3_1[language]}</li>
            <li>{privacyPolicy.list5_3_2[language]}</li>
            <li>{privacyPolicy.list5_3_3[language]}</li>
            <li>{privacyPolicy.list5_3_4[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list5_4[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list5_4_1[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list5_5[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list5_5_1[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list5_6[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list5_6_1[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p16[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p17[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p18[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p19[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point7[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p20[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p21[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list7_1[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list7_1_1[language]}</li>
            <li>{privacyPolicy.list7_1_2[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list7_2[language]}</li>
          </ul>
          <ul className="list">
            <li>{privacyPolicy.list7_2_1[language]}</li>
          </ul>
          <ul>
            <li>{privacyPolicy.list7_3[language]}</li>
            <li>{privacyPolicy.list7_4[language]}</li>
            <li>{privacyPolicy.list7_5[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point8[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p8_1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p8_2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list8_1[language]}</li>
            <li>{privacyPolicy.list8_2[language]}</li>
            <li>{privacyPolicy.list8_3[language]}</li>
            <li>{privacyPolicy.list8_4[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point9[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p9_1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p9_2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p9_3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point10[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point10_a[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10_1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10_2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10_3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point10_b[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10_4[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p10_5[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{privacyPolicy.list10_1[language]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point10_c[language]}</Typography.Paragraph>
        </Row>
        <Row className="address">
          <Typography.Paragraph>{privacyPolicy.p10_address1[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p10_address2[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p10_address3[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p10_address4[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p10_address5[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point11[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p11_1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.p11_2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.point12[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>
            {privacyPolicy.p12_1[language]}
            <strong>{privacyPolicy.p12_keyword[language]}</strong>
          </Typography.Paragraph>
        </Row>
        <Row className="address">
          <Typography.Paragraph>{privacyPolicy.p12_address1[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p12_address2[language]}</Typography.Paragraph>
          <Typography.Paragraph>{privacyPolicy.p12_address3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.email[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{privacyPolicy.version[language]}</Typography.Paragraph>
        </Row>
      </Col>
    </Row>
  );
};

export default connect(mapState)(PrivacyPolicy);
