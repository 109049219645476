import { kitConstants } from '../constants/kitConstants';
import { menuConstants } from '../constants/menuConstants';

export const initialStateKit = {
  points: 0,
  lCashDefault: 0,
  lCashCountry: 0,
  needsFetch: false,
};

export const kit = (state: any = initialStateKit, action: any) => {
  switch (action.type) {
    case kitConstants.UPDATE_KIT:
      return {
        ...state,
        [action.param]: action.value,
      };
    case kitConstants.UPDATE_PRICE:
      return {
        ...state,
        [action.param]: {
          [action.param]: action.value,
          points: action.points,
        },
        [action.param2]: action.points,
      };
    case kitConstants.UPDATE_LCASH:
      return {
        ...state,
        [action.name]: action.lCash,
      };
    case kitConstants.MEDIA_TYPE:
      return {
        ...state,
        mediaType: action.mediaType,
      };
    case kitConstants.EDIT_KIT:
      return {
        ...state,
        isEditing: true,
        ...action.kit,
      };
    case menuConstants.CLOSE_SUBMENU_1: {
      return initialStateKit;
    }
    case kitConstants.CLEAR_KIT_DATA: {
      return initialStateKit;
    }
    case kitConstants.FETCH_KITS: {
      return {
        ...state,
        needsFetch: action.needsFetch,
      };
    }
    default:
      return state;
  }
};

export const kitList = (state: Array<any> = [], action: any) => {
  switch (action.type) {
    case kitConstants.STORE_KITS:
      return action.kitList;
    case kitConstants.DELETE_KIT: {
      const updateState = state.filter((obj) => {
        return obj.ID !== action.ID;
      });
      return updateState;
    }
    case kitConstants.UPDATE_EDITED: {
      const index = state.findIndex((kit) => kit.ID === action.ID);
      const updateState = [...state];
      updateState[index] = action.kit;
      return updateState;
    }
    default:
      return state;
  }
};

export const kitDetails = (state: any = {}, action: any) => {
  switch (action.type) {
    case kitConstants.KIT_DETAILS:
      return {
        ...state,
        ...action.details,
      };
    default:
      return state;
  }
};

export const exchangeRate = (state: any = {}, action: any) => {
  switch (action.type) {
    case kitConstants.EXCHANGE_RATE:
      return {
        ...state,
        ...action.exchangeRate,
      };
    case kitConstants.UPDATE_POINTS_COUNTRY:
      return {
        ...state,
        pointsByCountry: action.points,
      };
    case kitConstants.UPDATE_POINTS_DEFAULT:
      return {
        ...state,
        pointsDefault: action.points,
      };
    case kitConstants.STORE_RATES:
      const indexCountry = action.rates.findIndex(
        (rate: { country: any }) => rate.country === action.country.toLowerCase()
      );
      let currentValue = 0;
      if (indexCountry !== -1) {
        currentValue = action.rates[indexCountry].coinValueInPoints;
      } else {
        currentValue = 0;
      }

      const indexDefault = action.rates.findIndex(
        (rate: { country: any }) => rate.country === 'default'
      );
      let defaultValue;
      if (indexDefault !== -1) {
        defaultValue = action.rates[indexDefault].coinValueInPoints;
      } else {
        defaultValue = 0;
      }

      return {
        ...state,
        rates: action.rates,
        pointsByCountry: currentValue,
        pointsDefault: defaultValue,
      };
    default:
      return state;
  }
};
