import React from 'react';
import KitCreation from './menu/kit/KitCreation';
import { connect } from 'react-redux';
import KitDetails from './menu/kit/KitDetails';
import ExchangeRate from './menu/kit/ExchangeRate';
import Recharge from './menu/balance/Recharge';
import * as subMenuConstants from '../../lib/SubMenuConstants';
import TreasureInfo from './menu/treasure/TreasureInfo';
import ContestForm from './menu/contest/ContestForm';
import CharacterFormLoading from './menu/characters/CharacterFormLoading';
import ContestInfo from './menu/contest/ContestInfo';
import Contestants from './menu/contest/Contestants';
import ContestantsReview from './menu/contest/ContestantsReview';
import NotificationFormLoading from './menu/notifications/NotificationFormLoading';
import NotificationDetails from './menu/notifications/NotificationDetails';
import CharacterInfo from './menu/characters/CharacterInfo';
import AdMaker from './treasureCreation/AdMaker';
import CandyEventDetails from './menu/candyEvent/CandyEventDetails';
import CashExchangeRequests from './menu/requests/cashExchange/CashExchangeRequests';
import PartnerRequests from './menu/requests/partnerRequests/PartnerRequests';
import EditIdolBanner from './menu/contest/EditIdolBanner';
import UserDetails from './menu/users/UserDetails';

interface Props {
  subMenu1?: string;
}

const mapState = (state: { subMenu1: string }) => {
  const { subMenu1 } = state;
  return { subMenu1 };
};

const SubMenu1 = (props: Props) => {
  const selectOpenMenu = () => {
    switch (props.subMenu1) {
      case subMenuConstants.kitCreation:
        return <KitCreation />;
      case subMenuConstants.kitDetails:
        return <KitDetails />;
      case subMenuConstants.exchangeRate:
        return <ExchangeRate />;
      case subMenuConstants.recharge:
        return <Recharge />; 
      case subMenuConstants.treasureDetails:
        return <TreasureInfo />;
      case subMenuConstants.contestForm:
        return <ContestForm />;
      case subMenuConstants.contestDetails:
        return <ContestInfo />;
      case subMenuConstants.editIdolBanner:
        return <EditIdolBanner />;
      case subMenuConstants.characterForm:
        return <CharacterFormLoading />;
      case subMenuConstants.characterDetails:
        return <CharacterInfo />;
      case subMenuConstants.contestants:
        return <Contestants />;
      case subMenuConstants.contestantsReview:
        return <ContestantsReview />;
      case subMenuConstants.notificationForm:
        return <NotificationFormLoading />;
      case subMenuConstants.notificationDetails:
        return <NotificationDetails />;
      case subMenuConstants.adMaker:
        return <AdMaker />;
      case subMenuConstants.candyEventDetails:
        return <CandyEventDetails />;
      case subMenuConstants.cashExchangeRequest:
        return <CashExchangeRequests />;
      case subMenuConstants.partnerRequests:
        return <PartnerRequests />;
      case subMenuConstants.userDetails:
        return <UserDetails />;
      default:
        break;
    }
  };

  return <>{selectOpenMenu()}</>;
};

export default connect(mapState)(SubMenu1);
