import React, { useState, useRef, useEffect, memo } from 'react';
import { Row, Typography, Col, Button, notification } from 'antd';
import Carousel from './Carousel';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { maximumAllowedMediaSize, typeJPEG, typePNG, typeGIF, typeWEBP } from '../../../lib/config';
import PropTypes from 'prop-types';
import SelectorGray from '../../SelectorGray';
import * as templates from '../../../components/treasure/makeNew/adKitTemplates';

function BackgroundImages(props) {
  const { onChange, language, orientation, initialImage, colorIsSelected } = props;

  const inputRef: any = useRef();

  const [backgroudImagesItems, setBackgroudImagesItems] = useState(
    templates.academylandscape.map((template, index) => {
      return (
        <img
          key={template.id}
          className="admaker-template"
          src={template.src}
          onClick={() => carouselHandler(index)}
        />
      );
    })
  );

  const [selectedCategory, setSelectedCategory] = useState('academy');
  const [selectedItem, setSelectedItem] = useState(0);

  const [customFileUrl, setCustomFileUrl] = useState<ArrayBuffer | String>(
    initialImage ? initialImage : ''
  );

  const disableOnChangeInUseEffect = customFileUrl || colorIsSelected;

  // Default initial image is set here
  useEffect(() => {
    if (!disableOnChangeInUseEffect) {
      onChange(templates[selectedCategory + orientation][selectedItem].src);
    }
  }, []);

  useEffect(() => {
    setBackgroudImagesItems(
      templates[selectedCategory + orientation].map((template, index) => {
        return (
          <img
            key={template.id}
            className="admaker-template"
            src={template.src}
            onClick={() => carouselHandler(index)}
          />
        );
      })
    );
  }, [orientation, selectedCategory]);

  useEffect(() => {
    if (!disableOnChangeInUseEffect) {
      onChange(templates[selectedCategory + orientation][selectedItem].src);
    }
  }, [orientation]);

  const carouselHandler = (item) => {
    onChange(templates[selectedCategory + orientation][item].src);
    setCustomFileUrl('');
    setSelectedItem(item);
  };

  const handleRefClick = (event) => {
    event.persist();
    inputRef.current.click();
  };

  const supportedFileTypes = [typeJPEG, typePNG, typeGIF, typeWEBP];
  const handleFile = (event: any) => {
    event.preventDefault();

    const file = event.target.files[0];
    const type = file.type;
    if (supportedFileTypes.indexOf(type) > -1) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setCustomFileUrl(reader.result);
        onChange(reader.result, file);
      };
    } else {
      notification.error({
        message: 'Wrong file type*',
      });
    }
  };

  const onSelectorChange = (value: string) => {
    const selectedElement = templates.selectorCategories.find((element) => element.name === value);
    if (selectedElement) {
      setSelectedCategory(selectedElement.value);
    }
  };

  return (
    <>
      <Typography.Text
        style={{ marginLeft: '1em', marginBottom: '1em', color: 'rgba(0, 0, 0, 0.65)' }}
      >
        {treasureCreation.backgroundImagesLabel[language]}
      </Typography.Text>
      <SelectorGray
        containerStyle={{ width: '150px', marginTop: '1em' }}
        selectorTextPosition="start"
        dropDownTextPosition="start"
        list={templates.selectorCategories.map((categories) => categories.name)}
        onChange={onSelectorChange}
      />
      <Carousel items={backgroudImagesItems} onChange={carouselHandler} />
      <Row
        type="flex"
        justify="end"
        style={{ marginLeft: '1em', marginRight: '14%', marginBottom: '1em' }}
      >
        <Col>
          <Button onClick={handleRefClick} className="buttons make-new-button" size="small">
            {treasureCreation.upload[language]}
          </Button>
        </Col>
      </Row>
      <input
        type="file"
        onChange={handleFile}
        ref={inputRef}
        style={{ visibility: 'hidden', position: 'absolute' }}
        accept={supportedFileTypes.join(', ')}
      />
    </>
  );
}

BackgroundImages.propTypes = {
  language: PropTypes.string,
  onChange: PropTypes.func,
  orientation: PropTypes.oneOf(['landscape', 'portrait']),
  initialImage: PropTypes.string,
  colorIsSelected: PropTypes.string,
};

BackgroundImages.defaultProps = {
  language: 'EN',
  onChange: () => {},
  orientation: 'landscape',
};

export default memo(BackgroundImages);
