import { characterConstants } from '../constants/characterConstants';

export const updateLocalizationData = (payload) => ({
  type: characterConstants.UPDATE_LOCALIZATION_DATA,
  payload: payload,
});

export const batchEdit = (payload) => ({ type: characterConstants.BATCH_EDIT, payload: payload });

export const resetStoryLocalization = () => ({ type: characterConstants.RESET_STORY_LOCALIZATION });

export const purgeCharacterData = () => ({ type: characterConstants.PURGE_CHARACTER_DATA });
