import { LanguageMap } from '../interfaces/language';

export const creatingCharacterStory: LanguageMap<string> = {
  EN: 'Creating new character story',
  KO: 'Creating new character story',
};

export const editingCharacterStory: LanguageMap<string> = {
  EN: 'Editing character story',
  KO: 'Editing character story',
};

export const saveButton: LanguageMap<string> = {
  EN: 'Save',
  KO: '저장',
};

export const createButton: LanguageMap<string> = {
  EN: 'Create',
  KO: 'Create',
};

export const cancelButton: LanguageMap<string> = {
  EN: 'Cancel',
  KO: '취소',
};

export const characterStoryMainDescription: LanguageMap<String> = {
  EN: 'Main description',
  KO: 'Main description',
};

export const characterStoryAuxillaryDescription: LanguageMap<String> = {
  EN: 'Optional description',
  KO: 'Optional description',
};

export const characterStoryAuxillaryDescriptionValidation: LanguageMap<String> = {
  EN: 'add input',
  KO: 'add input',
};

export const characterStoryMainDescriptionValidation: LanguageMap<String> = {
  EN: 'add input',
  KO: 'add input',
};

export const characterLabel: LanguageMap<string> = {
  EN: 'Character',
  KO: '캐릭터',
};

export const characterValidation: LanguageMap<string> = {
  EN: 'Select character!',
  KO: '캐릭터를 선택해주세요',
};

export const characterStoryTitle: LanguageMap<String> = {
  EN: 'Story title',
  KO: 'Story title',
};

export const characterStorySubtitle: LanguageMap<String> = {
  EN: 'Story subtitle',
  KO: 'Story subtitle',
};

export const characterStorySubtitleValidation: LanguageMap<String> = {
  EN: 'add story subtitle',
  KO: 'add story subtitle',
};

export const characterStoryTitleValidation: LanguageMap<String> = {
  EN: 'add title',
  KO: 'add title',
};

export const imageLabel: LanguageMap<string> = {
  EN: 'Image',
  KO: '이미지',
};

export const bottomImageLabel: LanguageMap<string> = {
  EN: 'Bottom image (Optional)',
  KO: 'Bottom image (Optional)',
};

export const characterStoryImageValidation: LanguageMap<String> = {
  EN: 'add story image',
  KO: 'add story image',
};

export const thumbnailImageLabel: LanguageMap<string> = {
  EN: 'Thumbnail image',
  KO: 'Thumbnail image',
};

export const characterStoryThumbnailValidation: LanguageMap<String> = {
  EN: 'add story thumbnail image',
  KO: 'add story thumbnail image',
};

export const videoLabel: LanguageMap<string> = {
  EN: 'Video',
  KO: 'Video',
};

export const characterStoryVideoValidation: LanguageMap<String> = {
  EN: 'add story video',
  KO: 'add story video',
};

export const updateFailed: LanguageMap<string> = {
  EN: 'update failed',
  KO: 'update failed',
};

export const uploadingFilesPleaseWait: LanguageMap<string> = {
  EN: 'Uploading files, please wait... ',
  KO: 'Uploading files, please wait... ',
};

export const languageList = {
  EN: [
    { value: 'EN', text: 'English' },
    { value: 'KO', text: 'Korean' },
  ],
  KO: [
    { value: 'EN', text: 'English' },
    { value: 'KO', text: 'Korean' },
  ],
};

export const showIn: LanguageMap<string> = {
  EN: 'Show in:',
  KO: 'Show in:',
};

export const list: LanguageMap<string> = {
  EN: 'List',
  KO: 'List',
};

export const details: LanguageMap<string> = {
  EN: 'Details',
  KO: 'Details',
};
