import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import {
  statisticsForCoinsText,
  statisticsForCoinsStrings,
} from '../../../../lib/strings/statistics';
import { statisticsForCoins } from './apiToTabel';
import * as statisticsService from '../../../../services/statisticsService';
import { useQuery } from 'react-query';

function StatisticsForCoins(props) {
  const { language } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForCoins = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForCoins(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['StatisticsForCoins', { ...currentFilters, page: currentPage }],
    fetchStatisticsForCoins,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  useEffect(() => {
    if (statisticsQuery.data) {
      const parsedStatistics = statisticsForCoins(statisticsQuery.data.transactions);

      setTotal({
        totalBalance: statisticsQuery.data.totalCoins,
        purchasedCoins: statisticsQuery.data.purchasedCoins,
        convertedCoins: statisticsQuery.data.coinsFromConversion,
        usedCoins: statisticsQuery.data.usedCoins,
      });
      setStatistics(parsedStatistics);
      setTotalTreasures(statisticsQuery.data.totalTransactions);
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
    setClickedUsername('');
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  let columns = [
    {
      titleStyle: {},
      dataIndex: 'date',
      onClick: () => {},
      dataStyle: {},
    },
    {
      dataIndex: 'username',
      onClick: clickedUsernameHandler,
      dataStyle: { textDecoration: 'underline', cursor: 'pointer' },
    },
    {
      dataIndex: 'country',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'purchasedCoins',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'coinsConvertedFromPoints',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'usedCoins',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'userCoinBalance',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
  ];

  columns.map((column, index) => {
    columns[index]['title'] = statisticsForCoinsStrings[language][column.dataIndex];
  });

  return (
    <Statistics
      label={statisticsForCoinsText[language]}
      columns={columns}
      dataSource={statistics}
      loading={statisticsQuery.isLoading}
      statisticsType={'StatisticsForCoins'}
      clickedUsername={clickedUsername}
      onFiltersChange={onFiltersChangeHandler}
      onPageChange={onPageChangeHandler}
      totalItems={totalTreasures}
      pageSize={10}
      currentPage={currentPage}
      total={total}
    />
  );
}

StatisticsForCoins.propTypes = {};

StatisticsForCoins.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(StatisticsForCoins);

// let dataForStatistics = [
//     {
//       date: 1607423692437,
//       username: 'user name bl1',
//       country: 'hr',
//       purchasedCoins: 300,
//       coinsConvertedFromPoints: 280,
//       usedCoins: 3000,
//       userCoinBalance: 200,
//       id: 55,
//     },
// ]
