import React from 'react';
import { Row, Col, Typography } from 'antd';
import allIcon from '../../../../assets/all_audience.svg';
import idolIcon from '../../../../assets/idol_audience.svg';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { notificationDetails } from '../../../../lib/SubMenuConstants';
import moment from 'moment';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import { all } from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';

interface Props {
  selected: any;
  openSubMenu1: (menu: string) => Action;
  title: string;
  timestamp: number;
  storeDetails: (notification: any) => Action;
  url: string;
  description: string;
  type: string;
  ID: number;
  language?: languages;
  contestID: number;
  contestName: string;
  handleSelectedNotification: (id: number) => void;
}

const mapState = (state: any) => {
  return {
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    openSubMenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    storeDetails: (notification: any) =>
      dispatch({ type: notificationsConstants.NOTIFICATION_DETAILS, notification }),
  };
};

const Notification = (props: Props) => {
  const openNotificationDetails = () => {
    props.openSubMenu1(notificationDetails);
    props.storeDetails(props);
    props.handleSelectedNotification(props.ID);
  };

  return (
    <Row
      type="flex"
      justify="space-between"
      align="middle"
      className={props.selected ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
      onClick={openNotificationDetails}
    >
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        className="notification-item full-width"
        style={{ width: '95%' }}
      >
        <Col>
          <img
            style={{ width: '30px' }}
            src={props.type.indexOf('general') !== -1 ? allIcon : idolIcon}
          />
        </Col>
        <Col style={{ width: '72%' }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Row>
                <Typography.Text className="bold">
                  {props.type.indexOf('general') !== -1 ? all[props.language] : props.title}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="fredoka">{props.title}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text>
                  {moment(props.timestamp, 'x').format('DD/MM/YY h:mm A')}
                </Typography.Text>
              </Row>
            </Col>
            <Col>
              <img src={arrow} className="treasure-arrow" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Notification);
