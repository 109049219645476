import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Select } from 'antd';
import PropTypes from 'prop-types';

function SelectorGray(props) {
  const {
    visible,
    list,
    onChange,
    defaultValue,
    containerStyle,
    dropDownTextPosition,
    selectorTextPosition,
    placeholder,
    listObject,
    firstCellEmpty,
    showSearch,
    ...otherProps
  } = props;

  if (!visible) {
    return null;
  }

  const onSelectHandler = (value) => onChange(value);

  const selectorClasses =
    selectorTextPosition === 'center'
      ? 'treasure-list-location-selector treasure-list-status-selector-picker'
      : 'treasure-list-location-selector treasure-list-status-selector-picker-justify-start';

  return (
    <Row className="treasure-list-location" style={containerStyle}>
      <Col>
        <Select
          className={selectorClasses}
          defaultValue={placeholder ? undefined : defaultValue || firstCellEmpty ? '' : list[0]}
          placeholder={placeholder}
          onChange={onSelectHandler}
          showSearch={showSearch}
          {...otherProps}
        >
          {firstCellEmpty && (
            <Select.Option
              className={dropDownTextPosition === 'center' ? 'text-centered' : null}
              key={'&nbsp;EmptyCell'}
              value={''}
            >
              &nbsp;
            </Select.Option>
          )}
          {list.map((name) => {
            return (
              <Select.Option
                className={dropDownTextPosition === 'center' ? 'text-centered' : null}
                key={name}
                value={name}
              >
                {name}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
}

SelectorGray.propTypes = {
  visible: PropTypes.bool,
  list: PropTypes.array,
  listObject: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  containerStyle: PropTypes.object,
  dropDownTextPosition: PropTypes.oneOf(['center', 'start']),
  selectorTextPosition: PropTypes.oneOf(['center', 'start']),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  firstCellEmpty: PropTypes.bool,
  showSearch: PropTypes.bool,
};

SelectorGray.defaultProps = {
  visible: true,
  list: ['Test1', 'Test2', 'Test3'],
  onChange: () => {},
  dropDownTextPosition: 'center',
  selectorTextPosition: 'center',
  firstCellEmpty: false,
  showSearch: false,
};

export default memo(SelectorGray);
