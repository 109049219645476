import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import {
  statisticsForAddressCoupon,
  statisticsForCouponStrings,
} from '../../../../lib/strings/statistics';
import { statisticsForCoupon} from './apiToTabel';
import * as statisticsService from '../../../../services/statisticsService';
import { useQuery } from 'react-query';

function StatisticsForCoupon(props) {
  const { language } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});
  const [pagcurrent, setPagCurrent]=useState(1)

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForCoupon = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForCoupon(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['statisticsForCoupon', { ...currentFilters, page: currentPage }],
    fetchStatisticsForCoupon,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  useEffect(() => {
    if (statisticsQuery.data) {
      const parsedStatistics = statisticsForCoupon(statisticsQuery.data);

      setTotal({
        totalBalance: statisticsQuery.data.Count,
        // purchasedCoins: statisticsQuery.data.purchasedCoins,
        // convertedCoins: statisticsQuery.data.coinsFromConversion,
        // usedCoins: statisticsQuery.data.usedCoins,
      });
       setStatistics(parsedStatistics);
      setTotalTreasures(statisticsQuery.data.Count);
      setPagCurrent(statisticsQuery.data.Count); 
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
    setClickedUsername('');
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  let columns = [
    {
      titleStyle: {},
      dataIndex: 'date',
      onClick: () => {},
      dataStyle: {},
    },
    {
      dataIndex: 'username',
      onClick: clickedUsernameHandler,
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' }, 
    },
    {
      dataIndex: 'country',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'no_of_coupons',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'dome_addr_1',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'dome_addr_2',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'dome_addr_3',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'unused_coupons',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      dataIndex: 'dome_add',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      // render: (text, indx, record) => <span>{pagcurrent-(currentPage*100)-indx}</span>
    },
  ];

  columns.map((column, index) => {
    columns[index]['title'] = statisticsForCouponStrings[language][column.dataIndex];
  });

  return (
    <Statistics
      label={statisticsForAddressCoupon[language]}
       columns={columns}
      dataSource={statistics}
      loading={statisticsQuery.isLoading}
      statisticsType={'StatisticsForCoupon'}
      clickedUsername={clickedUsername}
      onFiltersChange={onFiltersChangeHandler}
      onPageChange={onPageChangeHandler}
      totalItems={totalTreasures}
      pageSize={100}
      currentPage={currentPage}
      total={total}
    />
  );
}


StatisticsForCoupon.propTypes = {};

StatisticsForCoupon.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(StatisticsForCoupon);


