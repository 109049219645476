import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { Row, Col, Button, Typography, Select } from 'antd';
import { Table, Filter, Paginate } from '../../../../components';
import * as menuActions from '../../../../actions/menuActions';
import * as statisticsStrings from '../../../../lib/strings/statistics';
import PropTypes from 'prop-types';
import { lang } from 'moment';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function DomeStatistics(props) {
  const {
    language,
    closeSubmenuFullScreen,
    label,
    loading,
    statisticsType,
    dataSource,
    clickedUsername,
    kitList,
    onFiltersChange,
    onPageChange,
    totalItems,
    pageSize,
    currentPage,
    total,
    role,
    isPartner,
    currentFilters,
    ...otherProps
  } = props;

  const { width, height } = useWindowDimensions();

  const handleCloseStatistics = () => {
    closeSubmenuFullScreen();
  };

  const onFilterInputChange = (e) => {
    let filter = {
      filter: e.target.value,
    };
    onFiltersChange(filter);
  };

  return (
    <Row
      className="menu-wrapper full-screen-submenu"
      // type="flex"
      // justify="start"
    >
      {/* Back button and title */}
      <Row
        className="contest-top-row"
        type="flex"
        justify="start"
        align="middle"
      >
        <Button onClick={handleCloseStatistics} className="details-arrow">
          <img src={arrow} alt="close" />
        </Button>
        <Typography.Text className="contest-title bold">
          {label}
        </Typography.Text>
      </Row>
      <div style={{ paddingLeft: '2em', paddingRight: '2em' }}>
        {/* Filter and page numbers */}
        <Row
          style={{ marginBottom: '30px' }}
          type="flex"
          justify="space-between"
        >
          <Col>
            <h3>Total : {totalItems}</h3>
          </Col>
          <Col span={12}>
            <input
              name="subject"
              //   readOnly={true}
              type="text"
              onChange={onFilterInputChange}
              placeholder="Search by Username, Country, Dome Address or Dome Name"
              value={currentFilters.filter}
              className="inp regular"
            />
          </Col>
        </Row>
        <Table
          width={width - 70}
          height={height - 220}
          language={language}
          loading={loading}
          dataSource={dataSource}
          {...otherProps}/>
        <Row style={{ marginTop: '30px' }} type="flex" justify="center">
          <Col>
            <Paginate
              onPageChange={(page) => onPageChange(page.selected)}
              totalItems={totalItems}
              pageSize={pageSize}
              forcePage={currentPage}
            />
          </Col>
        </Row>
        {/* {dataSource.length !== 0 && !loading && (
          <div className="statistics-below-table">
            {statisticsType === 'StatisticsForTreasure' && (
              <BellowTable
                label={statisticsStrings.totalLcashBalance[language]}
                value={parseInt(total.totalBalance, 10)}
              />
            )}
          </div>
        )} */}
      </div>
    </Row>
  );
}

DomeStatistics.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  statisticsType: PropTypes.string,
  dataSource: PropTypes.array,
  onPageChange: PropTypes.func,
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  total: PropTypes.object,
};

DomeStatistics.defaultProps = {
  language: 'EN',
  onPageChange: () => {},
  pageSize: 10,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  kitList: state.kitList,
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenuFullScreen: () => dispatch(menuActions.closeSubmenuFullScreen()),
  openStats: () => dispatch(menuActions.openSubmenuFullScreen),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomeStatistics);

export function BellowTable(props) {
  const { label, value } = props;
  return (
    <div className="column">
      <div className="row">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
}

BellowTable.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
