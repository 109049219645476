import React from 'react';
import { Row, Col, Typography } from 'antd';
import Flag from 'react-flagkit';
import coin from '../../../../assets/coin_icon.svg';
import star from '../../../../assets/star.png';
import flags from '../../../../helpers/countryCallingCodes.json';

interface Props {
  countryName: string;
  points: number;
}

const ExchangeRateCountry = (props: Props) => {
  const countryFlag = flags.find(
    (country: { alpha2Code: string }) => country.alpha2Code.toLowerCase() === props.countryName
  );

  return (
    <Row type="flex" justify="space-between" style={{ width: '95%' }}>
      <Col>
        {props.countryName && <Flag className="flags" country={props.countryName.toUpperCase()} />}
        <Typography.Text className="bold">
          {countryFlag !== -1 && countryFlag ? countryFlag.name : ''}
        </Typography.Text>
      </Col>
      <Col className="bold" style={{ display: 'flex', alignItems: 'center' }}>
        <img src={star} /> {props.points} = <img src={coin} /> 1
      </Col>
    </Row>
  );
};

export default ExchangeRateCountry;
