import basic1 from './basic1.JPG';
import basic2 from './basic2.JPG';
import basic3 from './basic3.JPG';
import basic4 from './basic4.JPG';
import basic5 from './basic5.JPG';
import basic6 from './basic6.JPG';
import basic7 from './basic7.JPG';
import basic8 from './basic8.JPG';
import basic9 from './basic9.JPG';
import basic10 from './basic10.JPG';

import basic1_1 from './basic1.1.JPG';
import basic2_1 from './basic2.1.JPG';
import basic3_1 from './basic3.1.JPG';
import basic4_1 from './basic4.1.JPG';
import basic5_1 from './basic5.1.JPG';
import basic6_1 from './basic6.1.JPG';
import basic7_1 from './basic7.1.JPG';
import basic8_1 from './basic8.1.JPG';
import basic9_1 from './basic9.1.JPG';
import basic10_1 from './basic10.1.JPG';

export const basicportrait = [
  { id: 1, src: basic1 },
  { id: 2, src: basic2 },
  { id: 3, src: basic3 },
  { id: 4, src: basic4 },
  { id: 5, src: basic5 },
  { id: 6, src: basic6 },
  { id: 7, src: basic7 },
  { id: 8, src: basic8 },
  { id: 9, src: basic9 },
  { id: 10, src: basic10 },
];

export const basiclandscape = [
  { id: 1, src: basic1_1 },
  { id: 2, src: basic2_1 },
  { id: 3, src: basic3_1 },
  { id: 4, src: basic4_1 },
  { id: 5, src: basic5_1 },
  { id: 6, src: basic6_1 },
  { id: 7, src: basic7_1 },
  { id: 8, src: basic8_1 },
  { id: 9, src: basic9_1 },
  { id: 10, src: basic10_1 },
];
