import React, { useEffect, useState } from 'react';
import { Row, Button, Spin, Menu, notification, Col } from 'antd';
import NotificationstList from './NotificationsList';
import {
  getScheduledPushNotifications,
  getSentPushNotifications,
} from '../../../../services/notificationsService';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import { menuConstants } from '../../../../constants/menuConstants';
import { notificationForm } from '../../../../lib/SubMenuConstants';
import {
  newNotificationButton,
  templateButton,
  scheduledButton,
  sentButton,
} from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';
import * as notificationActions from '../../../../actions/notificationsActions';
import * as actions from '../../../../actions/notificationsActions';

interface Props {
  notifications?: any;
  storeNotifications: (notifications: any) => void;
  openSubMenu1: (menu: string) => void;
  language: languages;
  shouldNotificationsUpdate: boolean;
  updateNotifications: (value: boolean) => void;
  setNotificationsInitial: () => void;
  purgeNotificationLocalization: () => void;
  purgeNotficationDetails: () => void;
}

const mapStateToProps = (state: {
  notificationsList: any;
  authentication: any;
  notificationsLogic: any;
}) => {
  return {
    notifications: state.notificationsList,
    language: state.authentication.language,
    shouldNotificationsUpdate: state.notificationsLogic.shouldUpdate,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    storeNotifications: (notifications: any) =>
      dispatch({ type: notificationsConstants.STORE_NOTIFICATIONS, notifications }),
    openSubMenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    updateNotifications: (value: boolean) =>
      dispatch(notificationActions.updateNotifications(value)),
    setNotificationsInitial: () => dispatch(actions.setNotificationsInitial()),
    purgeNotificationLocalization: () => dispatch(actions.purgeNotificationLocalization()),
    purgeNotficationDetails: () => dispatch(actions.purgeNotificationDetails()),
  };
};

const Notifications = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState({ key: 'sent' });

  const handleSelectedButton = async (e: { key: string }) => {
    setloading(true);
    setSelectedRadioButton(e);
    const fetchScheduled =  await getScheduledPushNotifications();
    if (fetchScheduled) {
      const sort = fetchScheduled[e.key].sort((a: { ID: number }, b: { ID: number }) =>
        a.ID > b.ID ? 1 : -1
      );
      props.storeNotifications(sort);
    }
    props.updateNotifications(false);
    setloading(false);
  };

  const openNotificationCreation = () => {
    props.setNotificationsInitial();
    props.purgeNotificationLocalization();
    props.purgeNotficationDetails();
    props.openSubMenu1(notificationForm);
  };

  useEffect(() => {
    handleSelectedButton({ key: 'sent' });
  }, []);

  useEffect(() => {
    if (props.shouldNotificationsUpdate === true) {
      handleSelectedButton(selectedRadioButton);
    }
  }, [props.shouldNotificationsUpdate]);

  return (
    <Row>
      <Col
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1em' }}
      >
        <Row style={{ margin: '.5em', width: '100%' }} type="flex" justify="center">
          <Button
            className="buttons purple-gradient-right menu-button-width"
            onClick={openNotificationCreation}
          >
            {newNotificationButton[props.language]}
          </Button>
        </Row>
        <Row style={{ marginTop: '.5em', width: '100%' }} type="flex" justify="center">
          <Button className="buttons exchange-rate-btn menu-button-width">
            {templateButton[props.language]}
          </Button>
        </Row>
      </Col>

      <Row type="flex" justify="space-around" className="admin-review-btn">
        <Menu
          onClick={(e: any) => handleSelectedButton(e)}
          mode="horizontal"
          style={{ width: '100%' }}
          defaultSelectedKeys={['sent']}
        >
          <Menu.Item className="" key="scheduled">
            {scheduledButton[props.language]}
          </Menu.Item>
          <Menu.Item key="sent">{sentButton[props.language]}</Menu.Item>
        </Menu>
      </Row>
      {loading ? (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row style={{ width: '100%' }}>
          <NotificationstList notificationsList={props.notifications} />
        </Row>
      )}
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
