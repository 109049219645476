// import React from 'react';
// import { Typography, Row, Button } from 'antd';
// import PropTypes from 'prop-types';
// import { useInfiniteQuery } from 'react-query';
// import { lcashHistoryInfinite } from '../../../../services/balanceService';
// import { LcashHistoryListItem } from '../../../../components';
// import * as balanceStrings from '../../../../lib/strings/balance';

// function LcashHistory({ language }) {
//   const lcashHistoryInfiniteQuery = useInfiniteQuery(
//     'lcashHistory',
//     ({ pageParam }) => lcashHistoryInfinite(pageParam),
//     {
//       getNextPageParam: (lastPage) => {
//         const nextPage = lastPage.pagination.page + 1;
//         const maxPage = Math.ceil(lastPage.pagination.total / lastPage.pagination.itemsPerPage);
//         return nextPage <= maxPage ? nextPage : undefined;
//       },
//     }
//   );

//   const nextPageHandler = () => {
//     lcashHistoryInfiniteQuery.fetchNextPage();
//   };

//   return (
//     <section className="lcash-container">
//       <div className="title-container">
//         <Typography.Title level={4}>{balanceStrings.lcashHistory[language]}</Typography.Title>
//       </div>

//       <div className="list-container">
//         <Typography.Text strong>{balanceStrings.date[language]}</Typography.Text>
//         <Typography.Text strong>{balanceStrings.remarks[language]}</Typography.Text>
//         <Typography.Text strong>{balanceStrings.amount[language]}</Typography.Text>
//         <Typography.Text strong>{balanceStrings.balance[language]}</Typography.Text>

//         {lcashHistoryInfiniteQuery.data?.pages.map((page) =>
//           page.transactionHistory.map((transaction) => (
//             <LcashHistoryListItem
//               key={transaction.transactionDate}
//               transactionDate={transaction.transactionDate}
//               transactionAmount={transaction.transactionAmount}
//               totalBalance={transaction.totalBalance}
//               transactionType={transaction.transactionType}
//             />
//           ))
//         )}
//       </div>

//       {lcashHistoryInfiniteQuery.hasNextPage && (
//         <Row type="flex" justify="center">
//           <Button className="transparent-btn" onClick={nextPageHandler}>
//             {balanceStrings.loadNextPage[language]}
//           </Button>
//         </Row>
//       )}
//     </section>
//   );
// }

// LcashHistory.propTypes = {
//   language: PropTypes.string,
// };

// LcashHistory.defaultProps = {
//   language: 'EN',
// };

// export default LcashHistory;


import React, { useContext, useEffect } from 'react';
import {
  Row,
  Button,
  Typography,
  Col,
  InputNumber,
  Pagination,
  DatePicker,
  Table,
  Spin,
  Select,
} from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { connect } from 'react-redux';
import { rechargeConstants } from '../../../../constants/rechargeConstants';
import closeIcon from '../../../../assets/closeXsmall.svg';
import downloadIcon from '../../../../assets/downloadIcon.png';
import moment from 'moment';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '../../../../assets/chevronLeft.svg';
import ChevronRight from '../../../../assets/chevronRight.svg';
import searchIcon from '../../../../assets/searchIcon.svg';
import { languages } from '../../../../lib/interfaces/language';
import { userConstants } from '../../../../constants/signinConstants';
import { LOCAL_STORAGE_USERDATA } from '../../../../lib/config';
import { userData } from '../../../../reducers/userReducer';
import AppContext from '../../../../AppContext';
import { lcashTransaction } from '../../../../services/domeService';
import { useTranslation} from "react-i18next";
interface Props {
  handleClose: () => void;
  updateLCash: (amount: number) => void;
  amount: number;
  currentLCash: number;
  usd: number;
  isPaymentInitiated: boolean;
  language?: languages;
  userData: object;
  storeUserData: (user: object) => void;
}


const mapState = (state: {
  authentication: any;
  userData: { lcash: any };
  recharge: { usd: any; amount: any };
  isPaymentInitiated: boolean;
}) => {
  return {
    currentLCash: state.userData.lcash,
    usd: state.recharge.usd,
    amount: state.recharge.amount,
    isPaymentInitiated: state.isPaymentInitiated,
    language: state.authentication.language,
    userData: state.userData,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateLCash: (amount: number) =>
      dispatch({ type: rechargeConstants.STORE_AMOUNT, amount }),
    storeUserData: (user: any) =>
      dispatch({ type: userConstants.STORE_USER_DATA, user }),
  };
};

const LcashHistory = (props: Props) => {
  const [Startdate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState('');
  const [len, setlen] = useState(0);
  const [filtervalue, setFiltervalue] = useState("");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [pagcurrent, setPagCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  // const pageCount = Math.ceil(totalItems / pageSize);
  const { t } = useTranslation();
  const [allData, setAllData] = useState([]);
  const profile = useContext(AppContext);

  const [selectedCheckBoxIds, setSelectedCheckBoxIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // useEffect(() => {
  //   persistor.persist();
  //   persistor.flush();
  // }, []);

  

  const onStripePayment = () => {
    props.storeUserData({ refetch: true });
    localStorage.setItem(
      LOCAL_STORAGE_USERDATA,
      JSON.stringify({ ...userData, refetch: true })
    );
  };

  const closetable = () => {
    profile.profile['lcashHistory'] = false;
    profile.setContextData(profile.profile);
  };


  // const checkedItem = (e, key) => {
  //   console.log(e.target.value, key);
  //   if(e.target.value==="item"){
  //     console.log("kkk",key)
  //   }
  //   console.log(e);
  // };
  useEffect(() => {
    (async () => {
      setLoading(true);
      let data =  await lcashTransaction(1);
       
      // setlen(data.data.length);
      setData(data.transactionHistory);
      setAllData(data.alldata);
      setPagination(data.pagination.page);
      setPagCurrent(data.pagination.total);
      setLoading(false);

    })();
  }, [filtervalue, Startdate, endDate]);

  const onPageChange = async (e: any) => {
    let page_no = e.selected;
    let data =  await lcashTransaction(page_no + 1);
    setData(data.data);

  };

  const checkHandlar = (e) => {
    if (e.target.checked) {
      let tempItems = [...allData];
      let tempSelectedIds = allData.map((item) => item.ID);

      setSelectedCheckBoxIds(tempSelectedIds);
      setSelectedItems(tempItems);
    } else {
      setSelectedCheckBoxIds([]);
      setSelectedItems([]);
    }
  };

  const downloadFile = ({ allData, fileName, fileType }) => {
    const blob = new Blob([allData], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    if (!selectedItems.length) return;

    let headers = ['Date,Dome Name,Nickname,Country,Plum,Total_Plums'];


    let usersCsv = selectedItems.reduce((acc, user) => {
      const { date, dome_name, nickname, country, plum, total_received_plums } =
        user;
      acc.push(
        [
          moment(date).format('MM/DD/YYYY'),
          dome_name,
          nickname,
          country,
          plum,
          total_received_plums,
        ].join(',')
      );
      return acc;
    }, []);

    downloadFile({
      allData: [...headers, ...usersCsv].join('\n'),
      fileName: 'Received.csv',
      fileType: 'text/csv',
    });
    // }
  };

  const thousandSeparators = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const onSelectSingleItem = (e, item) => {
    let tempItems = [...selectedItems];
    let tempSelectedIds = [...selectedCheckBoxIds];
    if (e.target.checked) {
      tempSelectedIds.push(item.ID);
      tempItems.push(item);
      setSelectedCheckBoxIds(tempSelectedIds);
      setSelectedItems(tempItems);
    } else {
      tempSelectedIds = tempSelectedIds.filter(
        (filtItem) => filtItem !== item.ID
      );
      setSelectedCheckBoxIds(tempSelectedIds);
    }
  };

  const onFilterInputChange = (e) => {
    setFiltervalue(e.target.value)
  };


  const SearchData = async () => {
    // let data =  await getReceivedPlumaApi(1, props, filtervalue, Startdate, endDate)
    // setData(data.data);
  }

  const SearchStartDate = (e: any) => {
    if (e == null) {

      setStartdate("")
    }
    else {
      let dar1 = moment(e._d).format('YY/MM/DD')
      setStartdate(dar1)
    }
  };

  const { Option } = Select;

  const SearchEndDate = (e) => {
    if (e == null) {
      setEndDate("")
    }
    else {
      let dar = moment(e._d).format('YY/MM/DD')
      setEndDate(dar)
    }
  }

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <>

      <Row className="menu-wrapper treasure-menu-wrapper second-dialog cardBiger">
        <div className="second-card relative">
          <Row
            className="contest-top-row"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <div>
              <Button onClick={props.handleClose} className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>
              <Typography.Text
                style={{ textTransform: 'none' }}
                className="contest-title bold"
              >
                Transaction
                {/* {recharge[props.language]} */}
              </Typography.Text>
            </div>
            <div>
              <i onClick={closetable} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </Row>

          <div className="col col-12 table-bdr">
            <div className="grid-row">
              <div className="col col-5 pt-10 pb-10 fx-aic">
                {/* <input
                  name="subject"
                  type="text"
                  onChange={onFilterInputChange}
                  placeholder={t("Search by Dome Name country")}
                  // value={currentFilters}
                  className="inp regular"
                /> */}
                <Select defaultValue="recharged" style={{ width: '100%' }} onChange={handleChange}>
                  <Option value="recharged">Recharged</Option>
                  <Option value="created">Created</Option>
                  <Option value="refunded">Refunded</Option>
                </Select>

              </div>

              <div className="col col-6 bdr-left pt-10 pb-10">
                <div className="grid-row sm">
                  <div className="col col-6 size13">{t('Start Date')}</div>
                  <div className="col col-6 size13">{t('End date')}</div>
                </div>
                <div className="grid-row sm">
                  <div className="col col-6">
                    <DatePicker
                      onChange={SearchStartDate}
                    />
                  </div>
                  <div className="col col-6">
                    <DatePicker
                      onChange={SearchEndDate}
                    />
                  </div>
                </div>
              </div>
              <div className="col col-1 search-icn">
                <img
                  src={searchIcon}
                  alt="icon"
                  height={24}
                  className="cursor"
                  onClick={SearchData}
                />
              </div>
            </div>
          </div>
          <br></br>
          <div className='fx-jcsb-aic'>
            <button onClick={exportToCsv} className="size12 cursor">
              <img src={downloadIcon} alt="icon" height="16" />{t('Export to CSV')} 
            </button>
            <div className='size12'>
              Unit: Lcash
            </div>
          </div>
          {/* <Table columns={columns} dataSource={tableData} scroll={{ y: 240 }} /> */}

          <div className="domeAddContainer">
            <div className="mb-5 pt-10"></div>
            <Row>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th style={{ width: '30px' }} scope="col">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                        // checked={selectedCheckBoxIds.length === allData.length}
                        onChange={checkHandlar}
                      />
                    </th>
                    <th style={{ width: '90px' }} scope="col">{t('Date')}</th>
                    <th style={{ width: '50px' }} scope="col" className='text-left'>{t('In')}</th>
                    <th style={{ width: '50px' }} align="center" scope="col" className="text-left">{t('Out')}</th>
                    <th style={{ width: '80px' }} align="center" scope="col" className="text-center"> {t('Balance')} </th>
                    <th  style={{ width: '120px' }} align="center" scope="col" className="text-center"> {t('Ref ID')} </th>
                    <th  style={{ width: '120px' }} align="center" scope="col" className="text-center"> {t('Remarks')} </th>
                    <th align="center" scope="col" className="text-center"> {t('Invoice')} </th>
                  </tr>
                </thead>
              </table>
              <div className='domeAddContainer scrollSection' style={{ maxHeight: '52vh' }}>
                <table className="table">

                  <tbody>
                    {loading && (
                      <td align='center' colSpan={7}>
                        <Spin
                          size="large"
                          style={{ marginTop: '2em', marginBottom: '2em', textAlign: "center" }}
                        />
                      </td>

                    )}
                    {
                      data.map(function (item, key) {
                                                return (
                          <tr key={key}>
                            <td style={{ width: '30px' }}>
                              <input
                                type="checkbox"
                                id={`custom-checkbox-${item}`}
                                name="vehicle1"
                                value={item}

                                checked={selectedCheckBoxIds.includes(item.ID)}

                                onChange={(e) => onSelectSingleItem(e, item)}
                              />
                            </td>
                            <td style={{ width: '90px' }}>{moment(item.transactionDate).format('MM/DD/YYYY')}</td>
                            <td style={{ width: '50px' }}>
                              {item.transactionAmount}
                            </td>
                            <td style={{ width: '50px' }}>{item. dome_name}</td>
                            <td style={{ width: '80px' }} align="center" className="text-center">
                              {item.totalBalance}
                            </td>
                            <td style={{ width: '120px' }} className="text-center">{item.refID}</td>
                            {/* <span className={item.type== 4 ? 'text-error' : ''}></span> */}
                            <td  style={{ width: '120px' }} className="text-center ">
                              {item.transactionType}
                            </td>
                            <td className="text-center ">
                              {item.transactionId}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </Row>
            <Row className="fx-jcc flex-btn mb-15">
              <ReactPaginate
                containerClassName="statistic-paging-container"
                pageClassName="statistic-paging-page"
                pageLinkClassName="statistic-paging-page-link"
                activeLinkClassName="statistic-paging-page-link-active"
                previousLabel={<img src={ChevronLeft} />}
                nextLabel={<img src={ChevronRight} />}
                marginPagesDisplayed={1}
                pageCount={pagination}
                onPageChange={(e: any) => {
                  onPageChange(e);
                }}
              />
            </Row>
          </div>
        </div>
      </Row>

    </>
  );
};

export default connect(mapState, mapDispatch)(LcashHistory);

