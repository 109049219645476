import {
  baseURL, myDome, createDome, checkDomeName,Lboxadmin,uploadImageLbox,
  updateType, searchDomeByName, importVerify,
  importDome, fileUpload, updateSetting,
  fetchInBoundry, domeList, admnNotification,
  getAdminNotification, editingImport,
  RegisterDome, userDetail, userUpdate,plum_received,
  nameRequests, get3DIconsImg, sendEmail, getMessages, deleteDomeName, domeType, searchDomeByNameAndAddress, importDomeByName, importedDomeStatus, editDomeNameImportConfirm, deleteDomeNameImport, domeSettingDelete, uploadHallImages, importedDomeNameStatus
} from '../lib/config';

import { notification } from 'antd';
import { responseCodes } from '../lib/const';
import { store } from '../helpers/store';
import { useContext } from "react";
import LatLon from 'mt-latlon';
import {b} from '../containers/dashboard/menu/i18next';
import moment from 'moment';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};



export const listMyDomes = async () => {
  try {
    const fetchResponse =   await fetch(baseURL + myDome, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const userDetails = async (userId) => {
  try {
    const fetchResponse =   await fetch(baseURL + userDetail + '/' + userId, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const getMessagesList = async (dome_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + getMessages + '/' + dome_id, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}


export const deleteDome = async (dome_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + deleteDomeName + dome_id + '/setting_name', {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}
export const deletePaymontMethod = async (item_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/payment/methods/' + item_id , {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const setPaymentMethodAsDefault = async (item_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/payment/methods/' + item_id + '/set_default/', {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const UpdatePaymentMethod = async (item_id: any, data: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/payment/methods/' + item_id + '/', {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getDeleteDomeSettings = async (dome_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + domeSettingDelete + "/" + dome_id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const getDeleteHallImage = async (dome_id,file_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome +'/hall' + "/" + dome_id + "/" + file_id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}
export const getDeleteHallSection = async (dome_id,section_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome +'/hall' + "/" + dome_id + "/delete_section/" + section_id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const userUpdates = async (data) => {
  const formData = new FormData();
  const newValue = moment(data.yearOfBirth,'DD-MM-YYYY').format('x');

  formData.append('companyName', data.companyName);
  formData.append('email', data.email);
  formData.append('address', data.address);
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('phoneNumber', data.phoneNumber);
  formData.append('username', data.username);
  formData.append('yearOfBirth', newValue);
  try {
    const fetchResponse =   await fetch(baseURL + userUpdate, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
      body: formData,
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const getDomeTypes = async (dome_id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + domeType + dome_id + '/setting', {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === 1) {
      localStorage.setItem("dome_type",response.data.dome_type)
       return response.data;

    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const getAllHallSectionIamges = async (dome_id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/hall' + '/' + dome_id , {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === 1) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const isExistsDomeName = async (name: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + checkDomeName + '?dome_name=' + name, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const UpdateDome = async (id: number, dome_name: any, 
  // file: any
  ) => {
  const formData = new FormData();
  formData.append('dome_name', dome_name);
  // formData.append('file', file);
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/' + id + '/setting_name', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
      body: formData,
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const postDomeNameVerifyAfterImport = async (id: number,dome_no:any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/import/dome_name_changes', {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dome_id:id,
        dome_no
      })
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(b?.t('Something went wrong! Please try again later'),response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};


export const UpdateDomeType = async (id: string, dome_type: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + updateType + '/' + id, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },     
      body: JSON.stringify({
        dome_type: dome_type,
        lang:localStorage.getItem('lang'),
      })
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const UpdateDomeAddressBySuperAdmin = async (id: string, addr_name: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/domeAddress/admin/update_dome_info/' + id, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        addr_name: addr_name,
      })
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const toggleDomeType = async (type_setting_id, status) => {
  try {
    const fetchResponse =   await fetch(baseURL + domeType + type_setting_id + `/toggle?state=${status}`, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === 1) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const publishUnpublishDome = async (id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/' + id + '/publish', {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const publishDomeApi = async (id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/update_setting_status/' + id + '/', {    
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const SearchDome = async (dome_name: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + searchDomeByName + '?dome_name=' + dome_name, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getSearchDomeByNameAndAddress = async (dome_name: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + searchDomeByNameAndAddress + '?dome_name=' + dome_name, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(b?.t("Required Field Missing Dome name"));
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getDuplicateDomeNameDelete = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/import/delete' + '?dome_id=' + dome_id, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const getImportDomeStatus = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + importedDomeStatus + '?dome_id=' + dome_id, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const postImportDomeByName = async (dome_name: any, dome_id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + importDomeByName, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dome_name: dome_name,
        dome_id: dome_id
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
       showSuccessMessage(b?.t("Mail Sent Successfully"))
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const getImportDomeNameStatus = async (dome_id: any, parent_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + importedDomeNameStatus, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        
        dome_id,
        parent_id
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      showSuccessMessage(response?.data?.description)
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const postEditDomeNameImportConfirm = async (dome_name: any, dome_id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + editDomeNameImportConfirm, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dome_name: dome_name,
        dome_id: dome_id
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
       showSuccessMessage(b?.t("Imported Successfully"))
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getImportDomeDelete = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + deleteDomeNameImport + '?dome_id=' + dome_id, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const ImportDome = async (dome_name: any, dome_id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + importDome, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dome_name: dome_name,
        dome_id: dome_id
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(b?.t("Required Field Missing Dome name"));
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const ImportVerify = async (dome_id: number, parent_id: number, code: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + importVerify, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dome_id: dome_id,
        parent_id: parent_id,
        code: code
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const postHastagsApi = async (dome_id: number,data: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + `/hashtags/${dome_id}/`, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getHastagsApi = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + `/hashtags/${dome_id}/`, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getImageLbox = async () => {
  try {
    const fetchResponse =   await fetch(baseURL + uploadImageLbox, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const FileUpload = async (file: any) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    console.log("file",file)
    const fetchResponse =   await fetch(baseURL + fileUpload, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
      },
      body: formData
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
        showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const PublishImageWithUrl = async (id: number, type: any, data: any,) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/' + id + '/setimage', {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type,
        data: data,
      })

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const postArShow = async (dome_id: number, data: any) => {
  try {
    const fetchResponse =   await fetch(
      baseURL + "/api/armax/domeAddress/arshow/" + dome_id,
      {
        method: "POST",
        headers: {
          "access-token": store.getState().authentication.user.newToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
       showSuccessMessage(b?.t("ARshow updated"));
      return response.data;
    } else {
       showErrorMessage(b?.t("Required Field Missing"));
      return Promise.reject("failed to get sections");
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getDeleteArShow = async (dome_id,file_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + "/api/armax/domeAddress/arshow/" + dome_id + "/" + file_id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const getArShowData = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/arshow/' + dome_id, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const getChatUsersList = async (dome_id: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/admin/' +dome_id + '/joined_users' , {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};
export const getChatList = async (userId: any,otherUserId:any) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}/api/v2/message/list/1-${userId}-${otherUserId} ` , {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const PublishImageForHallWithSections = async (id: number, data: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + uploadHallImages + '/' + id, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};


export const updateImageForHallWithSections = async (id: number, data: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + uploadHallImages + '/' + id, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)

    });
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const loadDome = async (id: number) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/' + id + '/loaddome', {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
      },

    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      console.log(response.data,"response.data")
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const get3DIcons = async () => {
  try {
    const fetchResponse =   await fetch(baseURL + get3DIconsImg, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};


export const UpdateSetting = async (id: number, obj: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + updateSetting + '/' + id, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)

    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
       return response.data;
    } else {
      showErrorMessage(b?.t("File not uploaded"), b?.t("Dome updated successfully"));
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

   export const LboxAdminImage = async (firstFile:any,secondFile:any,thirdFile:any,fourthFile:any,fifthFile:any,sixthFile:any) => {
    try {
      const fetchResponse =   await fetch(baseURL+Lboxadmin,{
        method: 'PUT',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            image_1:firstFile ,
            image_2:secondFile,
            image_3:thirdFile,
            image_4:fourthFile,
            image_5:fifthFile,
            image_6:sixthFile
          }
        )
  
      });
      const response =   await fetchResponse.json();
      console.log(response,"response")
      if (response.code === responseCodes.successful) {
        showSuccessMessage(b?.t("Images updated successfully"))
         return response.data;
      } else {
         showErrorMessage(b?.t("File not uploaded"));
        return Promise.reject('failed to get sections');
      }
    } catch (error) {
      showErrorMessage("Try after sometimes");
      return error;
    }
  };



export const deleteSetting = async (id: number, obj: any) => {
  try {
    const fetchResponse =   await fetch(baseURL + updateSetting + '/' + id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj)

    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const GetAllDomeByBounds = async (obj: any) => {
  try {
    const requestOption = {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "north_east_lat": obj.north,
        "north_east_lng": obj.east,
        "south_west_lat": obj.south,
        "south_west_lng": obj.west,
      })
    };
    const res =   await fetch(baseURL + fetchInBoundry, requestOption).then(r => r.json());
    return res.data;
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const getMyDome = async () => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + myDome, requestOption).then(r => r.json());
  return res.data;
};

export const getAllDomes = async () => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + domeList, requestOption).then(r => r.json());
  return res.data;
};

export const crateNotification = async (body: any) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };
  const res =   await fetch(baseURL + admnNotification, requestOption).then(r => r.json());
  // return res.data;
  if (res.code === responseCodes.successful) {
    showSuccessMessage('Added Succesfully');
    return res.data;
  } else {
    showErrorMessage(b?.t("No Users found"), res.code);
    return Promise.reject('failed to get sections');
  }
}

export const getNotification = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + getAdminNotification + '/' + dome_id + '/notifications', requestOption).then(r => r.json());
  return res.data;
}

export const getNameReview = async () => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + nameRequests, requestOption).then(r => r.json());
  return res.data;
}

export const getNameReviewDetail = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + nameRequests + '/' + dome_id, requestOption).then(r => r.json());
  return res.data;
}

export const approveNameReview = async (dome_id, type) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  let statusType;
  if (type === 1) {
    statusType = '/approve'
  } else {
    statusType = '/pending'
  }
  const res =   await fetch(baseURL + nameRequests + '/' + dome_id + statusType, requestOption).then(r => r.json());
  return res.data;
}


export const sendEmailMessage = async (body) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const res =   await fetch(baseURL + sendEmail, requestOption).then(r => r.json());
  return res.data;
}
export const getDomeAddressNextSuffix = async (body) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const res =   await fetch(baseURL + createDome + '/next_suffix', requestOption).then(r => r.json());
  return res.data;
}


export const getNotice = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + getAdminNotification + '/' + dome_id + '/notice', requestOption).then(r => r.json());
  return res.data;
}

export const notice = async (dome_id, body) => {
  const requestOption = {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  };
  const response =   await fetch(baseURL + getAdminNotification + '/' + dome_id + '/notice', requestOption).then(r => r.json());
  if (response.code === responseCodes.successful) {
    return response.data;
  } else {
    showErrorMessage(response.error, response.code);
    return Promise.reject('failed to get sections');
  }
}

export const deleteNotice = async (dome_id,notice_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + getAdminNotification + '/' + dome_id + '/notice/' + notice_id, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      showSuccessMessage('deleted succesfully')
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}



export const importDomeEditing = async (dome_id: number, dome_name: any, type: any, color) => {
  let colorName = color.split('#')[1];

  try {
    const fetchResponse =   await fetch(baseURL + editingImport + '?dome_name=' + dome_name + '&type=' + type + '&dome_id=' + dome_id + '&color=' + colorName, {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
       showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
};

export const registerDome = async (latLng) => {
  try {
    const fetchResponse =   await fetch(baseURL + RegisterDome, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(latLng)
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}


export const postDomeApplication = async (body,dome_id) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  try{
    const res =   await fetch(baseURL + createDome + '/application/' + dome_id + '/', requestOption).then(r => r.json());
     showSuccessMessage(b?.t("application inserted"))
    return res.data;

  }catch (err){

  }
}


export const getDomeApplication = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + createDome + '/application/' + dome_id + '/', requestOption).then(r => r.json());
  return res.data;
}

export const deleteDomeApplication = async (dome_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/application/' + dome_id + '/', {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      showSuccessMessage('deleted succesfully')
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const postArinaEditApi = async (body,dome_id) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  try{
    const res =   await fetch(baseURL + createDome + '/arena/' + dome_id + '/', requestOption).then(r => r.json());
    showSuccessMessage(res?.data?.msg)
    return res.data;
    

  }catch (err){

  }
  
}

export const cointoPlum = async (body) => {
  const obj={
    single_plum_value:body
  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  try{
    const res =   await fetch(baseURL + "/api/armax/exchangeRate/cointoplum/",requestOption).then(r => r.json());
    
     showSuccessMessage(res?.data)
    return res.data;
  }catch (err){

  } 
}


export const Plumtocash = async (body) => {
  const obj={
    single_cash_value:body
  }
  

  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  try{
    const res =   await fetch(baseURL + "/api/armax/exchangeRate/plumtocash/",requestOption).then(r => r.json());
    
     showSuccessMessage(res?.data)
    return res.data;
  }catch (err){

  } 
}


export const putArinaEditApi = async (body) => {
  const requestOption = {
    method: 'Put',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  try{
    const res =   await fetch(" https://app.loamoa-dev-merge.cladev.com/api/armax/domeaddress/arena/video_url/9478",requestOption).then(r => r.json());
    return res.data;
  }
  catch (err){

  }
  
}

export const getDomeArinaApi = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
  };
  const res =   await fetch(baseURL + createDome + '/arena/' + dome_id + '/', requestOption).then(r => r.json());
  
  return res.data;
}

export const deleteDomeArenaApi = async (dome_id) => {
  try {
    const fetchResponse =   await fetch(baseURL + createDome + '/arena/' + dome_id + '/', {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      showSuccessMessage('deleted successfully')
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}

export const getDomeAdminPerformanceApi = async (dome_id) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  const res =   await fetch(baseURL + createDome + '/performance/' + dome_id + '/', requestOption).then(r => r.json());
  return res.data;
}

export const postDomeAdminPerformanceApi = async (body,dome_id) => {
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  try{
    const res =   await fetch(baseURL + createDome + '/performance/' + dome_id + '/', requestOption).then(r => r.json());
    showSuccessMessage('Updated Successfully')
    return res.data;

  }catch (err){

  }
}
export const putDomeAdminPerformanceApi = async (body,dome_id,performance_id) => {
  const requestOption = {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  try{
    const res =   await fetch(baseURL + createDome + '/performance/' + dome_id + '/' + performance_id, requestOption).then(r => r.json());
    showSuccessMessage('Updated Successfully')
    return res.data;

  }catch (err){

  }
}


export const getReceivedPlumaApi = async (page_no,props,search,Startdate,endDate) => {
  console.log("search",search)
  if(!search){
    search=''
  }

  if(!Startdate){
    Startdate=''
  }

  if(!endDate){
    endDate=""
  }
 
  const obj={
    user_id:props.userData.mysqlID
  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
     body: JSON.stringify(obj)
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/plum_received/?page="+page_no+"&dome_search="+search+"&start_date="+Startdate+"&end_date="+endDate, requestOption).then(r => r.json());
  
  return res.data;
}

 export const getReceivedPlumaApiSuperAdmin = async (page_no,search,startDate,endDate) => {
  if(!search){
    search=''
  }
  if(!startDate){
    startDate=""
  }

  if(!endDate){
    endDate=""
  }
     const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  const res =   await fetch(baseURL + plum_received + '?page='+page_no+"&search="+search+"&start_date="+startDate+"&end_date="+endDate, requestOption).then(r => r.json());
 
  return res.data;

}



export const getHistoryPlumaApi = async (page_no,props,filtervalue,Startdate,endDate) => {
  if(!filtervalue){
    filtervalue=''
  }
  if(!Startdate){
    Startdate=""
  }

  if(!endDate){
    endDate=""
  }

  const obj={
    user_id:props.userData.mysqlID
  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/plum_history/?page="+page_no+"&search="+filtervalue+"&start_date="+Startdate+"&end_date="+endDate, requestOption).then(r => r.json());
  
  return res.data;
}

export const lcashTransaction = async (page_no) => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  const res =   await fetch(baseURL + "/api/armax/lCashTransactionHistory/?page="+page_no, requestOption).then(r => r.json());
  return res.data;
 
}

export const coinsReceivedApi = async () => {
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/exchange_rate/", requestOption).then(r => r.json());
  return res.data;
 
}

export const admin_cointoplum_history = async (page_no,filtervalue,Startdate,endDate) => {
  if(!filtervalue){
    filtervalue=''
  }
  if(!Startdate){
    Startdate=""
  }

  if(!endDate){
    endDate=""
  }

  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/cointoplum_history/?page="+page_no+"&search="+filtervalue+"&start_date="+Startdate+"&end_date="+endDate, requestOption).then(r => r.json());
  return res.data;
 
}


export const getCashing  = async (page_no,search,startDate,endDate) => {
  if(!search){
    search=''
  }
  if(!startDate){
    startDate=""
  }

  if(!endDate){
    endDate=""
  }
   
  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
      
    },
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/cashing_request/?page="+page_no+"&search="+search+"&start_date="+startDate+"&end_date="+endDate, requestOption).then(r => r.json());
   
  return res.data;

}


// export const getCashing = async (page_no,) => {
  
//   const requestOption = {
//     method: 'GET',
//     headers: {
//       'access-token': store.getState().authentication.user.newToken,
//       'Content-Type': 'application/json'
      
//     },
//   };
//   const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/cashing_request/?page="+page_no, requestOption).then(r => r.json());
   
//   return res.data;

// }

export const UpdateCashingData = async (body) => {
  const obj={
    trans_id:body ,
    staff_name: body,
    status : body
  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  try{
    const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/update_cashing_data/",requestOption).then(r => r.json());
    
     showSuccessMessage(res?.data)
    return res.data;
  }catch (err){

  } 
}

export const plumtousd = async (body,name) => {
  const obj={
    plums:body,
    nickname:name

  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/plumtousd/", requestOption).then(r => r.json());

  return res.data;
}

export const bankInformation = async (apidata) => {

  const obj={
    beneficiary_name:apidata.key3,
    bank_name: apidata.key2,
      account_number: apidata. key1,
      swift_code: apidata.key4,
     
  }
  
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  try{
    const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/bank_information/",requestOption).then(r => r.json());
     showSuccessMessage(b?.t('Updated Successfully'))
    return res.data;
  }catch (err){

  } 
}

export const  bankInformationget= async () => {

  const requestOption = {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
    },
   
    
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/bank_information/", requestOption).then(r => r.json());
 
  return res.data;
 
}


export const sendmail= async (body,email) => {
  const obj={
    body:body,
    email:email

  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/cashing_request/sendmail", requestOption).then(r => r.json());
  
  return res.data;
}


export const updatecashingdata= async (trans_id,staff_name,status=1) => {
  const obj={
    trans_id:trans_id,
    staff_name:staff_name,
    status:status
  }
  const requestOption = {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  };
  const res =   await fetch(baseURL + "/api/armax/domeAddress/admin/update_cashing_data/", requestOption).then(r => r.json());
  
  return res.data;
}

// export const VoucherApi= async (usernames:any) => {
  
//   const requestOption = {
//     method: 'POST',
//     headers: {
//       'access-token': store.getState().authentication.user.newToken,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//       usernames:usernames 
//     })
//   };
//   const res =   await fetch(baseURL + "/api/armax/user/add_voucher_bundle", requestOption).then(r => r.json());
//   console.log(res,"ressssssss")
//   return res.data;
// }

export const VoucherApi = async (usernames:any) => {
  try {
      const fetchResponse =   await fetch(baseURL +"/api/armax/user/add_voucher_bundle" , {
          method: 'POST',
          headers: {
              'access-token': store.getState().authentication.user.newToken,
              'Accept': 'application/json',
              'Content-type': 'application/json',
          },
          body: JSON.stringify({ usernames:usernames })
      });
      const response =   await fetchResponse.json();
      if (response.code === 1) {
          showSuccessMessage('Given out successfuly')
          return response;
          // return response.data;

      } else {
           showErrorMessage('Please check the username');
          return Promise.reject('failed to get sections');
      }
  } catch (error) {
      showErrorMessage('Try after sometimes.');
      return error;
  }
};


export const  usernameVoucher = async (usernames:any) => {
  try {
      const fetchResponse =   await fetch(baseURL +'/api/v2/user/signup/usernameCheckup?username='+usernames, {
          method: 'GET',
          headers: {
              'access-token': store.getState().authentication.user.newToken,
              'Accept': 'application/json',
              'Content-type': 'application/json',
          },
         // body: JSON.stringify({ usernames:usernames })
      });
      const response =   await fetchResponse.json();
      if (response.code != 1) {
          // showSuccessMessage('Given out successfuly')
          return response;
          // return response.data;

      } else {
           showErrorMessage('Please check the Username');
          return Promise.reject('failed to get sections');
      }
  } catch (error) {
      showErrorMessage('Try after sometimes.');
      return error;
  }
};

export const  usernameVoucherAdmin = async (usernames:any) => {
  try {
      const fetchResponse =   await fetch(baseURL +'/api/v2/user/signup/usernameCheckup?username='+usernames, {
          method: 'GET',
          headers: {
              'access-token': store.getState().authentication.user.newToken,
              'Accept': 'application/json',
              'Content-type': 'application/json',
          },
         // body: JSON.stringify({ usernames:usernames })
      });
      const response =   await fetchResponse.json();
      if (response.code == 1) {
          // showSuccessMessage('Given out successfuly')
          return response;
          // return response.data;

      } else {
           showErrorMessage('Please check the Username');
          return Promise.reject('failed to get sections');
      }
  } catch (error) {
      showErrorMessage('Try after sometimes.');
      return error;
  }
};

export const  userphonenumber = async (phoneNumber:any) => {
  try {
      const fetchResponse =   await fetch(baseURL +'/api/v2/user/signup/phoneNumberExist', {
          method: 'POST',
          headers: {
              'access-token': store.getState().authentication.user.newToken,
              'Accept': 'application/json',
              'Content-type': 'application/json',
          },
          body: JSON.stringify({phoneNumber:phoneNumber})
      });
      const response =   await fetchResponse.json();
      if (response.code == 1) {
          // showSuccessMessage('Given out successfuly')
          return response;
          // return response.data;

      } else {
           showErrorMessage('Please check the Username');
          return Promise.reject('failed to get sections');
      }
  } catch (error) {
      showErrorMessage('Try after sometimes.');
      return error;
  }
};

/**
 ############################################################################
 ############################################################################

               LOGIC FOR PLOTTING POINTS ON MAP  

 ############################################################################
 ############################################################################
 */

function toRad(num) {
  return (num * Math.PI) / 180;
}

function toDeg(num) {
  return (num * 180) / Math.PI;
}

function destinationPoint(lat, long, brng, dist) {
  dist = dist / 6371;
  brng = toRad(brng);

  var lat1 = toRad(lat);
  var lon1 = toRad(long);

  var lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(dist) +
      Math.cos(lat1) * Math.sin(dist) * Math.cos(brng)
  );

  var lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(dist) * Math.cos(lat1),
      Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2)
    );

  if (isNaN(lat2) || isNaN(lon2)) return null;

  // return new google.maps.LatLng(lat2.toDeg(), lon2.toDeg());
  return [toDeg(lat2), toDeg(lon2)];
}

export const findNewCoordinates = (lat, long) => {


  


/**
   * ########################################################################
   ........................................... NEW CODE STARTS.................
   * ########################################################################
    */
  let p1:any = new LatLon(lat, long);
  // let p2:any = p1.destinationPoint(90, 0.02);
  // let p3:any = p1.destinationPoint(360, 0.02);

  var numberOfPoints = 500;
  const angles = [90,360];
  var points = [
    {
      latlngP:p1,
      ID: null,
      addr_name: '',
      location:{
               x: p1?._lon,
        y: p1?._lat
      },
      paid: 1,
      madeBy:360
    }
  ];

   for(let i=1;i<numberOfPoints;i++){

     if(points[i-1].madeBy === 360){

       for(let j=0;j<angles.length;j++){
        let temp_cords = points[i - 1].latlngP.destinationPoint(angles[j],0.02)
        
            let p = {
              latlngP:temp_cords,
      ID: null,
      addr_name: '',
      location:{
        x:temp_cords?._lon,
        y:temp_cords?._lat   
      },
      paid: 0,
      madeBy:angles[j]
    };
    
        points.push(p);
       }

     }else{

      let temp_cords = points[i - 1].latlngP.destinationPoint(90,0.02)

      
          let p = {
            latlngP:temp_cords,
    ID: null,
    addr_name: '',
    location:{
      x:temp_cords?._lon,
        y:temp_cords?._lat   
    },
    paid:0,
    madeBy:90
  };
  
      points.push(p);

     }
   }



  



  /**
   * ########################################################################
   ........................................... NEW CODE ENDS.................
   * ########################################################################
    */
  
  
  // var numberOfPoints = 500;
  // const angles = [90,360];
  // // var points = []
  // var points = [
  //   {
  //          ID: null,
  //     addr_name: '',
  //     location:{
  //       x: long,
  //       y: lat
  //     },
  //     paid: 1,
  //     madeBy:360
  //   }
  // ];

  //  for(let i=1;i<numberOfPoints;i++){

  //    if(points[i-1].madeBy === 360){

  //      for(let j=0;j<angles.length;j++){
  //       let temp_cords = destinationPoint(
  //         points[i - 1].location.y,
  //         points[i - 1].location.x,
  //         angles[j],
  //         0.02
  //       );
  //           let p = {
  //     ID: null,
  //     addr_name: '',
  //     location:{
  //       x:temp_cords[1],
  //       y:temp_cords[0]   
  //     },
  //     paid: 0,
  //     madeBy:angles[j]
  //   };
    
  //       points.push(p);
  //      }

  //    }else{

  //     let temp_cords = destinationPoint(
  //       points[i - 1].location.y,
  //       points[i - 1].location.x,
  //       90,
  //       0.02
  //     );
  //         let p = {
  //   ID: null,
  //   addr_name: '',
  //   location:{
  //     x:temp_cords[1],
  //     y:temp_cords[0]   
  //   },
  //   paid:0,
  //   // paid: i%2 == 0 ? 1 :0,
  //   madeBy:90
  // };
  
  //     points.push(p);

  //    }
  //  }
   
  return points;
}


/**
 ############################################################################
 ############################################################################

               LOGIC FOR PLOTTING POINTS ON MAP  

 ############################################################################
 ############################################################################
 */
