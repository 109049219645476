import promotion1 from './promotion1.JPG';
import promotion2 from './promotion2.JPG';
import promotion3 from './promotion3.JPG';

import promotion1_1 from './promotion1.1.JPG';
import promotion2_1 from './promotion2.1.JPG';
import promotion3_1 from './promotion3.1.JPG';

export const promotionportrait = [
  { id: 1, src: promotion1 },
  { id: 2, src: promotion2 },
  { id: 3, src: promotion3 },
];

export const promotionlandscape = [
  { id: 1, src: promotion1_1 },
  { id: 2, src: promotion2_1 },
  { id: 3, src: promotion3_1 },
];
