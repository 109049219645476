import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import * as treasureCreation from '../../lib/strings/treasureCreation';
import { roleMaster, roleAdmin, roleSuperadmin, sticker } from '../../lib/const';
import lcash from '../../assets/lcash.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ReviewButtons(props) {
  const {t}=useTranslation()
  const { language, treasure, role, onCancel, onReview,Points,Additional_prize} = props;
  const { treasureType, totalNumberOfUnits, pointsPerUnit, totalPrice } = treasure;
  const totalpoints=parseInt(Points)+parseInt(totalPrice)
  return (
    
    <Row className="review-buttons">
      <Col>
        {Number(treasureType) > sticker && role < roleAdmin ? (
          <Row>
            <Col span={12} offset={6}>           
              {Additional_prize=='2'&&
              <Typography.Text className="text-right pricing-text white-txt pt-0 pb-0">
                More Points = <img src={lcash} /> {Points?Points:0}
              </Typography.Text>}            
            
              <Typography.Text className={Additional_prize=='2'?"text-right pricing-text white-txt border-bottom pt-0 pb-0":"text-right pricing-text white-txt"}>
                {/* {!totalNumberOfUnits ? 0 : pointsPerUnit} {t('points in')}{' '} */}
                {totalNumberOfUnits ? totalNumberOfUnits : 0}{' '}
                {t('units')} = <img src={lcash} /> &nbsp; 
                {/* {totalPrice !== NaN ? totalPrice.toFixed(2) : 0} */}
                {isNaN(totalPrice) ? 0 : totalPrice.toFixed()}
                {/* {totalNumberOfUnits ? totalNumberOfUnits : 0}{' '} */}
                {/* {pointsPerUnit? pointsPerUnit:0} {''} */}
              </Typography.Text>
              {Additional_prize=='2'&&
              <Typography.Text className="text-right pricing-text white-txt pt-0 pb-0">
              Total = <img src={lcash} /> {totalpoints?totalpoints:0}
            </Typography.Text>}
            </Col>
          </Row>
        ) : null}

        {/* Buttons at the bottom Cancel Save as draft Next */}
        <Row type="flex" justify="space-around" className="buttons-row">
          <Col>
            <Button
              className="buttons transparent-btn-white treasure-button-width"
              onClick={onCancel}
            >
              <span style={{ textDecoration: 'underline' }}>
                {t('Delete')}
              </span>
            </Button>
          </Col>
          <Col>
            <Button
              className="buttons transparent-btn-white treasure-button-width"
              // onClick={saveAsDraft}
            >
              <span style={{ textDecoration: 'underline' }}>
                {t('Save as draft')}
              </span>
            </Button>
          </Col>
          <Col>
            <Button className="buttons yellow-gradient treasure-button-width" onClick={onReview}>
              {t('Next')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ReviewButtons.propTypes = {
  language: PropTypes.string,
  treasure: PropTypes.object.isRequired,
  role: PropTypes.oneOf([roleMaster, roleAdmin, roleSuperadmin]),
  onCancel: PropTypes.func,
  onReview: PropTypes.func,
  locations: PropTypes.array,
};

ReviewButtons.defaultProps = {
  language: 'EN',
  onCancel: () => {},
  onReview: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  saveAsDraft: () => dispatch(saveAsDraft()),
  

});
const mapStateToProps = (state:any) => ({
  Points: state.Additionalprize.MorePoints,
  Additional_prize:state.Additionalprize.additional_prize


  
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewButtons);
