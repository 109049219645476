import React, { Component, useState, useEffect } from 'react';
import { Row, Button, Col, Spin } from 'antd';
import KitList from './KitList';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { getKits } from '../../../../services/kitService';
import { kitConstants } from '../../../../constants/kitConstants';
import { roleSuperadmin } from '../../../../lib/const';
import { createNewKitButton, seeExchangeRateButton } from '../../../../lib/strings/kitCreation';
import { languages } from '../../../../lib/interfaces/language';
import { Action } from 'redux';

interface Props {
  kitList?: any;
  openKitSubmenu: (menu: string) => Action;
  storeKitList: (kitList: any) => Action;
  role: number;
  needsFetch: boolean;
  fetchAgain: (needsFetch: boolean) => Action;
  language?: languages;
  clear: () => Action;
}

const mapState = (state: {
  kit: any;
  kitList: any;
  userData: { role: number };
  authentication: any;
}) => {
  return {
    kitList: state.kitList,
    role: state.userData.role,
    needsFetch: state.kit.needsFetch,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    openKitSubmenu: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    storeKitList: (kitList: any) => dispatch({ type: kitConstants.STORE_KITS, kitList }),
    fetchAgain: (needsFetch: boolean) => dispatch({ type: kitConstants.FETCH_KITS, needsFetch }),
    clear: () => dispatch({ type: kitConstants.CLEAR_KIT_DATA }),
  };
};

const Kits = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const getKitList = async () => {
    setLoading(true);
    const res =  await getKits();
    if (res) {
      props.storeKitList(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    getKitList();
  }, []);

  const getKitListAgain = async () => {
    setLoading(true);
    const res =  await getKits();
    if (res) {
      props.storeKitList(res);
      props.fetchAgain(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.needsFetch === true) {
      getKitListAgain();
    }
  }, [props.needsFetch]);

  const createNewKit = () => {
    props.clear();
    props.openKitSubmenu('KitCreation');
  };

  return (
    <Row type="flex" justify="center">
      <Col style={{ width: '100%' }}>
        <Row type="flex" justify="center" className="kit-list-btn">
          <Button
            disabled={props.role < roleSuperadmin}
            className="buttons purple-gradient-right menu-button-width"
            onClick={() => createNewKit()}
             >
            {createNewKitButton[props.language]}
          </Button>
        </Row>
        <Row type="flex" justify="center" className="kit-list-btn">
          <Button
            className="buttons exchange-rate-btn menu-button-width"
            onClick={() => props.openKitSubmenu('ExchangeRate')}
          >
            {seeExchangeRateButton[props.language]}
          </Button>
        </Row>
        {!loading ? (
          <KitList kitList={props.kitList} />
        ) : (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Kits);
