import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { Modal, Spin, Row } from 'antd';
import Statistics from './Statistics';
import { statisticsForTreasure } from './apiToTabel';
import { statisticsForTreasureStrings, myAdskit } from '../../../../lib/strings/statistics';
import TreasureDetails from './TreasureDetails';
import closeX from '../../../../assets/closeX.svg';
import * as treasureService from '../../../../services/treasureService';
import { getKits } from '../../../../services/kitService';
import * as kitActions from '../../../../actions/kitActions';
import * as statisticsService from '../../../../services/statisticsService';
import { detailsConstants } from '../../../../constants/treasureDetailsConstants';
import { statisticsForMasters } from '../../../../lib/SubMenuConstants';

function StatisticsForMasters(props) {
  const { language, storeKitList, storeTreasureDetails, username } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [treasureDetails, setTreasureDetails] = useState({});

  const fetchStatisticsForTreasures = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForTreasures(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['StatisticsForTreasure', { ...currentFilters, page: currentPage, username }],
    fetchStatisticsForTreasures,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  const kitQuery = useQuery('kitList', getKits, {
    staleTime: 1800000,
    cacheTime: 3600000,
  });

  useEffect(() => {
    if (kitQuery.status === 'success' && statisticsQuery.data) {
      const parsedStatistics = statisticsForTreasure(
        statisticsQuery.data.statistics,
        kitQuery.data
      );
      setTotal({ totalBalance: statisticsQuery.data.totalBalance });
      setTotalTreasures(statisticsQuery.data.totalTreasures);
      setStatistics(parsedStatistics);
    }
  }, [kitQuery.data, statisticsQuery.data]);

  useEffect(() => {
    if (kitQuery.data) {
      storeKitList(kitQuery.data);
    }
  }, [kitQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  const showDetails = async (treasureId) => {
    setShowModal(true);
    const details =  await treasureService.getTreasureByID(treasureId);
    setTreasureDetails(details.treasure);
    //save to redux!!!!!!!!!
    storeTreasureDetails(details);
  };

  const onCancel = () => {
    setShowModal(false);
    setTreasureDetails({});
  };

  const columns = [
    {
      title: statisticsForTreasureStrings[language].date,
      titleStyle: {},
      dataIndex: 'date',
      dataStyle: {},
    },
    {
      title: statisticsForTreasureStrings[language].treasureName,
      dataIndex: 'treasureName',
      onClick: showDetails,
      dataStyle: { textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' },
    },
    // {
    //   title: statisticsForTreasureStrings[language].username,
    //   dataIndex: 'username',
    // },
    // {
    //   title: statisticsForTreasureStrings[language].country,
    //   dataIndex: 'country',
    //   titleStyle: { textAlign: 'center' },
    //   dataStyle: { textAlign: 'center' },
    // },
    {
      title: statisticsForTreasureStrings[language].kitType,
      dataIndex: 'kitType',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].createdUnits,
      dataIndex: 'createdUnits',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].collectedUnits,
      dataIndex: 'collectedUnits',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].availableUnits,
      dataIndex: 'availableUnits',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].duration,
      dataIndex: 'duration',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].pricePerUnit,
      dataIndex: 'pricePerUnit',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].ldiscountValue,
      dataIndex: 'ldiscountValue',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].unusedLcash,
      dataIndex: 'unusedLcash',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].chargedLcash,
      dataIndex: 'chargedLcash',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForTreasureStrings[language].status,
      dataIndex: 'status',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
  ];

  return (
    <>
      <Statistics
        label={myAdskit[language]}
        columns={columns}
        dataSource={statistics}
        loading={statisticsQuery.isLoading || kitQuery.isLoading}
        statisticsType={statisticsForMasters}
        onFiltersChange={onFiltersChangeHandler}
        onPageChange={onPageChangeHandler}
        totalItems={totalTreasures}
        pageSize={10}
        currentPage={currentPage}
        total={total}
      />
      <Modal
        visible={showModal}
        footer={null}
        centered
        onCancel={onCancel}
        className="resize-modal-to-children"
        bodyStyle={{ width: 'fit-content', padding: '0px' }}
        closeIcon={<img src={closeX} />}
      >
        {isEmpty(treasureDetails) ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ padding: '2em', width: 350, height: 350 }}
          >
            <Spin size="large" spinning={props.isLoading} />
          </Row>
        ) : (
          <TreasureDetails treasureDetails={treasureDetails} language={language} />
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  username: state.userData.username,
});

const mapDispatchToProps = (dispatch) => ({
  storeKitList: (kitList) => dispatch(kitActions.storeKitList(kitList)),
  storeTreasureDetails: (details) => dispatch({ type: detailsConstants.STORE_DETAILS, details }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsForMasters);
