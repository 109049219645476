import React from 'react';
import mapControlIcon from '../assets/map_drag_element.svg';

const RadiusComponent = () => {
  return (
    <div className="radius">
      <img src={mapControlIcon} />
    </div>
  );
};

export default RadiusComponent;
