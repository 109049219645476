import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

class ChatEdit extends React.Component<any, any> {
    static contextType = AppContext;
	
    constructor(props: any) {
        super(props);
        this.state = {
            success: '',
            error: '',
        }
		
    }

    
    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }

    render() {
        const context = this.context;
        return (
            <>
            <Row className="menu-wrapper treasure-menu-wrapper third-dialog ">
				<div className="third-card">
					<div className="fx-jcsb-aic mb-20">
						<h4 className="text-primary mb-0 font-bold"> &#62; Chat</h4>
						<div className="fx-aic">
							<i onClick={this.props.onCloseEdit} className="iconx cursor">
								<img src={closeIcon} alt="Info" />
							</i>
						</div>
					</div>

					<div className="domeAddContainer">
						<div className="group-chat-section">
							<h4>My Picks and joined users</h4>
							<div className="group-chat-setting">
								<h5 className="group-chat-setting__title">
									<span className="mr-3">Group chat with ‘My Picks’ and joined users</span>
									<i><img src={infoIcon} alt="icon" height="14" /></i>
								</h5>
								<div className="fx-jcsb-aic">
									<ul className="group-chat-setting__list">
										<li>
											<label>
												<input type="checkbox" />
												No reply from users via chat
											</label>
										</li>
										<li>
											<label>
												<input type="checkbox" />
												Reply from users via chat
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="group-chat-section">
							<h4>Now</h4>
							<div className="group-chat-setting">
								<h5 className="group-chat-setting__title">
									<span className="mr-3">Group chat with engaged now users</span>
									<i><img src={infoIcon} alt="icon" height="14" /></i>
								</h5>
								<div className="fx-jcsb-aic">
									<ul className="group-chat-setting__list">
										<li>
											<label>
												<input type="checkbox" />
												No reply from users via chat
											</label>
											<button className="ant-btn yellow-gradient xs">Enter</button>
										</li>
										<li>
											<label>
												<input type="checkbox" />
												Reply from users via chat
											</label>
											<button className="ant-btn yellow-gradient xs">Enter</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Row>
           </>
        )
    }
}
export default ChatEdit;
