import { cashExchangeConstants } from '../constants/cashExchangeConstants';

export const initialCashExchange = {
  pendingOrFinished: 'pending',
  selectedUserIndex: null,
};

export function cashExchange(state = initialCashExchange, action) {
  switch (action.type) {
    case cashExchangeConstants.CASH_EXCHANGE_RESET_STATE:
      return initialCashExchange;

    case cashExchangeConstants.CASH_EXCHANGE_EDIT_PARAMETERS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
