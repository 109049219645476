import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Button } from 'antd';
import TreasureList from './TreasureList';
import * as treasureService from '../../../../services/treasureService';
import { connect } from 'react-redux';
import { detailsConstants } from '../../../../constants/treasureDetailsConstants';
import {
  TreasureListRadioButtons,
  TreasureListStatus,
  TreasureListLocationSelector,
} from '../../../../components';
import { draft, inReview, approved, declined, pending, finished } from '../../../../lib/const';
import { isDev } from '../../../../lib/config';
import { roleMaster } from '../../../../lib/const';

interface Props {
  storeTreasureList: (treasureList: any) => void;
  treasureList: any;
  purgeList: () => void;
  resetTreasureList: () => void;
  language: string;
  role: number;
}

const mapState = (state: any) => {
  const { treasureList, userData, authentication } = state;
  return {
    treasureList,
    role: userData.role,
    language: authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    storeTreasureList: (treasureList: Array<any>) =>
      dispatch({ type: detailsConstants.STORE_LIST, treasureList }),
    purgeList: () => dispatch({ type: detailsConstants.DELETE_LIST }),
    resetTreasureList: () => dispatch({ type: detailsConstants.PURGE_DATA }),
  };
};

const selectedStatusToServerStatus = (status: string) => {
  switch (status) {
    case 'all':
      return 'all';
    case 'running':
      return approved;
    case 'draft':
      return draft;
    case 'review':
      return inReview;
    case 'pending':
      return pending;
    case 'finished':
      return finished;
    default:
      return 'all';
  }
};

const Treasures = (props: Props) => {
  const { language, role, treasureList, resetTreasureList, storeTreasureList } = props;

  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');

  useEffect(() => {
    resetTreasureList();
    fetchTreasureList(selectedStatus, 1);
    return () => {
      resetTreasureList();
    };
  }, []);

  const fetchTreasureList = async (status: string, page) => {
    setLoading(true);
    const fetchTreasures =  await treasureService.getCreatedTreasurePaging(
      selectedStatusToServerStatus(status),
      page
    );
  
    storeTreasureList(fetchTreasures);   
    setLoading(false);
  };

  const handleLoadMore = () => {
    fetchTreasureList(selectedStatus, treasureList.currentPage + 1);
  };
  const thereIsMoreToLoad = treasureList?.treasureLength > treasureList?.currentPage * 100;

  const handleOnChangeListStatus = (value: string) => {
    // to reduce unnecessary fetch
    if (value !== selectedStatus) {
      setLoading(true);
      resetTreasureList();
      fetchTreasureList(value, 1);
      setSelectedStatus(value);
    }
  };

  return (
    <Row>
      <Row type="flex" justify="start" className="scrollable-parent">
        <Col className="scrollable-child">
          <TreasureListRadioButtons
            role={role}
            // defaultValue={'Country'}
            onChange={(value) => {
            }}
            language={language}
            visible={isDev && role > roleMaster} // remove isDev when done
          />
          <TreasureListLocationSelector
            onChange={(value) => {
            }}
            list={['Zagreb', 'Rijeka']}
            visible={isDev && role > roleMaster} // remove isDev when done
          />
          <TreasureListStatus
            onChange={handleOnChangeListStatus}
            defaultValue={selectedStatus}
            language={language}
          />
        </Col>
      </Row>

      {/* treasure list */}
      <Row className="treasure-list">
        <Col>
          {treasureList.treasureData && <TreasureList treasureList={treasureList.treasureData} />}
        </Col>
      </Row>

      {/* load more button and loading spinner */}
      <Row type="flex" justify="space-around" className="buttons-row">
        {!loading ? (
          thereIsMoreToLoad && (
            <Button className="transparent-btn" onClick={handleLoadMore} style={{ margin: 'auto' }}>
              Load more
            </Button>
          )
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              marginBottom: '50px',
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </Row>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Treasures);
