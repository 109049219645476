import React from 'react';
import { Row, Checkbox, Typography, Col } from 'antd';
import { baseURL } from '../../../../lib/config';

interface Props {
  ID: number;
  username: string;
  avatar: any;
}

const User = (props: Props) => {
  return (
    <Row type="flex" justify="space-between" style={{ margin: '1em 0' }}>
      <Col className="user-list-item">
        <div
          style={{
            backgroundImage: `url(${baseURL}/uploads/${props.avatar?.picture?.nameOnServer})`,
          }}
        />
        <Typography.Text className="bold">{props.username}</Typography.Text>
      </Col>
      <Col>
        <Checkbox className="checkmark" value={props.ID} />
      </Col>
    </Row>
  );
};

export default User;
