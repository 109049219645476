import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Map from './Map';
import UserMenu from './menu/UserMenu';
import { Row, notification } from 'antd';
import { userConstants } from '../../constants/signinConstants';
import SubMenu1 from './SubMenu1';
import SubMenu2 from './SubMenu2';
import SubMenuFullScreen from './SubMenuFullScreen';
import { verifyPayment, getQueryParameterByName } from '../../services/paymentService';
import { treasureConstants } from '../../constants/treasureConstants';
import { languages } from '../../lib/interfaces/language';
import * as dashboardStrings from '../../lib/strings/dashboard';
import { Action } from 'redux';
import { getUserData } from '../../services/signinService';
import { LOCAL_STORAGE_USERDATA } from '../../lib/config';
import { executeSignOut } from '../../services/sharedServices';
import Geocode from 'react-geocode';
import { isDev } from '../../lib/config';
import {b} from '../dashboard/menu/i18next'

const mapState = (state: any) => {
  const {
    authentication,
    userData,
    isDetailsUIOpen,
    isContestFormUIOpen,
    isContestDetailsUIOpen,
    isContestantsScreenOpen,
    isContestantVideoScreenOpen,
    isSubMenu1Open,
    isSubMenu2Open,
    isSubMenuFullScreenOpen,
    isPaymentInitiated,
  } = state;
  const { user, language } = authentication;
  return {
    user,
    userData,
    isDetailsUIOpen,
    isContestFormUIOpen,
    isContestDetailsUIOpen,
    isContestantsScreenOpen,
    isContestantVideoScreenOpen,
    isSubMenu1Open,
    isSubMenu2Open,
    isPaymentInitiated,
    language,
    isSubMenuFullScreenOpen,
  };
};

const mapDispatch = (dispatch: (arg0: { type: string; user?: any }) => any) => {
  return {
    storeUserData: (user: any) => dispatch({ type: userConstants.STORE_USER_DATA, user }),
    clearPaymentFlag: () => dispatch({ type: treasureConstants.PAYMENT_COMPLETE }),
    toggleLanguage: () => dispatch({ type: userConstants.TOGGLE_LANGUAGE }),
    dispatch,
  };
};

interface DashboardProps extends RouteComponentProps {
  user: any;
  userData: any;
  isDetailsUIOpen: boolean;
  isClientsUIOpen: boolean;
  isContestFormUIOpen: boolean;
  isContestDetailsUIOpen: boolean;
  isContestantsScreenOpen: boolean;
  isContestantVideoScreenOpen: boolean;
  getUserData: () => Action;
  saveImgPath: () => Action;
  storeUserData: (user: any) => Action;
  isSubMenu1Open: boolean;
  isSubMenu2Open: boolean;
  isSubMenuFullScreenOpen: boolean;
  isPaymentInitiated: boolean;
  clearPaymentFlag: () => Action;
  language: languages;
  logout: () => Action;
  dispatch: any;
  toggleLanguage: any;
}

const Dashboard = (props: DashboardProps) => {
  const { userData, storeUserData, toggleLanguage } = props;

  const [isValidToken, setIsValidToken] = useState(false);

  useEffect(() => {
    checkValidityAndPayments();
    const { isPaymentInitiated, clearPaymentFlag } = props;

    const updateUserData = async () => {
      let newUserData =   await getUserData(userData._id);
      newUserData = { ...newUserData, refetch: false };
      storeUserData(newUserData);
      localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify(newUserData));
    };

    const lang= localStorage.getItem('lang')
    const statusNameToApi = (lang) => {
      switch (lang) {
        case 'en':
          return 'en';
        case 'ko':
          return 'ko';
          default:
            return 'en';
      }
    };
    const checkPaymentValidity = async () => {
      const currentUrl = window.location.href;
           document.body.classList.add('notification-open');
     if (/session_id/.test(currentUrl)) {
        const session_id_value = getQueryParameterByName('session_id', currentUrl);
                const response =   await verifyPayment(session_id_value);
                
        if (response.t_type === 'voucher_bundle') {
            notification.success({
            message: (b?.t('notificationCoupon')),
            onClose: () => {
              document.body.classList.remove('notification-open');
            },
            //  description: dashboardStrings.paymentMessageDetails[props.language],
            
           });
         } 
         else if (response.t_type === 'LCash') {
          notification.success({
              message: (b?.t('notificationLcash')),
          })
        }
         else {
          notification.error({
            message: (b?.t('notificationfeild')),
          });
         }
         clearPaymentFlag();
        
        
        } else {
        if (/paymentfailed=true/.test(currentUrl)) { 
          notification.error({
            message:(b?.t('notificationCancel')),
          });
                }
        clearPaymentFlag();
      }
    
      let langCheck=statusNameToApi(lang);
      setTimeout(()=>{
        window.location.reload()
        // if(langCheck==='ko'){
        //   window.location.href='/dashboard?ko';
        // }
      },2000)
    
     };

    if (isPaymentInitiated) {
      checkPaymentValidity();
    }
    // update userData on balanceUpdate
    if (userData.refetch) {
      updateUserData();
    }

    Geocode.setApiKey('AIzaSyAC7FC5wCXyfRRFiA5b3zQ1hF85iz_8EM4');
    }, []);

  const checkValidityAndPayments = async () => {
    if (props.user?.newToken) {
      setIsValidToken(true);
    } else {
      setIsValidToken(false);
      executeSignOut();
    }

    // if (isValidToken) {
    //   if (props.isPaymentInitiated) {
    //     const getURLPaymentPattern = window.location.href;
    //     console.log(getURLPaymentPattern);
    //     if (getURLPaymentPattern.includes('LOCAL_id=')) {
    //       const LOCALID = getURLPaymentPattern.split('=');
    //       const res =   await verifyPayment(LOCALID[1]);
    //       if (res.data.transactionSucceeded === 'true') {
    //         notification.success({
    //           message: dashboardStrings.paymentMessage[props.language],
    //           description: dashboardStrings.paymentMessageDetails[props.language],
    //         });
    //         props.clearPaymentFlag();
    //       } else {
    //         notification.error({
    //           message: dashboardStrings.paymentFailed[props.language],
    //         });
    //         props.clearPaymentFlag();
    //       }
    //     } else {
    //       notification.error({
    //         message: dashboardStrings.paymentFailed[props.language],
    //       });
    //       props.clearPaymentFlag();
    //     }
    //   }
    // }
  };

  if (!props.user) {
    return <Redirect to="/" />;
  }

  return (
    
    <div>
      <Row type="flex" justify="start">
        {!props.isSubMenuFullScreenOpen && <UserMenu />}
        {props.isSubMenu1Open ? <SubMenu1 /> : null}
        {props.isSubMenu2Open ? <SubMenu2 /> : null}
        {props.isSubMenuFullScreenOpen && <SubMenuFullScreen />}
      </Row>
      {isValidToken ? <Map isValidToken={isValidToken} /> : null}

      {/* {isDev && (
        <button
          style={{
            zIndex: 1000,
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'transparent',
            border: 'none',
          }}
          onClick={toggleLanguage}
        >
          EN/KO
        </button>
      )} */}
    </div>
    
  );
};

export default connect(mapState, mapDispatch)(Dashboard);
