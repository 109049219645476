export const kitCreation = 'KitCreation';
export const kitDetails = 'KitDetails';
export const exchangeRate = 'ExchangeRate';
export const recharge = 'Recharge';
export const exchangeRateByCountry = 'ExchangeRateByCountry';
export const kitPricesByCountry = 'KitPricesByCountry';
export const treasureClients = 'TreasureClients';
export const treasureDetails = 'TreasureDetails';

export const contestForm = 'ContestForm';
export const contestDetails = 'ContestDetails';
export const contestants = 'Contestants';
export const contestVideo = 'contestVideo';
export const contestantDetails = 'ContestantDetails';
export const contestantsReview = 'ContestantsReview';
export const contestantReviewDetails = 'ContestantReviewDetails';
export const editIdolBanner = 'EditIdolBanner';

export const notificationForm = 'NotificationForm';
export const searchUsers = 'SearchUsers';
export const notificationDetails = 'NotificationDetails';
export const searchContesants = 'SearchContesants';
export const adMaker = 'AdMaker';
export const characterForm = 'CharacterForm';
export const characterDetails = 'CharacterDetails';

export const statistics = 'statistics';
export const statisticsForUsers = 'StatisticsForUsers';
export const statisticsForTreasure = 'StatisticsForTreasure';
export const statisticsForPoints = 'StatisticsForPoints';
export const statisticsForCoins = 'StatisticsForCoins';
export const statisticsForVoucher = 'StatisticsForVoucher';

export const statisticsForCoupon = 'StatisticsForCoupon';
export const statisticsForAssignment = 'StatisticsForAssignment';


export const statisticsForPartners = 'StatisticsForPartners';
export const statisticsForMasters = 'StatisticsForMaster';
export const statisticsForDomeStats = 'StatisticsForDomeStats';
export const statisticsForPlums = 'StatisticsForPlums';
//   export const statisticsForcreated = 'statisticsForcreated ';

export const candyEventDetails = 'CandyEventDetails';
export const candyEventContestantDetails = 'CandyEventContestantDetails';

export const cashExchangeRequest = 'CashExchangeRequest';
export const cashExchangeDetails = 'CashExchangeDetails';

export const partnerRequests = 'PartnerRequests';
export const partnerRequestsDetails = 'PartnerRequestsDetails';

export const userDetails = 'UserDetails';
