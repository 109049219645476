import product1 from './product1.JPG';
import product2 from './product2.JPG';
import product3 from './product3.JPG';

import product1_1 from './product1.1.JPG';
import product2_1 from './product2.1.JPG';
import product3_1 from './product3.1.JPG';

export const productportrait = [
  { id: 1, src: product1 },
  { id: 2, src: product2 },
  { id: 3, src: product3 },
];

export const productlandscape = [
  { id: 1, src: product1_1 },
  { id: 2, src: product2_1 },
  { id: 3, src: product3_1 },
];
