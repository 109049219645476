import React from 'react';
import { Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import DetailsGrid from '../../candyEvent/DetailsGrid';
import ButtonWithSpinner from '../../ButtonWithSpinner';
import * as statisticsStrings from '../../../lib/strings/statistics';

function MarkAsPaidModal(props) {
  const { language, visible, onCancel, onPay, data } = props;

  const markAsPaidHandler = () => {
    onPay(data.ID);
    onCancel();
  };
  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      onCancel={onCancel}
      className="resize-modal-to-children"
      bodyStyle={{ padding: '3em 0em 0em 0em' }}
    >
      <Row className="partners-modal-container">
        <Row className="cashExchange-contestant-details">
          <DetailsGrid
            title={statisticsStrings.usernamePopup[language]}
            titleSpan={10}
            value={data.partnerUsername}
          />
          <DetailsGrid
            title={statisticsStrings.beneficiaryName[language]}
            titleSpan={10}
            value={data.beneficiaryName}
          />
          <DetailsGrid
            title={statisticsStrings.bankName[language]}
            titleSpan={10}
            value={data.bankName}
          />
          <DetailsGrid
            title={statisticsStrings.accountNumber[language]}
            titleSpan={10}
            value={data.partnerBankAccount}
          />
          <DetailsGrid
            title={statisticsStrings.abaNumber[language]}
            titleSpan={10}
            value={data.ABANumber}
          />
          <DetailsGrid
            title={statisticsStrings.partnersShare[language]}
            titleSpan={10}
            value={`${data.partnerShare}$`}
          />
          <DetailsGrid
            title={statisticsStrings.email[language]}
            isEmail={true}
            titleSpan={10}
            value={data.email}
          />
        </Row>
        <Row type="flex" justify="center" style={{ marginTop: '3em', marginBottom: '2em' }}>
          <ButtonWithSpinner
            className="buttons yellow-gradient cashExchange-send-notification-button"
            label={statisticsStrings.markAsPaid[language]}
            onClick={markAsPaidHandler}
          />
        </Row>
      </Row>
    </Modal>
  );
}

MarkAsPaidModal.propTypes = {
  language: PropTypes.string,
  visible: PropTypes.bool,
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onPay: PropTypes.func,
};

MarkAsPaidModal.defaultProps = {
  language: 'EN',
  data: {},
  onPay: () => {
    console.log('missing logic!');
  },
};

export default MarkAsPaidModal;
