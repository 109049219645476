import React, { Component } from "react";
import { Row, Spin } from "antd";
import AppContext from "../../../../AppContext";
import moment from 'moment';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../../src/assets/location-on-map.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import SettingReviewDetail from "./settingReviewDetails";
import { getNameReview } from '../../../../services/domeService';

class SettingReview extends Component<any, any> {
	static contextType = AppContext;
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			nameReqs: []
		}
	}


	componentDidMount = async () => {
		this.setState({
			loader: true
		})
		const nameReqs =  await getNameReview();
		this.setState({
			loader: false
		})
		this.setState({
			nameReqs: nameReqs
		});
	}

	edit = async () => {
		this.setState({
			edit: true,
		});

	}

	close = async () => {
		let profile =  await this.getContext();
		profile['setting_review'] = false;
		 await this.updateContext(profile);
	}

	closeDetails = async () => {
		this.setState({
			edit: false,
		});
	}

	getContext = async () => {
		const context = this.context;
		let profile = context.profile || {};
		return profile;
	}
	updateContext = async (data: any) => {
		const context = this.context;
		context.setProfile(data);
	}

	render() {
		const { nameReqs, loader } = this.state;
		let markUp = !loader ? (nameReqs.length > 0 ? nameReqs.map((resp, index) => (
			<div className="let-long-loop domeAddLoop " key={index}>
				<div className="let-long-loop__left">
					<div className="let-long-loop__left__row mb-10">
						<div className="let-long-loop__left__row__info">
							<h3 className="font-bold size14 mb-0">
								{resp.addr_name} <span className="text-muted">@</span>{resp.dome_name}
							</h3>
							<div className="let-long-loop__left__row__info__row">
								<i className="let-long-loop__left__row__icon">
									<img src={locationIcon} alt="Location" />
								</i>
								<div className="let-long-loop__left__row__info__row__info size10">
									<div className="mr-10">
										<p className="mb-0">Latitude</p>
										<p className="mb-0">{resp.location.y}</p>
									</div>
									<div className="mb-0">
										<p className="mb-0">Longitude</p>
										<p className="mb-0">{resp.location.x}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="let-long-loop__right">
					<div className="fx-jcsb-aic">
						<div className="size12">
							<p className="mb-3 text-error">Submitted on {moment(resp.name_submitted).format('DD/MM/YYYY')} <img src={editIcon} onClick={(e) => this.getDetail(e, resp.ID)} className="cursor" alt="icon" height="14" /></p>
							<p>Dome Name status: <span className={resp.dome_status === 1 ? 'text-success' : 'text-danger'}>{resp.dome_status === 0 ? 'Pending' : 'Approved'}</span></p>
						</div>
					</div>
				</div>
			</div>
		)) : <div className="fx-jcc">No Data Found</div>) : <Spin size="large" style={{ marginTop: '2em', marginLeft: '14em' }} />;

		return (
			<>
				<Row className="menu-wrapper treasure-menu-wrapper second-dialog">
					<div className="second-card relative">
						<div className="fx-jcsb-aic mb-20">
							<h4 className="text-primary mb-0 font-bold">&#62; Setting Review</h4>
							<div className="fx-aic">
								<i onClick={this.close} className="iconx cursor">
									<img src={closeIcon} alt="Info" />
								</i>
							</div>
						</div>
						<div className="domeAddContainer scrollSection">
							{markUp}
						</div>
					</div>
				</Row>
				{this.state.edit &&
					< SettingReviewDetail
						close={this.closeDetails}
					/>
				}
			</>
		)
	}
}

export default SettingReview;