import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import { roleAdmin, roleMaster } from '../../lib/const';
import PropTypes from 'prop-types';
import { world, country, state, city } from '../../lib/strings/treasureCreation';

function TreasureListRadioButtons(props) {
  const { defaultValue, onChange, role, language, visible } = props;

  if (!visible) {
    return null;
  }

  // master dont have this options
  if (role < roleAdmin) {
    return null;
  }

  const radioButtons = [
    { name: country[language], value: 'country' },
    { name: state[language], value: 'state' },
    { name: city[language], value: 'city' },
  ];
  if (role > roleAdmin) {
    radioButtons.unshift({ name: world[language], value: 'world' });
  }

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const buttonHandler = (value) => () => setCurrentValue(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  return (
    <Row type="flex" justify="space-between" className="treasure-list-radio-buttons">
      {radioButtons.map((element) => {
        return (
          <Col key={element.value}>
            <Button
              className={
                element.value === currentValue
                  ? 'treasure-list-buttons-selected'
                  : 'treasure-list-buttons'
              }
              onClick={buttonHandler(element.value)}
            >
              {element.name}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
}

TreasureListRadioButtons.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  role: PropTypes.number,
  language: PropTypes.string,
  visible: PropTypes.bool,
};

TreasureListRadioButtons.defaultProps = {
  defaultValue: 'city',
  onChange: () => {},
  role: roleMaster,
  language: 'EN',
  visible: true,
};

export default TreasureListRadioButtons;
