import React, { useEffect, useState } from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';

import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

const ChatNow = ({ closeNowChat, selectedUserForChat }) => {
  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold"> &#62; Now</h4>
            <div className="fx-aic">
              <div className="size12 text-primary">
                Status: <span>No reply from users via chat</span>
              </div>
              <i onClick={closeNowChat} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="chat-loop-container">
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
              <div className="date-separator">
                <span className="date-separator__date">2021.5.2</span>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
              </div>
              <div className="chat-loop">
                <figure className="chat-loop__fig">
                  <img src={burgerKingTrans} alt="logo" />
                </figure>
                <div className="chat-loop__msg">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Nisi repudiandae inventore doloribus? Voluptates tempora
                    soluta
                  </p>
                </div>
              </div>
            </div>

            <div className="reply-section">
              <label className="reply-section__upload">
                <input type="file" />+
              </label>
              <div className="reply-section__type">
                <input type="text" name="" id="" className="form-control" />
              </div>
              <button
                // onClick={onSendMeesageClick}
                className="reply-section__submit"
              >
                &nbsp;
              </button>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
  // }
};

export default ChatNow;
