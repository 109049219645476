import { LanguageMap } from '../interfaces/language';

export const treasureErrMsg: LanguageMap<string> = {
  EN: 'Error while creating treasure',
  KO: 'Treasure 제작과정에서 오류가 발생하였습니다',
};

export const treasureErrDesc: LanguageMap<string> = {
  EN: 'Error while creating treasure',
  KO: 'Treasure 제작과정에서 오류가 발생하였습니다',
};

export const kitErrMsg: LanguageMap<string> = {
  EN: 'Error while fetching kits',
  KO: 'Kit를 불러오는데 실패하였습니다',
};

export const charErrMsg: LanguageMap<string> = {
  EN: 'Error while fetching characters',
  KO: '캐릭터를 불러오는데 실패하였습니다',
};

export const locErrMsg: LanguageMap<string> = {
  EN: 'Error while creating location',
  KO: '위치를 생성하는데 실패하였습니다',
};

export const signInError: LanguageMap<string> = {
  EN: 'Sign in Failed',
  KO: '회원가입에 실패하였습니다',
};

export const positionErr: LanguageMap<string> = {
  EN: 'Position already taken!',
  KO: '누군가 이미 사용중입니다',
};

export const fileErr: LanguageMap<string> = {
  EN: 'Wrong file! ',
  KO: '파일이 올바르지 않습니다',
};

export const errMsg: LanguageMap<string> = {
  EN: 'Error',
  KO: '오류',
};

export const paymentSessionErr: LanguageMap<string> = {
  EN: 'Failed to get payment session!',
  KO: '결제 화면을 불러오는데 실패하였습니다',
};

export const fileUploadErr: LanguageMap<string> = {
  EN: 'Failed to upload file',
  KO: '파일 업로드에 실패하였습니다',
};

export const filesUploadErr: LanguageMap<string> = {
  EN: 'Failed to upload files',
  KO: 'Failed to upload files',
};

export const serverError: LanguageMap<string> = {
  EN: 'Server error:',
  KO: 'Server error:',
};

export const insufficientFunds: LanguageMap<string> = {
  EN: 'Insufficient funds',
  KO: 'Insufficient funds',
};

export const responseCode: LanguageMap<string> = {
  EN: 'Response code:',
  KO: 'Response code:',
};
