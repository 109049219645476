import React from 'react';
import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import burgerKing from '../../../../../src/assets/burgerKing.png';
import closeWhite from '../../../../../src/assets/closeWhite.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import {b} from '../../../dashboard/menu/i18next'
import {
  FileUpload,
  getAllHallSectionIamges,
  getDeleteHallImage,
  getDeleteHallSection,
  getDomeTypes,
  importDomeEditing,
  PublishImageForHallWithSections,
  updateImageForHallWithSections,
} from '../../../../services/domeService';
import ReactTooltip from 'react-tooltip';
import { baseURL } from '../../../../lib/config';
import { Translation } from 'react-i18next';

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const errorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class DomeHall extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      thisTime: true,
      color: this.props.color ? this.props.color : '',
      addr_name: '',
      loadAgain: false,
      sectionsData: [
        {
          id: 1,
          subSections: [
            {
              id: 'section1Child1',
              file: null,
              url: '',
            },
            {
              id: 'section1Child2',
              file: null,
              url: '',
            },
            {
              id: 'section1Child3',
              file: null,
              url: '',
            },
            {
              id: 'section1Child4',
              file: null,
              url: '',
            },
          ],
        },
      ],
      childItemCount: 4,
      sectionsCount: 1,
    };
  }

  roundToNearestNumber(n) {
    if (n > 0) return Math.ceil(n / 4.0) * 4;
    else if (n < 0) return Math.floor(n / 4.0) * 4;
    else return 4;
  }

  callGetDataApi = async () => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const res = await getAllHallSectionIamges(id);
    let final_result = [];
    let subsections_left = [];

    for (let i = 0; i < res.length; i++) {
      let curr_section = res[i].section;
      if (!final_result[curr_section - 1]) {
        final_result[curr_section - 1] = {};
      }
      if (final_result[curr_section - 1].subSections) {
        final_result[curr_section - 1] = {
          ...final_result[curr_section - 1],
          id: res[i].id,
          section: res[i].section,
          subSections: [
            ...final_result[curr_section - 1].subSections,
            {
              id: res[i].file.id,
              url: `${baseURL}/uploads/${res[i].file.id}`,
              file: null,
              deleteId: res[i].id,
            },
          ],
        };
      } else {
        final_result[curr_section - 1] = {
          ...final_result[curr_section - 1],
          id: res[i].id,
          section: res[i].section,
          subSections: [
            {
              id: res[i].file.id,
              url: `${baseURL}/uploads/${res[i].file.id}`,
              file: null,
              deleteId: res[i].id,
            },
          ],
        };
      }
    }

    for (let i = 0; i < final_result.length; i++) {
      let items_length = final_result[i]?.subSections?.length || 0;
      let num = this.roundToNearestNumber(items_length);
      subsections_left.push(num - items_length);
    }

    for (let i = 0; i < final_result.length; i++) {
      for (let j = 0; j < subsections_left[i]; j++) {
        if (final_result[i]) {
          final_result[i].subSections.push({
            id: `${b?.t('section')}${i + 1}child${j + 1}`,
            url: '',
            file: null,
          });
        } else {
          final_result[i] = {};
          final_result[i].subSections = [];
          final_result[i].subSections.push({
            id: `${b?.t('section')}${i + 1}child${j + 1}`,
            url: '',
            file: null,
          });
        }
      }
    }

    this.setState({
      sectionsData: final_result,
      sectionsCount: final_result.length,
      childItemCount: 1000,
    });
  };

  componentDidMount(): void {
    this.callGetDataApi();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (this.state.loadAgain) {
      this.callGetDataApi();
      this.setState({
        loadAgain: false,
      });
    }
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  inputHandler = (e: any) => {
    this.setState({
      addr_name: e.target.value,
    });
    if (e.target.value) {
      this.setState({ thisTime: false });
    } else {
      this.setState({ thisTime: true });
    }
  };

  importImageUrl = async (e: any) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const addr_name = profile.edit_current_dome.addr_name.trim();
    const import_addr_name = this.state.addr_name.trim();
    if (addr_name == import_addr_name) {
      errorMessage(
        b?.t('Import dome address name should not be same as current dome address name')
      );
      return false;
    }
    const type = 1;
    let importImageUrlData = await importDomeEditing(
      id,
      this.state.addr_name,
      type,
      this.state.color
    );
  };

  onAddWholeSectionClick = () => {
    let temp_sections = [...this.state.sectionsData];
    temp_sections = [
      ...temp_sections,
      {
        id: this.state.sectionsCount + 1,
        subSections: [
          {
            id: `${b?.t('section')}${this.state.sectionsCount + 1}child${
              this.state.childItemCount + 1
            }`,
            file: null,
            url: '',
          },
          {
            id: `${b?.t('section')}${this.state.sectionsCount + 1}child${
              this.state.childItemCount + 2
            }`,
            file: null,
            url: '',
          },
          {
            id: `${b?.t('section')}${this.state.sectionsCount + 1}child${
              this.state.childItemCount + 3
            }`,
            file: null,
            url: '',
          },
          {
            id: `${b?.t('section')}${this.state.sectionsCount + 1}child${
              this.state.childItemCount + 4
            }`,
            file: null,
            url: '',
          },
        ],
      },
    ];
    this.setState({
      sectionsData: temp_sections,
      sectionsCount: this.state.sectionsCount + 1,
      childItemCount: this.state.childItemCount + 4,
    });
  };

  onAddSubSectionClick = (id) => {
    let temp_sections = [...this.state.sectionsData];
    
    temp_sections = temp_sections.map((item) => {
      if (item.id === id) {
        item = {
          ...item,
          subSections: [
            ...item.subSections,
            {
              id: `${b?.t('section')}${id}child${this.state.childItemCount + 1}`,
              file: null,
              url: '',
            },
            {
              id: `${b?.t('section')}${id}child${this.state.childItemCount + 2}`,
              file: null,
              url: '',
            },
            {
              id: `${b?.t('section')}${id}child${this.state.childItemCount + 3}`,
              file: null,
              url: '',
            },
            {
              id: `${b?.t('section')}${id}child${this.state.childItemCount + 4}`,
              file: null,
              url: '',
            },
          ],
        };
      }
      return item;
    });

    this.setState((prevState) => {
      return {
        sectionsData: temp_sections,
        sectionsCount: prevState.sectionsCount + 1,
        childItemCount: prevState.childItemCount + 4,
      };
    });
  };

  onFileUpload = async (e, parentId, childId, secInd, uploadId) => {  
    e.persist();
    console.log(uploadId);

    let temp_sections = [...this.state.sectionsData];
    if (temp_sections && temp_sections.length > 0) {
      let imagesCount = 0;
      for (let i = 0; i < temp_sections.length; i++) {
        for (let j = 0; j < temp_sections[i].subSections.length; j++) {
          imagesCount++;
        }
      }    
      if (imagesCount > 50) {
        return errorMessage(b?.t('cannot upload more than 50 images', ''));
      }
    }
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;

    const res = await FileUpload(e.target.files[0]);
    if (res && res.file) {
      if (uploadId) {
        await updateImageForHallWithSections(id, {
          file_id: uploadId,
          file: res.file,
        });
      } else {
        await PublishImageForHallWithSections(id, {
          section: secInd + 1,
          file: res.file,
        });
      }

      const domeSetting = await getDomeTypes(id);

      profile.domeTypeSetting = domeSetting.dome_type_setting;
      await this.updateContext(profile);
    }
    var readerIcon = new FileReader();
    // eslint-disable-next-line
    var url = readerIcon.readAsDataURL(e.target.files[0]);
    readerIcon.onloadend = (ev) => {
      for (let i = 0; i < temp_sections.length; i++) {
        for (let j = 0; j < temp_sections[i].subSections.length; j++) {
          if (temp_sections[i].id === parentId) {
            if (temp_sections[i].subSections[j].id === childId) {
              temp_sections[i].subSections[j].file = e.target.files[0];
              temp_sections[i].subSections[j].url = readerIcon.result;
            }
          }
        }
      }
      this.setState({
        sectionsData: temp_sections,
        loadAgain: true,
      });
    }; 
  };
 
  onImageRemove = async (parentId) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    await getDeleteHallImage(id, parentId);
    this.setState({
      loadAgain: true,
    });
  };

  onDeleteWholeSection = async (e, data) => {
    // console.log(data);
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    await getDeleteHallSection(id, data.section);
    this.setState({
      loadAgain: true,
    });
  };

  publish = () => {
    showSuccessMessage(b?.t('Saved Successfully'));
  };

  render() {
    const context = this.context;

    return (
      <div id="tab-3" className="tab-pane ">
        {/* <div className="mb-10">
					<i className="blueBox mr-5"></i>
					<span className="text-primary mr-5">Arena editing</span>
					<i><img src={infoIcon} alt="Info" height="14" /></i>
				</div>
				<div className="grid-row sm mb-10">
					<div className="col col-1">&nbsp;</div>
					<div className="col col-11 border-bottom pb-10">
						<div className="fx-aic mb-10">
							<span className="size12 mr-5">Import</span>
							<input type="text" value="Dome Address to import" className="form-control xs" />
							<input type="text" value="" className="form-control xs ml-5" />
							<button className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
						</div>
						<div className="fx-aic">
							<span className="size12 mr-5">Not at this time</span>
							<input type="checkbox" />
						</div>
					</div>
				</div>*/}

        <div className="hall-tab">
          <div className="mb-10">
            <i className="blueBox mr-5"></i>
            <span className="text-primary mr-4">{b?.t("Hall")}
            {  localStorage.getItem("dome_type")=="1" ? "(20)": "(16)"}
              {/* {localStorage.getItem("type")=="1"?"(20)":"14" }   */}
             </span>
            {/* <i><img src={infoIcon} alt="Info" height="14" /></i> */}
            <div className="infoTip">
              <span className="infoTip__icon" data-for="soclose" data-tip="8">
                &nbsp;
              </span>
              <ReactTooltip
                id="soclose"
                getContent={(dataTip) => (
                  <div>
                    <p>
                    {b?.t("Make it as NFT gallery, an art gallery , a privately owned contents gallery, or a memorial gallery.")}  
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place={'right'}
                border={true}
                type={'light'}
              />
            </div>
          </div>

          {/* <div className="grid-row border-bottom pb-10">
						<div className="col col-2"></div>
						<div className="col col-10">
							<div className="grid-row sm">
								<div className="col col-2">
									<h4 className="text-primary">Import</h4>
								</div>
								<div className="col col-10">
									<label>Dome Name to import</label>
									<div className="fx-jcsb-aic mb-5">
										<input placeholder="Enter Dome Name" onChange={this.inputHandler} type="text" value={this.state.addr_name} className="form-control xs ml-5" />
										<button onClick={this.importImageUrl} className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
									</div>
									<div className="fx-aic">
										<span className="size12 mr-5">Not at this time</span>
										<input type="checkbox" checked={this.state.thisTime} />
									</div>
								</div>
							</div>
						</div>
					</div> */}  
                
          <div style={{ textAlign: 'right', fontWeight: 600 }}>
            
           {b?.t("File upload: jpg, jpeg, png, gif, pdf")} 
          </div>
          <div>
            {this.state.sectionsData &&
              this.state.sectionsData.map((section, i) => {
                const { subSections, id: parentId } = section;
                return (
                  <div
                    key={parentId}
                    style={{
                      border: '1px solid #CED4DA',
                      padding: '8px',
                      marginBottom: '10px',
                    }}
                    className="section-loop-container"
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '50%' }}>
                        <h4>{`${b?.t('section')}${i + 1}`}</h4>
                      </div>
                      <div style={{ textAlign: 'right', width: '50%' }}>
                        {' '}
                        <button
                          onClick={(e) => this.onDeleteWholeSection(e, section)}
                          className="iconx xs"
                        >
                          <img src={deleteIcon} alt="Delete" height="14" />
                        </button>
                      </div>
                    </div>
                    <div className="grid-row sm">
                      {subSections &&
                        subSections.map((sub_sec, childIndx) => {
                          const { id: childId, url, deleteId } = sub_sec;
                          return (
                            <div
                              key={childId}
                              className="col col-3 section-loop"
                            >
                              <figure className="section-loop__fig">
                                <img
                                  onClick={() =>
                                    this.props.onHallImagePreviewClick(url)
                                  }
                                  src={url}
                                  alt="pic"
                                />
                              </figure>
                              <label className="ant-btn primary-ghost-btn xs btn-block">
                                <input
                                  onChange={(e) =>
                                    this.onFileUpload(
                                      e,
                                      parentId,
                                      childId,
                                      i,
                                      deleteId
                                    )
                                  }
                                  type="file"
                                  className="d-none"
                                />
                                {b?.t("Upload")}
                              </label>
                              <div className="text-center mt-5">
                                <button
                                  onClick={
                                    url
                                      ? () => this.onImageRemove(deleteId)
                                      : () => {}
                                  }
                                  className="close closeBtn"
                                >
                                  <img
                                    src={closeWhite}
                                    alt="Delete"
                                    height="14"
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      {/* <div className="col col-3 section-loop">
                          <figure className="section-loop__fig">
                            <img src={burgerKing} alt="pic" />
                          </figure>
                          <label className="ant-btn primary-ghost-btn xs btn-block">
                            <input type="file" className="d-none" />
                            Upload
                          </label>
                          <div className="text-center mt-5">
                            <button className="close closeBtn">
                              <img src={closeWhite} alt="Delete" height="14" />
                            </button>
                          </div>
                        </div>
                        <div className="col col-3">
                          <figure className="section-loop__fig">
                            <img src={burgerKing} alt="pic" />
                          </figure>
                          <label className="ant-btn primary-ghost-btn xs btn-block">
                            <input type="file" className="d-none" />
                            Upload
                          </label>
                          <div className="text-center mt-5">
                            <button className="close closeBtn">
                              <img src={closeWhite} alt="Delete" height="14" />
                            </button>
                          </div>
                        </div>
                        <div className="col col-3">
                          <figure className="section-loop__fig">
                            <img src={burgerKing} alt="pic" />
                          </figure>
                          <label className="ant-btn primary-ghost-btn xs btn-block">
                            <input type="file" className="d-none" />
                            Upload
                          </label>
                          <div className="text-center mt-5">
                            <button className="close closeBtn">
                              <img src={closeWhite} alt="Delete" height="14" />
                            </button>
                          </div>
                        </div>
                        <div className="col col-3">
                          <figure className="section-loop__fig">
                            <img src={burgerKing} alt="pic" />
                          </figure>
                          <label className="ant-btn primary-ghost-btn xs btn-block">
                            <input type="file" className="d-none" />
                            Upload
                          </label>
                          <div className="text-center mt-5">
                            <button className="close closeBtn">
                              <img src={closeWhite} alt="Delete" height="14" />
                            </button>
                          </div>
                        </div> */}
                    </div>
                    <div className="fx-jcfe mt-10 mb-10">
                      <button
                        onClick={() => this.onAddSubSectionClick(section.id)}
                        className="ant-btn addMore"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="fx-jcfe mt-10 mb-10">
            <button
              onClick={this.onAddWholeSectionClick}
              className="ant-btn addMore"
            >
              +
            </button>
          </div>
          <div
            style={{
              marginTop: '1rem',
            }}
            className="grid-row fx-jcc"
          >
            <div className="col col-6 fx-jcsb">
              <button
                onClick={(e) => this.props.closeDomeHall(e)}
                className="ant-btn primary-ghost-btn xs"
              >
               {b?.t("Cancel")} 
              </button>
              <button
                onClick={this.publish}
                //   disabled={this.state.loader ? true : false}
                className="ant-btn yellow-gradient xs"
              >
                {/* {this.state.loader ? "Loading..." : "Edit"} */}
                {b?.t("Edit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DomeHall;