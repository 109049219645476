import { partnerRequestsConstants } from '../constants/partnerRequestsConstants';

export const initialPartnerRequests = {
  selectedUserIndex: null,
};

export function partnerRequests(state = initialPartnerRequests, action) {
  switch (action.type) {
    case partnerRequestsConstants.PARTNER_REQUESTS_RESET_STATE:
      return initialPartnerRequests;

    case partnerRequestsConstants.PARTNER_REQUESTS_EDIT_PARAMETERS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
