import React, { useState, useEffect } from 'react';
import NotificationForm from './NotificationForm';
import { Form, Spin, Row, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { updateLocalizationData, batchEdit } from '../../../../actions/characterActions';
import PropTypes from 'prop-types';
import { titleEdit, createNotif } from '../../../../lib/strings/adminNotifications';
import { getModelLocalization } from '../../../../services/localizationService';
import { localizationModels } from '../../../../lib/const';
import {
  parseLocalizationData,
  isEmpty,
} from '../../../../components/localization/localizationHelperFunctions';
import * as actions from '../../../../actions/notificationsActions';
import { notificationsConstants } from '../../../../constants/notificationsConstants';

function NotificationFormLoading(props) {
  const {
    language,
    isEditing,
    handleCloseSubmenu,
    id,
    batchUpdateCharacter,
    notifications,
    purgeNotificationLocalization,
    setNotificationLocalization,
    handleInput,
    setNotificationsInitial,
    purgeNotificationDetails,
  } = props;

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // wipe localizationData
    purgeNotificationLocalization();

    if (isEditing) {
      const fetchData = async () => {
        const notificationLocalization =  await getModelLocalization(
          id,
          localizationModels.notifications
        );
        if (!isEmpty(notificationLocalization)) {
          const parsedLocalizationData: any = parseLocalizationData(notificationLocalization);
          setNotificationLocalization(parsedLocalizationData);

          if (parsedLocalizationData.en) {
            handleInput('language', 'EN');
          } else {
            const languageList = Object.keys(parsedLocalizationData);
            handleInput('language', languageList[0].toUpperCase());
          }
        }
        setIsInitialized(true);
      };
      fetchData();
    }

    return () => {
      // wipe localization data on unmount
      purgeNotificationLocalization();
      setNotificationsInitial();
      purgeNotificationDetails();
    };
  }, []);

  const handleClose = async () => {
    handleCloseSubmenu();
    purgeNotificationLocalization();
  };

  if (isEditing && !isInitialized) {
    return (
      <Row className="characterStory-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {isEditing ? titleEdit[language] : createNotif[language]}
            </Typography.Text>
          </Row>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Spin size="large" />
          </div>
        </div>
      </Row>
    );
  }

  return <NotificationForm />;
}

NotificationFormLoading.propTypes = {};

NotificationFormLoading.defaultProps = {};

const mapState = (state: any) => {
  const { notification, notificationDetails } = state;
  const { isEditing } = notification;
  return {
    id: notificationDetails.ID,
    language: state.authentication.language,
    isEditing,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleCloseSubmenu: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    purgeNotificationLocalization: () => dispatch(actions.purgeNotificationLocalization()),
    setNotificationLocalization: (payload) =>
      dispatch(actions.setNotificationLocalization(payload)),
    handleInput: (param: string, value: string) =>
      dispatch({ type: notificationsConstants.NOTIFICATION_INPUT, param, value }),
    setNotificationsInitial: () => dispatch(actions.setNotificationsInitial()),
    purgeNotificationDetails: () => dispatch(actions.purgeNotificationDetails),
  };
};

const wrappedCharacterCreationForm = Form.create({ name: 'notification' })(NotificationFormLoading);
export default connect(mapState, mapDispatch)(wrappedCharacterCreationForm);
