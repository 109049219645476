import React from 'react';


/*
 *Create React Context API to store global status
 */
 export const defaultVal = {
     profile: {},
     setContextData(profile){
         this.profile= profile
     }
    } //Insert the default value here.
 const AppContext = React.createContext(defaultVal);
 export default AppContext;