import { Row, Col } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppContext from '../../../../AppContext';
import { getAllDomes } from '../../../../services/domeService';
import * as menuActions from '../../../../actions/menuActions';
import * as SubMenuConstants from '../../../../lib/SubMenuConstants';
import Profile from '../Profile';

interface State {
  exchange: boolean;
  FreeCoupon: boolean;
}

class SuperAdminMenu extends Component<any, any, State> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      setting_setting_active_class: '',
      dome_setting_setting_active_class: '',
      setting_exchange_active_class: '',
      setting_plum_active_class: '',
      current_menu: '',
      dome_admin: {
        name_review: false,
        name_review_active_class: '',
        domes: false,
        setting_review: false,
        setting_review_active_class: '',
        exchange: false,
        Add_setting_active_class: '',
        FreeCoupon: '',
        Voucher_setting_active_class: '',
        Month_setting_active_class: '',
        Coupon_setting_active_class: '',



      },
    };
  }

  componentDidMount = async () => {
    let domes = await getAllDomes();
    let data = await this.getContext();
    data.admin_domes = domes;
    await this.updateContext(data);
    //     let dome_admin = {...this.state.dome_admin,
    //         domes: domes,
    //         }

    //     this.setState({
    //        dome_admin: dome_admin
    //    });
  };

  nameReview = async () => {
    let data = await this.getContext();
    data.name_review = true;
    data.setting_review = false;
    await this.updateContext(data);
    let dome_admin = {
      ...this.state.dome_admin,
      name_review: true,
      setting_review: false,
      name_review_active_class: 'active',
      setting_review_active_class: '',
    };
    this.setState({
      dome_admin: dome_admin,
    });
  };

  settingReview = async () => {
    let data = await this.getContext();
    data.exchange = false;
    await this.updateContext(data);
    this.props.openSubMenuFullScreen(SubMenuConstants.statisticsForDomeStats);
    let dome_admin = {
      ...this.state.dome_admin,
      domes: false,
      name_review: false,
      setting_review: true,
      name_review_active_class: '',
      setting_review_active_class: 'active',
    };
    this.setState({
      dome_admin: dome_admin,
    });
  };

  MyExchange = async () => {
    let context = await this.getContext();
    context.exchange = true;
    context.plums = false,
      this.setState({
        setting_exchange_active_class: 'active',
        setting_plum_active_class: '',

      });

    await this.updateContext(context)
  }

  myplum = async () => {
    let context = await this.getContext();
    context.exchange = false;
    this.setState({
      setting_exchange_active_class: '',
      setting_plum_active_class: 'active',

    });

    await this.updateContext(context)
    this.props.openSubMenuFullScreen(SubMenuConstants.statisticsForPlums);
    // this.props.openSubMenuFullScreen(SubMenuConstants.statisticsForcreated);
  }



  plumSetting = async () => {
    let context = await this.getContext();
    context['adminVoucher']= false;
    this.setState({
      exchange: true,
      setting_active_class: 'active',
      setting_setting_active_class: 'active',
      Add_setting_active_class: '',
      FreeCoupon: false
    });
    await this.updateContext(context)
  };

  domeAddSetting = async () => {
    let context = await this.getContext();
    context.exchange = false; 
    this.setState({
      Add_setting_active_class: 'active',
      setting_setting_active_class: '',
      Voucher_setting_active_class:'',
      exchange: false,
      FreeCoupon: true
    });
    await this.updateContext(context)
  };

  // MyCoupon = async () => {
  //   this.setState({
  //     Coupon_setting_active_class: 'active',
  //     Voucher_setting_active_class: '',
  //     Month_setting_active_class: '',

  //   })

  // }

  // MyMonth = async () => {
  //   this.setState({
  //     Month_setting_active_class: 'active',
  //     Voucher_setting_active_class: '',
  //     Coupon_setting_active_class: '',
     
  //   })

  // }

  MyVoucher = async () => {
    console.log("arif")
    let context = await this.getContext();
    context['adminVoucher'] = true
    context.exchange = false;
    // console.log(context,'context')
    // context.adminVoucher = true;
    this.setState({
      Voucher_setting_active_class: 'active',
      Month_setting_active_class: '',
      Coupon_setting_active_class: '',
     
    })
    await this.updateContext(context)
  }


  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };
  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  render() {
    return (
      <>
        <Row className="fx-jcc domeAdminBtns">
          {/* <button
            onClick={this.nameReview}
            className={
              'ant-btn primary-ghost-btn xs ' +
              this.state.dome_admin.name_review_active_class
            }
          >
            Name Review
          </button> */}
          <button
            onClick={this.settingReview}
            className={
              'ant-btn primary-ghost-btn xss ml-0 ' +
              this.state.dome_admin.setting_review_active_class
            }
          >
            Dome Stats
          </button>
          <button disabled={true} className={'ant-btn primary-ghost-btn xss'}>
            Buy & Sell review
          </button>

          <button
            onClick={this.plumSetting}
            className={
              'ant-btn primary-ghost-btn xss ' +
              this.state.setting_setting_active_class
            }
          >
            Plum Setting
          </button>
          <button
            onClick={this.domeAddSetting}
            className={
              'ant-btn primary-ghost-btn xss ' +
              this.state.Add_setting_active_class
            }
          >
            Dome Add Setting
          </button>
        </Row>



        {this.state.FreeCoupon &&

          <Row className="fx-jcc" style={{ marginTop: "5%" }}>
            <button
              // onClick={this.MyCoupon}
              className={
                'ant-btn primary-ghost-btn xs my-2 ' +
                this.state.Coupon_setting_active_class
              }
            >
              Free Coupon
            </button>
            <button 
            // onClick={this.MyMonth}
              className={
                'ant-btn primary-ghost-btn xs my-2 ' +
                this.state.Month_setting_active_class
              }
            >
              Free Month
            </button>
            <button 
             onClick={this.MyVoucher}
              className={
                'ant-btn primary-ghost-btn xs my-2 ' +
                this.state.Voucher_setting_active_class
              }
            >
              Voucher
            </button>
          </Row>
        }
        {(this.state.exchange &&
          <Row className="fx-jcc" style={{ marginTop: "5%" }}>
            <button
              onClick={this.MyExchange}
              className={
                'ant-btn primary-ghost-btn xss ml-5 mr-5 ' +
                this.state.setting_exchange_active_class
              }
            >
              Exchange rate
            </button>

            <button
              onClick={this.myplum}
              className={'ant-btn primary-ghost-btn xss ' +
                this.state.setting_plum_active_class
              }>
              Plum Control
            </button>
          </Row>
        )}

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSubMenuFullScreen: (menu) =>
    dispatch(menuActions.openSubmenuFullScreen(menu)),
});

export default connect(null, mapDispatchToProps)(SuperAdminMenu);
