import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import { statisticsForUsers } from './apiToTabel';
import {
  statisticsForUsersStrings,
  statisticsForusersText1,
} from '../../../../lib/strings/statistics';
// import TreasureDetails from './TreasureDetails';
//import { Modal } from 'antd';
//import closeX from '../../../../assets/closeX.svg';
//import { Spin, Row } from 'antd';
//import * as treasureService from '../../../../services/treasureService';
//import { isEmpty } from 'lodash';
import { getKits } from '../../../../services/kitService';
//import * as kitActions from '../../../../actions/kitActions';
import * as statisticsService from '../../../../services/statisticsService';
//import { detailsConstants } from '../../../../constants/treasureDetailsConstants';
import { useQuery } from 'react-query';

function StatisticsForUsers(props) {
  const { language, storeKitList, storeTreasureDetails } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});
  const [pagcurrent, setPagCurrent]=useState(1)

  // const [showModal, setShowModal] = useState(false);
  // const [treasureDetails, setTreasureDetails] = useState({});

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForUsers = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForUsers(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['statisticsForUsers', { ...currentFilters, page: currentPage }],
    fetchStatisticsForUsers,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  const kitQuery = useQuery('kitList', getKits, {
    staleTime: 1800000,
    cacheTime: 3600000,
  });

  useEffect(() => {
    if ( statisticsQuery.data) {
      const parsedStatistics = statisticsForUsers(statisticsQuery.data);
       setTotal({ totalBalance: statisticsQuery.data.Count});
       setPagCurrent(statisticsQuery.data.Count);       
       setTotalTreasures(statisticsQuery.data.Count);
       setStatistics(parsedStatistics);
    }
  }, [statisticsQuery.data]);
    
  // useEffect(() => {
  //   if (kitQuery.data) {
  //     storeKitList(kitQuery.data);
  //   }
  // }, [kitQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
    setClickedUsername('');
  };

  // const showDetails = async (treasureId) => {
  //   setShowModal(true);
  //   const details =  await treasureService.getTreasureByID(treasureId);
  //   setTreasureDetails(details.treasure);
  //   //save to redux!!!!!!!!!
  //   storeTreasureDetails(details);
  // };
  
  // const onCancel = () => {
  //   setShowModal(false);
  //   setTreasureDetails({});
  // };

  const columns = [
    {
      title:statisticsForUsersStrings[language].date,
       dataIndex: 'date',
       titleStyle: { textAlign: 'center',paddingLeft:"10px",paddingRight:"10px" },
      dataStyle: {},
    },
    {
      title:statisticsForUsersStrings[language].yearOfBirth,
      dataIndex:'yearOfBirth',
      titleStyle: { textAlign: 'center' },
      dataStyle: {cursor: 'pointer' ,textAlign: 'center',paddingLeft:"10px",paddingRight:"10px" },
     
    },
    {
      title: statisticsForUsersStrings[language].username,
      dataIndex: 'username',
      onClick: clickedUsernameHandler,
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center', wordBreak: 'break-all',paddingLeft:"10px",paddingRight:"10px"},
    },
    {
      title: statisticsForUsersStrings[language].country,
      dataIndex: 'country',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].isMaster,
      dataIndex: 'isMaster',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].dome_add,
      dataIndex: 'dome_add',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].dome_pub,
      dataIndex: 'dome_pub',
      titleStyle: { textAlign: 'center'},
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].partnerData,
      dataIndex: 'partnerData',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].phoneNumber,
      dataIndex: 'phoneNumber',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].email,
      dataIndex: 'email',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      title: statisticsForUsersStrings[language].uid,
      dataIndex: 'uid',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, record) => <span>{pagcurrent-(currentPage*100)-indx}</span>
    },
    // {
    //   title: statisticsForTreasureStrings[language].unusedLcash,
    //   dataIndex: 'unusedLcash',
    //   titleStyle: { textAlign: 'center' },
    //   dataStyle: { textAlign: 'center' },
    // },
    // {
    //   title: statisticsForTreasureStrings[language].chargedLcash,
    //   dataIndex: 'chargedLcash',
    //   titleStyle: { textAlign: 'center' },
    //   dataStyle: { textAlign: 'center' },
    // },
    // {
    //   title: statisticsForTreasureStrings[language].status,
    //   dataIndex: 'status',
    //   titleStyle: { textAlign: 'center' },
    //   dataStyle: { textAlign: 'center' },
    // },
  ];
  //console.log(columns,"vvvvkkkkk")
  columns.map((column, index) => {
    columns[index]['title'] = statisticsForUsersStrings[language][column.dataIndex];
  });

  return (
    <>
      <Statistics
        label={statisticsForusersText1[language]}
        columns={columns}
        dataSource={statistics}
         loading={statisticsQuery.isLoading}
        statisticsType={'StatisticsForUsers'}
        onFiltersChange={onFiltersChangeHandler}
        onPageChange={onPageChangeHandler}
        clickedUsername={clickedUsername}
        totalItems={totalTreasures}
        pageSize={100}
        currentPage={currentPage}
        total={total}
      />
      {/* <Modal
        visible={showModal}
        footer={null}
        centered
        onCancel={onCancel}
        className="resize-modal-to-children"
        bodyStyle={{ width: 'fit-content', padding: '0px' }}
        closeIcon={<img src={closeX} />}
         >
        {isEmpty(treasureDetails) ? (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ padding: '2em', width: 350, height: 350 }}
          >
            <Spin size="large" spinning={props.isLoading} />
          </Row>
        ) : (
          <TreasureDetails treasureDetails={treasureDetails} language={language} />
        )}
      </Modal> */}
    </>
  );
}

 StatisticsForUsers.propTypes = {};

 StatisticsForUsers.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

const mapDispatchToProps = (dispatch) => ({
  // storeKitList: (kitList) => dispatch(kitActions.storeKitList(kitList)),
  // storeTreasureDetails: (details) => dispatch({ type: detailsConstants.STORE_DETAILS, details }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsForUsers);