import { LanguageMap } from '../interfaces/language';

export const usersNotFound: LanguageMap<string> = {
  EN: 'Users not found.',
  KO: 'Users not found.',
};

export const searchUsers: LanguageMap<string> = {
  EN: 'Search users',
  KO: 'Search users',
};

export const addLcash: LanguageMap<string> = {
  EN: 'Add Lcash',
  KO: 'Add Lcash',
};

export const usersLcash: LanguageMap<string> = {
  EN: 'Users Lcash:',
  KO: 'Users Lcash:',
};

export const retakeControl: LanguageMap<string> = {
  EN: 'Take account control',
  KO: 'Take account control',
};
