import store1 from './store1.JPG';
import store2 from './store2.JPG';
import store3 from './store3.JPG';
import store4 from './store4.JPG';
import store5 from './store5.JPG';
import store6 from './store6.JPG';
import store7 from './store7.JPG';
import store8 from './store8.JPG';
import store9 from './store9.JPG';
import store10 from './store10.JPG';

import store1_1 from './store1.1.JPG';
import store2_1 from './store2.1.JPG';
import store3_1 from './store3.1.JPG';
import store4_1 from './store4.1.JPG';
import store5_1 from './store5.1.JPG';
import store6_1 from './store6.1.JPG';
import store7_1 from './store7.1.JPG';
import store8_1 from './store8.1.JPG';
import store9_1 from './store9.1.JPG';
import store10_1 from './store10.1.JPG';

export const storeportrait = [
  { id: 1, src: store1 },
  { id: 2, src: store2 },
  { id: 3, src: store3 },
  { id: 4, src: store4 },
  { id: 5, src: store5 },
  { id: 6, src: store6 },
  { id: 7, src: store7 },
  { id: 8, src: store8 },
  { id: 9, src: store9 },
  { id: 10, src: store10 },
];

export const storelandscape = [
  { id: 1, src: store1_1 },
  { id: 2, src: store2_1 },
  { id: 3, src: store3_1 },
  { id: 4, src: store4_1 },
  { id: 5, src: store5_1 },
  { id: 6, src: store6_1 },
  { id: 7, src: store7_1 },
  { id: 8, src: store8_1 },
  { id: 9, src: store9_1 },
  { id: 10, src: store10_1 },
];
