import bar1 from './bar1.JPG';
import bar2 from './bar2.JPG';
import bar3 from './bar3.JPG';
import bar4 from './bar4.JPG';
import bar5 from './bar5.JPG';
import bar6 from './bar6.JPG';
import bar7 from './bar7.JPG';
import bar8 from './bar8.JPG';
import bar9 from './bar9.JPG';
import bar10 from './bar10.JPG';

import bar1_1 from './bar1.1.JPG';
import bar2_1 from './bar2.1.JPG';
import bar3_1 from './bar3.1.JPG';
import bar4_1 from './bar4.1.JPG';
import bar5_1 from './bar5.1.JPG';
import bar6_1 from './bar6.1.JPG';
import bar7_1 from './bar7.1.JPG';
import bar8_1 from './bar8.1.JPG';
import bar9_1 from './bar9.1.JPG';
import bar10_1 from './bar10.1.JPG';

export const barportrait = [
  { id: 1, src: bar1 },
  { id: 2, src: bar2 },
  { id: 3, src: bar3 },
  { id: 4, src: bar4 },
  { id: 5, src: bar5 },
  { id: 6, src: bar6 },
  { id: 7, src: bar7 },
  { id: 8, src: bar8 },
  { id: 9, src: bar9 },
  { id: 10, src: bar10 },
];

export const barlandscape = [
  { id: 1, src: bar1_1 },
  { id: 2, src: bar2_1 },
  { id: 3, src: bar3_1 },
  { id: 4, src: bar4_1 },
  { id: 5, src: bar5_1 },
  { id: 6, src: bar6_1 },
  { id: 7, src: bar7_1 },
  { id: 8, src: bar8_1 },
  { id: 9, src: bar9_1 },
  { id: 10, src: bar10_1 },
];
