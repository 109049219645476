import React, { memo } from 'react';
import { Row, Button, Typography } from 'antd';
import { arrow, editIcon } from '../assets';
import PropTypes from 'prop-types';

function BackButtonCenteredText(props) {
  const { label, onClick, onEdit, edit } = props;
  return (
    <Row className="back-button-centered-text">
      <Button className="arrow" onClick={onClick}>
        <img src={arrow} alt="close" />
      </Button>
      <Typography.Text className="title">{label}</Typography.Text>
      {edit && (
        <Button className="edit" onClick={onEdit}>
          <img src={editIcon} alt="close" />
        </Button>
      )}    
    </Row>
  );
}

BackButtonCenteredText.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  edit: PropTypes.bool,
  onEdit: PropTypes.func,
};

BackButtonCenteredText.defaultProps = {
  label: '',
  onClick: () => {
  },
  onEdit: () => {
  },
};

export default memo(BackButtonCenteredText);
