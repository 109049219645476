import React from 'react';
import { connect } from 'react-redux';
import { Row, Button, Typography } from 'antd';
import { menuConstants } from '../../../../constants/menuConstants';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import ExchangeRateCountry from './ExchangeRateCountry';
import * as exchangeStrings from '../../../../lib/strings/exchangeRate';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  handleClose: () => void;
  exchangeRates: Array<any>;
  language: languages;
}

interface State {
  exchangeRates: Array<any>;
}

const mapState = (state: any) => {
  return {
    exchangeRates: state.exchangeRate.rates,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
  };
};

const ExchangeRateByCountry = (props: Props) => {
  return (
    <Row className="kit-details-wrapper">
      <div className="scrollable">
        <Row className="er-top-row" type="flex" justify="start" align="middle">
          <Button onClick={props.handleClose} className="details-arrow">
            <img src={arrow} alt="close" />
          </Button>

          <Typography.Text className="contest-title bold">
            {exchangeStrings.exchangeRateByCountryTitle[props.language]}
          </Typography.Text>
        </Row>
        <Row type="flex" justify="center" style={{ margin: '1em' }}>
          {props.exchangeRates
            ? props.exchangeRates.map(
                (country: { country: string; coinValueInPoints: any; ID: number }) => {
                  if (country.country !== 'default') {
                    return (
                      <ExchangeRateCountry
                        key={country.ID}
                        countryName={country.country}
                        points={country.coinValueInPoints}
                      />
                    );
                  }
                }
              )
            : null}
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(ExchangeRateByCountry);
