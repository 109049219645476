export const base64ToFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getPicture = async (url) => {
  const result =   await fetch(url);
  return result;
};

export const imageToBase64 = async (image) => {
  const blob =   await image.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const constructPictureFile = async (url) => {
  const image =   await getPicture(url);
  const base64 =   await imageToBase64(image);
  const file = base64ToFile(base64, 'test');

  return { file, base64 };
};
