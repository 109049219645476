export const characterConstants = {
  SHOW_CHARACTER_FORM: 'SHOW_CHARACTER_FORM',
  UPDATE_INPUT: 'UPDATE_INPUT',
  STORE_CHARACTERS: 'STORE_CHARACTERS',
  SHOW_CHARACTER_DETAILS: 'SHOW_CHARACTER_DETAILS',
  GET_CHARACTER_BY_ID: 'GET_CHARACTER_BY_ID',
  EDIT_CHARACTER: 'EDIT_CHARACTER',
  DELETE_CHARACTER: 'DELETE_CHARACTER',
  SHOW_CHARACTER_LIST: 'SHOW_CHARACTER_LIST',
  FETCH_CHARACTERS: 'FETCH_CHARACTERS',
  STORE_FILES: 'STORE_FILES',
  PURGE_CHARACTER_DATA: 'PURGE_CHARACTER_DATA',
  CHARACTER_ID: 'CHARACTER_ID',
  STORE_FILE_PATH: 'STORE_FILE_PATH',
  CHARACTER_IMAGE: 'CHARACTER_IMAGE',
  UPDATE_LOCALIZATION_DATA: 'UPDATE_LOCALIZATION_DATA',
  BATCH_EDIT: 'BATCH_EDIT',
  RESET_STORY_LOCALIZATION: 'RESET_STORY_LOCALIZATION',
};
