import { LanguageMap, LanguageMapList } from '../interfaces/language';

export const termsTitle: LanguageMap<string> = {
  EN: 'LOAMOA Terms of Use',
};

export const p1: LanguageMap<string> = {
  EN:
    'These Terms of Use apply to you if you are a user of WeChat anywhere in the world. By using LOAMOA, you agree to these Terms of Use of Use. If you do not agree to these Terms of Use of Use, you may not use LOAMOA.',
};

export const p2: LanguageMap<string> = {
  EN:
    'These Terms of Use of Use govern your access and use of LOAMOA app, and related LOAMOA services (“LOAMOA”) of Armax Inc. (the “Company”), and any content (the “Content) such as text, graphics, photos or other materials uploaded, downloaded or appearing on LOAMOA referencing these Terms of Use. ',
};

export const p3: LanguageMap<string> = {
  EN:
    '1. The Company will post the content of these Terms of Use on the LOAMOA service app or home page in order for you to easily access the information.',
};

export const p4: LanguageMap<string> = {
  EN:
    '2. When you do not agree to application of amended Terms of Use of Use, the Company cannot apply amended provisions of LOAMOA, and you may terminate these Terms of Use of Use. In case the existing Terms of Use of Use cannot be applied for special reasons, the Company may terminate the use agreement.',
};

export const p5: LanguageMap<string> = {
  EN:
    '3. "LOAMOA Content" means the text, graphics, emoticons, images, illustrations, designs, icons, photographs, video clips, and other written materials that are provided to the User in the Service, unless otherwise stated. The copyright, trademark, trade dress, patent, and/or other intellectual property rights of any materials contained in the Service, including the selection and arrangement of the "LOAMOA Content", are owned by Armax Inc. and are protected by applicable laws.',
};

export const p6: LanguageMap<string> = {
  EN:
    '4. The Services consist of interactive features and areas which allow users to create, post, transmit, and/or store content, including but not limited to photos, videos, text, graphics, items, or other materials (collectively, "User Content") using “LOAMOA Content”. The Company may suggest or recommend “Contents” or features that users may be interested in based on activities in LOAMOA, in order to promote active mutual interaction among users. The Company may also suggest certain ways to experience LOAMOA for users. But of course, users can restrict access to their “User Content” to certain specific people in their personal settings.',
};

export const p7: LanguageMap<string> = {
  EN:
    '5. You also understand that your User Content may be viewable by others and that you have the ability to control who can access such content by adjusting your privacy settings. If User Content has been uploaded to LOAMOA, our staff is able to see it. The Company endeavor to keep your private content confidential, but please keep in mind that no technology is 100% secure, and it is possible that unauthorized third parties can violate our security measures and access your private contents. You also agree to abide by our Community Guidelines, which may be updated from time to time.',
};

export const p8: LanguageMap<string> = {
  EN:
    '6. Each user owns and retains all the copyright and other intellectual property rights of his/her “User Content,” but the Company’s ownership of the copyright and other intellectual property rights of “LOAMOA Contents” is not affected thereby. Users are solely responsible for all data charges incurred by their use of the Services.',
};

export const p9: LanguageMap<string> = {
  EN:
    '7. By submitting User Content to LOAMOA, you hereby grant us a license to use (including without limitation on the use of facial images, photos or videos for the purpose of machine learning or other research and development activities of the Company independently or jointly with its affiliates) such User Content in any and all media or distribution methods, now known or developing later (the "User Content License") which are subject to any privacy settings you have set to control who can see your User Content.',
};

export const p10: LanguageMap<string> = {
  EN:
    '8. You agree that you are solely responsible for your User Content and any claims arising therefrom, and that the Company is not responsible or liable for any User Content or claims arising therefrom. While the Company are not obligated to do so, the Company reserve the right, and have absolute discretion, to review, screen, and delete User Content at any time and for any reason.',
};

export const p11: LanguageMap<string> = {
  EN:
    '9. In order to commercially use the "User Content" produced by the user, the user must go through the notification procedure for ARMAX INC in advance. If it is found that the "User Content" including the "LOAMOA Content" is commercially used without prior notice, the Company may restrict such use.',
};

export const p12: LanguageMap<string> = {
  EN:
    '10. You agree that any feedback, suggestions, ideas, or other information or materials regarding the Company or the Services which you provide, whether by email or otherwise ("Feedback"), are non-confidential and shall become sole property of the Company. The Company will be entitled to unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without acknowledging or compensating you. You waive any rights you may have to Feedback (including any copyrights or moral rights). The Company like hearing from users.',
};

export const p13: LanguageMap<string> = {
  EN:
    '11. Be mindful of what you send in a message and who you send it to. It is okay with us if someone takes a screenshot, but the Company cannot speak for you or your friends. LOAMOA attempts to detect screenshots and notify the sender, but it does not always work perfectly - and your friends can always capture an image with a camera.',
};

export const p14: LanguageMap<string> = {
  EN:
    '12. Keep it legal. Do not use LOAMOA for any illegal shenanigans, and if you are messaging with someone who might be, you should not view or send the following contents.',
};

export const p14List: LanguageMapList<string> = {
  EN: {
    1: 'Pornography',
    2: 'Nudity or sexually suggestive content involving minors (people under 18 years old)',
    3: 'Minors engaged in activities which are physically dangerous and harmful',
    4: 'Invasions of privacy',
    5: 'Threats',
    6: 'Harassment or Bullying',
    7: 'Impersonation',
    8: 'Self-Harm',
  },
};

export const p15: LanguageMap<string> = {
  EN:
    '13. Violating these rules may result in removal of User Content, suspension of your account and being prohibited from using LOAMOA in the future. Please take these rules seriously and respect them in the spirit in which they are intended. The rules will change and evolve along with the LOAMOA user community. The Company will do our best to enforce them consistently and fairly, and ultimately the Company will try to do what the Company think is best in each situation, at our own discretion.',
};

export const p16: LanguageMap<string> = {
  EN:
    '14. You agree to refrain from behave in following manner along with restrictions included in this Terms of Use of Use. ',
};

export const p16List: LanguageMapList<string> = {
  EN: {
    1: 'Use the Services for any purpose that is illegal, beyond the scope of their intended use, or otherwise prohibited in these Terms of Use or Terms of Use of any third party which govern a particular Service;',
    2: 'Use the Services in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services in any manner;',
    3: 'Compromise the security of the Services;',
    4: 'Send any unsolicited or unauthorized advertising, spam, solicitations, or promotional materials;',
    5: 'Use any robot, spider, crawler, scraper, or other automated means or interface not provided by us to access the Services or to extract data;',
    6: 'Reverse engineer any aspect of the Services or do anything that might lead to the discovery of source code or bypass or circumvent measures applied to prevent or limit access to any area, content, or code of the Services;',
    7: 'Use or attempt to use another user’s account without authorization;',
    8: 'Attempt to circumvent any content-filtering techniques the Company apply, or attempt to access areas or features of the Services that you are not authorized to access;',
    9: 'Downloading (other than page caching) any portion of the Services, LOAMOA Content, or any information contained therein, except as expressly permitted on the Services;',
    10: 'Accessing the LOAMOA API with an unauthorized or third-party client;',
    11: 'Attempt to indicate in any manner that you have a relationship with us or that the Company have endorsed you or any products or services without our express written consent to do so;',
    12: 'Engage in any harassing, intimidating, predatory conduct, or stalking;',
    13: 'Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity;',
    14: 'Violate publicity, privacy, or data-protection rights of others, including by taking pictures of another individual without receiving their consent;',
    15: 'Infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party;',
    16: 'Buy, sell, rent, lease, or otherwise offer in exchange for any compensation, access to your LOAMOA account, messages, a LOAMOA username, or a friend link without LOAMOA’s prior written consent;',
    17: 'Develop any third-party applications which interact with User Content or the Services without our prior written consent; and',
    18: 'Use the Services for any illegal or unauthorized purpose or engage in, encourage, or promote any activity that violates these Terms of Use.',
  },
};

export const p17: LanguageMap<string> = {
  EN:
    '15. You further agree to abide by any third-party Terms of Use which apply to the Services or when posting reviews of LOAMOA, including the iTunes App Store Terms of Use of Service or the Android Market Terms of Use of Service. Posting LOAMOA usernames in app store reviews is strictly prohibited and may result in us deleting your LOAMOA account.',
};

export const p18: LanguageMap<string> = {
  EN:
    '16. The Company gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software which is provided to you by the Company as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by the Company, in a manner permitted by these Terms of Use.',
};

export const p19: LanguageMap<string> = {
  EN:
    '17. Account Information. You need to create an account on LOAMOA with us in order to use our Services. It is your responsibility to keep your log-in credentials secure and you are held responsible by the Company for all activities that occur via your account.',
};

export const p20: LanguageMap<string> = {
  EN:
    '18. You agree that you will not copy, reproduce, republish, frame, download, transmit, modify, display, reverse engineer, sell, or participate in any sale, rent, lease, loan, assign, distribute, license, sublicense, or exploit in any way, in whole or in part of LOAMOA Services, or any related program and software.',
};

export const p21: LanguageMap<string> = {
  EN:
    '19. Compensation for your damages caused by defects in paid services will be in accordance with Guidelines for Protection of Content Users.',
};

export const p22: LanguageMap<string> = {
  EN:
    '20. You acknowledge and agree that by accessing or using the Services, you may be exposed to materials from others which you may consider offensive, indecent, or otherwise objectionable, and agree to accept such risk. Views expressed on our Company site or through our Services do not necessarily reflect our views. The Company do not support or endorse certain content posted by you or other users.',
};

export const p23: LanguageMap<string> = {
  EN:
    '21. Although the Company do what the Company can to provide security measures to protect your content, the Company are not liable for any damages resulting from disclosure of your Content.',
};

export const p24: LanguageMap<string> = {
  EN:
    '22. Disclaimer for Warranties: YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES, THE COMPANYBSITE, APPLICATION AND CONTENT AND THE COMPANY MATERIALS ARE PROVIDED TO YOU “AS IS,” WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. FOR EXAMPLE, THE COMPANY MAKE NO WARRANTY THAT (a) THE SERVICES OR MATERIALS WILL MEET YOUR REQUIREMENTS OR WILL BE CONSTANTLY AVAILABLE, UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (b) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE, SERVICES, OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; OR THAT (c) ANY ERRORS OR DEFECTS IN THE SITE, SERVICES, OR MATERIALS WILL BE CORRECTED. Except where it is prohibited by law, in no event will the Company OR ITS SUPPLIERS, OR THEIR RESPECTIVE EXECUTIVES, DIRECTORS, EMPLOYEES, OR AGENTS (The “Company Parties”) be liable for any indirect, special, punitive, incidental, exemplary, or consequential damages which result from (a) the use of, or inability to use, the Services; (b) provision of the Services or any materials available therein; or (c) the conduct of other users of the Services.',
};

export const p25: LanguageMap<string> = {
  EN:
    '23. Applicable Law: You have established a contract with the Company and you agree to be bound by the laws of the United States regardless of conflicting laws and provisions thereof.',
};

export const p26: LanguageMap<string> = {
  EN:
    '24. Any information that you provide to the Company is subject to our Privacy Policy, which governs our collection and use of your information. You understand that through your use of the Services you consent to collection and use (as set forth in the Privacy Policy pursuant to Applicable Law) of this information. As part of providing the Services, the Company may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered as part of the Services, which you may not be able to opt-out from receiving. ',
};

export const p27: LanguageMap<string> = {
  EN:
    '25. All right and title, to the Services and interest in (excluding Content provided by users) is and will remain exclusive property of the Company and its licensors. The Services are protected by copyright, trademark, and other laws. Nothing in the Terms of Use gives you the right to use the name of the Company or any of the Company trademarks, logos, domain names, or other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding the Company, or the Services is entirely voluntary and the Company will be free to use such feedback, comments or suggestions as the Company consider to be appropriate without having any obligation to inform you.',
};

export const p28: LanguageMap<string> = {
  EN:
    '26. Severability: Even if a court finds any section of the Terms of Use invalid or unenforceable, the rest of the Terms of Use still apply.',
};

export const p29: LanguageMap<string> = {
  EN:
    '27. No Waiver: Even if the Company do not enforce (or the Company delay enforcement) of the Terms of Use against you, the Company have not waived our enforcement rights.',
};

export const p30: LanguageMap<string> = {
  EN:
    '28. Assignment or Transfer: You cannot assign or transfer your rights or obligations under this agreement to someone else without the Company’s written permission. The Company can transfer our rights and obligations to you without your permission unless the Company are acquired by or merge with another company, sell one of the Services, or otherwise.',
};

export const p31: LanguageMap<string> = {
  EN: '29. You can stop using our Services at any time.',
};

export const p32: LanguageMap<string> = {
  EN:
    '30. The Company may add, modify, or remove features or functionalities, and the Company may temporarily or permanently suspend or stop the Service. The Company may also add or create new limits to our Services at any time.',
};

export const p33: LanguageMap<string> = {
  EN:
    '31. In addition, termination of your account does not free you from any obligations of payment.',
};

export const p34: LanguageMap<string> = {
  EN:
    '32. If the Service is terminated or discontinued, then the Company will make reasonable effort to notify you and provide an opportunity to retrieve your content.',
};

export const p34List: LanguageMapList<string> = {
  EN: {
    1: 'LOAMOA TERMS OF USE OF SERVICE (USA-SPECIFIC TERMS OF USE)',
    2: 'If you are a user of LOAMOA in the United States of America, the below Additional Terms of Use: (a) are incorporated into these Terms of Use; (b) apply to your use of LOAMOA; and (c) override the head Terms of Use of these Terms of Use to the extent of any inconsistency.',
    3: 'If you are a user of LOAMOA in the United States of America, the following Terms of Use expressly replaces the above "Governing law and dispute resolution" section of these Terms of Use.',
    4: 'If you live in (or, if a business, your principal place of business is in) the United States, the laws of the state where you live govern all claims, regardless of conflict of law principles, except that the Federal Arbitration Act governs all provisions relating to arbitration. You and we irrevocably consent to the exclusive jurisdiction and venue of the state or federal courts of California, for all disputes arising out of or relating to these Terms of Use that are heard in court (excluding arbitration).',
    5: 'EACH OF THE PARTIES HERETO IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE TRANSACTIONS CONTEMPLATED HEREBY.',
    6: 'In the event of a dispute, you and we agree to try for sixty (60) days to resolve it informally. If you and we are unable to come to informal resolution within sixty (60) days, you and we agree to binding individual arbitration before the American Arbitration Association ("AAA") under the Federal Arbitration Act ("FAA") (with such arbitration to be conducted under the AAA\'s Commercial Arbitration Rules), and not to sue in court in front of a judge or jury. Instead, a neutral arbitrator will decide and the arbitrator’s decision will be final except for a limited right of appeal under the FAA. Class action lawsuits, class-wide arbitrations, private attorney-general actions, and any other proceeding where someone acts in a representative capacity are not allowed, and nor is combining individual proceedings without the consent of all parties. These Terms of Use govern to the extent they conflict with the AAA’s Commercial Arbitration Rules or Consumer Arbitration Rules. You and we must file in arbitration any claim or dispute (except intellectual property disputes) within one year from when it first could be filed. If the class action waiver is found to be illegal or unenforceable as to all or some parts of a dispute, then those parts won’t be arbitrated but will proceed in court, with the rest proceeding in arbitration. If any other provision of these provisions regarding arbitration is found to be illegal or unenforceable, that provision will be severed but the rest of these provisions regarding arbitration still apply.',
    7: 'If you are a California resident, then (except to the extent prohibited by applicable laws) you agree to waive California Civil Code Section 1542, and any similar provision in any other jurisdiction (if you are a resident of such other jurisdiction), which states: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor”.',
    8: 'LOAMOA TERMS OF USE OF SERVICE (AUSTRALIA-SPECIFIC TERMS OF USE)',
    9: 'If you are a user of LOAMOA in Australia, the below Additional Terms of Use: (a) are incorporated into these Terms of Use; (b) apply to your use of LOAMOA; and (c) override the head Terms of Use of these Terms of Use to the extent of any inconsistency.',
    10: 'All express or implied guarantees, warranties, representations, or other Terms of Use and conditions relating to these Terms of Use or their subject matter, not contained in these Terms of Use, are excluded from these Terms of Use to the maximum extent permitted by applicable laws and regulations.',
    11: 'Nothing in these Terms of Use excludes, restricts or modifies any guarantee, warranty, term or condition, right or remedy implied or imposed by any applicable laws and regulations which cannot lawfully be excluded, restricted or modified.',
    12: 'If any guarantee, condition, warranty or term is implied or imposed by any applicable laws and regulations and cannot be excluded (a “Non-Excludable Provision”), and we are able to limit your remedy for a breach of the Non-Excludable Provision, then our liability for breach of the Non-Excludable Provision is limited to one or more of the following at our option:',
    13: 'in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent goods, or the payment of the cost of having the goods repaired; or',
    14: 'in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.',
    15: 'LOAMOA TERMS OF USE OF SERVICE (EUROPEAN UNION-SPECIFIC TERMS OF USE)',
    16: 'If you are a user of LOAMOA and located in the European Union, the below Additional Terms of Use: (a) are incorporated into these Terms of Use; (b) apply to your use of LOAMOA; and (c) override the head Terms of Use of these Terms of Use to the extent of any inconsistency.',
    17: 'Refund of your purchases',
    18: 'If you have purchased and paid for a LOAMOA product or service provided by us (and not by any third parties), you may receive a refund for such purchase if we receive a refund request from you within 14 days from the date you completed the relevant purchase. If you have already used a portion of the relevant product or service, you will receive a refund for the unused portion only. In the case of a download or streaming product, you acknowledge that by proceeding to download or stream such product, you will not be entitled to a refund of such purchase.',
    19: 'We set out further information within the relevant LOAMOA services and applicable Additional Terms of Use in relation to how you can submit your refund request.',
    20: 'Dispute Resolution',
    21: 'Notwithstanding the "Governing Law and Dispute Resolution" section of these Terms of Use, if you are a "consumer" as defined under the EU Direction 83/2011/EU, any dispute, controversy or claim (whether in contract, tort or otherwise) between us and you, arising out of, relating to, or in connection with these Terms of Use will be referred to and finally resolved by the court of your place or residence or domicile. You can also file a complaint at the online platform for alternative dispute resolution (ODR-platform). You can find the ODR-platform through the following link: ',
    22: 'https://ec.europa.eu/consumers/odr.',
    23: 'Loss or damage',
    24: 'If any LOAMOA services or features which we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill we will either repair the damage or pay you reasonable compensation for such damage. However, we will not be liable for damage which you could have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us. We only supply LOAMOA and the services or features accessible via LOAMOA for domestic and private use. If you use LOAMOA or the services or features for any commercial or business purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.',
  },
};
