import React, { Component } from 'react';
import { Row, Col, Rate, Checkbox, Input } from 'antd';
import moment from 'moment';
import { throttle } from 'throttle-debounce';
import {b} from '../../../dashboard/menu/i18next'

import {
  getSupportMessage,
  sendNewMessage,
  updateRating,
  resolveIssue,
} from './supportService';

import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import starIcon from '../../../../../src/assets/star.png';

import iconSend from '../../../../../src/assets/iconSend.svg';
import iconReceive from '../../../../../src/assets/iconReceive.svg';
import { connect } from 'react-redux';
import { roleSuperadmin } from '../../../../lib/const';

const { TextArea } = Input;

// const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const mapStateToProps = (state: { userData: { role: number } }) => {
  const { userData } = state;

  const { role } = userData;
  return {
    role,
  };
};

class CaseDetails extends Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      case_id: 0,
      case_details: [
        { message: '', user_id: 0, datetime: '', support_id: 0, id: 0 },
      ],
      value: 0,
      checked: false,
      comment: '',
      is_rate_clicked: false,
    };
  }
  componentDidMount = () => {
    this.setState({
      checked: this.props.checked,
      value: this.props.value,
    });
  };
  componentWillReceiveProps(nextProps) {
    // alert(nextProps.checked);
    if (nextProps.checked != this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }

    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }
  onChange = async (e) => {
    let res =  await resolveIssue(this.props.case_id);
    this.setState({
      checked: e.target.checked,
    });
    this.props.updateStatus(this.props.case_id);
  };

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  handleChange = async (value) => {
    let data = {
      rate: value,
    };
     await updateRating(this.props.case_id, data);
    let profile =  await this.getContext();

    let support_cases = profile['support_cases'];
    let newprofile = support_cases.map((el) =>
      el.id == this.props.case_id ? { ...el, rate: value } : el
    );
    profile.support_cases = newprofile;
     await this.updateContext(profile);
    this.setState({ value: value });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  commentHandler = async (e: any) => {
    this.setState({
      comment: e.target.value,
    });
  };

  //  throttleFunc = throttle(1000, (num) => {
  //   console.log('num:', num);
  // });

  sendComment = throttle(
    7000,
    async (e: any) => {
      let profile =  await this.getContext();
      let data = {
        message: this.state.comment,
      };
       await sendNewMessage(this.props.case_id, data);
      const message =  await getSupportMessage(this.props.case_id);
      profile['support__mesaages'] = message;

       await this.updateContext(profile);
      this.setState({
        comment: '',
      });
    },
    {
      noTrailing: true,
    }
  );

  render() {
    const { value, checked } = this.state;
    const {
      // details,
      selectedUserId,
    } = this.props;

    let context = this.context;
    let profile = context.profile || {};
    let details = profile.support__mesaages || [];

    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog ">
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62; {this.props.subject ? this.props.subject : ''}
              </h4>
              <div className="fx-aic">
                <i onClick={this.props.close} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div
              className="my-setting-form scrollable"
              style={{ maxHeight: '540px' }}
            >
              <div className="grid-row sm form-group">
                <label className="col col-3 inp-label regular text-right">
                 {b?.t('Subject')} 
                </label>
                <div className="col col-9">
                  <input
                    name="subject"
                    readOnly={true}
                    type="text"
                    value={this.props.subject}
                    className="inp regular"
                  />
                </div>
              </div>
              <div className="grid-row sm form-group">
                <label className="col col-3 inp-label regular text-right">
                {b?.t('Date')}   
                </label>
                <div className="col col-9">
                  <p className="mt-10 mb-0">
                    {moment(this.props.date_time).format(
                      'MMMM DD YYYY hh:mm:ss'
                    )}
                  </p>
                </div>
              </div>
              <div className="mb-5">
                <span className="text-primary">{b?.t('Correspondence')} </span>
              </div>
              {details.map((d) => (
                <div key={d.id} className="grid-row sm form-group">
                  <label className="col col-3 inp-label smallLabel">
                    {d.user_name ? d.name : 'LoaMoa Team'}
                    <br />
                    {moment(d.datetime).format('MMMM DD YYYY')}
                    <br />
                    {moment(d.datetime).format('hh:mm:ss z')}
                    <br />
                  </label>
                  <div style={{ display: 'flex' }} className="col col-9">
                    {selectedUserId === d.user_id ? (
                      <img
                        title={d.user_name}
                        style={{ width: '30px', marginRight: '10px' }}
                        src={iconSend}
                        alt="icon"
                      />
                    ) : (
                      <img
                        title={d.user_name}
                        style={{ width: '20px', marginRight: '19px' }}
                        src={iconReceive}
                        alt="icon"
                      />
                    )}
                    <TextArea
                      className="inp regular"
                      rows={3}
                      value={d.message}
                    />
                  </div>
                </div>
              ))}

              <div className="text-center mb-5">
                <button className="ant-btn purple-gradient xs">
                {b?.t('Load More')} 
                </button>
              </div>

              <div className="grid-row sm form-group">
                <div className="col col-10">
                  <TextArea
                    onChange={this.commentHandler}
                    className="inp regular mb-5"
                    rows={2}
                    value={this.state.comment}
                  />
                </div>
                <div className="col col-2">
                  <button onClick={this.sendComment} className="sendBtn">
                    <img src={iconSend} alt="icon" />
                  </button>
                </div>
              </div>

              {/* <div className="rating-box mb-10">
							<TextArea onChange={this.commentHandler} className="inp regular mb-5" rows={3} value={this.state.comment} />
							<button onClick={this.sendComment} className="ant-btn purple-gradient xs">Submit</button>
						</div> */}

              {this.props.role < roleSuperadmin && (
                <div className="rating-box">
                  <p>
                    <Checkbox checked={checked} onChange={this.onChange}>
                    {b?.t('Resolved')}  
                    </Checkbox>
                  </p>
                  <p className="size12 mb-0">
                  {b?.t('Was this response helpful? Click here to rate')}:
                  </p>
                  <span>
                    <Rate onChange={this.handleChange} value={value} />
                    {value ? <span className="ant-rate-text"></span> : ''}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Row>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(CaseDetails);
