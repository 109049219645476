import { baseURL, cartCheckout, fetchCart } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';


const showErrorMessage = (title, description?) => {
    notification.error({
        message: `${title}`,
        description: `${description || ''}`,
        duration: 10,
    });
};

const apiData = async (items,coupons) => {
        let api_data = [];
    for (let d of items) {
        let obj={}
        if(coupons>0){
             obj = {
                'cart_id': d.id,
                'year': d.year,
                is_coupon:'1'
            }
        }
        else{
             obj = {
                'cart_id': d.id,
                'year': d.year,
                is_coupon:'0'
            }
        }
            api_data.push(obj);
            coupons-=1

    }
    return api_data;
}
const apiData2= async (items) => {
    let api_data = [];
    for (let d of items) {
         let obj={}
             obj = {
                'cart_id': d.id,
                'year': d.year    
        }
            api_data.push(obj);
            

    }
    return api_data;
}
export const cartPayment = async (card: any, profile: any,coupons:any) => {
    try {
        let items = profile.profile.items;
         items = items.filter(function(item) {
            return item.check === true;
          });

        let api_data =   await apiData(items,coupons);
        // console.log(coupons,"coupondata")
        //let coupondata=coupons==0?'0':'1'
        let fromValues = {
            email: profile.email,
            data: api_data
          }
         
          if(card && Object.keys(card).length !==0){
            fromValues['card_token'] = profile.card_token
            fromValues['card'] = card.last4
            fromValues['expires'] = profile.expires
            fromValues['card_type'] = card.brand
             fromValues['iscoupon'] = '0'
          }
        const fetchResponse =   await fetch(baseURL + cartCheckout, {
            method: 'POST',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(fromValues) 
            // body: JSON.stringify({
            //     email: profile.email,
            //     card_token: profile.card_token,
            //     card: card.last4,
            //     card_type: card.brand,
            //     expires: profile.expires,
            //     data: api_data
            // })
        });

        const response =   await fetchResponse.json();

        if (response.code === 1) {
            return response.data;
        } else {
            showErrorMessage(response.error);
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
};

export const cartPaymentcard = async (card: any, profile: any) => {
    try {
        let items = profile.profile.items;
         items = items.filter(function(item) {
            return item.check === true;
          });

        let api_data =   await apiData2(items);

        let fromValues = {
            email: profile.email,
            data: api_data
          }
         
          if(card && Object.keys(card).length !==0){
            fromValues['card_token'] = profile.card_token
            fromValues['card'] = card.last4
            fromValues['expires'] = profile.expires
            fromValues['card_type'] = card.brand
             fromValues['iscoupon'] = '0'
          }
        const fetchResponse =   await fetch(baseURL + cartCheckout, {
            method: 'POST',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(fromValues) 
            // body: JSON.stringify({
            //     email: profile.email,
            //     card_token: profile.card_token,
            //     card: card.last4,
            //     card_type: card.brand,
            //     expires: profile.expires,
            //     data: api_data
            // })
        });

        const response =   await fetchResponse.json();

        if (response.code === 1) {
            return response.data;
        } else {
            showErrorMessage(response.error);
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
};


export const getCart = async () => {
    try {
        const fetchResponse =   await fetch(baseURL + cartCheckout, {
            method: 'GET',
            headers: {
                'access-token': store.getState().authentication.user.newToken,
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
        });
        const response =   await fetchResponse.json ();
        if (response.code === 1) {
            return response.data;

        } else {
            showErrorMessage('Dome not added in cart', response.code);
            return Promise.reject('failed to get sections');
        }
    } catch (error) {
        showErrorMessage('Try after sometimes.');
        return error;
    }
}

