import { LanguageMap } from '../interfaces/language';

export const exchangeRateTitle: LanguageMap<string> = {
  EN: 'Exchange Rate',
  KO: '환율',
};

export const allRates: LanguageMap<string> = {
  EN: 'All rates',
  KO: '모든 가격',
};

export const points: LanguageMap<string> = {
  EN: 'Points',
  KO: '포인트',
};

export const coins: LanguageMap<string> = {
  EN: 'Coins',
  KO: '코인',
};

export const defaultLabel: LanguageMap<string> = {
  EN: 'Default',
  KO: '디폴트',
};

export const saveButton: LanguageMap<string> = {
  EN: 'Save',
  KO: '저장',
};

export const exchangeRateByCountryTitle: LanguageMap<string> = {
  EN: 'Exchange Rate by country',
  KO: '나라 별 환율',
};
