import React from 'react';
import { Col, Form, Typography, DatePicker } from 'antd';
import calendar from '../../assets/icon_date.svg';
import * as contestString from '../../lib/strings/contest';
import PropTypes from 'prop-types';

function ContestFormDates(props) {
  const {
    getFieldDecorator,
    language,
    handleDates,
    setStartDate,
    setEndDate,
    findMinOrMaxDate,
    startDate,
    endDate,
    qualificationsStartDate,
    qualificationsEndDate,
    finalistsAnnouncementDate,
    finalsEndDate,
    finalsStartDate,

    registrationStartDate,
    registrationEndDate,
    uploadContestantsVideoEarliestDate,
    uploadContestantsVideoLatestDate,
  } = props;

  return (
    <Col span={11}>
      <div className="contest-schedule">
        <Typography.Text className="contest-title">
          {contestString.schedule[language]}
        </Typography.Text>

        <label className="custom-label">{contestString.ApplyLabel[language]}</label>
        <div style={{ display: 'flex', justifyContent: 'spaceEvenly' }}>
          <Form.Item>
            {getFieldDecorator('registrationStartDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                name="registrationStartDate"
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setStartDate(e), 'registrationStartDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [startDate];
                  const minDate = findMinOrMaxDate('max', startDateList);

                  const endDateList = [
                    endDate,
                    registrationEndDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setStartDate(current, 100) <= minDate || setStartDate(current) >= maxDate;
                }}
              />
            )}
          </Form.Item>
          <Typography.Text className="date-text bold">
            {' '}
            {contestString.to[language]}
          </Typography.Text>
          <Form.Item>
            {getFieldDecorator('registrationEndDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setEndDate(e), 'registrationEndDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [startDate, registrationStartDate];
                  const minDate = findMinOrMaxDate('max', startDateList);

                  const endDateList = [
                    endDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setEndDate(current) <= minDate || setEndDate(current, 100) >= maxDate;
                }}
              />
            )}
          </Form.Item>
        </div>

        <label className="custom-label">{contestString.contestantVideoUploadLabel[language]}</label>
        <div style={{ display: 'flex', justifyContent: 'spaceEvenly' }}>
          <Form.Item>
            {getFieldDecorator('uploadContestantsVideoEarliestDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setStartDate(e), 'uploadContestantsVideoEarliestDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [startDate];
                  const minDate = findMinOrMaxDate('max', startDateList);

                  const endDateList = [
                    endDate,
                    uploadContestantsVideoLatestDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setStartDate(current, 100) <= minDate || setStartDate(current) >= maxDate;
                }}
              />
            )}
          </Form.Item>
          <Typography.Text className="date-text bold">
            {' '}
            {contestString.to[language]}
          </Typography.Text>
          <Form.Item>
            {getFieldDecorator('uploadContestantsVideoLatestDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setEndDate(e), 'uploadContestantsVideoLatestDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [startDate, uploadContestantsVideoEarliestDate];
                  const minDate = findMinOrMaxDate('max', startDateList);

                  const endDateList = [
                    endDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setEndDate(current) <= minDate || setEndDate(current, 100) >= maxDate;
                }}
              />
            )}
          </Form.Item>
        </div>

        <label className="custom-label">{contestString.qualifyingLabel[language]}</label>
        <div style={{ display: 'flex', justifyContent: 'spaceEvenly' }}>
          <Form.Item>
            {getFieldDecorator('qualificationsStartDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setStartDate(e), 'qualificationsStartDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [
                    startDate,
                    registrationStartDate,
                    registrationEndDate,
                    uploadContestantsVideoEarliestDate,
                    uploadContestantsVideoLatestDate,
                  ];
                  const minDate = findMinOrMaxDate('max', startDateList);
                  const endDateList = [
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                    endDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setStartDate(current, 100) <= minDate || setStartDate(current) >= maxDate;
                }}
              />
            )}
          </Form.Item>
          <Typography.Text className="date-text bold">
            {' '}
            {contestString.to[language]}
          </Typography.Text>
          <Form.Item>
            {getFieldDecorator('qualificationsEndDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setEndDate(e), 'qualificationsEndDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [
                    startDate,
                    registrationStartDate,
                    registrationEndDate,
                    uploadContestantsVideoEarliestDate,
                    uploadContestantsVideoLatestDate,
                    qualificationsStartDate,
                  ];
                  const minDate = findMinOrMaxDate('max', startDateList);
                  const endDateList = [
                    finalistsAnnouncementDate,
                    finalsEndDate,
                    finalsStartDate,
                    endDate,
                  ];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setEndDate(current) <= minDate || setEndDate(current, 100) >= maxDate;
                }}
              />
            )}
          </Form.Item>
        </div>

        <label className="custom-label">{contestString.finalLabel[language]}</label>
        <div style={{ display: 'flex', justifyContent: 'spaceEvenly' }}>
          <Form.Item>
            {getFieldDecorator('finalsStartDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setStartDate(e), 'finalsStartDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [
                    startDate,
                    registrationStartDate,
                    registrationEndDate,
                    uploadContestantsVideoEarliestDate,
                    uploadContestantsVideoLatestDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                  ];
                  const minDate = findMinOrMaxDate('max', startDateList);
                  const endDateList = [finalsEndDate, endDate];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setStartDate(current, 100) <= minDate || setStartDate(current) >= maxDate;
                }}
              />
            )}
          </Form.Item>
          <Typography.Text className="date-text bold">
            {' '}
            {contestString.to[language]}
          </Typography.Text>
          <Form.Item>
            {getFieldDecorator('finalsEndDate', {
              rules: [{ required: true, message: contestString.dateValidation[language] }],
            })(
              <DatePicker
                format={'DD/MM/YY'}
                suffixIcon={<img src={calendar} />}
                onChange={(e) => handleDates(setEndDate(e), 'finalsEndDate')}
                disabled={!startDate || !endDate}
                disabledDate={(current) => {
                  const startDateList = [
                    startDate,
                    registrationStartDate,
                    registrationEndDate,
                    uploadContestantsVideoEarliestDate,
                    uploadContestantsVideoLatestDate,
                    qualificationsStartDate,
                    qualificationsEndDate,
                    finalistsAnnouncementDate,
                    finalsStartDate,
                  ];
                  const minDate = findMinOrMaxDate('max', startDateList);
                  const endDateList = [endDate];
                  const maxDate = findMinOrMaxDate('min', endDateList);
                  return setEndDate(current) <= minDate || setEndDate(current, 100) >= maxDate;
                }}
              />
            )}
          </Form.Item>
        </div>
      </div>
    </Col>
  );
}

ContestFormDates.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  language: PropTypes.string,
  handleDates: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  findMinOrMaxDate: PropTypes.func,

  startDate: PropTypes.any,
  endDate: PropTypes.any,
  qualificationsStartDate: PropTypes.any,
  qualificationsEndDate: PropTypes.any,
  finalistsAnnouncementDate: PropTypes.any,
  finalsEndDate: PropTypes.any,
  finalsStartDate: PropTypes.any,
  registrationStartDate: PropTypes.any,
  registrationEndDate: PropTypes.any,
  uploadContestantsVideoEarliestDate: PropTypes.any,
  uploadContestantsVideoLatestDate: PropTypes.any,
};

ContestFormDates.defaultProps = {
  language: 'EN',
};

export default ContestFormDates;
