import { usersConstants } from '../constants/usersConstants';

export const initialUsers = {};

export function users(state = initialUsers, action) {
  switch (action.type) {
    case usersConstants.USERS_RESET:
      return initialUsers;
    case usersConstants.USERS_PUT:
      return { ...state, ...action.payload };
    case usersConstants.USERS_POST:
      return action.payload;
    default:
      return state;
  }
}
