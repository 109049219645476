import React from 'react';
import { getPaymentSession } from '../services/paymentService';
import * as Config from '../lib/config';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { treasureConstants } from '../constants/treasureConstants';
import { notification } from 'antd';
import { paymentSessionErr } from '../lib/strings/error';
import { languages } from '../lib/interfaces/language';
import { buyButton } from '../lib/strings/appConstants';
import {b} from '../containers/dashboard/menu/i18next'

interface Props {
  children: React.ReactNode;
  amount: Number;
  initiatePayment?: () => void;
  language?: languages;
}

const mapState = (state: { authentication: { language: any } }) => {
  return {
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: (arg0: { type: string }) => any) => {
  return {
    initiatePayment: () => dispatch({ type: treasureConstants.PAYMENT_INITIATED }),
  };
};

const StripePayment = (props: Props) => {
  const openStripe = async () => {
    if (props.amount) {
      console.log(window.location.protocol + '//' + window.location.host);
      const sessionRes =   await getPaymentSession(
        window.location.protocol + '//' + window.location.host + '/dashboard',
        props.amount
      );

      if (sessionRes.data && sessionRes.data.session && sessionRes.data.session.id) {
        props.initiatePayment();
        const stripe =   await loadStripe(Config.stripe.apikey);
        const { error } =   await stripe.redirectToCheckout({
          sessionId: sessionRes.data.session.id,
        });

        if (error) {
          alert('error, check log');
        }
      } else {
        alert('error, failed to get payment session');
      }
    } else {
      notification.error({
        message: b?.t('Failed to get payment session'),
      });
    }
  };

  return (
    <>
      <a href="#javascript:void(0)" onClick={() => openStripe()}>
        {b?.t('Buy')}
      </a>
    </>
  );
};

export default connect(mapState, mapDispatch)(StripePayment);
