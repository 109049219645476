export const detailsConstants = {
  STORE_LIST: 'STORE_LIST',
  STORE_DETAILS: 'STORE_DETAILS',
  CLOSE: 'CLOSE',
  SHOW_CLIENTS: 'SHOW_CLIENTS',
  HIDE_CLIENTS: 'HIDE_CLIENTS',
  DELETE_TREASURE: 'DELETE_TREASURE',
  REVIEWED: 'REVIEWED',
  STORE_CLIENTS: 'STORE_CLIENTS',
  DELETE_LIST: 'DELETE_LIST',
  PURGE_DATA: 'PURGE_DATA',
  EDIT_TREASURE_LIST_DATA_PARAMETAR: 'EDIT_TREASURE_LIST_DATA_PARAMETAR',
  EDIT_TREASURE_DETAILS_PARAMETAR: 'EDIT_TREASURE_DETAILS_PARAMETAR',
  PURGE_DETAILS_DATA: 'PURGE_DETAILS_DATA',
};
