import React from 'react';
import { Col, Row, Typography } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as menuActions from '../../../../actions/menuActions';
import * as usersActions from '../../../../actions/usersActions';
import { admin, superadmin, master, masterPartner } from '../../../../lib/strings/appConstants';
import { roleMaster, rolePartner, roleAdmin, roleSuperadmin } from '../../../../lib/const';
import { userDetails } from '../../../../lib/SubMenuConstants';

const getRoleName = (role, language) => {
  switch (role) {
    case roleSuperadmin:
      return superadmin[language];
    case roleAdmin:
      return admin[language];
    case rolePartner:
      return masterPartner[language];
    case roleMaster:
      return master[language];
  }
};

function User({ user, language, openUserDetails, closeSubmenu2, postUsers, selectedID }) {
  const openUserDetailsHandler = () => {
    closeSubmenu2();
    postUsers(user);
    openUserDetails();
  };

  return (
    <Row
      style={{ width: '100%', padding: '0 1em' }}
      onClick={openUserDetailsHandler}
      type="flex"
      justify="center"
      className={user._id === selectedID ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      <Row type="flex" justify="start" align="middle" style={{ width: '97%' }}>
        <Col className="treasure-img"></Col>
        <Col style={{ width: '100%' }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Row>
                <Typography.Text className="candyEvent-title">{user.username}</Typography.Text>
              </Row>
              <Row>
                <Typography.Text className="candyEvent-text">{user.phoneNumber}</Typography.Text>
              </Row>
              <Row className="candyEvent-country-text">{getRoleName(user.role, language)}</Row>
            </Col>
            <Col>
              <img src={arrow} className="treasure-arrow" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
}

User.propTypes = {
  user: PropTypes.object,
  language: PropTypes.string,
};

User.defaultProps = {
  user: {},
  language: 'EN',
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  selectedID: state.users._id,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openUserDetails: () => dispatch(menuActions.openSubmenu1(userDetails)),
  postUsers: (payload) => dispatch(usersActions.postUsers(payload)),
});

export default connect(mapsStateToProps, mapDispatchToProps)(User);
