import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';

import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {b} from '../../../dashboard/menu/i18next'

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

class ArShowItem extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      selectedDate: new Date(),
    };
  }

  onChangeEvent = (event) => {
    this.setState({ selectedDate: event });
  };

  renderStatus = () => {
    if (this.props.item.start_date && this.props.item.end_date) {
      if (
        moment(this.props.item.start_date).isBefore(moment(new Date())) &&
        moment(new Date()).isBefore(moment(this.props.item.end_date))
        //   ||
        // (moment(this.props.item.start_date).isAfter(moment(new Date())) &&
        //   moment(new Date()).isBefore(moment(this.props.item.end_date)))
      ) {
        return <i className="status running"></i>;
      }

      if (moment(this.props.item.start_date).isAfter(moment(new Date()))) {
        return <i className="status pending"></i>;
      }
      if (moment(new Date()).isAfter(moment(this.props.item.end_date))) {
        return <i className="status finished"></i>;
      }
    }

    return <i className="status notNow"></i>;
  };

  render() {
    return (
      <>
        <li className="editingList__item">
          <div className="editingList__item__row">
            <div className="editingList__item__row__left w-auto">
              <div className="grid-row xs">
                <div className="col col-6">
                  <div className="dateSection">
                    <div className="fx-aic">
                      <span className="dateSection__label">{b?.t('Start')}:</span>
                      <input
                        type="text"
                        value={
                          (this.props.item.start_date &&
                            moment(this.props.item.start_date).format(
                              'MMMM Do YYYY'
                            )) ||
                          ''
                        }
                        className="form-control xss rd-0 size11"
                      />
                      {/* <DatePicker
                        showTimeSelect
                        selected={this.state.selectedDate}
                        onChange={this.onChangeEvent}
                      /> */}
                    </div>
                    <div className="fx-aic">
                      <span className="dateSection__label">{b?.t('End')}:</span>
                      <input
                        type="text"
                        value={
                          (this.props.item.end_date &&
                            moment(this.props.item.end_date).format(
                              'MMMM Do YYYY'
                            )) ||
                          ''
                        }
                        placeholder=""
                        className="form-control xss rd-0 size11"
                      />
                    </div>
                  </div>
                </div>
                <div className="col col-6">
                  <input
                    type="text"
                    value={this.props.item.name}
                    className="form-control xs rd-0"
                  />
                </div>
              </div>
            </div>
            <div className="editingList__item__row__action">
              {/* <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
                            <input onChange={this.props.handleFile} type="file" />
                            Upload
                        </label> */}
              <div className="h-32 fx-jcc-aic">
                {this.renderStatus()}
                {/* <i className="status notNow"></i> */}
              </div>
              <button
                onClick={() =>
                  this.props.onArEditClick(this.props.index, this.props.item)
                }
                className="iconx xs"
              >
                <img src={editIcon} alt="Edit" height="14" />
              </button>
              <button
                onClick={this.props.removeUrlHandler}
                className="iconx xs"
              >
                <img src={deleteIcon} alt="Delete" height="14" />
              </button>
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default ArShowItem;
