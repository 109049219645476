import fashion1 from './fashion1.JPG';
import fashion2 from './fashion2.JPG';
import fashion3 from './fashion3.JPG';
import fashion4 from './fashion4.JPG';
import fashion5 from './fashion5.JPG';
import fashion6 from './fashion6.JPG';
import fashion7 from './fashion7.JPG';
import fashion8 from './fashion8.JPG';
import fashion9 from './fashion9.JPG';
import fashion10 from './fashion10.JPG';

import fashion1_1 from './fashion1.1.JPG';
import fashion2_1 from './fashion2.1.JPG';
import fashion3_1 from './fashion3.1.JPG';
import fashion4_1 from './fashion4.1.JPG';
import fashion5_1 from './fashion5.1.JPG';
import fashion6_1 from './fashion6.1.JPG';
import fashion7_1 from './fashion7.1.JPG';
import fashion8_1 from './fashion8.1.JPG';
import fashion9_1 from './fashion9.1.JPG';
import fashion10_1 from './fashion10.1.JPG';

export const fashionportrait = [
  { id: 1, src: fashion1 },
  { id: 2, src: fashion2 },
  { id: 3, src: fashion3 },
  { id: 4, src: fashion4 },
  { id: 5, src: fashion5 },
  { id: 6, src: fashion6 },
  { id: 7, src: fashion7 },
  { id: 8, src: fashion8 },
  { id: 9, src: fashion9 },
  { id: 10, src: fashion10 },
];

export const fashionlandscape = [
  { id: 1, src: fashion1_1 },
  { id: 2, src: fashion2_1 },
  { id: 3, src: fashion3_1 },
  { id: 4, src: fashion4_1 },
  { id: 5, src: fashion5_1 },
  { id: 6, src: fashion6_1 },
  { id: 7, src: fashion7_1 },
  { id: 8, src: fashion8_1 },
  { id: 9, src: fashion9_1 },
  { id: 10, src: fashion10_1 },
];
