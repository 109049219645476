import { Row, Spin } from 'antd';
import {
  deleteNotice,
  getNotice,
  notice,
} from '../../../../services/domeService';

import AppContext from '../../../../AppContext';
import React, { useState } from 'react';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import { notification } from 'antd';
import {useTranslation} from "react-i18next";
import { connect } from 'react-redux';
import {b} from '../../../dashboard/menu/i18next'


// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};



const NoticeItem = (props) => {
  const [state, setState] = useState()
  const [state1, setState1] = useState()
  const { t } = useTranslation();

  const textLimit = (e: any) => {
    const sub_index = e.target.getAttribute('data-index');
    console.log("yyyyyyy", sub_index)
    console.log("arif")
    if (sub_index == 0) {
      if (e.target.value.length <= 90) {
        console.log("mm", e.target.value)
        let val = e.target.value
        setState(
          val,
        );
      }
    }
    else {
      if (e.target.value.length <= 90) {
        console.log("mm", e.target.value)
        let val = e.target.value
        setState1(
          val,
        );
      }
    }
  };
  return (
    <li key={props.index} className="editingList__item notice-board-fields">
      <div className="editingList__item__row">
        <div className="editingList__item__row__left fullSide">
          <div className="grid-row xs mb-5 tittle-field">
            <div className="col col-3 fx-aic">
              <label className="fileLabel">{t('Title')}</label>
            </div>
            <div className="col col-9">
              <input
                onChange={(e) => props.inputHandler(e, props.index)}
                name="notice_title"
                type="text"
                data-index="-1"
                value={props.item.notice_title}
                className="form-control xss rd-0"
              />
            </div>
          </div>
          {props.item.sub_notice.map((sub_notice, i) => (
            <div key={props.index + '-' + i}>
              <div className="grid-row xs sub-tittle">
                <div className="col col-3 fx-aic">
                  <label className="fileLabel">{t('Sub-title')}{i + 1}</label>
                </div>
                <div className="col col-9">
                  <input
                    onChange={(e) => props.inputHandler(e, props.index)}
                    name="sub_title"
                    data-index={i}
                    type="text"
                    value={sub_notice.sub_title}
                    className="form-control xss rd-0"
                  />
                  {i === 0 ? props.item.sub_notice.map((item, index) => (
                    <>
                      {index === 0 ?
                        <div className="fx-jcfe" style={{ marginLeft: "90%" }}>
                          <small
                            style={{
                              color: `${item.sub_title.length < 25 ? 'lightgrey' : 'red'
                                }`,
                            }}
                          >
                            ({25 - item.sub_title.length}/25)
                          </small>
                        </div>
                        : ''}
                    </>
                  )) : ''}

                  {i === 1 ? props.item.sub_notice.map((item, index) => (
                    <>
                      {index === 1 ?
                        <div className="fx-jcfe" style={{ marginLeft: "90%" }}>
                          <small
                            style={{
                              color: `${item.sub_title.length < 25 ? 'lightgrey' : 'red'
                                }`,
                            }}
                          >
                            ({25 - item.sub_title.length}/25)
                          </small>
                        </div>
                        : ''}
                    </>
                  )) : ''}
                </div>
              </div>
              <div className="grid-row xs mb-5 description">
                <div className="col col-3 fx-aic">
                  <label className="fileLabel">{t('Description')}{i + 1}</label>
                </div>
                <div className="col col-9">
                  <textarea
                    onChange={(e) => {
                      props.inputHandlerDesc(e, props.index),
                        textLimit(e)
                    }}
                    name="description"
                    data-index={i}
                    rows={2}
                    value={sub_notice.description && i === 0 ? state : sub_notice.description ? state1 : ''}
                    defaultValue={sub_notice.description}
                    className="form-control xss rd-0"
                  >
                  </textarea>
                </div>
                <br />

                {i === 0 ? props.item.sub_notice.map((item, index) => (
                  <>
                    {index === 0 ?
                      <div className="fx-jcfe" style={{ marginLeft: "90%" }}>
                        <small
                          style={{
                            color: `${item.description.length < 90 ? 'lightgrey' : 'red'
                              }`,
                          }}
                        >
                          ({90 - item.description.length}/90)
                        </small>
                      </div>
                      : ''}
                  </>
                )) : ''}


                {i === 1 ? props.item.sub_notice.map((item, index) => (
                  <>
                    {index === 1 ?
                      <div className="fx-jcfe" style={{ marginLeft: "90%" }}>
                        <small
                          style={{
                            color: `${item.description.length < 90 ? 'lightgrey' : 'red'
                              }`,
                          }}
                        >
                          ({90 - item.description.length}/90)
                        </small>
                      </div>
                      : ''}
                  </>
                )) : ''}
              </div>
            </div>
          ))}
        </div>
        <div className="editingList__item__row__action">
          {/* <button className="iconx xs"><img src={editIcon} alt="Edit" height="14" /></button> */}
          <button onClick={props.removeHandler} className="iconx xs">
            <img src={deleteIcon} alt="Delete" height="14" />
          </button>
        </div>
      </div>
    </li>
  );
};

class DomeNotice extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      notice: [
        {
          notice_title: '',
          sub_notice: [
            { sub_title: '', description: '' },
            { sub_title: '', description: '' },
          ],
        },
      ],
    };
  }

  componentDidMount = async () => {
    let profile =  await this.getContext();
    const id = profile.admin_dome.ID;
    const notice =  await getNotice(id);
    console.log(notice);

    let temp_notice = [
      {
        notice_title: '',
        dome_id: null,
        id: null,
        sub_notice: [
          { sub_title: '', description: '' },
          { sub_title: '', description: '' },
        ],
      },
    ];
    if (notice && notice.length) {
      for (let i = 0; i < notice.length; i++) {
        temp_notice[i] = {
          ...temp_notice[i],
          notice_title: notice[i].title,
          dome_id: notice[i].dome_id,
          id: notice[i].id,
          sub_notice: notice[i].subnotice,
        };
        // temp_notice[i]['notice_title'] = notice[i].title;
        // temp_notice[i]['sub_notice'] = notice[i].subnotice;
      }
    }

    this.setState({
      notice: temp_notice,
    });

    //    let result = [];
    //    let sub_notice = [];
    //    for (var j = 0; j < notice.length; j++){
    //         var notice_title = notice[j].notice_title;
    //         result[notice[j].notice_id].notice_title = notice[j].notice_title;

    //         if(!result[notice[j].notice_id].hasOwnProperty("sub_notice")){
    //             let sub_notice = {
    //                 'sub_title' : notice[j].sub_title,
    //                 'description' : notice[j].description
    //             }
    //             result[notice[j].notice_id].sub_notice = [];
    //             result[notice[j].notice_id].sub_notice = ''

    //         }
    //         if(result[notice[j].notice_id].sub_notice)
    //         result[notice[j].notice_id].sub_notice = [];
    //         result[notice[j].notice_id].sub_notice[].sub_title =  '';
    //         result[notice[j].notice_id].sub_notice[].description = '';
    // }
  };

  addMore = async (e: any) => {
    let notice = [
      ...this.state.notice,
      {
        notice_title: '',
        sub_notice: [
          { sub_title: '', description: '' },
          { sub_title: '', description: '' },
        ],
      },
    ];
    this.setState({ notice: notice });
  };



  removeHandler = async (e, index, data) => {
    console.log(data);
    let notice = [...this.state.notice];
    notice.splice(index, 1);
    this.setState({
      notice: notice,
    });
    if (data.id) {
       await deleteNotice(data.dome_id, data.id);
    }
  };

  inputHandler = async (e, index) => {

    const sub_index = e.target.getAttribute('data-index');
    // console.log("ddd",sub_index)
    let notice = this.state.notice;
    // console.log("11",notice)
    let noticeIndex = notice[index];
    // console.log("12",noticeIndex)
    if (sub_index == -1) {
      if (e.target.value.length <= 26) {
        noticeIndex[e.target.name] = e.target.value;
      }
    } else {
      let sub_notice = noticeIndex.sub_notice[sub_index];
      if (e.target.value.length <= 26) {
        sub_notice[e.target.name] = e.target.value;
        noticeIndex.sub_notice[sub_index] = sub_notice;
      }
    }
    notice[index] = noticeIndex;
    this.setState({
      notice: notice,
    });
  };
  inputHandlerDesc = async (e, index) => {
    

    const sub_index = e.target.getAttribute('data-index');
    
    let notice = this.state.notice;
   
    let noticeIndex = notice[index];
    
    if (sub_index == -1) {
      if (e.target.value.length <= 91) {
        noticeIndex[e.target.name] = e.target.value;
      }
    } else {
      let sub_notice = noticeIndex.sub_notice[sub_index];
      if (e.target.value.length <= 91) {
        sub_notice[e.target.name] = e.target.value;
        noticeIndex.sub_notice[sub_index] = sub_notice;
      }
    }
    notice[index] = noticeIndex;
    this.setState({
      notice: notice,
    });
  };
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };
  publishNotice = async (e: any) => {
    const profile =  await this.getContext();
    const id = profile.admin_dome.ID;
    const noticedata = this.state.notice;
    for (let i = 0; i < noticedata.length; i++) {
      if (noticedata[i].notice_title == '') {
        showErrorMessage(b?.t('Error'), b?.t('Section') + i + 1 + b?.t('Should not be empty'));
        return false;
      }
    }
    const result =  await notice(id, noticedata);
    showSuccessMessage(b?.t('Success'), b?.t('Notice  updated successfully'));
    this.props.closeNotice();
  };

  render() {
    const context = this.context;
    const {translation}=this.props
    { console.log("bbbb", this.state) }
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
          <div className="third-card">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                {' '}
                &#62;{translation("Noticeboard")} 
              </h4>
              <div className="fx-aic">
                <i onClick={this.props.closeNotice} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>
            <div
              className="domeAddContainer scrollSection"
              style={{ maxHeight: '550px' }}
            >
              <ol className="editingList">
                {this.state.notice.map((item, index) => (
                  <>
                    <NoticeItem
                      key={index}
                      item={item}
                      index={index}
                      removeHandler={(e) => this.removeHandler(e, index, item)}
                      inputHandler={(e) => this.inputHandler(e, index)}
                      inputHandlerDesc={(e) => this.inputHandlerDesc(e, index)}
                    />
                    {/* <div className="fx-jcfe">
                      <small
                        style={{
                          color: `${item.sub_notice[0].description.length < 90 ? 'lightgrey' : 'red'
                            }`,
                        }}
                      >
                        ({90 - item.sub_notice[0].description.length}/90)
                      </small>
                    </div> */}
                  </>
                ))}

                <div className="fx-jcfe mt-10 mb-10">
                  <button onClick={this.addMore} className="ant-btn addMore">
                    +
                  </button>
                </div>
              </ol>

              <div className="grid-row fx-jcc">
                <div className="col col-6 fx-jcsb">
                  <button
                    onClick={this.props.closeNotice}
                    className="ant-btn primary-ghost-btn xs"
                  >
                   {translation("Cancel")} 
                  </button>
                  <button
                    onClick={this.publishNotice}
                    className="ant-btn yellow-gradient xs"
                  >
                    {translation("Publish")} 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(DomeNotice);
