import { baseURL, characterAPI, characterStoryAPI, localizationUploadFiles } from '../lib/config';
import { store } from '../helpers/store';
import { notification } from 'antd';
import { filesUploadErr } from '../lib/strings/error';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

export const initialCreateCharacterStory = async () => {
  try {
    const fetchResponse =   await fetch(baseURL + characterStoryAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.story.id;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject('failed to create character story');
    }
  } catch (error) {
    return error;
  }
};

export const createCharacterStory = async (contestData: any) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${characterStoryAPI}`, {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      Promise.reject('failed to create character story');
    }
  } catch (error) {
    return error;
  }
};

export const deleteCharacterStory = async (ID: number) => {
  try {
    const fetchResponse =   await fetch(`${baseURL}${characterStoryAPI}/${ID}`, {
      method: 'DELETE',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.character;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('Failed to delete contest');
    }
  } catch (error) {
    return error;
  }
};

export const getCharacterStory = async () => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/shop/stickers', {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data.stories;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to fetch contests');
    }
  } catch (error) {
    return error;
  }
};

export const updateCharacterStoryWithPercantage = async (character: any, listener: any) => {
  try {
    // backend refuse empty string
    let auxilliary_description = character.auxilliary_description;
    if (character.auxilliary_description === '') {
      auxilliary_description = ' ';
    }

    const formData = new FormData();
    formData.append('title', character.title);
    formData.append('description', character.description);
    formData.append('sub_title', character.sub_title);
    formData.append('characterID', character.characterID);
    formData.append('auxilliary_description', auxilliary_description);
    formData.append('video_thumbnail', character.thumb);
    formData.append('preview_image', character.image);
    formData.append('preview_video', character.video);
    formData.append('optional_image', character.bottomImage);
    formData.append('titleShowsInList', character.titleShowsInList);
    formData.append('titleShowsInDetails', character.titleShowsInDetails);
    formData.append('subtitleShowsInList', character.subtitleShowsInList);
    formData.append('subtitleShowsInDetails', character.subtitleShowsInDetails);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open('PUT', `${baseURL}${characterStoryAPI}/${character.id}`, true);
      xhr.setRequestHeader('access-token', store.getState().authentication.user.newToken);
      xhr.responseType = 'json';

      xhr.upload.onprogress = (e: ProgressEvent) => {
        const perc = Math.floor((e.loaded / e.total) * 1000) / 10;
        if (listener && listener.onProgress) listener.onProgress(perc);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            notification.error({
              message: filesUploadErr,
            });
            reject(status);
          }
        }
      };
      xhr.send(formData);
    });
  } catch (error) {
    notification.error({
      message: filesUploadErr,
    });
    return error;
  }
};

export const postStoryLocalization = async (story) => {
  try {
    const fetchResponse =   await fetch(baseURL + '/api/armax/localization', {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(story),
    });
    const responseStatus = fetchResponse.status;
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data.stories;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      notification.error({
        message: filesUploadErr,
      });
      return Promise.reject('failed to fetch contests');
    }
  } catch (error) {
    notification.error({
      message: filesUploadErr,
    });
    return error;
  }
};

export const getStoryLocalization = async (characterStoryId) => {
  try {
    const fetchResponse =   await fetch(
      `${baseURL}/api/armax/localization/byModel/story/${characterStoryId}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const responseStatus = fetchResponse.status;
    const response =   await fetchResponse.json();

    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject('failed to fetch contests');
    }
  } catch (error) {
    return error;
  }
};

// this API is for non english files
export const updateCharacterStoryFilesWithPercantage = async (character: any, listener: any) => {
  try {
    const formData = new FormData();
    formData.append('video_thumbnail', character.thumb);
    formData.append('preview_image', character.image);
    formData.append('preview_video', character.video);
    formData.append('optional_image', character.bottomImage);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open('PUT', `${baseURL}${localizationUploadFiles}`, true);
      xhr.setRequestHeader('access-token', store.getState().authentication.user.newToken);
      xhr.responseType = 'json';

      xhr.upload.onprogress = (e: ProgressEvent) => {
        const perc = Math.floor((e.loaded / e.total) * 1000) / 10;
        if (listener && listener.onProgress) listener.onProgress(perc);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            notification.error({
              message: filesUploadErr,
            });
            reject(status);
          }
        }
      };
      xhr.send(formData);
    });
  } catch (error) {
    notification.error({
      message: filesUploadErr,
    });
    return error;
  }
};
