import React from 'react';
import CloseXsmall from '../../assets/closeXsmall.svg';
import PropTypes from 'prop-types';

function Tag(props) {
  const { children, onClose, id, closable } = props;

  const onCloseHandler = () => {
    if (id) {
      onClose(id);
    } else {
      onClose(children);
    }
  };

  return (
    <div className="statistics-tag">
      {children}
      {closable && <img src={CloseXsmall} onClick={onCloseHandler} />}
    </div>
  );
}

Tag.propTypes = {
  children: PropTypes.string,
  onClose: PropTypes.func,
  closable: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tag.defaultProps = {
  onClose: (value) => {
    console.log('missing logic, value', value);
  },
  closable: true,
};

export default Tag;
