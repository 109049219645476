import React from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { OpenStatsButton } from '../../../../components';
import * as statisticsString from '../../../../lib/strings/statistics';
import * as menuActions from '../../../../actions/menuActions';
import * as SubMenuConstants from '../../../../lib/SubMenuConstants';
import { rolePartner, roleAdmin } from '../../../../lib/const';

const Stats = (props) => {
  const { language, openSubMenuFullScreen, role, phoneNumber } = props;
  return (
    <Row className="stats-container">
      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForusersText[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForUsers)}
        />
      )}

      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForTreasuresText[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForTreasure)}
        />
      )}

      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForPointsText[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForPoints)}
        />
      )}

      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForCoinsText[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForCoins)}
        />
      )}

      {/* Partner - master can see this one */}
      {role >= rolePartner && (
        <OpenStatsButton
          label={statisticsString.statisticsForPartners[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForPartners)}
        />
      )}

      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForAddressCoupon[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForCoupon)}
        />
      )}

      {role >= roleAdmin && (
        <OpenStatsButton
          label={statisticsString.statisticsForAddressVoucher[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForVoucher)}
        />
      )}
      
      {role >= roleAdmin && (phoneNumber == "+12139355403"||phoneNumber == "+821077032867") && (
        <OpenStatsButton
          label={statisticsString.statisticsForTeamAssignment[language]}
          onClick={() => openSubMenuFullScreen(SubMenuConstants.statisticsForAssignment)}
        />
      )}

    </Row>
  );
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
  phoneNumber: state.userData.phoneNumber,

});

const mapDispatchToProps = (dispatch) => ({
  openSubMenuFullScreen: (menu) => dispatch(menuActions.openSubmenuFullScreen(menu)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
