export const DefaultZoom = 11; // zoom
// export const DefaultRadius = 7.991312454818256; // km
 export const DefaultRadius = 10.655997027109008;
export const DefaultLat = 45.81444;
export const DefaultLng = 15.97798;

export const EN = 'EN';

export const draft = 1;
export const inReview = 2;
export const approved = 3;
export const declined = 4;
export const pending = 5;
export const finished = 6;

export const roleMaster = 900;
export const rolePartner = 950;
export const roleAdmin = 1000;
export const roleSuperadmin = 1100;
export const ultrasuperadmin = 1200;

export const keyBackspace = 'Backspace';
export const keyDelete = 'Delete';

export const sticker = 1;
export const point = 2;
export const candy = 3;
export const LDiscount = 4; // renamed to AD Discount
export const domeAdd = 5;
export const flash=6;

export const masterToIndividualUsers = '1';
export const masterToAllUsersInRadius = '2';
export const masterToAllClientsInRadius = '3';
export const usersToUsers = '4';

export const requestSuccessful = 1;

export const responseCodes = {
  successful: 1,
  unknownError: 4000000,
  tokenNotValid: 4000007,
  insufficientFunds: 4000125,
  userNotFound: 4000146,
  userNotMaster: 4000149,
};

export const kitPriceMultiplier1 = 0.4;
export const kitPriceMultiplier2 = 200;
export const kitLcashMultiplier = 0.6;
export const kitPriceMultiplier3 = 0.5;

export const contestantPending = 'pending';
export const contestantApproved = 'approved';

export const localizationModels = {
  characterStory: 'story',
  notifications: 'pushnotificationshistory',
};

export const TotalLcash='TotalLcash';
export const  SubTotal='SubTotal';
export const  MorePoints_total='MorePoints_total';
export const MorePoints='MorePoints';


export const Pointsflash='Pointsflash';
export const Pointsflash2='Pointsflash2';
export const Pointsflash3='Pointsflash3';
export const Pointsflash4='Pointsflash4';
export const Pointsflash5='Pointsflash5';


export const Physical1='Physical1';
export const Physical2='Physical2';
export const Physical3='Physical3';
export const Physical4='Physical4';
export const Physical5='Physical5';

 export const  additional_prize2='additional_prize2';
