import React, { Component } from 'react';
import { Row, Typography } from 'antd';
import * as treasureService from '../../../../services/treasureService';
import location_icon from '../../../../assets/treasure_item_more_location_icon.svg';
import * as mapConstants from '../../../../lib/strings/map';
import { languages } from '../../../../lib/interfaces/language';
import { connect } from 'react-redux';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import {b} from '../../../dashboard/menu/i18next'

interface Props {
  location_number: number;
  radius: number;
  lat: number;
  lng: number;
  messageLocations: Array<any>;
  ID: number;
  language: languages;
  treasureUnits: any;
  rec_count:any
  isOpen: boolean;
}

interface State {
  location: string;
}

const mapState = (state: {
  authentication: { language: languages };
  treasureDetails: { treasureUnits: any ,rec_count:any};
}) => {
  const { authentication, treasureDetails } = state; 
  const { treasureUnits,rec_count} = treasureDetails;
  const { language } = authentication;
  return { language, treasureUnits,rec_count};
};

class TreasureLocation extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      location: '',
    };
  }
  
  componentDidMount = async () => {
    const reverse =  await treasureService.reverseGeocode(this.props.lat, this.props.lng);
    if (typeof reverse === 'string') this.setState({ location: reverse });
  };

  componentDidUpdate = async (prevProps: { lat: any; lng: number }) => {
    if (this.props.isOpen) {
      if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
        const reverse =  await treasureService.reverseGeocode(this.props.lat, this.props.lng);
        if (typeof reverse === 'string') this.setState({ location: reverse });
      }
    }
  };

  render() {
    const { language, treasureUnits ,rec_count} = this.props;
    let messageLocations;
    if (this.props.messageLocations) {
      messageLocations = this.props.messageLocations.map((msgLocation, index) => {
        if (this.props.ID === msgLocation.locationID) {
          return (
            <Row type="flex" justify="space-between" key={index}>
              <Typography.Text className="bold">
                {treasureInfo.msgRadius[language]} #{index + 1}
              </Typography.Text>
              <Typography.Text>
                {msgLocation.radius >= 1
                  ? Number(msgLocation.radius).toFixed(2)
                  : Number(msgLocation.radius * 1000).toFixed(2)}{' '}
                {msgLocation.radius >= 1 ? mapConstants.km[language] : mapConstants.m[language]}
              </Typography.Text>
            </Row>
          );
        }
      });
    }

    let count: number = 0;
    if (treasureUnits) {
      treasureUnits.forEach((unit: { locationID: number; isCollected: number,rec_count:any }) => {
        if (unit.locationID === this.props.ID && rec_count === unit.rec_count && unit.isCollected=== 1) {
          count++;
        }
      });
    }

    return (
      <Row type="flex" justify="center" className="treasure-location">
        <Row type="flex" justify="center" align="middle">
          <img src={location_icon} className="location-icon" />
          <Typography.Text className="bold">#{this.props.location_number + 1}</Typography.Text>
        </Row>
        <Row type="flex" justify="center" className="treasure-geocoding bold">
          {this.state.location}
        </Row>
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {b?.t('Treasure Radius')}
          </Typography.Text>
          <Typography.Text>
            {this.props.radius >= 1
              ? Number(this.props.radius).toFixed(2)
              : Number(this.props.radius * 1000).toFixed(2)}{' '}
            {this.props.radius >= 1 ? mapConstants.km[language] : mapConstants.m[language]}
          </Typography.Text>
        </Row>
        {messageLocations}
        <Row type="flex" justify="space-between">
          <Typography.Text className="bold">
            {b?.t("Collected items")}
          </Typography.Text>
          <Typography.Text>{count}</Typography.Text>
        </Row>
      </Row>
    );
  }
}

export default connect(mapState)(TreasureLocation);
