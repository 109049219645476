import React, { useState, useEffect } from 'react';
import { Row, Col, Select } from 'antd';
import PropTypes from 'prop-types';

function LanguageSelector(props) {
  const { visible, list, onChange, defaultValue, containerStyle } = props;

  if (!visible) {
    return null;
  }

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const onSelectHandler = (value) => setCurrentValue(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  return (
    <Row className="language-localization" style={containerStyle}>
      <Col>
        <Select
          className="language-localization-selector language-localization-selector-picker"
          defaultValue={defaultValue}
          onChange={onSelectHandler}
        >
          {list.map((object) => {
            return (
              <Select.Option className="text-centered" key={object.value} value={object.value}>
                {object.text}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
}

LanguageSelector.propTypes = {
  visible: PropTypes.bool,
  list: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

LanguageSelector.defaultProps = {
  visible: true,
  defaultValue: 'EN',
  list: [
    { value: 'EN', text: 'English' },
    { value: 'KO', text: 'Korean' },
  ],
  onChange: () => {},
};

export default LanguageSelector;
