import React from 'react';

import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import logoPic from '../../../../../src/assets/logoPic.png';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import { getNotification, crateNotification } from '../../../../services/domeService';

const showErrorMessage = (title, description?) => {
	notification.error({
		message: `${title}`,
		description: `${description}`,
		duration: 10,
	});
};

class QandA extends React.Component<any, any> {
	static contextType = AppContext;

	constructor(props: any) {
		super(props);
		this.state = {
			success: '',
			error: '',
		}
	}


	formHandler = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

	}

	getContext = async () => {
		const context = this.context;
		let profile = context.profile || {};
		return profile;
	}

	updateContext = async (data: any) => {
		const context = this.context;
		context.setProfile(data);
	}

	render() {
		const context = this.context;
		return (
			<>
				<Row className="menu-wrapper treasure-menu-wrapper third-dialog">
					<div className="third-card">
						<div className="fx-jcsb-aic mb-20">
							<h4 className="text-primary mb-0 font-bold"> &#62; Q and A</h4>
							<i onClick={this.props.closeQandA} className="iconx cursor">
								<img src={closeIcon} alt="Info" />
							</i>
						</div>
						<div className="domeAddContainer">
							{/* <div className='text-center'>
								<figure className='mb-3'>
									<img src={logoPic} alt="logo" />
								</figure>
								<h2 className='text-muted mb-0'>Coming soon</h2>
							</div> */}
							<div className='fx-jcsb mb-10'>
								<span className='text-primary'>Question</span>
								<span>Username: wecol</span>
							</div>
							<div className='grid-row mb-10'>
								<span className='col col-1 mt-5'>Title</span>
								<div className='col col-11'>
									<input type="text" className='inp regular' placeholder='What color is it?' />
								</div>
							</div>
							<div className='mb-10'>
								<textarea rows={5} className='inp regular'>
									Let me discuss xxxx x xxx x xx xxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</textarea>
							</div>
							<div className='mb-5'>
								<span className='text-primary'>Answer</span>
							</div>
							<div>
								<textarea rows={5} className='inp regular'>
								It is in green colored xxxx x xxx x xx xxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</textarea>
							</div>

							<div className="text-right">
								<button className='ant-btn yellow-gradient xs'>Send</button>
							</div>

							<div className="text-right mt-10 mb-10">
								<button className="ant-btn addMore">+</button>
							</div>
						</div>
					</div>
				</Row>
			</>
		)
	}

}

export default QandA;