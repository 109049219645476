import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import {
  statisticsForTeamAssignment,
  statisticsForAssignmentStrings,
} from '../../../../lib/strings/statistics';
import {getStatisticsForSTeamAssignment,deleteUserByUltraAdmin,UserLoginStats} from '../../../../services/statisticsService';
import {updateUserPermissions} from '../../../../services/statisticsService';
import { useQuery } from 'react-query';
import Statistics2 from './statistics2';
import { showErrorMessage } from '../../../../services/candyEventService';
import {ByUltraUserSigup} from '../../../../services/signinService'
import {usernameVoucherAdmin,userphonenumber}  from '../../../../services/domeService';

function StatisticsForAssignment(props) {
  const { language } = props;
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});
  const [phoneValue,setphoneValue]=useState(0)
 
  useEffect(() => {
    fetchStatisticsTeamAssignment ()
  },[]);

  const [userData, setUserData] = useState([]); 
  const fetchStatisticsTeamAssignment = async () => {
    const response =  await getStatisticsForSTeamAssignment();
     setUserData(response['List']);  
    return response;
  }; 
  const statisticsQuery = useQuery(
    ['StatisticsForAssignment', { ...currentFilters, page: currentPage }],
    fetchStatisticsTeamAssignment,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );
  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);   
  };
  const showSuccessMessage = (title) => {
    notification.success({
      message: `${title}`,
      duration: 10,
    });
  }

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };
  const handleUpdatePermissions = async (username, userId, permissions) => {
    try {
       await updateUserPermissions(username, userId, permissions);
      showSuccessMessage("User Data Updated successfully");      
    } catch (error) {    
      showErrorMessage("Something Went Wrong");
      console.error('Failed to update user permissions:', error);
    }
  };
  
  const handleDeleteUser = async (userId) => {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (confirmed) {
      try {       
        const response =  await deleteUserByUltraAdmin(userId);
        showSuccessMessage("User Deleted successfully");        
        setTimeout(() => {
          // Refresh the page
          window.location.reload();
        }, 1000);         
      } catch (error) {    
        console.error('Failed to delete user:', error);
      }
    }
  }; 

  const XLSX = require('xlsx');
  const handleLoginStats = async (userId) => {   
    try {
      if(localStorage.getItem('startDate') && localStorage.getItem('startDate')){
        const startDate = localStorage.getItem('startDate')
        const endtDate = localStorage.getItem('enddate')
        const response =  await UserLoginStats(userId,startDate,endtDate);           
        if (typeof response['list'] === 'object') {
          const sheetData = response['list'].map((item) => {
            const loginDate = new Date(item.created).toLocaleDateString();
            const loginTime = new Date(item.created).toLocaleTimeString();
            return [
              item.user_id,
              response['user_details'].username,
              response['user_details'].phoneNumber,
              loginDate,
              loginTime,
            ];
          });  
          const workbook = XLSX.utils.book_new();
          const sheet = XLSX.utils.aoa_to_sheet(sheetData);  
          const headers = ['User ID', 'User Name', 'Phone Number', 'Login Dates', 'Login Time'];
          XLSX.utils.sheet_add_aoa(sheet, [headers], { origin: 'A1' });         
          XLSX.utils.book_append_sheet(workbook, sheet, 'User Login Stats');
          const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([wbout], { type: 'application/octet-stream' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;       
          link.download = `${response['user_details'].username}_${response['user_details'].phoneNumber}.xlsx`;
          link.click();
          URL.revokeObjectURL(url);
          showSuccessMessage('User Statistics Fetched successfully');
        } else {
          console.error('Invalid response format. Expected an object.');       
        }
      }else{
        const response =  await UserLoginStats(userId,'','');
        if (typeof response['list'] === 'object') {
          const sheetData = response['list'].map((item) => {
            const loginDate = new Date(item.created).toLocaleDateString();
            const loginTime = new Date(item.created).toLocaleTimeString();
            return [
              item.user_id,
              response['user_details'].username,
              response['user_details'].phoneNumber,
              loginDate,
              loginTime,
            ];
          });          
          const workbook = XLSX.utils.book_new();
          const sheet = XLSX.utils.aoa_to_sheet(sheetData);  
          const headers = ['User ID', 'User Name', 'Phone Number', 'Login Dates', 'Login Time'];
          XLSX.utils.sheet_add_aoa(sheet, [headers], { origin: 'A1' });         
          XLSX.utils.book_append_sheet(workbook, sheet, 'User Login Stats');
          const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([wbout], { type: 'application/octet-stream' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;       
          link.download = `${response['user_details'].username}_${response['user_details'].phoneNumber}.xlsx`;
          link.click();
          URL.revokeObjectURL(url);
          showSuccessMessage('User Statistics Fetched successfully');
        } else {
          console.error('Invalid response format. Expected an object.');       
        }
      }    
    } catch (error) {   
      console.error('Failed to fetch user login stats:', error);
    }
  };
  
  const handleAddUser = async (newUser) => {  
        {
      let username = await usernameVoucherAdmin(newUser.username)
     let data =  await userphonenumber(newUser.phoneNumber); 
    if(data.data.phoneExist==1){
      showSuccessMessage('phone number already registered');
    }
   else {
    try {
      const newUserPermissions = newUser.userPermissions;
      const { phoneNumber } = newUser;    
      if (!phoneNumber.startsWith("+")) {
        showErrorMessage(
          "Invalid phone number format",
          "Phone number must start with '+countrycode'."
        );        
        return;
      }  
      const userDataForSignup = {
        username: newUser.username,
        phoneNumber: newUser.phoneNumber,
        firstName: newUser.firstName,
        userPermissions: newUserPermissions,
        new_role: 1100,
      };  
      await ByUltraUserSigup(userDataForSignup);  
      newUser.isNewRow = false;
      setUserData([...userData]);
      showSuccessMessage('User Added successfully');
      setTimeout(() => {     
      window.location.reload();
      }, 1000);
    } catch (error) {     
      showErrorMessage('Failed to add the user:', error);     
    }
  }
}
  };

  const handlePhoneNumberChange = (event, index) => {
    const updatedPhoneNumber = event.target.value;
    const updatedUserData = [...userData]; // Clone the userData array to avoid mutating state directly
    updatedUserData[index].phoneNumber = updatedPhoneNumber; 
    setUserData(updatedUserData); // Update the state with the new userData array
  };
  const handleNameChange = (event, index) => {
    const updatedFullName = event.target.value;
    const updatedUserData = [...userData];  
    updatedUserData[index].firstName = updatedFullName;
    setUserData(updatedUserData);
  };
  const handleRemoveRow = (rowIndex) => {
    const updatedUserData = [...userData];
    // Remove the row at the specified index
    updatedUserData.splice(rowIndex, 1);
    setUserData(updatedUserData);
  };
  const columns1 = [  
    {
      title: 'Date',
      align: 'center',
      render: () => (
        <>
          {userData?.map((user) => {
            const date = new Date(user.created);
            const formattedDate = date.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            });
            return (
              // Use a conditional statement to check if record.id is defined and not empty
              user.id ? (
                <div key={user.id}>
                {/* <span className='dateLbl text-center'>{formattedDate}</span> */}
                <div style={{ marginTop: '5px', marginRight: '5px' }}>
                  <button className='ant-btn default-btn xs ml-0 mr-5'
                  onClick={() => handleLoginStats(parseInt(user.uid))}
                  >Login Stats</button>
                </div>            
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>                
                </div>
                <br /> {/* Add a line break between each user */}
                <br />
              </div>            
            
              ) : 
              <div key={user.id}>
                <span className='dateLbl text-center'>{formattedDate}</span>
                <div style={{ marginTop: '5px', marginRight: '5px' }}>
                  <button className='ant-btn default-btn xs ml-0 mr-5'
                  onClick={() => handleLoginStats(parseInt(user.uid))}
                  >Login Stats</button>
                </div>            
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>                
                </div>
                <br /> {/* Add a line break between each user */}
                <br />
              </div>
            );
          })}
        </>
      ),
    },    
    
    {
      title: 'Team Profile',
      align: 'center',
      render: (_, index) => (
        <>
          {userData.map((user, userIndex) => (
            <div key={user.id}>
              <div className='teamLoop'>
                <span className='lblText'>Name:</span>
                <input
              className="inpBlock"
              type="text"
              name="name"
              placeholder="Name"
              value={user.firstName || ''}
              onChange={(event) => handleNameChange(event, index)}              
            />
              </div>
              <div className='teamLoop'>
                <span className='lblText'>Username:</span>
                <input
                  type="text"
                  className='inpBlock'
                  name="username"
                  placeholder="Username"
                  value={user.username}
                  readOnly={!user.isAddingUser}
                  onChange={(event) => {
                    const updatedUserData = [...userData];
                    updatedUserData[userIndex].username = event.target.value;
                    setUserData(updatedUserData);
                  }}
                />
              </div>
              <div className='teamLoop'>
                <span className='lblText'>Phone no:</span>
                <input
              type="text"
              className="inpBlock"
              name="phoneNumber"
              placeholder="Phone No."
              value={user.phoneNumber || ''}
              onChange={(event) => {               
                    handlePhoneNumberChange(event, index);                
            }}             
              readOnly={!user.isAddingUser}
           />   
              </div>              
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>              
              </div>
              <br />
            </div>
          ))}
        </>
      ),
    },
    {
    title: 'Access Setting',
    align: 'center',
    render: () => (
      <>
        {userData.map((user, index) => {
        const permissions = user.userPermissions.split(',').map(Number);
        const hasPermission = (permission) => permissions.includes(permission);
        const handlePermissionChange = (permission) => {
          const updatedUserData = [...userData];
          const user = updatedUserData[index];
          const permissions = user.userPermissions.split(',').map(Number);
        
          if (permission === 1) {
            const isSuperadminAllChecked = !user.isSuperadminAllChecked;
            console.log("user.isSuperadminAllChecked", isSuperadminAllChecked);
            console.log("user.userPermissions", user.userPermissions);
        
            // Check if all checkboxes are already unchecked
            const allUnchecked = permissions.every((p) => p !== 1);
        
            if (isSuperadminAllChecked && !allUnchecked) {
              // Uncheck all checkboxes
              user.userPermissions = '';
            } else {
              // Check all checkboxes
              user.userPermissions = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16';
            }
            user.isSuperadminAllChecked = isSuperadminAllChecked;
          } else {
            const updatedPermissions = permissions.includes(permission)
              ? permissions.filter((p) => p !== permission)
              : [...permissions, permission];
        
            if (updatedPermissions.length === 16) {
              user.isSuperadminAllChecked = true;
              user.userPermissions = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16';
            } else {
              user.isSuperadminAllChecked = false;
              user.userPermissions = updatedPermissions.join(',');
            }
          }      
          setUserData(updatedUserData);        
        };
        
        if (user.isAddingUser) {
          return (
            <div key={user.id}>
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div className='accessLoop'>
                    <input
                      className='mr-3'
                      type="checkbox"
                      name="upper"
                      checked={hasPermission(1)}
                      onChange={() => handlePermissionChange(1)} // Example: Toggle permission 1
                    />
                    Superadmin {`>`} All
                  </div>
  
                  <div className='accessLoop'>
                    <input
                      className='mr-3'
                      type="checkbox"
                      name="mid"
                      checked={hasPermission(6)}
                      onChange={() => handlePermissionChange(6)}
                    />
                    Superadmin {`>`}kit
                  </div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="lower" checked={hasPermission(10)}
                    onChange={() => handlePermissionChange(10)} />Sup.admin{'>'}Dome Addr.</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(2)} 
                    onChange={() => handlePermissionChange(2)}/>Sup.admin{'>'}Charactors</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(7)} 
                    onChange={() => handlePermissionChange(7)}/>Superadmin{'>'}Request</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(11)} 
                    onChange={() => handlePermissionChange(11)}/>Superadmin{'>'}Dome Build</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(3)} 
                    onChange={() => handlePermissionChange(3)}/>Superadmin{'>'}Stats</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(8)} 
                    onChange={() => handlePermissionChange(8)}/>Superadmin{'>'}Notifs</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(13)} 
                    onChange={() => handlePermissionChange(13)}/>Superadmin{'>'}Dome Admin</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(4)} 
                    onChange={() => handlePermissionChange(4)}/>Superadmin{'>'}Candy C.</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(9)} 
                    onChange={() => handlePermissionChange(9)}/>Superadmin{'>'}Users</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(5)} 
                    onChange={() => handlePermissionChange(5)}/>Superadmin{'>'}Lbox Admin</div>
                  <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(12)}
                    onChange={() => handlePermissionChange(12)} />Superadmin{'>'}Account</div>
                  <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(16)}
                    onChange={() => handlePermissionChange(16)} />Sup.admin{'>'}Help Admin</div>
                </div>
                <div>
                  <div style={{ marginTop: '5px' }}>
                    <button
                      type="button"
                      className='ant-btn yellow-gradient xs ml-5 mr-5'
                      onClick={() => handleAddUser(user)}
                    >
                      Add
                    </button>
                  </div>
                  <div style={{ marginTop: '5px' }}>
                    <button
                      type="button"
                      className="ant-btn ant-btn-danger xs ml-5 mr-5"
                      onClick={() => handleRemoveRow(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            
            </div>
          );
        } else {
        
          return (
            <div key={user.id}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <div className='accessLoop'>
                    <input
                      className='mr-3'
                      type="checkbox"
                      name="upper"
                      checked={hasPermission(1)}
                      onChange={() => handlePermissionChange(1)} // Example: Toggle permission 1
                    />
                    Superadmin {`>`} All
                  </div>
  
                  <div className='accessLoop'>
                    <input
                      className='mr-3'
                      type="checkbox"
                      name="mid"
                      checked={hasPermission(6)}
                      onChange={() => handlePermissionChange(6)}
                    />
                    Superadmin {`>`}kit
                  </div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="lower" checked={hasPermission(10)}
                    onChange={() => handlePermissionChange(10)} />Sup.admin{'>'}Dome Addr.</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(2)} 
                    onChange={() => handlePermissionChange(2)}/>Sup.admin{'>'}Charactors</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(7)} 
                    onChange={() => handlePermissionChange(7)}/>Superadmin{'>'}Request</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(11)} 
                    onChange={() => handlePermissionChange(11)}/>Superadmin{'>'}Dome Build</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(3)} 
                    onChange={() => handlePermissionChange(3)}/>Superadmin{'>'}Stats</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(8)} 
                    onChange={() => handlePermissionChange(8)}/>Superadmin{'>'}Notifs</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(13)} 
                    onChange={() => handlePermissionChange(13)}/>Superadmin{'>'}Dome Admin</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(4)} 
                    onChange={() => handlePermissionChange(4)}/>Superadmin{'>'}Candy C.</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(9)} 
                    onChange={() => handlePermissionChange(9)}/>Superadmin{'>'}Users</div>
                  </div>
                  <div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional1" checked={hasPermission(5)} 
                    onChange={() => handlePermissionChange(5)}/>Superadmin{'>'}Lbox Admin</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional2" checked={hasPermission(12)}
                    onChange={() => handlePermissionChange(12)} />Superadmin{'>'}Account</div>
                    <div className='accessLoop'><input className='mr-3' type="checkbox" name="additional3" checked={hasPermission(16)} 
                    onChange={() => handlePermissionChange(16)}/>Sup.admin{'>'}Help Admin</div>
                  </div>
                  <div>
                    <div style={{ marginTop: '5px' }}>
                    <button
                      type="button"
                      className='ant-btn yellow-gradient xs ml-5 mr-5'
                      onClick={() => handleUpdatePermissions(user.username, user._id, user.userPermissions)}
                    >
                      Update
                    </button>
                    </div>
                    <div style={{ marginTop: '5px' }}>
                    <button
                      type="button"
                      className="ant-btn default-btn xs ml-5 mr-5"
                      onClick={() => handleDeleteUser(user._id)}>
                      Delete
                    </button>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            );
          }
          })}
        </>
      ),
      
    },
    {
      title: () => (
        <button className="ant-btn ant-btn-primary" onClick={addNewRow}>+</button>
      ),
      render: (_, index) => (
        index === 0 ? null : (
          <button className="ant-btn ant-btn-danger" >
            Delete
          </button>
        )
      ),
    },
  ];  
  const addNewRow = () => {
    const newRow = {
      id: Date.now(), // Unique identifier for the new row
      firstName: '',
      lastName: '',
      username: '',
      phoneNumber: '',
      userPermissions: '',
      isSuperadminAllChecked: false,
      isAddingUser: true,
    };
    setUserData([newRow, ...userData]);
  };

  columns1.forEach((column) => {
    if (typeof column.title === 'function') {
      const TitleComponent = column.title;
      column.title = (
        <div style={{ textAlign: 'center' }}>
          <TitleComponent />
        </div>
      );
    }
  });

  return (
    <>
      <Statistics2
        label={statisticsForTeamAssignment[language]}
         columns={columns1}       
        loading={statisticsQuery.isLoading}
        statisticsType={'StatisticsForAssignment'}
        // clickedUsername={clickedUsername}
        onFiltersChange={onFiltersChangeHandler}
        onPageChange={onPageChangeHandler}
        pageSize={10}
        currentPage={currentPage}
      />
    </>
  );
}
StatisticsForAssignment.propTypes = {};
StatisticsForAssignment.defaultProps = {};
const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

export default connect(mapStateToProps)(StatisticsForAssignment);