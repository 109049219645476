import moment from 'moment';
import { enterLatLng } from '../actions/treasureActions';
import { store } from '../helpers/store';
import { latLng } from './pointFill';
import * as treasureService from '../services/treasureService';

export const fillSticker = async (
  setFieldsValue,
  handleStickerName,
  onHashtagChange,
  onEndDateChange,
  typeOfSticker,
  terasureID
) => {
  typeOfSticker('existing');

  store.dispatch(enterLatLng(latLng.coordinates, 0));

  const res =   await treasureService.updateCharacter(terasureID, '15', 1817, '1', 65, 5);
  store.dispatch({ type: 'SELECTED_CHARACTER', characterID: 15 });
  store.dispatch({
    type: 'CHOSEN_STICKER',
    imgUrl: 'https://dev.loamoa.com/uploads/ptUeXVNCUKxXU1MdarvM25wqK1aCsZvA.webp',
    position: 5,
    sectionID: 65,
    stickerID: 1817,
  });

  setFieldsValue({
    lat0: latLng.coordinates.lat,
    lng0: latLng.coordinates.lng,
    address0: latLng.address,
  });

  handleStickerName({ target: { value: 'quickFill Sticker' } });

  onHashtagChange(['quickFill']);

  onEndDateChange(parseInt(moment().add(7, 'd').format('x')));
};
