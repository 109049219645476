import React from 'react';
import LocationMapControl from './LocationMapControl';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import { locationConstants } from '../../constants/locationConstants';

const mapStateToProps = (state) => ({
  address: state.address,
  locations: state.locations,
});

const mapDispatchToProps = (dispatch) => ({
  setAddress: (payload) => dispatch({ type: locationConstants.SET_ADDRESS, payload }),
  selectLocationRedux: (index) => dispatch({ type: locationConstants.SELECT_LOCATION, index }),
});

const LocationsMapControlList = (props) => {
  const { locations, address, setAddress, selectLocationRedux } = props;

  const changeSelectedLocationAndPan = (e: any) => {
    const location = locations[e.target.value];

    selectLocationRedux(e.target.value);

    setAddress({
      lat: location.latitude,
      lng: location.longitude,
      zoom: location.zoom,
      radius: location.radius,
    });
    address.mapInstance.setZoom(location.zoom);
  };

  let selectedIndex = 0;
  const locationsList = locations.map((location, index) => {
    if (location.selected) {
      selectedIndex = index;
    }
    return <LocationMapControl key={location.ID} index={index} />;
  });

  return (
    <Radio.Group
      className="location-list"
      onChange={changeSelectedLocationAndPan}
      value={selectedIndex}
    >
      {locationsList}
    </Radio.Group>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsMapControlList);
