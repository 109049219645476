import job1 from './job1.JPG';
import job2 from './job2.JPG';
import job3 from './job3.JPG';
import job4 from './job4.JPG';
import job5 from './job5.JPG';
import job6 from './job6.JPG';
import job7 from './job7.JPG';
import job8 from './job8.JPG';
import job9 from './job9.JPG';
import job10 from './job10.JPG';

import job1_1 from './job1.1.JPG';
import job2_1 from './job2.1.JPG';
import job3_1 from './job3.1.JPG';
import job4_1 from './job4.1.JPG';
import job5_1 from './job5.1.JPG';
import job6_1 from './job6.1.JPG';
import job7_1 from './job7.1.JPG';
import job8_1 from './job8.1.JPG';
import job9_1 from './job9.1.JPG';
import job10_1 from './job10.1.JPG';

export const jobportrait = [
  { id: 1, src: job1 },
  { id: 2, src: job2 },
  { id: 3, src: job3 },
  { id: 4, src: job4 },
  { id: 5, src: job5 },
  { id: 6, src: job6 },
  { id: 7, src: job7 },
  { id: 8, src: job8 },
  { id: 9, src: job9 },
  { id: 10, src: job10 },
];

export const joblandscape = [
  { id: 1, src: job1_1 },
  { id: 2, src: job2_1 },
  { id: 3, src: job3_1 },
  { id: 4, src: job4_1 },
  { id: 5, src: job5_1 },
  { id: 6, src: job6_1 },
  { id: 7, src: job7_1 },
  { id: 8, src: job8_1 },
  { id: 9, src: job9_1 },
  { id: 10, src: job10_1 },
];
