import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { Row, Col, Button, Typography, Popconfirm,message,DatePicker  } from 'antd';
import { Table, Paginate} from '../../../../components';
import * as menuActions from '../../../../actions/menuActions';
import * as statisticsStrings from '../../../../lib/strings/statistics';
import PropTypes from 'prop-types';
import countryOptions from '../../../../containers/dashboard/menu/statistics/CountriesName';
import { notification } from 'antd';
import * as statisticsService from '../../../../services/statisticsService';
import { getStatisticsForSTeamAssignment,getStatisticsForAdminTeamAssignment} from '../../../../services/statisticsService';
import moment from 'moment';

import { useQuery } from 'react-query';
import {
    statisticsForTeamAssignment,
  } from '../../../../lib/strings/statistics';
import { data } from '../domeAdmin/domeAdmin';
import ForAdmin from './StatisticsForAddressForAdmin'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
export const showErrorMessage = (title, description?) => {
    notification.error({
      message: `${title}`,
      description: `${description}`,
      duration: 10,
    });
  };
  const showSuccessMessage = (title) => {
    notification.success({
      message: `${title}`,
      duration: 10,
    });
  };
export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
}

function Statistics2(props) {
    const {
        //     language,
        closeSubmenuFullScreen,
      
        kitList,
       
        role,
        dataCount,
        //     isPartner,
        ...otherProps
    } = props;

    const { width, height } = useWindowDimensions();
    const handleCloseStatistics = () => {
        closeSubmenuFullScreen();
    };
    const { language } = props;
    const statisticsType = 'StatisticsForAssignment'
    const label = statisticsForTeamAssignment[language]
    const [currentFilters, setCurrentFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [statistics, setStatistics] = useState([]);
    const [totalTreasures, setTotalTreasures] = useState(0);
    const [total, setTotal] = useState({});
    const [superAdmin, setSuperAdmin] = useState("active");
    const [admin, SetAdmin] = useState("");
    const [SuperAdmincl, setSuperAdminCl] = useState(true);
    const [Admincl, setAdminCl] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null); 
    const [selectcountry,setSelectcountry]=useState('')   
    const [filterVisible, setFilterVisible] = useState(false);    
    
    const handleChange = async (selectedOption) => {       
        const isd = selectedOption.value.replace('+', '');
        setSelectcountry(isd)
       
      };     
      
    const isDateDisabled = (current) => {       
        return current && current > moment().endOf('day');
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: 200,            
        }),
    };

    const handleStartDateChange = (date) => {
      setStartDate(date);
    };  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };

    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const confirm = () => {   
        if (endDate && startDate) {          
            localStorage.setItem('startDate', startDate.format("YYYY-MM-DD"));
            localStorage.setItem('enddate', endDate.format("YYYY-MM-DD"));
            showSuccessMessage("Filter Applies Successfully")
        } else {
            showErrorMessage('Missing Dates','Please ensure you have selected both the Start and End dates.');   
        }     
        setFilterVisible(false);        
    };
       

    const [userData, setUserData] = useState([]);
    const fetchStatisticsTeamAssignment = async () => {
        const response =  await getStatisticsForSTeamAssignment();
        setUserData(response);    
        return response;
    };
    useEffect(() => {
        fetchStatisticsTeamAssignment()
        fetchStatisticsATeamAssignment() 
    }, []);
    const [userAData, setUserAData] = useState([]);
    const fetchStatisticsATeamAssignment = async () => {     
          const response =  await getStatisticsForAdminTeamAssignment();
          setUserAData(response);         
      };
    const statisticsQuery = useQuery(
        ['StatisticsForAssignment', { ...currentFilters, page: currentPage }],
        fetchStatisticsTeamAssignment,
        {
            staleTime: 1800000,
            cacheTime: 3600000,
        }
    );

    const SuperAdmin = () => {
        localStorage.removeItem('startDate')
        localStorage.removeItem('enddate')
        setSuperAdmin("active")
        setSuperAdminCl(true)
        SetAdmin("")
        setAdminCl(false)

    }


    const Admin = async () => {
        localStorage.removeItem('startDate');
        localStorage.removeItem('enddate');
        setSuperAdmin("");
        setSuperAdminCl(false);
    
        // Fetch data for Admin
        try {
            const response = await getStatisticsForAdminTeamAssignment();
            setUserAData(response);
            SetAdmin("active")
            setAdminCl(true);
        } catch (error) {
            showErrorMessage('Error fetching data for Admin','Somehting went wrong please try after sometime');
        }
    };    

    useEffect(() => {
        if (statisticsQuery.data) {
            const parsedStatistics = [data]     
                        setTotal({                
                totalBalance: statisticsQuery.data.Count-1,             
            });
            setStatistics(parsedStatistics);
            setTotalTreasures(statisticsQuery.data.Count);
        }
    }, [statisticsQuery.data]);


    const onPageChangeHandler = async (page) => {
        setCurrentPage(page);
    };
    return (
        <>
            <Row
                className="menu-wrapper full-screen-submenu"
            >
                {/* Back button and title */}
                <Row className="contest-top-row" type="flex" justify="start" align="middle">
                    <Button onClick={handleCloseStatistics} className="details-arrow">
                        <img src={arrow} alt="close" />
                    </Button>
                    <Typography.Text className="contest-title bold">{label}</Typography.Text>
                </Row>
                <div className='statisticsTable' style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                    {/* Filter and page numbers */}
                    <Row type="flex" justify="space-between">
                    <Col>
                            <style>
                                {`.ant-popover-buttons { display: none !important; } `}
                                {`.anticon-exclamation-circle { display: none !important; }`}
                            </style>
                            <Popconfirm
    placement="bottomLeft"
    visible={filterVisible}
    title={
        <>
            <DatePicker
                placeholder="Start Date"
                onChange={handleStartDateChange}
                disabledDate={isDateDisabled}
            />
            <DatePicker
                placeholder="End Date"
                onChange={handleEndDateChange}
                disabledDate={isDateDisabled}
            />
            <Button type="primary" className='xs' onClick={confirm}>
                Apply
            </Button>
        </>
    }
    onVisibleChange={(visible) => setFilterVisible(visible)}
>
    <Button className="statistics-transparent-btn">
        <Row type="flex" justify="start" align="middle">
            <Col>
                <Typography.Text
                    className="statistics-text statistics-bold statistics-underline"
                    style={{ marginLeft: '5px' }}
                >
                    Filter
                </Typography.Text>
            </Col>
        </Row>
    </Button>
</Popconfirm>

                        </Col>

                        <Col> <Row className="text-center fx-aic mb-10">
                            <button
                                onClick={SuperAdmin}
                                className={
                                    'ant-btn primary-ghost-btn smm ml-5 pl-20 pr-20 ' + superAdmin}>
                                Super Admin
                            </button>

                            <button
                                onClick={Admin}
                                className={'ant-btn primary-ghost-btn smm pl-20 pr-20' + admin
                                } style={{ marginLeft: "15px",  }}>
                                Admin
                            </button>
                            {Admincl && 
                            <Select
                            defaultValue={countryOptions[0]}
                            styles={customStyles}
                            className='ml-15 countrySelect'
                            onChange={handleChange}
                            options={countryOptions}
                        />
                        }
                        </Row></Col>
                        <Col>
                            <Paginate
                                onPageChange={(page) => onPageChangeHandler(page.selected)}
                                totalItems={totalTreasures}
                                pageSize={10}
                                forcePage={currentPage}
                            />
                        </Col>
                    </Row>
                    {SuperAdmincl
                        &&
                        <Table
                            width={width - 70}
                            height={height - 220}
                            language={language}
                            loading={statisticsQuery.isLoading}
                            dataSource={statistics}
                            {...otherProps}
                        />
                    }

                    {
                    Admincl &&                    
                   <ForAdmin  selectcountry={selectcountry}/>
                    }

                    {statistics.length !== 0 && !statisticsQuery.isLoading && (
                        <div className="statistics-below-table">
                            {statisticsType === 'StatisticsForAssignment' && (
                                <BellowTable
                                    label={statisticsStrings.TotalnoofTeam[language]}
                                    value={
                                        Admincl
                                            ? dataCount
                                            : parseInt(userData['Count']) - 1
                                    }
                                />
                            )}
                        </div>
                    )}

                </div>
            </Row>
        </>

    );
}

Statistics2.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    statisticsType: PropTypes.string,
    dataSource: PropTypes.array,
    onPageChange: PropTypes.func,
    totalItems: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    total: PropTypes.object,
};

Statistics2.defaultProps = {
    language: 'EN',
    onPageChange: () => { },
    pageSize: 10,
};

const mapStateToProps = (state) => ({
    language: state.authentication.language,
    kitList: state.kitList,
    role: state.userData.role,
    dataCount:state.userData.count
   
});

const mapDispatchToProps = (dispatch) => ({
    closeSubmenuFullScreen: () => dispatch(menuActions.closeSubmenuFullScreen()),
    openStats: () => dispatch(menuActions.openSubmenuFullScreen),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics2);

export function BellowTable(props) {
    const { label, value } = props;
    return (
        <div className="column">
            <div className="row">{label}</div>
            <div className="value">{value}</div>
        </div>
    );
}

BellowTable.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
