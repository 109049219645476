import React, { ChangeEvent, useState, useEffect } from 'react';
import { Row, Button, Typography, Col, Input, Checkbox } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import searchIcon from '../../../../assets/search.svg';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import User from './User';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
// import { getAllUsersList, getIdolUsersList } from '../../../../services/notificationsService';
import { contestConstants } from '../../../../constants/contestConstants';
import { contestantsTitle, selected } from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  handleClose: () => Action;
  category: number;
  contestantsList?: Array<any>;
  storeUsers: (users: Array<any>) => Action;
  selectedUsers: (user: any) => Action;
  contestantIDs: any;
  contestID: number;
  language: languages;
}

const mapState = (state: {
  notification: { category: any; contestantIDs: any; contestID: any; language: any };
  contestantsList: any;
}) => {
  return {
    category: state.notification.category,
    contestantsList: state.contestantsList,
    contestantIDs: state.notification.contestantIDs,
    contestID: state.notification.contestID,
    language: state.notification.language,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
    storeUsers: (list: Array<any>) =>
      dispatch({ type: contestConstants.SHOW_CONTESTANTS_LIST, list }),
    selectedUsers: (user: any) =>
      dispatch({ type: notificationsConstants.SELECTED_CONTESTANT, user }),
  };
};

const SearchContestants = (props: Props) => {
  const [pageNumber, setPageNumber] = useState(1);

  const hanldeCheckboxGroup = (checked: CheckboxValueType[]) => {
    props.selectedUsers(checked);
  };

  const getNewPage = async (which: string) => {
    let pageNo = pageNumber;
    which === 'prev' ? pageNo-- : pageNo++;
    // const res =  await getAllUsersList(pageNo);
    // props.storeUsers(res);
    setPageNumber(pageNo);
  };

  const getIdolUsers = async () => {
    // const contestants =  await getIdolUsersList(1, props.contestID, 'contestants');
    // props.storeUsers(contestants);
  };

  useEffect(() => {
    getIdolUsers();
  }, []);

  const searchUsers = async (e: ChangeEvent<HTMLInputElement>) => {
    // const res =  await getAllUsersList(1, e.target.value);
    // props.storeUsers(res);
  };

  return (
    <Row className="kit-list-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between" align="middle">
          <Col>
            <Button onClick={props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {contestantsTitle[props.language]}
            </Typography.Text>
          </Col>
          <Col style={{ marginRight: '1em' }}>
            <Typography.Text className="bold">
              {props.contestantIDs.length} {selected[props.language]}
            </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="search-container">
          <Row className="search-input-container">
            <Input.Search
              onChange={searchUsers}
              className="search-input"
              placeholder="Search"
              prefix={<img src={searchIcon} />}
            />
          </Row>
          {props.contestantsList.length > 0 ? (
            <Row>
              <Checkbox.Group
                value={props.contestantIDs}
                className="full-width"
                onChange={(checked) => hanldeCheckboxGroup(checked)}
              >
                {props.contestantsList.map((user: any) => {
                  return (
                    <User
                      ID={user.mysqlID}
                      key={user.mysqlID}
                      username={user.username}
                      avatar={user.avatar}
                    />
                  );
                })}
              </Checkbox.Group>
            </Row>
          ) : null}
        </Row>
        <Row>
          <Button onClick={() => getNewPage('prev')}>{'<'}</Button>
          <Button onClick={() => getNewPage('next')}>{'>'}</Button>
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(SearchContestants);
