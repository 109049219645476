import { LanguageMap } from '../interfaces/language';

export const character: LanguageMap<string> = {
  EN: 'Character:',
  KO: '캐릭터',
};

export const duration: LanguageMap<string> = {
  EN: 'Duration:',
  KO: '지속 시간',
};

export const noOfUnits: LanguageMap<string> = {
  EN: 'Number of units:',
  KO: '유닛 개수',
};

export const noOfLocations: LanguageMap<string> = {
  EN: 'Number of locations:',
  KO: '지역 개수',
};

export const clients: LanguageMap<string> = {
  EN: 'Clients:',
  KO: '고객',
};

export const msgRadius: LanguageMap<string> = {
  EN: 'Message Radius:',
  KO: '메시지 범위',
};

export const treasureRadius: LanguageMap<string> = {
  EN: 'Treasure Radius:',
  KO: '보물 범위',
};

export const collected: LanguageMap<string> = {
  EN: 'collected',
  KO: '수집 완료',
};

export const button1: LanguageMap<string> = {
  EN: 'Show Locations',
  KO: '지역 보여주기',
};

export const button2: LanguageMap<string> = {
  EN: 'Hide Locations',
  KO: '지역 숨기기',
};

export const buttonDecline: LanguageMap<string> = {
  EN: 'Decline',
  KO: '거절',
};

export const buttonApprove: LanguageMap<string> = {
  EN: 'Approve',
  KO: '승인',
};

export const buttonClients: LanguageMap<string> = {
  EN: 'Clients',
  KO: '고객',
};

export const buttonDelete: LanguageMap<string> = {
  EN: 'Delete',
  KO: 'Delete',
};

export const CollectedItems: LanguageMap<string> = {
  EN: 'Collected units:',
  KO: 'Collected units:',
};

export const status: LanguageMap<string> = {
  EN: 'Status:',
  KO: '현황',
};

export const draftsText: LanguageMap<string> = {
  EN: 'Draft',
  KO: '드래프트',
};

export const pendingText: LanguageMap<string> = {
  EN: 'Pending',
  KO: '진행중',
};

export const approvedText: LanguageMap<string> = {
  EN: 'Approved',
  KO: '승인',
};

export const declinedText: LanguageMap<string> = {
  EN: 'Declined',
  KO: '거절',
};

export const editButton: LanguageMap<string> = {
  EN: 'Edit',
  KO: '수정',
};

export const expireButton: LanguageMap<string> = {
  EN: 'Expire',
  KO: 'Expire',
};

export const reviewText: LanguageMap<string> = {
  EN: 'In review',
  KO: 'In review',
};

export const type: LanguageMap<string> = {
  EN: 'Type:',
  KO: 'Type:',
};

export const runningText: LanguageMap<string> = {
  EN: 'Running',
  KO: 'Running',
};

export const treasureTypeSticker: LanguageMap<string> = {
  EN: 'Sticker',
  KO: 'Sticker',
};

export const treasureTypePoint: LanguageMap<string> = {
  EN: 'Point',
  KO: 'Point',
};

export const treasureTypeCandy: LanguageMap<string> = {
  EN: 'Candy',
  KO: 'Candy',
};

export const treasureTypeLDiscount: LanguageMap<string> = {
  EN: 'AD Discount',
  KO: 'AD Discount',
};

export const treasureLdiscountValue: LanguageMap<string> = {
  EN: 'AD Discount value:',
  KO: 'AD Discount value:',
};

export const finishedText: LanguageMap<string> = {
  EN: 'Finished',
  KO: 'Finished',
};

export const adsKitNumber: LanguageMap<string> = {
  EN: 'Adskit number:',
  KO: 'Adskit number:',
};

export const flashNumber: LanguageMap<string> = {
  EN: 'Flash number:',
  KO: 'Flash number:',
};

export const totalLcash: LanguageMap<string> = {
  EN: 'Total Lcash:',
  KO: 'Total Lcash:',
};

export const uncollectedUnits: LanguageMap<string> = {
  EN: 'Uncollected units:',
  KO: 'Uncollected units:',
};

export const uncollectedBalance: LanguageMap<string> = {
  EN: 'Uncollected balance (Lcash):',
  KO: 'Uncollected balance (Lcash):',
};
