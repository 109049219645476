import food1 from './food1.JPG';
import food2 from './food2.JPG';
import food3 from './food3.JPG';
import food4 from './food4.JPG';
import food5 from './food5.JPG';
import food6 from './food6.JPG';
import food7 from './food7.JPG';
import food8 from './food8.JPG';
import food9 from './food9.JPG';
import food10 from './food10.JPG';

import food1_1 from './food1.1.JPG';
import food2_1 from './food2.1.JPG';
import food3_1 from './food3.1.JPG';
import food4_1 from './food4.1.JPG';
import food5_1 from './food5.1.JPG';
import food6_1 from './food6.1.JPG';
import food7_1 from './food7.1.JPG';
import food8_1 from './food8.1.JPG';
import food9_1 from './food9.1.JPG';
import food10_1 from './food10.1.JPG';

export const foodportrait = [
  { id: 1, src: food1 },
  { id: 2, src: food2 },
  { id: 3, src: food3 },
  { id: 4, src: food4 },
  { id: 5, src: food5 },
  { id: 6, src: food6 },
  { id: 7, src: food7 },
  { id: 8, src: food8 },
  { id: 9, src: food9 },
  { id: 10, src: food10 },
];

export const foodlandscape = [
  { id: 1, src: food1_1 },
  { id: 2, src: food2_1 },
  { id: 3, src: food3_1 },
  { id: 4, src: food4_1 },
  { id: 5, src: food5_1 },
  { id: 6, src: food6_1 },
  { id: 7, src: food7_1 },
  { id: 8, src: food8_1 },
  { id: 9, src: food9_1 },
  { id: 10, src: food10_1 },
];
