import { notificationsConstants } from '../constants/notificationsConstants';

export const updateNotifications = (value: boolean) => ({
  type: notificationsConstants.UPDATE_NOTIFICATIONS,
  payload: value,
});

export const setNotificationsInitial = () => ({
  type: notificationsConstants.NOTIFICATIONS_INITIAL,
});

export const purgeNotificationDetails = () => ({
  type: notificationsConstants.PURGE_NOTIFICATION_DETAILS,
});

export const setNotificationLocalization = (payload: any) => ({
  type: notificationsConstants.SET_NOTIFICATIONS_LOCALIZATION,
  payload,
});
export const updateNotificationLocalization = (language: string, payload: any) => ({
  type: notificationsConstants.UPDATE_NOTIFICATION_LOCALIZATION,
  language,
  payload,
});
export const purgeNotificationLocalization = () => ({ type: notificationsConstants.PURGE_DATA });
