import { contestConstants } from '../constants/contestConstants';
import { menuConstants } from '../constants/menuConstants';
import * as subMenuConst from '../lib/SubMenuConstants';
import { getContestants } from '../services/contestService';

export const getContestByID = (contest: any) => {
  return async (dispatch: (arg0: { type: string; contest?: any; menu?: string }) => void) => {
    // let response =   await getContestants(contest.ID, 1);

    // let pendingCount = response.pendingSignups;
    // dispatch(storeDetails({ ...contest, pendingCount }));
    dispatch(storeDetails({ ...contest }));
    dispatch(openSubMenu1(subMenuConst.contestDetails));
  };
  function storeDetails(contest: any) {
    return { type: contestConstants.GET_CONTEST_BY_ID, contest };
  }
  function openSubMenu1(menu: string) {
    return { type: menuConstants.OPEN_SUBMENU_1, menu };
  }
};

export const resetContestants = () => ({ type: contestConstants.CONTESTANTS_RESET_STATE });

export const updateContestants = (payload: object) => ({
  type: contestConstants.CONTESTANTS_UPDATE_STATE,
  payload,
});
