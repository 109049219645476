import React, { useState } from 'react';
import { Row } from 'antd';
import Notification from './Notification';

interface Props {
  notificationsList: any;
}

const NotificationsList = (props: Props) => {
  const [notificationID, setNotificationID] = useState(null);

  const handleSelectedNotification = (id: number) => {
    setNotificationID(id);
  };

  return (
    <Row type="flex" justify="center" style={{ width: '100%' }}>
      {props.notificationsList
        .sort((a: any, b: any) => (a.ID < b.ID ? 1 : -1))
        .map((notification: any) => {
          return (
            <Notification
              key={notification.ID}
              selected={notification.ID === notificationID}
              ID={notification.ID}
              title={notification.title}
              timestamp={notification.timestamp}
              type={notification.type}
              url={notification.url}
              description={notification.description}
              contestID={notification.contestID}
              contestName={notification.name}
              handleSelectedNotification={handleSelectedNotification}
            />
          );
        })}
    </Row>
  );
};

export default NotificationsList;
