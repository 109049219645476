import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import Client from './Client';
import { menuConstants } from '../../../../constants/menuConstants';

interface Props {
  language?: languages;
  closeDetails?: () => void;
  treasureDetails: any;
  closeClientsScreen: () => void;
  clients?: Array<any>;
}

const mapState = (state: {
  treasureDetails: any;
  authentication: { language: languages };
  clients: any;
}) => {
  const { treasureDetails, authentication, clients } = state;
  const { language } = authentication;
  return { treasureDetails, language, clients };
};

const mapDispatch = (dispatch: (arg0: { type: string }) => any) => {
  return {
    closeClientsScreen: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
  };
};

const TreasureClients = (props: Props) => {
  const { language } = props;

  return (
    <Row className="clients">
      <Col className="clients-scroll">
        <Row className="clients-top-row" type="flex" justify="space-between" align="middle">
          <Button onClick={props.closeClientsScreen} className="details-arrow">
            <img src={arrow} alt="close" />
          </Button>
          <Typography.Text>
            {props.clients.length} {treasureInfo.collected[language]}
          </Typography.Text>
        </Row>
        {props.clients.map((client: any, index: any) => {
          return (
            <Client
              key={index}
              name={client.username}
              phoneNumber={client.phoneNumber}
              img={client.avatar}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(TreasureClients);
