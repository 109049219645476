import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';
import { Row, Col, Typography } from 'antd';
import ReactPlayer from 'react-player';
import * as contestStrings from '../../../../lib/strings/contest';
import * as contestActions from '../../../../actions/contestActions';
import BackButtonLeftText from '../../../../components/BackButtonLeftText';
import PropTypes from 'prop-types';
import { baseURL } from '../../../../lib/config';
import { like } from '../../../../assets';
import * as menuActions from '../../../../actions/menuActions';

function ContestantDetails(props) {
  const {
    language,
    round,
    contestId,
    selectedContestantIndex,
    updateContestants,
    closeSubmenu2,
  } = props;
  const queryClient = useQueryClient();

  const contestantsList: any = queryClient.getQueryData([
    'ContestantsList',
    { id: contestId, pageNumber: 1, round: round },
  ]);

  useEffect(() => {
    return () => {
      updateContestants({ selectedContestantIndex: null });
    };
  }, []);

  if (contestantsList?.contestants[selectedContestantIndex]) {
    const {
      firstName,
      lastName,
      email,
      songPath,
      songTitle,
      songCaption,
      finalRoundVotes,
      freeChoiceVideo,
      freeChoiceVideoTitle,
      qualifyingRoundVotes,
      description,
    } = contestantsList.contestants[selectedContestantIndex];

    const roundVideoPath = round === 'qualifying' ? baseURL + freeChoiceVideo : baseURL + songPath;
    const roundTitle = round === 'qualifying' ? freeChoiceVideoTitle : songTitle;
    const roundDescription = round === 'qualifying' ? description : songCaption;
    const roundVoteCount = round === 'qualifying' ? qualifyingRoundVotes : finalRoundVotes;

    return (
      <Row className="cashEvent-request-wrapper cashEvent-text">
        <BackButtonLeftText
          label={contestStrings.contestantNumber[language]}
          onClick={closeSubmenu2}
        />
        <Row className="cashExchange-contestant-details scrollable">
          <Col>
            <Row className="contestant-details-username">
              <Typography.Text>{`${firstName} ${lastName}`}</Typography.Text>
            </Row>

            <Row className="contestant-details-email">
              <a href={`mailto: ${email}`}>
                <u>{email}</u>
              </a>
            </Row>

            <Row type="flex" justify="center" className="video-player">
              <Col style={{ flex: 1, maxHeight: '60vh' }}>
                <ReactPlayer url={roundVideoPath} controls pip />
              </Col>
            </Row>

            <Row type="flex" justify="end" align="bottom" className="contestant-details-video-like">
              <Col>
                <img src={like} />
              </Col>
              <Col style={{ paddingLeft: '6px' }}>
                <Typography.Text className="user-item-bellow-name">
                  {' '}
                  {roundVoteCount}
                </Typography.Text>
              </Col>
            </Row>

            <Row className="contestant-details-round-title">
              <Typography.Text>{roundTitle}</Typography.Text>
            </Row>

            <Row className="contestant-details-round-description">
              <Typography.Text>{roundDescription}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Row>
    );
  } else {
    closeSubmenu2();
    return null;
  }
}

ContestantDetails.propTypes = {
  selectedContestantIndex: PropTypes.number,
  round: PropTypes.oneOf(['qualifying', 'final']),
};

ContestantDetails.defaultProps = {
  selectedContestantIndex: 0,
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  round: state.contestants.round,
  contestId: state.contestDetails.ID,
  selectedContestantIndex: state.contestants.selectedContestantIndex,
});

const mapDispatchToProps = (dispatch) => ({
  updateContestants: (payload) => dispatch(contestActions.updateContestants(payload)),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(ContestantDetails);
