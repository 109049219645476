import { responseCodes } from '../lib/const';
import { notification } from 'antd';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import { executeSignOut } from './sharedServices';
import { isEmpty } from 'lodash';
import { serverError } from '../lib/strings/error';

export const getCashExchangeList = async ({ queryKey }) => {
  // for react-query
  const { paymentStatus } = queryKey[1];

  const fetchResponse =   await fetch(
    config.baseURL +
      config.cashExchange +
      '?' +
      new URLSearchParams({ paymentStatus: paymentStatus }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return isEmpty(response.data?.exchangeRequests) ? [] : response.data.exchangeRequests;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};

export const updateCashExchangeUserStatus = async (ID, paymentStatus) => {
  // for react-query
  const fetchResponse =   await fetch(`${config.baseURL}${config.cashExchange}/${ID}`, {
    method: 'PUT',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ paymentStatus: paymentStatus }),
  });

  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};
