import React, { memo } from 'react';
import { Row, Menu } from 'antd';
import * as cashExchangeStrings from '../../../lib/strings/cashExchangeStrings';
import PropTypes from 'prop-types';

function PendingDoneSelector(props) {
  const { onChange, value, language } = props;

  const onChangeHandler = (event) => {
    onChange(event.key);
  };

  return (
    <Row type="flex" justify="space-around" className="admin-review-btn">
      <Menu onClick={onChangeHandler} mode="horizontal" style={{ flex: 1 }} selectedKeys={[value]}>
        <Menu.Item key="pending">{cashExchangeStrings.pending[language]}</Menu.Item>
        <Menu.Item key="done">{cashExchangeStrings.done[language]}</Menu.Item>
      </Menu>
    </Row>
  );
}

PendingDoneSelector.propTypes = {
  language: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['pending', 'done']),
};

PendingDoneSelector.defaultProps = {
  onChange: (value) => {
    console.log('missing logic:', value);
  },
  value: 'pending',
};

export default memo(PendingDoneSelector);
