import React from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import Flag from 'react-flagkit';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

export const statusConstants = {
  pending: 0,
  active: 1,
  inactive: 2,
};

const calculateDuration = (startTime, endTime) => {
  let durationHours = (endTime - startTime) / 1000 / 60 / 60;
  if (durationHours < 0) {
    durationHours = 0;
  }
  if (durationHours > 24) {
    const days = ~~(durationHours / 24);
    const hoursDifference = ~~(durationHours - days * 24);
    return `${days} d ${hoursDifference} h`;
  }
  return `${~~durationHours} h`;
};

const pricePerUnit = (kitType, country) => {
  const countryPrice = kitType.pricesPerCountry.find((pricePerCountry) => {
    return pricePerCountry.country.toLowerCase() === country.toLowerCase();
  });
  if (countryPrice?.price) {
    return countryPrice.price;
  }

  const defaultPrice = kitType.pricesPerCountry.find((pricePerCountry) => {
    return pricePerCountry.country === 'default';
  });
  return defaultPrice.price;
};

export const statusToShow = (status) => {
  switch (status) {
    case statusConstants.pending:
      return React.createElement('div', { style: { color: '#FFCC33' } }, 'P');
    case statusConstants.active:
      return React.createElement('div', { style: { color: '#3CDB52' } }, 'A');
    case statusConstants.inactive:
      return React.createElement('div', { style: { color: '#FF0000' } }, 'F');
  }
};

export const countryFlag = (countryCode: string) => {
  const countryCodeUpperCase = countryCode.toUpperCase();
  return React.createElement(Flag, { country: countryCodeUpperCase });
};

export const statisticsForTreasure = (apiData, kitTypes) => {
  const currentTime = moment();

  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject) => {
    const kitData = kitTypes.find((kitType) => kitType.ID === cloneApiDataObject.kittypeID);

    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.targetDate, 10)).format('D/M/YY');
    if (kitData) cloneApiDataObject.kitType = kitData.name;
    cloneApiDataObject.duration = calculateDuration(
      currentTime,
      cloneApiDataObject.remainedDuration
    );

    // if(kitData && cloneApiDataObject.countryCode) cloneApiDataObject.pricePerUnit = pricePerUnit(kitData, cloneApiDataObject.countryCode)
    cloneApiDataObject.pricePerUnit = cloneApiDataObject.LcashPerUnit;
    cloneApiDataObject.status = statusToShow(cloneApiDataObject.status);
    cloneApiDataObject.id = cloneApiDataObject.treasureID;
    if (cloneApiDataObject.countryCode)
      cloneApiDataObject.country = countryFlag(cloneApiDataObject.countryCode);
    cloneApiDataObject.username = cloneApiDataObject.userName;
  });
  return cloneApiData;
};

export const statisticsForPoints = (apiData) => {
  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.created + index;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.created, 10)).format('D/M/YY');
    if (cloneApiDataObject.countryCode)
      cloneApiDataObject.country = countryFlag(cloneApiDataObject.countryCode);
    if (cloneApiDataObject.pointsConverted)
      cloneApiDataObject.pointsConvertedAsCoins = cloneApiDataObject.pointsConverted;
    if (cloneApiDataObject.pointsCollected)
      cloneApiDataObject.collectedPoints = cloneApiDataObject.pointsCollected;
  });
  return cloneApiData;
};

export const statisticsForCoins = (apiData) => {
  // console.log('apiData', apiData)

  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.created + index;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.created, 10)).format('D/M/YY');
    if (cloneApiDataObject.countryCode)
      cloneApiDataObject.country = countryFlag(cloneApiDataObject.countryCode);
  });
  // console.log('processed data:', cloneApiData)
  return cloneApiData;
};


export const statisticsTeamAssignment = (apiData) => {
  // console.log('apiData^^', apiData)

  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.created + index;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.created, 10)).format('D/M/YY');
    if (cloneApiDataObject.countryCode)
      cloneApiDataObject.country = countryFlag(cloneApiDataObject.countryCode);
  });
  // console.log('processed data:', cloneApiData)
  return cloneApiData;
};




// StatisticForPartners
const addButtonToPartners = (paymentStatus, payString, paidString) => {
  if (paymentStatus === 'pending') {
    return ButtonWithSpinner({
      label: payString,
      className: 'buttons yellow-gradient cashExchange-send-notification-button',
      style: { width: '76px' },
    });
  } else {
    return ButtonWithSpinner({
      label: paidString,
      className: 'buttons yellow-gradient cashExchange-send-notification-button',
      style: { width: '76px', opacity: 0.5 },
      disabled: true,
    });
  }
};

export const statisticsForPartnersAdmin = (apiData, payString, paidString) => {
  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.ID;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.endDate, 10)).format('DD/MM/YYYY');
    cloneApiDataObject.paymentStatus = addButtonToPartners(
      cloneApiDataObject.paymentStatus,
      payString,
      paidString
    );
  });
  return cloneApiData;
 };

const paymentText = (text, waitingString, receivedString) => {
  if (text === 'paid') {
    return Typography.Text({ children: receivedString, style: { color: '#3CDB52' } });
  } else {
    return Typography.Text({ children: waitingString });
  }
};

export const statisticsForPartnersPartner = (apiData, waitingString, receivedString) => {
  let cloneApiData = apiData.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.ID;
    cloneApiDataObject.date = moment(cloneApiDataObject.creationDate).format('DD/MM/YYYY');
    cloneApiDataObject.paymentStatus = paymentText(
      cloneApiDataObject.paymentStatus,
      waitingString,
      receivedString
    );
    cloneApiDataObject.treasureStatus = statusToShow(cloneApiDataObject.treasureStatus);
  });
  return cloneApiData;
};


export const statisticsForUsers = (apiData:any) => {
  let cloneApiData = apiData.List.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    cloneApiDataObject.id = cloneApiDataObject.created + index;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.created, 10)).format('D/M/YY');
    if(cloneApiDataObject.yearOfBirth){  
  cloneApiDataObject.yearOfBirth=moment(parseInt(cloneApiDataObject.yearOfBirth, 10)).format('DD/MM/YY')}
    if (cloneApiDataObject.partnerData==0) {
         cloneApiDataObject.partnerData = '';} else { cloneApiDataObject.partnerData='Y'}
    if (cloneApiDataObject.isMaster=='0') {
          cloneApiDataObject.isMaster = ''} else { cloneApiDataObject.isMaster='Master'}
    if (cloneApiDataObject.country)
        cloneApiDataObject.country = countryFlag(cloneApiDataObject.country);
  });
  return cloneApiData;
};


export const statisticsForCoupon = (apiData:any) => {
  let cloneApiData = apiData.List.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    //cloneApiDataObject.id = cloneApiDataObject.created + index;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.coupon_issue_date, 10)).format('DD/MM/YY');
   if (cloneApiDataObject.country)
       cloneApiDataObject.country = countryFlag(cloneApiDataObject.country);
  });
  return cloneApiData;
};

export const statisticsForVoucher = (apiData:any) => {
  let cloneApiData = apiData.List.map((apiDataObject) => ({ ...apiDataObject }));
  cloneApiData.map((cloneApiDataObject, index) => {
    //cloneApiDataObject.voucherID = cloneApiDataObject.voucherID;
    cloneApiDataObject.date = moment(parseInt(cloneApiDataObject.issue_date, 10)).format('DD/MM/YY');
    cloneApiDataObject.expiration = moment(parseInt(cloneApiDataObject.expiration, 10)).format('DD/MM/YY');
   if (cloneApiDataObject.country)
       cloneApiDataObject.country = countryFlag(cloneApiDataObject.country);
       if (cloneApiDataObject.used=='0') {
        cloneApiDataObject.used = 'Unused'} else { cloneApiDataObject.used='Used'}
        if (cloneApiDataObject.bundle_payment=='1') {
          cloneApiDataObject.bundle_payment = 'o'} else { cloneApiDataObject.bundle_payment='F'}
  });
  return cloneApiData;
};

