import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';

import { Row, Col } from 'antd';
import { BackButtonLeftText } from '../../../../../components';
import UserListPartnerRequests from '../../../../../components/requests/partnerRequests/UserListPartnerRequests';

import * as menuActions from '../../../../../actions/menuActions';
import * as subMenuConstants from '../../../../../lib/SubMenuConstants';
import * as partnerRequestsActions from '../../../../../actions/partnerRequestsActions';
import * as partnerRequestsStrings from '../../../../../lib/strings/partnerRequestsStrings';
import * as partnerRequestsServices from '../../../../../services/partnerRequestsServices';
import { showErrorMessage } from '../../../../../services/sharedServices';
import { serverError } from '../../../../../lib/strings/error';
import PropTypes from 'prop-types';

function PartnerRequests(props) {
  const {
    language,
    closeSubmenu1,
    closeSubmenu2,
    openPartnerRequestsDetails,
    partnerRequestsEditParameters,
    partnerRequestsReset,
    partnerRequests,
  } = props;

  useEffect(() => {
    return () => {
      partnerRequestsReset();
    };
  }, []);

  const partnerRequestsList: any = useQuery(
    ['PartnerRequestsList', {}],
    partnerRequestsServices.getPartnerRequestsList,
    {
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onCloseHandler = () => {
    closeSubmenu2();
    closeSubmenu1();
  };

  const onClickHandler = (index) => {
    partnerRequestsEditParameters({ selectedUserIndex: index });
    openPartnerRequestsDetails();
  };

  return (
    <Row className="partnerRequests-wrapper partnerRequests-text">
      <BackButtonLeftText
        label={partnerRequestsStrings.partnerRequestsTitle[language]}
        onClick={onCloseHandler}
      />

      <Col className="scrollable">
        <UserListPartnerRequests
          type={'done'}
          loading={partnerRequestsList.isLoading}
          data={partnerRequestsList.data}
          onClick={onClickHandler}
          selectedUserIndex={partnerRequests.selectedUserIndex}
        />
      </Col>
    </Row>
  );
}

PartnerRequests.propTypes = {
  language: PropTypes.string,
};

PartnerRequests.defaultProps = {
  language: 'EN',
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  partnerRequests: state.partnerRequests,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openPartnerRequestsDetails: () =>
    dispatch(menuActions.openSubmenu2(subMenuConstants.partnerRequestsDetails)),

  partnerRequestsEditParameters: (payload) =>
    dispatch(partnerRequestsActions.editParameters(payload)),
  partnerRequestsReset: () => dispatch(partnerRequestsActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerRequests);
