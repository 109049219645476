import React, { useState, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import pointImg from '../../../../assets/ar_star_large.svg';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import lcash from '../../../../assets/lcash.svg';
import { kitConstants } from '../../../../constants/kitConstants';
import flashIcon from '../../../../assets/flash.svg';

const { Text } = Typography;

interface Props {
  openDetails: (menu: string) => void;
  selected?: boolean;
  name: string;
  type: string;
  pricesPerCountry: Array<any>;
  adDuration: number;
  storeDetails: (details: any) => void;
  kit: any;
  t_type:any;
  country: string;
  handleSelectedKit: (id: number) => void;
  id: number;
}

const mapDispatch = (dispatch: any) => {
  return {
    openDetails: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    storeDetails: (details: any) => dispatch({ type: kitConstants.KIT_DETAILS, details }),
  };
};

const mapState = (state: any) => {
  return {
    country: state.userData.country,
  };
};

const Kit = (props: Props) => {
  const [defaultPrice, setDefaultPrice] = useState('');

  const openDetails = () => {
     props.openDetails('KitDetails');
    props.storeDetails(props.kit);
    props.handleSelectedKit(props.id);
  };

  useEffect(() => {
    const index = props.pricesPerCountry.findIndex((element) => element.country === props.country);
    if (index !== -1) {
      const defaultKitPrice = props.pricesPerCountry[index].price;
      setDefaultPrice(defaultKitPrice);
    } else {
      setDefaultPrice('-');
    }
  }, []);

  return (
    <Row
      style={{ width: '100%' }}
      onClick={openDetails}
      type="flex"
      justify="center"
      className={props.selected ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      <Row type="flex" justify="space-between" align="middle" style={{ width: '95%' }}>
        {props.t_type=="0" &&
        <Col className="treasure-img">
          <img src={pointImg} />
        </Col>}
        {props.t_type=="1" &&
        <Col className="treasure-img">
          <img src={flashIcon}/>
        </Col>}
        <Col style={{ width: '72%' }}>
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Col>
              <Row>
                <Text className="bold">{props.name}</Text>
              </Row>
              <Row>
                <Text className="bold">{props.type}</Text>
              </Row>
            </Col>
            <Col className="open-kit-details">
              <img src={lcash} />
              <Typography.Text style={{ margin: '0 .7em 0 0.2em' }}>{defaultPrice}</Typography.Text>
              <img src={arrow} className="treasure-arrow" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Kit);
