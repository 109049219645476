import React from 'react';
import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import closeX from '../../../assets/closeX.svg';

function PreviewModal(props) {
  const { imageUrl, visible, onCancel, landscapeOrPortrait } = props;

  const width = landscapeOrPortrait === 'landscape' ? 960 : 540;
  const height = landscapeOrPortrait === 'landscape' ? 540 : 960;

  return (
    <Modal
      visible={visible}
      footer={null}
      centered
      onCancel={onCancel}
      className="resize-modal-to-children"
      bodyStyle={{ width: 'fit-content', padding: '0px' }}
      closeIcon={<img src={closeX} />}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          style={{
            width: width,
            height: height,
          }}
        />
      ) : (
        <div
          style={{
            width: width,
            height: height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
}

PreviewModal.propTypes = {
  imageUrl: PropTypes.string,
  visible: PropTypes.bool,
  landscapeOrPortrait: PropTypes.oneOf(['portrait', 'landscape']),
};

PreviewModal.defaultProps = {
  visible: true,
  landscapeOrPortrait: 'landscape',
};

export default PreviewModal;
