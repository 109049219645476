import { paymentGetSessionAPI, baseURL, paymentVerifyAPI } from '../lib/config';
import { store } from '../helpers/store';
import { responseCodes } from '../lib/const';
import { executeSignOut, showErrorMessage } from './sharedServices';
import { serverError, responseCode, paymentSessionErr } from '../lib/strings/error';
import {b} from '../containers/dashboard/menu/i18next'

export const getPaymentSession = async (returnURL: String, amount: Number) => {
 const lang= localStorage.getItem('lang')
  const statusNameToApi = (lang) => {
    switch (lang) {
      case 'en':
        return 'en';
      case 'ko':
        return 'ko';
        default:
          return 'en';
    }
  };
      if(amount==115){
    try {
      const fetchResponse =   await fetch(baseURL + paymentGetSessionAPI, {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount:11500,
          type:"voucher_bundle",
          returnURL: returnURL,
          lang:statusNameToApi(lang),
        }),
      });   
      const response =   await fetchResponse.json();
      if (response.code === responseCodes.successful) {
        return response;
      } else if (response.code === responseCodes.tokenNotValid) {
        executeSignOut();
      } else {
        showErrorMessage(
          serverError[store.getState().authentication.language],
          `${paymentSessionErr[store.getState().authentication.language]}`
        );
        return Promise.reject(response);
      }
    } catch (error) {
      showErrorMessage(
        serverError[store.getState().authentication.language],
        `${paymentSessionErr[store.getState().authentication.language]}`
      );
      return error;
    }
  }
  try {
    const fetchResponse =   await fetch(baseURL + paymentGetSessionAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: amount,
        returnURL: returnURL,
      }),
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      showErrorMessage(
        serverError[store.getState().authentication.language],
        `${paymentSessionErr[store.getState().authentication.language]}`
      );
      return Promise.reject(response);
    }
  } catch (error) {
    showErrorMessage(
      serverError[store.getState().authentication.language],
      `${paymentSessionErr[store.getState().authentication.language]}`
    );
    return error;
  }
};

export const verifyPayment = async (sessionID: string) => {
  try {
    const fetchResponse =   await fetch(baseURL + paymentVerifyAPI, {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionID: sessionID,
      }),
    });
    const response =   await fetchResponse.json();
        if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      return Promise.reject(response);
    }
  } catch (error) {
    return error;
  }
};

export const getQueryParameterByName = (parameterName, url) => {
  parameterName = parameterName.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + parameterName + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
