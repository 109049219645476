import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import moment from 'moment';
import { Row, Col, Typography } from 'antd';
import ReactPlayer from 'react-player';
import * as contestStrings from '../../../../lib/strings/contest';
import * as contestActions from '../../../../actions/contestActions';
import BackButtonLeftText from '../../../../components/BackButtonLeftText';
import PropTypes from 'prop-types';
import { baseURL, isDev } from '../../../../lib/config';
import * as menuActions from '../../../../actions/menuActions';
import DetailsGrid from '../../../../components/candyEvent/DetailsGrid';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';
import * as contestService from '../../../../services/contestService';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';

function ContestantReviewDetails(props) {
  const { language, contestId, selectedContestantIndex, updateContestants, closeSubmenu2 } = props;
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      updateContestants({ selectedContestantIndex: null });
    };
  }, []);

  const contestantsReviewList: any = useQuery(
    ['ContestantsReviewList', { id: contestId, pageNumber: 1 }],
    contestService.getContestantsQueryReview,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  if (contestantsReviewList?.data?.pendingSignups[selectedContestantIndex]) {
    const {
      username,
      firstName,
      lastName,
      applicationTimestamp,
      freeSongUploadTimestamp,
      finalSongUploadTImestamp,
      email,
      songPath,
      songCaption,
      freeChoiceVideo,
      freeChoiceVideoTitle,
      description,
      signupID,
      status,
      freeChoiceVideoStatus,
    } = contestantsReviewList.data.pendingSignups[selectedContestantIndex];

    const isFreeChoiceVideoPending = freeChoiceVideoStatus === 'pending';
    const isGuideVideoPending = status === 'pending';

    const invalidateReviewList = () => {
      queryClient.invalidateQueries(['ContestantsReviewList', { id: contestId, pageNumber: 1 }]);
    };

    const onFreeChoiceVideoApproveSuccess = () => {
      invalidateReviewList();
      if (!isGuideVideoPending) {
        closeSubmenu2();
      }
    };

    const onApproveFreeChoiceVideoHandler = useMutation(
      () => contestService.approveFreeChoiceVideo(signupID),
      {
        onSuccess: onFreeChoiceVideoApproveSuccess,
        onError: (error) => {
          showErrorMessage(serverError[language], error);
        },
      }
    );

    const onFreeChoiceVideoDeclineSuccess = () => {
      invalidateReviewList();
      closeSubmenu2();
    };

    const onDeclineFreeChoiceVideoHandler = useMutation(
      () => contestService.declineFreeChoiceVideo(signupID),
      {
        onSuccess: onFreeChoiceVideoDeclineSuccess,
        onError: (error) => {
          showErrorMessage(serverError[language], error);
        },
      }
    );

    const onGuideVideoSuccess = () => {
      invalidateReviewList();
      if (!isFreeChoiceVideoPending) {
        closeSubmenu2();
      }
    };

    const onApproveGuideVideoHandler = useMutation(
      () => contestService.approveGuideVideo(signupID),
      {
        onSuccess: onGuideVideoSuccess,
        onError: (error) => {
          showErrorMessage(serverError[language], error);
        },
      }
    );

    const onDeclineGuideVideoHandler = useMutation(
      () => contestService.declineGuideVideo(signupID),
      {
        onSuccess: onGuideVideoSuccess,
        onError: (error) => {
          showErrorMessage(serverError[language], error);
        },
      }
    );

    const dateFormat = isDev ? 'DD/MM/YYYY H:mm:ss' : 'DD/MM/YYYY';

    return (
      <Row className="cashEvent-request-wrapper cashEvent-text">
        <BackButtonLeftText label={contestStrings.review[language]} onClick={closeSubmenu2} />
        <Row className="cashExchange-contestant-details scrollable">
          <Col>
            <DetailsGrid
              title={contestStrings.username[language]}
              titleSpan={10}
              value={username}
            />

            <DetailsGrid
              title={contestStrings.firstName[language]}
              titleSpan={10}
              value={firstName}
            />

            <DetailsGrid
              title={contestStrings.lastName[language]}
              titleSpan={10}
              value={lastName}
            />

            <DetailsGrid
              title={contestStrings.applicationDate[language]}
              titleSpan={15}
              value={moment(applicationTimestamp).format(dateFormat)}
            />

            {freeChoiceVideo && (
              <DetailsGrid
                title={contestStrings.freeSongDate[language]}
                titleSpan={15}
                value={moment(freeSongUploadTimestamp).format(dateFormat)}
              />
            )}

            {songPath && (
              <DetailsGrid
                title={contestStrings.guideSongDate[language]}
                titleSpan={15}
                value={moment(finalSongUploadTImestamp).format(dateFormat)}
              />
            )}

            <DetailsGrid
              title={contestStrings.email[language]}
              titleSpan={10}
              value={email}
              isEmail={true}
            />

            {/* Free choice video */}
            {freeChoiceVideo && (
              <Row>
                <Row type="flex" justify="center">
                  <Typography.Text className="contestant-review-details-section-title">
                    {contestStrings.freeChoiceVideoText[language]}
                  </Typography.Text>
                </Row>

                <Row type="flex" justify="center" className="video-player">
                  <Col style={{ flex: 1, maxHeight: '60vh' }}>
                    <ReactPlayer url={`${baseURL}/${freeChoiceVideo}`} controls pip />
                  </Col>
                </Row>

                <Row className="contestant-details-round-title">
                  <Typography.Text>{freeChoiceVideoTitle}</Typography.Text>
                </Row>

                <Row className="contestant-details-round-description">
                  <Typography.Text>{description}</Typography.Text>
                </Row>

                <Row type="flex" justify="center" style={{ marginTop: '3em', marginBottom: '2em' }}>
                  <ButtonWithSpinner
                    type="transparentRed"
                    label={contestStrings.rejectButton[language]}
                    isLoading={onDeclineFreeChoiceVideoHandler.isLoading}
                    onClick={onDeclineFreeChoiceVideoHandler.mutate}
                    disabled={
                      !isFreeChoiceVideoPending ||
                      onApproveFreeChoiceVideoHandler.isLoading ||
                      onDeclineGuideVideoHandler.isLoading ||
                      onApproveGuideVideoHandler.isLoading
                    }
                  />
                  <div style={{ width: '10px' }} />
                  <ButtonWithSpinner
                    className="buttons purple-gradient"
                    label={contestStrings.approveButton[language]}
                    isLoading={onApproveFreeChoiceVideoHandler.isLoading}
                    onClick={onApproveFreeChoiceVideoHandler.mutate}
                    disabled={
                      !isFreeChoiceVideoPending ||
                      onDeclineFreeChoiceVideoHandler.isLoading ||
                      onDeclineGuideVideoHandler.isLoading ||
                      onApproveGuideVideoHandler.isLoading
                    }
                  />
                </Row>
              </Row>
            )}

            {/* Guide song video */}
            {songPath && (
              <Row>
                <Row
                  type="flex"
                  justify="center"
                  className="contestant-review-details-section-title"
                >
                  <Typography.Text>{contestStrings.guideSongVideo[language]}</Typography.Text>
                </Row>
                <Row type="flex" justify="center" className="video-player">
                  <Col style={{ flex: 1, maxHeight: '60vh' }}>
                    <ReactPlayer url={`${baseURL}/${songPath}`} controls pip />
                  </Col>
                </Row>

                <Row className="contestant-details-round-description">
                  <Typography.Text>{songCaption}</Typography.Text>
                </Row>

                <Row type="flex" justify="center" style={{ marginTop: '3em', marginBottom: '2em' }}>
                  <ButtonWithSpinner
                    type="transparentRed"
                    label={contestStrings.rejectButton[language]}
                    isLoading={onDeclineGuideVideoHandler.isLoading}
                    onClick={onDeclineGuideVideoHandler.mutate}
                    disabled={
                      !isGuideVideoPending ||
                      onDeclineFreeChoiceVideoHandler.isLoading ||
                      onApproveFreeChoiceVideoHandler.isLoading ||
                      onApproveGuideVideoHandler.isLoading
                    }
                  />
                  <div style={{ width: '10px' }} />
                  <ButtonWithSpinner
                    className="buttons purple-gradient"
                    label={contestStrings.approveButton[language]}
                    isLoading={onApproveGuideVideoHandler.isLoading}
                    onClick={onApproveGuideVideoHandler.mutate}
                    disabled={
                      !isGuideVideoPending ||
                      onDeclineFreeChoiceVideoHandler.isLoading ||
                      onApproveFreeChoiceVideoHandler.isLoading ||
                      onDeclineGuideVideoHandler.isLoading
                    }
                  />
                </Row>
              </Row>
            )}
          </Col>
        </Row>
      </Row>
    );
  } else {
    closeSubmenu2();
    return null;
  }
}

ContestantReviewDetails.propTypes = {
  selectedContestantIndex: PropTypes.number,
  round: PropTypes.oneOf(['qualifying', 'final']),
};

ContestantReviewDetails.defaultProps = {
  selectedContestantIndex: 0,
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  round: state.contestants.round,
  contestId: state.contestDetails.ID,
  selectedContestantIndex: state.contestants.selectedContestantIndex,
});

const mapDispatchToProps = (dispatch) => ({
  updateContestants: (payload) => dispatch(contestActions.updateContestants(payload)),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(ContestantReviewDetails);
