// import { Row } from "antd";
// import React from "react";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import closeIcon from "../../../assets/closeXsmall.svg";

import React from 'react';
import Dropzone from 'react-dropzone';
import { Row, Spin, notification } from 'antd';
import ReactCrop from 'react-image-crop';
// import mergeImages from 'merge-images';
import 'react-image-crop/dist/ReactCrop.css';
import AppContext from '../../../AppContext';
import closeIcon from '../../../assets/closeXsmall.svg';
import domeNameCityEg from '../../../assets/domeNameCityEg.png';
import closeWhite from '../../../../src/assets/closeWhite.svg';
import { connect } from 'react-redux';
import {b} from '../../dashboard/menu/i18next'

import {
  FileUpload,
  getDomeArinaApi,
  getDomeTypes,
  postArinaEditApi,
  UpdateSetting,
  putArinaEditApi,
  
} from '../../../services/domeService';
import { blobToFile, getCroppedImg } from '../../../helpers/ImageCropping';
import { baseURL } from '../../../lib/config';

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

class ArenaEdit extends React.Component<any, any> {
  static contextType = AppContext;
  private imageElement: React.RefObject<HTMLImageElement>;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      city: '',
      city_name: '',
      dome_name_2d: '',
      defaultImage: '',
      defaultFileName: '',
      defaultPreview: '',
      defaultFile: '',
      title: '',
      video_url: '',
      url:'',
      src: '',
      crop: {
        unit: 'px', // default, can be 'px' or '%'
        x: 10,
        y: 50,
        width: 100,
        height: 100,
      },
    };
    this.imageElement = React.createRef();
  }

  

  componentDidMount = async () => {
    const profile =   await this.getContext();
    const arena__data = profile['arena__data'] || {};
    if (arena__data && Object.keys(arena__data).length !== 0) {
      const { background_image, city, signage_file, title, url } = arena__data;
      this.setState({
        image: background_image,
        fileName: background_image.name,
        preview: ` https://app.loamoa-dev-merge.cladev.com/uploads/${background_image.id}`,
        // src: ` https://app.loamoa-dev-merge.cladev.com/uploads/${background_image.id}`,
        file: background_image,
        city_name: city,
        defaultImage: signage_file,
        defaultFileName: signage_file.name,
        defaultPreview: ` https://app.loamoa-dev-merge.cladev.com/uploads/${signage_file.id}`,
        defaultFile: signage_file,
        title: title,
        video_url: url,
      });
    }
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  oNDropFileHandler = async (files) => {
    let file = files[0];
    let image = file;
    let fileName = file.name;
    let preview = URL.createObjectURL(file);
    const result =   await FileUpload(file);
    let resultfile = result.file;
    this.setState({
      image: image,
      fileName: fileName,
      preview: preview,
      file: resultfile,
    });
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result =   await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
        src: preview,
      });
    }
  };

  DefaultfileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let defaultImage = e.target.files[0];
      let defaultFileName = e.target.files[0].name;
      let defaultPreview = URL.createObjectURL(e.target.files[0]);
      const result =   await FileUpload(e.target.files[0]);
      let defaultFile = result.file;

      this.setState({
        defaultImage: defaultImage,
        defaultFileName: defaultFileName,
        defaultPreview: defaultPreview,
        defaultFile: defaultFile,
      });
    }
  };

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name]: '',
      },
    });
  };

  publish = async () => {
    if (!this.state.file) {
      showErrorMessage(b?.t('File not uploaded.'));
      return false;
    }
    if (!this.state.city_name) {
      showErrorMessage(b?.t('Please enter City Name.'));
      return false;
    }
    if (!this.state.title) {
      showErrorMessage(b?.t('Please enter title'));
      return false;
    }

    if (!this.validURL(this.state.video_url) || !this.state.video_url) {
      showErrorMessage(b?.t('Please Enter valid Video Url'));
      return;
    }

    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
      background_image: this.state.file,
      city:
        this.state.city_name.charAt(0).toUpperCase() +
        this.state.city_name.slice(1),
      title:
        this.state.title.charAt(0).toUpperCase() + this.state.title.slice(1),
      signage_file: this.state.defaultFile,
        url: this.state.video_url,
      
    };
    this.setState({
      loader: true,
    });
    const res =   await postArinaEditApi(obj, id);
    const arenaRes =   await getDomeArinaApi(id);

    if (arenaRes) {
      profile['arena__data'] = arenaRes;
    } else {
      profile['arena__data'] = null;
    }
    const domeSetting =   await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;
      await this.updateContext(profile);
    // showSuccessMessage(res.description);
    this.setState({
      loader: false,
    });
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
      await this.updateContext(profile);
  };

  truncateStr = (str) => {
    let subText = str.substring(0, 10);
    let remText = subText.replace(/ /g, '');

    let length = remText.length;
    if (length < 8) {
      return `${subText}`;
    }

    if (length === 8) {
      return `${subText}...`;
    }

    if (length > 8) {
      return `${subText.substring(0, 8)}...`;
    }
  };

  onVideoUrlChange = (e) => {
    this.setState({
      video_url: e.target.value,
    });
  };


  remove = async () => {
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
       url:""
    };
    this.setState({
      loader: true,
      video_url:""
    });
    const res =   await putArinaEditApi(obj);
    const arenaRes =   await getDomeArinaApi(id);
    if (arenaRes) { 
      profile['arena__data'] = arenaRes;
      profile.arena__data.url="";
    } 
    const domeSetting =   await getDomeTypes(id);
    profile.domeTypeSetting = domeSetting.dome_type_setting;
      await this.updateContext(profile);
    this.setState({
      loader: false,
    });
  };
  
 



  validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  onChange = (crop) => {
    this.setState({ crop });
  };

  onImageLoaded = (image) => {
    this.imageElement = image;
  };

  onComplete = async (crop) => {
    const croppedFile =   await getCroppedImg(
      this.imageElement,
      crop,
      this.state.fileName,
      this.state.type
    );
    const file = blobToFile(croppedFile, this.state.fileName);
    const result =   await FileUpload(file);
    let resultfile = result.file;

    preview: baseURL + '/uploads/' + resultfile.id;
    let preview = URL.createObjectURL(croppedFile);
    this.setState({
      preview: preview,
      file: resultfile,
    });
  };

  render() {
    const {translation}=this.props
    const context = this.context;
    const profile = context.profile;
    const edit_current_dome = profile['edit_current_dome'];
    let background_image_2d =
      edit_current_dome.background_image_2d !== null
        ? edit_current_dome.background_image_2d.id
        : '';
    return (
      <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; {translation('Arena 2D Icon & Entrance Signage')}
            </h4>
            <i onClick={this.props.onArenaModalClose} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="grid-row sm">
              <div className="col col-4">
                <img
                  src={domeNameCityEg}
                  alt="fig"
                  className="domeNameCityEg"
                />
              </div>

              <div className="col col-8">
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                    {translation("Background Image File")}
                    <em className="required">{translation('Required')}</em>
                  </h4>
                  <div className="grid-row sm">
                    <div className="col col-8">
                      <input
                        type="text"
                        value={
                          this.state.fileName
                          // ? this.state.fileName
                          // : edit_current_dome.background_image_2d !== null
                          // ? edit_current_dome.background_image_2d.name
                          // : ''
                        }
                        className="form-control xs"
                        readOnly
                      />
                    </div>
                    <div className="col col-4">
                      <label className="ant-btn primary-ghost-btn xs btn-block">
                        <input
                          onChange={this.fileHandler}
                          type="file"
                          className="d-none"
                        />
                       {translation('Upload')} 
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className='mb-10'>
									<h4 className="profileImgUpload__label">Dome Name<em className='required'>(Required)</em></h4>
									<input type="text" name="dome_name_2d" onChange={this.handleChange} value={this.state.dome_name_2d} className="form-control xs ttc" />
								</div> */}
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                  {translation('Title(Shown only 8 letters)')} 
                    <em className="required">{translation('Required')}</em>
                  </h4>
                  <input
                    type="text"
                    name="title"
                    onChange={this.handleChange}
                    value={
                      this.state.title &&
                      this.state.title.charAt(0).toUpperCase() +
                      this.state.title.slice(1)
                    }
                    className="form-control xs ttc"
                  />
                </div>
                <div className="mb-10">
                  <h4 className="profileImgUpload__label">
                  {translation('Name of city, district, location')}{' '}
                    <em className="required">{translation('Required')}</em>
                  </h4>
                  <input
                    type="text"
                    name="city_name"
                    onChange={this.handleChange}
                    value={
                      this.state.city_name &&
                      this.state.city_name.charAt(0).toUpperCase() +
                      this.state.city_name.slice(1)
                    }
                    className="form-control xs ttc"
                  />
                </div>
                <div className="text-center profileImgUpload twoDIconPrev">
                  <figure className="photoPrev">
                    <ReactCrop
                      src={this.state.src}
                      onComplete={this.onComplete}
                      onImageLoaded={this.onImageLoaded}
                      crop={this.state.crop}
                      onChange={(newCrop) => this.onChange(newCrop)}
                    />
                  </figure>
                  <h5> {translation('Drag and fit')}</h5>

                  {/* <figure className="photoPrev">
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        this.oNDropFileHandler(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.state.preview && (
                              <img
                                height="120"
                                width="120"
                                src={
                                  this.state.preview
                                  // ? this.state.preview
                                  // : ` https://app.loamoa-dev-merge.cladev.com/uploads/` +
                                  //   background_image_2d
                                }
                              />
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </figure>
                  <h5>Drag and fit</h5> */}
                </div>
              </div>
            </div>
            <div className="grid-row"></div>
            <div className="text-center mb-14">
              <h3 className="text-primary weight-600">{translation('Preview')}</h3>
            </div>
            <div className="twoDPlaceholder">
              <figure
                // style={{ height: '100px' }}
                className="twoDPlaceholder__fig"
              >
                {this.state.preview && (
                  <img
                    src={
                      this.state.preview
                      // ? this.state.preview
                      // : ` https://app.loamoa-dev-merge.cladev.com/uploads/` +
                      //   background_image_2d
                    }
                    alt="Logo"
                  />
                )}
              </figure>
              <figcaption className="twoDPlaceholder__info">
                <h3 className="weight-700">{this.state.dome_name_2d}</h3>
                <h4
                  title={
                    this.state.title &&
                    this.state.title.charAt(0).toUpperCase() +
                    this.state.title.slice(1)
                  }
                  className="weight-700 text-muted"
                >
                  {this.truncateStr(
                    this.state.title.charAt(0).toUpperCase() +
                    this.state.title.slice(1)
                  )}
                  {/* {this.state.title} */}
                </h4>
                <h4
                  title={
                    this.state.city_name &&
                    this.state.city_name.charAt(0).toUpperCase() +
                    this.state.city_name.slice(1)
                  }
                  className="weight-700 text-muted"
                >
                  {this.state.city_name.length > 10
                    ? `${(
                      this.state.city_name.charAt(0).toUpperCase() +
                      this.state.city_name.slice(1)
                    ).substring(0, 10)}...`
                    : this.state.city_name.charAt(0).toUpperCase() +
                    this.state.city_name.slice(1)}
                  {/* {this.state.city_name} */}
                </h4>
              </figcaption>
            </div>
            <div className="mb-14">
              <h3 className="text-primary weight-600">
               {translation('Entrance Signage (optional)')} 
              </h3>
            </div>
            <div
              style={{
                marginTop: '0.3rem',
                display: 'flex',
                marginRight: '1rem',
                borderRadius: '10px',
                width: '100%',
              }}
              className="col"
            >
              {/* <input
                style={{
                  width: '140%',
                  marginRight: '1.3rem',
                }}
                type="text"
                //   value="abc.jpg"
                className="form-control xs rd-0"
                readOnly
              /> */}
              <div style={{ width: '550px' }}>
                <div
                  style={{
                    width: '150px',
                    border: '1px solid black',
                    height: '50px',
                  }}
                >
                  {this.state.defaultPreview && (
                    <img
                      height={50}
                      width={150}
                      src={
                        this.state.defaultPreview
                        //   ? this.state.preview
                        //   : ` https://app.loamoa-dev-merge.cladev.com/uploads/` +
                        //     background_image_2d
                      }
                    />
                  )}
                </div>
              </div>
              <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
                <input onChange={this.DefaultfileHandler} type="file" />
              {translation('Upload')}  
              </label>
            </div>
            <div>
              <h5
                style={{
                  marginLeft: '2rem',
                  marginBottom: '3rem',
                  marginTop: '0.3rem',
                }}
                className="text-primary mb-0 font-bold"
              >
                (Size 450 x 105 px)
              </h5>
            </div>

            <p style={{ marginTop: '20px' }} className="error">
             {translation('Required')} 
            </p>
            <div className="editingList__item__row">
              <div className="editingList__item__row__left fullSide">
                <div className="grid-row xs">
                  <div className="col col-3 fx-aic">
                    <label className="fileLabel">{translation('Video Url')}</label>
                  </div>
                  <div className="col col-8">

                    <input
                      pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                      onChange={this.onVideoUrlChange}
                      type="text"
                      value={this.state.video_url}
                      className="form-control xs rd-0"

                    />

                  </div>
                  <div className="col col-1">
                    <button className="close closeBtn down" onClick={ this.remove} style={{ marginLeft: "40%", marginTop: "-10px" }} ><img  src={closeWhite}alt="Delete" height="14" 
                    />
                    </button>
                  </div>


                </div>
              </div>
            </div>


            <div
              style={{
                marginTop: '1rem',
              }}
              className="grid-row fx-jcc"
            >
              <div className="col col-6 fx-jcsb">
                <button
                  onClick={this.props.onArenaModalClose}
                  className="ant-btn primary-ghost-btn xs"
                >
                  {translation('Cancel')}
                </button>
                <button
                  onClick={this.publish}
                  disabled={this.state.loader ? true : false}
                  className="ant-btn yellow-gradient xs"
                >
                  {this.state.loader ? translation('Loading...') : translation('Edit')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)( ArenaEdit);

// type arArenaEditModalProps = {
//   onArenaModalClose: () => void;
// };

// const ArenaEdit = (props: arArenaEditModalProps) => {
//   return (
//     <>
//       <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
//         <div className="third-card relative">
//           <div className="fx-jcsb-aic mb-20">
//             <h4 className="text-primary mb-0 font-bold">
//               &#62; Arena 2D Icon & Entrance Signage
//             </h4>
//             <i className="iconx cursor">
//               <img
//                 onClick={(e) => props.onArenaModalClose()}
//                 src={closeIcon}
//                 alt="Info"
//               />
//             </i>
//           </div>

//           <div
//             style={{
//               display: "flex",
//             }}
//           >
//             <div
//               style={{
//                 marginRight: "1.6rem",
//                 marginLeft: "1.6rem",
//               }}
//             >
//               <div
//                 style={{
//                   width: "8rem",
//                   boxShadow:
//                     "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
//                   height: "12rem",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "8rem",
//                     backgroundColor: "gray",
//                     height: "6rem",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <img></img>
//                 </div>
//               </div>
//             </div>
//             <div>
//               <div
//                 style={{
//                   marginTop: "0.7rem",
//                   display: "flex",
//                   marginRight: "1rem",
//                   borderRadius: "10px",
//                   width: "100%",
//                 }}
//                 className="col"
//               >
//                 <input
//                   style={{
//                     width: "8.3rem",
//                     marginRight: "1.3rem",
//                   }}
//                   type="text"
//                   //   value="abc.jpg"
//                   className="form-control xs rd-0"
//                 />
//                 <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
//                   <input type="file" />
//                   Upload
//                 </label>
//               </div>
//               <div
//                 style={{
//                   marginTop: "0.7rem",
//                   width: "11.3rem",
//                   borderRadius: "10px",
//                 }}
//                 className="col col-8"
//               >
//                 <input
//                   type="text"
//                   //   value="abc.jpg"
//                   className="form-control xs rd-0"
//                 />
//               </div>
//               <div
//                 style={{
//                   marginTop: "0.7rem",
//                   width: "11.3rem",
//                   borderRadius: "10px",
//                 }}
//                 className="col col-8"
//               >
//                 <input
//                   type="text"
//                   //   value="abc.jpg"
//                   className="form-control xs rd-0"
//                 />
//               </div>
//             </div>
//           </div>
//           <div
//             style={{
//               display: "flex",
//               marginTop: "3rem",
//               marginBottom: "3rem",
//             }}
//           >
//             <div
//               style={{
//                 marginRight: "1.6rem",
//                 marginLeft: "1.6rem",
//               }}
//             >
//               <div
//                 style={{
//                   width: "8rem",
//                   boxShadow:
//                     "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
//                   height: "12rem",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "8rem",
//                     backgroundColor: "white",
//                     borderBottomColor: "black",
//                     height: "6rem",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <img></img>
//                 </div>
//               </div>
//             </div>

//             <div className="col col-4 text-center">
//               <figure className="photoPrev">
//                 {/* <ReactCrop
//                 // src={this.state.src}
//                 // onComplete={this.onComplete}
//                 // onImageLoaded={this.onImageLoaded}
//                 // crop={this.state.crop}
//                 // onChange={(newCrop) => this.onChange(newCrop)}
//                 /> */}
//               </figure>
//               <h5>Drag and fit</h5>
//             </div>
//           </div>

//           <div>
//             <h5
//               style={{
//                 marginLeft: "2rem",
//                 font: "bold",
//               }}
//             >
//               Image file (jpg , jpeg , gif , png.)
//             </h5>
//             <div
//               style={{
//                 marginTop: "0.3rem",
//                 display: "flex",
//                 marginRight: "1rem",
//                 borderRadius: "10px",
//                 width: "100%",
//               }}
//               className="col"
//             >
//               <input
//                 style={{
//                   width: "140%",
//                   marginRight: "1.3rem",
//                 }}
//                 type="text"
//                 //   value="abc.jpg"
//                 className="form-control xs rd-0"
//                 readOnly
//               />
//               <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
//                 <input type="file" />
//                 Upload
//               </label>
//             </div>
//             <h5
//               style={{
//                 marginLeft: "6rem",
//                 marginTop: "0.3rem",
//               }}
//               className="text-primary mb-0 font-bold"
//             >
//               (Size 450 x 105 px)
//             </h5>
//           </div>

//           <div
//             style={{
//               display: "flex",
//             }}
//           >
//             <label
//               style={{
//                 marginTop: "1rem",
//                 marginRight: "1rem",
//               }}
//               className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3"
//             >
//               Cancel
//             </label>
//             <label
//               style={{
//                 marginTop: "1rem",
//                 marginRight: "1rem",
//               }}
//               className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3"
//             >
//               Edit
//             </label>
//           </div>
//         </div>
//       </Row>
//     </>
//   );
// };

// export default ArenaEdit;
