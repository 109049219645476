import React, { useContext, useState } from 'react';
import { Button, Typography, Row, Input, Modal } from 'antd';
import MapZoomIn from '../../assets/map_plus_icon.svg';
import MapZoomOut from '../../assets/map_minus_icon.svg';
import { connect } from 'react-redux';
import * as map from '../../lib/strings/map';
import * as treasureActions from '../../actions/treasureActions';
import { languages } from '../../lib/interfaces/language';
import AppContext from '../../AppContext';

const { Text } = Typography;

interface Props {
  mapInstance?: any;
  locations?: any;
  selectedLocation?: number;
  zoom?: number;
  lat: number;
  updateLocationRadius: (radius: number, selectedLocation: number) => void;
  language: languages;
  address: any;
}

const mapState = (state: {
  address: any;
  locations: any;
  selectedLocation: number;
  authentication: any;
}) => {
  const { address, locations, selectedLocation, authentication } = state;
  const { language } = authentication;
  const { mapInstance, zoom, lat } = address;
  return { mapInstance, locations, selectedLocation, language, zoom, lat, address };
};

const actionCreators = {
  updateLocationRadius: treasureActions.storeLocationRadius,
};

const ZoomControls = (props: Props) => {
  //disableHidePlusMinusButtons
  const profile = useContext(AppContext);
 
  const [showInfoBox, setshowInfoBox] = useState(false);

  const handleZoomIn = () => {
   
    props.mapInstance.setZoom(props.zoom  +1);
  };

  const handleZoomOut = () => {
    props.mapInstance.setZoom(props.zoom - 1);
  };

  const handleRadius = (e: any) => {
    props.updateLocationRadius(Number(e.target.value), props.selectedLocation);    
    let zoom =
      Math.log(
        200 *
          ((156543.03392 * Math.cos((props.lat * Math.PI) / 180)) /
            (props.mapInstance.getZoom() >= 14
              ? e.target.value
              : e.target.value * 1000))
      ) / Math.log(2);

    props.mapInstance.setZoom(Math.round(zoom));
  };
  
  const { language, zoom, address } = props;

  return (
    <div className="zoom-controls">
      <Typography.Text strong style={{ top: '-20px', position: 'absolute' }}>
        Radius
      </Typography.Text>

      <Button
        className="zoom-button zoom-button-hover"
        onClick={handleZoomOut}
        disabled={zoom <= 13|| props.mapInstance.minZoom==zoom}
        
      >
        <img src={MapZoomOut} alt="zoom out" />
      </Button>
      <div
        className="scale-info fredoka"
        onClick={() => {
          setshowInfoBox(true);
        }}
      >
        <Text>
          {address.radius > 1 ? address.radius.toFixed(2) : address.radius.toFixed(2) * 1000}
          {address.radius > 1 ? map.km[language] : map.m[language]}
        </Text>
      </div>
      <Button
        className="zoom-button zoom-button-hover"
        onClick={handleZoomIn}
        disabled={zoom >= 21 || props.mapInstance.maxZoom==zoom}
      >
        <img src={MapZoomIn} alt="zoom in" />
      </Button>

      {showInfoBox ? (
        <Modal
          className="radius-input"
          visible={showInfoBox}
          footer={false}
          closable={true}
          mask={false}
          centered={true}
          width={'35%'}
        >
          <div>
            <Row>
              <Typography.Text className="roboto-regular">
                {`${map.ModalLocationRadius[language]} (${
                  props.zoom < 14 ? map.km[language] : map.m[language]
                })`}
              </Typography.Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Input
                className="fredoka input-treasure"
                placeholder="..."
                defaultValue={
                  props.locations[props.selectedLocation].radius >= 1
                    ? props.locations[props.selectedLocation].radius.toFixed(5)
                    : props.locations[props.selectedLocation].radius.toFixed(2) * 1000
                }
                onBlur={(e) => handleRadius(e)}
              />
              <Button
                className="fredoka white-txt buttons purple-gradient-right"
                onClick={() => setshowInfoBox(false)}
              >
                {map.ok[language]}
              </Button>
            </Row>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};


export default connect(mapState, actionCreators)(ZoomControls);