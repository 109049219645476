import { LanguageMap } from '../interfaces/language';

export const createNewCandyEvent: LanguageMap<string> = {
  EN: 'Create new Candy Challenge',
  KO: 'Create new Candy Challenge',
};

export const candyEvent: LanguageMap<string> = {
  EN: 'CANDY CHALLENGE',
  KO: 'CANDY CHALLENGE',
};

export const missingInputField: LanguageMap<string> = {
  EN: 'Please enter all required fields',
  KO: 'Please enter all required fields',
};

export const eventTitle: LanguageMap<string> = {
  EN: 'Challenge title',
  KO: 'Challenge title',
};

export const eventTitlePlaceholder: LanguageMap<string> = {
  EN: 'Name of the challenge',
  KO: 'Name of the challenge',
};

export const uploadPicture: LanguageMap<string> = {
  EN: 'Upload picture',
  KO: 'Upload picture',
};

export const selectorTitle: LanguageMap<string> = {
  EN: 'Month',
  KO: 'Month',
};

export const selectorPlaceholder: LanguageMap<string> = {
  EN: 'Select',
  KO: 'Select',
};

export const selectorMonthsList = {
  EN: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  KO: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const physicalGoods: LanguageMap<string> = {
  EN: 'Physical goods',
  KO: 'Physical goods',
};

export const digitalGoods: LanguageMap<string> = {
  EN: 'Digital goods',
  KO: 'Digital goods',
};

export const firstPrize: LanguageMap<string> = {
  EN: '1st place',
  KO: '1st place',
};

export const firstPrizePlaceholder: LanguageMap<string> = {
  EN: 'Item 1..',
  KO: 'Item 1..',
};

export const secondPrize: LanguageMap<string> = {
  EN: '2nd - 10th place',
  KO: '2nd - 10th place',
};

export const secondPrizePlaceholder: LanguageMap<string> = {
  EN: 'Item 2..',
  KO: 'Item 2..',
};

export const thirdPrize: LanguageMap<string> = {
  EN: '11th - 100th place',
  KO: '11th - 100th place',
};

export const fourthPrize: LanguageMap<string> = {
  EN: '101th - 500th place',
  KO: '101th - 500th place',
};

export const cancel: LanguageMap<string> = {
  EN: 'Cancel',
  KO: 'Cancel',
};

export const publish: LanguageMap<string> = {
  EN: 'Publish',
  KO: 'Publish',
};

export const thirdPrizePlaceholder: LanguageMap<string> = {
  EN: 'Item 3..',
  KO: 'Item 3..',
};

export const fourthPrizePlaceholder: LanguageMap<string> = {
  EN: 'Item 4..',
  KO: 'Item 4..',
};

export const monthOfTheEvent: LanguageMap<string> = {
  EN: 'Month of the challenge',
  KO: 'Month of the challenge',
};

export const prizes: LanguageMap<string> = {
  EN: 'Prizes',
  KO: 'Prizes',
};

export const reminderText: LanguageMap<string> = {
  EN: 'You don’t have all the informations about the winners. Send them a reminder!',
  KO: 'You don’t have all the informations about the winners. Send them a reminder!',
};

export const sendNotification: LanguageMap<string> = {
  EN: 'Send notification',
  KO: 'Send notification',
};

export const ranking: LanguageMap<string> = {
  EN: 'Ranking',
  KO: 'Ranking',
};

export const contestantDetail: LanguageMap<string> = {
  EN: 'DETAILS',
  KO: 'DETAILS',
};

export const contestantFirstName: LanguageMap<string> = {
  EN: 'First name:',
  KO: 'First name:',
};

export const contestantLastName: LanguageMap<string> = {
  EN: 'Last name:',
  KO: 'Last name:',
};

export const contestantPhoneNumber: LanguageMap<string> = {
  EN: 'Phone number:',
  KO: 'Phone number:',
};

export const contestantEmail: LanguageMap<string> = {
  EN: 'E-mail:',
  KO: 'E-mail:',
};

export const contestantAddress: LanguageMap<string> = {
  EN: 'Address:',
  KO: 'Address:',
};

export const noCandyEvent: LanguageMap<string> = {
  EN: 'There is no Candy Challenge yet!',
  KO: 'There is no Candy Challenge yet!',
};

export const errorTitle: LanguageMap<string> = {
  EN: 'Please enter title',
  KO: 'Please enter title',
};

export const errorGoods: LanguageMap<string> = {
  EN: 'Please select goods',
  KO: 'Please select goods',
};

export const errorFile: LanguageMap<string> = {
  EN: 'Please select picture file',
  KO: 'Please select picture file',
};

export const errorMonth: LanguageMap<string> = {
  EN: 'Please select month',
  KO: 'Please select month',
};

export const deleteButton: LanguageMap<string> = {
  EN: 'Delete',
  KO: 'Delete',
};

export const save: LanguageMap<string> = {
  EN: 'Save',
  KO: 'Save',
};
