import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers/rootReducer';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const LoggerMiddleware = createLogger();

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
  whitelist: ['balance', 'authentication', 'userData', 'isPaymentInitiated'],
};
const pReducer = persistReducer(persistConfig, createRootReducer(history));

export const store = createStore(
  pReducer,
  compose(
    applyMiddleware(
      thunkMiddleware,
      routerMiddleware(history),
      //
      LoggerMiddleware
    )
  )
);

const options: any = { manualPersist: true };

export const persistor = persistStore(store);

/* export const store = createStore(
    createRootReducer(history),
    compose(
        applyMiddleware(
            thunkMiddleware,
            routerMiddleware(history),
            LoggerMiddleware
        )
    )
) */
