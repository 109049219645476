import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

function PortraitOrLandscape(props) {
  const { value, onChange } = props;

  const [selectedMode, setSelectedMode] = useState(value);

  useEffect(() => {
    if (value !== selectedMode) {
      setSelectedMode(value);
    }
  }, [value]);

  const onClickHandler = (value: 'portrait' | 'landscape') => {
    setSelectedMode(value);
    onChange(value);
  };

  const landscapeContainerStyle = ['container', selectedMode === 'landscape' && 'active-container'];
  const landscapeRectangleStyle = [
    'portrait-rectangle',
    'landscape',
    selectedMode === 'landscape' && 'active-rectangle',
  ];

  const portraitContainerStyle = ['container', selectedMode === 'portrait' && 'active-container'];
  const portraitRectangleStyle = [
    'portrait-rectangle',
    selectedMode === 'portrait' && 'active-rectangle',
  ];

  return (
    <Row type="flex" justify="center" align="middle" className="portrait-landscape-rectangle">
      <div
        className={landscapeContainerStyle.join(' ')}
        onClick={() => onClickHandler('landscape')}
      >
        <div className={landscapeRectangleStyle.join(' ')} />
      </div>

      <div className={portraitContainerStyle.join(' ')} onClick={() => onClickHandler('portrait')}>
        <div className={portraitRectangleStyle.join(' ')} />
      </div>
    </Row>
  );
}

PortraitOrLandscape.propTypes = {
  value: PropTypes.oneOf(['landscape', 'portrait']),
  onChange: PropTypes.func,
};

PortraitOrLandscape.defaultProps = {
  value: 'landscape',
  onChange: () => {},
};

export default PortraitOrLandscape;
