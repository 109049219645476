import React from 'react';

import { baseURL } from '../../../../lib/config';

import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { connect } from 'react-redux';
import {b} from '../../../dashboard/menu/i18next'

import burgerKing from '../../../../../src/assets/burgerKing.png';
import {
  FileUpload,
  getDomeTypes,
  UpdateSetting,
} from '../../../../services/domeService';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { blobToFile, getCroppedImg } from '../../../../helpers/ImageCropping';

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

class DomeProfile extends React.Component<any, any> {
  private imageElement: React.RefObject<HTMLImageElement>;
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      src: '',
      type: '',
      crop: {
        unit: 'px', // default, can be 'px' or '%'
        x: 10,
        y: 50,
        width: 100,
        height: 100,
      },
    };
    this.imageElement = React.createRef();
  }

  onChange = (crop) => {
    this.setState({ crop });
  };

  onImageLoaded = (image) => {
    this.imageElement = image;
  };

  onComplete = async (crop) => {
    const croppedFile =  await getCroppedImg(
      this.imageElement,
      crop,
      this.state.fileName,
      this.state.type
    );
    const file = blobToFile(croppedFile, this.state.fileName);
    const result =  await FileUpload(file);
    let resultfile = result.file;

    preview: baseURL + '/uploads/' + resultfile.id;
    let preview = URL.createObjectURL(croppedFile);
    this.setState({
      preview: preview,
      file: resultfile,
    });
  };

  componentDidMount = async () => {
    const profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    current_edit_dome.profile;
    this.setState({
      image: current_edit_dome.profile,
      fileName: current_edit_dome.profile.name,
      preview: baseURL + '/uploads/' + current_edit_dome.profile.id,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  // oNDropFileHandler = async(files) =>{
  // 	let file = files[0];
  // 	let image = file;
  // 	let fileName = file.name;
  // 	let preview = URL.createObjectURL(file);
  // 	const result =  await FileUpload(file);
  // 	let resultfile = result.file;

  // 	this.setState({
  // 		image: image,
  // 		fileName:fileName,
  // 		preview: preview,
  // 		file: resultfile
  // 	});
  // }

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result =  await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
        src: preview,
        type: image.type,
      });
    }
  };

  publish = async () => {
    if (!this.state.file) {
      showErrorMessage(b?.t('File not uploaded.'));
      return false;
    }
    let profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;

    let obj = {
      profile: this.state.file,
    };
    const res =  await UpdateSetting(id, obj);
    const domeSetting =  await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;

    current_edit_dome.profile = this.state.file;
    profile.current_edit_dome = current_edit_dome;
     await this.update(profile);
    showSuccessMessage(b?.t('Dome updated successfully'));
    this.props.closeProfile();
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
     await this.updateContext(profile);
  };

  croppedUrl = (previewUrl) => {
    this.setState({
      preview: previewUrl,
    });
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    const profile = context.profile;
    const current_edit_dome = profile['edit_current_dome'] || {};
    let dome_name = current_edit_dome.dome_name || '';
    let addr_name = current_edit_dome.addr_name || '';
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
          <div className="third-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62; {translation('Profile')}</h4>
              <i
                onClick={(e) => this.props.closeProfile(e)}
                className="iconx cursor"
              >
                <img src={closeIcon} alt="Info" />
              </i>
            </div>

            <div className="domeAddContainer scrollSection">
              <div className="grid-row profileImgUpload">
                <div className="col col-8">
                  <h4>{translation('Profile image file upload')}</h4>
                  <div className="grid-row sm">
                    <div className="col col-8">
                      <input
                        type="text"
                        value={this.state.fileName}
                        className="form-control xs"
                        readOnly
                      />
                    </div>
                    <div className="col col-4">
                      <label className="ant-btn primary-ghost-btn xs btn-block">
                        <input
                          onChange={this.fileHandler}
                          type="file"
                          className="d-none"
                        />
                       {translation('Upload')} 
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col col-4 text-center">
                  <figure className="photoPrev">
                    <ReactCrop
                      src={this.state.src}
                      onComplete={this.onComplete}
                      onImageLoaded={this.onImageLoaded}
                      crop={this.state.crop}
                      onChange={(newCrop) => this.onChange(newCrop)}
                    />
                  </figure>
                  <h5>{translation('Drag and fit')} </h5>
                </div>
              </div>

              <div className="text-center mb-20">
                <h3 className="text-primary">{translation('Preview')}</h3>
              </div>

              <ul className="uploadedItemList">
                {/* <li>
								<figure>
									{this.state.preview ?<img src={this.state.preview} alt="" /> : '' }
								</figure>
								<figcaption>
									<h3>{dome_name}</h3>
								</figcaption>
							</li>
							<li>
								<figure>
                                {this.state.preview ?<img src={this.state.preview} alt="" /> : '' }
								</figure>
								<figcaption>
									<h3>{addr_name}</h3>
								</figcaption>
							</li> */}
                <li>
                  <figure>
                    {this.state.preview ? (
                      <img src={this.state.preview} alt="" />
                    ) : (
                      ''
                    )}
                  </figure>
                  <figcaption>
                    <h4 className="font-bold mb-0">
                      <span className="text-muted">@</span>
                    </h4>
                    <p className="text-dark size13 mb-0">000aaaa191</p>
                    <div className="grid-row">
                      <div className="col col-6">
                        <h4 className="text-default size12 mb-0">{translation('Latitude')}</h4>
                        <p className="size12 text-muted">25.59430213102</p>
                        
                      </div>
                      <div className="col col-6">
                        <h4 className="text-default size12 mb-0">{translation('Longitude')}</h4>
                        <p className="size12 text-muted">85.13888749960</p>
                      </div>
                    </div>
                  </figcaption>
                </li>
              </ul>

              <div className="grid-row fx-jcc">
                <div className="col col-6 fx-jcsb">
                  <button
                    onClick={(e) => this.props.closeProfile(e)}
                    className="ant-btn primary-ghost-btn xs"
                  >
                   {translation('Cancel')} 
                  </button>
                  <button
                    onClick={this.publish}
                    className="ant-btn yellow-gradient xs"
                  >
                   {translation('Edit')}  
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
import { from } from '../../../../lib/strings/statistics';
import { Translation } from 'react-i18next';
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});

export default connect(mapStateToProps, null) (DomeProfile);
