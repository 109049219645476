import React, { useState, useEffect, useRef, memo } from 'react';
import { Row, Button } from 'antd';
import sliderArrow from '../../../assets/slider-arrow.svg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import PropTypes from 'prop-types';

function Carousel(props) {
  const { items, onChange } = props;
  const carouselRef: any = useRef();
  const [disableLeft, setDisableLeft] = useState(true);
  const [disableRight, setDisableRight] = useState(false);

  const onChangeHandler = (event) => {
    const { item, slide, itemInSlide, isNextSlideDisabled, isPrevSlideDisabled } = event;
    setDisableLeft(isPrevSlideDisabled);
    setDisableRight(isNextSlideDisabled);
    // onChange(event.item)
  };

  const prevSlide = () => {
    carouselRef.current.slidePrev();
  };

  const nextSlide = () => {
    carouselRef.current.slideNext();
  };

  return (
    <Row className="make-new-carousel" type="flex">
      <Button className="carousel-btn" onClick={prevSlide} disabled={disableLeft}>
        <img src={sliderArrow} />
      </Button>

      <AliceCarousel
        ref={carouselRef}
        items={items}
        autoWidth={true}
        disableButtonsControls
        disableDotsControls
        onSlideChanged={onChangeHandler}
      />

      <Button className="carousel-btn" onClick={nextSlide} disabled={disableRight}>
        <img className="right-orientation" src={sliderArrow} />
      </Button>
    </Row>
  );
}

Carousel.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
};

Carousel.defaultProps = {
  onChange: () => {},
};

export default memo(Carousel);
