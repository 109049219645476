import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

function MultiLineInput(props) {
  const {
    getFieldDecorator,
    id,
    label,
    required,
    errorMessage,
    placeholder,
    onChange,
    onBlur,
    minRows,
    maxRows,
    className,
  } = props;
  return (
    <Form.Item label={label}>
      {getFieldDecorator(id, {
        rules: [{ required: required, message: errorMessage }],
      })(
        <Input.TextArea
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          autoSize={{ minRows, maxRows }}
          className={className}
        />
      )}
    </Form.Item>
  );
}

MultiLineInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
};
MultiLineInput.defaultProps = {
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
};

export default MultiLineInput;
