import React, { useState, useEffect, useRef, memo } from 'react';
import { Form, Row, Col, notification, Typography } from 'antd';
import {
  maximumAllowedMediaSize,
  typeJPEG,
  typePNG,
  typeGIF,
  typeWEBP,
  typeMP4,
  typeQuickTime,
  typeAVI,
  typeWMV,
  imageExtensionRegex,
  videoExtensionRegex,
} from '../lib/config';
import UploadIcon from '../assets/ad_placeholder.svg';
import ReactPlayer from 'react-player';
import * as treasureCreation from '../lib/strings/treasureCreation';
import { baseURL } from '../lib/config';
import PropTypes from 'prop-types';

function UploadFile(props) {
  const {
    label,
    type,
    initialFileUrl,
    valueUrl,
    onChange,
    size,
    formClassName,
    formOther,
    language,
    rowType,
    rowJustify,
    disableFileSelect,
    fileTypes,
    id,
  } = props;

  const [currentFileUrl, setCurrentFileUrl] = useState<string | ArrayBuffer>('');

  useEffect(() => {
    if (valueUrl !== currentFileUrl) {
      setCurrentFileUrl(valueUrl);
    }
  }, [valueUrl]);

  const inputRef: any = useRef();

  const newSelectDisplay = (fileUrl, fileType: 'video' | 'image' | 'file', size: 'small') => {
    let fileUrlToShow = fileUrl;
    if (!fileUrl && fileUrl === '' && initialFileUrl) {
      fileUrlToShow = `${baseURL}${initialFileUrl}`;
    }
    switch (fileType) {
      case 'image':
        if (fileUrlToShow) {
          return <img src={fileUrlToShow} className="upload-sticker-image adjust" />;
        } else if (size === 'small') {
          return (
            <img
              src={UploadIcon}
              className="upload-sticker-image"
              style={{ width: '21px', height: '28px' }}
            />
          );
        }
        return <img src={UploadIcon} className="upload-sticker-image" />;
      case 'video':
        if (fileUrlToShow) {
          return <ReactPlayer url={`${fileUrlToShow}`} controls height="auto" />;
        }
        return <img src={UploadIcon} className="upload-sticker-image" />;
      case 'file':
        if (fileUrlToShow) {
          let textToShow: any = currentFileUrl;
          if (textToShow.length > 30) {
            textToShow = 'File' + textToShow.match(/\.[0-9a-z]+$/);
          }
          return (
            <Row type="flex" justify="center">
              <Col>
                <Row type="flex" justify="center">
                  <Col>
                    <img
                      src={UploadIcon}
                      className="upload-sticker-image"
                      style={{ width: '21px', height: '28px' }}
                    />
                  </Col>
                </Row>
                <Row type="flex" justify="center" className="file-upload-file-text">
                  <Col className="fileUploadContainer">
                    <Typography.Text className="file-upload-file-text">
                      {textToShow}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
        return <img src={UploadIcon} className="upload-sticker-image" />;
      default:
        console.warn('wrong case');
    }
  };

  const selectSuportedFileTypes = (type) => {
    switch (type) {
      case 'image':
        return [typeJPEG, typePNG, typeGIF, typeWEBP];
      case 'video':
        return [typeMP4, typeQuickTime, typeAVI, typeWMV];
      case 'file':
        return fileTypes;
    }
  };

  const supportedFileTypes = selectSuportedFileTypes(type);

  const handleFile = (event: any) => {
    event.preventDefault();

    const file = event.target.files[0];
    const type = file.type;

    if (
      selectSuportedFileTypes('image').includes(type) ||
      selectSuportedFileTypes('video').includes(type)
    ) {
      if (file.size < maximumAllowedMediaSize) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setCurrentFileUrl(reader.result);
          onChange({ file: file, fileUrl: reader.result, fileType: type });
        };
      }
    } else if (file.size < maximumAllowedMediaSize) {
      setCurrentFileUrl(file.name);
      onChange({ file: file, fileUrl: file.name, fileType: type });
    } else {
      notification.error({
        message: treasureCreation.TreasureCreationImageUploadMessage[language],
        description: treasureCreation.TreasureCreationImageUploadDescription[language],
      });
    }
  };

  const handleRefClick = (event) => {
    event.persist();
    inputRef.current.value = '';
    inputRef.current.click();
  };

  let fileType = type;
  if (valueUrl || currentFileUrl) {
    const fileToCheck: any = valueUrl && currentFileUrl;

    if (/image/.test(fileToCheck)) {
      fileType = 'image';
    } else if (imageExtensionRegex.test(fileToCheck)) {
      fileType = 'image';
    } else if (/video/.test(fileToCheck)) {
      fileType = 'video';
    } else if (videoExtensionRegex.test(fileToCheck)) {
      fileType = 'video';
    }
  }

  return (
    <Form.Item {...formOther} className={formClassName} label={label}>
      <Row type={rowType} justify={rowJustify}>
        <Col style={{ width: '100%' }}>
          <input
            type="file"
            onChange={handleFile}
            ref={inputRef}
            style={{ visibility: 'hidden' }}
            accept={supportedFileTypes ? supportedFileTypes.join(', ') : ''}
          />
          <div className="upload" onClick={!disableFileSelect ? handleRefClick : () => {}}>
            {newSelectDisplay(currentFileUrl, fileType, size)}
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
}

UploadFile.propTypes = {
  type: PropTypes.string,
  fileTypes: PropTypes.array,
  label: PropTypes.string,
  valueUrl: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', '']),
  formClassName: PropTypes.string,
  formOther: PropTypes.object,
  language: PropTypes.string,
  rowType: PropTypes.string,
  rowJustify: PropTypes.string,
  id: PropTypes.string,
  disableFileSelect: PropTypes.bool,
};

UploadFile.defaultProps = {
  type: 'image',
  label: '',
  initialFileUrl: '',
  valueUrl: '',
  onChange: () => {},
  size: '',
  formClassName: 'roboto-regular image-upload-req',
  language: 'EN',
  rowType: 'flex',
  rowJustify: 'center',
  disableFileSelect: false,
};

export default memo(UploadFile);
