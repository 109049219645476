import React from 'react';
import AppContext from '../../../../AppContext';
import moment from 'moment';
import { notification, Row, Spin, DatePicker } from 'antd';
import { listMyDomes, userUpdates } from '../../../../services/domeService';

import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../../src/assets/location-on-map.svg';

import editIcon from '../../../../../src/assets/icon_edit.svg';
import burgerKing from '../../../../../src/assets/burgerKing.png';
import { connect } from 'react-redux';
import * as userService from '../../../../services/signinService';
import { storeUserDataGlobal } from '../../../../actions/signinActions';
import { LOCAL_STORAGE_USERDATA } from '../../../../lib/config';
import {b} from '../../../dashboard/menu/i18next'
// import * as userService from '../services/signinService';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class AccountSetting extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      profile: { profile_active_class: 'active' },
      assign_the_admin_class: '',
      user: {
        username: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        yearOfBirth: '',
        companyName: '',
        email: '',
        address: '',
      },
    };
  }
  

  componentDidMount = async () => {
    let profile = await this.getContext();
    let yearOfBirth = profile.user.yearOfBirth; 
    const formattedDate = moment(yearOfBirth).format('DD-MM-YYYY') 
    

    this.setState({
        username: profile.user.username,
        phoneNumber: profile.user.phoneNumber,
        firstName: profile.user.firstName,
        lastName: profile.user.lastName,
        yearOfBirth: formattedDate,
        companyName: profile.user.companyName,
        email: profile.user.email,
        address: profile.user.address,
    });
  };

  profileSetting = async () => {
    let profile = {
      ...this.state.profile,
      profile_active_class: 'active',
      assign_the_admin_class: '',
    };
    this.setState({
      profile: profile,
      assign_the_admin_class: '',
    });
    let context =  await this.getContext();
    context.myDomeList = [];
     await this.updateContext(context);
  };
  assignTheAdmin = async () => {
    return false;
    let profile = { ...this.state.profile, profile_active_class: '' };
    this.setState({
      assign_the_admin_class: '',
      profile: profile,
    });
     await this.getDomeList();
  };

  getDomeList = async () => {
    let profile =  await this.getContext();
    profile.loader = true;
     await this.updateContext(profile);

    const domeList =  await listMyDomes();

    profile =  await this.getContext();
    profile.loader = false;
    profile.myDomeList = [];
    profile.myDomeList = domeList;
     await this.updateContext(profile);
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  closeSetting = async () => {
    let profile =  await this.getContext();
    profile.accountSetting = false;
     await this.updateContext(profile);
  };
  handleDateChange = (date, dateString) => {
    const formattedDate = moment(dateString, 'DD-MM-YYYY').format('DD-MM-YYYY');
    
    this.setState({
      yearOfBirth: formattedDate,
    });
  };
  selectDome = async (e: any) => {
    let id = parseInt(e.currentTarget.attributes['data-key'].value);
    const context = this.context;
    let profile = context.profile;
    let myDomeList = profile.myDomeList || [];

    const currentDome = myDomeList.map((item) => {
      if (item.ID === id) {
        const updatedItem = {
          ...item,
          selected: !item.selected,
        };
        return updatedItem;
      }
      return item;
    });

    profile['myDomeList'] = currentDome;
    this.updateContext(profile);
  };

  InputHandler = async (e: any) => {
    let name = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    let data = {
      username: this.state.username,
      phoneNumber: this.state.phoneNumber,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      yearOfBirth: this.state.yearOfBirth,
      companyName: this.state.companyName,
      email: this.state.email,
      address: this.state.address,
    };
    if (!this.state.phoneNumber) {
      showErrorMessage(b?.t('please enter phone'));
      return;
    }
    if (!this.state.firstName) {
      showErrorMessage(b?.t('please enter First Name'));
      return;
    }
    if (!this.state.lastName) {
      showErrorMessage(b?.t('please enter Last Name'));
      return;
    }
    if (this.state.yearOfBirth=="Invalid date") {
      showErrorMessage(b?.t('please enter Year of Birth'));
      return;
    }
    if (!this.state.email) {
      showErrorMessage(b?.t('please enter Email'));
      return;
    }

    const response =  await userUpdates(data);
    const res =  await userService.getUserData(response.user._id);
    localStorage.setItem(LOCAL_STORAGE_USERDATA, JSON.stringify(res));

    this.props.storeUserDataGlobal(res);
    showSuccessMessage(b?.t('Updated Successfully'));
    //console.log(this.state);
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    let profile = context.profile;
    console.log(profile.user);
    let myDomeList = profile.myDomeList || [];
    // let countDome =  myDomeList.length? true: false;
    let loader = profile.loader || false;
    let myProfile = this.state.profile['profile_active_class'] || '';
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
          <div className="second-card gaps relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62; {translation("My Settings")}</h4>
              <div className="fx-aic">
                <i onClick={this.closeSetting} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer">
              <div className="fx-jcsb mt-15">
                <button
                  onClick={this.profileSetting}
                  className={
                    'ant-btn primary-ghost-btn xs ' +
                    this.state.profile.profile_active_class
                  }
                >

                {translation("My Profile")} 
                </button>
                <button
                  onClick={this.assignTheAdmin}
                  disabled
                  className={
                    'ant-btn primary-ghost-btn xs ml-15 mr-15 ' +
                    this.state.assign_the_admin_class
                  }
                >
                  {translation("Assign the admin")} 
                </button>
                <button
                  disabled={true}
                  className="ant-btn primary-ghost-btn xs"
                >
                  {translation("Invite My Dome developer")} 
                </button>
              </div>

              <div className="tab-content-box">
                {/* my-profile-tab */}
                {myProfile && (
                  <div id="my-profile-tab" className="tab-pane">
                    <form onSubmit={this.submit}>
                      <div className="my-setting-form">
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Username")}
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="username"
                              onChange={this.InputHandler}
                              value={this.state.username}
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Mobile Phone")} <sup>*</sup>
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="phoneNumber"
                              onChange={this.InputHandler}
                              value={this.state.phoneNumber}
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("First Name")} <sup>*</sup>
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="firstName"
                              onChange={this.InputHandler}
                              value={this.state.firstName}
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Last Name")} <sup>*</sup>{' '}
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="lastName"
                              onChange={this.InputHandler}
                              value={this.state.lastName}
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Birthday")} <sup>*</sup>
                          </label>
                          <div className="col col-8">
                            <DatePicker
                              name="yearOfBirth"
                              onChange={this.handleDateChange}
                              value={this.state.yearOfBirth ? moment(this.state.yearOfBirth, 'DD-MM-YYYY'): null}
                              format="DD-MM-YYYY"
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Company Name")}
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="companyName"
                              onChange={this.InputHandler}
                              value={this.state.companyName}
                              className="inp default"
                            />
                          </div>
                        </div>
                        <div className="grid-row sm form-group">
                          <label className="col col-4 inp-label">
                          {translation("Email")} <sup>*</sup>
                          </label>
                          <div className="col col-8">
                            <input
                              type="text"
                              name="email"
                              onChange={this.InputHandler}
                              value={this.state.email}
                              className="inp default"
                            />
                          </div>
                        </div>
                        {/* <div className="grid-row sm form-group">
										<label className="col col-4 inp-label">Address</label>
										<div className="col col-8">
											<input type="text" name="address" onChange={this.InputHandler} value={this.state.address} className="inp default" />
										</div>
									</div> */}
                        <div className="grid-row sm form-group mt-10">
                          <label className="col col-4 inp-label">&nbsp;</label>
                          <div className="col col-8">
                            <input
                              type="submit"
                              value={translation("Update")}
                              className="ant-btn yellow-gradient xs"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                <div id="assign-tab" className="tab-pane">
                  {/* <Row className="menu-wrapper treasure-menu-wrapper second-dialog domeSettingInside"> */}
                  {/* {loader &&
								<Spin size="large" style={{ marginTop: '2em', marginBottom: '2em' }} />
								}
								{myDomeList.map((dome, index) => (
								<>
									<div data-key={dome.ID} onClick={this.selectDome}  key={dome.ID} className= {dome.selected ? "let-long-loop domeAddLoop active" : "let-long-loop domeAddLoop "} >
										<div className="let-long-loop__left">
											<div className="let-long-loop__left__row mb-10">
												<div className="mr-5">
													<img src={burgerKing} alt="burger King" height="40" />
												</div>
												<div className="let-long-loop__left__row__info">
													<h3 className="font-bold size14 mb-0">
													{dome.addr_name} <span className="text-muted">@</span>{dome.dome_status === 1? dome.dome_name: ''}
												</h3>
													<div className="let-long-loop__left__row__info__row">
														<i className="let-long-loop__left__row__icon">
															<img src={locationIcon} alt="Location" />
														</i>
														<div className="let-long-loop__left__row__info__row__info size10">
															<div className="mr-10">
																<p className="mb-0">Latitude</p>
																<p className="mb-0">{dome.location.x}</p>
															</div>
															<div className="mb-0">
																<p className="mb-0">Longitude</p>
																<p className="mb-0">{dome.location.y}</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="let-long-loop__right">
										<div className="fx-jcsb-aic">
											<div className="size12">
												<p className="mb-3 text-primary">Dome Add status: <br /> {dome.addr_status ? <span className="published">{'Published'}</span> : <span className="unpublished">{'Unpublished'}</span> }</p>
												<p className="text-primary">Dome settings status: <br />{dome.setting_status ? <span className="approved">{"Approved"}</span> : <span className="notSubmitted">{"Not submitted"}</span> }</p>
											</div>
											<div className="iconx">
												<img src={editIcon} alt="icon" height="14" />
											</div>
										</div>
									</div>
									</div>
									{dome.selected &&
								    	<div className="mt-10">
									<div className="grid-row sm form-group">
										<label className="col col-3 inp-label">Email <sup>*</sup></label>
										<div className="col col-5">
											<input type="text" value="gdfws@gmail.com" className="inp default h-30" />
										</div>
										<div className="col col-4">
											<input type="button" value="Verified" className="ant-btn purple-gradient xss" />
											<span className="size12 ml-5">Change</span>
										</div>
									</div>

									<div className="grid-row sm form-group">
										<label className="col col-3 inp-label">Mobile Phone <sup>*</sup></label>
										<div className="col col-5">
											<input type="text" value="12124242322" className="inp default h-30" />
										</div>
										<div className="col col-4">
											<input type="button" value="Verified" className="ant-btn purple-gradient xss" />
											<span className="size12 ml-5">Change</span>
										</div>
									</div>

									<div className="grid-row sm form-group mt-10">
										<label className="col col-4 inp-label">&nbsp;</label>
										<div className="col col-8">
											<input type="submit" value="Submit" className="ant-btn yellow-gradient xs" />
										</div>
									</div>
								</div>
									}
								</>
							   ))} */}
                </div>

                <div id="invite-tab" className="tab-pane d-none"></div>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});

export default connect(mapStateToProps, {storeUserDataGlobal,})(AccountSetting);
