import React, { useState,useEffect } from 'react';
import { Col, Row, Form, Input, Select, Button, Upload, message } from 'antd';
import * as candyEvent from '../../lib/strings/candyEvent';
import UploadFile from '../UploadFile';
import moment from 'moment';
import PropTypes from 'prop-types';
import { baseURL } from '../../lib/config';
 import {getCandyEventList1} from '../../../src/services/candyEventService'
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import Item from 'antd/lib/list/Item';
function CreateGlobalInput(props) {
  const { language, title, month, eventPictureUrl, onChange ,EnterBanner,createurl,onClickk,candyEventList,selectedCountryCode,data,url} = props;
  const { getFieldDecorator } = props.form;
   const [Banner,setBanner]=useState('')
   const [image,setImage]=useState('')
   const [country, setCountry]=useState([])
   const [max,setMax]=useState(0)
  
   const currentDate = new Date()
   const currentMonth = currentDate.getMonth()
   const currentYear:any= currentDate.getFullYear()
   const maxValuedata = moment((max)).format('MM')
   const countrydata=moment((country)).format('MM')

 
   useEffect(()=>{
    setImage(data?.sponsorBanner)
    setBanner(data?.sponsorUrl)
   },[data])
  
  const onTitleChange = (event) => {
    onChange('title', event.target.value);
  };

  interface temp {
    month: number;
    contry: string;
  }
  const onMonthChange = (value) => {
    const startDate = parseInt(moment().month(value).startOf('month').format('x'));
    const endDate = parseInt(moment().month(value).endOf('month').format('x'));
   
    onChange('month', value);
    onChange('startDate', startDate);
    onChange('endDate', endDate);  
  };

  const onFileChange = ({ file, fileUrl }) => {
    if (fileUrl !== eventPictureUrl) {
      onChange('eventPictureUrl', fileUrl);
      onChange('eventPictureFile', file);
    }
  };

  const Urldata =(event:any)=>{
   setBanner (event.target.value)
  }

  useEffect(() => {
    CandyEventList()
  },[]);


  const CandyEventList= async()=>{
   let a=   await getCandyEventList1()
   const maxValue = a.reduce((max, obj) => {   if (obj.challenge_type === '0' && obj.endDate>max )
   if(moment(parseInt(obj.endDate)).format('YYYY')==currentYear){
     { return obj.endDate
    }} else {return max;} }, Number.NEGATIVE_INFINITY);    
     setMax(maxValue)
   const filteredItems = a.filter(item => item.country===selectedCountryCode) 
  

  var temp=[]
     await filteredItems.map((data:any)=>{
      if(moment(parseInt(data.endDate)).format('YYYY')==currentYear){
        temp.push(data.endDate)
      }
   })
   const maxValuee:any= Math.max(...temp);
   setCountry(maxValuee);
  }
  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      onClickk(event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    }
   }


  return (
    <>
      <Form.Item label={candyEvent.eventTitle[language]}>
        {getFieldDecorator('title', {
          rules: [{ required: true, message: candyEvent.errorTitle[language] }],
        })(
          <Input
            className="input-treasure full-width"
            onBlur={onTitleChange}
          />
        )}
      </Form.Item>
      
      <Form.Item required label="Sponsor Banner:">
        <Row gutter={16} type='flex' align='middle'>
          <Col className="gutter-row center" span={16}>
            <div className='border uploadPlaceholder' >
          {image?<div><img src={image}/></div>:<div>
          <p className='mb-0 mt-15' style={{lineHeight:0}}>Image</p> 
            <span >(218 x 43 px)</span></div> }
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <label className='ant-btn primary-ghost-btn xs fileUploadBtn'>
              <input type="file" name="" id="" onChange={onImageChange} />
              Upload
            </label>
          </Col>
        </Row>
        <Row gutter={16} type='flex' align='middle'>
          <Col className="gutter-row" span={16} >
            <Input placeholder="Url" name="" value={Banner} onChange={Urldata}/>
          </Col>
          <Col className="gutter-row" span={8}>
            <Button type="primary" className='primary-ghost-btn xs' block onClick={()=>EnterBanner(Banner)}>Enter</Button>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item label={candyEvent.uploadPicture[language]}>
        {getFieldDecorator('eventPictureUrl', {
          rules: [{ required: true, message: candyEvent.errorFile[language] }],
        })(
          <div>
            <UploadFile onChange={onFileChange} valueUrl={eventPictureUrl} />
          </div>
        )}
      </Form.Item>
      <Form.Item label={candyEvent.selectorTitle[language]}>
        <Row className="treasure-list-location">
          <Col>
            {getFieldDecorator('month', {
              rules: [{ required: true, message: candyEvent.errorMonth[language] }],
            })(
              <Select
                className="treasure-list-location-selector treasure-list-status-selector-picker"
                placeholder={candyEvent.selectorPlaceholder[language]}
                onChange={onMonthChange}
                  >            
                {candyEvent.selectorMonthsList[language].map((month, index) => 
                ( 
                  <Select.Option className="text-centered"
                 
                  disabled={ !selectedCountryCode ? (index<currentMonth||index<maxValuedata) :(index<currentMonth ||index<countrydata)}
                  key={month} value={index}>
                    {month}  
                  </Select.Option>
                )  
                )}
              </Select>
            )}
          </Col>
        </Row>
      </Form.Item>
    </>
  );
}

CreateGlobalInput.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
  title: PropTypes.string,
  month: PropTypes.number,
  eventPictureUrl: PropTypes.string,
  onChange: PropTypes.func,
  EnterBanner:PropTypes.func,
  onClickk:PropTypes.func,
  data:PropTypes.object,
  url:PropTypes.string,
};

CreateGlobalInput.defaultProps = {
  language: 'EN',
  onChange: () => {},
};

const mapsStateToProps = (state) => ({
  selectedCountryCode: state.candyEvent.selectedCountryCode,
});

export default connect(mapsStateToProps)(CreateGlobalInput);
