import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Typography, Divider, Spin } from 'antd';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import {
  DetailsGrid,
  Ranking,
  BackButtonCenteredText,
  ButtonWithSpinner,
} from '../../../../components';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import * as candyEventService from '../../../../services/candyEventService';
import moment from 'moment';
import { baseURL } from '../../../../lib/config';
import * as menuActions from '../../../../actions/menuActions';
import * as candyEventActions from '../../../../actions/candyEventActions';
import { candyEventContestantDetails } from '../../../../lib/SubMenuConstants';
import { serverError } from '../../../../lib/strings/error';
import { showErrorMessage } from '../../../../services/sharedServices';
import PropTypes from 'prop-types';
import { roleAdmin ,roleSuperadmin} from '../../../../lib/const';

function CandyEventDetails(props) {
  const {
    language,
    ID,
    resetCandyEvent,
    closeSubmenu1,
    closeSubmenu2,
    candyEventEditParameter,
    openContestantDetails,
    selectedCountryCode,
    contestantIndex,
    openCandyEventForm,
    openCandyChallengeForm,
    role,
  } = props;

  const [challengetype,setchallengetype]=useState(0)
  useEffect(() => {
    setchallengetype(candyEventData.challenge_type)
    return () => {
      closeSubmenu2();
    };   
  }, []);

  const [sendingNotifications, setSendingNotifications] = useState(false);

  const queryClient = useQueryClient();
  const candyEventList: any = queryClient.getQueryData([
    'CandyEventList',
    { country: selectedCountryCode },
  ]);

  const candyEventData = candyEventList.find((candyEvent) => candyEvent.ID === ID);
  const candyEventRankingList: any = useQuery(
    ['CandyEventRankingList', { ID: ID }],
    candyEventService.getCandyEventRankingList,
    {
      onError: (error) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const sendNotificationsHandler = async () => {
    setSendingNotifications(true);
     await candyEventService.candyEventSendNotifications(ID);
    setSendingNotifications(false);
  };

  const checkForCompletedProfiles = (contestants = []) => {
    const areContestantsCompleted = contestants.find(
      (contestant) => contestant.profileComplete === false
    );
    if (areContestantsCompleted) {
      return true;
    }
    return false;
  };

  const profilesIncomplete = useMemo(() => checkForCompletedProfiles(candyEventRankingList.data), [
    candyEventRankingList.data,
  ]);

  const onCloseHandler = () => {
    closeSubmenu1();
    resetCandyEvent();
  };

  const contestantDetailsHandler = (index) => {
    // console.log(candyEventEditParameter(),"arif")
    candyEventEditParameter(candyEventActions.editableParameters.contestantIndex, index);
    openContestantDetails();
  };

  const isCandyEventFinished = useMemo(
    () => parseInt(moment().format('x')) > candyEventData?.endDate,
    [candyEventData?.endDate]  
  );

  const invalidateCandyEventListAndClose = () => {
    queryClient.invalidateQueries(['CandyEventList', { country: selectedCountryCode }]);
    onCloseHandler();
  };

  const onDeleteHandler = useMutation(() => candyEventService.deleteCandyEvent(ID), {
    onSuccess: invalidateCandyEventListAndClose,
    onError: (error) => {
      showErrorMessage(serverError[language], error);
    },
  });

  const hideDeleteButton = useMemo(
    () =>
      candyEventData?.startDate < moment().format('x') &&
      candyEventData?.endDate > moment().format('x'),
    [candyEventData?.startDate, candyEventData?.endDate]  
  );

  const onEditHandler = () => {
    closeSubmenu1();
     candyEventEditParameter('isEditing', true);
    if(challengetype==0){
      openCandyChallengeForm()
    }
   else{
    openCandyEventForm()
  }
  };

  return (
    <Row className="candyEventDetails-wrapper candyEvent-text">
      <Col className="scrollable">
        <BackButtonCenteredText
          label={candyEventData?.title}
           onClick={onCloseHandler}
            edit={role===roleAdmin ||role===roleSuperadmin}
          onEdit={onEditHandler}
        />
        <div className="candyEventDetails-container">
          <Row>
            <img src={baseURL + candyEventData?.eventPictureUrl} />
          </Row>
          <Row style={{ marginTop: 25 }}>
            <DetailsGrid
              title={candyEvent?.monthOfTheEvent[language] + ':'}
              titleSpan={10}
              value={moment(candyEventData?.month + 1, 'M').format('MMMM')}
            />
            <DetailsGrid title={candyEvent?.prizes[language] + ':'} />
            <DetailsGrid
              title={candyEvent?.firstPrize[language] + ':'}
              titleSpan={10}
              value={candyEventData?.firstPrize?.name}
            />
            <DetailsGrid
              title={candyEvent?.secondPrize[language] + ':'}
              titleSpan={10}
              value={candyEventData?.secondPrize?.name}
            />
            <DetailsGrid
              title={candyEvent?.thirdPrize[language] + ':'}
              titleSpan={12}
              value={candyEventData?.thirdPrize?.name}
            />

            {challengetype==0?<DetailsGrid
              title={candyEvent?.fourthPrize[language] + ':'}
              titleSpan={12}
              value={candyEventData?.fourthPrize?.name}
            />:""}
            
          </Row>

          {
          // ! hideDeleteButton &&
           (
            <Row type="flex" justify="center" style={{ marginTop: 20 }}>
              <Col>
                <ButtonWithSpinner
                  label={candyEvent?.deleteButton[language]}
                  type="transparentRed"
                  underline
                  onClick={onDeleteHandler.mutate}
                />
              </Col>
            </Row>
          )}

          <Divider />

          {candyEventRankingList.isLoading && (
            <Row type="flex" justify="center">
              <Spin size="large" />
            </Row>
          )}

          {isCandyEventFinished && profilesIncomplete && (
            <Row>
              <Row type="flex" justify="center">
                <Typography.Text className="text-centered">
                  {candyEvent.reminderText[language]}
                </Typography.Text>
              </Row>
              <Row type="flex" justify="center">
                <Button
                  className="buttons yellow-gradient candyEvent-send-notification-button"
                  onClick={sendNotificationsHandler}
                >
                  {sendingNotifications ? (
                    <Spin
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    />
                  ) : (
                    candyEvent.sendNotification[language]
                  )}
                </Button>
              </Row>
            </Row>
          )}
        </div>
        <Ranking
          rankingData={candyEventRankingList.data}
          onContestantClick={contestantDetailsHandler}
          contestantIndex={contestantIndex}
        />
      </Col>
    </Row>
  );
}

CandyEventDetails.propTypes = {
  language: PropTypes.string,
  ID: PropTypes.number,
  selectedCountryCode: PropTypes.string,
  resetCandyEvent: PropTypes.func,
  closeSubmenu1: PropTypes.func,
  closeSubmenu2: PropTypes.func,
  candyEventEditParameter: PropTypes.func,
  openContestantDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  ID: state.candyEvent.ID,
  selectedCountryCode: state.candyEvent.selectedCountryCode,
  contestantIndex: state.candyEvent.contestantIndex,
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  resetCandyEvent: () => dispatch(candyEventActions.resetCandyEvent()),
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openCandyEventForm: () => dispatch(menuActions.openCreateCandyEvent()),
  openCandyChallengeForm:()=>dispatch(menuActions.openCreateCandyChallenge()),
  candyEventEditParameter: (parameterKey, parameterValue) =>
    dispatch(candyEventActions.editParameter(parameterKey, parameterValue)),
  openContestantDetails: () => dispatch(menuActions.openSubmenu2(candyEventContestantDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandyEventDetails);
