import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';
// import locationIcon from '../../../../src/assets/location-on-map.svg';
// import infoIcon from '../../../../src/assets/icon_info.svg';
// import editIcon from '../../../../src/assets/icon_edit.svg';
// import deleteIcon from '../../../../src/assets/icon_delete_o.svg';
// import { isExistsDomeName, UpdateDome,UpdateDomeType, SearchDome } from '../../../services/domeService';

class VerificationImport extends React.Component<any, any> {
    static contextType = AppContext;
    constructor(props: any) {
        super(props);
        this.state = {
            dome_name: false,
        }

    }

    verifyCode = async(e:any) =>{
        this.props.vrificationstatus( {
            verification_to_import: true, 
            after_verification: false,
            verify: false

        });
    }

    close = async(e:any) =>{
        this.props.vrificationstatus({
            verify: false
        });
    }

    render(){
        return(
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
            <div className="third-card">
                <div className="fx-jcsb-aic mb-20">
                    <h4 className="text-primary mb-0 font-bold"> &#62; Dome Name verification to import</h4>
                    <i onClick={this.close} className="iconx cursor">
                        <img src={closeIcon} alt="Info" />
                    </i>
                </div>

                <div className="grid-row sm fx-aic mb-10">
                    <div className="col col-4 size12 text-right">
                        Dome Name
                    </div>
                    <div className="col col-4">
                        <input type="text" value="@burgerking" className="form-control xs" />
                    </div>
                </div>
                <div className="grid-row sm fx-aic mb-10">
                    <div className="col col-4 size12 text-right">
                        Dome Address
                    </div>
                    <div className="col col-4">
                        <input type="text" value="000aa008" className="form-control xs" />
                    </div>
                </div>
                <div className="grid-row sm fx-aic mb-10">
                    <div className="col col-4 size12 text-right">
                        Vrification Code
                    </div>
                    <div className="col col-4">
                        <input type="text"  className="form-control xs" />
                    </div>
                </div>
                <div className="size12">
                    <p>The verification code has been sent to your email.</p>
                </div>
                <div className="fx-jcc">
                    <button onClick={this.verifyCode} className="ant-btn purple-gradient xs">Verified</button>
                    <button className="ant-btn yellow-gradient xs ml-10">Next</button>
                </div>
            </div>
        </Row>
       
       )
    
    }
    
}

export default VerificationImport;