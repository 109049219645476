import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import ArShowItem from './arShowItem';

import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import DatePicker from 'react-datepicker';
import ArEditModal from './components/ArEditModal';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { useTranslation} from "react-i18next";

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

const Item = (props) => {
  const { t } = useTranslation();
  
  return (
    <>
      <li key={props.index} className="editingList__item">
        <div className="editingList__item__row">
          <div className="editingList__item__row__left w-auto">
            <div className="grid-row xs">
              <div className="col col-6">
                <div className="dateSection">
                  <div className="fx-aic">
                    <span className="dateSection__label">(t('Start')):</span>
                    <input
                      type="text"
                      value="20/05/21 03:46 PM"
                      className="form-control xss rd-0 size11"
                    />
                  </div>
                  <div className="fx-aic">
                    <span className="dateSection__label">{t('End')}:</span>
                    <input
                      type="text"
                      value=""
                      placeholder="Select Date"
                      className="form-control xss rd-0 size11"
                    />
                  </div>
                </div>
              </div>
              <div className="col col-6">
                <input
                  type="text"
                  value="abc.jpg"
                  className="form-control xs rd-0"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="editingList__item__row__action">
            <label className="ant-btn primary-ghost-btn xs btn-block fileUploadBtn mb-3">
              <input type="file" />
              {t('Upload')} 
            </label>
            <button className="iconx xs">
              <img src={editIcon} alt="Edit" height="14" />
            </button>
            <button className="iconx xs">
              <img src={deleteIcon} alt="Delete" height="14" />
            </button>
          </div>
        </div>
      </li>
    </>
  );
};

class DomeArshow extends React.Component<any, any> {
  static contextType = AppContext;

  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      // arshow: [{ start_date: "", end_date: "", name: "" }],
    };
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  // addMore = async (e: any) => {
  //   let arshow = [
  //     ...this.state.arshow,
  //     { start_date: "", end_date: "", url: "" },
  //   ];
  //   this.setState({ arshow: arshow });
  // };

  // handleFile = async (e: any, index) => {
  //   if (e.target.files.length > 0) {
  //     let arshow = [...this.state.arshow];
  //     arshow[index]["name"] = e.target.files[0].name;
  //     this.setState({
  //       arshow: arshow,
  //     });
  //   }
  // };

  // removeUrlHandler = async (e, index) => {
  //   let arshow = [...this.state.arshow];
  //   arshow.splice(index, 1);
  //   this.setState({
  //     arshow: arshow,
  //   });
  // };

  // setValuesForArShowOnSave = (ind, values) => {
  //   let arshow = [...this.state.arshow];
  //   arshow[ind] = {
  //     start_date: values.startDate,
  //     end_date: values.endDate,
  //     url: values.videoName,
  //   };
  //   this.setState({
  //     arshow,
  //   });
  // };

  render() {
    const context = this.context;
    const {translation}=this.props
    return (
      <>
        <div id="arshow-tab" className="tab-pane">
          <div className="fx-jcsb-aic mb-10">
            <div>
              <i className="blueBox mr-5"></i>
              <span className="text-primary mr-5">{translation('ARshow editing')}</span>
              <i>
                {/* <img src={infoIcon} alt="Info" height="14" /> */}
                <div className="infoTip">
                  <span
                    className="infoTip__icon"
                    data-for="soclose"
                    data-tip="8"
                  >
                    &nbsp;
                  </span>
                  <ReactTooltip
                    id="soclose"
                    getContent={(dataTip) => (
                      <div>
                        <p style={{ fontStyle: 'normal' }}>
                        {translation('Create and share augmented reality content featuring the GIFs you wish to convey')}.
                        </p>
                      </div>
                    )}
                    effect="solid"
                    delayHide={500}
                    delayShow={500}
                    delayUpdate={500}
                    place={'right'}
                    border={true}
                    type={'light'}
                  />
                </div>
              </i>
            </div>
            <div>
              <span className="text-primary mr-5">
                <i className="status running"></i>{translation('Running')} 
              </span>
              <span className="text-primary mr-5">
                <i className="status pending"></i>{translation('Pending')} 
              </span>
              <span className="text-primary">
                <i className="status finished"></i>{translation('Finished')} 
              </span>
            </div>
          </div>

          <ol className="editingList">
            {this.props.arshowData.map((item, index) => (
              <ArShowItem
                setValuesForarshowDataOnSave={
                  this.props.setValuesForarshowDataOnSave
                }
                onArEditClick={this.props.onArEditClick}
                handleFile={(e) => this.props.handleFile(e, index)}
                 index={index}
                 item={item}
                removeUrlHandler={(e) => this.props.removeUrlHandler(e, index)}
              />
            ))}
          </ol>
          <div className="fx-jcfe mt-10 mb-10">
            <button onClick={this.props.addMore} className="ant-btn addMore">
              +
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomeArshow);
