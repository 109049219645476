import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import UploadFile from './UploadFile';

function UploadImgOrVidDecorator(props) {
  const { form, id, required, label, errorMessage, onChange, pictureUrl } = props;
  const { getFieldDecorator, setFieldsValue, getFieldsValue, getFieldValue } = form;

  const onChangeHandler = ({ file, fileUrl }) => {
    setFieldsValue({ [id]: fileUrl });
    onChange({ file, fileUrl });
  };

  return (
    <div className="upload-image-video-with-decorator">
      <Form.Item label={label}>
        {getFieldDecorator(id, {
          rules: [{ required: required, message: errorMessage }],
        })(
          <div>
            <UploadFile onChange={onChangeHandler} valueUrl={pictureUrl} />
          </div>
        )}
      </Form.Item>
    </div>
  );
}

UploadImgOrVidDecorator.propTypes = {
  form: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  pictureUrl: PropTypes.string,
};

UploadImgOrVidDecorator.defaultProps = {
  onChange: () => {
    console.log('missing logic');
  },
};

export default UploadImgOrVidDecorator;
