import React, { useState, useEffect, useCallback } from 'react';
import { Form, Select } from 'antd';
import greenDot from '../../assets/greenDot.svg';
import orangeDot from '../../assets/orangeDot.svg';
import redDot from '../../assets/redDot.svg';
import blackDot from '../../assets/blackDot.svg';
import yellowDot from '../../assets/yellowDot.svg';
import PropTypes from 'prop-types';
import * as treasureCreation from '../../lib/strings/treasureCreation';
import { useTranslation } from 'react-i18next';

// if style is set in css it is overridden in selector dropdown
const imgStyle = {
  width: 9,
  height: 9,
  marginTop: -2,
  display: 'inline-block',
};

function TreasureListStatus(props) {
  const { defaultValue, onChange, language } = props;

  const [currentValue, setCurrentValue] = useState(defaultValue);
  const {t}=useTranslation()

  const onSelectHandler = (value) => {
    setCurrentValue(value);
  };

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);
  
  return (
    <Form.Item label={status[language]} className="treasure-list-status">
      <Select
        className="treasure-list-status-selector point-creation-replay-picker"
        defaultValue={defaultValue}
        onChange={onSelectHandler}
      >
        <Select.Option value={'all'}>{t("all")}</Select.Option>
        <Select.Option value={'running'}>
          <img style={imgStyle} src={greenDot} />
          <span style={{ marginLeft: '8px' }}>{t('Running')} </span>
        </Select.Option>
        <Select.Option value={'pending'}>
          <img style={imgStyle} src={yellowDot} />
          <span style={{ marginLeft: '8px' }}>{t('Pending')}</span>
        </Select.Option>
        <Select.Option value={'draft'}>
          <img style={imgStyle} src={orangeDot} />
          <span style={{ marginLeft: '8px' }}>{t('Draft')}</span>
        </Select.Option>
        <Select.Option value={'review'}>
          <img style={imgStyle} src={redDot} />
          <span style={{ marginLeft: '8px' }}>{t('In review')}</span>
        </Select.Option>
        <Select.Option value={'finished'}>
          <img style={imgStyle} src={blackDot} />
          <span style={{ marginLeft: '8px' }}>{t("Finished")}</span>
        </Select.Option>
      </Select>
    </Form.Item>
  );
}

TreasureListStatus.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  language: PropTypes.string,
};

TreasureListStatus.defaultProps = {
  defaultValue: 'all',
  onChange: () => {},
  language: 'EN',
};

export default TreasureListStatus;
