import sale1 from './sale1.JPG';
import sale2 from './sale2.JPG';
import sale3 from './sale3.JPG';
import sale4 from './sale4.JPG';
import sale5 from './sale5.JPG';
import sale6 from './sale6.JPG';
import sale7 from './sale7.JPG';
import sale8 from './sale8.JPG';
import sale9 from './sale9.JPG';
import sale10 from './sale10.JPG';

import sale1_1 from './sale1.1.JPG';
import sale2_1 from './sale2.1.JPG';
import sale3_1 from './sale3.1.JPG';
import sale4_1 from './sale4.1.JPG';
import sale5_1 from './sale5.1.JPG';
import sale6_1 from './sale6.1.JPG';
import sale7_1 from './sale7.1.JPG';
import sale8_1 from './sale8.1.JPG';
import sale9_1 from './sale9.1.JPG';
import sale10_1 from './sale10.1.JPG';

export const saleportrait = [
  { id: 1, src: sale1 },
  { id: 2, src: sale2 },
  { id: 3, src: sale3 },
  { id: 4, src: sale4 },
  { id: 5, src: sale5 },
  { id: 6, src: sale6 },
  { id: 7, src: sale7 },
  { id: 8, src: sale8 },
  { id: 9, src: sale9 },
  { id: 10, src: sale10 },
];

export const salelandscape = [
  { id: 1, src: sale1_1 },
  { id: 2, src: sale2_1 },
  { id: 3, src: sale3_1 },
  { id: 4, src: sale4_1 },
  { id: 5, src: sale5_1 },
  { id: 6, src: sale6_1 },
  { id: 7, src: sale7_1 },
  { id: 8, src: sale8_1 },
  { id: 9, src: sale9_1 },
  { id: 10, src: sale10_1 },
];
