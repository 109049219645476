import React from 'react';

interface Props {
  pathToImg: string;
  stickerID: number;
  onImgUrlChange?: (
    url: string,
    stickerID: number,
    sectionID: number,
    sectionPosition: number
  ) => void;
  selected?: boolean;
  sectionID: number;
  stickerSectionPosition: number;
}

const Sticker = (props: Props) => {
  return (
    <div
      className={props.selected ? 'selected-bg' : null}
      onClick={() => {
        props.onImgUrlChange(
          props.pathToImg,
          props.stickerID,
          props.sectionID,
          props.stickerSectionPosition
        );
      }}
    >
      <div className="img" style={{ backgroundImage: `url(${props.pathToImg})` }}></div>
    </div>
  );
};

export default Sticker;
