import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Group, Text, Image, Rect, Transformer } from 'react-konva';
import useImage from 'use-image';
import PropTypes from 'prop-types';

import Logo from './Logo';
import EditableText from './EditableText';

function Canvas(props) {
  const {
    width,
    height,
    title,
    description,
    contactDetails,
    logoImageUrl,
    backgroundImage,
    backgroundColor,
    startExporting,
    onExport,
    canvasSize,
    scaleFactor,
    pixelRatio,
    showLogo,
    dimensions,
    dimensionsHandler,
  } = props;

  const [image, imageStatus] = useImage(backgroundImage, 'Anonymous');
  const [logoImage, statusLogoImage] = useImage(logoImageUrl, 'Anonymous');
  const stageRef = useRef(null);

  const showTitle = title.text ? true : false;
  const showDescription = description.text ? true : false;
  const showContactDetails = contactDetails.text ? true : false;
  const showBackgroundColor = backgroundColor ? true : false;
  const showLogoImage = statusLogoImage === 'loaded' && showLogo ? true : false;

  const handleExport = () => {
    // setIsSelected
    const selectedKeys = Object.keys(isSelected);
    let newState = {};
    selectedKeys.map((key) => ({ ...newState, [key]: false }));
    setIsSelected(newState);

    const url = stageRef.current.toDataURL({
      pixelRatio: pixelRatio,
    });
    const file = dataURLtoFile(url, 'file');
    return { file, fileUrl: url };
  };

  const dataURLtoFile = (dataurl: any, filename: string) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    if (startExporting) {
      // setIsSelected
      const selectedKeys = Object.keys(isSelected);
      let newState = {};
      selectedKeys.map((key) => ({ ...newState, [key]: false }));
      setIsSelected(newState);

      setTimeout(() => {
        console.log('export should start');
        onExport(handleExport());
      }, 150);
    }
  }, [startExporting]);

  const [isSelected, setIsSelected] = useState<any>({
    title: false,
    logo: false,
    description: false,
    contactDetails: false,
  });

  const isSelectedHandler = (key) => {
    setIsSelected((currentState) => {
      return { ...currentState, [key]: !currentState[key] };
    });
  };

  // const dimensionsHandler = (key, value) => {
  //   setDimensions((currentState) => {
  //     return { ...currentState, [key]: value };
  //   });
  // };

  return (
    <>
      <Stage
        width={canvasSize.width / scaleFactor}
        height={canvasSize.height / scaleFactor}
        ref={stageRef}
      >
        <Layer>
          <Group>
            {imageStatus === 'loaded' && (
              <Image
                width={canvasSize.width / scaleFactor}
                height={canvasSize.height / scaleFactor}
                image={image}
                // draggable
              />
            )}

            {showBackgroundColor && (
              <Rect
                x={0}
                y={0}
                width={canvasSize.width / scaleFactor}
                height={canvasSize.height / scaleFactor}
                fill={backgroundColor}
                // fillLinearGradientStartPointX={100}
                // fillLinearGradientStartPointY={canvasSize.height/scaleFactor/2}
                // fillLinearGradientEndPointX={canvasSize.width/scaleFactor}
                // fillLinearGradientEndPointY={canvasSize.height/scaleFactor/2}
                // fillLinearGradientColorStops={[0, 'red', 1, 'green']}
              />
            )}

            {showLogoImage && (
              <Logo
                // shapeProps={logoDimensions}
                // isSelected={isSelected.logo}
                // onSelect={() => isSelectedHandler('logo')}
                // onChange={(newAttrs) => { setLogoDimensions(newAttrs) }}
                shapeProps={dimensions.logo}
                isSelected={isSelected.logo}
                onSelect={() => isSelectedHandler('logo')}
                onChange={(newAttrs) => {
                  dimensionsHandler('logo', newAttrs);
                }}
                image={logoImage}
              />
            )}

            {showContactDetails && (
              <EditableText
                text={contactDetails.text}
                fontSize={contactDetails.fontSize}
                fontStyle={`${contactDetails.fontWeight} ${contactDetails.fontStyle}`}
                fill={contactDetails.fontColor}
                fontFamily={contactDetails.fontFamily}
                shapeProps={dimensions.contactDetails}
                isSelected={isSelected.contactDetails}
                onSelect={() => isSelectedHandler('contactDetails')}
                onChange={(newAttrs) => {
                  dimensionsHandler('contactDetails', newAttrs);
                }}
              />
            )}

            {showDescription && (
              <EditableText
                text={description.text}
                fontSize={description.fontSize}
                fontStyle={`${description.fontWeight} ${description.fontStyle}`}
                fill={description.fontColor}
                fontFamily={description.fontFamily}
                shapeProps={dimensions.description}
                isSelected={isSelected.description}
                onSelect={() => isSelectedHandler('description')}
                onChange={(newAttrs) => {
                  dimensionsHandler('description', newAttrs);
                }}
              />
            )}

            {showTitle && (
              <EditableText
                text={title.text}
                fontSize={title.fontSize}
                fontStyle={`${title.fontWeight} ${title.fontStyle}`}
                fill={title.fontColor}
                fontFamily={title.fontFamily}
                shapeProps={dimensions.title}
                isSelected={isSelected.title}
                onSelect={() => isSelectedHandler('title')}
                onChange={(newAttrs) => {
                  dimensionsHandler('title', newAttrs);
                }}
              />
            )}
          </Group>
        </Layer>
      </Stage>
    </>
  );
}

Canvas.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  contactDetails: PropTypes.object,
  logoImageUrl: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  startExporting: PropTypes.bool,
  onExport: PropTypes.func,
  canvasSize: PropTypes.object,
  scaleFactor: PropTypes.number,
  pixelRatio: PropTypes.number,
  showLogo: PropTypes.bool,
  dimensions: PropTypes.object,
  dimensionsHandler: PropTypes.func,
};

Canvas.defaultProps = {
  width: 400,
  height: 300,
  backgroundColor: '',
  backgroundImage: '',
  title: {},
  description: {},
  contactDetails: {},
  startExporting: false,
  onExport: () => {},
  canvasSize: { width: 1920 / 2, height: 1080 / 2 },
  scaleFactor: 2,
  pixelRatio: 2,
  showLogo: true,
  dimensions: {
    title: {
      x: 10,
      y: 10,
      rotation: 0,
    },
    logo: {
      width: 100,
      height: 100,
      x: 10,
      y: 55,
      rotation: 0,
    },
    description: {
      x: 10,
      y: 160,
      rotation: 0,
    },
    contactDetails: {
      x: 10,
      y: 220,
      rotation: 0,
    },
  },
  dimensionsHandler: () => {},
};

export default Canvas;
