import React from 'react';
import { Row, Col, Typography } from 'antd';
import placeholder from '../../../../assets/profile_picture.svg';
import { connect } from 'react-redux';
import { getContestByID } from '../../../../actions/contestActions';
import { baseURL } from '../../../../lib/config';
import { menuConstants } from '../../../../constants/menuConstants';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import moment from 'moment';
import * as contestStrings from '../../../../lib/strings/contest';
const { Text } = Typography;

interface Props {
  ID: number;
  startDate: number;
  endDate: number;
  name: string;
  all: any;
  selected: boolean;
  dispatch: any;
  characterName: string;
  image: string;
  characterDetails?: any;
  handleSelectedContest: (id: number) => void;
  openContestDetails: () => void;
  closeContestantVideoScreen: () => void;
  language: string;
  title: string;
}

const mapStateToProps = (state) => ({
  language: state.authentication.language,
});

const mapDispatch = (dispatch: (arg0: { type: string }) => any) => {
  return {
    openContestDetails: () => dispatch({ type: menuConstants.OPEN_SUBMENU_1 }),
    closeContestantVideoScreen: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
    dispatch,
  };
};

const Contest = (props: Props) => {
  const { language } = props;

  const openDetails = async () => {
    props.closeContestantVideoScreen();
    props.dispatch(getContestByID(props.all));
    props.handleSelectedContest(props.ID);
  };

  const contestIsFinished = moment().isAfter(moment(props.endDate));

  const classNameDate = ['contest-list-date', contestIsFinished && 'contest-list-date-finished'];

  return (
    <Row
      style={{ minHeight: '70px' }}
      onClick={openDetails}
      type="flex"
      justify="center"
      align="middle"
      gutter={[8, 0]}
      className={props.selected ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      <Col span={6} style={{ padding: '8px', display: 'flex', justifyContent: 'center' }}>
        <img
          style={{ maxWidth: '100%', maxHeight: '70px' }}
          src={props.image ? `${baseURL}/${props.image}` : placeholder}
        />
      </Col>
      <Col span={16}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Row type="flex" className="contest-list-title">
              <Text>{props.name}</Text>
              {props.characterDetails.name && <Text>{` - ${props.characterDetails.name}`}</Text>}
              {!props.characterDetails.name && props.title && <Text>{` - ${props.title}`}</Text>}
            </Row>
            <Row type="flex" className={classNameDate.join(' ')}>
              <Text>{moment(props.startDate, 'x').format('DD/MM/YY')}</Text> -{' '}
              {!contestIsFinished && <Text>{moment(props.endDate, 'x').format('DD/MM/YY')}</Text>}
              {contestIsFinished && <Text>{contestStrings.finished[language]}</Text>}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={2}>
        <img src={arrow} className="treasure-arrow" />
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatch)(Contest);
