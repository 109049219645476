import { TotalLcash,MorePoints,SubTotal,MorePoints_total,
  Pointsflash,Pointsflash2,Pointsflash3,Pointsflash4,Pointsflash5,
Physical1,Physical2,Physical3,Physical4,Physical5,additional_prize2} from '../../src/lib/const'

export function Additionalprize(state = {MorePoints_total:0,TotalLcash:0,SubTotal:0,MorePoints:0,
additional_prize:0, 
place_1st:{Units:"",PointsperUnit:"",TotalPoints:'',RequiredLcash:''},
place_2nd:{Units:"",PointsperUnit:"",TotalPoints:'',RequiredLcash:''},
place_3rd:{Units:"",PointsperUnit:"",TotalPoints:'',RequiredLcash:''},
place_4th:{Units:"",PointsperUnit:"",TotalPoints:'',RequiredLcash:''},
place_5th:{Units:"",PointsperUnit:"",TotalPoints:'',RequiredLcash:''},
prize_1st : {units: '', title: "", desc: ""},
prize_2nd : {units: '', title: "", desc: ""},
prize_3rd : {units: '', title: "", desc: ""},
prize_4th : {units: '', title: "", desc: ""},
prize_5th : {units: '', title: "", desc: ""},

}, action: any) {
  switch (action.type) {
    case TotalLcash:
        return {
          ...state,
          TotalLcash: action.message
      };
    case MorePoints:
      return {
        ...state,
        MorePoints: action.message
    };
    case Pointsflash:
      return {
        ...state,
        place_1st:action.place_1st,
    };
    case Pointsflash2:
      return {
        ...state,
        place_2nd:action.place_2nd,
    };
    case Pointsflash3:
      return {
        ...state,
        place_3rd:action.place_3rd,
    };
    case Pointsflash4:
      return {
        ...state,
        place_4th:action.place_4th,
    };
    case Pointsflash5:
      return {
        ...state,
        place_5th:action.place_5th,
    };
    case SubTotal:
      return {
        ...state,
        SubTotal: action.message
    };
    case MorePoints_total:
      return {
        ...state,
        MorePoints_total: action.message
    };

    case Physical1:
      return {
        ...state,
        prize_1st:action.prize_1st,
    };
    case Physical2:
    return {
      ...state,
      prize_2nd:action.prize_2nd,
  };
  case Physical3:
  return {
    ...state,
    prize_3rd:action.prize_3rd,
};
case Physical4:
return {
  ...state,
  prize_4th:action.prize_4th,
};

case Physical5:
return {
  ...state,
  prize_5th:action.prize_5th,
};

case additional_prize2:
return {
  ...state,
  additional_prize:action.additional_prize,
};


    default:
      return state;
  }
}