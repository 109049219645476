import React, { useState, useEffect } from 'react';
import { pending, requestSuccessful } from '../lib/const';
import { notification } from 'antd';
import { serverError } from '../lib/strings/error';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import moment from 'moment';
import countryData from '../helpers/country.json';
import { statusConstants } from '../containers/dashboard/menu/statistics/apiToTabel';
import { responseCodes } from '../lib/const';
import { executeSignOut } from './sharedServices';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

const countryToCountryCode = (countryName) => {
  // country codes from applicationCache, saved in redux

  const country = countryData.find((country) => countryName === country.name);
  return country.alpha2Code;
};

const unixTimestamp = (momentDate) => parseInt(moment(momentDate).format('x'));
const currentDate = new Date();



export const getStatisticsForTreasures = async (query: any) => {
  const findKitId = (kitType) => {
    const kitList = store.getState().kitList;
    const kit = kitList.find((kit) => kitType === kit.name);
    return kit?.ID;
  };

  const statusNameToApi = (statusName) => {
    switch (statusName) {
      case 'Pending':
        return statusConstants.pending;
      case 'Active':
        return statusConstants.active;
      case 'Inactive':
        return statusConstants.inactive;
    }
    // 'Pending', 'Active', 'Inactive'
  };

  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statistics +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          status: query?.status ? `${statusNameToApi(query.status)}` : '',
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          treasureName: query?.treasureName ? query.treasureName : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          kittypeid: query?.kitType ? `${findKitId(query.kitType)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForCoins = async (query: any) => {

  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsCoins +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};


export const getStatisticsForUsers = async (query: any) => {
  const usertypeNameToApi = (statusName) => {
    switch (statusName) {
      case 'Master':
       return '1';
     case 'Partner':
      return '2';
    case 'Quantum partner':
       return '3';
     case 'Regular user':
        return '4';
    default:
       return '';  
    }
  };

  const statusName = (statusName) => {
    switch (statusName) {
      case 'Master with Dome Address':
       return '1';
     case 'Master treasure created':
      return '2';
    default:
       return '';  
    }
  };
  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsUsers +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
        }),
      {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          start_date: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          end_date: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          user_type: query?.User_type ?`${usertypeNameToApi(query.User_type)}` : '',
          user_filter: query?.user_filter ?`${statusName(query.user_filter)}` : '',
        }),
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};


export const getStatisticsForCoupon= async (query: any) => {
// const usertypeNameToApi = (statusName) => {
  //   switch (statusName) {
  //     case 'Master':
  //      return '1';
  //    case 'Partner':
  //     return '2';
  //   case 'Quantum partner':
  //      return '3';
  //    case 'Regular user':
  //       return '4';
  //   default:
  //      return '';  
  //   }
  // };

  // const statusName = (statusName) => {
  //   switch (statusName) {
  //     case 'Master with Dome Address':
  //      return '1';
  //    case 'Master treasure created':
  //     return '2';
  //   default:
  //      return '';  
  //   }
  // };
  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsCoupon+
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
        }),
      {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          start_date: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          end_date: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
// user_type: query?.User_type ?`${usertypeNameToApi(query.User_type)}` : '',
          // user_filter: query?.user_filter ?`${statusName(query.user_filter)}` : '',
        }),
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForvoucherget= async (query: any,mysqlID:any) => {
  try {
    const fetchResponse =   await fetch(
      config.baseURL +config.statisticsVoucher+'?' +
        new URLSearchParams({
          page:query,        
        }),
      {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id:mysqlID
        }),
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForvoucher= async (query: any) => {
  const usertypeNameToApi = (statusName) => {
    switch (statusName) {
      case 'Online':
       return '1';
     case 'Offline':
      return '0';
    default:
       return ;  
    }
  };

  const statusName = (statusName) => {
    switch (statusName) {
      case 'Valid':
       return '1';
     case 'Expired':
      return '0';
    default:
       return ;  
    }
  };
  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsVoucher+
        '?' +
        new URLSearchParams({
          page: `${query.page}`,          
        }),
      {
        method: 'POST',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: query?.username ? query.username : '',
          country: query?.country ? countryToCountryCode(query.country) : '',
          start_date: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          end_date: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          bundle_payment: query?.Bundle?`${usertypeNameToApi(query.Bundle)}` : '',
          valid: query?.expiration ?`${statusName(query.expiration)}` : '',
          current_time:`${unixTimestamp(currentDate)}`
        }),
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForPoints = async (query: any) => {
  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsPoints +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
           username: query?.username ? query.username : '',
           country: query?.country ? countryToCountryCode(query.country) : '',
           startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
           endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForDomeStats = async (query: any) => {
  try {
    const fetchResponse =   await fetch(
      config.baseURL +
        config.statisticsDomeStats +
        '?' +
        new URLSearchParams({
          page: `${query.page}`,
          limit: query?.limit ? query.limit : 500,
          filter:query?.filter ? query.filter : ''
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    // showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};


export const getStatisticsForPartnersAdmin = async (query: any) => {
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : query?.paymentStatus;
  try {
    const fetchResponse =   await fetch(
      `${config.baseURL}${config.statisticsPartners}-admin?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? `${paymentStatusQuery}` : '',
          status: query?.status ? `${query.status}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const markAsPaid = async (ID) => {
  const fetchResponse =   await fetch(
    `${config.baseURL}${config.statisticsPartners}/markAsPaid/${ID}`,
    {
      method: 'PUT',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
         Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful && response.data === 'Success!') {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.data}, ${response.code}`;
  }
};

export const getStatisticsForPartners = async (query: any) => {
  const paymentStatusQuery = query?.paymentStatus === 'Not paid' ? 'pending' : 'paid';
  const treasureStatusQuery = query?.status === 'Inactive' ? 'finished' : query?.status;
  try {
    const fetchResponse =   await fetch(
      `${config.baseURL}${config.statisticsPartners}?` +
        new URLSearchParams({
          page: `${query.page}`,
          startDate: query?.dateFrom ? `${unixTimestamp(query.dateFrom)}` : '',
          endDate: query?.dateTo ? `${unixTimestamp(query.dateTo)}` : '',
          paymentStatus: query?.paymentStatus ? paymentStatusQuery : '',
          treasureStatus: query?.status ? `${treasureStatusQuery.toLowerCase()}` : '',
          partnerUsername: query?.partnerUsername ? `${query.partnerUsername}` : '',
        }),
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
           Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === requestSuccessful) {
      return response.data;
    }
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForSTeamAssignment = async (limit=100) => {
  try {  
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    const fetchResponse =   await fetch(
      `${config.baseURL}${config.statisticsSupAssignment}?limit=${limit}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
           Accept: 'application/json',
          'Content-Type': 'application/json',
        },        
      }
    );   
    const response =   await fetchResponse.json();  
    if (response.code === requestSuccessful) {
      const userList = response['data']['List'];
      const filteredUserList = userList.filter((user) => user.phoneNumber !== "+12139355403");
      const filteredResponseData = {
        ...response.data,
        List: filteredUserList
      };     
      return filteredResponseData;
    }
    
    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const getStatisticsForAdminTeamAssignment = async (limit = 100, isd = ' ') => {
  try {
    const fetchResponse = await fetch(
      `${config.baseURL}${config.statisticsAdminAssignment}?limit=${limit}&isd=${isd}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response = await fetchResponse.json();
    if (response.code === requestSuccessful) {
      const userList = response['data']['List'];
      const filteredUserList = userList.filter((user) => user.phoneNumber !== " ");
      const filteredResponseData = {
        ...response.data,
        List: filteredUserList
      };
      return filteredResponseData;
    }

    showErrorMessage(serverError[store.getState().authentication.language]);
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language]);
  }
};

export const updateUserPermissions = async (username,userId, permissions) => {    
  try {   
    const url = `${config.baseURL}${config.userStatePermissionsUpdate}`   
    const payload = JSON.stringify({
      userPermissions: permissions,
      spikaID: userId,
      username:username
    });
    const headers = {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const response =   await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: payload,
    });
    if (!response.ok) {
      throw new Error('Failed to update user permissions');
    }
    const data =   await response.json();  
  } catch (error) {
    console.log("ERROR IN PERMISSION",error);
  }
};


export const deleteUserByUltraAdmin = async (userId) => { 
  try {
    const fetchResponse =   await fetch(
      ` https://app.loamoa-dev-merge.cladev.com/api/v2/user/update/userPermissions/${userId}`,
        {
      method: 'PUT',
      headers: {        
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else {
      showErrorMessage(response.error, response.code);
      return Promise.reject('failed to get sections');
    }
  } catch (error) {
    showErrorMessage("Try after sometimes");
    return error;
  }
}


export async function UserLoginStats(userId, start_date, end_date) {
  try {
    const url = `${config.baseURL}${config.userLoginStats}${userId}`;

    const data = {
      start_date: start_date,
      end_date: end_date
    };

    const headers = {
      'access-token': store.getState().authentication.user.newToken,
      'lang': 'EN',
      'Content-Type': 'application/json',

    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (responseData.code === 1) {
      return responseData.data;
    } else {
      console.log("Not working");
    }
  } catch (error) {
    notification.error({
      message: 'Error while fetching data! Please try again.',
    });
  }
}



export const CartcheCkoutFromCoupon = async () => {  
  try {
    const url = `${config.baseURL}${config.cartCheckout}`   
    const payload = JSON.stringify({   
      iscoupon: '1',   
    });

    const headers = {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const response =   await fetch(url, {
      method: 'POST',
      headers: headers,
      body: payload,
    });

    if (!response.ok) {
      throw new Error('Failed to update user permissions');
    }
    const data =   await response.json();    
  } catch (error) {
    console.log(error);
  }
};