import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

function OneLineInput(props) {
  const {
    getFieldDecorator,
    id,
    label,
    required,
    errorMessage,
    placeholder,
    onChange,
    onBlur,
    className,
  } = props;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(id, {
        rules: [{ required: required, message: errorMessage }],
      })(
        <Input
          className={'one-line-input' + ' ' + className}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      )}
    </Form.Item>
  );
}

OneLineInput.propTypes = {
  getFieldDecorator: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

OneLineInput.defaultProps = {
  errorMessage: '',
  onChange: () => {},
  onBlur: () => {},
};

export default OneLineInput;
