import React from 'react';
import { Row, Col, Typography } from 'antd';
import chevronRightThinBlack from '../../assets/chevronRightThinBlack.svg';
import PropTypes from 'prop-types';

function OpenStatsButton(props) {
  const { label, onClick } = props;

  return (
    <Row
      className="stats-open-stats-button"
      type="flex"
      justify="center"
      align="middle"
      onClick={onClick}
    >
      <Col span={22} className="text-container">
        <Typography.Text className="stats-text size13">{label}</Typography.Text>
      </Col>
      <Col span={2}>
        <img src={chevronRightThinBlack} />
      </Col>
    </Row>
  );
}

OpenStatsButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  language: PropTypes.string,
};

OpenStatsButton.defaultProps = {
  onClick: () => {
    console.log('missing onClick logic');
  },
};

export default OpenStatsButton;
