import React from 'react';
import { connect } from 'react-redux';
import ExchangeRateByCountry from './menu/kit/ExchangeRateByCountry';
import KitPricesByCountry from './menu/kit/KitPricesByCountry';
import * as subMenuConstants from '../../lib/SubMenuConstants';
import TreasureClients from './menu/treasure/TreasureClients';
// import ContestantVideo from './menu/contest/ContestantVideo';
import SearchUsers from './menu/notifications/SearchUsers';
import SearchContestants from './menu/notifications/SearchContestants';
import CandyEventContestantDetails from './menu/candyEvent/CandyEventContestantDetails';
import CashExchangeDetails from './menu/requests/cashExchange/CashExchangeDetails';
import PartnerRequestsDetails from './menu/requests/partnerRequests/PartnerRequestsDetails';
import ContestantDetails from './menu/contest/ContestantDetails';
import ContestantReviewDetails from './menu/contest/ContestantReviewDetails';

interface Props {
  subMenu2?: string;
}

const mapState = (state: any) => {
  return {
    subMenu2: state.subMenu2,
  };
};

const SubMenu2 = (props: Props) => {
  const selectOpenMenu = () => {
    switch (props.subMenu2) {
      case subMenuConstants.exchangeRateByCountry:
        return <ExchangeRateByCountry />;
      case subMenuConstants.kitPricesByCountry:
        return <KitPricesByCountry />;
      case subMenuConstants.treasureClients:
        return <TreasureClients />;
      case subMenuConstants.searchUsers:
        return <SearchUsers />;
      case subMenuConstants.searchContesants:
        return <SearchContestants />;
      case subMenuConstants.candyEventContestantDetails:
        return <CandyEventContestantDetails />;
      case subMenuConstants.cashExchangeDetails:
        return <CashExchangeDetails />;
      case subMenuConstants.partnerRequestsDetails:
        return <PartnerRequestsDetails />;
      case subMenuConstants.contestantDetails:
        return <ContestantDetails />;
      case subMenuConstants.contestantReviewDetails:
        return <ContestantReviewDetails />;
      default:
        break;
    }
  };

  return <>{selectOpenMenu()}</>;
};

export default connect(mapState)(SubMenu2);
