import * as termsOfUse from '../../../lib/strings/termsOfUse';
import { Row, Col, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { languages } from '../../../lib/interfaces/language';

interface Props {
  language: languages;
}

const mapState = (state: { authentication: any }) => {
  const { authentication } = state;
  const { language } = authentication;
  return { language };
};

const TermsOfUse = (props: Props) => {
  const { language } = props;

  return (
    <Row type="flex" justify="center" className="privacy-policy-wrapper">
      <Col className="privacy-policy">
        <Row>
          <Typography.Title className="roboto-regular bold">
            {termsOfUse.termsTitle[language]}
          </Typography.Title>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p1[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p2[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p3[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p4[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p5[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p6[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p7[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p8[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p9[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p10[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p11[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p12[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p13[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p14[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{termsOfUse.p14List[language][1]}</li>
            <li>{termsOfUse.p14List[language][2]}</li>
            <li>{termsOfUse.p14List[language][3]}</li>
            <li>{termsOfUse.p14List[language][4]}</li>
            <li>{termsOfUse.p14List[language][5]}</li>
            <li>{termsOfUse.p14List[language][6]}</li>
            <li>{termsOfUse.p14List[language][7]}</li>
            <li>{termsOfUse.p14List[language][8]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p15[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p16[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{termsOfUse.p16List[language][1]}</li>
            <li>{termsOfUse.p16List[language][2]}</li>
            <li>{termsOfUse.p16List[language][3]}</li>
            <li>{termsOfUse.p16List[language][4]}</li>
            <li>{termsOfUse.p16List[language][5]}</li>
            <li>{termsOfUse.p16List[language][6]}</li>
            <li>{termsOfUse.p16List[language][7]}</li>
            <li>{termsOfUse.p16List[language][8]}</li>
            <li>{termsOfUse.p16List[language][9]}</li>
            <li>{termsOfUse.p16List[language][10]}</li>
            <li>{termsOfUse.p16List[language][11]}</li>
            <li>{termsOfUse.p16List[language][12]}</li>
            <li>{termsOfUse.p16List[language][13]}</li>
            <li>{termsOfUse.p16List[language][14]}</li>
            <li>{termsOfUse.p16List[language][15]}</li>
            <li>{termsOfUse.p16List[language][16]}</li>
            <li>{termsOfUse.p16List[language][17]}</li>
            <li>{termsOfUse.p16List[language][18]}</li>
          </ul>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p17[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p18[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p19[language]}</Typography.Paragraph>
        </Row>

        <Row>
          <Typography.Paragraph>{termsOfUse.p20[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p21[language]}</Typography.Paragraph>
        </Row>

        <Row>
          <Typography.Paragraph>{termsOfUse.p22[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p23[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p24[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p25[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p26[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p27[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p28[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p29[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p30[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p31[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p32[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p33[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p33[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <Typography.Paragraph>{termsOfUse.p34[language]}</Typography.Paragraph>
        </Row>
        <Row>
          <ul>
            <li>{termsOfUse.p34List[language][1]}</li>
            <li>{termsOfUse.p34List[language][2]}</li>
            <li>{termsOfUse.p34List[language][3]}</li>
            <li>{termsOfUse.p34List[language][4]}</li>
            <li>{termsOfUse.p34List[language][5]}</li>
            <li>{termsOfUse.p34List[language][6]}</li>
            <li>{termsOfUse.p34List[language][7]}</li>
            <li>{termsOfUse.p34List[language][8]}</li>
            <li>{termsOfUse.p34List[language][9]}</li>
            <li>{termsOfUse.p34List[language][10]}</li>
            <li>{termsOfUse.p34List[language][11]}</li>
            <li>{termsOfUse.p34List[language][12]}</li>
            <li>{termsOfUse.p34List[language][13]}</li>
            <li>{termsOfUse.p34List[language][14]}</li>
            <li>{termsOfUse.p34List[language][15]}</li>
            <li>{termsOfUse.p34List[language][16]}</li>
            <li>{termsOfUse.p34List[language][17]}</li>
            <li>{termsOfUse.p34List[language][18]}</li>
            <li>{termsOfUse.p34List[language][19]}</li>
            <li>{termsOfUse.p34List[language][20]}</li>
            <li>{termsOfUse.p34List[language][21]}</li>
            <li>{termsOfUse.p34List[language][22]}</li>
            <li>{termsOfUse.p34List[language][23]}</li>
            <li>{termsOfUse.p34List[language][24]}</li>
          </ul>
        </Row>
      </Col>
    </Row>
  );
};

export default connect(mapState)(TermsOfUse);
