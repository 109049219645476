import moment from 'moment';
import { enterLatLng } from '../actions/treasureActions';
import { store } from '../helpers/store';
import { latLng } from './pointFill';

export const fillCandy = async (
  handleCandyName,
  onHashtagChange,
  setFieldsValue,
  onEndDateChange
) => {
  store.dispatch(enterLatLng(latLng.coordinates, 0));

  setFieldsValue({
    lat0: latLng.coordinates.lat,
    lng0: latLng.coordinates.lng,
    address0: latLng.address,
  });

  handleCandyName({ target: { value: 'quickFill Candy' } });

  onHashtagChange(['quickFill']);

  onEndDateChange(parseInt(moment().add(7, 'd').format('x')));
};
