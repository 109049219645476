import React, { useState, useEffect } from 'react';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { Button, Row, Spin } from 'antd';
import { createCharacterStoryButton } from '../../../../lib/strings/contest';
import {
  getCharacterStory,
  initialCreateCharacterStory,
} from '../../../../services/characterService';
import { languages } from '../../../../lib/interfaces/language';
import { Dispatch, Action } from 'redux';
import CharacterList from './CharacterList';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { characterConstants } from '../../../../constants/characterConstants';
import { character } from '../../../../lib/strings/treasureInfo';
import { resetStoryLocalization, purgeCharacterData } from '../../../../actions/characterActions';

interface Props {
  characterList: any;
  subMenu1?: string;
  openCharacterFormUI?: (menu: string) => void;
  storeCharacters: (response: any) => void;
  isEditing: boolean;
  needsFetch: boolean;
  fetchAgain: (needsFetch: boolean) => void;
  getCharacterStoryID: (ID: number) => Action;
  language: languages;
  purgeCharacterData: () => void;
  resetStoryLocalization: () => void;
  closeCharacterFormUI: () => void;
}

const mapState = (state: any) => {
  return {
    subMenu1: state.subMenu1,
    characterList: state.characterList,
    isEditing: state.character.isEditing,
    needsFetch: state.character.needsFetch,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    openCharacterFormUI: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    closeCharacterFormUI: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    storeCharacters: (response: any) =>
      dispatch({ type: characterConstants.STORE_CHARACTERS, response }),
    fetchAgain: (needsFetch: boolean) =>
      dispatch({ type: characterConstants.FETCH_CHARACTERS, needsFetch }),
    getCharacterStoryID: (ID: number) => dispatch({ type: characterConstants.CHARACTER_ID, ID }),
    resetStoryLocalization: () => dispatch(resetStoryLocalization()),
    purgeCharacterData: () => dispatch(purgeCharacterData()),
  };
};

const Characters = (props: Props) => {
  const [loading, setloading] = useState(true);
  const [characterStoryShouldOpen, setCharacterStoryShouldOpen] = useState(false);

  useEffect(() => {
    fetchCharacterStory();
  }, []);

  const fetchCharacterStory = async () => {
    setloading(true);
    const fetchCharacterStory =  await getCharacterStory();
    if (fetchCharacterStory) {
      props.storeCharacters(fetchCharacterStory);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCharacterStoryListAgain();
  }, [props.needsFetch]);

  const fetchCharacterStoryListAgain = async () => {
    setloading(true);
    const fetchCharacterStory =  await getCharacterStory();
    if (fetchCharacterStory) {
      props.storeCharacters(fetchCharacterStory);
      props.fetchAgain(false);
      setloading(false);
    }
  };

  useEffect(() => {
    const asyncFunction = async () => {
      if (characterStoryShouldOpen) {
        props.openCharacterFormUI(subMenuConstants.characterForm);
        const res =  await initialCreateCharacterStory();
        props.getCharacterStoryID(res);
        setCharacterStoryShouldOpen(false);
      }
    };
    asyncFunction();
  }, [characterStoryShouldOpen]);

  const openCharacterCreation = async () => {
    // reset character, characterLocalization and close submenu, useEffect will open it when characterStoryShouldOpen is set to false
    props.resetStoryLocalization();
    props.purgeCharacterData();
    props.closeCharacterFormUI();
    setCharacterStoryShouldOpen(true);
  };

  return (
    <Row type="flex" justify="center" className="contests">
      <Button
        onClick={openCharacterCreation}
        className="buttons purple-gradient-right margin-contest menu-button-width"
      >
        {createCharacterStoryButton[props.language]}
      </Button>
      {!loading ? (
        <Row style={{ width: '100%' }}>
          <CharacterList characterList={props.characterList} />
        </Row>
      ) : (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      )}
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Characters);
