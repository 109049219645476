import React, { useState } from 'react';
import { Row, Button } from 'antd';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { getBoughtLCashList } from '../../../../services/balanceService';
import * as balanceStrings from '../../../../lib/strings/balance';
import { languages } from '../../../../lib/interfaces/language';
// import LcashHistory from './LcashHistory';

interface Props {
  handleOpenRecharge: (menu: string) => void;
  language?: languages;
}

const mapState = (state: { authentication: any }) => {
  return {
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleOpenRecharge: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
  };
};

const Balance = (props: Props) => {
  return (
    <Row>
      <Row type="flex" justify="center">
        <Button
          className="buttons purple-gradient-right"
          onClick={() => props.handleOpenRecharge('Recharge')}
        >
          {balanceStrings.recharge[props.language]}
        </Button>
      </Row>
      {/* <LcashHistory language={props.language} /> */}
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Balance);
