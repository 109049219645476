import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Row, Button, Typography, Col, Input, Checkbox } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import searchIcon from '../../../../assets/search.svg';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { notificationsConstants } from '../../../../constants/notificationsConstants';
import User from './User';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { getUsersList } from '../../../../services/notificationsService';
import { audience, selectAll } from '../../../../lib/strings/adminNotifications';
import { languages } from '../../../../lib/interfaces/language';
import { debounce } from 'lodash';

interface Props {
  handleClose: () => Action;
  category: number;
  users?: Array<any>;
  storeUsers: (users: Array<any>) => Action;
  selectedUsers: (user: any) => Action;
  userIDs: any;
  subMenu1: string;
  contestID: number;
  contestUserType: string;
  contestantIDs: Array<any>;
  language: languages;
  notification: any;
}

const mapState = (state: {
  notification: any;
  userList: any;
  subMenu1: string;
  authentication: any;
}) => {
  return {
    category: state.notification.category,
    users: state.userList,
    userIDs: state.notification.userIDs,
    subMenu1: state.subMenu1,
    contestID: state.notification.contestID,
    contestUserType: state.notification.contestUserType,
    contestantIDs: state.notification.contestantIDs,
    language: state.authentication.languages,
    notification: state.notification,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_2 }),
    storeUsers: (users: Array<any>) =>
      dispatch({ type: notificationsConstants.STORE_USERS, users }),
    selectedUsers: (user: any) => dispatch({ type: notificationsConstants.SELECTED_USERS, user }),
  };
};

const SearchUsers = (props: Props) => {
  const { notification, storeUsers, contestID } = props;
  const [indeterminate, setIndeterminate] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const indeterminateCheckbox = (e: any) => {
    setIndeterminate(false);
    e.target.checked
      ? props.selectedUsers(props.users.map((users) => users.mysqlID))
      : props.selectedUsers([]);
  };

  const hanldeCheckboxGroup = (checked: CheckboxValueType[]) => {
    setIndeterminate(true);
    props.selectedUsers(checked);
  };

  const getNewPage = async (which: string) => {
    let pageNo = pageNumber;
    which === 'prev' ? pageNo-- : pageNo++;
    const res =  await getUsersList(
      notification.category,
      pageNo,
      notification.type,
      searchValue,
      contestID
    );
    props.storeUsers(res.users);
    setTotalLength(res.totalLength);
    setPageNumber(pageNo);
  };

  useEffect(() => {
    setSearchValue('');
    const fetchUsers = async () => {
      const res =  await getUsersList(notification.category, 1, notification.type, '', contestID);
      console.log('res:', res);
      setPageNumber(1);
      storeUsers(res.users);
      setTotalLength(res.totalLength);

      console.log('length:', res.users.length);
    };
    fetchUsers();
  }, [notification.type, notification.category]);

  const getUsersListDebounce = useCallback(
    debounce(async (args) => {
      const res =  await getUsersList(notification.category, 1, notification.type, args, contestID);
      console.log('res:', res);
      storeUsers(res.users);
      setTotalLength(res.totalLength);
    }, 500),
    []
  );

  const searchUsers = async (e: ChangeEvent<HTMLInputElement>) => {
    setPageNumber(1);
    setSearchValue(e.target.value);
    getUsersListDebounce(e.target.value);
  };

  return (
    <Row className="kit-list-wrapper">
      <div className="scrollable">
        <Row className="contest-top-row" type="flex" justify="space-between" align="middle">
          <Col>
            <Button onClick={props.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            <Typography.Text className="contest-title bold">
              {audience[props.language]}
            </Typography.Text>
          </Col>
          <Col style={{ marginRight: '1em' }}>
            <Typography.Text className="bold">{props.userIDs.length} </Typography.Text>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="search-container">
          <Row className="search-input-container">
            <Input.Search
              onChange={searchUsers}
              className="search-input"
              placeholder="Search"
              prefix={<img src={searchIcon} />}
              value={searchValue}
            />
          </Row>
          {props.users.length > 0 && (
            <Row>
              {/* <Row type='flex' justify='space-between' className='full-width'>
                            <Typography.Text>{selectAll[props.language]}</Typography.Text>
                            <Checkbox onChange={(checked) => indeterminateCheckbox(checked)} indeterminate={indeterminate} />
                        </Row> */}
              <Checkbox.Group
                value={props.userIDs}
                className="full-width"
                onChange={(checked) => hanldeCheckboxGroup(checked)}
              >
                {props.users.map((user: any, index) => {
                  return (
                    <User
                      ID={user.mysqlID}
                      key={user.mysqlID}
                      username={user.username}
                      avatar={user.avatar}
                    />
                  );
                })}
              </Checkbox.Group>
            </Row>
          )}
        </Row>
        <Row>
          <Button onClick={() => getNewPage('prev')} disabled={pageNumber <= 1}>
            {'<'}
          </Button>
          <Button onClick={() => getNewPage('next')} disabled={totalLength <= pageNumber * 50}>
            {'>'}
          </Button>
        </Row>
      </div>
    </Row>
  );
};

export default connect(mapState, mapDispatch)(SearchUsers);
