import React from 'react';

import { Row, Spin, notification } from 'antd';
import AppContext from '../../../../AppContext';
import { connect } from 'react-redux';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import logoPic from '../../../../../src/assets/logoPic.png';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import { getNotification, crateNotification } from '../../../../services/domeService';

const showErrorMessage = (title, description?) => {
	notification.error({
		message: `${title}`,
		description: `${description}`,
		duration: 10,
	});
};

class DomeTemplate extends React.Component<any, any> {
	static contextType = AppContext;

	constructor(props: any) {
		super(props);
		this.state = {
			success: '',
			error: '',
		}
	}


	formHandler = (e: any) => {
		this.setState({
			[e.target.name]: e.target.value,
		});

	}

	getContext = async () => {
		const context = this.context;
		let profile = context.profile || {};
		return profile;
	}

	updateContext = async (data: any) => {
		const context = this.context;
		context.setProfile(data);
	}

	render() {
		const context = this.context;
		const {translation}=this.props
		return (
			<>
				<Row className="menu-wrapper treasure-menu-wrapper third-dialog">
					<div className="third-card">
						<div className="fx-jcsb-aic mb-20">
							<h4 className="text-primary mb-0 font-bold"> &#62;{translation('Use a Template')} </h4>
							<i onClick={this.props.closeCreateTemplate} className="iconx cursor">
								<img src={closeIcon} alt="Info" />
							</i>
						</div>
						<div className="domeAddContainer scrollSection fx-jcc-aic">
							<div className='text-center'>
								<figure className='mb-3'>
									<img src={logoPic} alt="logo" />
								</figure>
								<h2 className='text-muted mb-0'>{translation('Coming soon')}</h2>
							</div>
						</div>
					</div>
				</Row>
			</>
		)
	}

}

const mapStateToProps = (state:any) => ({
	translation: state.userData.translation,
  });
  export default connect(mapStateToProps, null)(DomeTemplate);
