import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

function LcashHistoryListItem({
  transactionDate,
  transactionAmount,
  totalBalance,
  transactionType,
}) {
  return (
    <>
      <div className="date-and-time">
        <Typography.Text>{moment(transactionDate).format('DD.MM.YY')}</Typography.Text>
        <Typography.Text type="secondary">
          {moment(transactionDate).format('HH:mm:ss')}
        </Typography.Text>
      </div>
      <Typography.Text>{transactionType}</Typography.Text>
      <Typography.Text
        className={[
          'amount',
          transactionAmount >= 0 ? 'balance-positive' : 'balance-negative',
        ].join(' ')}
      >
        {transactionAmount}
      </Typography.Text>
      <Typography.Text className="balance">{totalBalance}</Typography.Text>
    </>
  );
}

LcashHistoryListItem.propTypes = {
  transactionDate: PropTypes.number,
  transactionAmount: PropTypes.number,
  totalBalance: PropTypes.number,
  transactionType: PropTypes.string,
};

LcashHistoryListItem.defaultProps = {
  transactionDate: 0,
  transactionAmount: undefined,
  totalBalance: undefined,
  transactionType: undefined,
};

export default LcashHistoryListItem;
