import React, { useState, useEffect, memo } from 'react';
import { Form, Row, Col, Typography, Input, Popover, Select } from 'antd';
import { SketchPicker } from 'react-color';
import EllipseIcon from '../../../assets/ellipse12.svg';
import PropTypes from 'prop-types';

const fontTypeList = [
  'Arial',
  'Courier',
  'Courier New',
  'Helvetica',
  'Roboto',
  'Times New Roman',
  'Verdana',
];

function TextInput(props) {
  const { type, label, placeholder, onChange, initialTextSettings, popoverOffset } = props;
  const [fontSizeList, setFontSizeList] = useState([
    8,
    10,
    11,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    36,
    48,
    72,
  ]);

  const [textSettings, setTextSettings] = useState(initialTextSettings);
  const { text, fontFamily, fontSize, fontColor, fontWeight, fontStyle } = textSettings;

  const updateTextSettings = (
    key: 'text' | 'fontFamily' | 'fontSize' | 'fontColor' | 'fontWeight' | 'fontStyle',
    value: any
  ) => {
    setTextSettings((currentState) => ({
      ...currentState,
      [key]: value,
    }));
  };

  const textHandler = (event) => {
    updateTextSettings('text', event.target.value);
    // onChange('text', event.target.value);
  };

  const fontFamilyHandler = (value: string) => {
    updateTextSettings('fontFamily', value);
    // onChange('fontFamily', value);
  };

  const fontSizeHandler = (value: number) => {
    updateTextSettings('fontSize', value);
    // onChange('fontSize', value);
  };

  const fontColorHandler = (value) => {
    const rgba = `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`;
    updateTextSettings('fontColor', rgba);
    // onChange('fontColor', rgba);
  };

  const fontWeightHandler = () => {
    if (fontWeight === 'bold') {
      updateTextSettings('fontWeight', '');
      // onChange('fontWeight', '');
    } else {
      updateTextSettings('fontWeight', 'bold');
      // onChange('fontWeight', 'bold');
    }
  };

  const fontStyleHandler = () => {
    if (fontStyle === 'italic') {
      updateTextSettings('fontStyle', '');
      // onChange('fontStyle', '');
    } else {
      updateTextSettings('fontStyle', 'italic');
      // onChange('fontStyle', 'italic');
    }
  };

  useEffect(() => {
    onChange(textSettings);
  }, [textSettings]);

  const onSearchFontSize = (value) => {
    const numberValue = parseInt(value, 10);
    if (numberValue) {
      if (!fontSizeList.includes(numberValue)) {
        setFontSizeList([...fontSizeList, numberValue]);
      }
    }
  };

  return (
    <Form.Item className="roboto-regular make-new-container">
      <Row type="flex">
        <Col>
          <Typography.Text className="make-new-section-text">{label}</Typography.Text>
        </Col>
      </Row>
      {type === 'input' && (
        <Row>
          <Col>
            <Input
              className="input-treasure full-width"
              // onBlur={onBlur}
              placeholder={placeholder}
              defaultValue={text}
              onChange={textHandler}
            />
          </Col>
        </Row>
      )}
      {type === 'textArea' && (
        <Row>
          <Input.TextArea
            className="make-new-input-area"
            // onBlur={onBlur}
            placeholder={placeholder}
            style={{ height: '100px' }}
            defaultValue={text}
            onChange={textHandler}
          />
        </Row>
      )}

      <Row type="flex" justify="end" align="middle">
        {/* font type selector */}
        <Col>
          <Select
            showArrow={false}
            style={{ minWidth: '75px', marginLeft: '10px', marginRight: '10px' }}
            dropdownStyle={{ minWidth: '150px' }}
            size="small"
            defaultValue={fontFamily}
            onChange={fontFamilyHandler}
          >
            {fontTypeList.map((fontTypeList) => (
              <Select.Option key={fontTypeList} value={fontTypeList}>
                {fontTypeList}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* font size selector */}
        <Col>
          <Select
            showArrow={false}
            style={{ minWidth: '48px', marginLeft: '10px', marginRight: '10px' }}
            dropdownStyle={{ minWidth: '48px' }}
            showSearch
            onSearch={onSearchFontSize}
            notFoundContent={'Not a number'}
            size="small"
            defaultValue={fontSize}
            onChange={fontSizeHandler}
          >
            {fontSizeList.map((size, index) => (
              <Select.Option key={size} value={size}>
                {size}
              </Select.Option>
            ))}
          </Select>
        </Col>

        {/* color picker */}
        <Col>
          <Popover
            placement="bottom"
            trigger="click"
            overlayStyle={{ width: 0, height: 0 }}
            align={{ offset: popoverOffset }}
            content={<SketchPicker onChange={fontColorHandler} color={fontColor} />}
          >
            <img
              className="make-new-edit-element"
              style={{ cursor: 'pointer' }}
              src={EllipseIcon}
            />
          </Popover>
        </Col>

        {/* bold toggle */}
        <Col>
          <div
            className={
              fontWeight === 'bold'
                ? 'make-new-text-style make-new-text-style-active'
                : 'make-new-text-style'
            }
            onClick={fontWeightHandler}
          >
            <Typography.Text style={{ fontWeight: 'bold' }}>B</Typography.Text>
          </div>
        </Col>

        {/* italic toggle */}
        <Col>
          <div
            className={
              fontStyle === 'italic'
                ? 'make-new-text-style make-new-text-style-active'
                : 'make-new-text-style'
            }
            onClick={fontStyleHandler}
          >
            <Typography.Text style={{ fontStyle: 'italic' }}>I</Typography.Text>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
}

TextInput.propTypes = {
  type: PropTypes.oneOf(['input', 'textArea']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  initialTextSettings: PropTypes.object,
  loading: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'input',
  label: 'Title:',
  placeholder: 'Add title',
  onChange: () => {},
  initialTextSettings: {
    text: '',
    fontFamily: 'Arial',
    fontSize: 48,
    fontColor: 'rgba(0, 0, 0, 1)',
    fontWeight: '',
    fontStyle: '',
  },
  popoverOffset: [-80, -10],
  loading: false,
};

export default memo(TextInput);
