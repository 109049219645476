import React, { Component } from 'react';
import {
  Row,
  Col,
  Select,
  Typography,
  Form,
  notification,
  InputNumber,
  Spin,
} from 'antd';
import StickerCreation from './StickerCreation';
import FlashCreation from './FlashCreation';
import { connect } from 'react-redux';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import { languages } from '../../../lib/interfaces/language';
import * as treasureActions from '../../../actions/treasureActions';
import { FormComponentProps } from 'antd/lib/form';
import * as menuActions from '../../../actions/menuActions';
import * as appConstants from '../../../lib/strings/appConstants';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import * as treasureService from '../../../services/treasureService';
import PointCreation from './PointCreation';
import {
  roleAdmin,
  roleSuperadmin,
  candy,
  point,
  sticker,
  LDiscount,
  domeAdd,
  approved,
  finished,
  flash
} from '../../../lib/const';
import { bindActionCreators } from 'redux';
import LDiscountCreation from './LDiscountCreation';
import { menuConstants } from '../../../constants/menuConstants';
import CandyCreation from './CandyCreation';
import DomeCreation from './DomeCreation';
import AppContext from '../../../AppContext';
import { store } from '../../../helpers/store';
import { myDome, baseURL, fetchInBoundry } from '../../../lib/config';
import {b} from '../../dashboard/menu/i18next'
// const {parse, stringify} = require('flatted/cjs');

import cartIcon from '../../../../src/assets/icon_cart.svg';
import infoIcon from '../../../../src/assets/icon_info.svg';
import Mydome from '../menu/myDome';

import domeAddressIcon from '../../../../src/assets/domeAddressIcon.svg';
import greenIcon from '../../../../src/assets/green.svg';
import flashIcon from '../../../../src/assets/flash.svg';
import starIcon from '../../../../src/assets/starIcon.svg';

const { Option } = Select;
const { Title } = Typography;

const mapStateToProps = (state: any) => {
  const { treasure, authentication, locations, userData, treasureErrors } =
    state;
  const {
    treasureType,
    imgUrl,
    hashtags,
    treasureName,
    totalNumberOfUnits,
    durationUnit,
    duration,
    messagingOptions,
    totalPrice,
    pointsPerUnit,
    image,
    sectionPosition,
  } = treasure;
  const { language } = authentication;
  const { role } = userData;
  return {
    treasure,
    treasureType,
    language,
    locations,
    imgUrl,
    hashtags,
    treasureName,
    totalNumberOfUnits,
    durationUnit,
    duration,
    messagingOptions,
    role,
    totalPrice,
    pointsPerUnit,
    image,
    sectionPosition,
    treasureErrors,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      enterTreasureName: treasureActions.enterTreasureName,
      deleteAndCancel: menuActions.cancelTreasureCreation,
      goToReview: menuActions.reviewCreatedTreasure,
      enterDuration: treasureActions.enterDuration,
      addNewLocation: treasureActions.addNewLocation,
      enterMessagingRule: treasureActions.enterMessagingRule,
      selectTreasureType: treasureActions.selectTreasureType,
      handleInput: treasureActions.handleInput,
      handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
      addNewHashtag: treasureActions.addNewHashtag,
      removeHashtags: treasureActions.removeHashtags,
      resetTreasureErrors: () =>
        dispatch(treasureActions.resetTreasureErrors()),
    },
    dispatch
  );

interface TreasureCreationProps extends FormComponentProps {
  language: languages;
  hashtags: Array<any>;
  treasureName?: string;
  locations: any;
  imgUrl?: any;
  treasureType: number;
  totalNumberOfUnits: number;
  durationUnit: string;
  duration?: string;
  treasure: any;
  messagingOptions?: CheckboxValueType[];
  role: number;
  totalPrice: number;
  pointsPerUnit: number;
  isDrafts: number;
  addNewHashtag: (hashtag: string[]) => void;
  enterTreasureName?: (name: string) => void;
  deleteAndCancel?: (treasureID: number) => void;
  goToReview?: () => void;
  enterDuration?: (
    duration: number,
    unit: string,
    durationToHours: number
  ) => void;
  addNewLocation?: (locationID_UI: number) => void;
  enterMessagingRule?: (rule: CheckboxValueType[]) => void;
  selectTreasureType: (e: number) => void;
  image: File;
  sectionPosition?: number;
  handleInput: (parametarKey: string, value: any) => void;
  handleClose: () => void;
  removeHashtags: (deleteData: any) => void;
  kitType: string;
  treasureErrors: object;
  resetTreasureErrors: () => void;
}

const { Text } = Typography;

export class TreasureCreation extends Component<TreasureCreationProps> {
  static contextType = AppContext;

  durationRef: React.RefObject<InputNumber>;
  // radioGroupRef: any

  constructor(props: Readonly<TreasureCreationProps>) {
    super(props);
    this.durationRef = React.createRef();
  }

  async componentDidMount() {
    const { treasure, form } = this.props;
    if (!treasure.loading && treasure.ID) {
      // alert(this.props.treasureType);
      form.setFieldsValue({
        treasureType: this.props.treasureType,
      });
    }
  }

  componentDidUpdate(prevProps: { treasureType: number; treasure: any }) {
    const { treasure, form, treasureErrors } = this.props;

    if (
      !treasure.loading &&
      prevProps.treasureType !== this.props.treasureType
    ) {
      this.props.form.setFieldsValue({
        treasureType: this.props.treasureType,
      });
    }
    if (prevProps.treasure.loading && !treasure.loading) {
      form.setFieldsValue({
        treasureType: this.props.treasureType,
        name: this.props.treasureName,
      });
    }
  }

  async getMyDome() {
    const requestOption = {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
      },
    };
    const res = await fetch(baseURL + myDome, requestOption).then((r) =>
      r.json()
    );
    return res.data;
  }

  allDomesInViewport = async (mapInstance: any) => {
    const screenbound = mapInstance.getBounds();
    const north = screenbound.getNorthEast().lat();
    const east = screenbound.getNorthEast().lng();
    const south = screenbound.getSouthWest().lat();
    const west = screenbound.getSouthWest().lng();

    const requestOption = {
      method: 'POST',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        top_left_lat: north,
        top_left_lng: west,
        bottom_left_lat: south,
        bottom_left_lng: west,
        top_right_lat: north,
        top_right_lng: east,
        bottom_right_lat: south,
        bottom_right_lng: east,
      }),
    };
    const res = await fetch(baseURL + fetchInBoundry, requestOption).then((r) =>
      r.json()
    );
    return res.data;
  };

  async showMyDomeInMap() {
    let profile = this.context.profile;
    const mapInstance = store.getState().address.mapInstance;
    mapInstance.setZoom(11);
    let myDome = await this.getMyDome();

    for (var i = 0; i < myDome.length; i++) {
      var lat = parseFloat(myDome[i].location.x);
      var lng = parseFloat(myDome[i].location.y);
      var latlngset = new google.maps.LatLng(
        Number(myDome[i].location.y),
        Number(myDome[i].location.x)
      );

      var markerIcon = {
        url: 'https://app.loamoa-dev-merge.cladev.com/uploads/brown.png',
        labelOrigin: new google.maps.Point(10, -1),
      };

      var marker = new google.maps.Marker({
        position: latlngset,
        icon: markerIcon,
        map: mapInstance,
        label: {
          text: myDome[i].addr_name,
          color: '#0275d8',
          className: 'marker-label',
        },
        optimized: false,
      });
    }
    return myDome;
  }

  handleSelect = async (e: any) => {
    const context = this.context;
    let profileData = context.profile;
    if (profileData === null) {
      profileData = {};
    }
    var dom_map = false;
    // var adskit = false;
    var fetch_in_boundry = false;
    const mapInstance = store.getState().address.mapInstance;
    // if (e === 2) {
    //   profileData.disableHidePlusMinusButtons = true;
    //   // let markers = profileData.markers || [];
    //   // for (let i = 0; i < markers.length; i++) {
    //   //   markers[i].setMap(null);
    //   // }

    //   fetch_in_boundry = false;
    // } else {
    //   profileData.disableHidePlusMinusButtons = false;
    // }
    if (e === 5) {
      dom_map = true;
      fetch_in_boundry = true;
      mapInstance.setZoom(20);
      mapInstance.setOptions({
        minZoom: 19,
        maxZoom: 20,
      });
      profileData.disableHidePlusMinusButtons = false;
    } else {
      mapInstance.setOptions({
        minZoom: 7,
        maxZoom: 21,
      });
      mapInstance.setZoom(13);
      profileData.disableHidePlusMinusButtons = true;
      fetch_in_boundry = false;
    }
    profileData.fetch_in_boundry = fetch_in_boundry;
    profileData.dom_map = dom_map;
    profileData.menu = false;

    // if (dom_map === true) {
    //   profileData.dome =  await this.showMyDomeInMap();
    // }
    context.setProfile(profileData);

    this.props.selectTreasureType(e);
  };

  cancel = () => {
    /*
    Remove all listner event from map
    */

    const context = this.context;
    let profileData = context.profile;
    const mapInstance = store.getState().address.mapInstance;
    // Get all listner
    let idleEvent = profileData['idleEvent'] || false;
    let zoomChangedEvent = profileData['zoomChangedEvent'] || false;
    let dragEndEvent = profileData['dragEndEvent'] || false;
    // Remove listner
    if (idleEvent) {
      idleEvent.remove();
    }
    if (zoomChangedEvent) {
      zoomChangedEvent.remove();
    }
    if (dragEndEvent) {
      dragEndEvent.remove();
    }

    if (profileData === null) {
      profileData = {};
    }
    let markers = profileData.markers || [];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    profileData.fetch_in_boundry = false;
    context.setProfile(profileData);
    this.props.deleteAndCancel(this.props.treasure.ID);
    this.props.handleClose();
  };

  goToReview = async () => {
    const { treasureType, form, treasure } = this.props;
    form.validateFields();

    this.props.handleClose();

    if (this.props.treasureType === sticker) {
      if (!this.props.sectionPosition) {
        notification.error({
          message: treasureCreation.sectionErrorMessage[this.props.language],
        });
        return;
      }
    }

    const validationFields = ['treasureType'];

    const validationErrors = Object.values(
      form.getFieldsValue(validationFields)
    );

    if (treasureType === point) {
      if (
        !validationErrors.some(
          (e) => e === undefined || e === '' || e === null || e.length === 0
        ) &&
        (this.props.imgUrl ||
          this.props.treasure.mediaPath?.pic?.path ||
          this.props.treasure.mediaPath?.video?.path)
      ) {
        this.props.goToReview();
      } else if (
        !(
          this.props.imgUrl ||
          this.props.treasure.mediaPath?.pic?.path ||
          this.props.treasure.mediaPath?.video?.path
        )
      ) {
        notification.error({
          message:
            treasureCreation.TreasureCreationValidationMessage[
              this.props.language
            ],
          description:
            treasureCreation.TreasureCreationValidationDescription[
              this.props.language
            ],
        });
      } else {
        notification.error({
          message:
            treasureCreation.treasureCreationErrorTitle[this.props.language],
        });
        throw 'error with validation';
      }
    } else if (treasureType === sticker) {
      // it is same as point, but need to implement validation field logic
      if (
        !validationErrors.some((e) => e === undefined) &&
        (this.props.imgUrl ||
          this.props.treasure.mediaPath?.pic?.path ||
          this.props.treasure.mediaPath?.video?.path)
      ) {
        this.props.goToReview();
      } else if (
        !(
          this.props.imgUrl ||
          this.props.treasure.mediaPath?.pic?.path ||
          this.props.treasure.mediaPath?.video?.path
        )
      ) {
        notification.error({
          message:
            treasureCreation.TreasureCreationValidationMessage[
              this.props.language
            ],
          description:
            treasureCreation.TreasureCreationValidationDescription[
              this.props.language
            ],
        });
      } else {
        notification.error({
          message:
            treasureCreation.treasureCreationErrorTitle[this.props.language],
        });
        throw 'error with validation';
      }
    } else {
      if (
        !validationErrors.some(
          (e) => e === undefined || e === '' || e === null || e.length === 0
        )
      ) {
        this.props.goToReview();
      } else {
        throw 'error with validation';
      }
    }
  };

  handleAddAnotherLocation = () => {
    this.props.addNewLocation(this.props.locations.length);
  };

  startDateHandler = (value) => {
    this.updateTreasureApiWithCheck('startDate', value);
  };
  endDateHandler = (value) => {
    this.updateTreasureApiWithCheck('endDate', value);
  };

  updateTreasureApiWithCheck = (parametarKey: string, value: any) => {
    const { treasure, handleInput } = this.props;
    if (
      value !== null &&
      value !== undefined &&
      (!treasure[parametarKey] || treasure[parametarKey] !== value)
    ) {
      handleInput(parametarKey, value);
      treasureService.treasureUpdate(treasure.ID, parametarKey, value);
    }
  };

  onHashtagChange = async (event) => {
    const { treasure, addNewHashtag, removeHashtags } = this.props;

    const hashtagsNameList = treasure.hashtags.map(
      (hashtagObject) => hashtagObject.name
    );
    const hashtagsToDelete = hashtagsNameList.filter(
      (item) => !event.includes(item)
    );
    const hashtagsToAdd = event.filter(
      (item) => !hashtagsNameList.includes(item)
    );

    if (hashtagsToAdd.length) {
      // refactor reducer and action for this
      addNewHashtag(hashtagsToAdd);
    }
    if (hashtagsToDelete.length) {
      const deleteData = treasure.hashtags.filter((item) =>
        hashtagsToDelete.includes(item.name)
      );
      removeHashtags(deleteData);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { language, treasure } = this.props;
    const contextProfile = this.context;
    let profile = contextProfile.profile;
    let iscart = profile['iscart'] || false;

    const adKitWithKitType =
      this.props.treasureType === point &&
      (this.props.treasure.kitType === 'image' ||
        this.props.treasure.kitType === 'video');
        const isTreasureRunning =
        treasure.isEditing && (treasure.status === approved || treasure.status === finished);

    return (
      <Row type="flex" justify="center" className="create-treasure">
        {!treasure.loading && (
          <>
            <Col className="create-treasure-child">
              <div className="create-treasure-padding">
                <Row>
                  <Col>
                    <Title className="create-treasure-title" level={4}>
                      {b?.t('CREATE A NEW DOME ADDRESS & TREASURE')}
                    </Title>
                  </Col>
                </Row>

                <Form>
                  {/* Treasure type selector */}
                  <Form.Item
                    label={b?.t('Type')}
                  >
                    {getFieldDecorator('treasureType')(
                      <Select
                        className="select-treasure-type treasure-type-picker"
                        onChange={this.handleSelect}
                        disabled={isTreasureRunning}
                          >
                        <Option value={domeAdd}>
                          <span className='selectIcons'><img src={domeAddressIcon} alt="icon" /></span> {b?.t("Dome Address")}
                        </Option>

                        
                        {this.props.role >= roleAdmin && (
                          <Option value={sticker}>
                            {
                              b?.t('Sticker')
                            }
                          </Option>

                        )}

                        <Option value={flash}>
                          <span className='selectIcons'><img src={flashIcon} alt="icon" /></span> {b?.t('Flash')}
                        </Option>

                        <Option value={point}>
                          <span className='selectIcons'><img src={starIcon} alt="icon" /></span> {b?.t('Adskit')}
                        </Option>

                        <Option value="">
                          <span className='selectIcons'><img src={greenIcon} alt="icon" /></span> {b?.t('Green')}
                        </Option> 

                        {this.props.role >= roleAdmin && (
                          <Option value={candy}>
                            {appConstants.TreasureCreationCandy[language]}
                          </Option>
                        )}
                        {this.props.role >= roleSuperadmin && (
                          <Option value={LDiscount}>
                            {appConstants.TreasureCreationLDiscount[language]}
                          </Option>
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Form>
                {/* Dome Creation */}
                {this.props.treasureType === domeAdd && !iscart && (
                  <DomeCreation onCancel={this.cancel} />
                )}
              </div>
            </Col>

            {/* StickerCreation */}
            {this.props.treasureType === sticker && (
              <StickerCreation
                onHashtagChange={this.onHashtagChange}
                onStartDateChange={this.startDateHandler}
                onEndDateChange={this.endDateHandler}
                onAnotherLocationClick={this.handleAddAnotherLocation}
                onCancel={this.cancel}
                onReview={this.goToReview}
              />
            )}
      {/* {flashCreation} */}
{this.props.treasureType === flash && (
              <FlashCreation
                onHashtagChange={this.onHashtagChange}
                onStartDateChange={this.startDateHandler}
                onEndDateChange={this.endDateHandler}
                onAnotherLocationClick={this.handleAddAnotherLocation}
                onCancel={this.cancel}
                onReview={this.goToReview}
              />
            )}

            {/* Advertisment KIT */}
            {this.props.treasureType === point && (
              <PointCreation
                onHashtagChange={this.onHashtagChange}
                onStartDateChange={this.startDateHandler}
                onEndDateChange={this.endDateHandler}
                onAnotherLocationClick={this.handleAddAnotherLocation}
                onCancel={this.cancel}
                onReview={this.goToReview}
              />
            )}

            {/* CandyCreation */}
            {this.props.treasureType === candy && (
              <CandyCreation
                onHashtagChange={this.onHashtagChange}
                onStartDateChange={this.startDateHandler}
                onEndDateChange={this.endDateHandler}
                onAnotherLocationClick={this.handleAddAnotherLocation}
                onCancel={this.cancel}
                onReview={this.goToReview}
              />
            )}

            {/* LDiscount */}
            {this.props.treasureType === LDiscount && (
              <LDiscountCreation
                form={this.props.form}
                onHashtagChange={this.onHashtagChange}
                onStartDateChange={this.startDateHandler}
                onEndDateChange={this.endDateHandler}
                onAnotherLocationClick={this.handleAddAnotherLocation}
                onCancel={this.cancel}
                onReview={this.goToReview}
              />
            )}
          </>
        )}
        {treasure.loading && (
          <Spin
            size="large"
            style={{ marginTop: '2em', marginBottom: '2em' }}
          />
        )}
      </Row>
    );
  }
}

const wrappedTreasureCreationForm = Form.create({ name: 'treasure' })(
  TreasureCreation
);
export default connect(mapStateToProps,mapDispatchToProps)(wrappedTreasureCreationForm);
