import { characterConstants } from '../constants/characterConstants';

export const initialStateCharacter = {
  isEditing: false,
  isCreating: false,
  needsFetch: false,
  titleShowsInList: true,
  titleShowsInDetails: true,
  subtitleShowsInList: true,
  subtitleShowsInDetails: true,
  selectedLanguage: 'EN',
};

export const character = (state = initialStateCharacter, action: any) => {
  switch (action.type) {
    case characterConstants.CHARACTER_ID:
      return {
        ...state,
        id: action.ID,
        isCreating: true,
      };
    case characterConstants.UPDATE_INPUT:
      return {
        ...state,
        [action.param]: action.value,
      };
    case characterConstants.EDIT_CHARACTER:
      return {
        isEditing: true,
        ...action.character,
      };
    case characterConstants.FETCH_CHARACTERS:
      return {
        ...state,
        needsFetch: action.needsFetch,
      };
    case characterConstants.STORE_FILES:
      return {
        ...state,
        [action.typeOfFile]: action.file,
      };
    case characterConstants.PURGE_CHARACTER_DATA:
      return initialStateCharacter;
    case characterConstants.STORE_FILE_PATH:
      return {
        ...state,
        [action.typeOfFile]: action.path,
      };
    case characterConstants.BATCH_EDIT:
      const stringToBool = (value) => {
        if (typeof value === 'string') {
          switch (value.toLowerCase().trim()) {
            case 'true':
            case 'yes':
            case '1':
              return true;
            case 'false':
            case 'no':
            case '0':
            case null:
              return false;
            default:
              return Boolean(value);
          }
        }
        return Boolean(value);
      };
      return {
        ...state,
        auxilliary_description: '',
        ...action.payload,
        titleShowsInList: stringToBool(action.payload.titleShowsInList),
        titleShowsInDetails: stringToBool(action.payload.titleShowsInDetails),
        subtitleShowsInList: stringToBool(action.payload.subtitleShowsInList),
        subtitleShowsInDetails: stringToBool(action.payload.subtitleShowsInDetails),
        optional_image: { image: action.payload.optional_image },
        preview_image: { image: action.payload.preview_image },
        preview_video: { video: action.payload.preview_video },
        video_thumbnail: { image: action.payload.video_thumbnail },
      };
    default:
      return state;
  }
};

export const characterList = (state: Array<any> = [], action: any) => {
  switch (action.type) {
    case characterConstants.STORE_CHARACTERS:
      return action.response;
    case characterConstants.DELETE_CHARACTER:
      const characterToRemove = state.findIndex((character) => character.id === action.id);
      return [...state.slice(0, characterToRemove), ...state.slice(characterToRemove + 1)];
    default:
      return state;
  }
};

export const characterDetails = (state = {}, action: any) => {
  switch (action.type) {
    case characterConstants.GET_CHARACTER_BY_ID:
      return {
        ...state,
        ...action.character,
      };
    case characterConstants.DELETE_CHARACTER:
      return {};
    default:
      return state;
  }
};

export const characterStoryLocalization = (state = {}, action: any) => {
  switch (action.type) {
    case characterConstants.UPDATE_LOCALIZATION_DATA:
      return action.payload;
    case characterConstants.RESET_STORY_LOCALIZATION:
      return {};
    default:
      return state;
  }
};
