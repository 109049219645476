import { Row } from 'antd';
import React from 'react';
import { postImportDomeByName } from '../../../../services/domeService';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

type DomeDuplicateVerficationProps = {
  selectedData: {
    addr_name: string;
    ID: number;
    dome_name: string;
    email: string;
    dome_status: any;
    dome_name_suffix: any;
    file: any;
  };
  onClose: () => void;
  updateDomeList: (currDomeId: number) => void;
  callLoadAgain: () => void;
};

const DomeDuplicateVerfication = ({
  selectedData,
  onClose,
  updateDomeList,
  callLoadAgain,
}: DomeDuplicateVerficationProps) => {
  const {t}=useTranslation()
  const {
    addr_name,
    ID,
    dome_status,
    dome_name,
    dome_name_suffix,
    file,
    email,
  } = selectedData;

  const onConfirmClick = async () => {
    const final_dome_name = renderDomeName(
      dome_status,
      dome_name,
      dome_name_suffix,
      file
    );
     await postImportDomeByName(final_dome_name, ID);
     await updateDomeList(ID);
     await callLoadAgain();
    onClose();
  };

  const renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };

  const encodeEmail = (email) => {
    let finalEmail = [];
    let splited = email.split('');
    let atTheRateInd = email.indexOf('@');
    let dotInd = email.indexOf('.');
    let indexArray = [
      atTheRateInd,
      atTheRateInd - 1,
      atTheRateInd - 2,
      atTheRateInd - 3,
      dotInd,
    ];

    for (let index = 0; index < splited.length; index++) {
      if (indexArray.includes(index)) {
        finalEmail.push(splited[index]);
      } else {
        finalEmail.push('X');
      }
    }
    return finalEmail.join('');
  };

  return (
    <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
      <div className="third-card">
        <div className="fx-jcsb-aic mb-20">
          <h4 className="text-primary mb-0 font-bold">
            {' '}
            &#62;{t('Import Dome to duplicate verification')} 
          </h4>
        </div>

        <div className="grid-row sm fx-aic mb-10">
          <div className="col col-4 size12 text-right">{t('Dome Name if any')}</div>
          <div className="col col-7">
            <input
              type="text"
              value={renderDomeName(
                dome_status,
                dome_name,
                dome_name_suffix,
                file
              )}
              className="form-control xs"
              disabled
            />
          </div>
        </div>

        <div className="grid-row sm fx-aic mb-10">
          <div className="col col-4 size12 text-right">{t('Dome Address')}</div>
          <div className="col col-7">
            <input
              type="text"
              value={addr_name}
              className="form-control xs"
              disabled
            />
          </div>
        </div>

        <div className="size12 pl-30">
          <p className="text-primary">
          {t('The verification code for')} {addr_name}{t('has been sent to')}  {addr_name}
            {'  '}
            {t('assigned email')} {email && encodeEmail(email)}.
          </p>
        </div>
        <div className="fx-jcc">
          <button onClick={onClose} className="ant-btn primary-ghost-btn xs">
          {t('Cancel')}
          </button>
          <button
            onClick={onConfirmClick}
            className="ant-btn yellow-gradient xs ml-10"
          >
           {t('Confirm')} 
          </button>
        </div>
      </div>
    </Row>
  );
};

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomeDuplicateVerfication);
