import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Modal, Row, Col } from 'antd';
import Statistics from './Statistics';
import {
  statisticsForPartnersTitle,
  statisticsForPartnersStringsAdmin,
  statisticsForPartnersStringsPartner,
  pay,
  paid,
  waiting,
  received,
} from '../../../../lib/strings/statistics';
import { statisticsForPartnersAdmin, statisticsForPartnersPartner } from './apiToTabel';
import * as statisticsService from '../../../../services/statisticsService';
import { roleAdmin } from '../../../../lib/const';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';
import MarkAsPaidModal from '../../../../components/statistics/Partners/MarkAsPaidModal';

function StatisticsForPartners(props) {
  const { language, role } = props;
  const queryClient = useQueryClient();

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const isAdmin = role >= roleAdmin;
  const fetchStatisticsForPartners = async ({ queryKey }) => {
    if (isAdmin) {
      const response =  await statisticsService.getStatisticsForPartnersAdmin(queryKey[1]);
      return response;
    } else {
      const response =  await statisticsService.getStatisticsForPartners(queryKey[1]);
      return response;
    }
  };

  const statisticsQuery = useQuery(
    ['StatisticsForPartners', { ...currentFilters, page: currentPage, isAdmin }],
    fetchStatisticsForPartners
  );

  const onPayHandler = useMutation((ID) => statisticsService.markAsPaid(ID), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        'StatisticsForPartners',
        { ...currentFilters, page: currentPage },
      ]);
    },
    onError: (error) => {
      showErrorMessage(serverError[language], error);
    },
  });

  useEffect(() => {
    if (statisticsQuery.data?.statisticsData) {
      setPageSize(statisticsQuery.data.itemsPerPage);
      setTotalItems(statisticsQuery.data.treasureLength);

      let parsedStatistics;
      if (isAdmin) {
        parsedStatistics = statisticsForPartnersAdmin(
          statisticsQuery.data.statisticsData,
          pay[language],
          paid[language]
        );
      } else if (!isAdmin) {
        parsedStatistics = statisticsForPartnersPartner(
          statisticsQuery.data.statisticsData,
          waiting[language],
          received[language]
        );
      }
      setStatistics(parsedStatistics);
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
  };

  let columns;
  // if role >= admin
  if (isAdmin) {
    let columnsAdmin = [
      {
        titleStyle: {},
        dataIndex: 'date',
        dataStyle: {},
      },
      {
        dataIndex: 'treasureName',
      },
      {
        dataIndex: 'creatorUsername',
      },
      {
        dataIndex: 'partnerUsername',
      },
      {
        dataIndex: 'partnerShare',
        titleStyle: { textAlign: 'end', paddingRight: '5%' },
        dataStyle: { textAlign: 'end', paddingRight: '5%' },
      },
      {
        dataIndex: 'partnerBankAccount',
      },
      {
        dataIndex: 'paymentStatus',
        titleStyle: { textAlign: 'center' },
        dataStyle: { textAlign: 'center' },
        onClick: (rowId) => {
          handleShowModal(rowId);
        },
      },
    ];

    columnsAdmin.map((column, index) => {
      columnsAdmin[index]['title'] = statisticsForPartnersStringsAdmin[language][column.dataIndex];
    });

    columns = columnsAdmin;
    columns.map((column, index) => {
      columns[index]['title'] = statisticsForPartnersStringsAdmin[language][column.dataIndex];
    });
  } else if (!isAdmin) {
    let columnsPartner = [
      {
        titleStyle: {},
        dataIndex: 'date',
        dataStyle: {},
      },
      {
        dataIndex: 'treasureName',
      },
      {
        dataIndex: 'creatorUsername',
      },
      {
        dataIndex: 'createdUnits',
        titleStyle: { textAlign: 'end', paddingRight: '5%' },
        dataStyle: { textAlign: 'end', paddingRight: '5%' },
      },
      {
        dataIndex: 'collectedUnits',
        titleStyle: { textAlign: 'end', paddingRight: '5%' },
        dataStyle: { textAlign: 'end', paddingRight: '5%' },
      },
      {
        dataIndex: 'treasureStatus',
        titleStyle: { textAlign: 'center' },
        dataStyle: { textAlign: 'center' },
      },
      {
        dataIndex: 'chargedCash',
        titleStyle: { textAlign: 'end', paddingRight: '5%' },
        dataStyle: { textAlign: 'end', paddingRight: '5%' },
      },
      {
        dataIndex: 'partnerShare',
        titleStyle: { textAlign: 'end', paddingRight: '5%' },
        dataStyle: { textAlign: 'end', paddingRight: '5%' },
      },
      {
        dataIndex: 'paymentStatus',
        titleStyle: { textAlign: 'center' },
        dataStyle: { textAlign: 'center' },
        onClick: (rowId) => {
        },
      },
    ];

    columnsPartner.map((column, index) => {
      columnsPartner[index]['title'] =
        statisticsForPartnersStringsPartner[language][column.dataIndex];
    });

    columns = columnsPartner;
    columns.map((column, index) => {
      columns[index]['title'] = statisticsForPartnersStringsPartner[language][column.dataIndex];
    });
  }

  const handleShowModal = (id) => {
    const selectedStatistic = statisticsQuery.data.statisticsData?.find(
      (statistic) => statistic.ID === id
    );
    setModalData(selectedStatistic);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Statistics
        label={statisticsForPartnersTitle[language]}
        columns={columns}
        dataSource={statistics}
        loading={statisticsQuery.isLoading}
        statisticsType={'StatisticsForPartners'}
        onFiltersChange={onFiltersChangeHandler}
        onPageChange={onPageChangeHandler}
        totalItems={totalItems}
        pageSize={pageSize}
        currentPage={currentPage}
        isPartner={!isAdmin}
      />
      <MarkAsPaidModal
        language={language}
        visible={showModal}
        onCancel={hideModal}
        data={modalData}
        onPay={onPayHandler.mutate}
      />
    </>
  );
}

StatisticsForPartners.propTypes = {};

StatisticsForPartners.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
});

export default connect(mapStateToProps)(StatisticsForPartners);
