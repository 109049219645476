import React, { useState, useEffect } from 'react';
import { Button, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { contestConstants } from '../../../../constants/contestConstants';
import { getContest, initialCreateContest } from '../../../../services/contestService';
import ContestList from './ContestList';
import { menuConstants } from '../../../../constants/menuConstants';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { languages } from '../../../../lib/interfaces/language';
import { createIdolButton, editIdolBanner } from '../../../../lib/strings/contest';
import ButtonWithSpinner from '../../../../components/ButtonWithSpinner';

interface Props {
  contestList: any;
  subMenu1?: string;
  openSubmenu1?: (menu: string) => void;
  storeContests: (response: any) => void;
  isEditing: boolean;
  needsFetch: boolean;
  fetchAgain: (needsFetch: boolean) => void;
  getContestID: (ID: number) => Action;
  language: languages;
  clear: () => Action;
}

const mapState = (state: any) => {
  return {
    subMenu1: state.subMenu1,
    contestList: state.contestList,
    isEditing: state.contest.isEditing,
    needsFetch: state.contest.needsFetch,
    language: state.authentication.language,
  };
};

const mapDispatch = (dispatch: Dispatch) => {
  return {
    openSubmenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    storeContests: (response: any) => dispatch({ type: contestConstants.STORE_CONTESTS, response }),
    fetchAgain: (needsFetch: boolean) =>
      dispatch({ type: contestConstants.FETCH_CONTESTS, needsFetch }),
    getContestID: (ID: number) => dispatch({ type: contestConstants.CONTEST_ID, ID }),
    clear: () => dispatch({ type: contestConstants.PURGE_CONTEST_DATA }),
  };
};

const Contests = (props: Props) => {
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchContests();
  }, []);

  const fetchContests = async () => {
    setloading(true);
    const fetchContests =  await getContest();
    if (fetchContests) {
      props.storeContests(fetchContests);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchContestListAgain();
  }, [props.needsFetch]);

  const fetchContestListAgain = async () => {
    setloading(true);
    const fetchContests =  await getContest();
    if (fetchContests) {
      props.storeContests(fetchContests);
      props.fetchAgain(false);
      setloading(false);
    }
  };

  const openContestCreation = async () => {
    props.clear();
    props.openSubmenu1(subMenuConstants.contestForm);
    const res =  await initialCreateContest();
    props.getContestID(res);
  };

  const editIdolBannerHandler = () => {
    props.openSubmenu1(subMenuConstants.editIdolBanner);
  };

  return (
    <Row type="flex" justify="center" className="contests">
      <ButtonWithSpinner
        onClick={openContestCreation}
        label={createIdolButton[props.language]}
        type="purpleGradient"
        additionalClasses="menu-button-width"
        style={{ marginTop: '1em' }}
      />
      <ButtonWithSpinner
        onClick={editIdolBannerHandler}
        label={editIdolBanner[props.language]}
        type="purpleBorder"
        additionalClasses="menu-button-width"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      />
      {!loading ? (
        <Row style={{ width: '100%' }}>
          <ContestList contestList={props.contestList} />
        </Row>
      ) : (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      )}
    </Row>
  );
};

export default connect(mapState, mapDispatch)(Contests);
