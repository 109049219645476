import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import Treasures from './getTreasure/Treasures';
import Stats from './statistics/Stats';
import { connect } from 'react-redux';
import * as menuActions from '../../../actions/menuActions';
import { bindActionCreators } from 'redux';
import * as menu from '../../../lib/strings/menu';
import { languages } from '../../../lib/interfaces/language';
import Review from './getTreasure/Review';
import Drafts from './getTreasure/Drafts';
import { roleAdmin, rolePartner, roleSuperadmin, roleMaster } from '../../../lib/const';
import Contests from './contest/Contests';
import Kits from './kit/Kits';
import Balance from './balance/Balance';
import Notifications from './notifications/Notifications';
import Characters from './characters/Characters';
import { isDev } from '../../../lib/config';
import CandyEvents from './candyEvent/CandyEvents';
import Requests from './requests/Requests';
import Users from './users/Users';
import SuperAdminMenu from './superAdmin/menu';
import HelpMenu from './superAdmin/helpMenu';
import storedPermissions from "./Profile"
import MyDomButton from './MyDomButton';
import DomButton from './DomButton';
import AccountMenu from './accountMenuButton';
import AppContext from '../../../AppContext';
import Profile from './Profile';
import { store } from '../../../helpers/store';
import { useTranslation } from "react-i18next";

// import { GetAllDomeByBounds } from '../../../services/domeService';
import ReactTooltip from 'react-tooltip';
import {
  // findCoordinates,
  findNewCoordinates,
  GetAllDomeByBounds,
} from '../../../services/domeService';
import MasterSupport from '../../dashboard/menu/support/masterSupport';
import LatLon from 'mt-latlon';
import County_Cup from './candyEvent/County_Cup ';
// const {parse, stringify} = require('flatted/cjs');

const mapStateToProps = (state: {
  isSubMenuFullScreenOpen;
  menu;
  menuUIChange: {
    goToReview: boolean;
    openTreasureCreation: boolean;
    cancelTreasureCreation: boolean;
  };
  authentication: { language: languages };
  userData: { role: number, userPermissions: string, permissions: any };
  isDetailsUIOpen: boolean;
}) => {
  const {
    menuUIChange,
    authentication,
    userData,
    isDetailsUIOpen,
    isSubMenuFullScreenOpen,
    menu,
  } = state;
  const { goToReview, openTreasureCreation, cancelTreasureCreation } =
    menuUIChange;
  const { language } = authentication;
  const { role, userPermissions, permissions } = userData;

  return {
    goToReview,
    openTreasureCreation,
    cancelTreasureCreation,
    language,
    role,
    userPermissions,
    permissions,
    isDetailsUIOpen,
    isSubMenuFullScreenOpen,
    menu,
  };
};

// const actionCreators = {
//     handleOpenTreasureCreationUI: menuActions.openTreasureCreation,
//     handleCloseDetailsUI: menuActions.closeDetailsUI
// }

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      handleOpenTreasureCreationUI: menuActions.openTreasureCreation,
      handleCloseDetailsUI: menuActions.closeDetailsUI,
      menuChange: (menu) => dispatch(menuActions.changeMenu(menu)),
      closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
      closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
    },
    dispatch
  );

interface TreasureDataProps {
  role: number;
  userPermissions: string;
  permissions: any;
  language: languages;
  goToReview: boolean;
  isDetailsUIOpen: boolean;
  openTreasureCreation: boolean;
  cancelTreasureCreation: boolean;
  handleCloseDetailsUI: () => void;
  handleOpenTreasureCreationUI: () => void;
  isSubMenuFullScreenOpen: boolean;
  menuChange: (value: string) => void;
  menu: string;
  closeSubmenu1: () => void;
  closeSubmenu2: () => void;
}

const MenuButtons = (props: TreasureDataProps) => {

  const profile = useContext(AppContext);
  //prizes - creation and review switch here
  // const [current, setcurrent] = useState('')
  // const current = props.menu;
  const { t } = useTranslation();
  let current = props.menu;
  if (
    profile.profile['current_menu_name'] !== '' &&
    profile.profile['current_menu_name'] !== 'undefined'
  ) {
    current = profile.profile['current_menu_name'];
  }

  const lboxButton = () => {
    profile.profile['lbox'] = true
    profile.profile['current_menu_name']=false
    profile.profile['recharge'] = false;
    profile.profile['received'] = false;
    profile.profile['history'] = false;
    profile.profile['cashing'] = false;
    profile.profile['openMasterSupport'] = false;
    profile.profile['voucher'] = false;
    profile.setContextData(profile.profile)
  }

  const lboxButtonAdmin = () => {
    profile.profile['current_menu_name']=false
    profile.profile['adminVoucher'] = false;
    profile.profile['lboxAdmin'] = true
    profile.setContextData(profile.profile)
  }

  const handleClick = (e: any) => {
    profile.profile['menu'] = true;
    profile.profile['lbox'] = false;
    profile.profile['lboxAdmin'] = false
    profile.profile['prev_menu_name'] = current;
    profile.profile['current_menu_name'] = '';
    profile.profile['edit_current_dome'] = null;
    profile.profile['current_dome'] = null;
    profile.profile['admin_dome'] = null;
    profile.profile['accountSetting'] = null;
    profile.profile['paymentMethos'] = false;
    profile.profile['openMasterSupport'] = false;
    profile.profile['createMasterSupport'] = false;
    profile.profile['openMasterSupport'] = false;
    profile.profile['support'] = false;
    profile.profile['name_review'] = false;
    profile.profile['recharge'] = false;
    profile.profile['received'] = false;
    profile.profile['history'] = false;
    profile.profile['cashing'] = false;
    profile.profile['voucher'] = false;
    profile.profile['adminVoucher'] = false;

    profile.profile['domeList'] = null;
    profile.setContextData(profile.profile);

    const mapInstance = store.getState().address.mapInstance;
    mapInstance.setZoom(13);
    // mapInstance.setOptions({
    //   minZoom: 12,
    //   maxZoom: 12,
    //   gestureHandling: "none",
    //  // zoomControl: false
    // });
    // zoomControl: false,
    // gestureHandling: 'none'
    props.closeSubmenu2();
    props.closeSubmenu1();
    props.menuChange(e.target.value);
  };

  const handleMyDomClick = (e: any) => {
    handleClick(e);
  };
  const handleSelectedMenuItem = () => {
    switch (current) {
      case 'treasures':
        return <Treasures />;
      case 'Dome Add':
        return <MyDomButton />;
      case 'Dome':
        return <DomButton />;
      case 'Account':
        return <AccountMenu />;
      case 'characters':
        return <Characters />;
      case 'drafts':
        return <Drafts />;
      case 'statistics':
        return <Stats />;
      case 'review':
        return <Review reviewSelected={current === 'review' ? true : false} />;
      case 'idol':
        return <Contests />;
      case 'kit':
        return <Kits />;
      case 'balance':
        return <Balance />;
      case 'notifications':
        return <Notifications />;
      case 'candyEvent':
        return <CandyEvents />;
      case 'requests':
        return <Requests />;
      case 'users':
        return <Users />;
      case 'dome-admin':
        return <SuperAdminMenu />;
      case 'help-admin':
        return <HelpMenu />;
      case 'Help':
        return <MasterSupport />;
      default:
        break;
    }
  };

  const handleCreateNewTreasure = () => {
    profile.profile['dom_map'] = true;
    profile.profile['fetch_in_boundry'] = true;
    profile.profile['preset_dome'] = true;
    profile.setContextData(profile.profile);
    if (props.isDetailsUIOpen) {
      props.handleCloseDetailsUI();
    }
    props.handleOpenTreasureCreationUI();
    const mapInstance = store.getState().address.mapInstance;
    mapInstance.setZoom(20);
    mapInstance.setOptions({
      minZoom: 19,
      maxZoom: 20,
    });

    let idleEvent = mapInstance.addListener('idle', getBoundary);
    let zoomeChangedEvent = mapInstance.addListener(
      'zoom_changed',
      zoomChanged
    );
    let dragEndEvent = mapInstance.addListener('dragend', dragEnd);

    profile.profile['idleEvent'] = idleEvent;
    profile.profile['zoomChangedEvent'] = zoomeChangedEvent;
    profile.profile['dragEndEvent'] = dragEndEvent;
    /**............... */
    profile.profile['domeList'] = null;
    profile.profile['current_dome'] = false;
    profile.profile['isSettingsClosed'] = true;
    profile.profile['edit_current_dome'] = null;
    profile.profile['admin_dome'] = null;
    profile.profile['recharge'] = false;
    profile.profile['accountSetting'] = false;
    profile.profile['paymentMethos'] = false;
    profile.profile['openMasterSupport'] = false;
    profile.profile['support'] = false;
    profile.profile['createMasterSupport'] = false;
    profile.profile['received'] = false
    profile.profile['history'] = false
    profile.profile['cashing'] = false
    profile.profile['lbox'] = false; 
    profile.profile['voucher'] = false; 
    profile.profile['lboxAdmin'] = false;
    profile.profile['adminVoucher'] = false;


    /**............... */
    profile.setContextData(profile.profile);
  };

  const dragEnd = async () => {

    let context = profile;
    let profileData = context.profile;
    profileData['fetch_in_boundry'] = true;
    let markers = profileData['markers'];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profileData['markers'] = [];
  };

  const zoomChanged = async () => {
    // alert('Zoom Changed');
    let context = profile;
    let profileData = context.profile;
    profileData['fetch_in_boundry'] = true;
    let markers = profileData['markers'];
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    profileData['markers'] = [];
  };

  // function padLeadingZeros(num, size) {
  //   var s = num + '';
  //   while (s.length < size) s = '0' + s;
  //   return s;
  // }

  String.prototype.replaceAt = function (index, replacement) {
    return (
      this.substring(0, index) +
      replacement +
      this.substring(index + replacement.length)
    );
  };

  function domeName(prev_dome_name) {
    let new_name = '';
    for (let i = 9; i >= 0; i--) {
      if (i >= 3 && i <= 6) {
        let strchar = prev_dome_name[i].charCodeAt(0);
        if (strchar < 122) {
          if (i == 6) {
            prev_dome_name = prev_dome_name
              .replaceAt(7, '0')
              .replaceAt(8, '0')
              .replaceAt(9, '0');
          }
          if (i == 5) {
            prev_dome_name = prev_dome_name
              .replaceAt(6, 'a')
              .replaceAt(7, '0')
              .replaceAt(8, '0')
              .replaceAt(9, '0');
          }
          if (i == 4) {
            prev_dome_name = prev_dome_name
              .replaceAt(5, 'a')
              .replaceAt(6, 'a')
              .replaceAt(7, '0')
              .replaceAt(8, '0')
              .replaceAt(9, '0');
          }
          if (i == 3) {
            prev_dome_name = prev_dome_name
              .replaceAt(4, 'a')
              .replaceAt(5, 'a')
              .replaceAt(6, 'a')
              .replaceAt(7, '0')
              .replaceAt(8, '0')
              .replaceAt(9, '0');
          }
          strchar += 1;
          new_name = prev_dome_name.replaceAt(i, String.fromCharCode(strchar));
          break;
        }
      } else {
        let number = Number(prev_dome_name[i]);
        if (number < 9) {
          new_name = prev_dome_name.replaceAt(i, (number + 1).toString());
          break;
        } else {
          new_name = prev_dome_name.replaceAt(i, '0').toString();
          prev_dome_name = new_name;
        }
      }
    }

    return new_name;
  }

  const getBoundary = async () => {
    let context = profile;
    let profileData = context.profile;
    let adskit = profileData['adskit'] || false;
    let circle = profileData['circle'] || false;
    let fetch_in_boundry = profileData['fetch_in_boundry'] || false;
    var myDome = [];
    const mapInstance = store.getState().address.mapInstance;
    if (!mapInstance) return;
    if (adskit) {
      return false;
    }
    if (circle) {
      return false;
    }
    if (!fetch_in_boundry) {
      return false;
    }

    const bounds = mapInstance.getBounds();

    const obj: any = {};
    obj['north'] = bounds.getNorthEast().lat();
    obj['east'] = bounds.getNorthEast().lng();
    obj['south'] = bounds.getSouthWest().lat();
    obj['west'] = bounds.getSouthWest().lng();

    // const centerLat = mapInstance.getCenter().lat();
    // const centerLng = mapInstance.getCenter().lng();

    if (fetch_in_boundry && !profileData?.disableHidePlusMinusButtons) {
      profileData['dome_loader'] = true;
      profileData['points_loader'] = true;
      context.setContextData(profileData);
      profileData['fetch_in_boundry'] = false;
      myDome = findNewCoordinates(obj?.south, obj?.west);
      // let domeFromBac =  await findNewCoordinates(obj?.south, centerLng);
      let domeFromBac = await GetAllDomeByBounds(obj);

      /** ................ ADDING NAMES ACCORDING TO BACKEND STARTS ................ */

      if (domeFromBac && domeFromBac.last_name) {
        for (let i = 0; i < myDome.length; i++) {
          if (i === 0) {
            myDome[i].addr_name = domeName(domeFromBac.last_name);
          } else {
            myDome[i].addr_name = domeName(myDome[i - 1].addr_name);
          }
        }
      }
      /** ................ ADDING NAMES ACCORDING TO BACKEND ENDS ................ */

      /** ................ ADDING ALREADY TAKEN COORDINATES STARTS ................ */
      // if (domeFromBac && domeFromBac.domes && domeFromBac.domes.length !== 0) {
      //   for (let i = 0; i < myDome.length; i++) {
      //     for (let j = 0; j < domeFromBac.domes.length; j++) {
      //       if (
      //         myDome[i].location.x === domeFromBac.domes[j].location.x &&
      //         myDome[i].location.y === domeFromBac.domes[j].location.y
      //       ) {
      //         myDome[i] = domeFromBac.domes[j];
      //       }
      //     }
      //   }
      // }
      /** ................ ADDING ALREADY TAKEN COORDINATES ENDS ................ */

      /**
       #########################################################
                            NEW CODE STARTS
       #########################################################
       */


      // let updated_dome = [...myDome];
      // let dleted_index = [];
      if (domeFromBac && domeFromBac.domes && domeFromBac.domes.length !== 0) {
        /**................................ */

        //45.814462289898394
        //15.977834957195777
        // let temp_x = new LatLon(45.814462289898394, 15.977834957195777);
        // let temp_y = new LatLon(45.814462289898394, 15.977885919166985);

        // console.log('....ppppp....', temp_y.distanceTo(temp_x));
        // console.log('....ppppp22222....', temp_x.distanceTo(temp_y));

        /**................................ */

        // console.log(domeFromBac.domes);
        for (let i = 0; i < myDome.length; i++) {
          for (let j = 0; j < domeFromBac.domes.length; j++) {
            let p: any = new LatLon(
              domeFromBac.domes[j].location.y,
              domeFromBac.domes[j].location.x
            );

            // if (myDome[i].addr_name === '000aaaf046') {
            //   console.log(
            //     'here................',
            //     p.distanceTo(myDome[i].latlngP)
            //   );
            // }
            if (p.distanceTo(myDome[i].latlngP) < 0.02) {
              // console.log(p.distanceTo(myDome[i].latlngP));

              // myDome.splice(i, 1);
              // updated_dome.splice(i, 1);
              myDome[i].needToDelete = true;
              // dleted_index.push(i);
            }
            // if (
            //   myDome[i].location.x === domeFromBac.domes[j].location.x &&
            //   myDome[i].location.y === domeFromBac.domes[j].location.y
            // ) {
            //   myDome[i] = domeFromBac.domes[j];
            // }
          }
        }
      }

      // console.log('dleted_index', dleted_index);
      // console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb', updated_dome);
      // console.log(
      //   'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb    ............... myDome',
      //   myDome
      // );

      if (domeFromBac && domeFromBac.domes && domeFromBac.domes.length !== 0) {
        myDome = myDome.filter((item) => !item.needToDelete);
        // console.log('whattttttttttttttttttttttt', myDome);
        myDome = [...myDome, ...domeFromBac.domes];
      }

      /**
       #########################################################
                            NEW CODE ENDS
       #########################################################
       */

      // console.log('............................domeFromBac', domeFromBac);

      profileData['dome_loader'] = false;
      profileData['points_loader'] = false;
      context.setContextData(profileData);
    }

    let markers = [];

    for (var i = 1; i < myDome.length; i++) {
      var lat = parseFloat(myDome[i].location.x);
      var lng = parseFloat(myDome[i].location.y);
      // var name = myDome[i].addr_name;
      var latlngset = new google.maps.LatLng(
        Number(myDome[i].location.y),
        Number(myDome[i].location.x)
      );
      var url = ' https://app.loamoa-dev-merge.cladev.com/uploads/brown.png';
      if (myDome[i].paid == 1) {
        url = ' https://app.loamoa-dev-merge.cladev.com/uploads/red.png';
      }
      var markerIcon = {
        url: url,
        labelOrigin: new google.maps.Point(10, -1),
      };
      var marker = new google.maps.Marker({
        position: latlngset,
        icon: markerIcon,
        map: mapInstance,
        // label: {
        //   text: myDome[i].addr_name,
        //   color: '#0275d8',
        //   className: 'marker-label',
        // },
        optimized: false,
      });
      markers.push(marker);
      if (myDome[i].paid == 1) {
        const infowindow = new google.maps.InfoWindow({
          content:
            "<p class='text-primary text-center'>It's been already taken.</p> <p class='text-primary pr-10'>Please select other purple spots.</p>",
          // pixelOffset: new google.maps.Size(100, 0),
          disableAutoPan: true,
        });
        marker.addListener(
          'mouseover',
          (function (dome, mapInstance, infowindow) {
            return function () {
              this.setLabel({
                text: dome.addr_name,
                color: '#d8020a',
                className: 'marker-label',
              });

              infowindow.open(mapInstance, this);
            };
          })(myDome[i], mapInstance, infowindow)
        );

        marker.addListener(
          'mouseout',
          (function (dome, mapInstance, infowindow) {
            return function () {
              this.setLabel(null);
              infowindow.close();
            };
          })(myDome[i], mapInstance, infowindow)
        );

        // marker.addListener(
        //   'click',
        //   (function (lat, lng, dome, context) {
        //     return function () {
        //       // console.log(dome.addr_name);
        //       let profileData = context.profile;
        //       profileData['enter_for_location'] = true;
        //       profileData['dome_lat'] = lat;
        //       profileData['dome_lng'] = lng;
        //       profileData['dome_id'] = dome.ID;
        //       profileData['dome_name'] = dome.addr_name;
        //       profileData['is_paid'] = true;
        //       context.setContextData(profileData);
        //     };
        //   })(lat, lng, myDome[i], context)
        // );
      }

      if (myDome[i].paid != 1) {
        marker.addListener(
          'mouseover',
          (function (dome) {
            return function (e) {
              // console.log(e);
              this.setLabel({
                text: dome.addr_name,
                color: '#0275d8',
                className: 'marker-label',
              });
              var markerIcon = {
                url: ' https://app.loamoa-dev-merge.cladev.com/uploads/location.gif',
                // scaledSize: new google.maps.Size(20, 20),
                labelOrigin: new google.maps.Point(10, -5),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(11, 22),
              };
              this.setIcon(markerIcon);
            };
          })(myDome[i])
        );
        var mouseEvent = marker.addListener('mouseout', function (e) {
          let clicked = profile.profile['clicked'] || false;
          if (clicked == this) {
            return false;
          }
          this.setLabel(null);
          var markerIcon = {
            url: ' https://app.loamoa-dev-merge.cladev.com/uploads/brown.png',
            labelOrigin: new google.maps.Point(10, -1),
          };
          this.setIcon(markerIcon);
        });
        marker.addListener(
          'click',
          (function (lat, lng, dome, context) {
            return function () {
              if (profile.profile['clicked'] != this) {
                if (profile.profile['clicked']) {
                  profile.profile['clicked'].setLabel(null);
                  var Icon = {
                    url: ' https://app.loamoa-dev-merge.cladev.com/uploads/brown.png',
                    labelOrigin: new google.maps.Point(10, -1),
                  };
                  profile.profile['clicked'].setIcon(Icon);
                }
                //  profile.profile['clicked'].mouseout();
                profile.profile['clicked'] = this;
              }
              this.setLabel({
                text: dome.addr_name,
                color: '#0275d8',
                className: 'marker-label',
              });
              var markerIcon = {
                url: ' https://app.loamoa-dev-merge.cladev.com/uploads/location.gif',
                // scaledSize: new google.maps.Size(20, 20),
                labelOrigin: new google.maps.Point(10, -5),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(11, 22),
              };
              this.setIcon(markerIcon);
              // console.log(dome.addr_name);
              let profileData = context.profile;
              profileData['enter_for_location'] = true;
              profileData['dome_lat'] = lat;
              profileData['dome_lng'] = lng;
              profileData['dome_id'] = dome.ID;
              profileData['dome_name'] = dome.addr_name;
              profileData['is_paid'] = false;
              profileData['is_dome_registered_successfully'] = false;
              profileData['dome_point_unavailable'] = false;
              profileData['dome_error'] = false;
              profileData['payedFromCart'] = false;
              context.setContextData(profileData);
            };
          })(lat, lng, myDome[i], context)
        );
      }
    }
    profileData['markers'] = markers;
    context.setContextData(profileData);
  };

  // console.log(localStorage.getItem('permissions'),"getitem")

  const { language, role, permissions } = props;

  var permissionsArray;
  permissionsArray = permissions?.toString().split(",");



  return (

    <Row style={{ borderRadius: '0 0 20px 20px', height: 'inherit' }}>
      <Col>
        <Row className="">
          {/* sticky-buttons */}
          <Row type="flex" justify="center">
            <Button
              onClick={handleCreateNewTreasure}
              className="buttons yellow-gradient createNewDomeBtn"
            >
              {t("Create")}
            </Button>
            <div className="infoTip">
              <span className="infoTip__icon" data-for="soclose" data-tip="8">
                &nbsp;
              </span>
              <ReactTooltip
                id="soclose"
                getContent={(dataTip) => (
                  <div>
                    <p>
                      {t("Take")}
                    </p>
                  </div>
                )}
                effect="solid"
                delayHide={500}
                delayShow={500}
                delayUpdate={500}
                place={'right'}
                border={true}
                type={'light'}
              />
            </div>
          </Row>
          <Row type="flex" justify="space-around" className="menu-selector align-buttons">
            {/* Create Treasure */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="treasures"
                  className={`buttons-menu ${current === 'treasures' ? 'button-treasures-selected' : 'button-treasures'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'treasures' ? 'bold' : ''
                    }`}
                >
                  {t('treasures')}
                </Typography.Text>
              </Col>
            )}

            {/* Dome Add */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="Dome Add"
                  className={`buttons-menu ${current === 'Dome Add' ? 'button-dome-add-selected selected' : 'button-dome-add'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'Dome Add' ? 'bold' : ''
                    }`}
                >
                  {t('Dome Add')}
                </Typography.Text>
              </Col>
            )}

            {/* Dome Build */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="Dome"
                  className={`buttons-menu ${current === 'Dome' ? 'button-dome-selected selected' : 'button-dome'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'Dome' ? 'bold' : ''
                    }`}
                >
                  {t('Dome Build')}
                </Typography.Text>
              </Col>
            )}

            {/* Account */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="Account"
                  className={`buttons-menu ${current === 'Account' ? 'button-account-selected selected' : 'button-account'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'Account' ? 'bold' : ''
                    }`}
                >
                  {t("Account")}
                </Typography.Text>
              </Col>
            )}

            {/* Help */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="Help"
                  className={`buttons-menu ${current === 'Help' ? 'button-help-selected selected' : 'button-help'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'Help' ? 'bold' : ''
                    }`}
                >
                  {t('Help')}
                </Typography.Text>
              </Col>
            )}

            {/* Lbox */}
            {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={lboxButton}
                  value="Lbox"
                  className={`buttons-menu ${current === 'Lbox' ? 'button-lbox-selected selected' : 'button-lbox'
                    }`}
                ></Button>
                <Typography.Text
                  className={`buttons-menu-text ${current === 'Lbox' ? 'bold' : ''
                    }`}
                >
                  {t('Lbox')}
                </Typography.Text>
              </Col>
            )}

            {/* Balance */}
            {/* {props.role < roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="balance"
                  className={
                    current === 'balance'
                      ? 'buttons-menu button-balance-selected'
                      : 'buttons-menu button-balance'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'balance' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.PrizeDataButton9[language]}
                </Typography.Text>
              </Col>
            )} */}
            {/* </Row>
          <Row */}
            {/* type="flex"
            justify="space-around"
            className="menu-selector bottom"
          > */}
            {/* Characters */}
            {/* {(props.role >= roleAdmin || isDev) && (false) && ( */}

            {/* Treasure */}
            {props.role >= roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="treasures"
                  className={
                    current === 'treasures'
                      ? 'buttons-menu button-treasures-selected'
                      : 'buttons-menu button-treasures'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'treasures'
                      ? 'buttons-menu-text bold'
                      : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton3[language]}
                </Typography.Text>
              </Col>
            )}

            {permissionsArray?.includes("2") && props.role >= roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="characters"
                  className={
                    current === 'characters'
                      ? 'buttons-menu button-characters-selected'
                      : 'buttons-menu button-characters'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'characters'
                      ? 'buttons-menu-text bold'
                      : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton11[language]}
                </Typography.Text>
              </Col>
            )}

            {/* Stats */}
            {permissionsArray?.includes("3") && props.role >= roleAdmin && (
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="statistics"
                  className={
                    current === 'stats'
                      ? 'buttons-menu button-stats-selected'
                      : 'buttons-menu button-stats'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'statistics'
                      ? 'buttons-menu-text bold'
                      : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton4[language]}
                </Typography.Text>
              </Col>
            )}

            {/* Candy C. */}
            {permissionsArray?.includes("4") && props.role >= roleAdmin && (
              <Col className="buttons-col">
                <Button
                  value="candyEvent"
                  onClick={handleClick}
                  className={
                    current === 'candyEvent'
                      ? 'buttons-menu button-candyEvent-selected'
                      : 'buttons-menu button-candyEvent'
                  }
                ></Button>
                <Typography.Text
                  className={
                    current === 'candyEvent'
                      ? 'buttons-menu-text bold'
                      : 'buttons-menu-text'
                  }
                >
                  {menu.PrizeDataButton5[language]}
                </Typography.Text>
              </Col>
            )}

            {props.role >= roleAdmin ? (
              <>

                {/* Idol */}
                {permissionsArray?.includes("5") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="idol"
                      className={
                        current === 'idol'
                          ? 'buttons-menu button-contest-selected'
                          : 'buttons-menu button-contest'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'idol'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton6[language]}
                    </Typography.Text>
                  </Col>
                )}
                {/* Kit */}
                {permissionsArray?.includes("6") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="kit"
                      className={
                        current === 'kit'
                          ? 'buttons-menu button-kit-selected'
                          : 'buttons-menu button-kit'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'kit'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton7[language]}
                    </Typography.Text>
                  </Col>
                )}

                {/* Requests */}
                {permissionsArray?.includes("7") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="requests"
                      className={
                        current === 'requests'
                          ? 'buttons-menu button-requests-selected'
                          : 'buttons-menu button-requests'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'requests'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton8[language]}
                    </Typography.Text>
                  </Col>
                )}

                {/* Notifs */}
                {permissionsArray?.includes("8") && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="notifications"
                      className={
                        current === 'notifications'
                          ? 'buttons-menu button-notifications-selected'
                          : 'buttons-menu button-notifications'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'notifications'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton10[language]}
                    </Typography.Text>
                  </Col>
                )}

                {/* users */}
                {permissionsArray?.includes("9") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="users"
                      className={
                        current === 'users'
                          ? 'buttons-menu button-users-selected'
                          : 'buttons-menu button-users'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'users'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton18[language]}
                    </Typography.Text>
                  </Col>
                )}
                {/* Dome Add */}
                {permissionsArray?.includes("10") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="Dome Add"
                      className={
                        current === 'Dome Add'
                          ? 'buttons-menu button-dome-add-selected selected'
                          : 'buttons-menu button-dome-add '
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'Dome Add'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton12[language]}
                    </Typography.Text>
                  </Col>
                )}

                {/* Dome */}
                {permissionsArray?.includes("11") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="Dome"
                      className={
                        current === 'Dome'
                          ? 'buttons-menu button-dome-selected selected'
                          : 'buttons-menu button-dome'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'Dome'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton13[language]}
                    </Typography.Text>
                  </Col>
                )}

                {/* Account */}
                {permissionsArray?.includes("12") && props.role >= roleAdmin && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="Account"
                      className={
                        current === 'Account'
                          ? 'buttons-menu button-account-selected selected'
                          : 'buttons-menu button-account'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'Account'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton14[language]}
                    </Typography.Text>
                  </Col>
                )}


                {/* Dome Admin */}
                {permissionsArray?.includes("13") && (
                  <Col className="buttons-col">
                    <Button
                      onClick={handleClick}
                      value="dome-admin"
                      className={
                        current === 'dome-admin'
                          ? 'buttons-menu button-dome-admin-selected selected'
                          : 'buttons-menu button-dome-admin'
                      }
                    ></Button>
                    <Typography.Text
                      className={
                        current === 'dome-admin'
                          ? 'buttons-menu-text bold'
                          : 'buttons-menu-text'
                      }
                    >
                      {menu.PrizeDataButton16[language]}
                    </Typography.Text>
                  </Col>
                )}
                {/* Help Admin */}
                {permissionsArray?.includes("16") && (
                  <>
                    <Col className="buttons-col">
                      <Button
                        onClick={handleClick}
                        value="help-admin"
                        className={
                          current === 'help-admin'
                            ? 'buttons-menu button-help-selected selected'
                            : 'buttons-menu button-help'
                        }
                      ></Button>
                      <Typography.Text
                        className={
                          current === 'help-admin'
                            ? 'buttons-menu-text bold'
                            : 'buttons-menu-text'
                        }
                      >
                        {menu.PrizeDataButton17[language]}
                      </Typography.Text>
                    </Col>
                    <Col className="buttons-col">
                      <Button
                        // onClick={handleClick}
                        value="Help"
                        className={`buttons-menu ${current === 'Lbox' ? 'button-lbox-selected selected' : 'button-lbox'
                          }`}
                      ></Button>
                      <Typography.Text
                        className={`buttons-menu-text ${current === 'Lbox' ? 'bold' : ''
                          }`}
                      >
                        {t('Lbox')}
                      </Typography.Text>
                    </Col>
                    <Col className="buttons-col">
                      <Button
                         onClick={lboxButtonAdmin}
                        value="lbox-admin"
                        className={`buttons-menu ${current === 'lbox-admin' ? 'button-lbox-admin-selected selected' : 'button-lbox-admin'
                          }`}
                      ></Button>
                      <Typography.Text
                        className={`buttons-menu-text ${current === 'lbox-admin' ? 'bold' : ''
                          }`}
                      >
                        {t('Lbox Admin')}
                      </Typography.Text>
                    </Col>
                  </>
                )}

              </>
            ) : null}

            {/* Users */}
            {/* {props.role >= roleSuperadmin && (
            <Row type="flex" justify="space-around" className="menu-selector bottom">
              <Col className="buttons-col">
                <Button
                  onClick={handleClick}
                  value="users"
                  className={
                    current === 'users'
                      ? 'buttons-menu button-users-selected'
                      : 'buttons-menu button-users'
                  }
                ></Button>
                <Typography.Text
                  className={current === 'users' ? 'buttons-menu-text bold' : 'buttons-menu-text'}
                >
                  {menu.users[language]}
                </Typography.Text>
              </Col>
              <Col className="buttons-col"></Col>
              <Col className="buttons-col"></Col>
              <Col className="buttons-col"></Col>
            </Row>
          )} */}
          </Row>
        </Row>
        <Row>
          <Col>{handleSelectedMenuItem()}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButtons);
