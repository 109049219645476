import React, { useState } from 'react';
import { Row, Col, Button, Typography } from 'antd';
import {
  approved,
  declined,
  pending,
  roleAdmin,
  roleMaster,
  candy,
  sticker,
  draft,
  point,
  LDiscount,
} from '../../../../lib/const';
import { baseURL } from '../../../../lib/config';
import * as treasureInfo from '../../../../lib/strings/treasureInfo';
import TreasureLocation from '../treasure/TreasureLocation';
import * as appConstants from '../../../../lib/strings/appConstants';
import ReactPlayer from 'react-player';
import pointImg from '../../../../assets/ar_star_large.svg';
import stickerImg from '../../../../assets/sticker_item_on_map.svg';
import candyImg from '../../../../assets/icon_candy.svg';
import iconLDiscount from '../../../../assets/iconLDiscount.svg';

import PropTypes from 'prop-types';

function TreasureDetails(props) {
  const { treasureDetails, language } = props;

  const [showLocations, setShowLocations] = useState(false);

  let exposureDuration = (treasureDetails.endDate - treasureDetails.startDate) / 1000 / 60 / 60;
  let duration;
  let durationUnit;
  if (exposureDuration <= 24) {
    duration = exposureDuration.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption3[language]
        : appConstants.TreasureReviewDurationHours[language];
  } else if (exposureDuration > 24 && exposureDuration <= 24 * 7) {
    const hoursToDays = exposureDuration / 24;
    duration = hoursToDays.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption2[language]
        : appConstants.TreasureReviewDurationDays[language];
  } else if (exposureDuration > 24 * 7) {
    const hoursToWeeks = exposureDuration / (24 * 7);
    duration = hoursToWeeks.toFixed();
    durationUnit =
      duration === '1'
        ? appConstants.TreasureCreationDurationOption1[language]
        : appConstants.TreasureReviewDurationWeeks[language];
  }

  let statusText;
  switch (treasureDetails.status) {
    case draft:
      statusText = treasureInfo.draftsText[language];
      break;
    case pending:
      statusText = treasureInfo.pendingText[language];
      break;
    case approved:
      statusText = treasureInfo.approvedText[language];
      break;
    case declined:
      statusText = treasureInfo.declinedText[language];
      break;
    default:
      break;
  }

  const pickImage = () => {
    switch (treasureDetails.treasureType) {
      case sticker:
        return (
          <img
            src={
              treasureDetails.unitImage
                ? `${baseURL}${treasureDetails.unitImage.pic.path}`
                : stickerImg
            }
          />
        );
      case point:
        if (treasureDetails.mediaPath?.pic?.path) {
          return <img src={`${baseURL}${treasureDetails.mediaPath.pic.path}`} />;
        } else if (treasureDetails.mediaPath?.video?.path) {
          return <ReactPlayer url={`${baseURL}${treasureDetails.mediaPath.video.path}`} controls />;
        } else {
          return <img src={pointImg} />;
        }
      case candy:
        return <img src={candyImg} />;
      case LDiscount:
        return <img src={iconLDiscount} />;

      default:
        console.log('pickImage unknown treasure type');
        break;
    }
  };

  return (
    <div style={{ width: 350, padding: '1em' }}>
      <Row>
        <Row type="flex" justify="center" className="treasure-info">
          <Col style={{ width: '100%' }}>
            <Row className="details-top-row" type="flex" justify="center">
              <Typography.Text className="fredoka">
                {treasureDetails.status > pending ? treasureDetails.treasureName : 'Review'}
              </Typography.Text>
            </Row>

            {treasureDetails.status < approved ? (
              <Row type="flex" justify="center">
                <Typography.Text className="fredoka">
                  {treasureDetails.treasureName}
                </Typography.Text>
              </Row>
            ) : null}

            {/* {this.props.statistics && this.props.role >= roleAdmin ?
                            <Row type='flex' justify='center' align='middle' className='created-by'>
                                <img src={treasureDetails.ownerInfo.ownerData.avatar ?
                                    `${baseURL}/uploads/${treasureDetails.ownerInfo.ownerData.avatar.picture.nameOnServer}`
                                    : imgPlaceholder} />
                                <Typography.Text className='bold'>{treasureDetails.ownerInfo.ownerData.username}</Typography.Text>
                            </Row>
                            : null} */}

            <Row type="flex" justify="center" className="selected-treasure-img">
              {pickImage()}
            </Row>

            {/* Treasure type */}
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{treasureInfo.type[language]}</Typography.Text>
              <Typography.Text>
                {(treasureDetails.treasureType === sticker &&
                  treasureInfo.treasureTypeSticker[language]) ||
                  (treasureDetails.treasureType === point &&
                    treasureInfo.treasureTypePoint[language]) ||
                  (treasureDetails.treasureType === candy &&
                    treasureInfo.treasureTypeCandy[language]) ||
                  (treasureDetails.treasureType === LDiscount &&
                    treasureInfo.treasureTypeLDiscount[language])}
              </Typography.Text>
            </Row>

            {/* LDiscount value: */}
            {/* {treasureDetails.treasureType === LDiscount &&
                            <Row type='flex' justify='space-between'>
                                <Typography.Text className='bold'>{treasureInfo.treasureLdiscountValue[language]}</Typography.Text>
                                <Typography.Text>{this.props.treasureDetails.discount_value}</Typography.Text>
                            </Row>
                        } */}

            {treasureDetails.treasureType === sticker ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">
                  {treasureInfo.character[language]}
                </Typography.Text>
                <Typography.Text>{treasureDetails.characterName}</Typography.Text>
              </Row>
            ) : null}

            {treasureDetails.treasureType !== candy ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">
                  {treasureInfo.duration[language]}
                </Typography.Text>
                <Typography.Text>
                  {duration} {durationUnit}
                </Typography.Text>
              </Row>
            ) : null}

            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{treasureInfo.noOfUnits[language]}</Typography.Text>
              <Typography.Text>{treasureDetails.totalNumberOfUnits}</Typography.Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">
                {treasureInfo.noOfLocations[language]}
              </Typography.Text>
              <Typography.Text>{treasureDetails.locations.length}</Typography.Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Typography.Text className="bold">{treasureInfo.status[language]}</Typography.Text>
              <Typography.Text>{statusText}</Typography.Text>
            </Row>
            {treasureDetails.status > pending ? (
              <Row type="flex" justify="space-between">
                <Typography.Text className="bold">{treasureInfo.clients[language]}</Typography.Text>
                <Typography.Text>{treasureDetails.clients}</Typography.Text>
              </Row>
            ) : null}
          </Col>

          {!showLocations ? (
            <Row type="flex" justify="center" className="details-margin">
              <Button
                onClick={() => setShowLocations(!showLocations)}
                className="transparent-btn-black"
              >
                {treasureInfo.button1[language]}
              </Button>
            </Row>
          ) : (
            <Row type="flex" justify="center" className="details-margin">
              <Button
                onClick={() => setShowLocations(!showLocations)}
                className="transparent-btn-black"
              >
                {treasureInfo.button2[language]}
              </Button>
            </Row>
          )}

          {showLocations ? (
            <Row type="flex" justify="center">
              {treasureDetails.locations.map((location: any, index: number) => {
                return (
                  <TreasureLocation
                    key={index}
                    ID={location.ID}
                    location_number={index}
                    radius={location.radius}
                    lat={location.latitude}
                    lng={location.longitude}
                    messageLocations={treasureDetails.messageLocations}
                    isOpen={showLocations}
                  />
                );
              })}
            </Row>
          ) : null}

          {treasureDetails.status === pending && this.props.role >= roleAdmin ? (
            <Row type="flex" justify="space-between" className="details-margin">
              <Button
                className="transparent-btn-black"
                onClick={(e) => this.handleButtons(e)}
                value={declined}
              >
                {treasureInfo.buttonDecline[language]}
              </Button>
              <Button
                className="buttons yellow-gradient"
                onClick={(e) => this.handleButtons(e)}
                value={approved}
              >
                {treasureInfo.buttonApprove[language]}
              </Button>
            </Row>
          ) : null}
        </Row>
      </Row>
    </div>
  );
}

TreasureDetails.propTypes = {
  treasureDetails: PropTypes.object,
  language: PropTypes.string,
};

TreasureDetails.defaultProps = {
  treasureDetails: {},
  language: 'EN',
};

export default TreasureDetails;
