import React, { Component, RefObject } from 'react';
import {
  Row,
  Typography,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  notification,
  Modal,
} from 'antd';
import { contestConstants } from '../../../../constants/contestConstants';
import { connect } from 'react-redux';
import calendar from '../../../../assets/icon_date.svg';
import {
  createContest,
  updateContest,
  deleteContest,
  deleteFile,
  mediaUpload,
} from '../../../../services/contestService';
import { languages } from '../../../../lib/interfaces/language';
import { FormComponentProps } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import * as treasureCreation from '../../../../lib/strings/treasureCreation';
import {
  typeJPEG,
  typePNG,
  typeGIF,
  typeWEBP,
  maximumAllowedMediaSize,
  baseURL,
  typeMP4,
  typePDF,
  typeMP3,
  contestAPI,
  isDev,
} from '../../../../lib/config';
import UploadIcon from '../../../../assets/treasure_upload_image_icon.svg';
import first from '../../../../assets/icon_1st.svg';
import second from '../../../../assets/icon_2nd.svg';
import third from '../../../../assets/icon_3rd.svg';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { menuConstants } from '../../../../constants/menuConstants';
import { treasureConstants } from '../../../../constants/treasureConstants';
import * as treasureService from '../../../../services/treasureService';
import * as contestString from '../../../../lib/strings/contest';
import { Action } from 'redux';
import LoadingModal from '../../../../components/LoadingModal';
import ContestFormDates from '../../../../components/contest/ContestFormDates';

import { fillContestCharacter, fillContestCustom } from '../../../../qucikFill/contestFill';

const { Text } = Typography;

interface Props extends FormComponentProps {
  contest: any;
  language: languages;
  name: string;
  description: string;
  characterID: number;
  startDate: Moment;
  endDate: Moment;
  firstPlacePrize: string;
  secondPlacePrize: string;
  thirdPlacePrize: string;
  registrationStartDate: Moment;
  registrationEndDate: Moment;
  uploadContestantsVideoEarliestDate: Moment;
  uploadContestantsVideoLatestDate: Moment;
  qualificationsStartDate: Moment;
  qualificationsEndDate: Moment;
  finalistsAnnouncementDate: Moment;
  finalsStartDate: Moment;
  finalsEndDate: Moment;
  isEditing: boolean;
  isCreating: boolean;
  imgUrl: string;
  characters?: Array<any>;
  guideSong1: string;
  guideSong2: string;
  instrumental: string;
  lyricsPDF: string;
  instrumentalWithVocals: string;
  storeCharacters: (characters: any) => Action;
  handleInput: (param: string, value: string | number) => Action;
  handleClose: () => Action;
  storeContestImage: (imgFile: File, imgUrl: any) => Action;
  fetchAgain: (needsFetch: boolean) => Action;
  storeContestFiles: (file: File, typeOfFile: string) => Action;
  purgeContestData: () => Action;
  ID: number;
  loading: boolean;
  newToken: string;
  guidelineIDs: Array<any>;
  guidelineVideos: Array<any>;
  storeGuidelineSongID: (guidelineIDs: number) => Action;
  filePath: (fileName: string, path: string) => Action;
  errors: any;
  picturePath: string;
}

const mapState = (state: {
  contest: any;
  authentication: { language: string; user: any };
  characters: Array<any>;
}) => {
  const { contest, authentication } = state;
  const { user } = authentication;
  const { newToken } = user;
  const {
    name,
    description,
    characterID,
    startDate,
    endDate,
    firstPlacePrize,
    secondPlacePrize,
    thirdPlacePrize,
    registrationStartDate,
    registrationEndDate,
    uploadContestantsVideoEarliestDate,
    uploadContestantsVideoLatestDate,
    qualificationsStartDate,
    qualificationsEndDate,
    finalistsAnnouncementDate,
    finalsStartDate,
    finalsEndDate,
    isEditing,
    isCreating,
    imgUrl,
    guideSong1,
    guideSong2,
    lyricsPDF,
    ID,
    instrumental,
    instrumentalWithVocals,
    guidelineIDs,
    picturePath,
  } = contest;
  return {
    contest: contest,
    language: state.authentication.language,
    name,
    description,
    characterID,
    startDate,
    endDate,
    firstPlacePrize,
    secondPlacePrize,
    thirdPlacePrize,
    registrationStartDate,
    registrationEndDate,
    uploadContestantsVideoEarliestDate,
    uploadContestantsVideoLatestDate,
    qualificationsStartDate,
    qualificationsEndDate,
    finalistsAnnouncementDate,
    guideSong1,
    guideSong2,
    lyricsPDF,
    instrumental,
    finalsStartDate,
    finalsEndDate,
    isEditing,
    isCreating,
    imgUrl,
    instrumentalWithVocals,
    ID,
    newToken,
    picturePath,
    characters: state.characters,
    guidelineIDs,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleInput: (param: string, value: string) =>
      dispatch({ type: contestConstants.UPDATE_INPUT, param, value }),
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    storeContestImage: (imgFile: File, imgUrl: any) =>
      dispatch({ type: contestConstants.CONTEST_IMAGE, imgFile, imgUrl }),
    storeCharacters: (characters: any) =>
      dispatch({ type: treasureConstants.SAVE_CHARACTERS, characters }),
    fetchAgain: (needsFetch: boolean) =>
      dispatch({ type: contestConstants.FETCH_CONTESTS, needsFetch }),
    /* storeContestFiles: (file: File, typeOfFile: string) => dispatch({ type: contestConstants.STORE_FILES, file, typeOfFile }), */
    purgeContestData: () => dispatch({ type: contestConstants.PURGE_CONTEST_DATA }),
    storeGuidelineSongID: (guidelineIDs: number) =>
      dispatch({ type: contestConstants.STORE_GUIDELINE_SONG_ID, guidelineIDs }),
    filePath: (typeOfFile: string, path: string) =>
      dispatch({ type: contestConstants.STORE_FILE_PATH, typeOfFile, path }),
  };
};

class ContestForm extends Component<Props, any> {
  uploadImageRef: RefObject<any>;
  uploadFileRef: RefObject<any>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.uploadImageRef = React.createRef();
    this.uploadFileRef = React.createRef();

    this.state = {
      fileCategory: '',
      instrumental: '',
      instrumentalWithVocals: '',
      lyricsPDF: '',
      guideSong1: '',
      guideSong2: '',
      loading: false,
      percentage: 0,
      done: false,
      errors: {
        guideSong1: false,
        guideSong2: false,
        instrumental: false,
        instrumentalWithVocals: false,
        lyricsPDF: false,
      },
      newPictureIsUploaded: false,
      descriptionCharacterLeft: 500,
    };
  }

  componentDidMount = async () => {
    const characters =  await treasureService.getStickerCharacters();
    this.props.storeCharacters(characters);

    if (this.props.isEditing) {
      this.props.form.setFieldsValue({
        name: this.props.name,
        description: this.props.description,
        character: this.props.characterID,
        'start-date': moment(this.props.startDate),
        'end-date': moment(this.props.endDate),
        firstPlacePrize: this.props.firstPlacePrize,
        secondPlacePrize: this.props.secondPlacePrize,
        thirdPlacePrize: this.props.thirdPlacePrize,
        registrationStartDate: moment(this.props.registrationStartDate),
        registrationEndDate: moment(this.props.registrationEndDate),
        uploadContestantsVideoEarliestDate: moment(this.props.uploadContestantsVideoEarliestDate),
        uploadContestantsVideoLatestDate: moment(this.props.uploadContestantsVideoLatestDate),
        qualificationsStartDate: moment(this.props.qualificationsStartDate),
        qualificationsEndDate: moment(this.props.qualificationsEndDate),
        finalsStartDate: moment(this.props.finalsStartDate),
        finalsEndDate: moment(this.props.finalsEndDate),
        title: this.props.contest.title,
        picturePath: this.props.contest.picturePath,
      });
      this.setState({
        instrumental: this.props.instrumental
          ? this.props.instrumental
          : `${contestString.noFileSelected[this.props.language]}`,
        lyricsPDF: this.props.lyricsPDF
          ? this.props.lyricsPDF
          : `${contestString.noFileSelected[this.props.language]}`,
        guideSong1: this.props.guideSong1
          ? this.props.guideSong1
          : `${contestString.noFileSelected[this.props.language]}`,
        guideSong2: this.props.guideSong2
          ? this.props.guideSong2
          : `${contestString.noFileSelected[this.props.language]}`,
        instrumentalWithVocals: this.props.instrumentalWithVocals
          ? this.props.instrumentalWithVocals
          : `${contestString.noFileSelected[this.props.language]}`,
        descriptionCharacterLeft:
          this.state.descriptionCharacterLeft - this.props.description?.length,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isEditing !== this.props.isEditing) {
      if (!this.props.isEditing) {
        this.props.form.setFieldsValue({
          name: '',
          description: '',
          character: '',
          'start-date': null,
          'end-date': null,
          firstPlacePrize: '',
          secondPlacePrize: '',
          thirdPlacePrize: '',
          registrationStartDate: null,
          registrationEndDate: null,
          uploadContestantsVideoEarliestDate: null,
          uploadContestantsVideoLatestDate: null,
          qualificationsStartDate: null,
          qualificationsEndDate: null,
          finalistsAnnouncementDate: null,
          finalsStartDate: null,
          finalsEndDate: null,
        });
        this.setState({
          fileCategory: '',
          instrumental: '',
          instrumentalWithVocals: '',
          lyricsPDF: '',
          guideSong1: '',
          guideSong2: '',
          loading: false,
          percentage: 0,
          done: false,
          errors: {
            guideSong1: false,
            guideSong2: false,
            instrumental: false,
            instrumentalWithVocals: false,
            lyricsPDF: false,
          },
          newPictureIsUploaded: false,
        });
      }
    }
  }

  handleClose = async () => {
    if (!this.props.isEditing) {
       await deleteContest(this.props.ID);
    }
    this.props.handleClose();
    this.props.purgeContestData();
  };

  handleChange = (e: { target: { name: string; value: string } }) => {
    this.props.handleInput(e.target.name, e.target.value);
  };

  setStartDate = (e, offset = 0) =>
    moment(e)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0 + offset);
  setEndDate = (e, offset = 0) =>
    moment(e)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .set('millisecond', 999 - offset);

  handleDates = (e: any, id = 'string') => {
    let unixTimestamp = parseInt(moment(e).format('x'));
    if (
      'startDate' === id ||
      'registrationStartDate' === id ||
      'uploadContestantsVideoEarliestDate' === id ||
      'qualificationsStartDate' === id ||
      'finalsStartDate' === id
    ) {
      unixTimestamp = parseInt(
        moment(e).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format('x')
      );
    } else if (
      'endDate' === id ||
      'registrationEndDate' === id ||
      'uploadContestantsVideoLatestDate' === id ||
      'qualificationsEndDate' === id ||
      'finalsEndDate' === id
    ) {
      unixTimestamp = parseInt(
        moment(e)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .set('millisecond', 999)
          .format('x')
      );
    }
    this.props.handleInput(id, unixTimestamp);
  };

  handleSelect = (e: any) => {
    this.props.handleInput('characterID', e);
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.props.form.validateFields();
    let validationErrors;
    this.props.form.validateFields((errors, values) => {
      validationErrors = errors;
    });
    console.log('validationErrors:', validationErrors);

    if (!validationErrors) {
      if (this.props.isEditing || this.props.isCreating) {
        const res =  await updateContest(this.props.contest);
        this.props.handleClose();
        if (!res || !res.data) {
          notification.error({
            message: contestString.updateFailed[this.props.language],
          });
          throw 'update failed';
        } else {
          this.props.fetchAgain(true);
          this.props.purgeContestData();
        }
      } else {
        // app will never get here
        if (
          this.props.guideSong1 &&
          this.props.instrumental &&
          this.props.lyricsPDF &&
          this.props.instrumentalWithVocals
        ) {
          const res =  await createContest(this.props.contest);
          this.props.handleClose();
          if (!res || !res.data) {
            notification.error({
              message: contestString.creationFailed[this.props.language],
            });
            throw 'creation failed';
          } else {
            this.props.fetchAgain(true);
            this.props.purgeContestData();
          }
        } else {
          this.props.fetchAgain(true);
          this.props.purgeContestData();
        }
      }
    } else {
      throw 'error with validation';
    }
  };

  handleImageUpload = async (event: any) => {
    event.preventDefault();
    const supportedFileTypes = [typeJPEG, typePNG, typeGIF, typeWEBP];
    const image = event.target.files[0];
    const type = image.type;
    if (supportedFileTypes.indexOf(type) > -1 && image.size < maximumAllowedMediaSize) {
      const reader = new FileReader();
      reader.readAsDataURL(image);

      reader.onloadend = async () => {
        this.props.storeContestImage(image, reader.result);
        this.setState({ loading: true });
         await mediaUpload(this.props.ID, image, {
          onProgress: (perc: number) => {
            if (perc >= 100) {
              this.setState({ done: true });
            }
            this.setState({ percentage: perc });
          },
        });
        this.setState({ loading: false });
        this.props.form.setFieldsValue({ picturePath: reader.result });
      };

      this.setState({ newPictureIsUploaded: true });
    } else {
      notification.error({
        message: contestString.imageValidation[this.props.language],
        description: treasureCreation.TreasureCreationImageUploadDescription[this.props.language],
      });
    }
  };

  handleRefClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.persist();
    this.uploadImageRef.current.click();
  };

  handleFileUploadRef = (e: React.MouseEvent<HTMLElement>, fileCategory: string) => {
    e.persist();
    this.setState({
      fileCategory: fileCategory,
    });
    this.uploadFileRef.current.click();
  };

  handleFileUpload = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const type = file.type;
    const { fileCategory } = this.state;
    const props: any = this.props;
    if (
      (fileCategory === 'lyricsPDF' && type === typePDF) ||
      (fileCategory === 'instrumental' && type === typeMP3) ||
      (fileCategory === 'instrumentalWithVocals' && type === typeMP3) ||
      (fileCategory === 'guideSong1' && type === typeMP4) ||
      (fileCategory === 'guideSong2' && type === typeMP4)
    ) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        this.setState({ loading: true });

        const fileName = file.name;
        //this.props.storeContestFiles(fileName, fileCategory)
        if (fileCategory === 'instrumental') file.isInstrumental = true;
        if (fileCategory === 'instrumentalWithVocals') file.isInstrumentalWithVocals = true;

        //if guideline songs have previously been uploaded
        if (fileCategory.includes('guideSong') && this.props.guidelineIDs.length > 0) {
           await this.checkIfGuidelineSongExists(props[fileCategory]);
           await mediaUpload(
            this.props.ID,
            file,
            {
              onProgress: (perc: number) => {
                if (perc >= 100) {
                  this.setState({ done: true });
                }
                this.setState({ percentage: perc });
              },
            },
            (response) => {
              if (response) {
                this.props.storeGuidelineSongID(response.data[0].guidelineIDs);
                this.setState({ loading: false });
              }
              this.props.filePath(
                fileCategory,
                response.data[0].guidelineIDs.slice(-1)[0].videoPath
              );
            }
          );
        } else {
           await mediaUpload(
            this.props.ID,
            file,
            {
              onProgress: (perc: number) => {
                if (perc >= 100) {
                  this.setState({ done: true });
                }
                this.setState({ percentage: perc });
              },
            },
            (response) => {
              if (response) this.setState({ loading: false });
              if (fileCategory.includes('guideSong')) {
                this.props.storeGuidelineSongID(response.data[0].guidelineIDs);
                this.props.filePath(fileCategory, response.data[0].guidelineIDs[0].videoPath);
              } else {
                this.props.filePath(fileCategory, fileName);
              }
            }
          );
        }
      };
      reader.readAsDataURL(file);
      this.setState({
        [this.state.fileCategory]: file.name,
        done: false,
        percentage: 0,
      });
      event.target.value = null;
      switch (fileCategory) {
        case 'guideSong1':
          this.setState({
            errors: {
              guideSong1: false,
            },
          });
          return;
        case 'guideSong2':
          this.setState({
            errors: {
              guideSong2: false,
            },
          });
          return;
        case 'instrumental':
          this.setState({
            errors: {
              instrumental: false,
            },
          });
          return;
        case 'instrumentalWithVocals':
          this.setState({
            errors: {
              instrumentalWithVocals: false,
            },
          });
          return;
        case 'lyrics':
          this.setState({
            errors: {
              lyrics: false,
            },
          });
          return;
        default:
          return;
      }
    } else {
      switch (fileCategory) {
        case 'guideSong1':
          this.setState({
            errors: {
              guideSong1: true,
            },
          });
          return;
        case 'guideSong2':
          this.setState({
            errors: {
              guideSong2: true,
            },
          });
          return;
        case 'instrumental':
          this.setState({
            errors: {
              instrumental: true,
            },
          });
          return;
        case 'instrumentalWithVocals':
          this.setState({
            errors: {
              instrumentalWithVocals: true,
            },
          });
          return;
        case 'lyrics':
          this.setState({
            errors: {
              lyrics: true,
            },
          });
          return;
        default:
          return;
      }
      /*notification.error({
                message: contestString.incorrectFormatMessage[this.props.language],
                description: contestString.incorrectFormatDescription[this.props.language]
            })*/
    }
  };

  checkIfGuidelineSongExists = async (oldName?: string) => {
    if (oldName) {
      const index = this.props.guidelineIDs.findIndex((guideSong) =>
        oldName.includes(guideSong?.videoPath)
      );
      if (index !== -1) {
         await deleteFile(this.props.guidelineIDs[index].ID);
      }
    }
  };

  findMinOrMaxDate = (minOrMax: string, dates: any[]): Moment => {
    let filteredDates = dates.filter((element) => element).map((element) => moment(element));
    if (filteredDates[0]) {
      if (minOrMax === 'max') {
        return moment.max([...filteredDates]);
      } else {
        return moment.min([...filteredDates]);
      }
    }
  };

  fileLinkText = (
    name: 'guideSong1' | 'guideSong2' | 'instrumentalWithVocals' | 'instrumental' | 'lyricsPDF'
  ) => {
    const { language } = this.props;
    const regex = new RegExp(baseURL);
    const testUrl = regex.test(this.state[name]);

    if (testUrl) {
      switch (name) {
        case 'guideSong1':
          return `${contestString.file[language]} ${contestString.guideSongVideo[language]} 1`;
        case 'guideSong2':
          return `${contestString.file[language]} ${contestString.guideSongVideo[language]} 2`;
        case 'instrumentalWithVocals':
          return `${contestString.file[language]} ${contestString.music[language]}`;
        case 'instrumental':
          return `${contestString.file[language]} ${contestString.instrumental[language]}`;
        case 'lyricsPDF':
          return `${contestString.file[language]} ${contestString.lyrics[language]}`;
      }
    } else {
      return this.state[name];
    }
  };

  descriptionMaximumCharacters = 500;
  onDescriptionChangeHandler = (event) => {
    this.setState({
      descriptionCharacterLeft: this.descriptionMaximumCharacters - event.target.value.length,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { language, isEditing } = this.props;
    const showImageFromUrl =
      (this.props.imgUrl && !this.props.picturePath) ||
      (this.props.imgUrl && this.state.newPictureIsUploaded);
    const showImageFromFile = !this.state.newPictureIsUploaded && this.props.picturePath;
    const showUploadSticker = !this.props.imgUrl && !this.props.picturePath;
    let idolImage: string;
    if (this.props.isEditing) {
      idolImage =
        this.props.imgUrl === `/${this.props.contest?.characterDetails?.images?.small}`
          ? `/${this.props.contest.characterDetails?.images.large}`
          : this.props.imgUrl;
    }

    return (
      <Row className="contest-wrapper">
        <div className="scrollable">
          <Row className="contest-top-row" type="flex" justify="start" align="middle">
            <Button onClick={this.handleClose} className="details-arrow">
              <img src={arrow} alt="close" />
            </Button>
            {!this.props.isEditing ? (
              <Typography.Text className="contest-title bold">
                {contestString.creatingIdol[language]}
              </Typography.Text>
            ) : (
              <Typography.Text className="contest-title bold">
                {contestString.editingIdol[language]}
              </Typography.Text>
            )}
          </Row>

          <Form
            className="form-contest"
            onSubmit={(e) => e.preventDefault()}
            onReset={(e) => e.preventDefault()}
          >
            <Row type="flex" justify="center">
              <Row type="flex" justify="space-between" className="top-dates">
                <Col className="start-date-col">
                  <Row>
                    <Form.Item label={contestString.idolNameLabel[language]}>
                      {getFieldDecorator('name', {
                        rules: [
                          { required: true, message: contestString.idolNameValidation[language] },
                        ],
                      })(
                        <Input
                          name="name"
                          onBlur={this.handleChange}
                          className="contest-input-bottom-line"
                        />
                      )}
                    </Form.Item>
                  </Row>
                </Col>

                <Col span={11}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'spaceEvenly',
                      padding: 10,
                    }}
                  >
                    <Form.Item label={contestString.startDateLabel[language]}>
                      {getFieldDecorator('start-date', {
                        rules: [
                          { required: true, message: contestString.startDateValidation[language] },
                        ],
                      })(
                        <DatePicker
                          className="contest-start-end-date"
                          suffixIcon={<img src={calendar} />}
                          format={'DD/MM/YY'}
                          onChange={(e) => this.handleDates(this.setStartDate(e), 'startDate')}
                          disabledDate={(current) => {
                            const dateList = [
                              this.props.finalsEndDate,
                              this.props.finalsStartDate,
                              this.props.finalistsAnnouncementDate,
                              this.props.qualificationsEndDate,
                              this.props.qualificationsStartDate,
                              this.props.uploadContestantsVideoLatestDate,
                              this.props.uploadContestantsVideoEarliestDate,
                              this.props.registrationEndDate,
                              this.props.registrationStartDate,
                              this.props.endDate,
                            ];
                            const maxDate = this.findMinOrMaxDate('min', dateList);
                            return maxDate < current;
                          }}
                        />
                      )}
                    </Form.Item>

                    <div style={{ width: '40px' }} />

                    <Form.Item label={contestString.endDateLabel[language]}>
                      {getFieldDecorator('end-date', {
                        rules: [
                          { required: true, message: contestString.endDateValidation[language] },
                        ],
                      })(
                        <DatePicker
                          className="contest-start-end-date"
                          suffixIcon={<img src={calendar} />}
                          format={'DD/MM/YY'}
                          disabledDate={(current) => {
                            const dateList = [
                              this.props.finalsEndDate,
                              this.props.finalsStartDate,
                              this.props.finalistsAnnouncementDate,
                              this.props.qualificationsEndDate,
                              this.props.qualificationsStartDate,
                              this.props.uploadContestantsVideoLatestDate,
                              this.props.uploadContestantsVideoEarliestDate,
                              this.props.registrationEndDate,
                              this.props.registrationStartDate,
                              this.props.startDate,
                            ];
                            const minDate = this.findMinOrMaxDate('max', dateList);
                            return current < minDate;
                          }}
                          onChange={(e) => this.handleDates(e, 'endDate')}
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="space-between" style={{ width: '100%' }}>
                <Col span={11}>
                  <Form.Item
                    label={contestString.characterLabel[language]}
                    className="contest-title full-width"
                  >
                    {getFieldDecorator('character', {
                      rules: [
                        { required: false, message: contestString.characterValidation[language] },
                      ],
                    })(
                      <Select
                        className="select-treasure-type treasure-type-picker full-width"
                        onChange={this.handleSelect}
                      >
                        {/* empty cell */}
                        <Select.Option
                          className="sticker-character"
                          key={'&nbsp;EmptyCell'}
                          value={''}
                        >
                          &nbsp;
                        </Select.Option>
                        {this.props.characters.map(
                          (character: {
                            ID: React.ReactText;
                            image: any;
                            name: React.ReactNode;
                          }) => {
                            return (
                              <Select.Option
                                key={character.ID}
                                className="sticker-character"
                                value={character.ID}
                              >
                                <img src={`${baseURL}/${character.image}`} />
                                {character.name}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item
                    className="roboto-regular not-required-attachment"
                    style={{ width: '100%' }}
                    label={contestString.imageLabel[language]}
                  >
                    <Row type="flex" justify="center" style={{ width: '100%' }}>
                      <input
                        type="file"
                        accept={`${typePNG}, ${typeWEBP}, ${typeJPEG}, ${typeGIF}`}
                        onChange={this.handleImageUpload}
                        ref={this.uploadImageRef}
                        style={{ visibility: 'hidden', height: '0', width: '0' }}
                      />
                      {getFieldDecorator('picturePath', {
                        rules: [
                          {
                            required: !this.props.characterID,
                            message: contestString.uploadImage[language],
                          },
                        ],
                      })(
                        <div
                          className="upload"
                          onClick={this.handleRefClick}
                          style={{ width: '100%' }}
                        >
                          {showImageFromUrl && (
                            <img src={this.props.imgUrl} className="img-upload adjust" />
                          )}
                          {showImageFromFile && (
                            <img src={`${baseURL}/${idolImage}`} className="img-upload adjust" />
                          )}
                          {showUploadSticker && (
                            <img src={UploadIcon} className="upload-sticker-image" />
                          )}
                        </div>
                      )}
                    </Row>
                  </Form.Item>

                  {!this.props.characterID && (
                    <Form.Item label={contestString.titleLabel[language]}>
                      {getFieldDecorator('title', {
                        rules: [
                          { required: true, message: contestString.titleValidation[language] },
                        ],
                      })(
                        <Input
                          className="contest-input-bottom-line"
                          name="title"
                          onBlur={this.handleChange}
                          placeholder={contestString.titlePlaceholder[language]}
                        />
                      )}
                    </Form.Item>
                  )}

                  <Form.Item label={contestString.descriptionLabel[language]}>
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: !this.props.characterID,
                          message: contestString.descriptionValidation[language],
                        },
                      ],
                    })(
                      <Input.TextArea
                        name="description"
                        onBlur={this.handleChange}
                        placeholder={contestString.descriptionPlaceholder[language]}
                        onChange={this.onDescriptionChangeHandler}
                        maxLength={this.descriptionMaximumCharacters}
                      />
                    )}
                    <div
                      className="ldiscount-character-count"
                      style={{ position: 'absolute', bottom: -5, right: 10 }}
                    >
                      {this.state.descriptionCharacterLeft}
                    </div>
                  </Form.Item>
                </Col>

                <ContestFormDates
                  language={language}
                  getFieldDecorator={getFieldDecorator}
                  handleDates={this.handleDates}
                  setStartDate={this.setStartDate}
                  setEndDate={this.setEndDate}
                  findMinOrMaxDate={this.findMinOrMaxDate}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  qualificationsStartDate={this.props.qualificationsStartDate}
                  qualificationsEndDate={this.props.qualificationsEndDate}
                  finalistsAnnouncementDate={this.props.finalistsAnnouncementDate}
                  finalsEndDate={this.props.finalsEndDate}
                  finalsStartDate={this.props.finalsStartDate}
                  registrationStartDate={this.props.registrationStartDate}
                  registrationEndDate={this.props.registrationEndDate}
                  uploadContestantsVideoEarliestDate={this.props.uploadContestantsVideoEarliestDate}
                  uploadContestantsVideoLatestDate={this.props.uploadContestantsVideoLatestDate}
                />
              </Row>
            </Row>

            <Row type="flex" justify="start" className="full-width">
              <input
                /* accept={`${typeMP4}, ${typePDF}, ${typeMP3}`} */
                type="file"
                onChange={(e) => this.handleFileUpload(e)}
                ref={this.uploadFileRef}
                style={{ visibility: 'hidden', height: '0', width: '0' }}
              />
              <Row type="flex" className="full-width">
                <Typography.Text className="contest-title">
                  {contestString.attachmentsLabel[language]}
                </Typography.Text>
              </Row>

              <Row type="flex" className="full-width">
                <Typography.Text>{contestString.guideSongVideosLabel[language]}</Typography.Text>
              </Row>

              <Row type="flex" justify="space-between" className="full-width">
                <Form.Item
                  className="not-required-attachment"
                  label={contestString.guideSong1Label[language]}
                >
                  <Row type="flex" justify="space-between">
                    <Col>
                      {this.props.isCreating ? (
                        <Typography.Text className={this.state.guideSong1 ? 'bold' : 'bold-gray'}>
                          {!this.state.errors.guideSong1
                            ? this.state.guideSong1
                              ? this.state.guideSong1
                              : contestString.noFileSelected[language]
                            : contestString.uploadMP4[language]}
                        </Typography.Text>
                      ) : (
                        <Typography.Text className="bold">
                          <a target="_blank" href={`${this.state.guideSong1}`}>
                            {this.fileLinkText('guideSong1')}
                          </a>
                        </Typography.Text>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => this.handleFileUploadRef(e, 'guideSong1')}
                        className="buttons yellow-gradient"
                      >
                        {contestString.browseButton[language]}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  className="not-required-attachment"
                  label={contestString.guideSong2Label[language]}
                >
                  <Row type="flex" justify="space-between">
                    <Col>
                      {this.props.isCreating ? (
                        <Typography.Text className={this.state.guideSong2 ? 'bold' : 'bold-gray'}>
                          {!this.state.errors.guideSong2
                            ? this.state.guideSong2
                              ? this.state.guideSong2
                              : contestString.noFileSelected[language]
                            : contestString.uploadMP4[language]}
                        </Typography.Text>
                      ) : (
                        <Typography.Text className="bold">
                          <a target="_blank" href={`${this.state.guideSong2}`}>
                            {this.fileLinkText('guideSong2')}
                          </a>
                        </Typography.Text>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => this.handleFileUploadRef(e, 'guideSong2')}
                        className="buttons yellow-gradient"
                      >
                        {contestString.browseButton[language]}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Row>

              <Row type="flex" justify="space-between" className="full-width">
                <Row type="flex" className="full-width">
                  <Typography.Text>{contestString.otherFilesLabel[language]}</Typography.Text>
                </Row>

                <Form.Item
                  className="not-required-attachment"
                  label={contestString.music1Label[language]}
                >
                  <Row type="flex" justify="space-between">
                    <Col>
                      {this.props.isCreating ? (
                        <Typography.Text
                          className={this.props.instrumentalWithVocals ? 'bold' : 'bold-gray'}
                        >
                          {!this.state.errors.instrumentalWithVocals
                            ? this.state.instrumentalWithVocals
                              ? this.state.instrumentalWithVocals
                              : contestString.noFileSelected[language]
                            : contestString.uploadMP3[language]}
                        </Typography.Text>
                      ) : (
                        <Typography.Text className="bold">
                          <a target="_blank" href={`${this.state.instrumentalWithVocals}`}>
                            {this.fileLinkText('instrumentalWithVocals')}
                          </a>
                        </Typography.Text>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => this.handleFileUploadRef(e, 'instrumentalWithVocals')}
                        className="buttons yellow-gradient"
                      >
                        {contestString.browseButton[language]}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  className="not-required-attachment"
                  label={contestString.music2Label[language]}
                >
                  <Row type="flex" justify="space-between">
                    <Col>
                      {this.props.isCreating ? (
                        <Typography.Text className={this.props.instrumental ? 'bold' : 'bold-gray'}>
                          {!this.state.errors.instrumental
                            ? this.state.instrumental
                              ? this.state.instrumental
                              : contestString.noFileSelected[language]
                            : contestString.uploadMP3[language]}
                        </Typography.Text>
                      ) : (
                        <Typography.Text className="bold">
                          <a target="_blank" href={`${this.state.instrumental}`}>
                            {this.fileLinkText('instrumental')}
                          </a>
                        </Typography.Text>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => this.handleFileUploadRef(e, 'instrumental')}
                        className="buttons yellow-gradient"
                      >
                        {contestString.browseButton[language]}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  className="not-required-attachment"
                  label={contestString.lyricsLabel[language]}
                >
                  <Row type="flex" justify="space-between">
                    <Col>
                      {this.props.isCreating ? (
                        <Typography.Text className={this.props.lyricsPDF ? 'bold' : 'bold-gray'}>
                          {!this.state.errors.lyricsPDF
                            ? this.state.lyricsPDF
                              ? this.state.lyricsPDF
                              : contestString.noFileSelected[language]
                            : contestString.uploadPDF[language]}
                        </Typography.Text>
                      ) : (
                        <Typography.Text className="bold">
                          <a target="_blank" href={`${this.state.lyricsPDF}`}>
                            {this.fileLinkText('lyricsPDF')}
                          </a>
                        </Typography.Text>
                      )}
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => this.handleFileUploadRef(e, 'lyricsPDF')}
                        className="buttons yellow-gradient"
                      >
                        {contestString.browseButton[language]}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Row>
            </Row>

            <Row type="flex" justify="start" style={{ margin: '1em' }}>
              <Row style={{ height: 'fitContent' }}>
                <Typography.Text className="contest-title">
                  {contestString.prizesLabel[language]}
                </Typography.Text>
              </Row>
              <Row type="flex" justify="center" style={{ width: '100%' }}>
                <Form.Item
                  className="prizes-input"
                  colon={false}
                  label={
                    <Row>
                      <img src={first} />
                      <Text>{contestString.firstPlacePrize[language]}</Text>
                    </Row>
                  }
                >
                  {getFieldDecorator('firstPlacePrize', {
                    rules: [{ required: true, message: contestString.prizeValidation[language] }],
                  })(
                    <Input
                      name="firstPlacePrize"
                      onBlur={this.handleChange}
                      className="input-treasure"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="prizes-input"
                  label={
                    <Row>
                      <img src={second} />
                      <Text>{contestString.secondPlacePrize[language]}</Text>
                    </Row>
                  }
                >
                  {getFieldDecorator('secondPlacePrize', {
                    rules: [{ required: true, message: contestString.prizeValidation[language] }],
                  })(
                    <Input
                      name="secondPlacePrize"
                      onBlur={this.handleChange}
                      className="input-treasure"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  colon={false}
                  className="prizes-input"
                  label={
                    <Row>
                      <img src={third} />
                      <Text>{contestString.thirdPlacePrize[language]}</Text>
                    </Row>
                  }
                >
                  {getFieldDecorator('thirdPlacePrize', {
                    rules: [{ required: true, message: contestString.prizeValidation[language] }],
                  })(
                    <Input
                      name="thirdPlacePrize"
                      onBlur={this.handleChange}
                      className="input-treasure"
                    />
                  )}
                </Form.Item>
              </Row>
            </Row>
          </Form>
          <Row type="flex" justify="center" style={{ padding: '25px' }}>
            <Button className="transparent-btn-black" onClick={this.handleClose}>
              {contestString.cancelButton[language]}
            </Button>
            <Button className="buttons purple-gradient-right" onClick={this.handleSubmit}>
              {contestString.saveButton[language]}
            </Button>
          </Row>
          <Row className="loading-modal-container">
            <Modal
              bodyStyle={{ padding: '0', paddingTop: '1em', borderRadius: '10px' }}
              visible={this.state.loading && !this.state.done}
              footer={null}
              closable={false}
              centered={true}
            >
              <LoadingModal
                isLoading={this.state.loading}
                percentage={this.state.percentage}
                done={this.state.done}
              />
            </Modal>
          </Row>
        </div>

        {/* quickFill for testing */}
        {isDev && !isEditing && (
          <div className="quick-fill-container">
            <Button
              onClick={() => {
                fillContestCharacter(this.props.form.setFieldsValue, this.props.handleInput);
              }}
            >
              AUTOFILL w Character
            </Button>

            <Button
              onClick={() => {
                fillContestCustom(
                  this.props.form.setFieldsValue,
                  this.props.handleInput,
                  this.props.ID
                );
              }}
            >
              AUTOFILL w/o Character
            </Button>
          </div>
        )}
      </Row>
    );
  }
}

const wrappedContestCreationForm = Form.create({ name: 'contest' })(ContestForm);
export default connect(mapState, mapDispatch)(wrappedContestCreationForm);
