import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography,Spin } from 'antd';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import imgDocIcon from '../../../../../src/assets/imgDocIcon.svg';
import { world } from '../../../../lib/strings/treasureCreation';
import CreateCandyChallenge from '../../../dashboard/menu/candyEvent/CreateCandyChallenge'
import { getCandyEventList } from '../../../../../src/services/candyEventService'
import * as candyEventService from '../../../../services/candyEventService';
import { serverError } from '../../../../lib/strings/error';
import { useQuery } from 'react-query';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import { connect } from 'react-redux';
import * as menuActions from '../../../../actions/menuActions';
import PropTypes from 'prop-types';
import  CandyEventChallenge from './CandyEventChallenge';


const { Title, Text } = Typography;


function World_Cup(Props) {
  const{
    language,
    selectedCountryCode,
    openCreateCandyChallenge,
    challenge_type
    }=Props
    const [newCandy, setNewCandy] = useState(true)
    // const [create, setcreate] = useState(false)
    

    const Challenge = () => {
        setNewCandy(false)
        // setcreate(true)
        openCreateCandyChallenge()
    }

    useEffect(() => {
        candyEventChallenge
    }, []);

const candyEventChallenge = useQuery(
     ['CandyEventList', { country:"" }],

    candyEventService.getCandyEventList,
    {
      onError: (error) => {
        candyEventService.showErrorMessage(serverError[language], error);
      },
    }
    
  );

return (
    <>
        <Button className="buttons purple-gradient-right margin-contest menu-button-width" onClick={Challenge}>Create new Candy Challenge</Button>

        {newCandy && <Row style={{ width: '100%' }}>

        {candyEventChallenge.isLoading && (
          <Row type="flex" justify="center">
            <Spin size="large" />
          </Row>
        )}
        <Row style={{ width: '100%' }}>
          <CandyEventChallenge candyEventChallenge={candyEventChallenge.data} language={language} />
        </Row>
            
  
        </Row>
        }
        {/* {create &&
            <CreateCandyChallenge />
        } */}
    </>

)
} 

World_Cup.propTypes = {
    openCreateCandyChallenge: PropTypes.func,
    
  };
  
  const mapsStateToProps = (state) => ({
    language: state.authentication.language,
    role: state.userData.role,
    selectedCountryCode: state.candyEvent.selectedCountryCode,
  });
const mapDispatchToProps = (dispatch) => ({
    openCreateCandyChallenge: () => dispatch(menuActions.openCreateCandyChallenge()),
  });

export default connect(mapsStateToProps, mapDispatchToProps)(World_Cup)