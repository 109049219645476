import React from 'react';
import { connect } from 'react-redux';
import {
  Statistics,
  StatisticsForTreasure,
  StatisticsForPoints,
  StatisticsForCoins,
  StatisticsForPartners,
  StatisticsForMasters,
  StatisticsForCoupon,
  StatisticsForAssignment,
  StatisticsForVoucher
} from './menu/statistics';
import * as subMenuConstants from '../../lib/SubMenuConstants';
import StatisticsForDomeStats from './menu/statistics/StatisticsForDomeStats';
import Plums from './menu/superAdmin/Plums';
import StatisticsForUsers from './menu/statistics/StatisticsForUsers';
// import PlumCreated from './menu/superAdmin/PlumCreated';
// import Plumcreated from './menu/superAdmin/PlumCreated';
//  import PlumReceived from './menu/superAdmin/PlumReceived';


const SubMenuFullScreen = (props) => {
  const { subMenuFullScreen } = props;
  const selectOpenMenu = () => {
    switch (subMenuFullScreen) {
      case subMenuConstants.statisticsForUsers:
        return <StatisticsForUsers />;
      case subMenuConstants.statisticsForTreasure:
        return <StatisticsForTreasure />;
      case subMenuConstants.statisticsForPoints:
        return <StatisticsForPoints />;
      case subMenuConstants.statisticsForCoins:
        return <StatisticsForCoins />;
        case subMenuConstants.statisticsForCoupon:
          return <StatisticsForCoupon />;
        case subMenuConstants.statisticsForAssignment:
          return <StatisticsForAssignment />;         
      case subMenuConstants.statisticsForPartners:
        return <StatisticsForPartners />;
      case subMenuConstants.statisticsForMasters:
        return <StatisticsForMasters />;
      case subMenuConstants.statisticsForDomeStats:
        return <StatisticsForDomeStats />;
      case subMenuConstants.statisticsForPlums:
        return<Plums/>;
      case subMenuConstants.statisticsForVoucher:
        return <StatisticsForVoucher/>;
        // case subMenuConstants.statisticsForcreated:
        // return<PlumCreated/>;
      //  case subMenuConstants.statisticsForReceived:
      //     return <PlumReceived />;
   
       
      default:
        console.log('Unknown case');
        return <Statistics />;
    }
  };

  return <>{selectOpenMenu()}</>;
};

const mapStateToProps = (state) => ({
  subMenuFullScreen: state.subMenuFullScreen,
});

export default connect(mapStateToProps)(SubMenuFullScreen);
