import React from 'react';
import { Typography, Col, Row } from 'antd';
import * as menu from '../../../../lib/strings/menu';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';
import { useTranslation } from 'react-i18next'

interface Props {
  language: languages;
}

const mapState = (state: { authentication: any }) => {
  const { authentication } = state;
  const { language } = authentication;
 
  return { language };
};

const NoTreasure = (props: Props) => {
  const { t } = useTranslation();
  const { language } = props;
  return (
    <Row className="no-prizes">
      <Col>
        <Typography.Text className="roboto-regular center large-text">
          {t("There is no treasures yet")}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default connect(mapState)(NoTreasure);
