import React, { Component } from 'react';
import { Typography, Row, Col, Menu, Button } from 'antd';
import * as treasureService from '../../../services/treasureService';
import { baseURL } from '../../../lib/config';
import { connect } from 'react-redux';
import { treasureConstants } from '../../../constants/treasureConstants';
import Section from './Section';
import { languages } from '../../../lib/interfaces/language';
import * as treasureCreation from '../../../lib/strings/treasureCreation';

interface Props {
  characters: Array<any>;
  treasure: any;
  selectCharacter: (characterID: number) => void;
  close: (e: React.MouseEvent) => void;
  onImgUrlChange?: (url: string, stickerID: number, sectionID: number) => void;
  updateImgPath: (imgUrl: string, stickerID: number, sectionID: number, position: number) => void;
  language: languages;
  form: { setFieldsValue };
}

interface State {
  imgUrl: string;
  character: number;
  stickerID: number;
  sectionID: number;
  sectionPosition: number;
}

const mapState = (state: { characters: any; treasure: any; authentication: any }) => {
  const { characters, treasure, authentication } = state;
  const { language } = authentication;
  return { characters, treasure, language };
};

const mapDispatch = (dispatch: any) => {
  return {
    selectCharacter: (characterID: number) =>
      dispatch({ type: treasureConstants.SELECTED_CHARACTER, characterID }),
    updateImgPath: (imgUrl: string, stickerID: number, sectionID: number, position: number) =>
      dispatch({
        type: treasureConstants.CHOSEN_STICKER,
        imgUrl,
        stickerID,
        sectionID,
        position,
      }),
  };
};

class ChooseSticker extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      imgUrl: '',
      character: null,
      stickerID: null,
      sectionID: null,
      sectionPosition: null,
    };
  }

  onImgUrlChange = (url: string, id: number, sectionID: number, sectionPosition: number) => {
    this.setState({
      imgUrl: url,
      stickerID: id,
      sectionID: sectionID,
      sectionPosition: sectionPosition,
    });
  };

  handleSubmit = async (e: React.MouseEvent<Element, MouseEvent>) => {
    const { treasure, form } = this.props;
    if (treasure.characterID || this.state.stickerID) {
      const res = await treasureService.updateCharacter(
        treasure.ID,
        treasure.characterID,
        this.state.stickerID,
        treasure.treasureType,
        this.state.sectionID,
        this.state.sectionPosition
      );
      if (!res.error) {
        form.setFieldsValue({ stickerImage: this.state.imgUrl });
        this.props.updateImgPath(
          this.state.imgUrl,
          this.state.stickerID,
          this.state.sectionID,
          this.state.sectionPosition
        );
      }
      // error handeling is missing
      this.props.close(e);
    }
  };

  render() {
    const { language } = this.props;
    return (
      <Row>
        <Row type="flex" justify="center">
          <Typography.Title style={{ letterSpacing: '.04em' }} className="fredoka">
            {treasureCreation.stickersTitle[language]}
          </Typography.Title>
        </Row>
        <Row type="flex">
          <Col
            span={6}
            style={{
              overflowY: 'hidden',
              height: 'fitContent',
              maxHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Menu
              onClick={(e) => this.props.selectCharacter(Number(e.key))}
              className="choose-sticker-menu"
              mode="inline"
            >
              {this.props.characters
                ? this.props.characters.map((character) => {
                    return (
                      <Menu.Item style={{ marginBottom: '0' }} key={character.ID}>
                        <img src={`${baseURL}/${character.image}`} alt={character.name} />
                        <Typography.Text>{character.name}</Typography.Text>
                      </Menu.Item>
                    );
                  })
                : null}
            </Menu>
          </Col>
          <Col
            style={{
              overflowY: 'hidden',
              height: 'fitContent',
              maxHeight: '70vh',
              display: 'flex',
              flexDirection: 'column',
            }}
            span={18}
          >
            <div style={{ overflowY: 'scroll' }}>
              <Row className="character-stickers">
                {this.props.characters[this.props.treasure.characterID - 1] ?.sections.map(
                  (section: any) => {
                    return (
                      <Section
                        key={section.ID}
                        ID={section.ID}
                        name={section.name}
                        stickers={section.stickers}
                        onImgUrlChange={this.onImgUrlChange}
                        selectedStickerID={this.state.stickerID}
                      />
                    );
                  }
                )}
              </Row>
              <Row className="button-choose-sticker" type="flex" justify="center">
                <Button className="buttons transparent-btn-black" onClick={this.props.close}>
                  Close
                </Button>
                <Button className="buttons yellow-gradient" onClick={(e) => this.handleSubmit(e)}>
                  {treasureCreation.chooseStickerButton[language]}
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default connect(mapState, mapDispatch)(ChooseSticker);
