import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { Row, Col, Button, Typography, Select, DatePicker } from 'antd';
import { Table, Filter, Paginate } from '../../../../components';
import * as menuActions from '../../../../actions/menuActions';
import * as statisticsStrings from '../../../../lib/strings/statistics';
import PropTypes from 'prop-types';
import { lang } from 'moment';
import calendar from '../../../../../src/assets/icon_date.svg';
import * as SubMenuConstants from '../../../../lib/SubMenuConstants';
import { PlumCreated } from '../superAdmin/PlumCreated';
import PlumReceived from '../superAdmin/plumReceived';
import Plums from '../superAdmin/Plums';
import longaro from '../../../../assets/long-aro.jpg';
import moment from 'moment';
import { getCashing, UpdateCashingData, sendmail, updatecashingdata, } from '../../../../services/domeService';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function DomePlums(props) {

    const {
        language,
        closeSubmenuFullScreen,
        label,
        loading,
        statisticsType,
        dataSource,
        clickedUsername,
        kitList,
        onFiltersChange,
        onPageChange,
        totalItems,
        num,
        id,
        pageSize,
        currentPage,
        total,
        role,
        isPartner,
        currentFilters,
        ...otherProps
    } = props;

    const { width, height } = useWindowDimensions();


    const [plums, setPlums] = useState(true)
    const [received, setReceived] = useState(false)
    const [created, setCreated] = useState(false)
    const [myplumcashing, setMyplumcashing] = useState("active")
    const [myplumreceived, setMyplumreceived] = useState("")
    const [myplumcreated, setMyplumcreated] = useState("")
    const [receiveddata, setReceiveddata] = useState("")
    const [createddata, setCreateddata] = useState("")
    const [receivedplum, setReceivedplum] = useState("")
    const [createddt, setCreatedda] = useState("")
    const [message, setMessage] = useState('');
    const [donate, setDonate] = useState([]);
    const [recdonate, setRecdonate] = useState([]);
    const [totala, setTotal] = useState('')
    const [sum, setSum] = useState([])
    const [filtervalue, setFiltervalue] = useState('')
    const [startDate, setStartdate] = useState('')
    const [endDate,setEndDate]=useState('')
    const [ReceivedSearch,setReceivedSearch]=useState("")
    const [Rec1,setRec1]=useState('')
    const [Rec2,setRec2]=useState('')
    const [ c1,setC1]=useState('')

    const [Ce1,setCe1]=useState('')
    const [Ce2,setCe2]=useState('')
    const [Ce3,setCe3]=useState('')
    //  const [dataSource1, setDataSource] = useState([])

useEffect(()=>{
    setReceivedSearch('')
    setRec1('')
    setRec2('')
    }
    ,[created,plums ])


    useEffect(()=>{
        setCe2('')
        setCe1 ('')
        setCe3('')
        }
        ,[received,plums])

        useEffect(()=>{
            setFiltervalue('')
            setStartdate ('')
            setEndDate('')
            }
            ,[received,created])

    const handleCloseStatistics = () => {
        closeSubmenuFullScreen();
    };

    const plumcashing = async(e) => {
        //   await getCashing(1,filtervalue,startDate,endDate)
        //   setDataSource(dataSource.data);
        setPlums(true)
        setCreated(false)
        setReceived(false)
        setMyplumcashing("active")
        setMyplumreceived("")
        setMyplumcreated("")

    }
    const plumreceive = (e) => {
        setPlums(false)
        setCreated(false)
        setReceived(true)
        setMyplumcashing("")
        setMyplumreceived("active")
        setMyplumcreated("")
    }


    const plumcreated = (e) => {
        setPlums(false)
        setCreated(true)
        setReceived(false)
        setMyplumcashing("")
        setMyplumcreated("active")
        setMyplumreceived("")
    }


    const gettotal = (data, ds, user, rec, sum) => {
        setReceiveddata(data)
        setReceivedplum(ds)
        setDonate(user)
        setRecdonate(rec)
        setSum(sum)
    }

    const createtotal = (data, db, total) => {
        setCreateddata(data)
        setCreatedda(db)
        setTotal(total)
    }

    const onFilterInputChange = (e) => {
        setFiltervalue(e.target.value)
    };

    const onfilterClick = () => {
        onFiltersChange(filtervalue);
    }

    const DataSearchStart = (e) => {
        if(e==null){
          setStartdate('') 
          onFiltersChange()
        }
        else{
         let dar1=moment(e._d).format('YY/MM/DD')
         setStartdate(dar1)
         onFiltersChange(filtervalue,dar1) 
        }    
    };

    const DateSearchEnd=(e)=>{
        if(e==null){
            setEndDate("")
            onFiltersChange();
        }
        else{
        let dar=moment(e._d).format('YY/MM/DD')
        setEndDate(dar) 
        onFiltersChange(filtervalue,dar);
        }
    }

    const onReceivedInput=(e:any)=>{
        setMessage(e.target.value)
    }


    const onReceivedfilterClick=()=>{
       setReceivedSearch(message)  
    }
    const rec1=(e)=>{
        if(e==null){
            setRec1('')
        }
        else{
        let dar=moment(e._d).format('YY/MM/DD')
        setRec1(dar) 
        }
    }

    const rec2=(e)=>{
        if(e==null){
            setRec2('')
        }
        else{
        let dar=moment(e._d).format('YY/MM/DD')
        setRec2(dar) 
        }
    }


    const onFiltercreated=(e)=>{
        setC1(e.target.value)

    }


    const oncreateclick=()=>{
        setCe3(c1)  
     }

     const cre1 =(e)=>{
        if(e==null){
            setCe1('') 
        }
        else{
         let dar=moment(e._d).format('YY/MM/DD')
         setCe1(dar) 
        }
     }
 
     const cre2=(e)=>{
        if(e==null){
            setCe2('')
        }
        else{
         let dar=moment(e._d).format('YY/MM/DD')
         setCe2(dar) 
        }
     }

    return (
        <>

            <Row
                className="menu-wrapper full-screen-submenu">

                {/* Back button and title */}
                <Row
                    className="contest-top-row"
                    type="flex"
                    justify="start"
                    align="middle"
                >
                    <Button onClick={handleCloseStatistics} className="details-arrow">
                        <img src={arrow} alt="close" />
                    </Button>
                    <Typography.Text className="contest-title bold">
                        PLUM CONTROL AND STATISTICS
                    </Typography.Text>
                    <Button onClick={plumcashing}
                        className={'ant-btn primary-ghost-btn xs ml-30 mr-5 ' +
                            myplumcashing
                        }>

                        Plum cashing request
                    </Button>

                    <Button
                        onClick={plumreceive}
                        className={'ant-btn primary-ghost-btn xs ml-5 mr-5 ' +
                            myplumreceived
                        }>

                        Plum received
                    </Button>

                    <Button
                        onClick={plumcreated}
                        className={'ant-btn primary-ghost-btn xs ml-5 mr-5 ' +
                            myplumcreated
                        }>
                        Plum created
                    </Button>
                </Row>

                {plums &&
                    <div className='plum-cashing-request-tbl' style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                        <div className='col col-10 table-bdr'>
                            <div className='grid-row'>
                                <div className='col col-8 pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6'>Total Number of Username: {id} </div>
                                        <div className='col col-6 mb-5'>
                                            <img src={longaro} alt="aro" />
                                        </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6 mt-10'>Total Number of Requested plum for cashing: {num}</div>
                                        <div className='col col-6 relative'>
                                            <input
                                                name="subject"
                                                type="text"
                                                onChange={onFilterInputChange}
                                                placeholder="Search by Username, Country, Date, Expired"
                                                // value={currentFilters}
                                                className="inp regular"
                                            />
                                            <button className='iconx searchicn'
                                                onClick={onfilterClick}
                                            >

                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='col col-4 bdr-left pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6 mb-5'>Start Date</div>
                                        <div className='col col-6 mb-5'>End date</div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6'>
                                             <DatePicker onChange={DataSearchStart} />
                                        </div>
                                        <div className='col col-6'>
                                            <DatePicker onChange={DateSearchEnd} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        {/* <Plums/> */}
                        <Table
                            width={width - 70}
                            height={height - 220}
                            // language={language}
                            loading={loading}
                            dataSource={dataSource}
                            {...otherProps} />
                        <Row style={{ marginTop: '30px' }} type="flex" justify="center">
                            <Col>
                                <Paginate
                                    onPageChange={(page) => onPageChange(page.selected)}
                                    totalItems={totalItems}
                                    pageSize={500}
                                    forcePage={currentPage}
                                />
                            </Col>
                        </Row>

                       

                    </div>
                }


                {received

                    &&
                    <div className='plum-received-tbl' style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                        <div className='col col-10 table-bdr'>
                            <div className='grid-row'>
                                <div className='col col-8 pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6'>Total Number of users who received: {recdonate} </div>
                                        <div className='col col-6 mb-5'>
                                            <img src={longaro} alt="aro" />
                                        </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6'>Total Number of users who donated: {donate} </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6 mt-10'>Total Number of Plums received: {sum}</div>
                                        <div className='col col-6 relative'>
                                            <input
                                                name="subject"
                                                type="text"
                                                onChange={onReceivedInput}
                                                placeholder="Search by Username, Country, Date, Expired"
                                                // value={message}
                                                className="inp regular"
                                            />
                                            <button className='iconx searchicn'
                                            onClick={onReceivedfilterClick}
                                            >

                                            </button>
                                            
                                        </div>

                                    </div>
                                </div>

                                <div className='col col-4 bdr-left pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6 mb-5'>Start Date</div>
                                        <div className='col col-6 mb-5'>End date</div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6'>

                                            <DatePicker  onChange={rec1} />
                                        </div>
                                        <div className='col col-6'>
                                            <DatePicker  onChange={rec2}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PlumReceived props={gettotal} datafun={{ReceivedSearch,Rec1,Rec2}} />

                    </div>
                }

                {created &&
                    <div className='plum-created-tbl' style={{ paddingLeft: '2em', paddingRight: '2em' }}>
                        <div className='col col-10 table-bdr'>
                            <div className='grid-row'>
                                <div className='col col-8 pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6'>Total Number of Username: {totala} </div>
                                        <div className='col col-6 mb-5'>
                                            <img src={longaro} alt="aro" />
                                        </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6 mt-10'>Total Number of Plum exchanged from coins: {createddt}</div>
                                        <div className='col col-6 relative'>
                                            <input
                                                name="subject"
                                                type="text"
                                                 onChange={onFiltercreated}
                                                placeholder="Search by Username, Country, Date, Expired"
                                                // value={currentFilters}
                                                className="inp regular"
                                            />
                                            <button className='iconx searchicn'
                                            onClick={oncreateclick}
                                            ></button>
                                        </div>
                                    </div>
                                </div>

                                <div className='col col-4 bdr-left pt-10 pb-10'>
                                    <div className='grid-row'>
                                        <div className='col col-6 mb-5'>Start Date</div>
                                        <div className='col col-6 mb-5'>End date</div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='col col-6'>
                                            <DatePicker  onChange={cre1}/>
                                        </div>
                                        <div className='col col-6'>
                                            <DatePicker onChange={cre2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <PlumCreated props={createtotal}  datafuntion={{Ce3,Ce1,Ce2}} />

                    </div>
                }
            </Row>
        </>
    );
}

DomePlums.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    statisticsType: PropTypes.string,
    dataSource: PropTypes.array,
    onPageChange: PropTypes.func,
    totalItems: PropTypes.number,
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    total: PropTypes.object,
};

DomePlums.defaultProps = {
    language: 'EN',
    onPageChange: () => { },
    pageSize: 10,
};

const mapStateToProps = (state) => ({
    language: state.authentication.language,
    kitList: state.kitList,
    role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
    closeSubmenuFullScreen: () => dispatch(menuActions.closeSubmenuFullScreen()),
    openStats: () => dispatch(menuActions.openSubmenuFullScreen),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomePlums);

export function BellowTable(props) {
    const { label, value } = props;
    return (
        <div className="column">
            <div className="row">{label}</div>
            <div className="value">{value}</div>
        </div>
    );
}

BellowTable.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
