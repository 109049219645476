import { LanguageMap } from '../interfaces/language';

export const recharge: LanguageMap<string> = {
  EN: 'Recharge',
  KO: '충전',
};

export const spent: LanguageMap<string> = {
  EN: 'Spent',
  KO: '소비됨',
};

export const bought: LanguageMap<string> = {
  EN: 'Bought',
  KO: '구매함',
};

export const currentBalance: LanguageMap<string> = {
  EN: 'Current Balance',
  KO: '현재 잔고',
};

export const lCash: LanguageMap<string> = {
  EN: 'Lcash',
  KO: 'Lcash',
};

export const adKit: LanguageMap<string> = {
  EN: 'Ad KIT',
  KO: '광고 키트',
};

export const lcashHistory: LanguageMap<string> = {
  EN: 'Lcash History',
  KO: 'Lcash History',
};

export const date: LanguageMap<string> = {
  EN: 'Date:',
  KO: 'Date:',
};

export const remarks: LanguageMap<string> = {
  EN: 'Remarks:',
  KO: 'Remarks:',
};

export const amount: LanguageMap<string> = {
  EN: 'Amount:',
  KO: 'Amount:',
};

export const balance: LanguageMap<string> = {
  EN: 'Balance:',
  KO: 'Balance:',
};

export const loadNextPage: LanguageMap<string> = {
  EN: 'Load next page',
  KO: 'Load next page',
};
