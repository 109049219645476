import React, { memo } from 'react';
import { Row, Menu } from 'antd';
import PropTypes from 'prop-types';

function MenuToggleSwitch(props) {
  const { onChange, menuItems, value, classNameRoot } = props;

  const onChangeHandler = (event) => {
    onChange(event.key);
  };

  return (
    <Row type="flex" justify="space-around" className={classNameRoot}>
      <Menu onClick={onChangeHandler} mode="horizontal" style={{ flex: 1 }} selectedKeys={[value]}>
        {menuItems.map((menu) => (
          <Menu.Item key={menu.value}>{menu.label}</Menu.Item>
        ))}
      </Menu>
    </Row>
  );
}

MenuToggleSwitch.propTypes = {
  classNameRoot: PropTypes.string,
  onChange: PropTypes.func,
  menuItems: PropTypes.array,
  value: PropTypes.string,
};

MenuToggleSwitch.defaultProps = {
  onChange: (value) => {
    console.log('missing logic:', value);
  },
  menuItems: [
    { label: 'Menu1', value: 'menu1' },
    { label: 'Menu2', value: 'menu2' },
  ],
  classNameRoot: 'admin-review-btn',
};

export default memo(MenuToggleSwitch);
