import React from 'react';
import { Row, Typography } from 'antd';
import Sticker from './Sticker';
import { baseURL } from '../../../lib/config';

interface Props {
  ID: number;
  name: string;
  stickers: Array<any>;
  onImgUrlChange: (url: string, id: number, sectionID: number, sectionPosition: number) => void;
  selectedStickerID: number;
}

const Section = (props: Props) => {
  return (
    <Row type="flex" justify="start" style={{ width: '100%' }}>
      <Row style={{ display: 'block' }}>
        <Typography.Text className="fredoka">{props.name}</Typography.Text>
      </Row>
      <Row type="flex" justify="center">
        {props.stickers
          .filter((sticker: any) => {
            return sticker.stickerImage?.pic.path;
          })
          .map((sticker: any) => {
            return (
              <Sticker
                stickerID={sticker.ID}
                key={sticker.ID}
                pathToImg={`${baseURL}${sticker.stickerImage?.pic.path}`.replace(/ /g, '')}
                onImgUrlChange={props.onImgUrlChange}
                selected={sticker.ID === props.selectedStickerID}
                sectionID={props.ID}
                stickerSectionPosition={sticker.sectionPosition}
              />
            );
          })}
      </Row>
    </Row>
  );
};

export default Section;
