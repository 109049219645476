import React from 'react';
import { Row, Menu } from 'antd';
import { AdminReviewButton1, AdminReviewButton2 } from '../../../../lib/strings/treasureReview';
import { connect } from 'react-redux';
import { languages } from '../../../../lib/interfaces/language';

interface Props {
  handleSelectedButton: (e: any) => void;
  language?: languages;
}

const mapState = (state: { authentication: { language: languages } }) => {
  return {
    language: state.authentication.language,
  };
};

const AdminReviewButtons = (props: Props) => {
  return (
    <Row type="flex" justify="space-around" className="admin-review-btn">
      <Menu
        onClick={(e: any) => props.handleSelectedButton(e)}
        mode="horizontal"
        style={{ width: '100%' }}
      >
        <Menu.Item className="" key="3,4">
          {AdminReviewButton1[props.language]}
        </Menu.Item>
        <Menu.Item className="" key="2">
          {AdminReviewButton2[props.language]}
        </Menu.Item>
      </Menu>
    </Row>
  );
};

export default connect(mapState)(AdminReviewButtons);
