export const userConstants = {
  SEND_PHONE_NUMBER_REQUEST: 'SEND_PHONE_NUMBER_REQUEST',
  SEND_PHONE_NUMBER_SUCCESS: 'SEND_PHONE_NUMBER_SUCCESS',
  SEND_PHONE_NUMBER_FAILURE: 'SEND_PHONE_NUMBER_FAILURE',

  CANCEL: 'CANCEL',

  SIGNIN_REQUEST: 'SIGNIN_REQUEST',
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
  SIGNIN_FAILURE: 'SIGNIN_FAILURE',

  SIGNOUT: 'USERS_SIGNOUT',
  RESET: 'RESET',

  LANGUAGE: 'LANGUAGE',
  STORE_USER_DATA: 'STORE_USER_DATA',
  COUNTRY_CODE: 'COUNTRY_CODE',

  RETAKE_CONTROL: 'RETAKE_CONTROL',

  TOGGLE_LANGUAGE: 'TOGGLE_LANGUAGE',
  USER_BOOKS:'USER_BOOKS',
  COUPONS:'COUPONS',
  USER_TRANSLATION:'USER_TRANSLATION',
  PERMISSIONS:'PERMISSIONS',
  USER_COUNT:'USER_COUNT',
  VOUCHERS:'VOUCHERS'




};

interface PhoneNumberResponse {
  code: number;
  time: number;
  data: any;
}

interface SendPhoneNumberRequestAction {
  type: typeof userConstants.SEND_PHONE_NUMBER_REQUEST;
  phoneNumber: string;
}

interface SendPhoneNumberSuccessAction {
  type: typeof userConstants.SEND_PHONE_NUMBER_SUCCESS;
  phoneNumber: string;
  response: PhoneNumberResponse;
}

interface SendPhoneNumberFailureAction {
  type: typeof userConstants.SEND_PHONE_NUMBER_FAILURE;
  phoneNumber: string;
}

interface Cancel {
  type: typeof userConstants.CANCEL;
  phoneNumber: string;
}

interface SigninRequestAction {
  type: typeof userConstants.SIGNIN_REQUEST;
  verificationNumber: string;
}

interface SigninSuccessAction {
  type: typeof userConstants.SIGNIN_SUCCESS;
  user: any;
}

interface SigninFailureAction {
  type: typeof userConstants.SIGNIN_FAILURE;
  error: string;
}

interface ResetState {
  type: typeof userConstants.RESET;
}

export type userActionTypes =
  | SigninRequestAction
  | SigninSuccessAction
  | SigninFailureAction
  | ResetState
  | SendPhoneNumberFailureAction
  | SendPhoneNumberSuccessAction
  | SendPhoneNumberRequestAction
  | Cancel;
