export const notificationsConstants = {
  STORE_NOTIFICATIONS: 'STORE_NOTIFICATIONS',
  NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
  NOTIFICATION_INPUT: 'NOTIFICATION_INPUT',
  NOTIFICATION_DATE: 'NOTIFICATION_DATE',
  WHEN_TO_SEND: 'WHEN_TO_SEND',
  STORE_USERS: 'STORE_USERS',
  SELECTED_USERS: 'SELECTED_USERS',
  STORE_CONTEST_ID: 'STORE_CONTEST_ID',
  ALL_SELECTED: 'ALL_SELECTED',
  CONTEST_USER_TYPE: 'CONTEST_USER_TYPE',
  SELECTED_CONTESTANT: 'SELECTED_CONTESTANT',
  NOTIFICATION_DETAILS: 'NOTIFICATION_DETAILS',
  EDIT_NOTIFICATION: 'EDIT_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  CLEAR_USERS: 'CLEAR_USERS',
  STORE_VOTERS_BY_CONTESTANTS: 'STORE_VOTERS_BY_CONTESTANTS',
  TYPE_BACKEND: 'TYPE_BACKEND',
  NOTIFICATIONS_INITIAL: 'NOTIFICATIONS_INITIAL',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  UPDATE_NOTIFICATION_LOCALIZATION: 'UPDATE_NOTIFICATION_LOCALIZATION',
  PURGE_DATA: 'PURGE_DATA',
  SET_NOTIFICATIONS_LOCALIZATION: 'SET_NOTIFICATIONS_LOCALIZATION',
  PURGE_NOTIFICATION_DETAILS: 'PURGE_NOTIFICATION_DETAILS',
};
