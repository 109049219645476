import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Spin, Col, Input, Form, Button } from 'antd';
import { debounce } from 'lodash';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import * as menuActions from '../../../../actions/menuActions';
import * as usersServices from '../../../../services/usersServices';
import { searchUsers } from '../../../../lib/strings/users';
import UsersList from './UsersList';

function Users({ language }) {
  const [username, setUsername] = useState('');

  const usersListInfiniteQuery = useInfiniteQuery(
    ['UsersList', { username }],
    ({ queryKey, pageParam }) => usersServices.getUsersListInfinite(queryKey, pageParam),
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.pagination.page + 1;
        const maxPage = Math.ceil(lastPage.pagination.total / lastPage.pagination.itemsPerPage);

        return nextPage <= maxPage ? nextPage : undefined;
      },
    }
  );

  const debounceUsername = useCallback(
    debounce((value) => {
      if (value.length > 2 || value.length === 0) {
        setUsername(value);
      }
    }, 500),
    []
  );

  const usernameChangeHandler = (event) => {
    debounceUsername(event.target.value);
  };

  const nextPageHandler = () => {
    usersListInfiniteQuery.fetchNextPage();
  };

  return (
    <Row type="flex" justify="center" className="candyEvents-list">
      <Col style={{ width: '100%' }}>
        <Row
          className="create-treasure-padding"
          style={{ width: '100%', marginTop: '2em', marginBottom: '2em' }}
        >
          <Col>
            <Form>
              <Form.Item label={searchUsers[language]}>
                <Input className="input-treasure full-width" onChange={usernameChangeHandler} />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <UsersList
            usersList={usersListInfiniteQuery.data?.pages}
            loading={usersListInfiniteQuery.isLoading}
            language={language}
          />
        </Row>

        {(usersListInfiniteQuery.isLoading || usersListInfiniteQuery.isFetchingNextPage) && (
          <Row type="flex" justify="center">
            <Spin size="large" />
          </Row>
        )}

        {usersListInfiniteQuery.hasNextPage && (
          <Row type="flex" justify="center">
            <Button className="transparent-btn" onClick={nextPageHandler}>
              Load next page
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
}

Users.propTypes = {
  language: PropTypes.string,
  role: PropTypes.number,
  openCreateCandyEvent: PropTypes.func,
  closeSubmenu1: PropTypes.func,
  closeSubmenu2: PropTypes.func,
};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  role: state.userData.role,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
});

export default connect(mapsStateToProps, mapDispatchToProps)(Users);
