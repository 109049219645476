import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Button, Popover } from 'antd';
import FilterForm from './FilterForm';
import Tag from './Tag';
import FilterIcon from '../../assets/filterIcon.svg';
import * as statisticsString from '../../lib/strings/statistics';
import PropTypes from 'prop-types';

function Filter(props) {
  const { language, filterForm, statisticsType, clickedUsername, kitList, onChange, role } = props;

  useEffect(() => {
    if (clickedUsername) {
      let newFilters = { ...filters };
      newFilters.username = clickedUsername;
      setFilters(newFilters);
      updateTags(newFilters);
      // Api call with newFilters query
      onChange(newFilters);
    }
  }, [clickedUsername]);

  const [showPopover, setShowPopover] = useState(false);

  const [filters, setFilters] = useState<any>({});

  const [tags, setTags] = useState<any>([]);

  const onFilterChange = (
    key:
      | 'username'
      | 'treasureName'
      | 'dateFrom'
      | 'dateTo'
      | 'country'
      | 'kitType'
      | 'status'
      | 'partnerUsername'
      | 'paymentStatus'
      | 'treasureStatus',
    value: any
  ) => {
    let newFilters = { ...filters };

    if (value !== undefined && value !== null && value !== '') {
      newFilters[key] = value;
    } else {
      delete newFilters[key];
    }

    setFilters(newFilters);
  };

  const filterButtonHandler = () => {
    setShowPopover((currentState) => !currentState);
  };

  const filterOnApplyHandler = () => {
    updateTags(filters);
    // Api call with filter query
    onChange(filters);
  };

  const filterOnClearHandler = () => {
    setFilters({});
    updateTags({});
    // Api call with filter query
    onChange({});
  };

  const updateTags = (filters) => {
    const currentFilters = { ...filters };

    const filterKeys = Object.keys(currentFilters);
    let tags = [];
    filterKeys.map((filterKey) => {
      if (filterKey === 'dateTo' || filterKey === 'dateFrom') {
        tags.push({ id: filterKey, value: currentFilters[filterKey].format('D/M/YYYY') });
      } else {
        tags.push({ id: filterKey, value: currentFilters[filterKey] });
      }
    });
    setTags(tags);
  };

  const onTagDelete = (
    key: 'username' | 'treasureName' | 'dateFrom' | 'dateTo' | 'country' | 'kitType' | 'status'
  ) => {
    let newFilters = { ...filters };
    delete newFilters[key];
    setFilters(newFilters);
    updateTags(newFilters);
    // Api call with filter query
    onChange(newFilters);
  };

  return (
    <Row type="flex" justify="start" align="middle">
      <Popover
        placement="bottomLeft"
        overlayStyle={{ width: 0, height: 0 }}
        content={
          <FilterForm
            closeForm={() => setShowPopover(false)}
            language={language}
            onChange={onFilterChange}
            valueObject={filters}
            onClear={filterOnClearHandler}
            onApply={filterOnApplyHandler}
            statisticsType={statisticsType}
            kitList={kitList}
            role={role}
            {...filterForm}
          />
        }
        align={{ offset: [-20, -20] }}
        visible={showPopover}
      >
        <Button className="statistics-transparent-btn" onClick={filterButtonHandler}>
          <Row type="flex" justify="start" align="middle">
            <Col>
              <img src={FilterIcon} style={{ marginBottom: '2px' }} />
            </Col>
            <Col>
              <Typography.Text
                className="statistics-text statistics-bold statistics-underline"
                style={{ marginLeft: '5px' }}
              >
                {statisticsString.filter[language]}
              </Typography.Text>
            </Col>
          </Row>
        </Button>
      </Popover>
      {tags.map((tag) => (
        <Tag id={tag.id} key={tag.id} onClose={onTagDelete}>
          {tag.value}
        </Tag>
      ))}
    </Row>
  );
}

Filter.propTypes = {
  language: PropTypes.string,
  statisticsType: PropTypes.string,
  clickedUsername: PropTypes.string,
  onChange: PropTypes.func,
};

Filter.defaultProps = {
  language: 'EN',
  onChange: () => {},
};

export default Filter;
