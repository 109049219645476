import { store } from '../helpers/store';
import { candy } from './const';

export const calculateRandomLocations = (
  randomNumber1: number,
  randomNumber2: number,
  id: number,
  candyCount?: string
) => {
  const treasureType = store.getState().treasure.treasureType;
  const x0 = store.getState().locations[id].longitude;
  const y0 = store.getState().locations[id].latitude;
  const locationID = store.getState().locations[id].ID;
  const radius = store.getState().locations[id].radius * 1000;
  const rd = radius / 111300;

  const w = rd * Math.sqrt(randomNumber1);
  const t = 2 * Math.PI * randomNumber2;
  const x = w * Math.cos(t);
  const y = w * Math.sin(t);

  // Resulting point.
  const latitude = y + y0;
  const longitude = x + x0;

  if (treasureType !== candy) {
    return { latitude, longitude, locationID };
  } else {
    return { latitude, longitude, locationID, candyCount };
  }
};

export const reverseRanomLocation = (latitude: number, longitude: number) => {};
