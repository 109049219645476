import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import * as treasureReview from '../../../lib/strings/treasureReview';
import moment from 'moment';
import PropTypes from 'prop-types';

function SelectorLDiscount(props) {
  const {
    language,
    visible,
    list,
    onChange,
    defaultValue,
    containerStyle,
    dropDownTextPosition,
    selectorTextPosition,
    placeholder,
    listObject,
    ...otherProps
  } = props;

  if (!visible) {
    return null;
  }

  const [currentValue, setCurrentValue] = useState(defaultValue);

  const onSelectHandler = (value) => setCurrentValue(value);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  const selectorClasses =
    selectorTextPosition === 'center'
      ? 'treasure-list-location-selector treasure-list-status-selector-picker'
      : 'treasure-list-location-selector treasure-list-status-selector-picker-justify-start';

  const currentDateUnix = parseInt(moment().format('x'));
  const filteredList = list.sort((first, second) => second.expirationDate - first.expirationDate);

  return (
    <Row className="treasure-list-location" style={containerStyle}>
      <Col>
        <Select
          {...otherProps}
          className={selectorClasses}
          defaultValue={defaultValue && list[0]}
          placeholder={placeholder}
          onChange={onSelectHandler}
        >
          {filteredList.map((ldiscount) => {
            const expiredOrExpiration =
              currentDateUnix >= ldiscount.expirationDate
                ? treasureReview.LDiscountExpired[language]
                : treasureReview.LDiscountExpiration[language];
            return (
              <Select.Option
                className={dropDownTextPosition === 'center' ? 'text-centered' : null}
                key={ldiscount.ID}
                value={ldiscount.ID}
                disabled={currentDateUnix >= ldiscount.expirationDate}
              >
                {`${ldiscount.treasureName} ${
                  ldiscount.discountValue
                } (${expiredOrExpiration} ${moment(ldiscount.expirationDate).format(
                  'DD/MM/YYYY'
                )})`}
              </Select.Option>
            );
          })}

          {/* <Select.Option
            className={dropDownTextPosition === 'center' ? 'text-centered' : null}
            key={0}
            value={0}
          >
            {treasureReview.dontWantToUseLDiscount[language]}
          </Select.Option> */}
        </Select>
      </Col>
    </Row>
  );
}

SelectorLDiscount.propTypes = {
  language: PropTypes.string,
  visible: PropTypes.bool,
  list: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  containerStyle: PropTypes.object,
  dropDownTextPosition: PropTypes.oneOf(['center', 'start']),
  selectorTextPosition: PropTypes.oneOf(['center', 'start']),
  placeholder: PropTypes.string,
};

SelectorLDiscount.defaultProps = {
  language: 'EN',
  visible: true,
  list: [],
  onChange: (value) => {
    console.log('missing logic, value:', value);
  },
  dropDownTextPosition: 'center',
  selectorTextPosition: 'center',
};

export default SelectorLDiscount;
