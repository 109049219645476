import React, { useState } from 'react';
import Kit from './Kit';

interface Props {
  kitList?: any;
}

const KitList = (props: Props) => {
  const [kitID, setkitID] = useState(null);

  const handleSelectedKit = (id: number) => {
    setkitID(id);
  };

  return (
    <div>
      {props.kitList
        ? props.kitList
            .sort((a: any, b: any) => (a.ID < b.ID ? 1 : -1))
            .map((kit: any) => {
              return (
                <Kit
                  key={kit.ID}
                  name={kit.name}
                  type={kit.type}
                  selected={kit.ID === kitID}
                  id={kit.ID}
                  pricesPerCountry={kit.pricesPerCountry}
                  adDuration={kit.adDuration}
                  kit={kit}
                  handleSelectedKit={handleSelectedKit}
                  t_type={kit.t_type}
                />
              );
            })
        : null}
    </div>
  );
};

export default KitList;
