import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';
import { DetailsGrid, BackButtonLeftText } from '../../../../components';
import * as candyEvent from '../../../../lib/strings/candyEvent';
import { useQueryClient } from 'react-query';
import * as menuActions from '../../../../actions/menuActions';
import * as candyEventActions from '../../../../actions/candyEventActions';
import PropTypes from 'prop-types';
function CandyEventContestantDetails(props) {
  const queryClient = useQueryClient();
  const { language, contestantIndex, ID, closeSubmenu2, candyEventEditParameter } = props;

  const candyEventRankingList = queryClient.getQueryData(['CandyEventRankingList', { ID: ID }]);
  const { firstName, lastName, phoneNumber, email, address } = candyEventRankingList[
    contestantIndex
  ];

  const onCloseHandler = () => {
    closeSubmenu2();
    candyEventEditParameter(candyEventActions.editableParameters.contestantIndex, null);
  };

  return (
    <Row className="candyEventDetails-wrapper candyEvent-text" type="flex">
      <BackButtonLeftText label={candyEvent.contestantDetail[language]} onClick={onCloseHandler} />
      <Row className="candyEvent-contestant-details">
        <DetailsGrid
          title={candyEvent.contestantFirstName[language]}
          titleSpan={10}
          value={firstName}
        />
        <DetailsGrid
          title={candyEvent.contestantLastName[language]}
          titleSpan={10}
          value={lastName}
        />
        <DetailsGrid
          title={candyEvent.contestantPhoneNumber[language]}
          titleSpan={10}
          value={phoneNumber}
        />
        <DetailsGrid title={candyEvent.contestantEmail[language]} titleSpan={10} value={email} />
        <DetailsGrid
          title={candyEvent.contestantAddress[language]}
          titleSpan={10}
          value={address}
        />
      </Row>
    </Row>
  );
}

CandyEventContestantDetails.propTypes = {
  language: PropTypes.string,
  contestantIndex: PropTypes.number,
  ID: PropTypes.number,
  closeSubmenu2: PropTypes.func,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  contestantIndex: state.candyEvent.contestantIndex,
  ID: state.candyEvent.ID,
});

const mapDispatchToProps = (dispatch) => ({
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  candyEventEditParameter: (parameterKey, parameterValue) =>
    dispatch(candyEventActions.editParameter(parameterKey, parameterValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandyEventContestantDetails);
