
import React, { useContext, useEffect } from "react";
import { Row, Button, Typography, Col, InputNumber, DatePicker, Spin } from "antd";
import arrow from "../../../../assets/treasure_item_more_info_icon.svg";
import equalIcon from "../../../../assets/equal.svg";
import { menuConstants } from "../../../../constants/menuConstants";
import { connect } from "react-redux";
import { rechargeConstants } from "../../../../constants/rechargeConstants";
import StripePayment from "../../../../components/StripePayment";
import searchIcon from '../../../../assets/searchIcon.svg';
import { persistor } from "../../../../helpers/store";
import lcashIcon from "../../../../assets/lcash.svg";
import closeIcon from "../../../../assets/closeXsmall.svg";
import downloadIcon from '../../../../assets/downloadIcon.png';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '../../../../assets/chevronLeft.svg';
import ChevronRight from '../../../../assets/chevronRight.svg';
import { useTranslation} from "react-i18next";
import moment from 'moment';
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  recharge,
  lCash,
  currentBalance,
} from "../../../../lib/strings/balance";
import { languages } from "../../../../lib/interfaces/language";

import { userConstants } from "../../../../constants/signinConstants";
import { LOCAL_STORAGE_USERDATA } from "../../../../lib/config";
import { userData } from "../../../../reducers/userReducer";
import AppContext from "../../../../AppContext";
import { getHistoryPlumaApi } from '../../../../services/domeService';
import { T } from "antd/lib/upload/utils";
// import CashConfirmation from "./CashConfirmation";
interface Props {
  handleClose: () => void;
  updateLCash: (amount: number) => void;
  amount: number;
  currentLCash: number;
  usd: number;
  isPaymentInitiated: boolean;
  language?: languages;
  userData: object;
  role:Number
  storeUserData: (user: object) => void;
}

const mapState = (state: {
  authentication: any;
  userData: { lcash: any ,role:any};
  recharge: { usd: any; amount: any };
  isPaymentInitiated: boolean;
}) => {
  return {
    currentLCash: state.userData.role,
    usd: state.recharge.usd,
    amount: state.recharge.amount,
    isPaymentInitiated: state.isPaymentInitiated,
    language: state.authentication.language,
    userData: state.userData,
  };
};

const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateLCash: (amount: number) =>
      dispatch({ type: rechargeConstants.STORE_AMOUNT, amount }),
    storeUserData: (user: any) =>
      dispatch({ type: userConstants.STORE_USER_DATA, user }),
  };
};

const History = (props: Props) => {
  // const [show, setShow] = useState(false);
  // const [showText, setShowText] = useState(false);
  // const [checked, setChecked] = useState(false);
  // const [plumpAmt, setPlumpmAmt] = useState(null);
  const [users, setUsers] = useState("")
  const [data, setData] = useState([])
  const [ischecked, setIsChecked] = useState(false);
  const [allData, setAllData] = useState([]);
  const [selectedCheckBoxIds, setSelectedCheckBoxIds] = useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [Startdate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState('');
  const [filtervalue, setFiltervalue] = useState("");
  const [loading, setLoading] = useState(false);


  const [pagination, setPagination] = useState(0)
  const [pagcurrent, setPagCurrent] = useState(1)

  const { t } = useTranslation();

  const profile = useContext(AppContext);
  useEffect(() => {
    persistor.persist();
    persistor.flush();
  }, []);



  const closetable = () => {
    profile.profile["history"] = false;
    profile.setContextData(profile.profile);
  };



  useEffect(() => {
    (async () => {
      setLoading(true)
      let data =  await getHistoryPlumaApi(1, props, filtervalue, Startdate, endDate)
           
      setData(data.data);
      setAllData(data.alldata)

      setPagination(data.pagination.total_pages)
      setPagCurrent(data.pagination.current)
      setLoading(false)
    })();
  }, [filtervalue, Startdate, endDate]);

  const onPageChange = async (e: any) => {
    let page_no = e.selected
    let data =  await getHistoryPlumaApi(page_no + 1, props, filtervalue, Startdate, endDate)
    setData(data.data);
  }


  const downloadFile = ({ allData, fileName, fileType }) => {
    const blob = new Blob([allData], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    if (!selectedItems.length) return;
    // if (ischecked) {

    // Headers for each column
    let headers = ["Date, DomeNme,Received,Cashed,Balance,Paid(U$), Receipt,Expired"];


    // Convert users data to a csv
    let usersCsv = selectedItems.reduce((acc, user) => {
      const { date, dome_name, plum, cashed_plum, total_plums, usd, Receipt, expired_at } =
        user;
      acc.push(
        [(moment(date).format('MM/DD/YYYY')),
          dome_name,
          plum,
          cashed_plum,
          total_plums,
          usd,
          Receipt,
        (moment(expired_at).format('MM/DD/YYYY'))].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      allData: [...headers, ...usersCsv].join("\n"),
      fileName: "history.csv",
      fileType: "text/csv",
    });

  }

  const checkHandlar = (e) => {
    if (e.target.checked) {
      let tempItems = [...allData];
      let tempSelectedIds = allData.map((item) => item.ID);

      setSelectedCheckBoxIds(tempSelectedIds);
      setSelectedItems(tempItems);
    } else {
      setSelectedCheckBoxIds([]);
      setSelectedItems([]);
    }
  };



  const thousandSeparators = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }

  const thousandSeparator = (x) => {

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  const onSelectSingleItem = (e, item) => {
    let tempItems = [...selectedItems];
    let tempSelectedIds = [...selectedCheckBoxIds];
    if (e.target.checked) {
      tempSelectedIds.push(item.ID);
      tempItems.push(item);
      setSelectedCheckBoxIds(tempSelectedIds);
      setSelectedItems(tempItems);
    } else {
      tempSelectedIds = tempSelectedIds.filter(
        (filtItem) => filtItem !== item.ID
      );
      setSelectedCheckBoxIds(tempSelectedIds);
    }
  };

  const onFilterInputChange = (e) => {
    setFiltervalue(e.target.value)
  };


  const SearchData = async () => {
    // let data =  await getHistoryPlumaApi(1, props, filtervalue, Startdate, endDate)
    // setData(data.data);
  }

  const SearchStartDate = (e) => {
    if (e == null) {
      setStartdate('')
    }
    else {
      let dar = moment(e._d).format('YY/MM/DD')
      setStartdate(dar)
    }
  };
  const SearchEndDate = (e) => {
    if (e == null) {
      setEndDate("")
    }
    else {
      let dar = moment(e._d).format('YY/MM/DD')
      setEndDate(dar)
    }
  }

  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog cardBiger">
        <div className="second-card relative">
          <Row
            className="contest-top-row"
            type="flex"
            justify="space-between"
            align="middle"
          >
            <div>
              <Button onClick={props.handleClose} className="details-arrow">
                <img src={arrow} alt="close" />
              </Button>
              <Typography.Text
                style={{ textTransform: "none" }}
                className="contest-title bold"
              >
               {t("Balance")}
                {/* {recharge[props.language]} */}
              </Typography.Text>
            </div>
            <div>
              <i onClick={closetable} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
          </Row>

          <div className='col col-12 table-bdr'>
            <div className='grid-row'>
              <div className='col col-5 pt-10 pb-10 fx-aic'>
                <input
                  name="subject"
                  type="text"
                  onChange={onFilterInputChange}
                  placeholder={t("Search by Dome Name country")}
                  // value={currentFilters}
                  className="inp regular"
                />
              </div>

              <div className='col col-6 bdr-left pt-10 pb-10'>
                <div className='grid-row sm'>
                  <div className='col col-6 size13'>{t('Start Date')}</div>
                  <div className='col col-6 size13'>{t('End date')}</div>
                </div>
                <div className='grid-row sm'>
                  <div className='col col-6'>
                    <DatePicker onChange={SearchStartDate} />
                  </div>
                  <div className='col col-6'>
                    <DatePicker onChange={SearchEndDate} />
                  </div>
                </div>
              </div>
              <div className="col col-1 search-icn">
                <img src={searchIcon} alt="icon" height={24} className="cursor" onClick={SearchData} />
              </div>
            </div>
          </div>

          <div className="domeAddContainer">
            <div className="mb-5 pt-10 fx-jcsb-aic">
              {/* <button className="size12">
                <img
                  src={downloadIcon}
                  alt="icon"
                  height="16"
                  onClick={exportToCsv}
                />{" "}
                Export to CSV
              </button> */}

              <button onClick={exportToCsv} className="size12 cursor">
                <img src={downloadIcon} alt="icon" height="16" />{t('Export to CSV')}
              </button>
              <span className="size13 text-right">{t("Note")}</span>
            </div>

            <Row>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th style={{ width: '34px' }} scope="col">
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="Bike"
                        onChange={checkHandlar}
                      />
                    </th>
                    <th style={{ width: '80px' }} scope="col">{t('Date')}</th>
                    <th style={{ width: '110px' }} scope="col">{t("Dome Name")}</th>
                    <th style={{ width: '65px' }} scope="col">{t("Received")}</th>
                    <th style={{ width: '60px' }} scope="col">{t("Cashed")}</th>
                    <th style={{ width: '60px' }} scope="col" className="text-right">{t("Balance1")}</th>
                    <th style={{ width: '60px' }} scope="col">{t("Paid(U$)")}</th>
                    <th style={{ width: '60px' }} scope="col">{t("Receipt")}</th>
                    <th style={{ width: '100px' }} scope="col text-center">{t("Expired")}</th>
                  </tr>
                </thead>
              </table>
              <div className='domeAddContainer scrollSection' style={{ maxHeight: '51vh' }}>
                <table className="table">
                  <tbody>
                    {loading && (
                      <td align='center' colSpan={9}>
                        <Spin
                          size="large"
                          style={{ marginTop: '2em', marginBottom: '2em', textAlign: "center" }}
                        />
                      </td>)}
                    {data
                      .map(function (item, key) {
                        return (
                          <tr key={key}>
                            <td style={{ width: '34px' }}>
                              <input
                                type="checkbox"
                                id={`custom-checkbox-${item}`}
                                name="vehicle1"
                                value={item}
                                checked={selectedCheckBoxIds.includes(item.ID)}
                                onChange={(e) => onSelectSingleItem(e, item)}
                              />

                            </td>
                            
                            <td style={{ width: '80px' }}>{moment(item.date).format('MM/DD/YYYY')}</td>

                            <td style={{ width: '110px', wordBreak: 'break-all' }}>{item.dome_name}</td>
                            <td style={{ width: '65px' }} className="text-center">{item.type == 4 ? "" : item.plum}</td>
                            <td style={{ width: '60px' }} className="text-right pr-15"> {thousandSeparator(item.cashed_plum ? item.cashed_plum : "")}</td>
                            <></>
                            <td style={{ width: '60px' }} className={item.type == 4 ? 'text-error text-right pr-15' : 'text-right pr-15'}>{
                             props.currentLCash==1100 ? thousandSeparators(item.total_received_plums) 
                               :
                              thousandSeparators(item.type == 4 ? item.total_plums : item.total_received_plums)}
                              {/* {item.total_received_plums} */}
                            </td>
                            <td style={{ width: '60px' }} className="text-right pr-15">{item.usd}</td>
                            <td style={{ width: '60px' }} align="center">{item.Receipt} <img src={downloadIcon} alt="icon" style={{ height: '16px' }} /></td>
                            <td style={{ width: '100px' }} className="text-right">{item.expired_at ? moment(item.expired_at).format('MM/DD/YYYY') : ""}</td>
                          </tr>
                        );
                      })}

                  </tbody>
                </table>
              </div>
            </Row>
            <Row className="fx-jcc flex-btn mb-15">
              <ReactPaginate
                containerClassName="statistic-paging-container"
                pageClassName="statistic-paging-page"
                pageLinkClassName="statistic-paging-page-link"
                activeLinkClassName="statistic-paging-page-link-active"
                pageCount={pagination}
                previousLabel={<img src={ChevronLeft} />}
                nextLabel={<img src={ChevronRight} />}
                marginPagesDisplayed={1}
                onPageChange={(e: any) => { onPageChange(e) }}
              />
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
};

export default connect(mapState, mapDispatch)(History);