import React, { Component, RefObject } from 'react';
import { Form, Input, Typography, Row, Button, InputNumber, Col } from 'antd';
import MessagingRadiusForm from './MessagingRadiusForm';
import { Autocomplete } from '@react-google-maps/api';
import { connect } from 'react-redux';
import * as treasureActions from '../../../actions/treasureActions';
import locationRefresh from '../../../assets/location_refresh_icon.svg';
import rightcheckiconwhite from '../../../assets/rightcheckiconwhite.svg';
import locationUndo from '../../../assets/location_undo_icon.svg';
import trash from '../../../assets/location_delete_icon.svg';
import rangeAroIcon from '../../../assets/rangeAroIcon.svg';
import * as treasureCreation from '../../../lib/strings/treasureCreation';
import * as treasureReview from '../../../lib/strings/treasureReview';
import { languages } from '../../../lib/interfaces/language';
import * as menuActions from '../../../actions/menuActions';
import { roleAdmin, sticker, candy, point, approved, finished, flash } from '../../../lib/const';
import Geocode from 'react-geocode';
import { b } from '../../dashboard/menu/i18next';
import { Recuring } from '../../../../src/services/treasureService'
import AppContext from '../../../AppContext';
import ReactTooltip from 'react-tooltip';

interface LocationFormProps {
  numberOfUnits: number;
  ID: number;
  enterNumberOfUnits: (numberOfUnits: string, key: number) => void;
  enterAddress: (address: any, locationID_UI: number) => void;
  enterLatLng: (latLong: any, locationID_UI: number) => void;
  deleteLocation?: (locationID_UI: number) => void;
  locationID_UI: number;
  locations: any;
  messageLocations: any;
  addNewMessagingRadius: (locationID_UI: number) => void;
  calculateRandomNumbers: (locationID_UI: number, numberOfUnits: number) => void;
  countTotalUnits: (numberOfUnits: number) => void;
  language?: languages;
  treasureUnitsLocations: any;
  form?: any;
  treasureType: number;
  role?: number;
  mapInstance?: any;
  showLocation: (locationID_UI: number) => void;
  hideLocation: (locationID_UI: number) => void;
  openLocationUIs: any;
  calculateCandies: (numberOfUnits: number) => void;
  location?: any;
  treasure: any;
  additional_prize: any,
  radius: any,
  zoom: any,
}

const actionCreators = {
  enterAddress: treasureActions.enterAddress,
  enterLatLng: treasureActions.enterLatLng,
  enterNumberOfUnits: treasureActions.enterNumberOfUnits,
  deleteLocation: treasureActions.deleteLocation,
  addNewMessagingRadius: treasureActions.addNewMessagingRadius,
  calculateRandomNumbers: treasureActions.calculateRandomNumbers,
  countTotalUnits: treasureActions.updateTotalNumberOfUnits,
  showLocation: menuActions.showLocation,
  hideLocation: menuActions.hideLocation,
  calculateCandies: treasureActions.calculateCandiesInUnits,
};

const mapStateToProps = (state: {
  treasure: any;
  locations: any;
  messageLocations: any;
  authentication: any;
  treasureUnitsLocations: any;
  userData: any;
  openLocationUIs: any;
  address: any;
  Additionalprize: any


}) => {
  const {
    treasure,
    locations,
    messageLocations,
    authentication,
    treasureUnitsLocations,
    userData,
    openLocationUIs,
    Additionalprize,
    address,
  } = state;
  const { language } = authentication;
  const { role } = userData;
  const { additional_prize } = Additionalprize;
  const { mapInstance, radius, zoom } = address;
  const { numberOfUnits, treasureType, ID } = treasure;
  return {
    numberOfUnits,
    locations,
    messageLocations,
    language,
    treasureUnitsLocations,
    treasureType,
    role,
    openLocationUIs,
    mapInstance,
    treasure,
    additional_prize,
    ID,
    radius,
    zoom

  };
};

class LocationForm extends Component<LocationFormProps, any> {
  static contextType = AppContext;
  constructor(props: Readonly<LocationFormProps>) {
    super(props);

    this.state = {
      autocomplete: null,
      latitude: null,
      longitude: null,
      additionalprizes: false,
    };
  }

  componentDidMount = () => {
    const { location, locationID_UI } = this.props;
    Geocode.setApiKey('AIzaSyAC7FC5wCXyfRRFiA5b3zQ1hF85iz_8EM4');
    this.props.form.setFieldsValue({ ['address' + this.props.locationID_UI]: '' });
    this.props.form.setFieldsValue({
      ['numberOfUnits' + this.props.locationID_UI]:
        this.props.locations[this.props.locationID_UI].numberOfUnits !== 0
          ? this.props.locations[this.props.locationID_UI].numberOfUnits
          : '',
    });

    if (location.latitude && location.longitude) {
      this.props.form.setFieldsValue({ ['lat' + locationID_UI]: location.latitude });
      this.props.form.setFieldsValue({ ['lng' + locationID_UI]: location.longitude });

      Geocode.fromLatLng(location.latitude, location.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          this.props.form.setFieldsValue({ ['address' + locationID_UI]: address });
          //this.props.enterLatLng(latLng, this.props.locationID_UI)
          // this.syncingCirclesSizes()
        },
        (error) => {
        }
      );
    }
  };

  handleGeocoding(e: any) {
    const addressToGeocode = e.target.value;
    this.props.enterAddress(addressToGeocode, this.props.locationID_UI);
  }

  handleAddAnotherMessagingRadius = () => {
    this.props.addNewMessagingRadius(this.props.locationID_UI);
  };

  handleDeleteLocation = () => {
    this.props.deleteLocation(this.props.locationID_UI);
  };

  handleInput = async (value: any) => {
    if (this.props.treasureType === candy) {
      this.props.calculateCandies(value);
    }
    this.props.enterNumberOfUnits(value, this.props.locationID_UI);
    this.props.calculateRandomNumbers(this.props.locationID_UI, value);
    this.props.countTotalUnits(value);
    let a = localStorage.getItem("endstart1")
    let b = parseInt(a)
    let d = localStorage.getItem('ck')
    const c = await Recuring(this.props.ID, d ? d : '1', b)
    localStorage.removeItem('ck')
  };

  calculateNew = () => {
    this.props.calculateRandomNumbers(
      this.props.locationID_UI,
      this.props.locations[this.props.locationID_UI].numberOfUnits
    );
  };

  onLoad = (autocomplete: any) => {
    this.setState({
      autocomplete: autocomplete,
    });
  };

  closeadditionalprizes = async (e: any) => {
    this.setState({
      additionalprizes: false,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };
  onadditionalprizes = async () => {
    let context = await this.getContext()
    context.additionalprizes = true;
    await this.updateContext(context);
  }

  syncingCirclesSizes = () => {
    //syncing circles sizes after changing location
    this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() + 1);
    this.props.mapInstance.setZoom(this.props.mapInstance.getZoom() - 1);
  };

  onPlaceChanged = async () => {
    if (this.state.autocomplete !== null) {
      const res = await this.state.autocomplete.getPlace();
      if (res.formatted_address) {
        this.props.form.setFieldsValue({
          ['address' + this.props.locationID_UI]: res.formatted_address,
        });
        this.props.enterAddress(res, this.props.locationID_UI);
        const resLatLng = res.geometry.location;
        const latLng = {
          lat: await resLatLng.lat(),
          lng: await resLatLng.lng(),
        };
        this.props.form.setFieldsValue({ ['lat' + this.props.locationID_UI]: latLng.lat });
        this.props.form.setFieldsValue({ ['lng' + this.props.locationID_UI]: latLng.lng });
        this.setState({ latitude: latLng.lat, longitude: latLng.lng });
        this.syncingCirclesSizes();
      }
    } else {
      throw 'Autocomplete is not loaded yet!';
    }
  };

  onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    switch (e.target.id) {
      case 'lat':
        this.setState({ latitude: e.target.value });
        break;
      case 'lng':
        this.setState({ longitude: e.target.value });
        break;
      default:
        break;
    }
  };

  inputLatitude = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ latitude: e.target.value });
    let latLng = { lat: e.target.value, lng: this.state.longitude };

    if (e.target.value && this.state.longitude) {
      const newAdressLatLng = {
        lat: parseFloat(e.target.value),
        lng: parseFloat(this.state.longitude),
      };
      this.props.enterLatLng(newAdressLatLng, this.props.locationID_UI);
    }
    this.state.longitude
      ? Geocode.fromLatLng(e.target.value, this.state.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          this.props.form.setFieldsValue({ ['address' + this.props.locationID_UI]: address });
          //this.props.enterLatLng(latLng, this.props.locationID_UI)
          this.syncingCirclesSizes();
        },
        (error) => {
        }
      )
      : null;
  };

  inputLongitude = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({ longitude: e.target.value });
    let latLng = { lat: parseInt(this.state.latitude), lng: parseInt(e.target.value) };

    if (e.target.value && this.state.latitude) {
      const newAdressLatLng = {
        lat: parseFloat(this.state.latitude),
        lng: parseFloat(e.target.value),
      };
      this.props.enterLatLng(newAdressLatLng, this.props.locationID_UI);
    }

    this.state.latitude
      ? Geocode.fromLatLng(this.state.latitude, e.target.value).then(
        (response) => {
          const address = response.results[0].formatted_address;
          this.props.form.setFieldsValue({ ['address' + this.props.locationID_UI]: address });
          //this.props.enterLatLng(latLng, this.props.locationID_UI)
          this.syncingCirclesSizes();
        },
        (error) => {
        }
      )
      : null;
  };

  renderContent()  {
    const zoom:number = this.props.zoom;
    switch (zoom) {
      case 13:
        return 3000;
      case 14:
        return 1000;
      case 15:
        return 700;
      case 16:
        return 600;
      case 17:
        return 500;
      case 18:
        return 400;
      case 19:
        return 300;
      case 20:
        return 200;
      case 21:
        return 100;
      default:
        return;
    }
  }

  renderContentmin() {
    const zoom = this.props.zoom;
    switch (zoom) {
      case 13:
        return 10;
      case 14:
        return 10;
      case 15:
        return 10;
      case 16:
        return 10;
      case 17:
        return 10;
      case 18:
        return 5;
      case 19:
        return 5;
      case 20:
        return 5;
      case 21:
        return 5;
      default:
        return;
    }
  }


  render() {
    const { language, treasure, role, additional_prize } = this.props;

    const isTreasureRunning =
      treasure.isEditing && (treasure.status === approved || treasure.status === finished);

    const { getFieldDecorator } = this.props.form;
    const { prize_type} = this.props.treasure;

    const MessagingRadiusFormChildren: JSX.Element[] = [];

    for (let i = 0; i < this.props.messageLocations.length; i++) {
      if (
        this.props.messageLocations[i]?.locationID ===
        this.props.locations[this.props?.locationID_UI]?.ID
      ) {
        MessagingRadiusFormChildren.push(
          <MessagingRadiusForm
            key={i}
            messageLocationID_UI={i}
            locationID_UI={this.props.locationID_UI}
          />
        );
      }
    }

    getFieldDecorator(`${['numberOfUnits' + this.props.locationID_UI]}`, { initialValue: '' });

    return (
      <>
        {this.props.locations.length > 1 ? (
          <Row type="flex" justify="space-between" align="middle">
            <Typography.Text className="location-title">
              #{this.props.locationID_UI + 1}  {b?.t('Location')}
            </Typography.Text>
            <img
              className="icon-filter"
              onClick={this.handleDeleteLocation}
              src={trash}
              alt="delete location"
            />
          </Row>
        ) : null}

        <div className="location-wrapper">
          {/* <Typography.Paragraph>{treasureCreation.LocationFormName[language]}</Typography.Paragraph> */}
          <Form.Item
            className="roboto-regular"
            label={b?.t('Address')}
          >
            <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}>
              {getFieldDecorator(['address' + this.props.locationID_UI], {
                rules: [{ required: false, message: b?.t('Please enter at least one address') }],
              })(
                <Input
                  className="input-treasure"
                  placeholder={b?.t('Address')}
                  disabled={isTreasureRunning}
                />
              )}
            </Autocomplete>
          </Form.Item>

          {role >= roleAdmin && (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="roboto-regular"
                  label={b?.t('Latitude')}
                >
                  {getFieldDecorator(['lat' + this.props.locationID_UI], {
                    rules: [{ required: false }],
                  })(
                    <Input
                      id={'lat' + this.props.locationID_UI}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => this.inputLatitude(e)}
                      className="input-treasure"
                      // value={this.state.latitude}
                      onChange={(e: React.FocusEvent<HTMLInputElement>) => this.onChange(e)}
                      placeholder={b?.t('Number....')}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="roboto-regular"
                  label={b?.t('Longitude')}
                >
                  {getFieldDecorator(['lng' + this.props.locationID_UI], {
                    rules: [{ required: false }],
                  })(
                    <Input
                      id={'lng' + this.props.locationID_UI}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => this.inputLongitude(e)}
                      className="input-treasure"
                      // value={this.state.longitude}
                      onChange={(e: React.FocusEvent<HTMLInputElement>) => this.onChange(e)}
                      placeholder={b?.t('Number....')}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* Number of units, messaging radius and new messaging radius */}
          {'this.props.openLocationUIs[this.props.locationID_UI]' ? (
            <>
              <Row>
                <Row type="flex" justify="space-between" align="middle">
                  {this.props.treasureType === sticker ? (
                    <Typography.Text className="required-field">
                      {b?.t('Number of stickers')}
                    </Typography.Text>
                  ) : this.props.treasureType == flash ? (
                    <Typography.Text className="required-field">
                      {b?.t('Number of Flash Units')}
                    </Typography.Text>
                  ) : <Typography.Text className="required-field">
                    {b?.t('Number of units')}
                  </Typography.Text>


                  }
                  <Row type="flex">
                    <Button className="location-button" onClick={this.calculateNew}>
                      <img src={locationRefresh} />
                    </Button>
                    <Button className="location-button">
                      <img src={locationUndo} />
                    </Button>
                  </Row>
                </Row>
              </Row>
              <Form.Item
                className="ant-row ant-form-item roboto-regular"
                style={{
                  justifyContent: 'start',
                   }}
                    >
                   {getFieldDecorator(['numberOfUnits' + this.props.locationID_UI], {
                  rules: [
                    {
                      required: true,
                      message: b?.t('Please add at least minimum unit')
                    },
                  ],
                    })(
                    <InputNumber
                    required
                    className="input-number"
                    onChange={this.handleInput}
                   // onBlur={(e) => this.handleInput(e)}
                    placeholder={b?.t('Number....')}
                    min={this.renderContentmin()}
                    max={this.renderContent()}
                    disabled={isTreasureRunning}
                  />
                )}

                {/* <Col className='size12 font-bold mt-5' style={{lineHeight:'normal'}}>Range: 500 - 10000</Col> */}
                {this.props.treasureType > sticker &&
                  this.props.role < roleAdmin &&
                  treasure.price ? (
                  <Typography.Text style={{ marginLeft: '5px' }}>
                    {this.props.locations[this.props.locationID_UI].locationPrice
                      ? (
                        this.props.locations[this.props.locationID_UI].numberOfUnits *
                        treasure.price
                      ).toFixed(2)
                      : 0}{' '}
                    {b?.t('lCash')}
                  </Typography.Text>
                ) : null}
              </Form.Item>
              {this.props.treasureType == flash &&
                <Col className='size12 font-bold mt-5' style={{ lineHeight: 'normal' }}>{this.renderContentmin()}~{this.renderContent()} based on Radius setting

                  <div className="infoTip">
                    <span
                      className="infoTip__icon"
                      data-for="soclose"
                      data-tip="8"
                    >
                      &nbsp;
                    </span>
                    <ReactTooltip
                      id="soclose"
                      getContent={(dataTip) => (
                        <div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                          </p>
                        </div>
                      )}
                      effect="solid"
                      delayHide={500}
                      delayShow={500}
                      delayUpdate={500}
                      place={'right'}
                      border={true}
                      type={'light'}
                    />
                  </div>
                </Col>
              }
            </>
          ) : null}
        </div>

        {additional_prize == '2'||prize_type=='2' ? <div className='fx-jcc mt-15'>
          <button  onClick={this.onadditionalprizes}className='ant-btn buttons purple-gradient-right btn-block'>More Points are set <img height={16} src={rightcheckiconwhite} alt="icon" /> </button>
        </div>
          : additional_prize == '1' ||prize_type=='1'? <div className='fx-jcc mt-15'>
            <button onClick={this.onadditionalprizes} className='ant-btn buttons purple-gradient-right btn-block'>Physical Goods are set <img height={16} src={rightcheckiconwhite} alt="icon" /> </button>
          </div>
            : this.props.treasureType == flash?
              (<div className='fx-jcc mt-10'>
                <Button shape='round' onClick={this.onadditionalprizes}>Additional Prizes as an option</Button>
              </div>) : ''}
      </>
    );

  }

}
export default connect(mapStateToProps, actionCreators)(LocationForm);
