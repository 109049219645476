import React, { useState } from 'react';
import { Row, Col, Typography, Modal } from 'antd';
import ReactPlayer from 'react-player';
import { badgeFirst, badgeSecond, badgeThird, playYellow, likesYellow } from '../../../assets';
import * as contestStrings from '../../../lib/strings/contest';
import { baseURL } from '../../../lib/config';
import PropTypes from 'prop-types';

function Winner(props) {
  const {
    language,
    place,
    username,
    prize,
    qualifyingVotes,
    finalVotes,
    visible,
    userAvatar,
    qualifyingVideo,
    finalVideo,
  } = props;

  const [showVideo, setShowVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');

  const badgeSelector = (place: number) => {
    switch (place) {
      case 1:
        return badgeFirst;
      case 2:
        return badgeSecond;
      case 3:
        return badgeThird;
      default:
        return badgeFirst;
    }
  };

  const marginTopSelector = (place: number) => {
    switch (place) {
      case 1:
        return { marginTop: 0 };
      case 2:
        return { marginTop: 25 };
      case 3:
        return { marginTop: 50 };
      default:
        return { marginTop: 0 };
    }
  };

  const showVideoHandler = (event) => {
    if (event.target.id === 'finalVideo') {
      setSelectedVideo(finalVideo);
    } else {
      setSelectedVideo(qualifyingVideo);
    }
    setShowVideo(true);
  };

  const hideVideoHandler = () => {
    setShowVideo(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <Row
        type="flex"
        justify="space-around"
        className="contest-winner"
        style={marginTopSelector(place)}
      >
        <Col>
          <Row type="flex" justify="center">
            <Col>
              <img src={badgeSelector(place)} />
            </Col>
          </Row>
          <Row>
            <Col className="medal-container">
              <Row type="flex" justify="center">
                <Col>
                  <img className="profile-img" src={userAvatar} />
                </Col>
              </Row>
              <Row type="flex" justify="center" style={{ marginTop: 12 }}>
                <Col>
                  <Typography.Text className="text-username">{username}</Typography.Text>
                </Col>
              </Row>
              <Row type="flex" justify="center" style={{ marginTop: 5 }}>
                <Col>
                  <Typography.Text className="text-prize">{prize}</Typography.Text>
                </Col>
              </Row>

              <Row
                type="flex"
                align="middle"
                justify="space-between"
                style={{ marginTop: 8, paddingLeft: 17, paddingRight: 17 }}
                gutter={[4, 0]}
              >
                <Col>
                  <Row type="flex">
                    <Col>{contestStrings.qualifying[language]}</Col>
                  </Row>
                  <Row type="flex">
                    <Col>
                      <img src={likesYellow} />
                    </Col>
                    <Col>{qualifyingVotes}</Col>
                  </Row>
                </Col>
                <Col>
                  <img
                    id="qualifyingVideo"
                    className="play-button"
                    src={playYellow}
                    onClick={showVideoHandler}
                  />
                </Col>
              </Row>

              <Row
                type="flex"
                align="middle"
                justify="space-between"
                style={{ marginTop: 12, paddingLeft: 17, paddingRight: 17, paddingBottom: 8 }}
                gutter={[4, 0]}
              >
                <Col>
                  <Row type="flex">
                    <Col>{contestStrings.final[language]}</Col>
                  </Row>
                  <Row type="flex">
                    <Col>
                      <img src={likesYellow} />
                    </Col>
                    <Col>{finalVotes}</Col>
                  </Row>
                </Col>
                <Col>
                  <img
                    id="finalVideo"
                    className="play-button"
                    src={playYellow}
                    onClick={showVideoHandler}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        visible={showVideo}
        footer={null}
        centered
        onCancel={hideVideoHandler}
        className="resize-modal-to-children"
        bodyStyle={{ padding: '0px' }}
      >
        <Row type="flex" justify="center" className="video-player">
          <Col style={{ flex: 1, maxHeight: '60vh' }}>
            <ReactPlayer url={selectedVideo} controls pip />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

Winner.propTypes = {
  language: PropTypes.string.isRequired,
  place: PropTypes.number,
  username: PropTypes.string,
  prize: PropTypes.string,
  qualifyingVotes: PropTypes.number,
  finalVotes: PropTypes.number,
  visible: PropTypes.bool,
  userAvatar: PropTypes.string,
  qualifyingVideo: PropTypes.string,
  finalVideo: PropTypes.string,
};

Winner.defaultProps = {
  language: 'EN',
  place: 1,
  visible: true,
};

export default Winner;
