import { DatePicker, Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';
import React from 'react';
import calendar from '../../../../../src/assets/icon_date.svg';
import { connect } from 'react-redux';
import { notification } from 'antd';
import moment from 'moment';
import {b} from '../../../dashboard/menu/i18next'
import {
  getDomeAdminPerformanceApi,
  postDomeAdminPerformanceApi,
  putDomeAdminPerformanceApi,
} from '../../../../services/domeService';
import { throttle } from 'throttle-debounce';
import closeIcon from '../../../../assets/closeXsmall.svg';

const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class DomePerformance extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      title: '',
      description: '',
      start_date: '',
      end_date: '',
      video_type: '',
      live_time: '01:00PM , 03:00 PM',
      performance_id: null,
    };
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  callDataApi = async () => {
    const profile =  await this.getContext();

    let id = profile.admin_dome.ID;
    const res =  await getDomeAdminPerformanceApi(id);
    if (res && res.length !== 0) {
      console.log(res);
      const {
        title,
        description,
        start_date,
        end_date,
        video_type,
        live_time,
        id,
      } = res[0];
      this.setState({
        title,
        description,
        start_date: moment(start_date),
        end_date: moment(end_date),
        video_type,
        live_time,
        performance_id: id,
      });
    }
  };

  componentDidMount(): void {
    this.callDataApi();
  }

  onChangeHandler = ({ target: { name, value } }) => {
    if (value.length <= 100) {
      this.setState({
        [name]: value,
      });
    }
  };

  onDateChange = (e: moment.Moment, name: string) => {
    this.setState({
      [name]: e,
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current <= moment(this.state.start_date).add(1, 'days');
  };
  disableStartDate = (current) => {
    // Can not select days before today and today
    return current > moment(this.state.end_date).subtract(1, 'days');
  };

  onVideoTypeChange = (e, val) => {
    this.setState({
      video_type: val,
    });
  };

  publish = throttle(
    5000,
    async () => {
      const {
        title,
        description,
        start_date,
        end_date,
        video_type,
        live_time,
        performance_id,
      } = this.state;

      if (!title) {
        showErrorMessage(b?.t('Title Required'));
        return;
      }
      if (!description) {
        showErrorMessage(b?.t('Description Required'));
        return;
      }
      if (!start_date) {
        showErrorMessage(b?.t('Start Date Required'));
        return;
      }
      if (!end_date) {
        showErrorMessage(b?.t('End Date Required'));
        return;
      }
      if (!video_type) {
        showErrorMessage(b?.t('Format Required'));
        return;
      }

      let formValues: any = {
        title,
        description,
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),

        video_type,
      };

      if (video_type === 2) {
        formValues.live_time = live_time || '01:00PM , 03:00 PM';
      }
      const profile =  await this.getContext();

      let id = profile.admin_dome.ID;

      if (performance_id) {
         await putDomeAdminPerformanceApi(formValues, id, performance_id);
      } else {
         await postDomeAdminPerformanceApi(formValues, id);
         await this.callDataApi();
      }
    },
    {
      noTrailing: true,
    }
  );

  renderStatus = () => {
    if (this.state.start_date && this.state.end_date) {
      if (
        moment(this.state.start_date).isBefore(moment(new Date())) &&
        moment(new Date()).isBefore(moment(this.state.end_date))
        //   ||
        // (moment(this.state.start_date).isAfter(moment(new Date())) &&
        //   moment(new Date()).isBefore(moment(this.state.end_date)))
      ) {
        return <i className="status running"></i>;
      }

      if (moment(this.state.start_date).isAfter(moment(new Date()))) {
        return <i className="status pending"></i>;
      }
      if (moment(new Date()).isAfter(moment(this.state.end_date))) {
        return <i className="status finished"></i>;
      }
    }

    return <i className="status notNow"></i>;
  };

  clearData = () => {
    this.setState({
      title: '',
      description: '',
      start_date: '',
      end_date: '',
      video_type: '',
    });
  };

  onClose = () => {
    this.props.closePerformance();
  };

  render() {
    const {translation}=this.props
    const context = this.context;
    return (
      <>
        <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
          <div className="third-card">
            <div className="domeAddContainer scrollSection">
              <div id="arshow-tab" className="tab-pane">
                <div className="fx-jcsb-aic mb-10 alignment">
                  {/* <div>
                            <i className="blueBox mr-5"></i>
                            <span className="text-primary mr-5"> Arena Performance Schedule</span>
                        </div> */}
                  <h4
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    className="text-primary font-bold"
                  >
                    <div>
                      <i className="blueBox"></i>{translation('Arena Performance Schedule')} 
                      <div className="infoTip">
                        <span className="infoTip__icon">&nbsp;</span>
                        <div className="infoTip__tooltip">
                          <p className="size12 mb-0 font-regular">
                          {translation('Arena can be activated through this Performance Schedule Setting')} .
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <i onClick={this.onClose} className="iconx cursor">
                        <img src={closeIcon} alt="Info" />
                      </i>
                    </div>
                  </h4>
                  <div className="performance-status">
                    <span className="text-primary mr-5">
                      <i className="status running"></i>{translation('Running')}
                    </span>
                    <span className="text-primary mr-5">
                      <i className="status pending"></i> {translation('Pending')}
                    </span>
                    <span className="text-primary">
                      <i className="status finished"></i> {translation('Finished')}
                    </span>
                  </div>
                </div>
              </div>
              <h4 className="text-primary ">
              {translation('Currently a video cloud with URL can be published on the Arena in beta test')}
              </h4>

              <div
                style={{
                  border: '2px solid grey',
                  minHeight: '20rem',
                  padding: '1rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  
                  <label className="fileLabel fx col col-3">
                    <span className='mr-5'>{this.renderStatus()}</span> 
                    {translation('Title')}
                  </label>
                  <div className="col col-9">
                    <input
                      onChange={this.onChangeHandler}
                      name="title"
                      type="text"
                      data-index="-1"
                      value={this.state.title}
                      className="form-control xss rd-0"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  <label className="fileLabel col col-3">{translation('Description')}:</label>
                  <div style={{ position: 'relative' }} className="col col-9">
                    <textarea
                      rows={4}
                      onChange={this.onChangeHandler}
                      name="description"
                      data-index="-1"
                      value={this.state.description}
                      className="form-control xss rd-0"
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 24,
                        color: `${
                          this.state.description.length < 95
                            ? 'lightgrey'
                            : 'red'
                        }`,
                      }}
                      >
                      {100 - this.state.description.length}/100
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{width:'100%'}}>
                    {' '}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.4rem',
                      }}
                    >
                      <label className='fileLabel fx col col-3'>
                        {translation('Start')}:
                      </label>
                      <div className='col col-9'>
                        <DatePicker
                          style={{
                            marginRight: '1rem',
                            width: '161px',
                          }}
                          format={'MM/DD/YY'}
                          name="registrationStartDate"
                          suffixIcon={<img src={calendar} />}
                          onChange={(e) => this.onDateChange(e, 'start_date')}
                          disabledDate={
                            this.state.end_date && this.disableStartDate
                          }
                          dropdownClassName="arShow__datepicker"
                          defaultValue={this.state.start_date}
                          value={this.state.start_date}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.4rem',
                      }}
                    >
                      <label className='fileLabel fx col col-3'>
                       {translation('End')}:
                      </label>
                      <div className='col col-9'>
                        <DatePicker
                          style={{
                            width: '162px',
                          }}
                          format={'MM/DD/YY'}
                          name="registrationStartDate"
                          suffixIcon={<img src={calendar} />}
                          onChange={(e) => this.onDateChange(e, 'end_date')}
                          disabledDate={
                            this.state.start_date && this.disabledDate
                          }
                          dropdownClassName="arShow__datepicker"
                          defaultValue={this.state.end_date}
                          value={this.state.end_date}
                        />
                      </div>
                    </div>
                    <div className='fx-aic mb-10'>
                      <span className='fileLabel fx col col-3'>
                        {translation('Format')}:
                      </span>
                      <label className='fx col col-9'>
                      {translation('Video Cloud')}
                        <input
                          style={{
                            marginLeft: '0.5rem',
                          }}
                          onChange={(e) => this.onVideoTypeChange(e, 1)}
                          type="checkbox"
                          checked={this.state.video_type === 1}
                        />
                      </label>
                    </div>
                    <div className='fx-aic'>
                      <span className='fileLabel fx col col-4'>{translation('Live (pending)')} </span>
                      <label className='col col-8'>
                        <input
                          disabled
                          style={{
                            marginLeft: '0.5rem',
                            cursor: 'not-allowed',
                          }}
                          onChange={(e) => this.onVideoTypeChange(e, 2)}
                          type="checkbox"
                          checked={this.state.video_type === 2}
                        />
                      </label>
                    </div>
                  </div>
                  <div>
                    {this.state.video_type === 2 && (
                      <div>
                        <div
                          style={{
                            height: '119px',
                            marginTop: '30px',
                            border: '1px solid black',
                          }}
                          className="col col-12"
                        >
                          {/* <span>Time</span> */}
                          <input
                            // onChange={(e) => props.inputHandler(e, props.index)}
                            name="notice_title"
                            type="text"
                            data-index="-1"
                            value="01:00 PM, 03:00 PM"
                            className="form-control xss rd-0"
                            readOnly
                            style={{ border: 0 }}
                          />
                          {/* <span> Less than 5 mins a day</span> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '2rem',
                  }}
                  className="grid-row fx-jcc"
                >
                  <div className="col col-6 fx-jcsb">
                    <button
                      onClick={this.clearData}
                      className="ant-btn primary-ghost-btn xs"
                    >
                     {translation('Cancel')} 
                    </button>
                    <button
                      onClick={this.publish}
                      //   disabled={this.state.loader ? true : false}
                      className="ant-btn yellow-gradient xs"
                    >
                      {/* {this.state.loader ? "Loading..." : "Edit"} */}
                      {translation('Edit')}  
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (DomePerformance);
