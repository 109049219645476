import React from 'react';
import { Row, Col, Spin, Typography } from 'antd';
import ContestantsListItem from './ContestantsListItem';
import * as contestStrings from '../../../lib/strings/contest';
import PropTypes from 'prop-types';

function ContestantsList(props) {
  const { loading, data, round, selectedContestantIndex, onClick, language } = props;

  if (loading) {
    return (
      <Row type="flex" justify="center" className="contestants-user-list">
        <Spin size="large" />
      </Row>
    );
  }

  const contestantOrContestants =
    data.length === 0
      ? contestStrings.contestantNumber[language]
      : contestStrings.contestantsNumber[language];

  return (
    <Row type="flex" justify="center" className="contestants-user-list">
      <Col span={24}>
        <Typography.Text className="contestant-count">
          {data.length} {contestantOrContestants}
        </Typography.Text>
        {data.map((contestant, index) => {
          return (
            <ContestantsListItem
              onClick={onClick}
              key={contestant.contestantID}
              index={index}
              username={contestant.username}
              selected={selectedContestantIndex === index}
              votes={
                round === 'qualifying'
                  ? contestant.qualifyingRoundVotes
                  : contestant.finalRoundVotes
              }
              profilePicUrl={contestant.avatar?.thumbnail?.nameOnServer}
            />
          );
        })}
      </Col>
    </Row>
  );
}

ContestantsList.propTypes = {
  language: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  onClick: PropTypes.func,
  selectedContestantIndex: PropTypes.number,
  round: PropTypes.oneOf(['qualifying', 'final']),
};

ContestantsList.defaultProps = {
  loading: true,
  data: [],
};

export default ContestantsList;
