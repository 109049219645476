import { treasureConstants } from '../constants/treasureConstants';
import { locationConstants } from '../constants/locationConstants';
import * as constant from '../lib/const';
import { LOCAL_STORAGE_USER_LOCATION } from '../lib/config';
import { menuConstants } from '../constants/menuConstants';
import { cloneDeep } from 'lodash';

export const initialStateTreasure = {
  isEditing: false,
  onBack: false,
  characterID: 1,
  totalPrice: 0,
  messagingOptions: [],
  durationUnit: 'h',
  kitID: 0,
  hasImagePropsUpdateFailed: false,
  kitType: '',
  hashtags: [],
  color: '#000000',
  useAdMaker: false,
  stickerType: 'new',
  flashType:'new',
  mediaPath: {},
  logoUrl: '',
  cuponUrl: '',
  uploadReuseMakeNew: 'upload',
  availableDiscounts: [],
  adKitMakeNew: false,
  adKitDataDetails: {},
  loading: false,
  ldiscountExpirationDate: null,
  image: null,
  imgUrl: '',
};

export function treasure(state = initialStateTreasure, action: any) {
  let cloneState = { ...state };
  switch (action.type) {
    case treasureConstants.INITIAL:
      Object.assign(cloneState, action.response);
      return cloneState;
    case treasureConstants.TREASURE_TYPE:
      return {
        ...state,
        treasureType: action.treasureType,
      };
    case treasureConstants.TREASURE_NAME:
      return {
        ...state,
        treasureName: action.treasureName,
      };
    case treasureConstants.HASHTAG:
      cloneState.hashtags.push(action.hashtags.hashtag);
      return cloneState;
    case treasureConstants.STICKER_IMAGE:
      return {
        ...state,
        image: action.image,
        imgUrl: action.imgUrl,
        isVideo: action.isVideo,
      };
    case treasureConstants.CHOSEN_STICKER:
      return {
        ...state,
        imgUrl: action.imgUrl,
        stickerID: action.stickerID,
        sectionID: action.sectionID,
        sectionPosition: action.position,
      };
    case treasureConstants.FAILED_POST:
      return {
        ...state,
        hasImagePropsUpdateFailed: true,
      };
    case treasureConstants.SUCCESSFUL_POST:
      return {
        ...state,
        hasImagePropsUpdateFailed: false,
      };
    case treasureConstants.UPDATE_POSITION:
      return {
        ...state,
        sectionPosition: action.position,
      };
    case treasureConstants.UPDATE_SECTION:
      return {
        ...state,
        sectionID: action.sectionID,
        takenPositions: action.takenPositions,
      };
    case treasureConstants.DURATION:
      return {
        ...state,
        duration: action.duration,
      };
    case treasureConstants.DURATION_UNIT:
      return {
        ...state,
        durationUnit: action.durationUnit,
      };
    case treasureConstants.MSG_RULE:
      return {
        ...state,
        messagingOptions: action.messagingRule,
      };
    case treasureConstants.TOTAL_NUMBER_OF_UNITS:
      return {
        ...state,
        totalNumberOfUnits: action.totalNumberOfUnits,
        totalPrice: action.totalPrice,
      };
    case treasureConstants.SELECTED_CHARACTER:
      return {
        ...state,
        characterID: action.characterID,
      };
    case treasureConstants.TYPE_OF_STICKER:
      return {
        ...state,
        stickerType: action.typeOfSticker,
      };
      case treasureConstants.TYPE_OF_FLASH:
        return {
          ...state,
          flashType: action.typeOfflash,
        };
    case locationConstants.RESET_REDUCERS:
      initialStateTreasure.hashtags = [];
      return initialStateTreasure;
    case treasureConstants.TREASURE_POINTS:
      let image = null;
      let imgUrl = '';
      if (action.kitType === state.kitType) {
        image = state.image;
        imgUrl = state.imgUrl;
      }
      return {
        ...state,
        pointsPerUnit: action.pointsPerUnit,
        totalPrice: action.totalPrice,
        price: action.price,
        kitTypeID: action.kitTypeID,
        kitType: action.kitType,
        image: image,
        imgUrl: imgUrl,
      };
    case menuConstants.EDIT_DRAFT:
      return {
        ...state,
        isEditing: true,
        // ...action.treasure,
        // characterID: 1,
        // hashtags: [...state.hashtags]
      };
    case treasureConstants.USE_AD_MAKER:
      return {
        ...state,
        useAdMaker: action.useAdMaker,
      };
    case treasureConstants.AD_MAKER_INPUT:
      return {
        ...state,
        [action.name]: action.value,
      };
    case treasureConstants.PICK_COLOR:
      return {
        ...state,
        color: action.color,
      };
    case treasureConstants.CREATE_AD:
      return {
        ...state,
        image: action.ad,
        imgUrl: action.base64,
      };
    case treasureConstants.UPDATE_MEDIA_PATH:
      const newMediaPath = { ...state.mediaPath, pic: {}, video: {} };
      return {
        ...state,
        mediaPath: { ...newMediaPath, ...action.payload },
      };
    case treasureConstants.TREASURE_BATCH_EDIT:
      return {
        ...state,
        ...action.payload,
      };
    case treasureConstants.TREASURE_REMOVE_HASHTAG:
      let indexToDelete;
      state.hashtags.find((elementObject, index) => {
        indexToDelete = index;
        return elementObject.ID === action.hashtag.ID;
      });
      const newHashtags = [
        ...state.hashtags.slice(0, indexToDelete),
        ...state.hashtags.slice(indexToDelete + 1),
      ];
      return { ...state, hashtags: newHashtags };
    default:
      return state;
  }
}

let initialAddress = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_LOCATION));
const initialState = initialAddress
  ? {
      lat: initialAddress.lat,
      lng: initialAddress.lng,
      radius: constant.DefaultRadius,
      zoom: constant.DefaultZoom,
      needReset: false,
    }
  : {
      lat: constant.DefaultLat,
      lng: constant.DefaultLng,
      radius: constant.DefaultRadius,
      zoom: constant.DefaultZoom,
      needReset: false,
    };

export function address(state = initialState, action: any) { 
  switch (action.type) {
    case locationConstants.ADDRESS:
      return {
        ...state,
        lat: action.lat,
        lng: action.lng,
      };
    case locationConstants.UPDATE_MAP_ADDRESS:
      const { lat, lng } = action.address;
      return {
        ...state,
        lat: lat,
        lng: lng,
      };
    case locationConstants.LOCATION_RADIUS:  
      return {
        ...state,
        zoom: action.zoom,
        radius: action.radius,
        needReset: action.needReset,
      };
    case locationConstants.MAP_INSTANCE:
      return {
        ...state,
        mapInstance: action.mapInstance,
      };
    case locationConstants.MAP_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
        radius: action.radius,
      };
    case locationConstants.SET_ADDRESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const initialLocations = [];
  export function locations(state: any = initialLocations, action: any) {
  let cloneState = [...state];
  switch (action.type) {
    case locationConstants.SET_LOCATIONS:
      return action.payload;

    case locationConstants.SELECT_LOCATION: {
      let bufferLocations = [...state];
      bufferLocations = bufferLocations.map((location) => ({ ...location, selected: false }));
      bufferLocations[action.index] = { ...bufferLocations[action.index], selected: true };

      return bufferLocations;
    }

    case locationConstants.ADD_ANOTHER_LOCATION: {
      let bufferLocations = [...state];
      bufferLocations = bufferLocations.map((location) => ({ ...location, selected: false }));
      bufferLocations = [...bufferLocations, action.payload];

      return bufferLocations;
    }
    case locationConstants.DELETE_LOCATION2: {
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    }
    case locationConstants.CHANGE_LOCATION: {
      let bufferLocations = [...state];
      bufferLocations[action.index] = {
        ...bufferLocations[action.index],
        ...action.payload,
      };
      return bufferLocations;
    }

    // old stuff
    case locationConstants.CREATE_LOCATION:
      cloneState.push(action.response);
      return cloneState;

    case locationConstants.NO_OF_UNITS: {
      let editedLocation = { ...cloneState[action.ID] };
      editedLocation.numberOfUnits = action.numberOfUnits;
      editedLocation.locationPrice = action.locationPrice;
      cloneState[action.ID] = editedLocation;
      return cloneState;
    }

    case locationConstants.UPDATE_LOCATION_ADDRESS: {
      let editedLocation = { ...cloneState[action.locationID_UI] };
      editedLocation.latitude = action.lat;
      editedLocation.longitude = action.lng;
      cloneState[action.locationID_UI] = editedLocation;
      return cloneState;
    }

    case locationConstants.UPDATE_LOCATION_ON_IDLE: {
      if (!state[action.locationID_UI]) {
        return state;
      }
      let editedLocation = { ...cloneState[action.locationID_UI] };
      editedLocation.latitude = action.lat;
      editedLocation.longitude = action.lng;
      cloneState[action.locationID_UI] = editedLocation;
      return cloneState;
    }

    case locationConstants.DELETE_LOCATION:
      const locationToRemove = action.locationID_UI;
      cloneState.splice(locationToRemove, 1);
      return cloneState;

    case locationConstants.LOCATION_RADIUS: {     
      if (!state[action.locationID_UI]) {
        return state;
      }
      let editedLocation = { ...cloneState[action.locationID_UI] };
      editedLocation.radius = action.radius;
      cloneState[action.locationID_UI] = editedLocation;
      return cloneState;
    }

    case locationConstants.LOCATION_RADIUS_INPUT: {
      let editedLocation = { ...cloneState[action.locationID_UI] };
      editedLocation.radius = action.locationRadius;
      cloneState[action.locationID_UI] = editedLocation;
      return cloneState;
    }
    case locationConstants.RESET_REDUCERS:
      return [];
    case locationConstants.LOCATION_FROM_DRAFT:
      let newLocations = action.locations.slice();
      newLocations.forEach((location: any) => {
        location.latitude = Number(location.latitude);
        location.longitude = Number(location.longitude);
        location.radius = Number(location.radius);
        location.numberOfUnits = Number(location.unitCopies);
        delete location.unitCopies;
      });
      return newLocations;
    default:
      return state;
  }
}

export function messageLocations(state: any = [], action: any) {
  switch (action.type) {
    case locationConstants.CREATE_MSG_LOCATION: {
      let cloneState = [...state];
      cloneState.push(action.response);
      return cloneState;
    }
    case locationConstants.UPDATE_MSG_LOCATION: {
      let cloneState = cloneDeep(state);
      cloneState.forEach((messageLocation: any) => {
        if (messageLocation.locationID === action.locationID) {
          messageLocation.latitude = action.lat;
          messageLocation.longitude = action.lng;
        }
      });
      return cloneState;
    }

    case locationConstants.UPDATE_MSG_RADIUS: {
      let cloneState = [...state];
      let editedMessageLocation = { ...cloneState[action.messageLocationID_UI] };
      editedMessageLocation.radius = action.messagingRadius;
      cloneState[action.messageLocationID_UI] = editedMessageLocation;
      return cloneState;
    }

    case locationConstants.UPDATE_ALL_MSG_RADIUS: {
      let cloneState = cloneDeep(state);
      cloneState.forEach((messageLocation: any) => {
        if (messageLocation.locationID === action.selectedLocation) {
          messageLocation.radius = action.messagingRadius;
        }
      });
      return cloneState;
    }
    case locationConstants.DELETE_MSG_RADIUS: {
      let cloneState = [...state];
      cloneState.splice(action.messageLocationID_UI, 1);
      return cloneState;
    }

    case locationConstants.DELETE_ALL_MSG_RADIUS: {
      let cloneState = cloneDeep(state);
      cloneState.forEach((messageLocation: any) => {
        if (action.locationID === messageLocation.locationID) {
          cloneState.splice(action.locationID_UI, 1);
        }
      });
      return cloneState;
    }

    case locationConstants.MSG_LOCATION_FROM_DRAFT:
      let newMsgLocations = action.messageLocations.slice();
      newMsgLocations.forEach((messageLocation: any) => {
        messageLocation.latitude = messageLocation.latitude;
        messageLocation.longitude = messageLocation.longitude;
        messageLocation.radius = messageLocation.radius;
      });
      return newMsgLocations;
    case locationConstants.RESET_REDUCERS:
      return [];
    case locationConstants.CLEAR_ALL_MSG_RADIUS:
      return [];
    default:
      return state;
  }
}

export function selectedLocation(state: number = 0, action: any) {
  switch (action.type) {
    case locationConstants.UPDATE_SELECTED:
      return action.last - 1;
    case locationConstants.SELECTED:
      return action.selected;
    case locationConstants.RESET_REDUCERS:
      return 0;
    case locationConstants.CHANGE_AFTER_DELETE:
      return action.newSelected;
    default:
      return state;
  }
}

export function treasureUnitsRandomNumbers(state: any = {}, action: any) {
  let cloneState = { ...state };
  switch (action.type) {
    case locationConstants.CALCULATED_UNITS:
      cloneState[action.locationID] = action.latLng;
      return {
        ...cloneState,
        [action.locationID]: action.latLng,
      };
    case locationConstants.DELETE_UNITS:
      const newState = Object.keys(cloneState).reduce((newObj: any, key: any) => {
        const item = cloneState[key];
        if (key != action.locationID_UI) newObj[Object.keys(newObj).length] = item;
        return newObj;
      }, {});

      return newState;
    case locationConstants.RESET_REDUCERS:
      return {};
    default:
      return state;
  }
}

export function treasureUnitsLocation(state: any = {}, action: any) {
  let cloneState = { ...state };
  switch (action.type) {
    case locationConstants.STORE_UNITS:
      cloneState[action.locationID_UI] = action.treasureUnits;
      return {
        ...cloneState,
      };
    case locationConstants.DELETE_UNITS:
      const newState = Object.keys(cloneState).reduce((newObj: any, key: any) => {
        const item = cloneState[key];
        if (key != action.locationID_UI) newObj[Object.keys(newObj).length] = item;
        return newObj;
      }, {});
      return newState;
    case locationConstants.UNITS_FROM_DRAFTS:
      cloneState[action.locationID_UI] = action.treasureUnits;
      return cloneState;
    case locationConstants.RESET_REDUCERS:
      return {};
    default:
      return state;
  }
}

export function treasureUnitsResponse(state: any = {}, action: any) {
  let cloneState = { ...state };
  switch (action.type) {
    case locationConstants.STORE_RESPONSE:
      cloneState[action.locationID_UI] = action.response;
      return cloneState;
    case locationConstants.DELETE_UNITS:
      const newState = Object.keys(cloneState).reduce((newObj: any, key: any) => {
        const item = cloneState[key];
        if (key != action.locationID_UI) newObj[Object.keys(newObj).length] = item;
        return newObj;
      }, {});
      return newState;
    case locationConstants.UNITS_FROM_DRAFTS:
      cloneState[action.locationID_UI] = action.treasureUnits;
      return state;
    case locationConstants.RESET_REDUCERS:
      return {};
    default:
      return state;
  }
}

export function treasureUnitsSent(state: any = [], action: any) {
  let cloneState = [...state];
  switch (action.type) {
    case locationConstants.NOT_SENT:
      cloneState.push(false);
      return cloneState;
    case locationConstants.ALREADY_SENT:
      cloneState[action.locationID_UI] = true;
      return cloneState;
    case locationConstants.DELETE_UNITS:
      cloneState.splice(action.locationID_UI, 1);
      return cloneState;
    case locationConstants.RESET_REDUCERS:
      return [];
    default:
      return state;
  }
}

export function characters(state: any = [], action: any) {
  switch (action.type) {
    case treasureConstants.SAVE_CHARACTERS:
      return action.characters;
    default:
      return state;
  }
}

export function isPaymentInitiated(state: boolean = false, action: any) {
  switch (action.type) {
    case treasureConstants.PAYMENT_INITIATED:
      return true;
    case treasureConstants.PAYMENT_COMPLETE:
      return false;
    case locationConstants.RESET_REDUCERS:
      return false;
    default:
      return state;
  }
}

export function treasureUnitCandy(state: Array<number> = [], action: any) {
  switch (action.type) {
    case treasureConstants.RANDOM_CANDY:
      return action.randomCandyNumbers;
    default:
      return state;
  }
}

export const initialTreasureErrors = {};
export function treasureErrors(state = initialTreasureErrors, action: any) {
  switch (action.type) {
    case treasureConstants.TREASURE_ERROR_ADD: {
      return { ...state, ...action.payload };
    }
    case treasureConstants.TREASURE_ERROR_RESET: {
      return initialTreasureErrors;
    }
    default:
      return state;
  }
}
