import { candyEventConstants } from '../constants/candyEventConstants';

export const initialCandyEvent = {
  isEditing: false,
  ID: null,
  contestantIndex: null,
  selectedCountryCode: '',
};
export function candyEvent(state = initialCandyEvent, action) {
  switch (action.type) {
    case candyEventConstants.CANDY_EVENT_RESET_STATE:
      return initialCandyEvent;
    case candyEventConstants.CANDY_EVENT_EDIT_PARAMETER:
      return { ...state, [action.parameterKey]: action.value };
    case candyEventConstants.CANDY_EVENT_BATCH_SET:
      return action.payload;
    default:
      return state;
  }
}
