import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import BackButtonLeftText from '../../../../components/BackButtonLeftText';
import MenuToggleSwitch from '../../../../components/MenuToggleSwitch';
import ContestantsReviewList from '../../../../components/contest/contestants/ContestantsReviewList';
import * as contestStrings from '../../../../lib/strings/contest';
import * as contestActions from '../../../../actions/contestActions';
import * as contestService from '../../../../services/contestService';
import * as menuActions from '../../../../actions/menuActions';
import * as subMenuConstants from '../../../../lib/SubMenuConstants';
import { menuConstants } from '../../../../constants/menuConstants';
import { showErrorMessage } from '../../../../services/sharedServices';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';

function ContestantsReview(props) {
  const {
    language,
    round,
    contestId,
    contestName,
    selectedContestantIndex,
    updateContestants,
    resetContestants,
    openContestantDetails,
    openContestDetails,
    closeSubmenu2,
  } = props;

  useEffect(() => {
    return () => {
      closeSubmenu2();
      resetContestants();
    };
  }, []);

  const onCloseHandler = () => {
    openContestDetails();
  };

  const contestantsReviewList: any = useQuery(
    ['ContestantsReviewList', { id: contestId, pageNumber: 1 }],
    contestService.getContestantsQueryReview,
    {
      onError: (error: any) => {
        showErrorMessage(serverError[language], error);
      },
    }
  );

  const onClickHandler = (index) => {
    console.log('onClickHandler');
    updateContestants({ selectedContestantIndex: index });
    openContestantDetails();
  };

  return (
    <Row className="partnerRequests-wrapper partnerRequests-text">
      <BackButtonLeftText label={contestName} onClick={onCloseHandler} />

      <Col className="scrollable">
        <ContestantsReviewList
          language={language}
          loading={contestantsReviewList.isLoading}
          data={contestantsReviewList.data?.pendingSignups}
          onClick={onClickHandler}
          selectedContestantIndex={selectedContestantIndex}
        />
      </Col>
    </Row>
  );
}

ContestantsReview.propTypes = {
  language: PropTypes.string.isRequired,
  contestId: PropTypes.number,
  contestName: PropTypes.string,
  selectedContestantIndex: PropTypes.number,
};

ContestantsReview.defaultProps = {};

const mapsStateToProps = (state) => ({
  language: state.authentication.language,
  contestId: state.contestDetails.ID,
  contestName: state.contestDetails.name,
  selectedContestantIndex: state.contestants.selectedContestantIndex,
});

const mapDispatchToProps = (dispatch) => ({
  updateContestants: (payload) => dispatch(contestActions.updateContestants(payload)),
  resetContestants: () => dispatch(contestActions.resetContestants()),
  openContestantDetails: () =>
    dispatch(menuActions.openSubmenu2(subMenuConstants.contestantReviewDetails)),
  closeSubmenu1: () => dispatch(menuActions.closeSubmenu1()),
  closeSubmenu2: () => dispatch(menuActions.closeSubmenu2()),
  openContestDetails: () =>
    dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu: subMenuConstants.contestDetails }),
});

export default connect(mapsStateToProps, mapDispatchToProps)(ContestantsReview);
