import { Row, Spin, notification } from 'antd';

import AppContext from '../../../../AppContext';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import burgerKing from '../../../../../src/assets/burgerKing.png';
import closeWhite from '../../../../../src/assets/closeWhite.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import { connect } from 'react-redux';
import {b} from '../../../dashboard/menu/i18next'
import {
  deleteDomeArenaApi,
  getDomeTypes,
  importDomeEditing,
} from '../../../../services/domeService';
import infoIcon from '../../../../../src/assets/icon_info.svg';

const showSuccessMessage = (title, description?) => {
  notification.success({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

const errorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description || ''}`,
    duration: 10,
  });
};

class DomeArena extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props: any) {
    super(props);
    this.state = {
      success: '',
      error: '',
      thisTime: true,
      color: this.props.color ? this.props.color : '',
      addr_name: '',
      cannotDeleteModal: false,
    };
  }

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  inputHandler = (e: any) => {
    this.setState({
      addr_name: e.target.value,
    });
    if (e.target.value) {
      this.setState({ thisTime: false });
    } else {
      this.setState({ thisTime: true });
    }
  };

  importImageUrl = async (e: any) => {
    const profile = await this.getContext();
    const id = profile.edit_current_dome.ID;
    const addr_name = profile.edit_current_dome.addr_name.trim();
    const import_addr_name = this.state.addr_name.trim();
    if (addr_name == import_addr_name) {
      errorMessage( b?.t('Import dome address name should not be same as current dome address name') );
      return false;
    }
    const type = 1;
    let importImageUrlData = await importDomeEditing(
      id,
      this.state.addr_name,
      type,
      this.state.color
    );
  };

  onDeleteApplication = async () => {
    let profile = await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    const addr_status = current_edit_dome.addr_status;
    if (addr_status) {
      this.setState({
        cannotDeleteModal: !this.state.cannotDeleteModal,
      });
    } else {
      await deleteDomeArenaApi(id);
      profile['arena__data'] = null;
      const domeSetting = await getDomeTypes(id);

      profile.domeTypeSetting = domeSetting.dome_type_setting;
      await this.updateContext(profile);
    }
    this.props.onArenaModalClose();
  };

  closeCannotDeleteModal = () => {
    this.setState({
      cannotDeleteModal: false,
    });
  };

  render() {
    const {translation}=this.props
    const context = this.context;
    const profile = context.profile;
    const arena__data = profile['arena__data'] || {};
    const title = arena__data?.title;
    const city = arena__data?.city;
    const background_image = arena__data?.background_image?.name;
    const signage_file = arena__data?.signage_file?.name;
    const url = arena__data?.url;
    return (
      <>
        <div id="tab-3" className="tab-pane ">
          {/* <div className="mb-10">
					<i className="blueBox mr-5"></i>
					<span className="text-primary mr-5">Arena editing</span>
					<i><img src={infoIcon} alt="Info" height="14" /></i>
				</div>
				<div className="grid-row sm mb-10">
					<div className="col col-1">&nbsp;</div>
					<div className="col col-11 border-bottom pb-10">
						<div className="fx-aic mb-10">
							<span className="size12 mr-5">Import</span>
							<input type="text" value="Dome Address to import" className="form-control xs" />
							<input type="text" value="" className="form-control xs ml-5" />
							<button className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
						</div>
						<div className="fx-aic">
							<span className="size12 mr-5">Not at this time</span>
							<input type="checkbox" />
						</div>
					</div>
				</div>*/}

          <div className="arena-content">
            <div className="mb-10">
              <i className="blueBox mr-5"></i>
              <span className="text-primary mr-5">{translation('Arena')}</span>
              <div className="infoTip">
                <span className="infoTip__icon" data-for="soclose" data-tip="8">
                  &nbsp;
                </span>
                <ReactTooltip
                  id="soclose"
                  getContent={(dataTip) => (
                    <div>
                      <p className="mb-0">
                        {translation('Make the 2D icon for the app')}
                        
                        {/* Upload an image to show the
											Program schedule and implementation
											is controlled in Dome Admin Performance Schedule. */}
                      </p>
                    </div>
                  )}
                  effect="solid"
                  delayHide={500}
                  delayShow={500}
                  delayUpdate={500}
                  place={'right'}
                  border={true}
                  type={'light'}
                />
              </div>
            </div>
            {/*
					<div className="grid-row border-bottom pb-10">
						<div className="col col-2"></div>
						<div className="col col-10">
							<div className="grid-row sm">
								<div className="col col-2">
									<h4 className="text-primary">Import</h4>
								</div>
								<div className="col col-10">
									<label>Dome Name to import</label>
									<div className="fx-jcsb-aic mb-5">
										<input placeholder="Enter Dome Name" onChange={this.inputHandler} type="text" value={this.state.addr_name} className="form-control xs ml-5" />
										<button onClick={this.importImageUrl} className="ant-btn primary-ghost-btn xs ml-5">Verify</button>
									</div>
									<div className="fx-aic">
										<span className="size12 mr-5">Not at this time</span>
										<input type="checkbox" checked={this.state.thisTime} />
									</div>
								</div>
							</div>
						</div>
					</div> */}

            <div className="arena2d-ent-container">
              <div className="fx-jcsb-aic mt-5 mb-5">
                <h4 className="mb-0 size12 text-primary">
                 {translation('Arena 2D Icon')}  &amp; {translation('Entrance Signage')}
                </h4>
                <div className="fx-aic">
                  <button className="iconx xs">
                    <img
                      onClick={this.props.onArArenaEditClick}
                      src={editIcon}
                      alt="Edit"
                      height={14}
                    />
                  </button>
                  <button
                    onClick={this.onDeleteApplication}
                    className="iconx xs"
                  >
                    <img src={deleteIcon} alt="Delete" height={14} />
                  </button>
                </div>
              </div>

              <ul className="editingList noStyle">
                <li className="editingList__item">
                  <div className="editingList__item__row">
                    <div className="editingList__item__row__left w-full">
                      <div
                        style={{
                          marginBottom: '3px',
                          fontWeight: 600,
                          color: 'black',
                        }}
                        className="grid-row xs"
                      >
                        <div className="col col-6">
                          <label className="fileLabel">
                           {translation("Background Image File")} 
                          </label>
                        </div>
                        <div className="col col-5">
                          <label className="fileBtn">
                            <span className="fileBtn__title">
                              {background_image}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          marginBottom: '3px',
                          fontWeight: 600,
                          color: 'black',
                        }}
                        className="grid-row xs"
                      >
                        <div className="col col-6">
                          <label className="fileLabel">{translation('Title')}</label>
                        </div>
                        <div className="col col-5">
                          <div className="fileBtn">
                            <label className="fileBtn">
                              <span className="fileBtn__title">{title}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginBottom: '3px',
                          fontWeight: 600,
                          color: 'black',
                        }}
                        className="grid-row xs"
                      >
                        <div className="col col-6">
                          <label className="fileLabel">
                           {translation('Name of city, district, location')} {' '}
                          </label>
                        </div>
                        <div className="col col-5">
                          <div className="fileBtn">
                            <label className="fileBtn">
                              <span className="fileBtn__title">{city}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginBottom: '3px',
                          fontWeight: 600,
                          color: 'black',
                        }}
                        className="grid-row xs"
                      >
                        <div className="col col-6">
                          <label className="fileLabel">{translation('Signage file')}</label>
                        </div>
                        <div className="col col-5">
                          <div className="fileBtn">
                            <label className="fileBtn">
                              <span className="fileBtn__title">
                                {signage_file}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginBottom: '3px',
                          fontWeight: 600,
                          color: 'black',
                        }}
                        className="grid-row xs"
                      >
                        <div className="col col-6">
                          <label className="fileLabel">{translation('Video Url')}</label>
                        </div>
                        <div className="col col-5">
                          <div className="fileBtn">
                            <label className="fileBtn">
                              <span className="fileBtn__title">{url}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.cannotDeleteModal ? 'overlay opened' : 'overlay'
          }
        ></div>
        <div
          className={
            !this.state.cannotDeleteModal
              ? 'custom-modal xs'
              : 'custom-modal xs opened'
          }
        >
          <div
            className="custom-modal__close"
            onClick={this.closeCannotDeleteModal}
          ></div>
          <div className="custom-modal__body">
            <p>
              {translation("It can be deleted after unpublishing this Dome or just hidden in Dome settings")}
              
            </p>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(DomeArena);
