import beauty1 from './beauty1.JPG';
import beauty2 from './beauty2.JPG';
import beauty3 from './beauty3.JPG';
import beauty4 from './beauty4.JPG';
import beauty5 from './beauty5.JPG';
import beauty6 from './beauty6.JPG';
import beauty7 from './beauty7.JPG';
import beauty8 from './beauty8.JPG';
import beauty9 from './beauty9.JPG';
import beauty10 from './beauty10.JPG';

import beauty1_1 from './beauty1.1.JPG';
import beauty2_1 from './beauty2.1.JPG';
import beauty3_1 from './beauty3.1.JPG';
import beauty4_1 from './beauty4.1.JPG';
import beauty5_1 from './beauty5.1.JPG';
import beauty6_1 from './beauty6.1.JPG';
import beauty7_1 from './beauty7.1.JPG';
import beauty8_1 from './beauty8.1.JPG';
import beauty9_1 from './beauty9.1.JPG';
import beauty10_1 from './beauty10.1.JPG';

export const beautyportrait = [
  { id: 1, src: beauty1 },
  { id: 2, src: beauty2 },
  { id: 3, src: beauty3 },
  { id: 4, src: beauty4 },
  { id: 5, src: beauty5 },
  { id: 6, src: beauty6 },
  { id: 7, src: beauty7 },
  { id: 8, src: beauty8 },
  { id: 9, src: beauty9 },
  { id: 10, src: beauty10 },
];

export const beautylandscape = [
  { id: 1, src: beauty1_1 },
  { id: 2, src: beauty2_1 },
  { id: 3, src: beauty3_1 },
  { id: 4, src: beauty4_1 },
  { id: 5, src: beauty5_1 },
  { id: 6, src: beauty6_1 },
  { id: 7, src: beauty7_1 },
  { id: 8, src: beauty8_1 },
  { id: 9, src: beauty9_1 },
  { id: 10, src: beauty10_1 },
];
