export const parseStoryLocalization = (data: Array<any>, language: string) => {
  const languageData = data.filter((objectData) => objectData.lang_code === language);
  const parametarNames = languageData.map((objectData) => objectData.param_name);
  let parametars = {};
  parametarNames.map((parametarName) => {
    parametars = {
      ...parametars,
      [parametarName]: languageData.find((objectData) => objectData.param_name === parametarName)
        .localized_val,
    };
  });
  return parametars;
};

export const languageList = (data: Array<any>) => {
  let languages = {};
  data.map((element) => {
    languages = { ...languages, [element.lang_code]: element.lang_code };
  });
  return languages;
};

export const parseStoryLocalizationAll = (data: Array<any>) => {
  const languages = Object.keys(languageList(data));

  let allLanguages = {};
  languages.map((language) => {
    const languageData = data.filter((objectData) => objectData.lang_code === language);
    const parametarNames = languageData.map((objectData) => objectData.param_name);
    let parametars = {};
    parametarNames.map((parametarName) => {
      parametars = {
        ...parametars,
        [parametarName]: languageData.find((objectData) => objectData.param_name === parametarName)
          .localized_val,
      };
    });
    allLanguages = { ...allLanguages, [language]: parametars };
  });
  return allLanguages;
};

export const constructStoryLocalizationBody = (
  id: number,
  language: string,
  transformData: any,
  oldLocalization?: any
) => {
  const parametars = Object.keys(transformData);
  let story = oldLocalization ? oldLocalization.story : {};
  parametars.map((key) => {
    let spreadData = {};
    if (story[key] && story[key][id]) {
      spreadData = story[key][id];
    }
    story = { ...story, [key]: { [id]: { ...spreadData, [language]: transformData[key] } } };
  });
  return { story };
};

export const isEmpty = (obj) => {
  for (let x in obj) {
    return false;
  }
  return true;
};
