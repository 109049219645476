import React from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import {
  couponUrlName,
  couponUrlPlaceholder,
  incorrectUrl,
} from '../../../lib/strings/treasureCreation';
import { inputParameters } from '../../../actions/treasureActions';
import { isValidUrlPattern } from '../../../helpers/helperFunctions';

function CouponUrl({ language, form, disabled, initialValue, onBlur }) {
  return (
    <Form.Item className="roboto-regular" label={couponUrlName[language]}>
      {form.getFieldDecorator(inputParameters.couponUrl, {
        rules: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
              } else if (isValidUrlPattern(value)) {
                callback();
              } else {
                callback(incorrectUrl[language]);
              }
            },
          },
        ],
        initialValue: initialValue,
      })(
        <Input
          className="input-treasure full-width"
          onBlur={onBlur}
          placeholder={couponUrlPlaceholder[language]}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
}

CouponUrl.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onBlur: PropTypes.func,
};

CouponUrl.defaultProps = {
  language: 'EN',
  disabled: false,
  initialValue: '',
  onBlur: () => {},
};

export default CouponUrl;
