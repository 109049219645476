import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Typography, Row, Button, InputNumber, Col, Carousel, Select, } from 'antd';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import AppContext from '../../../../AppContext';
import { getImageLbox } from '../../../../services/domeService';
import { baseURL } from '../../../../lib/config';

import {
  FileUpload,
} from '../../../../services/domeService';
import { LboxAdminImage } from '../../../../../src/services/domeService'

const { Option } = Select;
const LboxAdmin = (props) => {
  const profile = useContext(AppContext);
  // const [firstImage,  setFirstImage] = useState('');
  const [firstFileName, setFirstFileName] = useState('');
  // const [firstPreview, setFirstPreview] = useState('');
  const [firstFile, setFirstFile] = useState(null);
  const [firstSrc, setFirstSrc] = useState('');
  // const [firsttype, setFirstType] = useState('');

  // const [secondImage,  setSecondImage] = useState('');
  const [secondFileName, setSecondFileName] = useState('');
  // const [secondPreview, setSecondPreview] = useState('');
  const [secondFile, setSecondFile] = useState(null);
  const [secondSrc, setSecondSrc] = useState('');
  // const [secondtype, setSecondType] = useState('');

  // const [thirdImage,  setThirdImage] = useState('');
  const [thirdFileName, setThirdFileName] = useState('');
  // const [thirdPreview, setThirdPreview] = useState('');
  const [thirdFile, setThirdFile] = useState(null);
  const [thirdSrc, setThirdSrc] = useState('');
  // const [thirdtype, setThirdType] = useState('');

  // const [thirdImage,  setThirdImage] = useState('');
  const [fourthFileName, setFourthFileName] = useState('');
  // const [thirdPreview, setThirdPreview] = useState('');
  const [fourthFile, setFourthFile] = useState(null);
  const [fourthSrc, setFourthSrc] = useState('');
  // const [thirdtype, setThirdType] = useState('');

  // const [thirdImage,  setThirdImage] = useState('');
  const [fifthFileName, setFifthFileName] = useState('');
  // const [thirdPreview, setThirdPreview] = useState('');
  const [fifthFile, setFifthFile] = useState(null);
  const [fifthSrc, setFifthSrc] = useState('');
  // const [thirdtype, setThirdType] = useState('');
  // const [thirdImage,  setThirdImage] = useState('');

  const [sixthFileName, setSixthFileName] = useState('');
  // const [thirdPreview, setThirdPreview] = useState('');
  const [sixthFile, setSixthFile] = useState(null);
  const [sixthSrc, setSixthSrc] = useState('');
  // const [thirdtype, setThirdType] = useState('');

  const [image1, SetImage1] = useState('')
  const [image2, SetImage2] = useState('')
  const [image3, SetImage3] = useState('')

  const [image4, SetImage4] = useState('')
  const [image5, SetImage5] = useState('')
  const [image6, SetImage6] = useState('')

  const [value,setValue]=useState('english')

  const lboxClose = () => {
    profile.profile['lboxAdmin'] = false
    profile.setContextData(profile.profile)
  }
  function handleChange(value) {
    console.log(value,'dddddd')
    console.log(`selected ${value}`);
    setValue(value)
  }

  useEffect(() => {
    (async () => {
            let data = await getImageLbox();
       SetImage1(baseURL + '/uploads/' + data.voucherData.image_1.id)
       SetImage2(baseURL + '/uploads/' + data.voucherData.image_2.id)
       SetImage3(baseURL + '/uploads/' + data.voucherData.image_3.id)
       SetImage4(baseURL + '/uploads/' + data.voucherData.image_4.id)
       SetImage5(baseURL + '/uploads/' + data.voucherData.image_5.id)
       SetImage6(baseURL + '/uploads/' + data.voucherData.image_6.id)
    })();
  }, []);
  
  const handleImageChange = async (e, inputNumber) => {
    if (e.target.files.length > 0) {
      const selectedImage = e.target.files[0];
      const selectedFileName = selectedImage.name;
      const selectedPreview = URL.createObjectURL(selectedImage);
      const result = await FileUpload(selectedImage);
      const selectedFile = result.file;
      switch (inputNumber) {
        case "inputNumber1":
          // setFirstImage(selectedImage);
          setFirstFileName(selectedFileName);
          // setFirstPreview(selectedPreview);
          setFirstFile(selectedFile);
          setFirstSrc(selectedPreview);
          // setFirstType(selectedImage.type);
          break;
        case "inputNumber2":
          // setSecondImage(selectedImage);
          setSecondFileName(selectedFileName);
          // setSecondPreview(selectedPreview);
          setSecondFile(selectedFile);
          setSecondSrc(selectedPreview);
          // setSecondType(selectedImage.type);
          break;
        case "inputNumber3":
          // setThirdImage(selectedImage);
          setThirdFileName(selectedFileName);
          // setThirdPreview(selectedPreview);
          setThirdFile(selectedFile);
          setThirdSrc(selectedPreview);
          // setThirdType(selectedImage.type);
          break;
          case "inputNumber4":
          // setThirdImage(selectedImage);
          setFourthFileName(selectedFileName);
          // setThirdPreview(selectedPreview);
          setFourthFile(selectedFile);
          setFourthSrc(selectedPreview);
          // setThirdType(selectedImage.type);
          break;
          case "inputNumber5":
          // setThirdImage(selectedImage);
          setFifthFileName(selectedFileName);
          // setThirdPreview(selectedPreview);
          setFifthFile(selectedFile);
          setFifthSrc(selectedPreview);
          // setThirdType(selectedImage.type);
          break;
          case "inputNumber6":
          // setThirdImage(selectedImage);
          setSixthFileName(selectedFileName);
          // setThirdPreview(selectedPreview);
          setSixthFile(selectedFile);
          setSixthSrc(selectedPreview);
          // setThirdType(selectedImage.type);
          break;
        default:
          break;
      }
    }
  };
 const handleImageUpload = async ()=>{
  const res =  await LboxAdminImage(firstFile,secondFile,thirdFile,fourthFile,fifthFile,sixthFile);
}
  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">
              &#62; Lbox Admin
            </h4>
            <div className="fx-aic">
              <i className="iconx cursor">
                <img src={closeIcon} onClick={lboxClose} alt="Info" />
              </i>
            </div>
          </div>

          <div className="domeAddContainer scrollSection" style={{ paddingBottom: '130px' }}>
            <Row className='mb-10'>
              <Col span={18} offset={2}>
                <Select defaultValue="english" style={{ width: "100%" }} onChange={handleChange}>
                  <Option value="english">English</Option>
                  <Option value="korean">한국어</Option>
                </Select>
              </Col>
            </Row>
           {value=='english'&&
            <Row className='mb-10'>
              <Typography.Text className='font-bold'>Image:</Typography.Text>
              <div>
                <Carousel className='customSlider'>
                {/* autoplay */}
                  <div>
                    <img src={firstSrc?firstSrc:image1} />
                  </div>
                  <div>
                    <img src={secondSrc?secondSrc:image2} />
                  </div>
                  <div>
                    <img src={thirdSrc?thirdSrc:image3} />
                  </div>
                </Carousel> 
              </div>
            </Row>}
            {value=='korean'&&
              <Row className='mb-10'>
              <Typography.Text className='font-bold'>Image:</Typography.Text>
              <div>
                <Carousel className='customSlider'>
                {/* autoplay */}
                  <div>
                    <img src={fourthSrc?fourthSrc:image4} />
                  </div>
                  <div>
                    <img src={fifthSrc?fifthSrc:image5} />
                  </div>
                  <div>
                    <img src={sixthSrc?sixthSrc:image6} />
                  </div>
                </Carousel> 
              </div>
            </Row>}
            {value=='english'&&
            <Row className='mb-10'>
              <label htmlFor="pic1" className='fileUpd'>
                <span>{firstFileName?firstFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input 
                  id='pic1'
                  placeholder="Image file(jpg, png) Size: 414x233 16:9 Ratio"
                  type="file" 
                  accept="image/*"
                  onChange={(event) => handleImageChange(event,'inputNumber1')} />
              </label>
              
            </Row>}
            {value=='english'&&
            <Row className='mb-10'>
              <label htmlFor="pic2" className='fileUpd'>
                <span>{secondFileName?secondFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input  
                  id='pic2'
                  type="file" 
                  accept="image/*"
                  onChange={(event) => handleImageChange(event,'inputNumber2')}
                  />

              </label>
            </Row>}
            {value=='english'&&
            <Row className='mb-10'>
              <label htmlFor="pic3" className='fileUpd'>
                <span>{thirdFileName?thirdFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input
                id='pic3'
                accept="image/*"
                type="file" 
                onChange={(event) => handleImageChange(event,'inputNumber3')}
                />
              </label>
            </Row>
             }
            {value=='korean'&&
            <Row className='mb-10'>
              <label htmlFor="pic4" className='fileUpd'>
                <span>{fourthFileName?fourthFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input 
                  id='pic4'
                  placeholder="Image file(jpg, png) Size: 414x233 16:9 Ratio"
                  type="file" 
                  accept="image/*"
                  onChange={(event) => handleImageChange(event,'inputNumber4')} />
              </label>
              
            </Row>}
            {value=='korean'&&
            <Row className='mb-10'>
              <label htmlFor="pic5" className='fileUpd'>
                <span>{fifthFileName?fifthFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input  
                  id='pic5'
                  type="file" 
                  accept="image/*"
                  onChange={(event) => handleImageChange(event,'inputNumber5')}
                  />

              </label>
            </Row>}
            {value=='korean'&&
            <Row className='mb-10'>
              <label htmlFor="pic6" className='fileUpd'>
                <span>{sixthFileName?sixthFileName:'Image file(jpg, png) Size: 414x233 16:9 Ratio'}</span>
                <Input
                id='pic6'
                accept="image/*"
                type="file" 
                onChange={(event) => handleImageChange(event,'inputNumber6')}
                />
              </label>
            </Row>
             }

            <Row type='flex' justify='center'>
              <button onClick={lboxClose} className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
              <button  onClick={handleImageUpload} className='ant-btn yellow-gradient xs ml-5'>Save</button>
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
};

export default LboxAdmin;