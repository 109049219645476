import React from 'react';
import { Row, Spin, notification, Input, Select } from 'antd';
import AppContext from '../../../AppContext';
import ReactTooltip from 'react-tooltip';
import DomeNotice from './domeEdit/domeNotice';
import DomeTicket from './domeEdit/domeTicket';
import DomeImageUrl from './domeEdit/domeImageUrl';
import DomeArena from './domeEdit/domeArena';
import DomeHall from './domeEdit/domeHall';
import DomeImage from './domeEdit/domeImage';
import DomeApplication from './domeEdit/domeApplication';
import DomeProfile from './domeEdit/domeProfile';
import DomeOuterLogo from './domeEdit/domeOuterLogo';
import LobbyLogo from './domeEdit/lobbyLogo';
import DomeThreedIcon from './domeEdit/domeThreedIcon';
import DomeTwodIcon from './domeEdit/domeTwodIcon';
import DomeOuterWall from './domeEdit/domeOuterWall';
import DomeInnerWall from './domeEdit/domeInnerWall';
import { ImageUrlSection } from './domeEdit/domeImageUrl';
import closeIcon from '../../../../src/assets/closeXsmall.svg';
import locationIcon from '../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../src/assets/icon_info.svg';
import editIcon from '../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../src/assets/icon_delete_o.svg';
import iconDelete from '../../../../src/assets/icon_delete_o.svg';
import infoIconPrimary from '../../../../src/assets/icon_info_blue.svg';
import burgerKing from '../../../../src/assets/burgerKing.png';
import burgerKingTrans from '../../../../src/assets/burgerKingTrans.png';
import {b} from '../../dashboard/menu/i18next'
import {
  isExistsDomeName,
  UpdateDome,
  UpdateDomeType,
  FileUpload,
  UpdateSetting,
  get3DIcons,
  loadDome,
  getDomeTypes,
  getDomeApplication,
  getDomeArinaApi,
  deleteSetting,
  postHastagsApi,
  getHastagsApi,
} from '../../../services/domeService';

import { InputTags } from 'react-bootstrap-tagsinput';
import ImagePreviewModal from './domeEdit/components/ImagePreviewModal';
import { baseURL } from '../../../lib/config';
import ApplicationEdit from './ApplicationEdit';
import ArenaEdit from './ArenaEdit';
import { store } from '../../../helpers/store';
import { getMarkerLocation } from '../../../helpers/GetLocation';
import { connect } from 'react-redux';
// import 'react-bootstrap-tagsinput/dist/index.css'

const { TextArea } = Input;

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

class DomeEditing extends React.Component<any, any> {
  static contextType = AppContext;
  subMenuRef: React.RefObject<unknown>;
  hasTagIntialRef: React.RefObject<unknown>;
  hashInputRef: React.RefObject<unknown>;
  constructor(props: any) {
    super(props);
    this.state = {
      icons: [],
      iconImg: '',
      icon: '',
      success: '',
      error: '',
      activeImageUrl: '',
      activeArean: '',
      activeHall: '',
      activeNotice: '',
      activeImages: '',
      activeApplication: '',
      activeTicket: '',
      profile: false,
      outerLogo: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
      loader: false,
      icon3D: {},
      color: '',
      hashTags: [],
      suggestions: [],
      typeName: '',
      imagePreviewModalShow: false,
      imagePreviewSource: '',
      hideMarker: false,
      applicationEditModal: false,
      arArenaEditModal: false,
      hasTagIntialRender: false,
      deleteImg: false,
    };
    this.subMenuRef = React.createRef();
    this.hasTagIntialRef = React.createRef();
    this.hashInputRef = React.createRef();
    this.closeContent = this.closeContent.bind(this);
  }

  onArArenaEditClick = () => {
    this.setState({
      arArenaEditModal: true,
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };
  
  onArenaModalClose = () => {
    this.setState({
      arArenaEditModal: false,
    });
  };

  onApplicationEditClick = () => {
    this.setState({
      applicationEditModal: true,
      arArenaEditModal: false,
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  onApplicationModalClose = () => {
    this.setState({
      applicationEditModal: false,
    });
  };

  onImagePreviewClick = async (indx) => {
    let previewUrl = baseURL + '/uploads/';
    const profile =   await this.getContext();
    let id = profile.edit_current_dome.ID;
    let result =   await loadDome(id);
    // console.log('result', result);
    let previewImg = `${previewUrl}${result.filesUrl[indx].file.id}`;
    // for (let i = 0; i < result.filesUrl.length; i++) {
    //   var _inputList = {
    //     url: result.filesUrl[i].url,
    //     preview: previewUrl + result.filesUrl[i].file.id,
    //     fileName: result.filesUrl[i].file.name,
    //     file: result.filesUrl[i].file,
    //   };
    //   inputList.push(_inputList);
    // }
    // console.log('previewImg', previewImg);
    this.setState({
      imagePreviewModalShow: true,
      imagePreviewSource: previewImg,
      hideMarker: false,
      applicationEditModal: false,
      arArenaEditModal: false,
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  onHallImagePreviewClick = (url) => {
    this.setState({
      imagePreviewModalShow: true,
      imagePreviewSource: url,
      hideMarker: true,
      applicationEditModal: false,
      arArenaEditModal: false,
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  onImagePreviewClickClose = () => {
    this.setState({
      imagePreviewModalShow: false,
      applicationEditModal: false,
      arArenaEditModal: false,
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  callHastagsApi = async (id) => {
    const res =   await getHastagsApi(id);
    if (res && res.hashtags && res.hashtags.length) {
      const { hashtags } = res;
      let finalHash = hashtags.map((item) => item.hashtag);
      this.setState({
        hashTags: finalHash,
        hasTagIntialRender: true,
      });
    } else {
      this.setState({
        hashTags: [],
        hasTagIntialRender: true,
      });
    }
  };

  componentDidMount = async () => {
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    // console.log('components', current_edit_dome);
    let id = current_edit_dome.ID;
    this.hasTagIntialRef.current = true;
    this.callHastagsApi(id);
    const icons =   await get3DIcons();
    this.setState({
      icons: icons,
    });
    const res =   await getDomeApplication(id);
    if (res) {
      profile['application_data'] = res;
    } else {
      profile['application_data'] = null;
    }

    const arenaRes =   await getDomeArinaApi(id);

    if (arenaRes) {
      profile['arena__data'] = arenaRes;
    } else {
      profile['arena__data'] = null;
    }
    this.updateContext(profile);
  };

  componentDidUpdate = async () => {
    let profile =   await this.getContext();
    if (profile.isDomeSubEditingActive) {
      if (profile.domeEditingTypeColor && profile.domeEditingTypeName) {
        if (profile.domeEditingTypeName !== 'Basic Setting') {
          this.domeImageUrl(
            profile.domeEditingTypeName,
            profile.domeEditingTypeColor
          );
        }
        profile.isDomeSubEditingActive = false;
          await this.updateContext(profile);
        if (this.subMenuRef?.current) {
          // window.scrollTo(0, this.subMenuRef.current.offsetTop);
          this.subMenuRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  closeEditting = async (e: any) => {
    let profile =   await this.getContext();
    profile['edit_current_dome'] = null;
      await this.updateContext(profile);
  };

  domeImageUrl = async (name, color) => {
    this.setState({ color: color });
    if (name === 'Image+') {
      this.setState({
        typeName: name,
        activeHall: '',
        activeImageUrl: 'active',
        activeArean: '',
        activeNotice: '',
        activeImages: '',
        activeApplication: '',
        activeTicket: '',
      });
    } else if (name === 'Ticket Box') {
      this.setState({
        typeName: name,
        activeHall: '',
        activeImageUrl: '',
        activeArean: '',
        activeNotice: '',
        activeImages: '',
        activeApplication: '',
        activeTicket: 'active',
      });
    } else if (name === 'Application') {
      this.setState({
        typeName: name,
        activeHall: '',
        activeImageUrl: '',
        activeArean: '',
        activeNotice: '',
        activeImages: '',
        activeApplication: 'active',
        activeTicket: '',
      });
    } else if (name === 'Noticeboard') {
      this.setState({
        typeName: name,
        activeHall: '',
        activeTicket: '',
        activeImageUrl: '',
        activeArean: '',
        activeNotice: 'active',
        activeImages: '',
        activeApplication: '',
      });
    } else if (name === 'Arena') {
      this.setState({
        typeName: name,
        activeHall: '',
        activeTicket: '',
        activeImageUrl: '',
        activeArean: 'active',
        activeNotice: '',
        activeImages: '',
        activeApplication: '',
      });
    } 
    else if (name === 'Hall (20)') {
      this.setState({
        typeName: name,
        activeHall: 'active',
        activeTicket: '',
        activeImageUrl: '',
        activeArean: '',
        activeNotice: '',
        activeImages: '',
        activeApplication: '',
      });
    }
    else if (name === 'Hall (16)') {
      this.setState({
        typeName: name,
        activeHall: 'active',
        activeTicket: '',
        activeImageUrl: '',
        activeArean: '',
        activeNotice: '',
        activeImages: '',
        activeApplication: '',
      });
    }
    
  };

  closeDomeHall = () => {
    this.setState({
      typeName: '',
      activeHall: '',
      activeTicket: '',
      activeImageUrl: '',
      activeArean: '',
      activeNotice: '',
      activeImages: '',
      activeApplication: '',
    });
  };

  domeNotice = async (e: any) => {
    this.setState({
      activeImageUrl: '',
      activeArean: '',
      activeNotice: 'active',
      activeImages: '',
      activeApplication: '',
    });
  };

  domeArena = async (e: any) => {
    this.setState({
      activeImageUrl: '',
      activeArean: 'active',
      activeNotice: '',
      activeImages: '',
      activeApplication: '',
    });
  };

  domeImage = async (e: any) => {
    this.setState({
      activeImageUrl: '',
      activeArean: '',
      activeNotice: '',
      activeImages: 'active',
      activeApplication: '',
    });
  };

  domeApplication = async (e: any) => {
    this.setState({
      activeImageUrl: '',
      activeArean: '',
      activeNotice: '',
      activeImages: '',
      activeApplication: 'active',
    });
  };

  openProfile = async (e: any) => {
    this.setState({
      profile: true,
      outerLogo: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  closeProfile = async (e: any) => {
    this.setState({
      profile: false,
    });
  };

  openOuterLogo = async (e: any) => {
    this.setState({
      outerLogo: true,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  closeOuterLogo = async (e: any) => {
    this.setState({
      outerLogo: false,
    });
  };

  openLobbyLogo = async (e: any) => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: true,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  closeLobbyLogo = async (e: any) => {
    this.setState({ lobbylogo: false });
  };

  openThreedIcon = async (e: any) => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: true,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
    // console.log(threedicon)
  };

  closeThreedIcon = async (e: any) => {
    this.setState({
      threedicon: false,
    });
  };

  closeAllEditScreens = () => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: false,
    });
  };

  openTwodIcom = async (e: any) => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: true,
      outerwall: false,
      innerwall: false,
    });
  };

  closeTwodIcon = async (e: any) => {
    this.setState({
      twodicom: false,
    });
  };

  openOuterWall = async (e: any) => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: true,
      innerwall: false,
    });
  };

  closeOterWall = async (e: any) => {
    this.setState({
      outerwall: false,
    });
  };

  openInnerWall = async (e: any) => {
    this.setState({
      outerLogo: false,
      profile: false,
      lobbylogo: false,
      threedicon: false,
      twodicom: false,
      outerwall: false,
      innerwall: true,
    });
  };

  closeInnerWall = async (e: any) => {
    this.setState({
      innerwall: false,
    });
  };

  closeContent = async (e: any, obj: any) => {
    this.setState(obj);
  };

  handleChangeIcon = async (e, iconImg) => {
    this.setState({
      icon: e.target.value,
      iconImg: iconImg.id,
      icon3D: iconImg,
    });
    let obj = {
      icon_3d: iconImg,
    };
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    const res =   await UpdateSetting(id, obj);
  };

  publish = async () => {
    if (Object.keys(this.state.iconImg).length === 0) {
      showErrorMessage(b?.t('Please select Icon'));
      return false;
    }
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
      icon_3d: this.state.icon3D,
    };
    this.setState({
      loader: true,
    });
    const res =   await UpdateSetting(id, obj);
    const domeSetting =   await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;
    current_edit_dome.icon_3d = this.state.icon3D;
    profile.current_edit_dome = current_edit_dome;
      await this.update(profile);
     showSuccessMessage(b?.t("Dome Setting Updated"));
    this.setState({
      loader: false,
    });
      await this.closeThreedIcon();
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
      await this.updateContext(profile);
  };

  getLocation = (lat: any, lng: any, dome_addr: any, e: any) => {
    const contextData = this.context;
    let profile = contextData.profile;
    const mapInstance = store.getState().address.mapInstance;
    if (this.state.marker) {
      this.state.marker.setMap(null);
    }

    let res = getMarkerLocation(lat, lng, dome_addr);
    mapInstance.setCenter(res.center);
    this.setState({
      marker: res.marker,
    });
  };

  onDeleteSettingsClick = async (key) => {
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {};
    if (key === 'outer_logo') {
      obj = {
        [key]: ' ',
      };
      current_edit_dome['outerlogo'] = null;
    } else if (key === 'profile') {
      obj = {
        [key]: ' ',
      };
      current_edit_dome['profile'] = null;
      // console.log("-iii---",current_edit_dome['profile'])
    } else if (key === 'loby_logo') {
      obj = {
        [key]: ' ',
      };
      current_edit_dome['lobbylogo'] = null;
      this.setState({ deleteImg: true });
    } else if (key === 'icon_3d') {
      obj = {
        [key]: ' ',
      };
      current_edit_dome['icon_3d'] = null;
    } else if (key === '2d_icon') {
      obj = {
        background_image_2d: ' ',
        city_name: ' ',
        dome_name_2d: ' ',
      };
      current_edit_dome['background_image_2d'] = null;
      current_edit_dome['city_name_2d'] = '';
      current_edit_dome['dome_name_2d'] = '';
    }

    const res =   await deleteSetting(id, obj);
    // console.log('resarif', obj);
    const domeSetting =   await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;

    profile.current_edit_dome = current_edit_dome;
      await this.update(profile);
    // showSuccessMessage('Dome updated successfully.');
    // this.props.closeProfile();
  };

  renderDomeName = (status, name, suffix, file) => {
    if (status === 1) {
      if (file) {
        return name;
      } else {
        if (name && suffix) {
          return `${name}${suffix}`;
        }
      }
    }
    return '';
  };

  // onHastagChange = async (value) => {
  //   if (this.state.hasTagIntialRender) {
  //     let profile =   await this.getContext();
  //     const current_edit_dome = profile['edit_current_dome'] || {};
  //     let id = current_edit_dome.ID;
  //       await postHastagsApi(id, value.values);
  //     this.setState({
  //       hasTags: value.values,
  //     });
  //   }

  // };

  onHastagChange = async (value) => {
    if (value.length > 15) {
      showErrorMessage(b?.t('Cannot Add More Hashtags'));
      return;
    }
    this.setState({
      hashTags: value,
    });
  };

  onHastagSave = async (value) => {
    let profile =   await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
      await postHastagsApi(id, value);
  };
  onDeselect = (value) => {
    this.hashInputRef.current.focus();
  };

  disabelButtons = (template_type, index) => {
    let a =  localStorage.getItem("dome_type")
    if (template_type === 'A' && index >= 9 && a=="1") {
      return true;
    }
    if (template_type === "A" && index>= 10 && a=="5") {
      return true;
     }
    if (template_type === 'B' && index >= 11) {
      return true;
    }
    return false;
  };

  render() {
    const { translation } = this.props
    const context = this.context;
    const profile = context.profile;
    const edit_current_dome = profile['edit_current_dome'];
    const domeProfile = edit_current_dome.profile || {};

    // console.log("===="+edit_current_dome.dome_type)

    let outerLogo = edit_current_dome.outerlogo || {};
    let outerLogoName = outerLogo.name || '';
    let lobbylogo = edit_current_dome.lobbylogo || {};
    // console.log('edit_current_dome', edit_current_dome.lobbyLogo);
    // console.log('lobby logo', lobbylogo);
    let settingTypes =profile.domeTypeSetting;  
     // console.log(settingTypes,'settingTypes')
    let template_type = '';

    if (settingTypes && settingTypes.length !== 0) {
      for (let i = 0; i < settingTypes.length; i++) {
        if (settingTypes[i]['name'] === 'Hall') {
          template_type = 'A';
        }
        if (settingTypes[i]['name'] === 'Arena') {
          template_type = 'B';
        }
      }
    }

    let currentLang = localStorage.getItem('lang');

    return (
      <>
        <Row
          className={`menu-wrapper treasure-menu-wrapper ${
            this.props.isUsedForThirdD ? 'third-dialog' : 'second-dialog'
          }`}
        >
          <div className="second-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">
                &#62;{ translation('Dome Editing')} 
              </h4>
              <div className="fx-aic">
                <div className="size12 text-primary"> 
                { translation('Dome Address')} :&nbsp;
                  {edit_current_dome.addr_status ? (
                    <span style={{fontStyle: 'italic'}} translate="yes" title="게시" className="published">
                      {translation('Published')}
                    </span>
                  ) : (
                    <span
                      style={{fontStyle: 'italic'}}
                      translate="yes"
                      title="게시되지 않음"
                      className="unpublished"
                    >
                      {translation('Unpublished')}
                    </span>
                  )}
                </div>
                <i onClick={this.closeEditting} className="iconx cursor">
                  <img src={closeIcon} alt="Info" />
                </i>
              </div>
            </div>

            <div className="domeAddContainer scrollSection">
              <div className="let-long-loop domeAddLoop">
                <div className="let-long-loop__left">
                  <div className="let-long-loop__left__row mb-10">
                    <i
                      style={{ cursor: 'pointer' }}
                      onClick={(e) =>
                        this.getLocation(
                          edit_current_dome.location.y,
                          edit_current_dome.location.x,
                          edit_current_dome.addr_name,
                          e
                        )
                      }
                      className="let-long-loop__left__row__icon"
                    >
                      <img src={locationIcon} alt="Location" />
                    </i>
                    <div
                      className="let-long-loop__left__row__info"
                      translate="no"
                    >
                      <h4 className="font-bold mb-0">
                        <span className="text-muted">@</span>
                        {this.renderDomeName(
                          edit_current_dome.dome_status,
                          edit_current_dome.dome_name,
                          edit_current_dome.dome_name_suffix,
                          edit_current_dome.file
                        )}
                        {/* {edit_current_dome.dome_status === 1
                          ? edit_current_dome.dome_name
                          : ''} */}
                      </h4>
                      <p className="text-dark size13 mb-0">
                        {edit_current_dome.addr_name}
                      </p>
                      <div className="let-long-loop__left__row__info__row">
                        <div className="let-long-loop__left__row__info__row__info size10">
                          <div className="mr-10">
                            <p className="mb-0">{translation("Latitude")}</p>
                            <p className="mb-0">
                              {edit_current_dome.location.y}
                            </p>
                          </div>
                          <div className="mb-0">
                            <p className="mb-0">{translation("Longitude")}</p>
                            <p className="mb-0">
                              {edit_current_dome.location.x}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid-row sm pt-10">
                <div className="col col-4">
                  <h4>
                    <i className="blueBox mr-5"></i>
                    <span className="text-primary mr-5">{translation("Basic Setting")}</span>
                    {/* <i><img src={infoIcon} alt="Info" height="14" /></i> */}
                    <div className="infoTip">
                      <span
                        className="infoTip__icon"
                        data-for="soclose_1"
                        data-tip="8"
                      >
                        &nbsp;
                      </span>
                      <ReactTooltip
                        id="soclose_1"
                        getContent={(dataTip) => (
                          <div>
                            <p>
                            {translation("3D Icon and 2D Icon are minimum requirements to publish your Dome")}
                              
                            </p>
                          </div>
                        )}
                        effect="solid"
                        delayHide={500}
                        delayShow={500}
                        delayUpdate={500}
                        place={'right'}
                        border={true}
                        type={'light'}
                      />
                    </div>
                  </h4>
                </div>
                <div className="col col-8">
                  <ol className="editingList">
                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4">
                              <label className="fileLabel">{translation('Profile')}</label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn">
                                <span title={domeProfile.name ? domeProfile.name : translation('file')} className="fileBtn__title">
                                  {domeProfile.name ? domeProfile.name : translation('file')}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openProfile}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            onClick={() =>
                              this.onDeleteSettingsClick('profile')
                            }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>

                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4">
                              <label className="fileLabel">{translation('Outer Logo')}</label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn">
                                <span title={outerLogoName ? outerLogoName : translation('file')} className="fileBtn__title">
                                  {outerLogoName ? outerLogoName : translation('file')}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openOuterLogo}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            onClick={() =>
                              this.onDeleteSettingsClick('outer_logo')
                            }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>

                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4 fx-aic">
                              <label className="fileLabel">{translation('Lobby Logo')}</label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn">
                                <span title={lobbylogo.name ? lobbylogo.name : translation('file')} className="fileBtn__title">
                                  {lobbylogo.name ? lobbylogo.name : translation('file')}{' '}
                                  {/* {console.log('arif1234', lobbylogo)} */}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openLobbyLogo}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            onClick={() =>
                              this.onDeleteSettingsClick('loby_logo')
                            }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>

                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4 fx-aic">
                              <label className="fileLabel">
                                <span className="jointboth">
                                  <i>{translation('3D Icon')}</i>
                                  <span className="required">{translation('Required')}</span>
                                </span>
                              </label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn preview3DIcon">
                                {/* <span className="fileBtn__title">{icon_3d.name ? 'Active': 'file'}</span> */}
                                {this.state.iconImg ? (
                                  <img
                                    src={
                                      ' https://app.loamoa-dev-merge.cladev.com/uploads/' +
                                      this.state.iconImg
                                    }
                                    height="40"
                                    width="60"
                                    alt="icon"
                                  />
                                ) : edit_current_dome.icon_3d ? (
                                  <img
                                    src={
                                      ' https://app.loamoa-dev-merge.cladev.com/uploads/' +
                                      edit_current_dome.icon_3d.id
                                    }
                                    height="40"
                                    width="60"
                                    alt="icon"
                                  />
                                ) : (
                                  ''
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openThreedIcon}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            onClick={() =>
                              this.onDeleteSettingsClick('icon_3d')
                            }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4 fx-aic">
                              <label className="fileLabel">
                                <span className="jointboth">
                                  <i>{translation('2D Icon')}</i>
                                  <span className="required">{translation('Required')}</span>
                                </span>
                              </label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn mb-5">
                                <span title={edit_current_dome.background_image_2d
                                    ? edit_current_dome.background_image_2d.name
                                    : translation('file')} className="fileBtn__title">
                                  {edit_current_dome.background_image_2d
                                    ? edit_current_dome.background_image_2d.name
                                    : translation('file')}
                                </span>
                              </label>
                              <div className="mb-5">
                                <input
                                  type="text"
                                  // value={`${edit_current_dome.dome_name}${
                                  //   edit_current_dome.dome_name_suffix
                                  //     ? edit_current_dome.dome_name_suffix
                                  //     : ''
                                  // }`}
                                  value={this.renderDomeName(
                                    edit_current_dome.dome_status,
                                    edit_current_dome.dome_name,
                                    edit_current_dome.dome_name_suffix,
                                    edit_current_dome.file
                                  )}
                                  className="form-control xss rd-0"
                                  readOnly
                                />
                              </div>
                              <div className="mb-0">
                                <input
                                  type="text"
                                  value={edit_current_dome.city_name_2d}
                                  className="form-control xss rd-0"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openTwodIcom}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            onClick={() =>
                              this.onDeleteSettingsClick('2d_icon')
                            }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>

                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4 fx-aic">
                              <label className="fileLabel">{translation('Inner Type')}</label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn">
                                <span className="fileBtn__title"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openOuterWall}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            //  onClick={() =>

                            //     this.onDeleteSettingsClick('outer_logo')
                            //   }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>

                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                          <div className="grid-row xs">
                            <div className="col col-4 fx-aic">
                              <label className="fileLabel">{translation('Inner Color')}</label>
                            </div>
                            <div className="col col-8">
                              <label className="fileBtn">
                                <span className="fileBtn__title"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="editingList__item__row__action">
                          <button
                            onClick={this.openInnerWall}
                            className="iconx xs"
                          >
                            <img src={editIcon} alt="Edit" height="14" />
                          </button>
                          <button
                            // onClick={() =>

                            //     this.onDeleteSettingsClick('outer_logo')
                            //   }
                            className="iconx xs"
                          >
                            <img src={deleteIcon} alt="Delete" height="14" />
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="editingList__item">
                      <div className="editingList__item__row">
                        <div className="editingList__item__row__left">
                        {translation('Hashtags')}
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <label>
                <div
                  className="col col-7"
                  style={{ marginLeft: '32%', fontSize: '12px' }}
                >
                  <em>{translation('Maximum 15 Hashtags')}</em>
                </div>
              </label>
              <div className="pt-10 mb-10">
                <div className="fx inputTagsUi uiModification">
                  <div className="col col-8" style={{ marginLeft: '32%' }}>
                    {/* <TextArea 
                    // placeholder="Autosize height" 
                    translate='no'
                    // values={this.state.hashTags} 
                    onTags={(value) => this.onHastagChange(value)} rows={4} 
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                     */}
                    {/* <InputTags
                      translate='no'
                      
                      values={this.state.hashTags}
                      //  placeholder="#"
                      onTags={(value) => this.onHastagChange(value)}
                      

                    /> */}
                    <Select
                      ref={this.hashInputRef}
                      style={{ width: '100%' }}
                      // placeholder="#"
                      // className="hashtag"
                      value={this.state.hashTags}
                      mode="tags"
                      tokenSeparators={[',']}
                      onBlur={this.onHastagSave}
                      onChange={this.onHastagChange}
                      onDeselect={this.onDeselect}
                      maxTagCount={15}
                    ></Select>
                  </div>
                </div>
              </div>

              <div className="fx-jcc fx-fw">
                {/* {console.log(settingTypes,"settingtypes")} */}
                                {settingTypes.length > 0 &&
                  settingTypes.map((type, i) =>
                  
                    type.name !== 'Basic Setting' &&
                    type.name !== 'Noticeboard' &&
                    type.name !== 'Performance Schedule' ? (
                                            <button
                        key={i}
                        onClick={
                          type.name === 'Image+' &&
                          this.disabelButtons(template_type, i)
                            ? () => {}
                            : () => this.domeImageUrl(type.name, type.color)
                        }
                        //  disabled={true} className={'ant-btn primary-ghost-btn xs '}
                        style={
                          type.name === 'Image+' &&
                          this.disabelButtons(template_type, i)
                            ? { cursor: 'not-allowed'}
                            : {}
                        }
                        className={
                          this.state.typeName === type.name &&
                          this.state.color === type.color 
                            ? `ant-btn 
                            ${
                              type.is_hide === '0'
                                ? `primary-ghost-btn`
                                : `gray-ghost-btn`
                            } xs dome-types active`
                            : `ant-btn ${
                                type.is_hide === '0'
                                  ? `primary-ghost-btn `
                                  : `gray-ghost-btn d-none`
                              } xs dome-types`
                        }
                      >
                        {currentLang == "ko" ? type.name_ko : type.name}
                      </button>
                    ) : (
                      ''
                    )
                  )}
              </div>

              <div ref={this.subMenuRef} className="tab-content-box">
                {/* Image+Url Tab */}
                {this.state.activeImageUrl && (
                  <DomeImageUrl
                    closeContent={this.closeContent}
                    color={this.state.color}
                    onImagePreviewClick={this.onImagePreviewClick}
                  />
                )}

                {/* {this.state.ImageUrlSection && (
                  <ImageUrlSection
                    color={this.state.color}
                  />
                )} */}

                {/* Notice tab */}
                {this.state.activeNotice && (
                  <DomeNotice
                    closeContent={this.closeContent}
                    color={this.state.color}
                  />
                )}
                {/* Notice tab */}
                {this.state.activeTicket && (
                  <DomeTicket
                    closeContent={this.closeContent}
                    color={this.state.color}
                  />
                )}
                {/* Arena Tab */}
                {this.state.activeArean && (
                  <DomeArena
                    onArArenaEditClick={this.onArArenaEditClick}
                    onArenaModalClose={this.onArenaModalClose}
                    closeContent={this.closeContent}
                    color={this.state.color}
                    closeAllEditScreens={this.closeAllEditScreens}
                  />
                )}
                {/* Hall Tab */}
                {this.state.activeHall && (
                  <DomeHall
                    closeContent={this.closeContent}
                    color={this.state.color}
                    onHallImagePreviewClick={this.onHallImagePreviewClick}
                    closeDomeHall={this.closeDomeHall}
                  />
                )}
                {/* Image tab */}
                {this.state.activeImages && (
                  <DomeImage closeContent={this.closeContent} />
                )}
                {/* Application tab */}
                {this.state.activeApplication && (
                  <DomeApplication
                    onApplicationEditClick={this.onApplicationEditClick}
                    onApplicationModalClose={this.onApplicationModalClose}
                    closeContent={this.closeContent}
                    color={this.state.color}
                  />
                )}
              </div>
            </div>
          </div>
        </Row>

        {/* Profile */}
        {this.state.profile && <DomeProfile closeProfile={this.closeProfile} />}

        {/* Outer Logo */}
        {this.state.outerLogo && (
          <DomeOuterLogo closeOuterLogo={this.closeOuterLogo} />
        )}
        {/* Lobby Logo */}
        {this.state.lobbylogo && (
          <LobbyLogo
            closeLobbyLogo={this.closeLobbyLogo}
            img={this.state.deleteImg}
          />
        )}
        {/* 3D Icon */}
        {this.state.threedicon && (
          <DomeThreedIcon
            icon={this.state.icon}
            closeThreedIcon={this.closeThreedIcon}
            icons={this.state.icons}
            handleChangeIcon={this.handleChangeIcon}
            isChecked={
              this.state.iconImg
                ? this.state.iconImg
                : edit_current_dome.icon_3d
                ? edit_current_dome.icon_3d.id
                : ''
            }          
            loader={this.state.loader}
            publish={this.publish}
          />
        )}
        {/* 2D Icon */}
        {this.state.twodicom && (
          <DomeTwodIcon closeTwodIcon={this.closeTwodIcon} />
        )}
        {/* Outer wall */}
        {this.state.outerwall && (
          <DomeOuterWall closeOterWall={this.closeOterWall} />
        )}
        {/* Inner wall */}
        {this.state.innerwall && (
          <DomeInnerWall closeInnerWall={this.closeInnerWall} />
        )}
        {/* IMAGE PREVIEW MODAL */}
        {this.state.imagePreviewModalShow && (
          <ImagePreviewModal
            imagePreviewSource={this.state.imagePreviewSource}
            closeModal={this.onImagePreviewClickClose}
            hideMarker={this.state.hideMarker}
          />
        )}
        {this.state.applicationEditModal && (
          <ApplicationEdit
            onApplicationModalClose={this.onApplicationModalClose}
          />
        )}
        {this.state.arArenaEditModal && (
          <ArenaEdit onArenaModalClose={this.onArenaModalClose} />
        )}
      </>
    );
  }
}
const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null)(DomeEditing);
