import React from 'react';
import { Row, Typography, Col } from 'antd';
import placeholder from '../../../../assets/profile_picture.svg';
import { connect } from 'react-redux';
//import { getContestByID } from '../../../../actions/contestActions'
import { baseURL } from '../../../../lib/config';
import { menuConstants } from '../../../../constants/menuConstants';
import arrow from '../../../../assets/treasure_item_more_info_icon.svg';
import { characterConstants } from '../../../../constants/characterConstants';
import * as subMenuConst from '../../../../lib/SubMenuConstants';

const { Text, Title } = Typography;

interface Props {
  ID: number;
  title: string;
  all: any;
  selected: boolean;
  dispatch: any;
  thumbnail: string;
  character?: any;
  handleSelectedCharacter: (id: number) => void;
  openCharacterDetails: () => void;
  storeDetails: (character: any) => void;
  openSubMenu1: (menu: String) => void;
  characters: any;
}

const mapStateToProps = (state) => ({
  characters: state.characters,
});

const mapDispatch = (dispatch: (arg0: { type: string; character?: any; menu?: string }) => any) => {
  return {
    openCharacterDetails: () => dispatch({ type: menuConstants.OPEN_SUBMENU_1 }),
    storeDetails: (character: any) =>
      dispatch({ type: characterConstants.GET_CHARACTER_BY_ID, character }),
    openSubMenu1: (menu: string) => dispatch({ type: menuConstants.OPEN_SUBMENU_1, menu }),
    dispatch,
  };
};

const Character = (props: Props) => {
  const openDetails = async () => {
    //props.dispatch(getCharacterByID(props.all))
    props.storeDetails(props.all);
    props.openSubMenu1(subMenuConst.characterDetails);
    props.handleSelectedCharacter(props.ID);
  };

  const characterName = props.characters.find((character) => character.ID === props.all.characterID)
    .name;

  return (
    <Row
      style={{ width: '100%' }}
      onClick={openDetails}
      type="flex"
      justify="center"
      className={props.selected ? 'treasure-wrapper-selected' : 'treasure-wrapper'}
    >
      <Row type="flex" justify="space-between" align="middle" style={{ width: '95%' }}>
        <Col className="character-img">
          <img src={props.thumbnail ? `${baseURL}/${props.thumbnail}` : placeholder} />
        </Col>
        <Col style={{ width: '72%' }}>
          <Row type="flex" justify="space-between" style={{ width: '100%' }}>
            <Col>
              <Row>
                <Title style={{ fontSize: '18px' }} className="fredoka" level={4}>
                  {props.title}
                </Title>
              </Row>
              <Row>
                <Text className="bold">{characterName}</Text>
              </Row>
            </Col>
            <Col className="open-kit-details">
              <img src={arrow} className="treasure-arrow" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

export default connect(mapStateToProps, mapDispatch)(Character);
