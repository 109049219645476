import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Typography, Form, Button, Radio, Input, Select } from 'antd';
import * as candyEventStrings from '../../../../lib/strings/candyEvent';
import CreateGlobalInput from '../../../../components/candyEvent/CreateGlobalInput';
import CreatePrizeForm from '../../../../components/candyEvent/CreatePrizeForm';
import OptionalFreeCoupon from '../../../../components/candyEvent/OptionalFreeCoupon';
import * as menuActions from '../../../../actions/menuActions';
import { baseURL, candyEvent as candyEventUrl, isDev } from '../../../../lib/config';
import * as candyEventService from '../../../../services/candyEventService';
import * as sharedServices from '../../../../services/sharedServices';
import { responseCodes } from '../../../../lib/const';
import ModalLoadingProgressText from '../../../../components/ModalLoadingProgressText';
import { useQueryClient, useMutation } from 'react-query';
import { serverError } from '../../../../lib/strings/error';
import PropTypes from 'prop-types';
import { fillCandyChallenge } from '../../../../qucikFill/candyChallengeFill';
import { type } from '../../../../lib/strings/treasureInfo';

function CreateCandyChallenge(props) {
  const queryClient = useQueryClient();
  const { language,closeCreateCandyChallenge, selectedCountryCode, candyEvent, form } = props;
  const { validateFields } = form;

  const [globalInfo, setGlobalInfo] = useState({
    ID: null,
    country: null,
    createdTime: null,
    adminID: null,
    startDate: null,
    endDate: null,
    title: '',
    month: null,
    eventPictureUrl: null,
    eventPictureFile: '',
    firstPrizeCoupon:null,
    secondPrizeCoupon:null,
    thirdPrizeCoupon:null,
    fourthPrizeCoupon:null,


   
  });
  const [GlobalInfo1,setGlobalInfo1]=useState({
    sponsorUrl:'',
    sponsorBanner:'',
  })

  const [GlobalInfo2,setGlobalInfo2]=useState({
    firstPrizeCoupon:null,
    secondPrizeCoupon:null,
    thirdPrizeCoupon:null,
    fourthPrizeCoupon:null,
  })
    

  
  const [firstPrize, setFirstPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });
  const [secondPrize, setSecondPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });
  const [thirdPrize, setThirdPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });

  const [fourthPrize, setFourthPrize] = useState({
    goods: '',
    name: '',
    pictureUrl: null,
    pictureFile: '',
  });
  const [candy,setCandy]=useState("")

  const [coupon,SetCoupon]=useState({
    state1:'',
    state2:'',
    state3:'', 
    state4:'', 
  })
  const [Banner,setBanner]=useState('')
  const [urlimg,seturl]=useState(null)

  useEffect(() => {
    if (candyEvent.isEditing && candyEvent.ID) {
      const getCandyEventData = () => {
        const candyEventList: any = queryClient.getQueryData([
          'CandyEventList',
          { country: candyEvent.selectedCountryCode },
        ]);
        const candyEventData = candyEventList.find((cEvent) => cEvent.ID === candyEvent.ID);
        return candyEventData;
      };
      const candyEventData = getCandyEventData();

      form.setFieldsValue({
        title: candyEventData?.title,
        month: candyEventData?.month,
        eventPictureUrl: `${baseURL}${candyEventData?.eventPictureUrl}`,
        'firstPrize-goods': candyEventData?.firstPrize?.goods,
        'firstPrize-name': candyEventData?.firstPrize?.name,
        'firstPrize-picture': `${baseURL}${candyEventData?.firstPrize?.pictureUrl}`,

        'secondPrize-goods': candyEventData?.secondPrize?.goods,
        'secondPrize-name': candyEventData?.secondPrize?.name,
        'secondPrize-picture': `${baseURL}${candyEventData?.secondPrize?.pictureUrl}`,

        'thirdPrize-goods': candyEventData?.thirdPrize?.goods,
        'thirdPrize-name': candyEventData?.thirdPrize?.name,
        'thirdPrize-picture': `${baseURL}${candyEventData?.thirdPrize?.pictureUrl}`,

        'fourthPrize-goods': candyEventData?.fourthPrize?.goods,
        'fourthPrize-name': candyEventData?.fourthPrize?.name,
        'fourthPrize-picture': `${baseURL}${candyEventData?.fourthPrize?.pictureUrl}`,
        'sponsorBanner':`${baseURL}${candyEventData?.sponsor?.sponsorBanner}`,
         'sponsorUrl':`${baseURL}${candyEventData?.sponsor?.sponsorUrl}`,

         'firstPrizeCoupon':`${baseURL}${candyEventData?.firstPrizeCoupon}`,
         'secondPrizeCoupon':`${baseURL}${candyEventData?.secondPrizeCoupon}`,
         'thirdPrizeCoupon':`${baseURL}${candyEventData?.thirdPrizeCoupon}`,
         'fourthPrizeCoupon':`${baseURL}${candyEventData?.fourthPrizeCoupon}`,
      });

      setGlobalInfo({
        ...globalInfo,
        eventPictureUrl: `${baseURL}${candyEventData?.eventPictureUrl}`,
           });

      setGlobalInfo2({
        firstPrizeCoupon:`${candyEventData?.firstPrizeCoupon}`,
        secondPrizeCoupon:`${candyEventData?.secondPrizeCoupon}`,
        thirdPrizeCoupon:`${candyEventData?.thirdPrizeCoupon}`,
        fourthPrizeCoupon:`${candyEventData?.fourthPrizeCoupon}`,
     
      });

      setGlobalInfo1({
        sponsorBanner: `${baseURL}${candyEventData?.sponsor?.sponsorBanner}`,
        sponsorUrl:`${candyEventData?.sponsor?.sponsorUrl}`,
      });

      setFirstPrize({
        ...firstPrize,
        pictureUrl: `${baseURL}${candyEventData?.firstPrize?.pictureUrl}`,
      });
      setSecondPrize({
        ...secondPrize,
        pictureUrl: `${baseURL}${candyEventData?.secondPrize?.pictureUrl}`,
      });
      setThirdPrize({
        ...thirdPrize,
        pictureUrl: `${baseURL}${candyEventData?.thirdPrize?.pictureUrl}`,
      });
      setFourthPrize({
        ...thirdPrize,
        pictureUrl: `${baseURL}${candyEventData?.fourthPrize?.pictureUrl}`,
      });
    }
  }, []);

  const onGlobalInfoChangeHandler = (parameterKey: string, parameterValue: any) => {

    // console.log(parameterKey,"parameterKey")
    // console.log(parameterValue,"parameterValue")
    setGlobalInfo((currentGlobalInfo) => ({
      ...currentGlobalInfo,
      [parameterKey]: parameterValue,
    }));
  };

  const firstPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setFirstPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const secondPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setSecondPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const thirdPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setThirdPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const fourthPrizeChangeHandler = (parameterKey: string, parameterValue: any) => {
    setFourthPrize((currentState) => ({
      ...currentState,
      [parameterKey]: parameterValue,
    }));
  };

  const challenge_type="0";
  
  const [percentage, setPercentage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const onPublishHandler = async () => {
    let validationErrors;
    validateFields((errors, values) => {
      validationErrors = errors;
    });

    

    if (!validationErrors) {
      setPercentage(0);
      setShowModal(true);

      // create from data
      const formData = new FormData();
      formData.append('firstPrizeCoupon', coupon.state1);
      formData.append('secondPrizeCoupon', coupon.state2);
      formData.append('thirdPrizeCoupon', coupon.state3);
      formData.append('fourthPrizeCoupon', coupon.state4);
      formData.append('title', globalInfo.title);
      formData.append('month', sharedServices.toFormData(globalInfo.month));
      formData.append('eventPicture', globalInfo.eventPictureFile);
      formData.append('startDate', sharedServices.toFormData(globalInfo.startDate));
      formData.append('endDate', sharedServices.toFormData(globalInfo.endDate));

      formData.append('firstPrizeGoods', firstPrize.goods);
      formData.append('firstPrizeName', firstPrize.name);
      formData.append('firstPrizePicture', firstPrize.pictureFile);

      formData.append('secondPrizeGoods', secondPrize.goods);
      formData.append('secondPrizeName', secondPrize.name);
      formData.append('secondPrizePicture', secondPrize.pictureFile);

      formData.append('thirdPrizeGoods', thirdPrize.goods);
      formData.append('thirdPrizeName', thirdPrize.name);
      formData.append('thirdPrizePicture', thirdPrize.pictureFile);

      formData.append('fourthPrizeGoods',fourthPrize.goods);
      formData.append('fourthPrizeName', fourthPrize.name);
      formData.append('fourthPrizePicture', fourthPrize.pictureFile);

      formData.append("challenge_type","0");
      formData.append("sponsorUrl",Banner);
      formData.append("sponsorBanner",urlimg);

      // check if is editing or creating
      let candyEventID = candyEvent.ID;
      // console.log("arif123",candyEventID )
      // console.log(candyEventID,"candyEventID")
      if (!candyEvent.isEditing)
       {
        const create =  await candyEventService.createCandyEvent("",challenge_type);
        candyEventID = create.ID; 
        setCandy(candyEventID)
        // console.log(create,"create") 
        // console.log(candy,candyEventID,"candy")
      }

      const url = baseURL + candyEventUrl + '/' + candyEventID;

      // console.log(url,"url")

      const res =  await sharedServices.uploadFiles(
        url,
        formData,
        {
          onProgress: (perc: number) => {
            setPercentage(perc,);
          },
        },
        'PUT'
      );
      if (res.code === responseCodes.successful) {
        closeCreateCandyChallenge();
        // console.log(res,"res")
        return res.data;
      } else {
        throw `Response code: ${res.code}`;
      }
    }
    setShowModal(false);
  
  };

  const createCandyEventMutation = useMutation(onPublishHandler, {
    onSuccess: () =>
      queryClient.invalidateQueries(['CandyEventList', { country: selectedCountryCode }]),     
    onError: (error) => {
      candyEventService.showErrorMessage(serverError[language], error);
    },   
  });
 
const EnterCoupon=(value,index)=>{
  (console.log(value,index))
  if(index==1){
    SetCoupon({...coupon,state1:value})
  }
  else if(index==2){
    SetCoupon({...coupon,state2:value})
  }
  else if(index==3){
    SetCoupon({...coupon,state3:value})
  }
  
  else{
    SetCoupon({...coupon,state4:value})
  }
  //  SetCoupon(a)
  

}
const EnterBanner=(Banner)=>{
  setBanner(Banner)
}

const onUploadImg=(value)=>{
  
  seturl(value)
}

  // console.log('globalInfo', globalInfo);
  // console.log('firstPrize', firstPrize);
  // console.log('secondPrize', secondPrize);
  // console.log('thirdPrize', thirdPrize);

  return (
    <Row type="flex" justify="center" className="create-treasure">
      <Col className="candyEvent-create-container">
        <Row type="flex" justify="center" style={{ flex: 1 }}>
          <Col>
            <Typography.Title className="candyEvent-create-text candyEvent-create-title" level={4}>
              {candyEventStrings.candyEvent[language]}
            </Typography.Title>
          </Col>
        </Row>
        <Form>
          <CreateGlobalInput
            form={props.form}
            language={language}
            onChange={onGlobalInfoChangeHandler}
            title={globalInfo.title}
            month={globalInfo.month}
            eventPictureUrl={globalInfo.eventPictureUrl}
            EnterBanner={(Banner)=>EnterBanner(Banner)}
            onClickk={onUploadImg}
            data={GlobalInfo1}
            url={Banner}
          />
          
          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="firstPrize"
            label={candyEventStrings.firstPrize[language]}
            placeholder={candyEventStrings.firstPrizePlaceholder[language]}
            onChange={firstPrizeChangeHandler}
            goods={firstPrize.goods}
            name={firstPrize.name}
            pictureUrl={firstPrize.pictureUrl}
          />

          <OptionalFreeCoupon EnterCoupon={(value)=>EnterCoupon(value,1)} 
          coupon={GlobalInfo2} couponNumber={GlobalInfo2.firstPrizeCoupon}
          />
          
          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="secondPrize"
            label={candyEventStrings.secondPrize[language]}
            placeholder={candyEventStrings.secondPrizePlaceholder[language]}
            onChange={secondPrizeChangeHandler}
            goods={secondPrize.goods}
            name={secondPrize.name}
            pictureUrl={secondPrize.pictureUrl}
          />

          <OptionalFreeCoupon EnterCoupon={(value)=>EnterCoupon(value,2)} 
          coupon={GlobalInfo2} couponNumber={GlobalInfo2.secondPrizeCoupon}/>

          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="thirdPrize"
            label={candyEventStrings.thirdPrize[language]}
            placeholder={candyEventStrings.thirdPrizePlaceholder[language]}
            onChange={thirdPrizeChangeHandler}
            goods={thirdPrize.goods}
            name={thirdPrize.name}
            pictureUrl={thirdPrize.pictureUrl}
          />

          <OptionalFreeCoupon EnterCoupon={(value)=>EnterCoupon(value,3)} 
          coupon={GlobalInfo2} couponNumber={GlobalInfo2.thirdPrizeCoupon}
          />

          <CreatePrizeForm
            form={props.form}
            language={language}
            decorator="fourthPrize"
            label={candyEventStrings.fourthPrize[language]}
            placeholder={candyEventStrings.fourthPrizePlaceholder[language]}
            onChange={fourthPrizeChangeHandler}
            goods={fourthPrize.goods}
            name={fourthPrize.name}
            pictureUrl={fourthPrize.pictureUrl}
          />

          <OptionalFreeCoupon EnterCoupon={(value)=>EnterCoupon(value,4)} coupon={GlobalInfo2}  couponNumber={GlobalInfo2.fourthPrizeCoupon}
          />

        </Form>
      </Col>

      {/* Buttons at the bottom Cancel and Publish */}
      <Row className="review-buttons">
        <Col>
          <Row type="flex" justify="space-around" className="buttons-row">
            <Col>
              <Button
                className="buttons transparent-btn-white treasure-button-width"
                onClick={closeCreateCandyChallenge}
                 >
                <span style={{ textDecoration: 'underline' }}>
                  {candyEventStrings.cancel[language]}
                </span>
              </Button>
            </Col>
            <Col>
              <Button
                className="buttons yellow-gradient treasure-button-width"
                onClick={() => {
                  createCandyEventMutation.mutate();
                }}
              >
                {candyEvent.isEditing
                  ? candyEventStrings.save[language]
                  : candyEventStrings.publish[language]}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalLoadingProgressText
        loading={showModal}
        done={!showModal}
        percentage={percentage}
        text={'Files are uploading'}
      />

      {/* quickFill for testing */}
      {isDev && !candyEvent.isEditing && (
        <div className="quick-fill-container">
          <Button
            onClick={() => {
              fillCandyChallenge(
                form.setFieldsValue,
                setGlobalInfo,
                setFirstPrize,
                setSecondPrize,
                setThirdPrize,
               
              );
            }}
          >
            AUTOFILL
          </Button>
        </div>
      )}
    </Row>
  );
}

CreateCandyChallenge.propTypes = {
  language: PropTypes.string,
  selectedCountryCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  selectedCountryCode: state.candyEvent.selectedCountryCode,
  candyEvent: state.candyEvent,
});

const mapDispatchToProps = (dispatch) => ({
  closeCreateCandyChallenge: () => dispatch(menuActions.closeMenu()),
});

const wrappedCreateCandyChallenge = Form.create({ name: 'candyEvent'})(CreateCandyChallenge);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedCreateCandyChallenge);
