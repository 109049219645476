import React from 'react';
import { Chart } from "react-google-charts";
import { Col, Row, Spin, Typography, notification } from 'antd';
import AppContext from '../../../../AppContext';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import { baseURL } from '../../../../lib/config';

import logoInvoice from '../../../../../src/assets/logoInvoice.png';
import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';
import logoPic from '../../../../../src/assets/logoPic.png';

import { connect } from 'react-redux';
import {
  FileUpload,
  getDomeTypes,
  UpdateSetting,
} from '../../../../services/domeService';
import { Translation } from 'react-i18next';
import {b} from '../../../dashboard/menu/i18next'

export const data = [
  ["", "", ""],
  ["F M", 0, 0],
  ["F M", 1, 3],
  ["F M", 2, 7],
  ["F M", 5, 10],
  ["F M", 0, 0],
  ["F M", 0, 0],
  ["F M", 0, 0],
  ["F M", 0, 0],
];

export const options = {
  legend: {
    position: 'none', // Set the position to "none" to hide the legend
  },
};

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

const showSuccessMessage = (title) => {
  notification.success({
    message: `${title}`,
    duration: 10,
  });
};
class AdskitReportformat extends React.Component<any, any> {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      image: '',
      fileName: '',
      preview: '',
      file: '',
      outerwall: '',
    };
  }

  componentDidMount = async () => {
    const profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    this.setState({
      outerwall: current_edit_dome.outerwall,
    });
  };

  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  fileHandler = async (e) => {
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      let fileName = e.target.files[0].name;
      let preview = URL.createObjectURL(e.target.files[0]);
      const result =  await FileUpload(e.target.files[0]);
      let file = result.file;

      this.setState({
        image: image,
        fileName: fileName,
        preview: preview,
        file: file,
      });
    }
  };
  publish = async () => {
    if (!this.state.outerwall) {
      showErrorMessage(b?.t('Please select outerwall'));
      return false;
    }
    let profile =  await this.getContext();
    const current_edit_dome = profile['edit_current_dome'] || {};
    let id = current_edit_dome.ID;
    let obj = {
      outer_wall: this.state.outerwall,
    };
    const res =  await UpdateSetting(id, obj);
    const domeSetting =  await getDomeTypes(id);

    profile.domeTypeSetting = domeSetting.dome_type_setting;

    current_edit_dome.outerwall = this.state.outerwall;
    profile.current_edit_dome = current_edit_dome;
     await this.update(profile);

    showSuccessMessage(b?.t('Dome updated successfully.'));

    this.props.closeAdskitreport();
  };

  update = async (profile: any) => {
    let domeSettingList = profile.domeSettingList;
    let current_edit_dome = profile.current_edit_dome;
    domeSettingList[current_edit_dome.current_index] = current_edit_dome;
    profile.domeSettingList = domeSettingList;
     await this.updateContext(profile);
  };

  onChangeOuterWall = async (e) => {
    this.setState({
      outerwall: e.currentTarget.value,
    });
  };
  render() {

    const {translation}=this.props
    return (
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog">
        <div className="third-card relative">
          <div className="fx-jcsb-aic mb-20">
            <h4 className="text-primary mb-0 font-bold">&#62;{translation('Adskit Report format')}</h4>
            <i onClick={this.props.closeAdskitreport} className="iconx cursor">
              <img src={closeIcon} alt="Info" />
            </i>
          </div>

          <div className="domeAddContainer scrollSection">
            <div className="text-center">
              {/* <figure className="mb-3">
                <img src={logoPic} alt="logo" />
              </figure>
              <h2 className="text-muted mb-0">{translation('Coming soon')}</h2> */}
            </div>

            <div className='col-12'>
              <Row>
                <Col span={12}>
                  <figure>
                    <img src={logoInvoice} alt="logo" />
                  </figure>
                  <p className='text-dark size12'>
                  Armax Inc. <br />
                  4300 Stevens Creek Blvd <br />
                  Ste 250 San Jose <br />
                  CA 95129 USA
                  </p>
                </Col>
                <Col span={12}>
                  <h4 className='mb-0 text-dark font-bold size12'>Issued to:</h4>
                  <p className='size12 mb-10 text-dark'>Username: JohnB</p>
                  <p className='text-dark size12'>
                  Name: John Kim <br />
                  Email:armaxjohn@gmail.com <br />
                  Company Name: None
                  </p>
                </Col>
              </Row>
              <Row className='text-center mb-10'>
                <Col><Typography.Text className='font-bold'>Adskit Report</Typography.Text></Col>
              </Row>

              <Row>
                <table width="100%" style={{fontSize:'12px'}}>
                  <tr>
                    <td className='size12'>
                      Adskit number A1000018010 <br />
                      Name: ShakeShack
                    </td>
                    <td className='size12'>Based on Sep 29, 2023</td>
                  </tr>
                </table>
              </Row>

              <Row>
                <table className="tableCst" width="100%">
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td>Number of Units</td>
                      <td>Lcash per unit</td>
                      <td>Total Lcash</td>
                    </tr>
                    <tr>
                      <td>Flyer5 (0.04Lcash/unit)</td>
                      <td>10,000</td>
                      <td>0.04</td>
                      <td>0.04</td>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <td>Collected Units / %</td>
                      <td>Uncollected Units</td>
                      <td>Converted Lcash</td>
                    </tr>
                    <tr>
                      <td>2023/9/29-2023/9/30</td>
                      <td>6 / 0.06%</td>
                      <td>9,994 / 99.94%</td>
                      <td>399.76</td>
                    </tr>
                  </tbody>
                </table>
              </Row>
              
              <div className='chartDv'>
                <div className='unitno'>No of units</div>
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="100px"
                  data={data}
                  options={options}
                />
                <div className='genderText'>Gender</div>
              </div>
              
              <div className='ageData'>
                <span className='ageData__ageTitle'>Age</span>
                <span>11~20</span>
                <span>21~30</span>
                <span>31~40 </span>
                <span>41~50</span>
                <span>51~60</span>
                <span>61~70</span>
                <span>71~</span>
              </div>

              <Row>
                <Col span={14} offset={5}>
                  <table className="tableCst" width="100%">
                      <tbody>
                        <tr>
                          <td align='center'>Date</td>
                          <td align='center'>Collected Units</td>
                          <td align='center'>Uncollected Units</td>
                        </tr>
                        <tr>
                          <td align='center'>2023/9/29</td>
                          <td align='center'>2</td>
                          <td align='center'>9,998</td>
                        </tr>
                        <tr style={{background:'white'}}>
                          <td align='center'>2023/9/30</td>
                          <td align='center'>4</td>
                          <td align='center'>9,994</td>
                        </tr>
                      </tbody>
                    </table>
                </Col>
              </Row>
            </div>
            
          </div>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state:any) => ({
  translation: state.userData.translation,
});
export default connect(mapStateToProps, null) (AdskitReportformat)