import React from 'react';
import { Row, Button, Table, Input, Col ,notification } from 'antd';
import AppContext from '../../../AppContext';
import closeIcon from '../../../../src/assets/closeXsmall.svg';
import starIcon from '../../../../src/assets/starIcon.svg';
import logoPic from '../../../../src/assets/logoPic.png';
import { connect } from 'react-redux';
import * as treasureActions from '../../../actions/treasureActions';
import {totalAdditionalprize} from '../../../actions/Additionalprize'

interface AdditionalFormProps {
  totalNumberOfUnits:any
  translation:any,
  TotalLcashdata:any,
  MorePointsdata:any,
  SubTotalflash:any,
  TotalLcashflash:any,
  FlashUnitsflash:any,
  MorePointsflash: any, 
  MorePointsflash2:any,
  MorePointsflash3:any,
  MorePointsflash4:any,
  MorePointsflash5:any,
  PhysicalGoods1:any,
  PhysicalGoods2:any,
  PhysicalGoods3:any,
  PhysicalGoods4:any,
  PhysicalGoods5:any,
  PhysicalGoodsare:any,
  pointsPerUnit:any,
  price:any,
  treasure:any,


}

const actionCreators = {
  countTotalUnits: treasureActions.updateTotalNumberOfUnits,
  TotalLcashdata:totalAdditionalprize.TotalLcashdata,
    MorePointsdata:totalAdditionalprize.MorePointsdata,
    SubTotalflash:totalAdditionalprize.SubTotalflash,
    TotalLcashflash:totalAdditionalprize.TotalLcashflash,
    FlashUnitsflash:totalAdditionalprize.FlashUnitsflash,

    MorePointsflash:totalAdditionalprize.MorePointsflash,
    MorePointsflash2:totalAdditionalprize.MorePointsflash2,
    MorePointsflash3:totalAdditionalprize.MorePointsflash3,
    MorePointsflash4:totalAdditionalprize.MorePointsflash4,
    MorePointsflash5:totalAdditionalprize.MorePointsflash5,
 
    
    PhysicalGoods1:totalAdditionalprize.PhysicalGoods1,
    PhysicalGoods2:totalAdditionalprize.PhysicalGoods2,
    PhysicalGoods3:totalAdditionalprize.PhysicalGoods3,
    PhysicalGoods4:totalAdditionalprize.PhysicalGoods4,
    PhysicalGoods5:totalAdditionalprize.PhysicalGoods5,
    PhysicalGoodsare:totalAdditionalprize.PhysicalGoodsare
};

class AdditionalPrizes extends React.Component<AdditionalFormProps, any> {
  static contextType = AppContext;
  constructor(props: Readonly<AdditionalFormProps>)  {
    super(props);
    this.state = {
      physicalgoods: true,
      points: false,
      pointsperunit:'',
      pointsperunit1:'',
      pointsperunit2:'',
      pointsperunit3:'',
      pointsperunit4:'',
Lcash:'',
      Lcash1:'',
      Lcash2:'',
      Lcash3:'',
      Lcash4:'',
      PhysicalGoods_active: 'active',
      MorePoints_active:'',
      unitDataprice1:'',
      unitDataprice2:'',
      unitDataprice3:'',
      unitDataprice4:'',
      unitDataprice5:'',
      selectedRows:'',
       subTotal:'',
       RequiredLcash:'',
       phy_unit1:'',
        phy_unit2:'',
        phy_unit3:'',
        phy_unit4:'',
        phy_unit5:'',
       description1: '',
       description3: '',
       description2: '',
       description4: '',
       description5: '',
       title1: '',
        title3: '',
       title2: '',
       title4: '',
       title5: '',
       error:false,
       error1:false,
       error2:false,error3:false,error4:false,error5:false,error6:false,error7:false,
       error8:false,error9:false,
       enterbutton:false,
        enterbutton2:false,
       phyerror1:false,
        phyerror2:false,
       phyerror3:false,
       phyerror4:false,
       phyerror5:false,
        desc1:false,
         desc2:false,
        desc3:false,
        desc4:false,
        desc5:false,
       selectedRows1:'',
       unit_er1:false,
       unit_er2:false,
       unit_er3:false,
       unit_er4:false,
       unit_er5:false,
       plc1st:'',plc2st:'',plc3st:'',plc4st:'',plc5st:'',
       title_1:'',title_2:'',title_3:'',title_4:'',title_5:'',
       desc_1:'',desc_2:'',desc_3:'',desc_4:'',desc_5:'',
       additional_prize:'2',
       additional_prize2:'1',
       points1:'',
       points2:'',
       points3:'',
       points4:'',
       points5:'',
       Congrats1:false,
       Congrats2:false,
       Congrats3:false,
       Congrats4:false,
       Congrats5:false,
       Congrats6:false,
       Congrats7:false,
       Congrats8:false,
       Congrats9:false,
       Congrats10:false,
           };
  }

  
  getContext = async () => {
    const context = this.context;
    let profile = context.profile || {};
    return profile;
  };

  

  updateContext = async (data: any) => {
    const context = this.context;
    context.setProfile(data);
  };

  offadditionalprizes = async () => {
    let context = await this.getContext()
    context.additionalprizes = false;
    await this.updateContext(context);
  }
  PhysicalGoods = () => {
    this.setState({
      PhysicalGoods_active: 'active',
      MorePoints_active:'',
      physicalgoods: true,
      points: false
     
    })

  }
  
  MorePoints = () => {
    this.setState({
      PhysicalGoods_active: '',
      MorePoints_active:'active',
      points: true,
      physicalgoods: false
    })

  }
componentDidMount(): void {
    if(this.props.treasure.prize_type=='2'){
    this.setState({
      PhysicalGoods_active: '',
      MorePoints_active:'active',
      points: true,
      physicalgoods: false,
      unitDataprice1:this.props?.treasure?.flash_prize?.place_1st?.unit,
   place:this.props?.treasure?.flash_prize?.place_1st?.PointsperUnit,
  pointsperunit:this.props?.treasure?.flash_prize?.place_1st?.TotalPoints,
  Lcash:this.props?.treasure?.flash_prize?.place_1st?.RequiredLcash,

  unitDataprice2:this.props?.treasure?.flash_prize?.place_2nd?.unit,
  place1:this.props?.treasure?.flash_prize?.place_2nd?.PointsperUnit,
  pointsperunit1:this.props?.treasure?.flash_prize?.place_2nd?.TotalPoints,
  Lcash1:this.props?.treasure?.flash_prize?.place_2nd?.RequiredLcash,

  unitDataprice3:this.props?.treasure?.flash_prize?.place_3rd?.unit,
  place2:this.props?.treasure?.flash_prize?.place_3rd?.PointsperUnit,
  pointsperunit2:this.props?.treasure?.flash_prize?.place_3rd?.TotalPoints,
  Lcash2:this.props?.treasure?.flash_prize?.place_3rd?.RequiredLcash,

  unitDataprice4:this.props?.treasure?.flash_prize?.place_4th?.unit,
  place3:this.props?.treasure?.flash_prize?.place_4th?.PointsperUnit,
  pointsperunit3:this.props?.treasure?.flash_prize?.place_4th?.TotalPoints,
  Lcash3:this.props?.treasure?.flash_prize?.place_4th?.RequiredLcash,

  unitDataprice5:this.props?.treasure?.flash_prize?.place_5th?.unit,
  place4:this.props?.treasure?.flash_prize?.place_5th?.PointsperUnit,
  pointsperunit4:this.props?.treasure?.flash_prize?.place_5th?.TotalPoints,
  Lcash4:this.props?.treasure?.flash_prize?.place_5th?.RequiredLcash,
   SubTotal:this.props?.treasure?.MorePointsTotal,
   RequiredLcash:this.props?.treasure?.MorePoints,
    })
  }
  if(this.props.treasure.prize_type=='1'){
    this.setState({
      PhysicalGoods_active: 'active',
      MorePoints_active:'',
      physicalgoods: true,
      points: false,
      phy_unit1:this.props?.treasure?.flash_prize?.place_1st?.units,
      title1:this.props?.treasure?.flash_prize?.place_1st?.title,
      description1:this.props?.treasure?.flash_prize?.place_1st?.desc,

      phy_unit2:this.props?.treasure?.flash_prize?.place_2nd?.units,
      title2:this.props?.treasure?.flash_prize?.place_2nd?.title,
      description2:this.props?.treasure?.flash_prize?.place_2nd?.desc,

      phy_unit3:this.props?.treasure?.flash_prize?.place_3rd?.units,
      title3:this.props?.treasure?.flash_prize?.place_3rd?.title,
      description3:this.props?.treasure?.flash_prize?.place_3rd?.desc,

      phy_unit4:this.props?.treasure?.flash_prize?.place_4th?.units,
      title4:this.props?.treasure?.flash_prize?.place_4th?.title,
      description4:this.props?.treasure?.flash_prize?.place_4th?.desc,

      phy_unit5:this.props?.treasure?.flash_prize?.place_5th?.units,
      title5:this.props?.treasure?.flash_prize?.place_5th?.title,
      description5:this.props?.treasure?.flash_prize?.place_5th?.desc,
     
    })
  } 
} 
  update(selectedRows: any){
    let itemElements= 0;
    let  Lcashdata=0;
    selectedRows.forEach((item: any) => {
      if(item == "1" ){
        if (this.state.pointsperunit) {
          itemElements+=(this.state.pointsperunit),
          Lcashdata+=this.state.Lcash 
        }
      }
      
      if (item == "2" && this.state.pointsperunit1) {
        itemElements+=(this.state.pointsperunit1),
        Lcashdata+=this.state.Lcash1
      }
      if (item == "3"&& this.state.pointsperunit2) {
        itemElements+=(this.state.pointsperunit2),
        Lcashdata+=this.state.Lcash2
      }
      
      if (item == "4"&&this.state.pointsperunit3) {
        itemElements+=(this.state.pointsperunit3),
        Lcashdata+=this.state.Lcash3
      }
    

      if (item == "5"&&this.state.pointsperunit4) {
        itemElements+=(this.state.pointsperunit4),
        Lcashdata+=this.state.Lcash4
      }
      
    }); 
    this.setState({
      SubTotal:itemElements,
      selectedRows:selectedRows,
      RequiredLcash:Lcashdata
    }) 
  }

  xpointsperunit1ndPlace=(e:any)=>{
    const xplace1 = e.target.value.replace(/[^0-9]/g, '');
     const xplace=parseInt(xplace1)
    const place=parseInt(e.target.value)*parseInt(this.state.unitDataprice1)
    const Required=(this.props.price*place)/100
     const points=xplace+Required
    this.setState({
      pointsperunit:place,
      Lcash:Required,
      place:xplace,
      error1:false,
       points1:points,
      Congrats6:true,
       Congrats7:false,
       Congrats8:false,
       Congrats9:false,
       Congrats10:false,
    })
    setTimeout(() => {
      this.update(this.state.selectedRows)
    }, 1000);
  }
  xpointsperunit2ndPlace=(e:any)=>{
    const xplace1 = e.target.value.replace(/[^0-9]/g, '');
     const xplace=parseInt(xplace1)
    const place=parseInt(e.target.value)*this.state.unitDataprice2
    const Required=(this.props.price*place)/100
    const points=xplace+Required
    this.setState({
       pointsperunit1:place,
      Lcash1:Required,
       place1:xplace,
       error3:false,
       points2:points,
       Congrats6:false,
       Congrats7:true,
       Congrats8:false,
       Congrats9:false,
       Congrats10:false,
    })
    setTimeout(() => {
      this.update(this.state.selectedRows)
    }, 1000);
  }
  xpointsperunit3ndPlace=(e:any)=>{
    const xplace1 = e.target.value.replace(/[^0-9]/g, '');
     const xplace=parseInt(xplace1)
    const place=parseInt(e.target.value)*this.state.unitDataprice3
    const Required=(this.props.price*place)/100
    const points=xplace+Required
    this.setState({
       pointsperunit2:place,
       Lcash2:Required,
       place2:xplace,
       error5:false,
       points3:points,
       Congrats6:false,
       Congrats7:false,
       Congrats8:true,
       Congrats9:false,
       Congrats10:false,
    })
    setTimeout(() => {
      this.update(this.state.selectedRows)
    }, 1000);
  }
  xpointsperunit4ndPlace=(e:any)=>{
    const xplace1 = e.target.value.replace(/[^0-9]/g, '');
     const xplace=parseInt(xplace1)
    const place=parseInt(e.target.value)*this.state.unitDataprice4
    const Required=(this.props.price*place)/100
    const points=xplace+Required
    this.setState({
       pointsperunit3:place,
Lcash3:Required,
       place3:xplace,
       error7:false,
       points4:points,
       Congrats6:false,
       Congrats7:false,
       Congrats8:false,
       Congrats9:true,
       Congrats10:false,
    })
    setTimeout(() => {
      this.update(this.state.selectedRows)
    }, 1000);
  }
  xpointsperunit5ndPlace=(e:any)=>{
    const xplace1 = e.target.value.replace(/[^0-9]/g, '');
     const xplace=parseInt(xplace1)
    const place=parseInt(e.target.value)*this.state.unitDataprice5
    const Required=(this.props.price*place)/100
    const points=xplace+Required
    this.setState({
       pointsperunit4:place,
Lcash4:Required,
       place4:xplace,
       error9:false,
       points5:points,
       Congrats6:false,
       Congrats7:false,
       Congrats8:false,
       Congrats9:false,
       Congrats10:true,
    })
    setTimeout(() => {
      this.update(this.state.selectedRows)
    }, 1000);
  }
  unit1=(e:any)=>{
    const unitData = e.target.value.replace(/[^0-9]/g, '');
    // const unitData=parseInt(e.target.value)
    this.setState({
      unitDataprice1:unitData,
      error:false
    })
  }
  unit2=(e:any)=>{
    const unitData = e.target.value.replace(/[^0-9]/g, '');
    //const unitData=parseInt(e.target.value)
    this.setState({
      unitDataprice2:unitData,
      error2:false
    })
  }
  unit3=(e:any)=>{
    const unitData = e.target.value.replace(/[^0-9]/g, '');
    //const unitData=parseInt(e.target.value)
    this.setState({
      unitDataprice3:unitData,
      error4:false
    })
  }
  unit4=(e:any)=>{
    const unitData = e.target.value.replace(/[^0-9]/g, '');
    //const unitData=parseInt(e.target.value)
    this.setState({
      unitDataprice4:unitData,
      error6:false
    })
  }
  unit5=(e:any)=>{
    const unitData = e.target.value.replace(/[^0-9]/g, '');
    //const unitData=parseInt(e.target.value)
    this.setState({
      unitDataprice5:unitData,
      error8:false
    })
  }

  
     rowSelection = {
      onChange: (selectedRows: any) => { 
        if(selectedRows.length)
        {    
          var itemElements= 0;
          var  Lcashdata=0;
           selectedRows.forEach((item: any) => {
          if(item == "1" ){
            if (this.state.pointsperunit) {
              itemElements+=(this.state.pointsperunit),
              Lcashdata+=this.state.Lcash             
            }
            else{
               this.setState(
                {error:true,
                  error1:true
              });
            }
          }
          if(item == "2" ){
            if (this.state.pointsperunit1) {
              itemElements+=(this.state.pointsperunit1),
              Lcashdata+=this.state.Lcash1           
            }
            else{
               this.setState(
                {error2:true,
                 error3:true
              });
            }
          }
          if(item == "3" ){
            if (this.state.pointsperunit2) {
              itemElements+=(this.state.pointsperunit2),
              Lcashdata+=this.state.Lcash2            
            }
            else{
               this.setState(
                {error4:true,
                  error5:true
              });
            }
          }

          if(item == "4" ){
            if (this.state.pointsperunit3) {
              itemElements+=(this.state.pointsperunit3),
              Lcashdata+=this.state.Lcash3            
            }
            else{
               this.setState(
                {error6:true,
                  error7:true
              });
            }
          }                

          if(item == "5" ){
            if (this.state.pointsperunit4) {
              itemElements+=(this.state.pointsperunit4),
              Lcashdata+=this.state.Lcash4           
            }
            else{
               this.setState(
                {error8:true,
                  error9:true
              });
            }
           }         
         }); 
        //  if(selectedRows.length==5){
        //     this.setState({
        //       enterbutton:true
        //     })
        //  }
       
          this.setState({
          SubTotal:itemElements,
          selectedRows:selectedRows,
          RequiredLcash:Lcashdata,
           enterbutton:true
        }) 
        }
         else{
          this.setState({
            error:false,
            error1:false,
            error2:false,error3:false,error4:false,error5:false,error6:false,error7:false,
            error8:false,error9:false,
            enterbutton:false,
            SubTotal:'',
            RequiredLcash:''

          })
        }
      },
    };

 
    Place1st=(e:any)=>{
      const Units=parseInt(e.target.value)
      this.setState({phy_unit1:Units,unit_er1:false})
      setTimeout(() => {
        this.update1(this.state.selectedRows1)
      }, 1000);
      }

    Place2st=(e:any)=>{
      const Units=parseInt(e.target.value)
      this.setState({phy_unit2:Units,unit_er2:false})
      setTimeout(() => {
        this.update1(this.state.selectedRows1)
      }, 1000);
      }

      Place3st=(e:any)=>{
        const Units=parseInt(e.target.value)
        this.setState({phy_unit3:Units,unit_er3:false})
        setTimeout(() => {
          this.update1(this.state.selectedRows1)
        }, 1000);
        }

        Place4st=(e:any)=>{
          const Units=parseInt(e.target.value)
          this.setState({phy_unit4:Units,unit_er4:false})
          setTimeout(() => {
            this.update1(this.state.selectedRows1)
          }, 1000);
          
          }
       Place5st=(e:any)=>{
            const Units=parseInt(e.target.value)
            this.setState({phy_unit5:Units,unit_er5:false})
            setTimeout(() => {
              this.update1(this.state.selectedRows1)
            }, 1000);
            }
        
    onChangeHandler = ({ target: { name, value } }) => {
      if (value.length <= 60) {
        this.setState({
          [name]: value,
        });

        if(name=='description1')
        {
         this.setState({desc1:false,Congrats1:true,Congrats2:false,Congrats3:false,Congrats4:false,Congrats5:false})
         
        }
        if(name == 'description2')
        {
         this.setState({desc2:false,Congrats1:false,Congrats2:true,Congrats3:false,Congrats4:false,Congrats5:false}) 
        }
        if(name == 'description3')
        {
         this.setState({desc3:false,Congrats1:false,Congrats2:false,Congrats3:true,Congrats4:false,Congrats5:false})
        }
        if(name == 'description4')
        {
         this.setState({desc4:false,Congrats1:false,Congrats2:false,Congrats3:false,Congrats4:true,Congrats5:false})
        }
        if(name == 'description5')
        {
         this.setState({desc5:false,Congrats1:false,Congrats2:false,Congrats3:false,Congrats4:false,Congrats5:true})
        }
        setTimeout(() => {
          this.update1(this.state.selectedRows1)
        }, 1000);
      }
    };

    onChangeHandler2 = ({ target: { name, value } }) => {
      if (value.length <= 22) {
        this.setState({
          [name]: value,
  
        });
         if(name=='title1')
         {
          this.setState({phyerror1:false,Congrats1:true,Congrats2:false,Congrats3:false,Congrats4:false,Congrats5:false})
         }
         if(name == 'title2')
         {
          this.setState({phyerror2:false,Congrats1:false,Congrats2:true,Congrats3:false,Congrats4:false,Congrats5:false}) 
         }
         if(name == 'title3')
         {
          this.setState({phyerror3:false,Congrats1:false,Congrats2:false,Congrats3:true,Congrats4:false,Congrats5:false})
         }
         if(name == 'title4')
         {
          this.setState({phyerror4:false,Congrats1:false,Congrats2:false,Congrats3:false,Congrats4:true,Congrats5:false})
         }
         if(name == 'title5')
         {
          this.setState({phyerror5:false,Congrats1:false,Congrats2:false,Congrats3:false,Congrats4:false,Congrats5:true})
         }
        
        setTimeout(() => {
          this.update1(this.state.selectedRows1)
        }, 1000);
      }
    }; 

     update1 = (selectedRows1:any) => {
      if(selectedRows1.length){
        selectedRows1.forEach((item: any) => {
          if(item == "1" ){
            if (this.state.phy_unit1) {
               const plc1st=this.state.phy_unit1
               this.setState({plc1st:plc1st})
            }
            else{
               this.setState(
                {unit_er1:true});
            }
            if (this.state.title1) {
                 const title_1=  this.state.title1 
                 this.setState({title_1:title_1}) 
            }
            else{
               this.setState(
                {phyerror1:true});
            }
            if(this.state.description1){
             const desc_1= this.state.description1
             this.setState({desc_1:desc_1})
            }
            else{
               this.setState({desc1:true})
            }
          }
          if(item == "2" ){
            if (this.state.phy_unit2 ) {
              const plc2st=this.state.phy_unit2
               this.setState({plc2st:plc2st})            
            }
            else{
               this.setState({unit_er2:true });
            }
            if (this.state.title2 ) {
              const title_2=  this.state.title2 
                 this.setState({title_2:title_2})                
            }
            else{
               this.setState({phyerror2:true,});
            }
            if(this.state.description2){
              const desc_2= this.state.description2
              this.setState({desc_2:desc_2})
             }
             else{
               this.setState({desc2:true})
            }
          }
          if(item == "3" ){
            if (this.state.phy_unit3 ) {
              const plc3st=this.state.phy_unit3
              this.setState({plc3st:plc3st})                
            }
            else{
               this.setState(
                {unit_er3:true });
            }
            if (this.state.title3) {
              const title_3=  this.state.title3 
              this.setState({title_3:title_3})                  
            }
            else{
               this.setState(
                {phyerror3:true});
            }
            if(this.state.description3){
              const desc_3= this.state.description3
              this.setState({desc_3:desc_3})
             
             }
             else{
              this.setState({desc3:true})
           } 
          }
          if(item == "4" ){
            if (this.state.phy_unit4 ) {
              const plc4st=this.state.phy_unit4
              this.setState({plc4st:plc4st})              
            }
            else{
               this.setState(
                {unit_er4:true });
            }
            if (this.state.title4) {
              const title_4=  this.state.title4
              this.setState({title_4:title_4})                 
            }
            else{
               this.setState(
                {phyerror4:true});
            }
            if(this.state.description4){
              const desc_4= this.state.description4
              this.setState({desc_4:desc_4})
             }
             else{
              this.setState({desc4:true})
           } 
          }
          if(item == "5" ){
            if (this.state.phy_unit5 ) {
              const plc5st=this.state.phy_unit5
              this.setState({plc5st:plc5st})                   
            }
            else{
               this.setState(
                {unit_er5:true });
            }
            if (this.state.title5) {
              const title_5=  this.state.title5
              this.setState({title_5:title_5})                
            }
            else{
               this.setState(
                {phyerror5:true});
            }
            if(this.state.description5){
              const desc_5= this.state.description5
              this.setState({desc_5:desc_5})
             }
             else{
              this.setState({desc5:true})
           } 
          }
        })
      //   if(selectedRows1.length==5){
      //     this.setState({
      //       enterbutton2:true
      //     })
      //  }
        this.setState({
          selectedRows1:selectedRows1,
          enterbutton2:true
        }) 
       }
       
    else{
      this.setState({phyerror1:false,
         phyerror2:false,
         phyerror3:false,
         phyerror4:false,
         phyerror5:false,
        desc1:false,
         desc3:false,
        desc2:false,
        desc4:false,
        desc5:false,
        enterbutton2:false,
        unit_er1:false,
        unit_er2:false,
        unit_er3:false,
        unit_er4:false,
        unit_er5:false,
      })
    }
       
      }
    

     rowSelection1 = {
      onChange: (selectedRows:any) => {
       if(selectedRows.length){
        selectedRows.forEach((item: any) => {
          if(item == "1" ){
            if (this.state.phy_unit1) {
               const plc1st=this.state.phy_unit1
               this.setState({plc1st:plc1st})
            }
            else{
               this.setState(
                {unit_er1:true});
            }
            if (this.state.title1) {
                 const title_1=  this.state.title1 
                 this.setState({title_1:title_1}) 
            }
            else{
               this.setState(
                {phyerror1:true});
            }
            if(this.state.description1){
             const desc_1= this.state.description1
             this.setState({desc_1:desc_1})
            }
            else{
               this.setState({desc1:true})
            }
          }
          if(item == "2" ){
            if (this.state.phy_unit2 ) {
              const plc2st=this.state.phy_unit2
               this.setState({plc2st:plc2st})            
            }
            else{
               this.setState({unit_er2:true });
            }
            if (this.state.title2 ) {
              const title_2=  this.state.title2 
                 this.setState({title_2:title_2})                
            }
            else{
               this.setState({phyerror2:true,});
            }
            if(this.state.description2){
              const desc_2= this.state.description2
              this.setState({desc_2:desc_2})
             }
             else{
               this.setState({desc2:true})
            }
          }
          if(item == "3" ){
            if (this.state.phy_unit3 ) {
              const plc3st=this.state.phy_unit3
              this.setState({plc3st:plc3st})                
            }
            else{
               this.setState(
                {unit_er3:true });
            }
            if (this.state.title3) {
              const title_3=  this.state.title3 
              this.setState({title_3:title_3})                  
            }
            else{
               this.setState(
                {phyerror3:true});
            }
            if(this.state.description3){
              const desc_3= this.state.description3
              this.setState({desc_3:desc_3})
             
             }
             else{
              this.setState({desc3:true})
           } 
          }
          if(item == "4" ){
            if (this.state.phy_unit4 ) {
              const plc4st=this.state.phy_unit4
              this.setState({plc4st:plc4st})              
            }
            else{
               this.setState(
                {unit_er4:true });
            }
            if (this.state.title4) {
              const title_4=  this.state.title4
              this.setState({title_4:title_4})                 
            }
            else{
               this.setState(
                {phyerror4:true});
            }
            if(this.state.description4){
              const desc_4= this.state.description4
              this.setState({desc_4:desc_4})
             }
             else{
              this.setState({desc4:true})
           } 
          }
          if(item == "5" ){
            if (this.state.phy_unit5 ) {
              const plc5st=this.state.phy_unit5
              this.setState({plc5st:plc5st})                   
            }
            else{
               this.setState(
                {unit_er5:true });
            }
            if (this.state.title5) {
              const title_5=  this.state.title5
              this.setState({title_5:title_5})                
            }
            else{
               this.setState(
                {phyerror5:true});
            }
            if(this.state.description5){
              const desc_5= this.state.description5
              this.setState({desc_5:desc_5})
             }
             else{
              this.setState({desc5:true})
           } 
          }
        })
      //   if(selectedRows.length==5){
      //     this.setState({
      //       enterbutton2:true
      //     })
      //  }
        this.setState({
          selectedRows1:selectedRows,
           enterbutton2:true
        }) 
       }

       
    else{
      this.setState({phyerror1:false,
         phyerror2:false,
         phyerror3:false,
         phyerror4:false,
         phyerror5:false,
        desc1:false,
         desc3:false,
        desc2:false,
        desc4:false,
        desc5:false,
        enterbutton2:false,
        unit_er1:false,
        unit_er2:false,
        unit_er3:false,
        unit_er4:false,
        unit_er5:false,
      })
    }
        
      },
    };
    
    enterbutton=()=>{
      this.props.MorePointsdata(this.state.RequiredLcash)
      this.props.SubTotalflash(this.state.SubTotal)
      this.props.TotalLcashflash(this.state.RequiredLcash+this.props.totalNumberOfUnits*this.props.price)
      this.props.FlashUnitsflash(this.props.totalNumberOfUnits*this.props.price)
      this.props.MorePointsflash({unit:this.state.unitDataprice1,PointsperUnit:this.state.place,TotalPoints:this.state.pointsperunit,RequiredLcash:this.state.Lcash})
      this.props.MorePointsflash2({unit:this.state.unitDataprice2,PointsperUnit:this.state.place1,TotalPoints:this.state.pointsperunit1,RequiredLcash:this.state.Lcash1})
      this.props.MorePointsflash3({unit:this.state.unitDataprice3,PointsperUnit:this.state.place2,TotalPoints:this.state.pointsperunit2,RequiredLcash:this.state.Lcash2})
      this.props.MorePointsflash4({unit:this.state.unitDataprice4,PointsperUnit:this.state.place3,TotalPoints:this.state.pointsperunit3,RequiredLcash:this.state.Lcash3})
      this.props.MorePointsflash5({unit:this.state.unitDataprice5,PointsperUnit:this.state.place4,TotalPoints:this.state.pointsperunit4,RequiredLcash:this.state.Lcash4})
      this.props.PhysicalGoodsare(this.state.additional_prize)
     this.offadditionalprizes()
    this.showSuccessMessage()
    }

    enterbutton2=()=>{
      this.props.PhysicalGoods1({units:this.state.plc1st,title:this.state.title_1,desc:this.state.desc_1,})
      this.props.PhysicalGoods2({units:this.state.plc2st,title:this.state.title_2,desc:this.state.desc_2,})
      this.props.PhysicalGoods3({units:this.state.plc3st,title:this.state.title_3,desc:this.state.desc_3,})
      this.props.PhysicalGoods4({units:this.state.plc4st,title:this.state.title_4,desc:this.state.desc_4,})
      this.props.PhysicalGoods5({units:this.state.plc5st,title:this.state.title_5,desc:this.state.desc_5,})
      this.props.PhysicalGoodsare(this.state.additional_prize2)
      this.offadditionalprizes()
      this.showSuccessMessage()
    }

     showSuccessMessage = () => {
      notification.success({
        message: 'Successfully Additional Prizes set',
        duration: 10,
      });
    };

    thousandSeparators = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

  render() {
    //const SubTotal=this.state.pointsperunit+this.state.pointsperunit1+this.state.pointsperunit2+this.state.pointsperunit3+this.state.pointsperunit4
    const {totalNumberOfUnits,price,treasure} = this.props
    console.log(treasure,"treasure")
     const flashNumber=totalNumberOfUnits*price
     const FlashUnits=parseInt(this.state.RequiredLcash)+totalNumberOfUnits*price
   
    const { TextArea } = Input;
    const columns = [
      {
        title: 'Prizes',
        dataIndex: 'prizes',      
      },
      {
        title: 'Units',
        dataIndex: 'unit',  
        width: '60px',     
      },
      {
        title: 'x Points per Unit =',
        dataIndex: 'x_points_per_unit',
      },
      {
        title: 'Total Points',
        dataIndex: 'total_points',
      },
      {
        title: '(Required Lcash)',
        dataIndex: 'required_lcash',
      },
    ];

    const data = [
      {
        key: '1',
        prizes: '1st Place',
        unit: <><Input size="small" id="numericInput" value={this.state.unitDataprice1?this.state.unitDataprice1:''} onChange={this.unit1} /><span className={this.state.error?'error er1':'error er1 hideNow'} >Enter the number</span></> ,
        x_points_per_unit: <><Input onChange={this.xpointsperunit1ndPlace} size="small"id="numericInput" value={this.state.place?this.state.place:''} style={{width:'76px'}}  /><span className={this.state.error1?'error er2':'error er2 hideNow'}>Enter the number</span></> ,
        total_points: <span className='onlyText'> {this.state.pointsperunit?this.state.pointsperunit:""}</span>,
        required_lcash: <span className='onlyText'>{this.state.Lcash?this.state.Lcash:''}</span>,
      },
      {
        key: '2',
        prizes: '2nd Place',
        unit: <><Input size="small" id="numericInput" value={this.state.unitDataprice2?this.state.unitDataprice2:''} onChange={this.unit2} /><span className={this.state.error2?'error er1':'error er1 hideNow'}>Enter the number</span></> ,
        x_points_per_unit: <><Input onChange={this.xpointsperunit2ndPlace}   size="small" id="numericInput" value={this.state.place1?this.state.place1:''} style={{width:'76px'}}  /><span className={this.state.error3?'error er2':'error er2 hideNow'}>Enter the number</span></> ,
        total_points: <span className='onlyText'>{this.state.pointsperunit1?this.state.pointsperunit1:""}</span>,
        required_lcash: <span className='onlyText'>{this.state.Lcash1?this.state.Lcash1:''}</span>,
      },
      {
        key: '3',
        prizes: '3rd Place',
        unit: <><Input size="small" id="numericInput" value={this.state.unitDataprice3?this.state.unitDataprice3:''} onChange={this.unit3} /><span className={this.state.error4?'error er1':'error er1 hideNow'}>Enter the number</span></> ,
        x_points_per_unit: <><Input onChange={this.xpointsperunit3ndPlace} size="small" id="numericInput" value={this.state.place2?this.state.place2:''} style={{width:'76px'}} /><span className={this.state.error5?'error er2':'error er2 hideNow'}>Enter the number</span></> ,
        total_points: <span className='onlyText'>{this.state.pointsperunit2?this.state.pointsperunit2:""}</span>,
        required_lcash: <span className='onlyText'>{this.state.Lcash2?this.state.Lcash2:''}</span>,
      },
      {
        key: '4',
        prizes: '4th Place',
        unit: <><Input size="small" id="numericInput" value={this.state.unitDataprice4?this.state.unitDataprice4:''} onChange={this.unit4}/><span className={this.state.error6?'error er1':'error er1 hideNow'}>Enter the number</span></> ,
        x_points_per_unit: <><Input onChange={this.xpointsperunit4ndPlace} size="small" id="numericInput" value={this.state.place3?this.state.place3:''} style={{width:'76px'}} /><span className={this.state.error7?'error er2':'error er2 hideNow'}>Enter the number</span></> ,
        total_points: <span className='onlyText'>{this.state.pointsperunit3?this.state.pointsperunit3:""}</span>,
        required_lcash: <span className='onlyText'>{this.state.Lcash3?this.state.Lcash3:''}</span>,
      },
      {
        key: '5',
        prizes: '5th Place',
        unit: <><Input size="small" id="numericInput" value={this.state.unitDataprice5?this.state.unitDataprice5:''} onChange={this.unit5}/><span className={this.state.error8?'error er1':'error er1 hideNow'}>Enter the number</span></> ,
        x_points_per_unit: <><Input onChange={this.xpointsperunit5ndPlace} size="small" id="numericInput" value={this.state.place4?this.state.place4:''} style={{width:'76px'}} /><span className={this.state.error9?'error er2':'error er2 hideNow'}>Enter the number</span></> ,
        total_points: <span className='onlyText'>{this.state.pointsperunit4?this.state.pointsperunit4:""}</span>,
        required_lcash: <span className='onlyText'>{this.state.Lcash4?this.state.Lcash4:''}</span>,
      },
    ];
    const columns1 = [
      {
        title: 'Prizes',
        dataIndex: 'prizes',
      },
      {
        title: 'Units x',
        dataIndex: 'unit_x',
      },
      {
        title: 'Physical Goods',
        dataIndex: 'physical_Goods',
      }
    ];


    const data1 = [
      {
        key: '1',
        prizes: '1st Place',
        unit_x: <><Input size="small" value={this.state.phy_unit1} onChange={this.Place1st} style={{width:'40px'}} /><p className={this.state.unit_er1?'error unitx_er':'error unitx_er hideNow'}>Enter the number</p></>,
        physical_Goods: 
        <div>
          <Row className='mb-5'>
            <Col span={12}>
              Title of the Goods:
            </Col>
            <Col span={12}>
              <div style={{position:'relative'}}>
                <p className={this.state.phyerror1?'error':'error hideNow'}>Fill in the blank</p>
                <Input 
                value={this.state.title1}
                onChange={this.onChangeHandler2} 
                name='title1' 
                className='pr-40' 
                size="small"
                />
                <div
                    className='catLimit'
                      style={{
                        right: 5,
                        color: `${
                          this.state.title1.length < 20
                            ? 'lightgrey'
                            : 'red'
                        }`,
                      }}
                      >
                      {22 - this.state.title1.length}/22
                  </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>Description:</Col>
            <Col span={16}>
              <div style={{ position: 'relative' }}>
                <TextArea
                  rows={2}
                  onChange={this.onChangeHandler}
                  name="description1"
                  data-index="-1"
                  value={this.state.description1}
                  className="form-control xss rd-0"
                />
                <p className={this.state.desc1?'error':'error hideNow'}>Fill in the blank</p>
                <div
                  className='catLimit'
                  style={{
                    right: 10,
                    color: `${
                      this.state.description1.length < 55
                        ? 'lightgrey'
                        : 'red'
                    }`,
                  }}
                  >
                  {60 - this.state.description1.length}/60
                </div>
              </div>
            </Col>
          </Row>
        </div>,
      },
      {
        key: '2',
        prizes: '2nd Place',
        unit_x: <><Input size="small" value={this.state.phy_unit2} onChange={this.Place2st} style={{width:'40px'}} /><p className={this.state.unit_er2?'error unitx_er':'error unitx_er hideNow'}>Enter the number</p></> ,
        physical_Goods: 
        <div>
        <Row className='mb-5'>
          <Col span={12}>Title of the Goods:</Col>
          <Col span={12}>
            <div style={{position:'relative'}}>
              <p className={this.state.phyerror2?'error':'error hideNow'}>Fill in the blank</p>
              <Input 
              value={this.state.title2}
              onChange={this.onChangeHandler2} 
              name='title2' 
              className='pr-40' 
              size="small"
              />
              <div
                  className='catLimit'
                    style={{
                      right: 5,
                      color: `${
                        this.state.title2.length < 20
                          ? 'lightgrey'
                          : 'red'
                      }`,
                    }}
                    >
                    {22 - this.state.title2.length}/22
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>Description:</Col>
          <Col span={16}>
            <div style={{ position: 'relative' }}>
              <TextArea
                rows={2}
                onChange={this.onChangeHandler}
                name="description2"
                data-index="-1"
                value={this.state.description2}
                className="form-control xss rd-0"
              />
              <p className={this.state.desc2?'error':'error hideNow'}>Fill in the blank</p>
              <div
                className='catLimit'
                style={{
                  right: 10,
                  color: `${
                    this.state.description2.length < 55
                      ? 'lightgrey'
                      : 'red'
                  }`,
                }}
                >
                {60 - this.state.description2.length}/60
              </div>
            </div>
          </Col>
        </Row>
      </div>,
      },
      {
        key: '3',
        prizes: '3rd Place',
        unit_x: <><Input size="small" value={this.state.phy_unit3} onChange={this.Place3st}  style={{width:'40px'}} /><p className={this.state.unit_er3?'error unitx_er':'error unitx_er hideNow'}>Enter the number</p></> ,
        physical_Goods: 
        <div>
        <Row className='mb-5'>
          <Col span={12}>Title of the Goods:</Col>
          <Col span={12}>
            <div style={{position:'relative'}}>
              <p className={this.state.phyerror3?'error':'error hideNow'}>Fill in the blank</p>
              <Input 
              value={this.state.title3}
              onChange={this.onChangeHandler2} 
              name='title3' 
              className='pr-40' 
              size="small"
              />
              <div
                  className='catLimit'
                    style={{
                      right: 5,
                      color: `${
                        this.state.title3.length < 20
                          ? 'lightgrey'
                          : 'red'
                      }`,
                    }}
                    >
                    {22 - this.state.title3.length}/22
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>Description:</Col>
          <Col span={16}>
            <div style={{ position: 'relative' }}>
              <TextArea
                rows={2}
                onChange={this.onChangeHandler}
                name="description3"
                data-index="-1"
                value={this.state.description3}
                className="form-control xss rd-0"
              />
              <p className={this.state.desc3?'error':'error hideNow'}>Fill in the blank</p>
              <div
                className='catLimit'
                style={{
                  right: 10,
                  color: `${
                    this.state.description3.length < 55
                      ? 'lightgrey'
                      : 'red'
                  }`,
                }}
                >
                {60 - this.state.description3.length}/60
              </div>
            </div>
          </Col>
        </Row>
      </div>,
      },
      {
        key: '4',
        prizes: '4th Place',
        unit_x: <><Input size="small" value={this.state.phy_unit4} onChange={this.Place4st}  style={{width:'40px'}} /><p className={this.state.unit_er4?'error unitx_er':'error unitx_er hideNow'}>Enter the number</p></> ,
        physical_Goods: 
        <div>
        <Row className='mb-5'>
          <Col span={12}>Title of the Goods:</Col>
          <Col span={12}>
            <div style={{position:'relative'}}>
              <p className={this.state.phyerror4?'error':'error hideNow'}>Fill in the blank</p>
              <Input 
              value={this.state.title4}
              onChange={this.onChangeHandler2} 
              name='title4' 
              className='pr-40' 
              size="small"
              />
              <div
                  className='catLimit'
                    style={{
                      right: 5,
                      color: `${
                        this.state.title4.length < 20
                          ? 'lightgrey'
                          : 'red'
                      }`,
                    }}
                    >
                    {22 - this.state.title4.length}/22
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>Description:</Col>
          <Col span={16}>
            <div style={{ position: 'relative' }}>
              <TextArea
                rows={2}
                onChange={this.onChangeHandler}
                name="description4"
                data-index="-1"
                value={this.state.description4}
                className="form-control xss rd-0"
              />
              <p className={this.state.desc4?'error':'error hideNow'}>Fill in the blank</p>
              <div
                className='catLimit'
                style={{
                  right: 10,
                  color: `${
                    this.state.description4.length < 55
                      ? 'lightgrey'
                      : 'red'
                  }`,
                }}
                >
                {60 - this.state.description4.length}/60
              </div>
            </div>
          </Col>
        </Row>
      </div>,
      },
      {
        key: '5',
        prizes: '5th Place',
        unit_x: <><Input size="small" value={this.state.phy_unit5} onChange={this.Place5st}  style={{width:'40px'}} /><p className={this.state.unit_er5?'error unitx_er':'error unitx_er hideNow'}>Enter the number</p></> ,
        physical_Goods: 
        <div>
        <Row className='mb-5'>
          <Col span={12}>Title of the Goods:</Col>
          <Col span={12}>
            <div style={{position:'relative'}}>
              <p className={this.state.phyerror5?'error':'error hideNow'}>Fill in the blank</p>
              <Input 
              value={this.state.title5}
              onChange={this.onChangeHandler2} 
              name='title5' 
              className='pr-40' 
              size="small"
              />
              <div
                  className='catLimit'
                    style={{
                      right: 5,
                      color: `${
                        this.state.title5.length < 20
                          ? 'lightgrey'
                          : 'red'
                      }`,
                    }}
                    >
                    {22 - this.state.title5.length}/22
                </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>Description:</Col>
          <Col span={16}>
            <div style={{ position: 'relative' }}>
              <TextArea
                rows={2}
                onChange={this.onChangeHandler}
                name="description5"
                data-index="-1"
                value={this.state.description5}
                className="form-control xss rd-0"
              />
              <p className={this.state.desc5?'error':'error hideNow'}>Fill in the blank</p>
              <div
                className='catLimit'
                style={{
                  right: 10,
                  color: `${
                    this.state.description5.length < 55
                      ? 'lightgrey'
                      : 'red'
                  }`,
                }}
                >
                {60 - this.state.description5.length}/60
              </div>
            </div>
          </Col>
        </Row>
      </div>,
      },
    ];

    return (
      <>

        <Row className="menu-wrapper treasure-menu-wrapper second-dialog bigger5x z-index-13">
          <div className="third-card relative">
            <div className="fx-jcsb-aic mb-20">
              <h4 className="text-primary mb-0 font-bold">&#62;Additional Prizes as an option</h4>
              <i onClick={this.offadditionalprizes} className="iconx cursor">
                <img src={closeIcon} alt="Info" />
              </i>
            </div>
            <br></br>

            <div className="domeAddContainer scrollSection">
              <div className='fx-jcc mb-10'>
                <button  onClick={this.PhysicalGoods}  className={'ant-btn primary-ghost-btn xs mr-5 ' + this.state.PhysicalGoods_active}>Physical Goods</button>
                <button className={'ant-btn primary-ghost-btn xs ml-5 '+ this.state.MorePoints_active} onClick={this.MorePoints}>More Points</button>
              </div>

              {
                this.state.physicalgoods &&
                <div id="physicalGoodsCard">
                  <div className='additionalPrizesTable'>
                    <Table pagination={false} rowSelection={this.rowSelection1} columns={columns1} dataSource={data1} />
                  </div>
                  
                  <p className='size11 text-center'>Note: All units above are included in the Number of Flash Units on the left section.</p>

                  <div className='text-center'>
                    <button onClick={this.offadditionalprizes}  className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
                    {this.props.treasure.prize_type=='1'?
                     <button onClick={this.enterbutton2} className='ant-btn xs rd-5 ml-5 yellow-gradient'>Edit</button> :
                    <button  onClick={this.enterbutton2} className={this.state.enterbutton2?'ant-btn xs rd-5 ml-5 yellow-gradient':'ant-btn xs rd-5 ml-5 event-none' }>Enter</button>}
                  </div>
                </div>
              }

              {this.state.points &&
                <div id='morePointsCard'>
                  <div className='additionalPrizesTable'>
                  <Table pagination={false} rowSelection={this.rowSelection} columns={columns} dataSource={data}/>
                  </div>
                  <p className='size11 text-center'>Note: All units above are included in the Number of Flash Units on the left section.</p>
                  <div className='subtotal-block'>
                    <Row type='flex' justify='space-between' className='py-10'>
                      <Col span={15} className='text-dark size13'>Sub-Total (Lcash) for the More Points</Col>
                      <Col span={9} className='fx-jcsb'>
                        <span style={{ width: '85px' }} className='onlyText'>{this.state.SubTotal?this.state.SubTotal:''}</span>
                        <span style={{ width: '85px' }} className='onlyText'>{this.state.RequiredLcash?this.state.RequiredLcash:''}</span>
                      </Col>
                    </Row>
                    <Row type='flex' justify='space-between' className='py-10'>
                      <Col span={15} className='text-dark size13'>Sub-Total (Lcash) for the Number of Flash Units</Col>
                      <Col span={9} className='fx-jcfe'>
                        <span style={{ width: '85px' }} className='onlyText'>{flashNumber?flashNumber:''}</span>
                      </Col>
                    </Row>
                  </div>

                  <Row type='flex' justify='space-between' className='py-10 pr-10'>
                    <Col span={15} className='text-dark size13 font-bold'>Total (Lcash)</Col>
                    <Col span={9} className='fx-jcfe'>
                      <span style={{ width: '85px' }} className='onlyText'>{FlashUnits?FlashUnits:""}</span>
                      {/* <Input style={{ width: '80px' }} placeholder='1,410' size="small" /> */}
                    </Col>
                  </Row>

                  <div className='text-center'>
                    <button onClick={this.offadditionalprizes}  className='ant-btn primary-ghost-btn xs mr-5'>Cancel</button>
                    {this.props.treasure.prize_type=='2'?
                   <button onClick={this.enterbutton} className='ant-btn xs rd-5 ml-5 yellow-gradient'>Edit</button> :
                   <button  onClick={this.enterbutton} className={this.state.enterbutton?'ant-btn xs rd-5 ml-5 yellow-gradient':'ant-btn xs rd-5 ml-5 event-none'}>Enter</button> }
                  </div>
                </div>
              }

            </div>
          </div>
        </Row>
        {this.state.Congrats1 &&
            <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>1st Prize</h1>
              <h4>{this.state.title1}</h4>
               <p>{this.state.description1}</p>   
          </div>}

        {this.state.Congrats2 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>2nd Prize</h1>
              <h4>{this.state.title2}</h4>
              <p>{this.state.description2}</p>     
        </div>}

        {this.state.Congrats3 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>3rd Prize</h1>
              <h4>{this.state.title3}</h4>
              <p>{this.state.description3}</p>     
        </div>}
        {this.state.Congrats4 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>4th Prize</h1>
              <h4>{this.state.title4}</h4>
              <p>{this.state.description4}</p>     
        </div>}
        {this.state.Congrats5 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>5th Prize</h1>
              <h4>{this.state.title5}</h4>
              <p>{this.state.description5}</p>     
        </div>}
        
        {this.state.Congrats6 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>1st Prize</h1> 
              <h4 className='pointsx'>{this.thousandSeparators(this.state.points1?this.state.points1:"")}</h4>
              <p className='pointTx'>Points</p>
        </div>
        }
        {this.state.Congrats7 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>2nd Prize</h1> 
              <h4 className='pointsx'>{this.thousandSeparators(this.state.points2?this.state.points2:"")}</h4>
              <p className='pointTx'>Points</p>
        </div>
        }
        {this.state.Congrats8 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>3rd Prize</h1> 
              <h4 className='pointsx'>{this.thousandSeparators(this.state.points3?this.state.points3:"")}</h4>
              <p className='pointTx'>Points</p>
        </div>
        }
        {this.state.Congrats9 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>4th Prize</h1> 
              <h4 className='pointsx'>{this.thousandSeparators(this.state.points4?this.state.points4:"")}</h4>
              <p className='pointTx'>Points</p>
        </div>
        }
        {this.state.Congrats10 &&
        <div className='congratsModal'>
              <button className='closeDark'></button>
              <img src={starIcon} alt="icon" />
              <span>Congrats</span>
              <h6>You received</h6>
              <h1>5th Prize</h1> 
              <h4 className='pointsx'>{this.thousandSeparators(this.state.points5?this.state.points5:"")}</h4>
              <p className='pointTx'>Points</p>
        </div>
        }
      </>
    );
  }
}
const mapStateToProps = (state: any) => (
  {
    translation: state.userData.translation,
    totalNumberOfUnits:state.treasure.totalNumberOfUnits,
    pointsPerUnit:state.treasure.pointsPerUnit,
    price:state.treasure.price,
    treasure:state.treasure,
  } 
);

export default connect(mapStateToProps, actionCreators)(AdditionalPrizes);