import React from 'react';

import { Row, Spin } from 'antd';
import AppContext from '../../../../AppContext';


import closeIcon from '../../../../../src/assets/closeXsmall.svg';

import locationIcon from '../../../../../src/assets/location-on-map.svg';
import infoIcon from '../../../../../src/assets/icon_info.svg';
import editIcon from '../../../../../src/assets/icon_edit.svg';
import deleteIcon from '../../../../../src/assets/icon_delete_o.svg';

import burgerKingTrans from '../../../../../src/assets/burgerKingTrans.png';

// import { isExistsDomeName, UpdateDome,UpdateDomeType } from '../../../services/domeService';

class MyPicks extends React.Component<any, any> {
    static contextType = AppContext;
	
    constructor(props: any) {
        super(props);
        this.state = {
            success: '',
            error: '',
        }
		
    }

    
    getContext = async () => {
        const context = this.context;
        let profile = context.profile || {};
        return profile;
    }

    updateContext = async (data: any) => {
        const context = this.context;
        context.setProfile(data);
    }

    render() {
        const context = this.context;
        return (
            <>
             <Row className="menu-wrapper treasure-menu-wrapper third-dialog">
				<div className="third-card">
					<div className="fx-jcsb-aic mb-20">
						<h4 className="text-primary mb-0 font-bold"> &#62; My Picks</h4>
						<div className="fx-aic">
							<div className="size12 text-primary">Status: <span>No reply from users via chat</span></div>
							<i onClick={this.props.closeMyPicks} className="iconx cursor">
								<img src={closeIcon} alt="Info" />
							</i>
						</div>
					</div>

					<div className="domeAddContainer">
						<div className="chat-loop-container">
							<div className="date-separator">
								<span className="date-separator__date">2021.5.2</span>
							</div>
							<div className="chat-loop">
								<figure className="chat-loop__fig">
									<img src={burgerKingTrans} alt="logo" />
								</figure>
								<div className="chat-loop__msg">
									<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi repudiandae inventore doloribus? Voluptates tempora soluta</p>
								</div>
							</div>
							<div className="chat-loop">
								<figure className="chat-loop__fig">
									<img src={burgerKingTrans} alt="logo" />
								</figure>
								<div className="chat-loop__msg">
									<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi repudiandae inventore doloribus? Voluptates tempora soluta</p>
								</div>
							</div>
							<div className="date-separator">
								<span className="date-separator__date">2021.5.2</span>
							</div>
							<div className="chat-loop">
								<figure className="chat-loop__fig">
									<img src={burgerKingTrans} alt="logo" />
								</figure>
								<div className="chat-loop__msg">
									<p>Lorem ipsum dolor sit amet</p>
								</div>
							</div>
							<div className="chat-loop">
								<figure className="chat-loop__fig">
									<img src={burgerKingTrans} alt="logo" />
								</figure>
								<div className="chat-loop__msg">
									<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi repudiandae inventore doloribus? Voluptates tempora soluta</p>
								</div>
							</div>
						</div>

						<div className="reply-section">
							<label className="reply-section__upload">
								<input type="file" />
								+
							</label>
							<div className="reply-section__type">
								<input type="text" name="" id="" className="form-control" />
							</div>
							<button className="reply-section__submit">&nbsp;</button>
						</div>
					</div>
				</div>
			</Row>
			
           </>
            )
    }

}

export default MyPicks;
