import React from 'react';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '../../assets/chevronLeft.svg';
import ChevronRight from '../../assets/chevronRight.svg';
import PropTypes from 'prop-types';

function Paginate(props) {
  const { onPageChange, totalItems, pageSize, forcePage } = props;

  const pageCount = Math.ceil(totalItems / pageSize);

  return (
    <ReactPaginate
      containerClassName="statistic-paging-container"
      pageClassName="statistic-paging-page"
      pageLinkClassName="statistic-paging-page-link"
      activeLinkClassName="statistic-paging-page-link-active"
      pageCount={pageCount}
      previousLabel={<img src={ChevronLeft} />}
      nextLabel={<img src={ChevronRight} />}
      marginPagesDisplayed={1}
      onPageChange={onPageChange}
      forcePage={typeof forcePage === 'number' ? forcePage : undefined}
    />
  );
}

Paginate.propTypes = {
  onPageChange: PropTypes.func,
  totalItems: PropTypes.number,
  pageSize: PropTypes.number,
  forcePage: PropTypes.number,
};

Paginate.defaultProps = {
  onPageChange: (value) => {
  },
  totalItems: 20,
  pageSize: 20,
};

export default Paginate;
