import { responseCodes } from '../lib/const';
import { notification } from 'antd';
import { store } from '../helpers/store';
import * as config from '../lib/config';
import { executeSignOut } from './sharedServices';
import { isEmpty } from 'lodash';
import { serverError } from '../lib/strings/error';

export const showErrorMessage = (title, description?) => {
  notification.error({
    message: `${title}`,
    description: `${description}`,
    duration: 10,
  });
};

export const getCandyEventList = async ({ queryKey }) => {
  // for react-query
  const fetchResponse =   await fetch(
    config.baseURL +
      config.candyEvent +
      '?' +
      new URLSearchParams({ country: queryKey[1]?.country }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return isEmpty(response.data) ? [] : response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};


export const getCandyEventList1= async () => {
  // for react-query
  const fetchResponse =   await fetch(
    config.baseURL +
      config.candyEvent +
      '?' +
      new URLSearchParams(),
      // new URLSearchParams({ country: queryKey[1]?.country }),
    {
      method: 'GET',
      headers: {
        'access-token': store.getState().authentication.user.newToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return isEmpty(response.data) ? [] : response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};

export const getCandyEventRankingList = async ({ queryKey }) => {
  // for react-query
  const { ID } = queryKey[1];
  const fetchResponse =   await fetch(config.baseURL + config.candyEvent + `/${ID}/ranking`, {
    method: 'GET',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return isEmpty(response.data) ? [] : response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};

export const createCandyEvent = async (selectedCountryCode:any,challenge_type:any) => {
  // const formData = new FormData();
  // formData.append('countrycode',selectedCountryCode);

   const country=selectedCountryCode
  
  const fetchResponse =   await fetch(config.baseURL + config.candyEvent, {
    method: 'POST',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     country,
     challenge_type
    })
  });
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `Response code: ${response.code}`;
  }
};

export const candyEventSendNotifications = async (ID: number) => {
  try {
    const fetchResponse =   await fetch(
      config.baseURL + config.candyEventSendNotifications + `/${ID}`,
      {
        method: 'GET',
        headers: {
          'access-token': store.getState().authentication.user.newToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    const response =   await fetchResponse.json();
    if (response.code === responseCodes.successful) {
      return response.data;
    } else if (response.code === responseCodes.tokenNotValid) {
      executeSignOut();
    } else {
      throw `Response code: ${response.code}`;
    }
  } catch (error) {
    showErrorMessage(serverError[store.getState().authentication.language], error);
  }
};

export const deleteCandyEvent = async (ID) => {
  const fetchResponse =   await fetch(`${config.baseURL}${config.candyEvent}/${ID}`, {
    method: 'DELETE',
    headers: {
      'access-token': store.getState().authentication.user.newToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const response =   await fetchResponse.json();
  if (response.code === responseCodes.successful) {
    return response.data;
  } else if (response.code === responseCodes.tokenNotValid) {
    executeSignOut();
  } else {
    throw `${response.error} ${response.code}`;
  }
};

// export const Recuring = async (candyEventID,ck) => {
//   const formData = new FormData();
//   // formData.append('recurring','1');
//   // formData.append('ID',ID);
//   try {
//     const fetchResponse =   await fetch(`${config.baseURL}${config.Recurring}/${candyEventID}/${ck}`, {
      
//       method: 'PUT',
//       headers: {
//         'access-token': store.getState().authentication.user.newToken,
//       },
//       body: formData,
//     });
//     console.log(fetchResponse,"fetchResponse")
//     const response =   await fetchResponse.json();
//     if (response.code === responseCodes.successful) {
//       return response.data;
//     } else {
//       showErrorMessage(response.error, response.code);
//       return Promise.reject('failed to get sections');
//     }
//   } catch (error) {
//     showErrorMessage("Try after sometimes");
//     return error;
//   }
// }
