import { candyEventConstants } from '../constants/candyEventConstants';

export const resetCandyEvent = () => ({ type: candyEventConstants.CANDY_EVENT_RESET_STATE });

export const editableParameters = {
  ID: 'ID',
  contestantIndex: 'contestantIndex',
  isEditing: 'isEditing',
  selectedCountryCode: 'selectedCountryCode',
};

export const editParameter = (parameterKey, value) => ({
  type: candyEventConstants.CANDY_EVENT_EDIT_PARAMETER,
  parameterKey,
  value, 
});

export const batchSet = (payload) => ({ type: candyEventConstants.CANDY_EVENT_BATCH_SET, payload });
