import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Typography, Row, Button, InputNumber, Col, Carousel,notification } from 'antd';
import closeIcon from '../../../../../src/assets/closeXsmall.svg';
import voucherPic from '../../../../../src/assets/voucherPic.png';
import AppContext from '../../../../AppContext';
import { getImageLbox } from '../../../../services/domeService';
import { baseURL } from '../../../../lib/config';
import UsersPayLbox from './UsersPayLbox';
import StripePayment from '../../../../components/StripePayment';
import { connect } from 'react-redux';
import { menuConstants } from '../../../../constants/menuConstants';
import { LOCAL_STORAGE_USERDATA } from '../../../../lib/config';
import { userData } from '../../../../reducers/userReducer';
import { rechargeConstants } from '../../../../constants/rechargeConstants';
import { userConstants } from '../../../../constants/signinConstants';
import { b } from '.././i18next'

interface Props {
  handleClose: () => void;
  updateLCash: (amount: number) => void;
  amount: number;
  currentLCash: number;
  usd: number;
  isPaymentInitiated: boolean;
  vouchers:any;
  
  userData: object;
  storeUserData: (user: object) => void;
}

const mapState = (state: {
  authentication: any;
  userData: { lcash: any,vouchers:any };
  recharge: { usd: any; amount: any };
  isPaymentInitiated: boolean;
  // coupons: state.userData.coupons,
}) => {
  return {
    currentLCash: state.userData.lcash,
    usd: state.recharge.usd,
    amount: state.recharge.amount,
    isPaymentInitiated: state.isPaymentInitiated,
    language: state.authentication.language,
    userData: state.userData,
    vouchers: state.userData.vouchers

  };
};
const mapDispatch = (dispatch: any) => {
  return {
    handleClose: () => dispatch({ type: menuConstants.CLOSE_SUBMENU_1 }),
    updateLCash: (amount: number) =>
      dispatch({ type: rechargeConstants.STORE_AMOUNT, amount }),
    storeUserData: (user: any) =>
      dispatch({ type: userConstants.STORE_USER_DATA, user }),
  };
};

const showErrorMessage = (title) => {
  notification.error({
    message: `${title}`,
    duration: 10,
  });
};

// Functional component
const Lbox = (Props:Props) => {
    const profile = useContext(AppContext);
  const [image1, SetImage1] = useState('')
  const [image2, SetImage2] = useState('')
  const [image3, SetImage3] = useState('')
  const [image4, SetImage4] = useState('')
  const [image5, SetImage5] = useState('')
  const [image6, SetImage6] = useState('')
  const [voucherBundle,SetvoucherBundle]=useState(false)

  const lboxClose = () => {
    profile.profile['lbox'] = false
    profile.setContextData(profile.profile)
  }

  useEffect(() => {
    (async () => {
            let data = await getImageLbox();
       SetImage1(baseURL + '/uploads/' + data.voucherData.image_1.id)
      SetImage2(baseURL + '/uploads/' + data.voucherData.image_2.id)
       SetImage3(baseURL + '/uploads/' + data.voucherData.image_3.id)
       SetImage4(baseURL + '/uploads/' + data.voucherData.image_4.id)
       SetImage5(baseURL + '/uploads/' + data.voucherData.image_5.id)
        SetImage6(baseURL + '/uploads/' + data.voucherData.image_6.id)
    })();
  }, []);

  const BuyNowLbox = () => {
    Props.storeUserData({ refetch: true });
    localStorage.setItem(
      LOCAL_STORAGE_USERDATA,
      JSON.stringify({ ...userData, refetch: true })
    );
   }
  return (
    <>
      <Row className="menu-wrapper treasure-menu-wrapper second-dialog cardBiger lboxCard">
        <div className="third-card relative">
          <div className="domeAddContainer">
            <i className="iconx cursor closeIcon">
              <img src={closeIcon} onClick={lboxClose} alt="Info" />
            </i>
            <Row type='flex'>
              <Col span={12} className='galleryCard'>
                <div className="fx-jcsb-aic mb-10 mt-15">
                  <h4 className="text-primary mb-0 font-bold">
                    &#62; {b?.t('Lbox')}
                  </h4>
                </div>
                <div className='galleryCard__slider'>
                {localStorage.getItem('lang')=='ko'?
                  <Carousel autoplay className='customSlider'>
                    <div>
                      <h3> <img src={image4}/></h3>
                    </div>
                    <div>
                      <h3><img src={image5} /></h3>
                    </div>
                    <div>
                      <h3><img src={image6} /></h3>
                    </div>
                    {/* <div>
                      <h3>Image 4</h3>
                    </div> */}
                  </Carousel>
                  :
                  <Carousel autoplay className='customSlider'>
                    <div>
                      <img src={image1}/>
                    </div>
                    <div>
                      <img src={image2} />
                    </div>
                    <div>
                      <img src={image3} />
                    </div>
                  </Carousel>
                  }
                </div>
                <p>{b?.t('Dome Address Voucher Bundle')}</p>
                <p>{b?.t("Don't miss out")}</p>
              </Col>
              <Col span={12} className='voucherBundleCard'>
                <div className='scrollSection'>
                  <div className='text-center'>
                    <span className='font-bold text-center voucherBundle'>{b?.t('Dome Address')} <br /> {b?.t('Voucher Bundle')}</span>
                  </div>
                  <h4 className='font-bold text-center'>{b?.t('Three 5-year Dome Address Vouchers')}</h4>
                  <div className='voucherwhitecard'>
                    <Row type='flex' justify="space-between">
                      <Col span={10}>
                        <div className='voucherPlaceholder'>
                          <img src={voucherPic} alt="voucher" />
                        </div>
                      </Col>
                      <Col span={14}>
                        <h3 className='font-bold text-primary'>{b?.t('Price')}:</h3>
                        <h3 className='font-bold text-dark'>USD <del>229.50</del></h3>
                        <h3 className='font-bold text-dark'>USD 115.00 <span className='text-red font-bold'>(50% ↓)</span></h3>
                      </Col>
                    </Row>
                    <Button
                className="ant-btn buyNowBtn "
                onClick={BuyNowLbox}
              >
                <StripePayment  amount={115}> </StripePayment>
              </Button>
                    {/* <button onClick={BuyNowLbox}
                     className='ant-btn buyNowBtn'>Buy Now</button> */}
                  </div>
                  <div className='benefitsCard'>
                    <h3 className='font-bold text-primary'>{b?.t('Benefits')}:</h3>
                    <p className='text-dark'>{b?.t('You can secure')}</p>
                    <ul className=''>
                      <li className='mb-10 text-dark'>{b?.t('With a drastically')}</li>
                      <li className='text-dark'>{b?.t('If another user wants')}</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </>
  );
};

export default connect(mapState, mapDispatch) (Lbox);