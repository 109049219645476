import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Statistics from './Statistics';
import { statisticsForVoucher } from './apiToTabel';
import {
    statisticsForVoucherStrings,
    statisticsForAddressVoucher,
} 
from '../../../../lib/strings/statistics';
import { getKits } from '../../../../services/kitService';
import * as statisticsService from '../../../../services/statisticsService';
import { useQuery } from 'react-query';


function StatisticsForVoucher(props) {
  const { language } = props;

  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [statistics, setStatistics] = useState([]);
  const [totalTreasures, setTotalTreasures] = useState(0);
  const [total, setTotal] = useState({});
  const [pagcurrent, setPagCurrent]=useState(1)

  const [clickedUsername, setClickedUsername] = useState('');
  const clickedUsernameHandler = (rowId, username) => {
    setClickedUsername(username);
  };

  const fetchStatisticsForVoucher = async ({ queryKey }) => {
    const response =  await statisticsService.getStatisticsForvoucher(queryKey[1]);
    return response;
  };

  const statisticsQuery = useQuery(
    ['statisticsForVoucher', { ...currentFilters, page: currentPage }],
    fetchStatisticsForVoucher,
    {
      staleTime: 1800000,
      cacheTime: 3600000,
    }
  );

  const kitQuery = useQuery('kitList', getKits, {
    staleTime: 1800000,
    cacheTime: 3600000,
  });

  useEffect(() => {
    if ( statisticsQuery.data) {
      const parsedStatistics = statisticsForVoucher(statisticsQuery.data);
       setTotal({ totalBalance: statisticsQuery.data.Count});
       setPagCurrent(statisticsQuery.data.List.length);       
       setTotalTreasures(statisticsQuery.data.Count);
       setStatistics(parsedStatistics);
    }
  }, [statisticsQuery.data]);

  const onFiltersChangeHandler = async (filters) => {
    setCurrentPage(0);
    setCurrentFilters(filters);
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
    setClickedUsername('');
  };

  const columns = [
    {
       dataIndex: 'date',
       titleStyle: { textAlign: 'center',paddingLeft:"10px",paddingRight:"10px" },
      dataStyle: {},
    },
    {
      
      dataIndex: 'voucherID',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      
      dataIndex: 'username',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
     
      dataIndex: 'country',
      titleStyle: { textAlign: 'center'},
      dataStyle: { textAlign: 'center' },
    },

    {
     
      dataIndex: 'bundle_payment',
      titleStyle: { textAlign: 'center'},
      dataStyle: { textAlign: 'center' },
    },
    {
     
      dataIndex: 'used',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      
      dataIndex: 'dome_addr',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
      
      dataIndex: 'expiration',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
    },
    {
     
      dataIndex: 'total_no_of_bundle',
      titleStyle: { textAlign: 'center' },
      dataStyle: { textAlign: 'center' },
      render: (text, indx, record) => <span>{pagcurrent-(currentPage*100)-indx}</span>
    },

  ];
  
  columns.map((column, index) => {
    columns[index]['title'] = statisticsForVoucherStrings[language][column.dataIndex];
  });

  return (
    <>
      <Statistics
        label={statisticsForAddressVoucher[language]}
        columns={columns}
        dataSource={statistics}
         loading={statisticsQuery.isLoading}
        statisticsType={'StatisticsForVoucher'}
        onFiltersChange={onFiltersChangeHandler}
        onPageChange={onPageChangeHandler}
        clickedUsername={clickedUsername}
        totalItems={totalTreasures}
        pageSize={100}
        currentPage={currentPage}
        total={total}
      />
    </>
  );
}

 StatisticsForVoucher.propTypes = {};

 StatisticsForVoucher.defaultProps = {};

const mapStateToProps = (state) => ({
  language: state.authentication.language,
  userId:state.userData.mysqlID
 
});

export default connect(mapStateToProps)(StatisticsForVoucher);