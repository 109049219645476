export { default as candySmall } from './candySmall.svg';
export { default as icon_male } from './icon_male.png';
export { default as icon_female } from './icon_female.png';
export { default as icon_other } from './icon_other.png';
export { default as arrow } from './treasure_item_more_info_icon.svg';
export { default as imgPlaceholder } from './profile_picture.svg';
export { default as pointImg } from './ar_star_large.svg';
export { default as stickerImg } from './sticker_item_on_map.svg';
export { default as candyImg } from './icon_candy.svg';
export { default as iconLDiscount } from './iconLDiscount.svg';
export { default as like } from './like.svg';
export { default as badgeFirst } from './badgeFirst.svg';
export { default as badgeSecond } from './badgeSecond.svg';
export { default as badgeThird } from './badgeThird.svg';
export { default as likesYellow } from './likesYellow.svg';
export { default as playYellow } from './playYellow.svg';
export { default as editIcon } from './icon_edit.svg';
