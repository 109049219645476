export const parseLocalizationData = (data: Array<any>, language?: string) => {
  if (Array.isArray(data)) {
    if (language) {
      const languageSmall = language.toLowerCase();
      const languageData = data.filter((objectData) => objectData.lang_code === languageSmall);
      const parametarNames = languageData.map((objectData) => objectData.param_name);
      let parametars = {};
      parametarNames.map((parametarName) => {
        parametars = {
          ...parametars,
          [parametarName]: languageData.find(
            (objectData) => objectData.param_name === parametarName
          ).localized_val,
        };
      });
      return parametars;
    } else {
      const languages = Object.keys(localizationLanguageList(data));

      let allLanguages = {};
      languages.map((language) => {
        const languageData = data.filter((objectData) => objectData.lang_code === language);
        const parametarNames = languageData.map((objectData) => objectData.param_name);
        let parametars = {};
        parametarNames.map((parametarName) => {
          parametars = {
            ...parametars,
            [parametarName]: languageData.find(
              (objectData) => objectData.param_name === parametarName
            ).localized_val,
          };
        });
        allLanguages = { ...allLanguages, [language]: parametars };
      });
      return allLanguages;
    }
  } else {
    return {};
  }
};

export const localizationLanguageList = (data: Array<any>) => {
  let languages = {};
  data.map((element) => {
    languages = { ...languages, [element.lang_code]: element.lang_code };
  });
  return languages;
};

export const constructLocalizationBody = (
  id: number,
  model: 'story' | 'notifications' | 'idol_banner',
  language: string,
  transformData: object,
  oldLocalization?: any
) => {
  const parametars = Object.keys(transformData);
  let data = {};
  if (!isEmpty(oldLocalization)) {
    data = oldLocalization[model];
  }

  parametars.map((key) => {
    let spreadData = {};
    if (data[key] && data[key][id]) {
      spreadData = data[key][id];
    }
    const dataToWrite = transformData[key] === undefined ? '' : transformData[key];
    data = {
      ...data,
      [key]: {
        [id]: {
          ...spreadData,
          [language.toLowerCase()]: dataToWrite,
        },
      },
    };
  });
  return { [model]: data };
};

export const constructLocalizationBodyMultiLanguage = (
  id: number,
  model: 'story' | 'notifications' | 'idol_banner',
  transformData: any
) => {
  const languages = Object.keys(transformData);
  let returnData = {};
  languages.map((language) => {
    returnData = constructLocalizationBody(
      id,
      model,
      language,
      transformData[language],
      returnData
    );
  });

  return returnData;
};

export const isEmpty = (obj) => {
  for (let x in obj) {
    return false;
  }
  return true;
};
