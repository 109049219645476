import React from 'react';
import { Spin, Row, Typography, Col } from 'antd';
import { loadingModalText } from '../lib/strings/appConstants';
import { languages } from '../lib/interfaces/language';
import { connect } from 'react-redux';

interface Props {
  isLoading: boolean;
  percentage: number;
  done: boolean;
  language: languages;
}

const LoadingModal = (props: Props) => {
  return (
    <Row type="flex" justify="center" className="loading-modal">
      <Col>
        <Row type="flex" justify="center" style={{ padding: '2em' }}>
          <Typography.Text className="fredoka">
            {loadingModalText[props.language]} {props.percentage}%
          </Typography.Text>
        </Row>
        <Row type="flex" justify="center" style={{ padding: '2em' }}>
          <Spin size="large" spinning={props.isLoading} />
        </Row>
      </Col>
    </Row>
  );
};

const mapState = (state: { authentication: { language: languages } }) => {
  return {
    language: state.authentication.language,
  };
};

export default connect(mapState)(LoadingModal);
